import PropTypes from "prop-types"
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const DeLinkModal = ({ show, onDeleteClick, onCloseClick }) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          {/* <button type="button" onClick={onDeleteClick} className="btn-close position-absolute end-0 top-0 m-3"></button> */}
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>
          <p className=" font-size-16 mb-4 fw-bold">
            Are you sure you want to permanently De-Link the Record.
          </p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-danger"
              onClick={onDeleteClick}
            >
              De-Link
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeLinkModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeLinkModal
