import React, { useEffect, useState, useMemo, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import toastr from "toastr"
import Flatpickr from "react-flatpickr"
import { createTheme } from "@material-ui/core/styles"
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useSticky } from "react-table-sticky"
import Tooltip from "@material-ui/core/Tooltip"
import {
  Row,
  Col,
  CardBody,
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Badge,Container,
  BreadcrumbItem
} from "reactstrap"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Filter, DefaultColumnFilter } from "../../../components/Common/filters"
import Pagination from "../../../components/Common/Pagination"
import styled from "styled-components" 
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { Popper } from "@material-ui/core"
import FilterModal from "../../../components/FilterModal/FilterModal"
import axios from "axios"
import TableHeader from "components/Common/TableHeader"
import { Link } from "react-router-dom"
import { format } from "date-fns"
import { FormatedDate, RedirectOnUnAuthentication, RemoveSpaces, RenameKeysOfObject, encryptData, trimLeft } from "helpers/helper_function"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min"

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :first-child {
        .td {
          padding-top: 10px;
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      padding-left: 10px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 90px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`
const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 110,
  },

  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}

const TableContainer = ({
  loader,
  columns,
  reqresData,
  filterArray,
  searchObject,
  downloadAccess,
  handleSort,
  setSortBy,
  handleRender,
  props,
  customePageSize,
  setCustomePageSize,
  scanBtn,
  fetchData,
  dateQuery,
  setSearchObject,
  setCurrentPage,
  setFilterArray,
  setDelete,
  is_delete,
  setScanBtn,
  data,
  pageCount: customePageCount,
  customPageSize,recordId
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      // disableRowSelect: true,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useFlexLayout,
    useBlockLayout,
    useSticky,
    useRowSelect
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Let's make a column for selection
    //     {
    //       id: "selection",
    //       sticky: "left",
    //       width: 37,

    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <div style={{ backgroundColor: "#fff" }}>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ])
    // }
  )

  const [btnLoader, setBtnLoader] = useState(true)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  // const handleScan = () => {
  //   const authToken = JSON.parse(localStorage.getItem("authUser"))
  //   setScanBtn(true)

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}/scan-data`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken.data.authToken}`,
  //         },
  //       }
  //     )
  //     .then(response => {
  //       //   exportToExcel(response.data.data)
  //       setScanBtn(false)
  //       setDelete(prevDelete => !prevDelete)
  //       toastr.success("Scan successfully")
  //       // if(response.status === 1){
  //       //   console.log(response.status, "response.status")
  //     })
  //     .catch(error => {
  //       RedirectOnUnAuthentication(error,navigateTo)
  //       setScanBtn(false)
  //     })
  // }

  const handleSearchQueryChange = (fldName, value) => {
    console.log(fldName, value, "check handleSearchQueryChange and value")

    const authToken = JSON.parse(localStorage.getItem("authUser"))

    let columnName
    if (fldName === "obligation.obligationDesc") {
      columnName = "obligationDesc"
    } else if (fldName === "obligation.obligationName") {
      columnName = "obligationName"
    } else if (fldName === "obligation_citationUrl") {
      columnName = "citationUrl"
    } else if (fldName === "obId") {
      columnName = "obId"
      const updatedSearchQuery = {
        ...searchQuery,
        ["newObligationReport.obId"]: value,
      }

      setSearchQuery(updatedSearchQuery)
    } else if (fldName === "createdDate") {
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)

      setSearchQuery(prev => ({ ...prev, [fldName]: formattedDate }))
    } else {
      columnName = fldName
    }

    // console.log(columnName, value, "check")

    const requestData = {
      reportId: recordId,
      tableName: "newObligationReport",
      feildName: columnName,
      searchValue: value ? value : "",
      relationFeild: "obligation", 
      isUnlink: false,
    } 

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log(response,  "re ponse")
        if (fldName != "createdDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [fldName]: response.data.data.map(item => item[columnName]),
          }))
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnName]: [],
        }))

      })
  }

  const handleAutocompleteChange = (columnId, value) => {
    // console.log(columnId, value, 'check handleAutocompleteChange and value')
    let updatedValue
    let columnName
    // console.log(columnId, typeof(columnId));
    // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
    if (value == "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
      // console.log("**************");
    } else if (columnId == "createdDate") {
      // Set the value to false when selecting "No"
      console.log(value, "value")
      const newDate = new Date(value)
      console.log(newDate, "newDate")
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      updatedValue = formattedDate
    } else {
      updatedValue = value.trim()
    }

    // if (columnId == 'obId') {
    //   console.log(columnId == 'obId', 'check', 'newObligationReport.obId');
    //  columnName = "newObligationReport.obId"
    // //  updatedValue = value
    // }else if (columnId == "obligationDesc") {
    //   columnName = "newObligationReport.obligationDesc"
    //   updatedValue = value
    //  }else {
    //   updatedValue = value // Set the value to true when selecting "Yes"
    //   columnName = columnId
    // }

    // console.log(columnName,updatedValue, "updated alue")
    const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)

    console.log("autoEmpty", autoEmpty)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const clearFilter = () => {
    setSearchQuery({})
    setSelectedHeader([])
    setAutoEmpty({})
    setAutoFill({})
    setFilterArray({})
    setSearchObject({})
    setSortBy({
      tableName: "obligation",
      fieldName: "citationUrl",
      order: "ASC",
    })
    setSelectedDate(null)
    setCurrentPage(1)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          border: "1px solid #ced4da",
          borderBottom: 0,
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          // '&&[class*="MuiOutlinedInput-root"] $input': {
          //   padding: "1px"
          // },
          "& .MuiAutocomplete-inputRoot": {
            color: "purple",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
              {
                // Default left padding is 6px
                padding: 26,
              },
          },
        },
      },
    },
  })

  const classes = useStyles()

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  // const classes = useStyles();

  return (
    <Fragment>
      
    
      <Row className="pt-3">
        <Col
          xs={12}
          md={12}
          lg={12}
          xl={6}
          className="d-flex flex-column flex-lg-row gap-3 align-items-center"
        >
          {/* <div className="d-flex  align-items-center ">
              <h5 className="font-size-18 mb-0">New Obligation Report</h5> */}
          {/* <select
                className="form-select"
                value={customePageSize}
                onChange={onChangeInSelect}
                style={{width : "150px", marginLeft: "10px"}}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    No of Results {pageSize}
                  </option>
                ))}
              </select> */}
          {/* </div> */}
          {/* <div className="d-flex justify-content-start ">
           
            </div> */}
          <TableHeader
            heading={""}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        {/* {console.log(headerGroups && headerGroups[0].headers[2], "headerGroups")} */}
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={6}
          className="justify-content-end py-2"
        >
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {/* {downloadAccess && downloadAccess.readAccess === true ? (
              scanBtn ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={() => { 
                    setTimeout(() => { 
                      setDelete(!is_delete)
                    }, 2000)
                  }}
                >
                  <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                  Scan Now
                </button>
              )
            ) : (
              ""
            )} */}

            {/* {downloadAccess && downloadAccess.readAccess === true ? (
                    // btnLoader ? (
                    //   <div className="ml-5">
                    //     <Spinner className="ms-2 " color="primary" />
                    //   </div>
                    // ) : (
                      <button 
                      className="btn btn-primary"
                      onClick={handleExport}>
                         <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                           Download
                      </button>
                    )
                //   )
                  :
                  ("")
                  } */}
          </div>
        </Col>
      </Row>

      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 500 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span>
                              <br /> */}
                      </div>
                      {/* {console.log(column.id, 'colims')} */}
                      {column.id != "selection" &&
                      column.id != "id" &&
                      column.id != "createdDate" &&
                      column.id != "status" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{ width: "70%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              options={autoEmpty[column.id] || searchQuery[column.id] ? autoFill[column.id] || [] : []}
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value)
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  disabled={loader}
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={handleKeyDown}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          {
                          column.id !== "obligation_citationUrl" &&
                          column.id !== "obligation.obligationDesc" ? (
                            <button
                              onClick={() => {
                                setShowModal(true)
                                setModalData(column)
                              }}
                              disabled={loader}
                              className={
                                selectedHeader.includes(column.Header)
                                  ? "filter_button_selected"
                                  : "filter_button"
                              }
                              // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            >
                              <i className="bx bx-filter font-size-18"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : column.id == "createdDate" ? (
                        <div
                          style={{
                            width: "40%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: '2023-07-19',
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                              <button
                                                type="button"
                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                // disabled
                                                style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                              >
                                                <i
                                                  className="fa fa-calendar"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                            </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          filterArray={filterArray}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          tableName="newObligationReport"
          relationFeild={`${
            modalData.id === "obligation_citationUrl"  
              ? "obligation"
              : ""
          }`}
          isUnlink={false}
          isObliReport={true}
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0??false}
          filterableObjects={{"reportId": recordId}}
          // tableName={`${
          //   modalData.id === "disclosure"
          //     ? "Disclosure"
          //     : modalData.id === "obligation"
          //     ? "Obligation"
          //     : "disclosure_obligations_obligation"
          // }`}
        />
      </Styles>
      
      {/* )} */}
    </Fragment>
  )
}

const ScannerReportWithUrlList = props => { 
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [modalDisID, setModalDisID] = useState("")
  const [modalObID, setModalObID] = useState("")
  const [withData, setWithData] = useState(false)
  const [rowID, setRowID] = useState()
  const [disclosureDetail, setDisclosureDetail] = useState([])
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [downloadAccess, setDownloadAccess] = useState([])
  const [sortBy, setSortBy] = useState({
    tableName: "obligation",
      fieldName: "citationUrl",
      order: "ASC",
  })
  const [scanBtn, setScanBtn] = useState(true)
  const location = useLocation()
  const stateData  =location?.state  || {}
  const recordId  =stateData?.rowData?.id || ""
  const recordDate  =stateData?.rowData?.createdDate || ""
  const dispatch = useDispatch()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const limit = 20
  
  console.log(scanBtn, "scan btn")

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  useEffect(() => {
    setSortBy({
      tableName: "obligation",
      fieldName: "citationUrl",
      order: "ASC",
    })
  }, [])

  const handleSort = column => {
    console.log(column, "handleSort")
    const isAsc =   sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName: "obligation",
        fieldName: column.id=="obligation_citationUrl"?"citationUrl":column.id,
        order: sortOrder,
      })
    }
  }
 

  const toggle = data => {
    setModal(!modal)
    setRowID(data.id)
    setWithData(true)
    if (data.disclosure) {
      setModalDisID(data.disclosure.id)
    }
    if (data.obligation) {
      setModalObID(data.obligation.id)
    }
  }

  useEffect(() => {
    if (modal === false) {
      setModalObID("")
      setModalDisID("")
      setWithData(false)
    }
  }, [modal])

  const test = () =>{
    console.log(location.state);
  }
  const columns = [
 
    {
      Header: "Citation URL",
      accessor: "obligation_citationUrl",
      // width: 400,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return <div onClick={()=>console.log(rowData)}>{cellProps.value}</div>
      },
    },
  
    {
      Header: "Action",
      width: 150,
      textAlign: "top",
      sticky: "right",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        // setScanBtn(rowData.status === 0 ? true : false)
        return (
          <Link
            to={{
              pathname: "/url-updated-obli-report",
              state: { rowData: { ...stateData.rowData,...rowData, clear: true,stateDataRowData:stateData.rowData } },
            }}
            style={{ pointerEvents: rowData.status === 0 ? "none" : "" }}
            // disabled={rowData.status === 0 ? true : false}
          >
            <button
              className="btn btn-primary btn-sm"
              style={{ padding: "5px 15px" }}
              disabled={rowData.status === 0 ? true : false}
              onClick={() => console.log(  "daddadadadad", { ...stateData.rowData,...rowData, clear: true } )}
            >
              {/* <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "} */}
              View
            </button>
          </Link>
        )
      },
    },
  ]

 

  const fetchData = searchObject => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log(filterArray, "filterArray")
    // const data = JSON.stringify({searchQuery})
    console.log(searchObject, "re-searchQuery")
    let finalData= Object.keys(filterArray).length
      ? filterArray
      : searchObject
    console.log(finalData, "finalData")
    setLoader(true)
    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } else {
      localStorage.removeItem("selectedFilterArray")
    }

    const params = {
      obligation_citationUrl : "obligation.citationUrl", 
    }
    const rawObject = {
      ...finalData,  }
    const modifiedFinalData = RenameKeysOfObject({ object: rawObject, params: params }) 
    const requestData = {
      "reportId": recordId,
      ...modifiedFinalData,
      orderBy: {
        ...sortBy,
      },
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}new-obligation-citation-url-report/?limit=${customePageSize}&pageNo=${currentPage}`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          console.log(  "response.data.data",response.data.data)
          setDisclosureDetail(response.data.data)
          
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    console.log("page is loading")
    fetchData(searchObject)
    localStorage.removeItem("formattedData")
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const handleRender = () => {
    console.log(is_delete, "scannedddddd")
    setDelete(!is_delete)
  }

  document.title = "Report's Citation URLs"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Row>
            <Col className="col-12" onClick={test}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {`#${recordId} - Report's Citation URLs - ${FormatedDate(recordDate)}`}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "/url-new-obli-report",
                          // state: { rowData: {id: data.queryId} },
                        }}
                      >
                        {"Reg Content Change Report With Citation"}
                      </Link>
                    </BreadcrumbItem> 
                    <BreadcrumbItem active>
                      {`Report's Citation URLs - ${FormatedDate(recordDate)}`}
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                    toggle={toggle}
                    setModal={setModal}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    setDelete={setDelete}
                    is_delete={is_delete}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    setSearchObject={setSearchObject}
                    filterArray={filterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    setFilterArray={setFilterArray}
                    setCurrentPage={setCurrentPage}
                    setScanBtn={setScanBtn}
                    columns={columns}
                    scanBtn={scanBtn}
                    data={disclosureDetail}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    recordId={recordId}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        name={console.log({ totalItems })}
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ScannerReportWithUrlList)
