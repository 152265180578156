import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios"
import { useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import {
  addNewObligation as onAddNewObligation,
  updateObligation as onUpdateObligation,
} from "store/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication } from "helpers/helper_function"

const ObligationUpdate = props => {
  const [dropdownState, setDropdownState] = useState(false)
  const [selectRegulator, setSelectRegulator] = useState([])
  const [selectRegulation, setSelectRegulation] = useState([])
  const [regulatioData, setRegulationData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState()
  const [btnDisable, setBtnDisable] = useState(false)
  const [regrId, setRegrID] = useState(null)
  const [obIdCheck, setObIdCheck] = useState("")
  const [obOnChange, setObOnChange] = useState("")
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  console.log(regrId, "regrID")
  const dispatch = useDispatch()

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }get-regulators/?limit=${1000}&pageNo=${1}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data)
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;

          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.regulatorShortName,
          }))

          const modifiedOptions = [
            { label: "Select Regulator", value: 0 }, // 0 option
            ...optionData, // API options
          ]
          setSelectRegulator(modifiedOptions)
          // setSelectRegulator(response.data.data.map(option => ({
          //   value: option.id,
          //   label: option.regulatorShortName
          // })))
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  const fetchRegulations = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      regulatorId: regrId,
    }
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }/get-regulations/?limit=${1000}&pageNo=${1}`,
        { data: encryptData(data) },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.regShortName,
          }))

          const modifiedOptions = [
            { label: "Select Regulations", value: 0 }, // 0 option
            ...optionData, // API options
          ]

          setSelectRegulation(modifiedOptions)

          // setSelectRegulation(response.data.data.map(option => ({
          //   value: option.id,
          //   label: option.regShortName
          // })))
        }
        // throw response.data;
      })
      .catch(err => {
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        // throw message;
        RedirectOnUnAuthentication(err,navigateTo,message)
      })
  }

  useEffect(() => {
    if (regrId) {
      fetchRegulations()
    }
  }, [regrId])

  const location = useLocation()

  const [data, setData] = useState()
  console.log(data, "rowdata")
  useEffect(() => {
    if (location.state) {
      setData(location.state.rowData)
      setObIdCheck(location.state.rowData.obId)
      setRegrID(
        location.state.rowData && location.state.rowData.regulator
          ? location.state.rowData.regulator.id
          : null || null
      )
      const statusSelct = statusOptions.find(
        name => name.value === location.state.rowData.status
      )
      if (statusSelct !== undefined) {
        setSelectedStatus(statusSelct)
      }
    }
  }, [])

  // console.log( data, 'data')
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }

  const statusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Approved" },
    { value: 3, label: "Removed" },
  ]

  const handleStatusChange = option => {
    validation.setFieldValue("status", option)
  }

  const dropdownToggle = () => {
    setDropdownState(!dropdownState)
  }

  const handleRegrID = id => {
    if (id === 0 || id === null) {
      setRegrID(null)
      setSelectRegulation([{ label: "Select Regulations", value: 0 }])
    } else {
      setRegrID(id)
    }
  }

  // console.log(obIdCheck, "obIdCheck")
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      obId: (data && data.obId) || "",
      obligationName: (data && data.obligationName) || "",
      obligationDesc: (data && data.obligationDesc) || "",
      citationUrl: (data && data.citationUrl) || "",
      citation: (data && data.citation) || "",
      obligationNote: (data && data.obligationNote) || "",
      status: selectedStatus || "",
      regulatorId: (data && data.regulator && data.regulator.id) || 0,
      regulationId: (data && data.regulation && data.regulation.id) || 0,
      //   idx : idx || '',
    },
    validationSchema: Yup.object({
      obligationName: Yup.string().required("Please Enter Obligation Name "),
      obligationDesc: Yup.string().required(
        "Please Enter Obligation Description"
      ),
      // citation: Yup.string().matches(/^[a-zA-Z0-9_ ( ) - . -]+$/, 'Special characters are not allowed').required("Please Enter Citation "),
      citation: Yup.string().required("Please Enter Citation "),
      citationUrl: Yup.string().required("Please Enter Citation Url "),
      // obligationNote: Yup.string().required("Please Enter obligation Note "),
      regulatorId: Yup.string().required("Please Select Regulator"),
      regulationId: Yup.string().required("Please Select Regulation"),
      status: Yup.object().required("Please Select Status"),
    }),
    onSubmit: values => {
      // console.log(values, 'values')
      if (!data) {
        const addData = {
          obId: values.obId,
          regulatorId: values.regulatorId,
          regulationId: values.regulationId,
          obligationName: values.obligationName,
          obligationDesc: values.obligationDesc,
          citation: values.citation,
          obligationNote: values.obligationNote,
          citationUrl: values.citationUrl,
          status: values.status.value,
        }
        console.log(addData, "addData")
        dispatch(onAddNewObligation(addData, props.history))
        // setBtnDisable(true)
      } else {
        // console.log(obIdCheck, values.obId ,"obidcheck")
        var finalObId
        if (values.obId !== obIdCheck) {
          console.log(obIdCheck, values.obId, "obidcheck")
          finalObId = values.obId
        }
        const updateData = {
          id: data && data.id,
          obId: `${values.obId !== obIdCheck ? values.obId : ""}`,
          regulatorId: parseInt(values.regulatorId),
          regulationId: parseInt(values.regulationId),
          obligationName: values.obligationName,
          obligationDesc: values.obligationDesc,
          citation: values.citation,
          citationUrl: values.citationUrl,
          obligationNote: values.obligationNote,
          status: values.status.value,
        }
        dispatch(onUpdateObligation(updateData, props.history,"/test-obligations/all-obligations"))
        // console.log(updateData, 'updateData')
        // setBtnDisable(true)
      }
    },
  })

  // console.log(selectRegulation,"selectRegulation")
  // console.log(validation.values.regulationId,"regulationId")
  const addTitle = "Add Obligation"
  const updateTitle = "Update Obligation"
  //meta title
  document.title = "Obligations"

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Obligations"
            // breadcrumbItem={updateTitle}
            breadcrumbItem={data ? updateTitle : addTitle}
            path="/test-obligations/all-obligations"
          />
          {/* write Html code or structure */}
          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Password Changed Successfully.</Alert>
              ) : null}
            </Col>
          </Row> */}
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    {data && (
                      <Col xs="12" md="6">
                        <div className="mb-3">
                          <Label className="form-label">OB_ID</Label>
                          <Input
                            name="obId"
                            // value={data && data.cfr_Ref}
                            className="form-control"
                            placeholder="Enter OB_ID"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.obId || ""}
                            invalid={
                              validation.touched.obId && validation.errors.obId
                                ? true
                                : false
                            }
                          />
                          {validation.touched.obId && validation.errors.obId ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.obId}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    )}

                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">Obligation Name</Label>
                        <Input
                          name="obligationName"
                          // value={data && data.cfr_Ref}
                          className="form-control"
                          placeholder="Enter Obligation Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.obligationName || ""}
                          invalid={
                            validation.touched.obligationName &&
                            validation.errors.obligationName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.obligationName &&
                        validation.errors.obligationName ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.obligationName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Obligation Description
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.obligationDesc || ""}
                          config={{
                            removePlugins: [
                              "ImageCaption",
                              "ImageStyle",
                              "ImageToolbar",
                              "ImageUpload",
                              "MediaEmbed",
                              "EasyImage",
                              "UploadImage",
                            ],
                          }}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "300px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("obligationDesc", data)
                          }}
                        />
                        {validation.touched.obligationDesc &&
                        validation.errors.obligationDesc ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.obligationDesc}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Citation URL</Label>
                        <Input
                          name="citationUrl"
                          // value={data && data.citationUrl}
                          className="form-control"
                          placeholder="Enter Citation URL"
                          type="url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.citationUrl || ""}
                          invalid={
                            validation.touched.citationUrl &&
                            validation.errors.citationUrl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.citationUrl &&
                        validation.errors.citationUrl ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.citationUrl}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Citation</Label>
                        <Input
                          name="citation"
                          // value={data && data.reg_related_low}
                          className="form-control"
                          placeholder="Enter Citation"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.citation || ""}
                          invalid={
                            validation.touched.citation &&
                            validation.errors.citation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.citation &&
                        validation.errors.citation ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.citation}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Obligation Notes </Label>
                        <Input
                          name="obligationNote"
                          // value={data && data.reg_auth_reg}
                          className="form-control"
                          placeholder="Enter Obligation Notes"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.obligationNote || ""}
                          invalid={
                            validation.touched.obligationNote &&
                            validation.errors.obligationNote
                              ? true
                              : false
                          }
                        />
                        {validation.touched.obligationNote &&
                        validation.errors.obligationNote ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.obligationNote}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Regulator</Label>
                        <Select
                          options={selectRegulator}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="regulatorId"
                          value={selectRegulator.find(
                            option =>
                              option.value === validation.values.regulatorId
                          )}
                          onChange={option => {
                            validation.setFieldValue(
                              "regulatorId",
                              option.value
                            )
                            handleRegrID(option.value)
                            validation.setFieldValue("regulationId", 0) // Reset regulationId to 0 when regulatorId changes
                          }}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.regulatorId &&
                        validation.errors.regulatorId ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.regulatorId}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Regulation</Label>
                        <Select
                          options={selectRegulation}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="regulationId"
                          value={selectRegulation.find(
                            option =>
                              option.value === validation.values.regulationId
                          )}
                          onChange={option =>
                            validation.setFieldValue(
                              "regulationId",
                              option.value
                            )
                          }
                          onBlur={validation.handleBlur}
                          isDisabled={
                            !validation.values.regulatorId ||
                            validation.values.regulatorId === 0
                          }
                        />
                        {validation.touched.regulationId &&
                        validation.errors.regulationId ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.regulationId}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <Label htmlFor="reason">Status</Label>
                      <Select
                        options={statusOptions}
                        styles={customStyles}
                        name="status"
                        value={validation.values.status}
                        onChange={handleStatusChange}
                        onBlur={validation.handleBlur}
                      />
                      {/* jurisdiction */}
                      {validation.touched.status && validation.errors.status ? (
                        <div className="error" style={{ fontSize: "12px" }}>
                          {validation.errors.status}
                        </div>
                      ) : null}
                      {/* <Input
                        name="status"
                        type="select"
                        className="form-select"
                        // onClick={() => setSelecClicked(!selectClicked)}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.status || ""
                        }
                      >
                        <option value={1}>Pending</option>
                        <option value={2}>Approved</option>
                        <option value={3}>Removed</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null} */}
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">Link Disclosure</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.paymentStatus || ""}
                        >
                          <option>DODB000640</option>
                          <option>DODB000640</option>
                          <option>DODB000640</option>
                        </Input>
                        {validation.touched.paymentStatus &&
                        validation.errors.paymentStatus ? (
                          <FormFeedback type="invalid">
                            {validation.errors.paymentStatus}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          De-Link Current Disclosure
                        </Label>
                        <div>
                        <Button
                          className="btn btn-secondary waves-effect waves-light "
                          color="info"
                        >
                          De-Link
                        </Button>
                        </div>
                        
                      </div>
                    </Col>
                  </Row> */}
                  <Row className="mb-3"></Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button
                      disabled={btnDisable}
                      type="submit"
                      className="me-2"
                      color="primary"
                    >
                      Submit
                    </Button>
                    <Link to="/test-obligations/all-obligations">
                      <Button
                        className="btn btn-secondary waves-effect waves-light "
                        color="secondnary"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default ObligationUpdate
