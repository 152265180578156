import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useLocation } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const DisclosureTextUpdate = () => {
  const [dropdownState, setDropdownState] = useState(false)
  const [ckData, setCkData] = useState("")
  const [textcount, settextcount] = useState(0)
  const [textareabadge, settextareabadge] = useState(0)

  console.log(ckData, "ckdata")
  // const { error, success } = useSelector(state => ({
  //     error: state.Profile.error,
  //     success: state.Profile.success,
  //     }));
  const location = useLocation()

  const [data, setData] = useState()
  useEffect(() => {
    if (location.state) {
      setData(location.state.rowData)
    }
  }, [])
  // const [status, setStatus] = useState(data.status === "Active" ? true : false)
  // const [blocked, setBlocked] = useState(data.blocked)
  const dropdownToggle = () => {
    setDropdownState(!dropdownState)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      current: "",
      new: "",
      confirm: "",
      //   idx : idx || '',
    },
    validationSchema: Yup.object({
      current: Yup.string().required("Please Enter Name"),
      new: Yup.string().required("Please Enter Hierarchy Order"),
      //   confirm: Yup.string().required("Please Re-Enter New Password"),
    }),
    onSubmit: values => {
      dispatch(editProfile(values))
    },
  })
  const addTitle = "Add Disclosure Text"
  const updateTitle = "Update Disclosure Text"
  //meta title
  document.title = "Update Disclosure Text| Apogee - Admin"

  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  console.log(data, "dta")
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Disclosure Text"
            // breadcrumbItem={updateTitle}
            breadcrumbItem={data ? updateTitle : addTitle}
          />
          {/* write Html code or structure */}
          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Password Changed Successfully.</Alert>
              ) : null}
            </Col>
          </Row> */}
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12" md="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Disclosure Text Description
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={data && data.reg_desc}
                          onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor)
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                          }}
                        />
                        {validation.touched.new && validation.errors.new ? (
                          <FormFeedback type="invalid">
                            {validation.errors.new}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="5">
                      <div className="mb-3">
                        <Label className="form-label">Sample</Label>
                        <Input
                          name="current"
                          value={data && data.cfr_Ref}
                          className="form-control"
                          placeholder="Enter Sample"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.username || ""}
                          invalid={
                            validation.touched.current &&
                            validation.errors.current
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current &&
                        validation.errors.current ? (
                          <FormFeedback type="invalid">
                            {validation.errors.current}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Sample URL1</Label>
                        <Input
                          name="current"
                          value={data && data.reg_url}
                          className="form-control"
                          placeholder="Enter Sample URL1"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.username || ""}
                          invalid={
                            validation.touched.current &&
                            validation.errors.current
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current &&
                        validation.errors.current ? (
                          <FormFeedback type="invalid">
                            {validation.errors.current}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Sample URL2</Label>
                        <Input
                          name="current"
                          value={data && data.pack_size}
                          className="form-control"
                          placeholder="Enter Sample URL2"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.username || ""}
                          invalid={
                            validation.touched.current &&
                            validation.errors.current
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current &&
                        validation.errors.current ? (
                          <FormFeedback type="invalid">
                            {validation.errors.current}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Sample URL3</Label>
                        <Input
                          name="Units"
                          value={data && data.reg_related_low}
                          className="form-control"
                          placeholder="Enter Sample URL3"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.username || ""}
                          invalid={
                            validation.touched.current &&
                            validation.errors.current
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current &&
                        validation.errors.current ? (
                          <FormFeedback type="invalid">
                            {validation.errors.current}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs="12" md="6">
                      <Label htmlFor="reason">Safe Harbor</Label>
                      <select name="reason" className={"form-control"}>
                        <option value="" selected={true}>
                          All
                        </option>
                        <option value="">Part</option>
                        <option value="">None</option>
                      </select>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">Sample</Label>
                        <Input
                          name="current"
                          value={data && data.cfr_Ref}
                          className="form-control"
                          placeholder="Enter Sample"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.username || ""}
                          invalid={
                            validation.touched.current &&
                            validation.errors.current
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current &&
                        validation.errors.current ? (
                          <FormFeedback type="invalid">
                            {validation.errors.current}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row></Row>
                </div>
                <div className="text-end">
                  <Button type="submit" className="me-2" color="primary">
                    Submit
                  </Button>
                  <Button
                    className="btn btn-secondary waves-effect waves-light "
                    color="secondnary"
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default DisclosureTextUpdate
