import React, { useState } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "../Authentication/CarouselPage"
import lightlogo from "../../assets/images/logo-light.svg"
import loginLogo from "../../assets/images/loginPageLogo.png"
import smallLogo from "../../assets/images/small-logo.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {RedirectOnUnAuthentication} from "../../helpers/helper_function"
const ForgetPassword = () => {
  //meta title
  document.title = "Forget Password  | Apogee - Admin & Dashboard"
  const [apiLoader, setApiLoader] = useState(false)
  const [mailSent, setMailSent] = useState(false)
  const [mailErr, setMailErr] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please Enter Valid Email").min(10,"Email must be at least 10 characters").max(50,"Email must be at most 50 characters").required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      console.log(values)
      // const authToken = JSON.parse(localStorage.getItem('authUser'))
      setApiLoader(true)
      axios
        .post(`${process.env.REACT_APP_BASE_URL}forgot-password`, values)
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // setSuccess(true)
            // toastr.success('Link Successfully Added.')
            setApiLoader(false)
            setMailSent(true)
            setMailErr(false)
            return response.data
          }
        })
        .catch(err => {
          let message
          RedirectOnUnAuthentication(err,navigateTo)
          console.log(err, "error")
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = err.response.data.message
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          setApiLoader(false)
          setMailErr(true)
          setErrMsg(message)
          // return message
          // throw message;
          // toastr.error(message)
        })
    },
  })
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <div className='mask d-flex justify-content-center align-items-center '  style={{ height: '100vh' }}>
            <div id='authenticationMainBox' >
              <div className='m-5' id='authentication-Under-MainBox' >
              <Col>
              <div className="auth-full-page-content p-md-3 p-3">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-5 mb-md-5 mt-3">
                      {/* <Link to="dashboard" className="d-block auth-logo"> */}
                      <img
                        src={loginLogo}
                        alt=""
                        height="50"
                        width="100"
                        className="auth-logo-light"
                      />
                      {/* <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        /> */}
                      {/* </Link> */}
                    </div>
                    <div>
                      <div>
                        <h5 className="text-primary">Reset Password</h5>
                        <p className="text-light">Re-Password with Apogee.</p>
                      </div>

                      <div className="mt-4">
                        {apiLoader && (
                          <div
                            className="container-fluid "
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Spinner className="ms-2" color="primary" />
                          </div>
                        )}

                        {mailSent && (
                          <div
                            className="alert alert-success text-center mb-4"
                            role="alert"
                          >
                            Password Reset instructions sent to your mail.
                          </div>
                        )}

                        {mailErr && (
                          <div
                            className="alert alert-danger text-center mb-4"
                            role="alert"
                          >
                            {errMsg}
                          </div>
                        )}

                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label text-light">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </Button>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p className="text-light">
                            Remember It ?{" "}
                            <Link to="login" className="fw-medium text-primary">
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Themesbrand
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col>
            </div>
            </div>
          </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPassword
