import React, { useState } from 'react';

const useFilterIndicator = () => {
  const [indicatorStates, setIndicatorStates] = useState({});

  const toggleIndicator = (columnId) => {
    setIndicatorStates(prevState => ({
      ...prevState,
      [columnId]: !prevState[columnId]
    }));
  };

  const generateSortingIndicator = (column) => {
    // console.log(column.id , "Here Is The column id")
    if (column && column.id !== "selection" && 
    column.Header !== "Action" && 
    column.id !== 'disclosure_obligations_obligation' && 
    column.id !== 'obligations') {
      const showIndicator = indicatorStates[column.id] || false;
      return showIndicator ? 
        <>
        <span style={{ color: '#556EE6' }}> <i className="fas fa-caret-square-down"></i></span>
        </>
        :
        <span style={{ color: '#556EE6' }}> <i className="fas fa-caret-square-up"></i></span>;
    } else {
      return null;
    }
  };

  return {
    generateSortingIndicator,
    toggleIndicator
  };
};

export default useFilterIndicator;
