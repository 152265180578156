import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
  useRef,
} from "react"
import toastr from "toastr" 
import { useSticky } from "react-table-sticky"
import { Table, Row, Col, Spinner, Card } from "reactstrap" 
import styled from "styled-components"
import axios from "axios"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
 import RegulationScanUpdate from "./subComponent/Update"
import EditSingleRecord from "./subComponent/EditSingleRecord"  
import { CirculareLoader } from "helpers/helper_function"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const RegulationScan = props => {
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([]) 
  const [loader, setLoader] = useState(true)
  // const [loader, setLoader] = useState(true)
  const [searchObject, setSearchObject] = useState({})
  const [scanTypeData, setScanTypeData] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [editListData, setEditListData] = useState({})
  const [editSingleData, setSingleEditListData] = useState({})
  const AllProps = {
    setEditListData: setEditListData,
    editListData: editListData,
    setCurrentPage:setCurrentPage,
    editSingleData:editSingleData,
    setSingleEditListData:setSingleEditListData,
    scanTypeData:scanTypeData
  }
  const fetchData = searchObject => {

    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setLoader(true)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-scan-settings`, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        var message
        setLoader(false)
        console.log("test258456987", err)
        // RedirectOnUnAuthentication(err,navigateTo)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    console.log("page is loading", props.activeTab)
    // fetchData(searchObject)
    if (props.activeTab == 7) {
      fetchData(searchObject)
      setCurrentPage(1)
    }
    // if (checkOrg !== undefined ) {
    //   fetchData(searchObject)
    //   localStorage.removeItem("formattedData")
    // }
  }, [props.activeTab])

  const Test = () => {
    console.log("editListData", editListData)
  }
  const OnEditList = data => {
    console.log("OnEditList",data);
    setCurrentPage(2)
    setScanTypeData(data)
  }
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
     
                        {/* <CardBody>  */}
      {currentPage == 2 ? (
        <RegulationScanUpdate AllProps={AllProps} />
      ) : currentPage == 3 ? (
        <>
        
        <EditSingleRecord AllProps={AllProps} />
        </>
      ) : (
        <>

          <div className="table-responsive">
          <Card className="shadow-none border mb-0">
          {
              reqresData.length === 0? <div className="noRecord">{loader?  <CirculareLoader/>:<h2>No Record</h2>}</div>:""
            }
            <Table className={`${ reqresData.length !== 0?"table align-middle mb-0 table-nowrap" :"d-none"} `}>
              <thead
                className="table-light"
                onClick={() => {
                  console.log(reqresData)
                }}
              >
                <tr>
                  <th scope="col">Type Of Scan</th>
                  {/* <th scope="col">Product Desc</th> */}
                  <th scope="col" colSpan="2">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
           
                {reqresData.map((i, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <th scope="row">
                          {i.type == 1 ? "Production Scan" : "Test Scan"}
                        </th>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              OnEditList(i)
                            }}
                          >
                            <i
                              style={{ cursor: "pointer" }}
                              className="bx bx-edit-alt  font-size-18"
                            ></i>
                            &nbsp; Edit
                          </button>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
            </Card>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default RegulationScan
