import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { reviewUnLike, setReviewUnlikeModal, updateReview } from 'store/slices/authorReviewSlice';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ListGroup from 'react-bootstrap/ListGroup';
import toastr from "toastr"
import { unlikeViewOptions, classifications } from 'helpers/helper_function';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const AuthorUnlikeModal = () => {
  const { reviewUnlikeModal } = useSelector((state) => state.AuthorReview);
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [selectedClassification, setSelectedClassification] = useState(0);
  const [review , setReview] = useState('')
  const [reviews, setReviews] = useState('')
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation()
  const rowData = location.state.rowData;
  const reportId = localStorage.getItem('rowDataId')
  const history = useHistory()

  const {reviewDetail} = useSelector((state) => state.AuthorReview)
  const [reviewData , setReviewData] = useState('')

  useEffect(() => {
    setReviewData(reviewDetail.data ? reviewDetail.data : rowData)
  }, [reviewDetail, rowData]);


  const handleUnlike = useCallback(async () => {
    try {
      const res = await dispatch(reviewUnLike({
        reportId: reportId,
        obId: reviewData.obId,
        reviewStatus: review === 'Pending Author Review' ? 1 : 2,
        reviewReason: selectedClassification,
        review: reviews
      }));
      const response = await dispatch(updateReview({ reportId: reportId }));
      setReview('');
      setSelectedClassification(0)
      toastr.success('New Review status updated successfully!');
      dispatch(setReviewUnlikeModal(false));
      if (response.data === null) {
        history.push('/reg-review-report');
      }
    } catch (error) {
      toastr.error("Something Went Wrong Please Try Later");
      throw (error);
    }
  }, [dispatch, history, reportId, review, reviewData.obId, selectedClassification, reviews]);

  
  const toggle = () => {
    setReview('')
    dispatch(setReviewUnlikeModal(false));
  };

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClassificationClick = (classification) => {
    console.log(classification, 'Here We Are Getting Classification')
    setSelectedClassification(classification);
    // setIsOpen(false); // Close dropdown after selection
  };

  useEffect(() => {
    if (isOpen) {
      setHeight(dropdownRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  
  return (
    <div>
      <Modal
        isOpen={reviewUnlikeModal}
        toggle={toggle}
        className="modal-dialog-centered"
        style={{ borderRadius: '10px', width: '30%' }}
      >
        <ModalHeader toggle={toggle} className="d-flex justify-content-between align-items-center">
          <h5 className="modal-title mt-0" style={{ fontSize: '16px' }}>Update the Status</h5>
        </ModalHeader>
        <ModalBody>
          <p className="mb-0" style={{ fontSize: '14px' }}>Classification Reason</p>
          <div
            style={{
              border: '1px solid #CED4DA',
              borderRadius: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                paddingRight: '10px',
                paddingLeft: '10px'
              }}
              onClick={handleSelectClick}
            >
              <span style={{ fontSize: '14px'}}>
                {/* {selectedClassification || 'Select'} */}
                Select
              </span>
              <i
                style={{ fontSize: '20px', cursor: 'pointer' }}
                className={`mdi mdi-arrow-${isOpen ? 'down' : 'up'}-drop-circle-outline`}
              />
            </div>
            <div
              ref={dropdownRef}
              style={{
                height: height,
                overflow: 'hidden',
                transition: 'height 0.3s ease',
              }}
            >
              <hr className='mt-1 mb-0' style={{ border: '1px solid #EFF2F7'}} />
              <div style={{ fontSize: '14px', overflow:'hidden', 
                 borderBottomLeftRadius:'10px',
                 borderBottomRightRadius:'10px'
               }}>
                {classifications.map((classification) => (
                  <div
                    key={classification.value}
                    onClick={() => handleClassificationClick(classification.value)}
                    style={{
                      paddingLeft: '10px',
                      backgroundColor: selectedClassification === classification.value ? '#F3F4F7' : 'transparent',
                      cursor: 'pointer',
                    }}
                  >
                    <ListGroup.Item style={{ padding: '8px 8px' }}>{classification.label} 
                    <b>{classification.per}</b></ListGroup.Item>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <b>More Options</b>
            <FormControl style={{ width: '100%' }}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="all"
                    name="radio-buttons-group"
                >
                    {unlikeViewOptions.map((option, index) => (
                    <FormControlLabel
                    onChange={(e) => setReview(e.target.value)}
                        key={index}
                        style={{ fontSize: '14px' }}
                        value={option.value}
                        control={<Radio style={{ transform: 'scale(0.8)' }} />}
                        label={<span style={{ fontSize: '15px' }}>{option.label}</span>}
                    />
                    ))}
                </RadioGroup>
            </FormControl>
          </div>
          {/* <div>
            {review === 'Pending Author Review' && (            
                <textarea className="form-control" onChange={(e) => setReviews(e.target.value)} placeholder='Add Review' 
                id="exampleFormControlTextarea1" rows="3"/>
            )}
          </div> */}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            className="px-4 rounded-pill"
            style={{ background: '#B6B6B6', color: 'black', border: 'none' }}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            className="px-4 rounded-pill"
            color="primary"
            onClick={handleUnlike}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AuthorUnlikeModal;
