import { ApiService } from "./ApiService";

export async function apiReviewLike({ reportId, obId, reviewStatus }) {
    return ApiService.fetchData({
      url: `/update-status-review-obligation`,
      method: "post",
      data: { reportId, obId , reviewStatus }, 
    });
  }


  export async function apiReviewUnlike({ reportId, obId, reviewStatus , reviewReason, review }) {
    return ApiService.fetchData({
      url: `/update-status-review-obligation`,
      method: "post",
      data: { reportId, obId , reviewStatus , reviewReason, review }, 
    });
  }

  export async function apiReviewDetail({ reportId }) {
    return ApiService.fetchData({
      url: `/get-next-obligation-for-review`,
      method: "post",
      data: { reportId }, 
    });
  }


  export async function apiStartNewReview({ reportId , status }) {
    return ApiService.fetchData({
      url: `/update-status-review-obligation-report`,
      method: "post",
      data: { reportId , status }, 
    });
  }