import React, { useMemo, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap" 
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteModal from "../../components/Common/DeleteModal"
import { useDispatch } from "react-redux"
import { deleteDisclosure as onDeleteDisclosure } from "../../store/actions"
import { CheckValidValue, FormatedDate } from "helpers/helper_function"

const DisclosureView = props => {
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [deleteDisc, setDeleteDiscl] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const [disclosureAccess, setDisclosureAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDisclosureAccess(authToken && authToken.data.role.rolesPermission[3])
  }, [])

  const toggle = () => setModal(!modal)

  const data = location.state.rowData

  console.log(data, "data")
  let rowData = data
  document.title = "Disclosures"
  // const LightTooltip = withStyles(theme => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }))(Tooltip)

  // const DarkTooltip = withStyles(theme => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.common.black,
  //     color: "rgba(255, 255, 255, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }))(Tooltip)

  const editDisclosure = rowData => {
    props.history.replace({
      pathname: "/disclosures/disclosure_update",
      state: { rowData },
    })
  }
  const onClickDelete = rowData => {
    setDeleteDiscl(rowData)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    setDelete(!is_delete)
    if (deleteDisc.id) {
      dispatch(onDeleteDisclosure(deleteDisc.id, props.history))
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])


  const test = () =>{
    console.log(data)
  }

  const contentRef = useRef(null);
 
  // const setInnerText = (content) => {
  //   // if (contentRef.current) {
  //     const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
  //     contentRef.current.innerHTML = encodedContent;
  //   // }
  // }

  useEffect(() => {
    const encodedContent = data && data?.text ? data.text.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;") : "-";
      contentRef.current.innerHTML = encodedContent;  
  },[data])

  return (
    <React.Fragment>
      <div className="page-content" onClick={test}>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs
            title="Disclosures"
            breadcrumbItem="Disclosure Details"
            path="/disclosures/all-disclosures"
          /> */}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Disclosure Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
                <ul className="nav nav-pills" style={{ justifyContent: "end" }}>
                  {/* <li className="nav-item">
                  <Link to={{ pathname: `${process.env.REACT_APP_ATTACHMENT_URL}/attachment/${data.attachment}` }} target="_blank">
                    <Button
                      // onClick={() => viewAttachMent(rowData)}
                      style={{
                        backgroundColor: "#556ee6",
                        marginLeft: 5,
                        color: "#fff",
                        textTransform: "none",
                      }}
                    >
                      <i className="bx bxs-file-pdf font-size-20 align-middle me-1"></i>
                      
                    </Button>
                    </Link>
                  </li> */}
                  {disclosureAccess &&
                  disclosureAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => editDisclosure(rowData)}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                        Edit
                      </Button>
                    </li>
                  ) : (
                    ""
                  )}
                  {disclosureAccess &&
                  disclosureAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => {
                          onClickDelete(rowData)
                        }}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          marginRight: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                        Delete
                      </Button>
                      <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteOrder}
                        onCloseClick={() => setDeleteModal(false)}
                      />
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <div className="row">
                      <div className="col-12 col">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className=""></h4>
                          <div
                            className="page-title-right"
                            style={{ marginTop: "7px" }}
                          >
                            <ol className="breadcrumb m-0">
                              <li className="breadcrumb-item">
                                <Link to={{pathname:"/disclosures/all-disclosures", state: { rowData: {isClear: true} }}}>
                                  Disclosures
                                </Link>
                              </li>
                              <li
                                className="active breadcrumb-item"
                                aria-current="page"
                              >
                                Disclosure Details
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Row>
                    <Col md={4} className="px-4">
                      <h5 className="mb-sm-0 font-size-18">
                        Disclosure Details
                      </h5>
                    </Col>
                    <Col
                      md={8}
                      className="d-flex flex-wrap gap-2 justify-content-end"
                    >
                      <button className="btn btn-primary">
                        <i className="bx bx-printer font-size-16 align-middle me-1"></i>
                        Print
                      </button>
                    </Col>
                  </Row> */}
                  <div className="table-responsive mt-3 px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>Disclosure ID</th>
                          <td style={{ width: "75%" }}> 
                          {   CheckValidValue({value:data?.disId})?data?.disId :  "-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Disclosure Title</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.disclosureTitle})?data?.disclosureTitle :  "-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Disclosure Description
                          </th>
                          <td style={{ width: "75%" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:   CheckValidValue({value:data?.disclosureDesc})?data?.disclosureDesc :  "-"   ,
                              }}
                            ></div>
                            {/* {data && data.disclosureDesc} */}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Disclosure Type</th>
                          <td style={{ width: "75%" }}>
                            {data && data.disclosureType === 1
                              ? "Text"
                              : data.disclosureType === 2
                              ? "Doc"
                              : "Other"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Disclosure Text
                          </th>
                          <td style={{ width: "75%" }}>
                            {/* <div
                              style={{ whiteSpace: "break-spaces" }}
                              dangerouslySetInnerHTML={{
                                __html:    CheckValidValue({value:data?.text})?data?.text :  "-"    ,
                              }}
                            ></div> */}
                            <div style={{ whiteSpace: "break-spaces" }} ref={contentRef} />
                            {/* {data && data.disclosureDesc} */}
                          </td>
                        </tr>

                        {/* {data &&
                          data.DisclosureTextLanguage &&
                          data.DisclosureTextLanguage.map(item => (
                            <tr key={item.id}>
                              <th style={{ width: "15%" }}>
                                Disclosure Text ({item.language.name})
                              </th>
                              <td style={{ width: "75%" }}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:CheckValidValue({value:item?.text})?item?.text :  "-" ,
                                  }}
                                ></div>
                                
                              </td>
                            </tr>
                          ))} */}

                        <tr>
                          <th style={{ width: "15%" }}>Effective Until Date</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.regEffectiveDate} */}
                            {data && data.effectiveUntilDate
                              ?  FormatedDate(data && data.effectiveUntilDate) 
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample</th>
                          <td style={{ width: "75%" }}>
                            {(data && data.sample) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample URL-1</th>
                          <td style={{ width: "75%" }}>
                            {(data && data.sampleUrl1) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample URL-2</th>
                          <td style={{ width: "75%" }}>
                            {(data && data.sampleUrl2) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample URL-3</th>
                          <td style={{ width: "75%" }}>
                            {(data && data.sampleUrl3) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Disclosure Note</th>
                          <td style={{ width: "75%" }}>
                            {(data && data.disclosureNote) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Safe Harbor</th>
                          <td style={{ width: "75%" }}>
                            {data && data.safeHarbor == 1
                              ? "All"
                              : data.safeHarbor === 2
                              ? "Part"
                              : "None"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Disclaimer</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data && data.disclaimer === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.disclaimer === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Disclosure specific URL
                          </th>
                          <td style={{ width: "75%" }}>
                            {(data && data.disclosureSpecificUrl) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Disclosure specific Citation
                          </th>
                          <td style={{ width: "75%" }}>
                            {(data && data.disclosureSpecificCitation) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Status</th>
                          <td style={{ width: "75%" }}>
                            {data && data.status ? (
                              <Badge
                                className={
                                  data && data.status == 1
                                    ? "font-size-12 badge-soft-warning"
                                    : data.status == 2
                                    ? "font-size-12 badge-soft-success"
                                    : "font-size-12 badge-soft-danger"
                                }
                              >
                                {data && data.status == 1
                                  ? "Pending"
                                  : data.status === 2
                                  ? "Approved"
                                  : "Removed"}
                              </Badge>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        {/* <tr>
                          <th style={{ width: "15%" }}>Language</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.lang === "Yes"
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.lang}
                            </Badge>
                          </td>
                        </tr> */}
                        <tr>
                          <th style={{ width: "15%" }}>
                            Product Group Investment
                          </th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data && data.prodGroupInvestments === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.prodGroupInvestments === true
                                ? "Yes"
                                : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Product Group Desposit
                          </th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.prodGroupDeposits === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.prodGroupDeposits === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Product Group Auto</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.prodGroupAuto === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.prodGroupAuto === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Product Group Mortage
                          </th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.prodGroupMortgage === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.prodGroupMortgage === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Product Group Card</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.prodGroupCard === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.prodGroupCard === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Product Group Other</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.prodGroupOther === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.prodGroupOther === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Life - Marketing</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.lifeMarketing === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.lifeMarketing === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Life - Open Onboard</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.lifeOpenOnboard === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.lifeOpenOnboard === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Life - Servicing</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.lifeServicing === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.lifeServicing === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Life - Close-out End</th>
                          <td style={{ width: "75%" }}>
                            <Badge
                              className={
                                data.lifeCloseOutEnd === true
                                  ? "font-size-12 badge-soft-success"
                                  : "font-size-12 badge-soft-danger"
                              }
                            >
                              {data.lifeCloseOutEnd === true ? "Yes" : "No"}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Communication Types</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.communicationTypes})?data?.communicationTypes :  "-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Last Updated Date</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.regEffectiveDate} */}
                            {data && data.updatedDate
                              ?  FormatedDate(data && data.updatedDate)    
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Attachment</th>
                          <td style={{ width: "75%" }}>
                            {data && data.attachment ? (
                              // <DarkTooltip title="Open Attachment">
                                <Link
                                  to={{
                                    pathname: `${process.env.REACT_APP_ATTACHMENT_URL}/attachment/${data.attachment}`,
                                  }}
                                  target="_blank"
                                >
                                  {/* <Button
                                    // onClick={() => viewAttachMent(rowData)}
                                    style={{
                                      backgroundColor: "#556ee6",
                                      marginLeft: 5,
                                      color: "#fff",
                                      textTransform: "none",
                                    }}
                                  > */}
                                  {
                                   ["doc","docx"].includes(data.attachment.split(".").pop()) ? <i className="bx bxs-file-doc font-size-24"></i> : <i className="bx bxs-file-pdf font-size-24"></i>
                                  }

                                  {/* </Button> */}
                                </Link>  
                              // </DarkTooltip>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Obligations List</h4>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Obligation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    <table className="table table-responsive mt-3 px-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Obligation Name</th>
                          <th scope="col">Obligation Description</th>
                          <th scope="col">Obligation Notes</th>
                          <th scope="col">Citation</th>
                          <th scope="col">Citation URL</th>
                          <th scope="col">Regulator Short Name</th>
                          <th scope="col">Reg Law Short Name</th>
                          <th scope="col">Updated Date</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && 
                          data[ checkOrg === 3?"obligations":"custObligations"]?.map(item => (
                            <tr key={item.id}>
                              <th scope="row">  {  CheckValidValue({value:item?.obId})?item?.obId :  "-"  }</th>
                              <td   > 
                                {  CheckValidValue({value:item?.obligationName})?item?.obligationName :  "-"  }
                              </td>
                              <td>
                                {/* <DarkTooltip title="View Details"> */}
                                  <div style={{ textAlign: "center" }}>
                                    <i
                                      onClick={() => {
                                        setModal(!modal)
                                        setDesc(item.obligationDesc)
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        textAlign: "center",
                                        padding: "0 5px",
                                        borderRadius: "20%",
                                        color: "#556ee6",
                                      }}
                                      className="bx bxs-info-circle  font-size-24"
                                      id="descToolTip"
                                    ></i>
                                    <div
                                      onClick={() => {
                                        setModal(!modal)
                                        setDesc(item.obligationDesc)
                                      }}
                                    ></div>
                                  </div>
                                {/* </DarkTooltip> */}
                              </td>
                              <td >
                                <div   >
 
{  CheckValidValue({value:item?.obligationNote})?item?.obligationNote :  "-"  }
                                </div>
                                
                              </td>
                              <td>{item.citation}</td>
                              <td>
                                {/* {item.citationUrl.substr(0, 15) + "..."} */}
                                {/* <LightTooltip title={item.citationUrl}> */}

                                {  CheckValidValue({value:item?.citationUrl})? <div
                                    
                                    style={{
                                      textDecoration: "underline",
                                      fontSize: "13px",
                                      color: "blue",
                                    }}
                                  >
                                    {item?.citationUrl
                                      .replaceAll("https://", "")
                                      .replaceAll("http://", "")
                                      .replaceAll("www.", "") }
                                  </div> :  "-"  }
                                 
                                {/* </LightTooltip> */}
                              </td>
                              <td>   {  CheckValidValue({value:item?.issuingAuthority})?item?.issuingAuthority :  "-"  }</td>
                              <td>{item.regLaw}</td>
                              <td>
                                {/* { item.updatedDate || "-"} */} 
                                {(item.updatedDate &&
                                FormatedDate(item.updatedDate) 
                                  ) ||
                                  "-"}
                              </td>
                              <td>
                                <Badge
                                  className={
                                    item.status === 2
                                      ? "font-size-11 badge-soft-success"
                                      : item.status == 3
                                      ? "font-size-11 badge-soft-danger"
                                      : "font-size-11 badge-soft-warning"
                                  }
                                >
                                  {item.status == 1
                                    ? "Pending"
                                    : item.status == 2
                                    ? "Approved"
                                    : "Removed"}
                                </Badge>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DisclosureView
