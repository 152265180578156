import { takeEvery, put, call, all, fork } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_REGULATIONS,
  ADD_NEW_REGULATION,
  UPDATE_REGULATION,
  DELETE_REGULATION,
} from "./actionTypes"

import {
  getRegulationsSuccess,
  getRegulationsFail,
  addRegulationSuccess,
  addRegulationFail,
  updateRegulationSuccess,
  updateRegulationFail,
  deleteRegulationSuccess,
  deleteRegulationFail,
} from "./actions"

import {
  getRegulations,
  addNewRegulation,
  updateRegulation,
  deleteRegulation,
} from "../../helpers/fakebackend_helper"
import { ToastError } from "helpers/helper_function"

function* fetchRegulations({ urlData }) {
  // if(urlData){
  const limit = urlData.limit
  const page = urlData.currentPage
  // console.log(limit, page,"page in saga")
  // }
  try {
    const response = yield call(getRegulations, limit, page)
    yield put(getRegulationsSuccess(response))
  } catch (error) {
    console.log(error, "error")
    yield put(getRegulationsFail(error))
  }
}

function* onAddNewRegulation({ payload: { regulation, history } }) {
  console.log(regulation, "regulation")
  try {
    const response = yield call(addNewRegulation, regulation)
    yield put(addRegulationSuccess(response))
    toastr.success("Regulation Successfully Added.")
    
    history.push("/regulations/all-regulations")
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(addRegulationFail(error))
  }
}

function* onUpdateRegulation({ payload: { regulation, history } }) {
  try {
    const response = yield call(updateRegulation, regulation)
    yield put(updateRegulationSuccess(response))
    toastr.success("Regulation Successfully Updated.")
    history.push("/regulations/all-regulations")
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(updateRegulationFail(error))
  }
}

function* onDeleteRegulation({ payload: { regulation, history } }) {
  try {
    const response = yield call(deleteRegulation, regulation)
    console.log("response", response)
    yield put(deleteRegulationSuccess(response))
    toastr.success("Regulation Successfully Deleted.")
    history.push("/regulations/all-regulations")
  } catch (error) {
    console.log("error", error)
     ToastError(error)
    yield put(deleteRegulationFail(error))
  }
}

export function* watchFetchRegulations() {
  yield takeEvery(GET_REGULATIONS, fetchRegulations)
}

function* regulationsSaga() {
  yield all([fork(watchFetchRegulations)])
  yield takeEvery(ADD_NEW_REGULATION, onAddNewRegulation)
  yield takeEvery(UPDATE_REGULATION, onUpdateRegulation)
  yield takeEvery(DELETE_REGULATION, onDeleteRegulation)
}

export default regulationsSaga
