import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { fetchOrgOptions } from "../../../store/actions"
import axios from "axios"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/avatar.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const dispatch = useDispatch()

  const [menu, setMenu] = useState(false)
  // const [orgOptions, setOrgOptions] = useState([])
  const [username, setusername] = useState("")
  const [checkAdmin, setCheckAdmin] = useState(false)
  const [userId, setUserId] = useState("")
  const [checkUser, setCheckUser] = useState(false)
  const [loader, setLoader] = useState(false)
  const [apiCalled, setApiCalled] = useState(false)

  const orgOptions = useSelector(state => state.ObsReducer.orgOptions)

  // console.log(orgOptions, "orgOptions")

  useEffect(() => {
    const states = {
      userId,
      checkAdmin,
      menu,
    }
    dispatch(fetchOrgOptions(userId, checkAdmin, menu))
  }, [dispatch, userId, checkAdmin, menu])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (
      authToken &&
      authToken.data.customerorg.id === 3 &&
      authToken.data.role.id !== 17
    ) {
      setCheckAdmin(true)
    }
  }, [])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (authToken && authToken.data.role.id === 17) {
      setUserId(authToken.data.id)
      setCheckUser(true)
    }
  }, [])

  // console.log(userId, "userId")

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.data.firstName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.data.firstName)
      }
    }
  }, [props.success])

  useEffect(() => {
    const obj2 = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    if (obj2) {
      setusername(obj2 && obj2.name)
    } else if (authToken) {
      setusername(authToken.data.customerorg.companyName)
    }
  }, [username])

  // console.log(username, "user name")

  // useEffect(() => {
  //   if (userId !== "" || checkAdmin && menu) {
  //     if (!optionsFetched) {
  //       setLoader(true)
  //     const authToken = JSON.parse(localStorage.getItem("authUser"))
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_BASE_URL}get-all`,
  //         {
  //           tableName: "customerOrganization",
  //           fieldName: [
  //             "customerOrganization.id",
  //             "customerOrganization.companyName",
  //           ],
  //           isClient: false,
  //           user_id : userId && userId !== undefined ? parseInt(userId) : "",
  //           isApogeeUser:checkAdmin || userId ? true : false ,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken.data.authToken}`,
  //           },
  //         }
  //       )
  //       .then(response => {
  //         // console.log('res',response.data)
  //         if (response.status >= 200 || response.status <= 299) {
  //           setLoader(false)
  //           const optionData = response.data.data.map(option => ({
  //             value: option.id,
  //             label: option.companyName,
  //           }))
  //           setOrgOptions(optionData)
  //           setOptionsFetched(true)
  //         }
  //       })
  //       .catch(err => {
  //         var message
  //         if (err.response && err.response.status) {
  //           setLoader(false)
  //           switch (err.response.status) {
  //             case 404:
  //               message = "Sorry! Network Error(Status:404)"
  //               break
  //             case 500:
  //               message =
  //                 "Sorry! something went wrong, please contact our support team, Status-500   "
  //               break
  //             case 401:
  //               message = "You are not authorized to view this data."
  //               break
  //             default:
  //               message = err[1]
  //               break
  //           }
  //         }
  //         throw message
  //       })
  //     }
  //   }
  // }, [userId,checkAdmin,menu])

  // useEffect(() => {
  //   const cachedOptions = localStorage.getItem("orgOptions");
  //   if (cachedOptions) {
  //     setOrgOptions(JSON.parse(cachedOptions));
  //   } else {
  //   if (userId !== "" && (checkAdmin || menu)) {
  //     setLoader(true);
  //     const authToken = JSON.parse(localStorage.getItem("authUser"));
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_BASE_URL}get-all`,
  //         {
  //           tableName: "customerOrganization",
  //           fieldName: ["customerOrganization.id", "customerOrganization.companyName"],
  //           isClient: false,
  //           user_id: userId && userId !== undefined ? parseInt(userId) : "",
  //           isApogeeUser: checkAdmin || userId ? true : false,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken.data.authToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         if (response.status >= 200 || response.status <= 299) {
  //           setLoader(false);
  //           const optionData = response.data.data.map((option) => ({
  //             value: option.id,
  //             label: option.companyName,
  //           }));
  //           setOrgOptions(optionData);
  //           localStorage.setItem("orgOptions", JSON.stringify(optionData));
  //         }
  //       })
  //       .catch((err) => {
  //         var message;
  //         if (err.response && err.response.status) {
  //           setLoader(false);
  //           switch (err.response.status) {
  //             case 404:
  //               message = "Sorry! Network Error (Status: 404)";
  //               break;
  //             case 500:
  //               message =
  //                 "Sorry! Something went wrong. Please contact our support team (Status: 500)";
  //               break;
  //             case 401:
  //               message = "You are not authorized to view this data.";
  //               break;
  //             default:
  //               message = err[1];
  //               break;
  //           }
  //         }
  //         throw message;
  //       });
  //   }
  // }
  // }, [userId, checkAdmin, menu]);

  // console.log(orgOptions, "orgOptions")

  const handleOrganizationClick = (orgId, name) => {
    // Call the API or perform any action when an organization is clicked
    // Example: axios.get(`${process.env.REACT_APP_BASE_URL}organization/${orgId}`)
    console.log("Clicked organization ID:", orgId)
    const user = {
      orgId,
      name,
    }
    localStorage.setItem("orgID", JSON.stringify(user))
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {checkAdmin || (checkUser && !loader) ? (
            <>
              <Link
                to="/"
                onClick={() => handleOrganizationClick(3, "Apogee")}
                className="dropdown-item"
              >
                {props.t("Apogee")}
              </Link>
              {/* </DropdownItem> */}
              <div className="dropdown-divider" />
              {loader ? (
                <div
                  className="container-fluid"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : (
                <>
                  {orgOptions &&
                    orgOptions.map(option => (
                      <Link
                        to="/"
                        key={option.value}
                        onClick={() =>
                          handleOrganizationClick(option.value, option.label)
                        }
                        className="dropdown-item"
                      >
                        {option.label}
                      </Link>
                    ))}
                </>
              )}
              <div className="dropdown-divider" />
            </>
          ) : null}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
