import React from "react"
import { useLocation } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const DisclosureTextView = () => {
  const location = useLocation()

  const data = location.state.rowData
  console.log(data, "data")
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Disclosures"
            breadcrumbItem="Disclosure Text Details"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={4} className="px-4">
                      <h5 className="mb-sm-0 font-size-18">
                        Disclosure Text Details
                      </h5>
                    </Col>
                    <Col
                      md={8}
                      className="d-flex flex-wrap gap-2 justify-content-end"
                    >
                      <button className="btn btn-primary">
                        <i className="bx bx-printer font-size-16 align-middle me-1"></i>
                        Print
                      </button>
                    </Col>
                  </Row>
                  <div className="table-responsive mt-3 px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>Disclosure ID</th>
                          <td style={{ width: "75%" }}>{data.discl_id}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Text</th>
                          <td style={{ width: "75%" }}>{data.text}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample</th>
                          <td style={{ width: "75%" }}>{data.sample}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample URL 1</th>
                          <td style={{ width: "75%" }}>{data.sample_url1}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample URL 2</th>
                          <td style={{ width: "75%" }}>{data.sample_url2}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Sample URL 3</th>
                          <td style={{ width: "75%" }}>{data.sample_url3}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Safe Harbor</th>
                          <td style={{ width: "75%" }}>{data.safe_harb}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Disclosure Notes</th>
                          <td style={{ width: "75%" }}>{data.discl_notes}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DisclosureTextView
