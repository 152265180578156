import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
  useRef,
} from "react"
import toastr from "toastr"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import { Table, Row, Col, Spinner } from "reactstrap"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import styled from "styled-components"
import FilterModal from "../../components/FilterModal/FilterModal"
// import { TableHeader, Search } from "../Datatable/index"

// eslint-disable-next-line react/display-name

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 10px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 50px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`
const ScanTableContainer = ({
  loader,
  columns,
  data,
  pageCount: customePageCount,
  customPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      // disableRowSelect: true,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useFlexLayout,
    useBlockLayout,
    useSticky,
    useRowSelect
  )

  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const [clearFilterModal, setClearFilterModal] = useState(false)

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  }

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filterID, setFilterID] = useState([])

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }

  // const isAutofillDisabled = column.id === 'your-column-id';
  return (
    <>
      <Fragment>
        <Styles>
          <div style={{ minHeight: "25rem" }}>
            <div
              className="sticky table"
              {...getTableProps()}
              style={{ height: "400px" }}
            >
              <div className="table-light header" style={{ fontWeight: 600 }}>
                {headerGroups.map(headerGroup => (
                  <div
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map(column => (
                      // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                      <div
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="th"
                      >
                        {/* <div> */}
                        <div
                          className="mb-2 mt-0"
                          {...column.getSortByToggleProps()}
                          // onClick={() =>
                          //   handleSort(column)
                          // }
                        >
                          {column.render("Header")}

                          <span style={{ color: "#556ee6" }}>
                            {" "}
                            {/* {generateSortingIndicator(column)} */}
                          </span>
                          <br />
                        </div>
                        {/* {console.log(column.id, 'colims')} */}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              {loader ? (
                <div
                  className="container-fluid mt-5 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : rows.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <div {...row.getRowProps()} className="tr">
                          {row.cells.map(cell => {
                            return (
                              <div
                                key={cell.id}
                                {...cell.getCellProps({
                                  style: {
                                    backgroundColor: "#fff",
                                  },
                                })}
                                className="td"
                              >
                                {cell.render("Cell")}
                              </div>
                            )
                          })}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <Row className="mt-5">
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "150px 0",
                      fontSize: "25px",
                    }}
                  >
                    No Records Found
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Styles>

        {/* </div> */}

        {/* {!fetchError && totalItems ? ( */}

        {/* // ) : null} */}
        {/* <Row className="mt-3 justify-content-md-center justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>
  
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row> */}
      </Fragment>
    </>
  )
}

export default ScanTableContainer
