import { combineReducers } from "redux"
import LayoutReducer from "./layout/reducer"
import LoginReducer from "./auth/login/reducer"
import AccountReducer from "./auth/register/reducer"
import ForgetPasswordReducer from "./auth/forgetpwd/reducer"
import ProfileReducer from "./auth/profile/reducer"
import RegulatorsReducer from "./regulators/reducer"
import RegulationsReducer from "./regulations/reducer"
import DisclosuresReducer from "./disclosures/reducer"
import OligationsReducer from "./obligations/reducer"
import LinksReducer from "./link/reducer"
import ObsReducer from "./OrgOptions/reducer"
import ecommerceReducer from "./e-commerce/reducer"
import chatReducer from "./chat/reducer"
import cryptoReducer from "./crypto/reducer"
import invoicesReducer from "./invoices/reducer"
import JobReducerReducer from "./jobs/reducer"
import contactsReducer from "./contacts/reducer"
import DashboardReducer from "./dashboard/reducer"
import DashboardSaasReducer from "./dashboard-saas/reducer"
import RegChangeReportReducer from './slices/regChangeReportSlice'
import RegReviewSummaryReducer from './slices/regReviewSummarySlice'
import WorkFlowReviewReportReducer from './slices/workFlowReportSlice'
import AuthorReviewReducer from './slices/authorReviewSlice'

const rootReducer = combineReducers({
  Layout : LayoutReducer,
  Login : LoginReducer,
  Account : AccountReducer,
  ForgetPassword : ForgetPasswordReducer,
  Profile : ProfileReducer,
  ecommerce : ecommerceReducer,
  chat : chatReducer,
  crypto : cryptoReducer,
  invoices : invoicesReducer,
  JobReducer : JobReducerReducer,
  contacts : contactsReducer,
  Dashboard : DashboardReducer,
  DashboardSaas : DashboardSaasReducer,
  Regulators: RegulatorsReducer,
  Regulations : RegulationsReducer,
  Obligations : OligationsReducer,
  Disclosures : DisclosuresReducer,
  Links : LinksReducer,
  ObsReducer : ObsReducer,
  RegChangeReport : RegChangeReportReducer,
  RegReviewSummary : RegReviewSummaryReducer,
  workFlowReport : WorkFlowReviewReportReducer,
  AuthorReview : AuthorReviewReducer,
})

export default rootReducer
