import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiReviewLike, apiReviewUnlike } from "services/authorReviewService";
import { apiReviewDetail } from "services/workFlowService";
export const SLICE_NAME = "authorReviewSlice";


export const reviewLike = createAsyncThunk(
  `${SLICE_NAME}/reviewLike`,
  async ({ reportId, obId, reviewStatus }) => {
    try {
      const response = await apiReviewLike({ reportId, obId , reviewStatus });
      return response?.data;
    } catch (error) {
      console.log(error, "From Function");
      throw error;
    }
  }
);

export const reviewUnLike = createAsyncThunk(
  `${SLICE_NAME}/reviewUnlike`,
  async ({ reportId, obId, reviewStatus, reviewReason, review }) => {
    try {
      const response = await apiReviewUnlike({ reportId, obId , reviewStatus, reviewReason, review });
      return response?.data;
    } catch (error) {
      console.log(error, "From Function");
      throw error;
    }
  }
);

export const updateReview = createAsyncThunk(
  `${SLICE_NAME}/reviewUnlike`,
  async ({ reportId }) => {
    try {
      const response = await apiReviewDetail({ reportId });
      return response?.data;
    } catch (error) {
      console.log(error, "From Function");
      throw error;
    }
  }
);


const initialState = {
  loading : false,
  dashboardData : {},
  openAuthor : false,
  reviewUnlikeModal : false, 
  reportsId : null,
  reviewUpdate : false , 
  reviewDetail : [] || null,
  review : '',
  reviewStatus : 1
};

const AuthorReviewSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDashboardData: (state, action) => { 
        state.dashboardData = action.payload
    },
    setOpenAuthorModal : (state, action) => {
        state.openAuthor = action.payload
    },
    setReviewDetail : (state, action) => {
        state.review = action.payload
    },
    setReviewUnlikeModal : (state , action) => {
        state.reviewUnlikeModal = action.payload
    },
    setReviewDeail : (state, action) => {
        state.reviewDetail = action.payload
    }, 
    setReviewUpdate : (state , action) => {
        state.reviewUpdate = action.payload
    },
    setReviewStatus : (state, action) => {
        state.reviewStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateReview.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateReview.fulfilled, (state,action) => {
      state.loading = false;
      state.reviewDetail = action.payload;
    });
    builder.addCase(updateReview.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { 
  setLoading, 
  setReviewDeail, 
  setDashboardData, 
  setOpenAuthorModal, 
  setReviewDetail,  
  setReviewUnlikeModal,
  setReviewUpdate,
  setReviewStatus
} = AuthorReviewSlice.actions;

export default AuthorReviewSlice.reducer;
