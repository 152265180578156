import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Button,
  Label,
  Form,
  BreadcrumbItem,
  Table,
  Badge,
  Card,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { CirculareLoader } from "helpers/helper_function"

const RegulationScanUpdate = ({ AllProps }) => {
  const {
    setEditListData,
    editListData,
    setCurrentPage,
    setSingleEditListData,
    editSingleData,
    scanTypeData,
  } = AllProps

  const test = () => {}

  const OnEdit = data => {
    setCurrentPage(3)
    setSingleEditListData(data)
  }
  const FormFieldData = [
    {
      lableName: "Regulation Short Name ",
      fieldName: "regShortName",
      placeholder: "",
      type: "select",
      value: "",
    },
    {
      lableName: "Regulation URL",
      fieldName: "regUrl",
      placeholder: "",
      type: "select",
      value: "",
    },
    {
      lableName: "Obligation Id",
      fieldName: "obId",
      placeholder: "",
      type: "select",
      value: "",
    },
    {
      lableName: "Citation URL",
      fieldName: "citationUrl",
      placeholder: "",
      type: "select",
      value: "",
    },
    {
      lableName: "Citation",
      fieldName: "citation",
      placeholder: "",
      type: "select",
      value: "",
    },
  ]
  return (
    <>
      <div onClick={test} className="RegulationScanEdit">
        <Row className=" d-flex align-items-center">
          <Col className="col-12 ">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">
                {`REGULATION SCAN`}
              </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0 breadCrumbOL">
                  <BreadcrumbItem>
                    <span
                      className="cusorPointer"
                      onClick={() => setCurrentPage(0)}
                    >
                      {" "}
                      {scanTypeData.type==1?"Production Scan":"Test Scan"} 
                    </span>
                  </BreadcrumbItem>

                  <BreadcrumbItem active>
                    {`Regulation Scan`}
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <br /> 

        {FormFieldData.length === 0 ? (
          <div className="noRecord">
            <h2>No Record</h2>
          
          </div>
        ) : (
          ""
        )}
        <div className={FormFieldData.length === 0 ? "d-none" : "form-group OverFlowAuto"}>
          <Row className="mb-3">
          <Card className="shadow-none border mb-0">
            <Table className="table align-middle mb-0 table-nowrap">
              {/* <Col xs="12" md="12"  > */}
              <thead className="table-light">
                <tr>
                  <th scope="col">Field</th>
                  {/* <th scope="col">Product Desc</th> */}
                  <th scope="col" colSpan="2">
                    Action
                  </th>
                </tr>
              </thead>
              {/* </Col> */}
              <tbody>
                {FormFieldData.map((i, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <th scope="row">
                          <Label className="form-label">{i.lableName}</Label>
                        </th>
                        <td>
                        <Badge className="font-size-15 badge-soft-success" pill>
                        <i 
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      onClick={() => {
                        OnEdit(i)
                      }}
                      // id="edittooltip"
                    ></i></Badge>
                          {/* <button
                            className="btn btn-success"
                            onClick={() => {
                              OnEdit(i)
                            }}
                          >
                            Edit
                          </button> */}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table></Card>

            {/* {FormFieldData.map((i, index) => {
              return (
                <>
                  <Col xs="12" md="12" key={index}>
                    <div className="mb-3 FieldRecordList">
                      <Label className="form-label">{i.lableName}</Label>

                      <button
                        className="btn btn-success"
                        onClick={() => {
                          OnEdit(i)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </Col>
                </>
              )
            })} */}
          </Row>
        </div>
        {/* <Col xs="12" md="12">
          <div className="text-end">
            <Button
              onClick={() => setCurrentPage(0)}
              className="btn btn-secondary waves-effect waves-light "
              color="secondnary"
            >
              Cancel
            </Button>
          </div>
        </Col> */}
      </div>
    </>
  )
}

export default RegulationScanUpdate
