import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import {
  addNewRegulator as onAddNewRegulator,
  updateRegulator as onUpdateRegulator,
} from "store/actions"
import axios from "axios"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { CheckValidValue, NotNull } from "helpers/helper_function"

const RegulatorsUpdate = props => {
  const [dropdownState, setDropdownState] = useState(false)
  const [ckData, setCkData] = useState("")
  const [selectedJuris, setSelectedJuris] = useState()
  const [selectedCntry, setSelectedCntry] = useState()
  const [selectedState, setSelectedState] = useState()
  const [btnDisable, setBtnDisable] = useState(false)

  const dispatch = useDispatch()

  const location = useLocation()

  const [data, setData] = useState()
  useEffect(() => {
    if (location.state) {
      setData(location.state.rowData)
      const cntry = countryOptions.find(
        name =>
        `${NotNull(name?.value)}`.toLowerCase() ===
        `${NotNull(location?.state?.rowData?.country)}`.toLowerCase()
      )
      const forState = stateOptions.find(
        name =>
        `${NotNull(name?.value)}`.toLowerCase() ===
        `${NotNull(location?.state?.rowData?.state)}`.toLowerCase()
      )
      
      if (cntry !== undefined) {
        setSelectedCntry(cntry)
      }
      if (forState !== undefined) {
        setSelectedState(forState)
      }
    }
  }, [])

  const handleSelectChange = option => {
    validation.setFieldValue("jurisdiction", option)
  }

  const stateOptions = [
    { value: "All", label: "All" },
    {
      label: "AL",
      value: "AL",
    },
    {
      label: "AK",
      value: "AK",
    },
    {
      label: "AZ",
      value: "AZ",
    },
    {
      label: "AR",
      value: "AR",
    },
    {
      label: "CA",
      value: "CA",
    },
    {
      label: "CO",
      value: "CO",
    },
    {
      label: "CT",
      value: "CT",
    },
    {
      label: "DE",
      value: "DE",
    },
    {
      label: "FL",
      value: "FL",
    },
    {
      label: "GA",
      value: "GA",
    },
    {
      label: "HI",
      value: "HI",
    },
    {
      label: "ID",
      value: "ID",
    },
    {
      label: "IL",
      value: "IL",
    },
    {
      label: "IN",
      value: "IN",
    },
    {
      label: "IA",
      value: "IA",
    },
    {
      label: "KS",
      value: "KS",
    },
    {
      label: "KY",
      value: "KY",
    },
    {
      label: "LA",
      value: "LA",
    },
    {
      label: "ME",
      value: "ME",
    },
    {
      label: "MD",
      value: "MD",
    },
    {
      label: "MA",
      value: "MA",
    },
    {
      label: "MI",
      value: "MI",
    },
    {
      label: "MN",
      value: "MN",
    },
    {
      label: "MS",
      value: "MS",
    },
    {
      label: "MO",
      value: "MO",
    },
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "NE",
      value: "NE",
    },
    {
      label: "NV",
      value: "NV",
    },
    {
      label: "NH",
      value: "NH",
    },
    {
      label: "NJ",
      value: "NJ",
    },
    {
      label: "NM",
      value: "NM",
    },
    {
      label: "NY",
      value: "NY",
    },
    {
      label: "NC",
      value: "NC",
    },
    {
      label: "ND",
      value: "ND",
    },
    {
      label: "OH",
      value: "OH",
    },
    {
      label: "OK",
      value: "OK",
    },
    {
      label: "OR",
      value: "OR",
    },
    {
      label: "PA",
      value: "PA",
    },
    {
      label: "RI",
      value: "RI",
    },
    {
      label: "SC",
      value: "SC",
    },
    {
      label: "SD",
      value: "SD",
    },
    {
      label: "TN",
      value: "TN",
    },
    {
      label: "TX",
      value: "TX",
    },
    {
      label: "UT",
      value: "UT",
    },
    {
      label: "VT",
      value: "VT",
    },
    {
      label: "VI",
      value: "VI",
    },
    {
      label: "VA",
      value: "VA",
    },
    {
      label: "WA",
      value: "WA",
    },
    {
      label: "WV",
      value: "WV",
    },
    {
      label: "WI",
      value: "WI",
    },
    {
      label: "WY",
      value: "WY",
    },
  ]

  const handleStateChange = option => {
    validation.setFieldValue("state", option)
  }

  const countryOptions = [
    { value: "All", label: "All" },
    { value: "Australia", label: "Australia" },
    { value: "Canada", label: "Canada" },
    { value: "International", label: "International" },
    { value: "Mexico", label: "Mexico" },
    { value: "United States", label: "United States" },
    { value: "UK", label: "UK" },
    { value: "US", label: "US" },
    
  ]

  const handleCountryChange = option => {
    validation.setFieldValue("country", option)
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
    menuList: (base) => ({
      ...base,
      maxHeight: "150px" // your desired height
    })
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      short_name: (data && data.regulatorShortName) || "",
      long_name: (data && data.regulatorLongName) || "",
      regulatorDesc: (data && data.regulatorDesc) || "",
      main_url: (data && data.regulatorMainUrl) || "",
      upd_src: (data && data.regulatorUpdateSource) || "",
      upd_cont: (data && data.regulatorUpdateContact) || "",
      upd_last: (data && data.updatedDate) || "",
      country: selectedCntry || countryOptions[0],
      state: selectedState || "",
      county: (data && data.county) || "",
      // jurisdiction: selectedJuris || "",
      //   idx : idx || '',
    },
    validationSchema: Yup.object({
      short_name: Yup.string().matches(
        /^[a-zA-Z0-9_ ( ) - . -]+$/,
        "Special characters are not allowed"
      ), //.required("Please Enter Short Name"),
      long_name: Yup.string().matches(
        /^[a-zA-Z0-9_ ( ) - . -]+$/,
        "Special characters are not allowed"
      ), //.required("Please Enter Long Name"),
      regulatorDesc: Yup.string(), //.required("Please Enter Desc"),
      main_url: Yup.string(), //.required("Please Enter Main URL"),
      // upd_src: Yup.string().matches(/^[a-zA-Z0-9_ ( ) - . -]+$/, 'Special characters are not allowed').required("Please Enter Update Source"),
      // upd_cont: Yup.string().matches(/^[a-zA-Z0-9_ ( ) - . -]+$/, 'Special characters are not allowed').required("Please Enter Update Contact"),
      // upd_last: Yup.string().required("Please Enter Last Date"),
      country: Yup.object(), //.required("Please Select country"),
      state: Yup.object(), //.required("Please Select state"),
      // jurisdiction: Yup.object().required("Please Select Jurisdiction"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      if (!data) {
        const addData = {
          regulatorShortName: values.short_name,
          regulatorLongName: values.long_name,
          regulatorDesc: values.regulatorDesc,
          regulatorMainUrl: values.main_url,
          regulatorUpdateSource: values.upd_src,
          regulatorUpdateContact: values.upd_cont,
          country: values.country.value,
          county: values.county,
          state: values.state.value,
          // jurisdiction: values.jurisdiction.value,
          status: true,
        }
        // console.log(addData, 'addData')
        dispatch(onAddNewRegulator(addData, props.history))
        // setBtnDisable(true)
        // validation.resetForm();
      } else {
        const updateData = {
          id: data && data.id,
          regulatorShortName: values.short_name,
          regulatorLongName: values.long_name,
          regulatorDesc: values.regulatorDesc,
          regulatorMainUrl: values.main_url,
          regulatorUpdateSource: values.upd_src,
          regulatorUpdateContact: values.upd_cont,
          country: values.country.value,
          state: values.state.value,
          county: values.county,
          // jurisdiction: values.jurisdiction.value,
          status: true,
        }
        dispatch(onUpdateRegulator(updateData, props.history))
        // validation.resetForm();
      }
    },
  })

  const addTitle = "Add Regulator"
  const updateTitle = "Update Regulator"
  //meta title
  document.title = "Regulators"

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Regulators"
            path={{pathname:"/regulators/all-regulators", state: { rowData: {isClear: true} }}}
            // breadcrumbItem={updateTitle}
            breadcrumbItem={data ? updateTitle : addTitle}
          />
          {/* write Html code or structure */}
          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Password Changed Successfully.</Alert>
              ) : null}
            </Col>
          </Row> */}
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulator Short Name
                        </Label>
                        <Input
                          name="short_name"
                          // value={data && data.short_name}
                          className="form-control"
                          placeholder="Enter Regulator Short Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.short_name || ""}
                          invalid={
                            validation.touched.short_name &&
                            validation.errors.short_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.short_name &&
                        validation.errors.short_name ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.short_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulator Long Name
                        </Label>
                        <Input
                          name="long_name"
                          // value={data && data.long_name}
                          className="form-control"
                          placeholder="Enter Regulator Long Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.long_name || ""}
                          invalid={
                            validation.touched.long_name &&
                            validation.errors.long_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.long_name &&
                        validation.errors.long_name ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.long_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulator Description
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.regulatorDesc || ""}
                          config={{
                            removePlugins: [
                              "ImageCaption",
                              "ImageStyle",
                              "ImageToolbar",
                              "ImageUpload",
                              "MediaEmbed",
                              "EasyImage",
                              "UploadImage",
                            ],
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("regulatorDesc", data)
                            // setCkData(data)
                          }}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "300px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                        />
                        {validation.touched.regulatorDesc &&
                        validation.errors.regulatorDesc ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.regulatorDesc}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Regulator Main URL</Label>
                        <Input
                          name="main_url"
                          // value={data && data.main_url}
                          className="form-control"
                          placeholder="Enter Regulator main URL"
                          type="url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.main_url || ""}
                          invalid={
                            validation.touched.main_url &&
                            validation.errors.main_url
                              ? true
                              : false
                          }
                        />
                        {validation.touched.main_url &&
                        validation.errors.main_url ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.main_url}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {/* <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulator Update Source
                        </Label>
                        <Input
                          name="upd_src" 
                          className="form-control"
                          placeholder="Enter Regulator update source"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.upd_src || ""}
                          invalid={
                            validation.touched.upd_src &&
                            validation.errors.upd_src
                              ? true
                              : false
                          }
                        />
                        {validation.touched.upd_src &&
                        validation.errors.upd_src ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.upd_src}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulator Update Contact
                        </Label>
                        <Input
                          name="upd_cont" 
                          className="form-control"
                          placeholder="Enter Regulator update contact"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.upd_cont || ""}
                          invalid={
                            validation.touched.upd_cont &&
                            validation.errors.upd_cont
                              ? true
                              : false
                          }
                        />
                        {validation.touched.upd_cont &&
                        validation.errors.upd_cont ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.upd_cont}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col> */}
                  </Row>

                  <Row className="mb-3">
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Regulator Country</Label>
                        <Select
                          options={countryOptions}
                          name="country"
                          styles={customStyles}
                          // defaultValue={countryOptions.find(({ value }) => value == data && data.country)}
                          value={validation.values.country}
                          onChange={handleCountryChange}
                          onBlur={validation.handleBlur}
                          
                        /> 
                        {/* jurisdiction */}
                        {
                        validation.touched.country &&
                        validation.errors.country ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.country}
                          </div>
                        ) : null
                        }
                        {/* <Input
                        name="country"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.country || ""
                        }
                      >
                        <option>United States</option>
                      </Input>
                      {validation.touched.country && validation.errors.country ? (
                        <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                      ) : null} */}
                      </div>
                    </Col>
                   

                    {/* ddddddddddddddddddddddddddddddddd */}
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Regulator State</Label>
                        <Select
                          options={stateOptions}
                          name="state"
                          styles={customStyles}
                          value={validation.values.state}
                          onChange={handleStateChange}
                          onBlur={validation.handleBlur}
                        /> 
                        {validation.touched.state && validation.errors.state ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.state}
                          </div>
                        ) : null}
                       
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Regulator County</Label>
                        <Input
                          name="county"
                          // value={data && data.short_name}
                          className="form-control"
                          placeholder="Enter Regulator County"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.county || ""}
                          invalid={
                            validation.touched.county &&
                            validation.errors.county
                              ? true
                              : false
                          }
                        />
                        {validation.touched.county &&
                        validation.errors.county ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.county}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {/* <Col xs="12" md="4">
                    <div className="mb-3">
                      <Label className="form-label">Jurisdiction</Label>
                      <Select
                        options={jurisdictionOptions}
                        defaultValue={selectedJuris && selectedJuris}
                        name="jurisdiction"
                        styles={customStyles}
                        value={validation.values.jurisdiction}
                        onChange={handleSelectChange}
                        onBlur={validation.handleBlur}
                      />
                      {validation.touched.jurisdiction && validation.errors.jurisdiction ? (
                        <div className="error" style={{fontSize: '12px'}}>{validation.errors.jurisdiction}</div>
                      ) : null}
                    </div>
                    </Col> */}
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button
                      disabled={btnDisable}
                      type="submit"
                      className="me-2"
                      color="primary"
                    >
                      Submit
                    </Button>
                    <Link to="/regulators/all-regulators">
                      <Button
                        className="btn btn-secondary waves-effect waves-light "
                        color="secondnary"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default RegulatorsUpdate
