import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap" 
import DeleteModal from "../../../components/Common/DeleteModal"
import { deleteRegulation as onDeleteRegulation } from "../../../store/actions"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { CheckValidValue, FormatedDate } from "helpers/helper_function"

const RegulationView = props => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const dispatch = useDispatch()
  const [delRegulation, setDelRegulation] = useState(null)
  const [regulationAccess, setRegulationAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setRegulationAccess(authToken && authToken.data.role.rolesPermission[1])
  }, [])

  const location = useLocation()
  document.title = "Regulations"
  const data = location.state.rowData
  let rowData = data
  console.log(data, "data")

  const editRegulation = rowData => {
    props.history.replace({
      pathname: "/regulations/regulation_update",
      state: { rowData },
    })
  }
  const onClickDelete = rowData => {
    setDelRegulation(rowData)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    setDelete(!is_delete)
    const deleteId = delRegulation.id
    if (deleteId) {
      dispatch(onDeleteRegulation(delRegulation.id, props.history))
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Regulation Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
                <ul className="nav nav-pills" style={{ justifyContent: "end" }}>
                  {regulationAccess &&
                  regulationAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => editRegulation(rowData)}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                        Edit
                      </Button>
                    </li>
                  ) : (
                    ""
                  )}
                  {regulationAccess &&
                  regulationAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => {
                          onClickDelete(rowData)
                        }}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          marginRight: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                        Delete
                      </Button>
                      <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteOrder}
                        onCloseClick={() => setDeleteModal(false)}
                      />
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <div className="row">
                      <div className="col-12 col">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className=""></h4>
                          <div
                            className="page-title-right"
                            style={{ marginTop: "7px" }}
                          >
                            <ol className="breadcrumb m-0">
                              <li className="breadcrumb-item">
                                <Link to={{pathname:"/regulations/all-regulations", state: { rowData: {isClear: true} }}}>
                                  Regulations
                                </Link>
                              </li>
                              <li
                                className="active breadcrumb-item"
                                aria-current="page"
                              >
                                Regulation Details
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Row>
                        <Col md={4} className="px-4">
                        <h5 className="mb-sm-0 font-size-18">
                        Regulation Details
                      </h5>
                        </Col> */}
                  {/*<Col md={8} className="d-flex flex-wrap gap-2 justify-content-end">*/}
                  {/*    <button className="btn btn-primary">*/}
                  {/*    <i className="bx bx-printer font-size-16 align-middle me-1"></i>*/}
                  {/*        Print*/}
                  {/*    </button>*/}
                  {/*</Col>*/}
                  {/* </Row> */}
                  <div className="table-responsive table-striped px-0">
                    <Table className="px-0 table-striped">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>Regulation ID</th>
                          <td style={{ width: "75%" }}>{   CheckValidValue({value:data?.regId})?data?.regId:"-"  }</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulator</th>
                          <td style={{ width: "75%" }}>
                             

{   CheckValidValue({value:data?.regulator?.regulatorShortName})?data?.regulator?.regulatorShortName:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>CRF Ref</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.cfrRef})?data?.cfrRef:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Law Short Name</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.regShortName})?data?.regShortName:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Long Name</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.regLongName})?data?.regLongName:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Description</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.regDescription} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html:  CheckValidValue({value:data?.regDescription})?data?.regDescription:"-"    ,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Link</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data.regUrl})?data.regUrl:"-"  }
                          </td>
                        </tr>
                    
                        <tr>
                          <th style={{ width: "15%" }}>Reg Related Law</th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.regRelatedLaw})?data?.regRelatedLaw:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Effective Date</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.regEffectiveDate} */}
                            {data && data.regEffectiveDate
                              ?
                              
                              FormatedDate(data.regEffectiveDate) 
                              
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Reg Compliance Guide URL
                          </th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.regComplianceGuideUrl})?data?.regComplianceGuideUrl:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulation last Update Date</th>
                          <td style={{ width: "75%" }}>
                            {/* {(data && data.updatedDate)?data.updatedDate:'-'} */}
                            {data && data.updatedDate
                              ? 
                              FormatedDate(data.updatedDate) 
                              
                              : "-"}
                              {/* - */}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Regulator Short Name
                          </th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.regAuthoringRegulator})?data?.regAuthoringRegulator:"-"  }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Reg Primary Supervisor
                          </th>
                          <td style={{ width: "75%" }}> 
                            {   CheckValidValue({value:data?.regPrimarySupervisor})?data?.regPrimarySupervisor:"-"  }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegulationView
