import { takeEvery, put, call, all, fork } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_DISCLOSURES,
  ADD_NEW_DISCLOSURE,
  UPDATE_DISCLOSURE,
  DELETE_DISCLOSURE,
} from "./actionTypes"

import {
  getDisclosuresSuccess,
  getDisclosuresFail,
  addDisclosureSuccess,
  addDisclosureFail,
  updateDisclosureSuccess,
  updateDisclosureFail,
  deleteDisclosureSuccess,
  deleteDisclosureFail,
  setLoading,
} from "./actions"

import {
  getDisclosures,
  addNewDisclosure,
  updateDisclosure,
  deleteDisclosure,
} from "../../helpers/fakebackend_helper"
import { ToastError } from "helpers/helper_function"
// import { useDispatch } from "react-redux"

// const dispatch = useDispatch()

function* fetchDisclosures({ urlData }) {
  // if(urlData){
  const limit = urlData.limit
  const page = urlData.currentPage
  // console.log(limit, page,"page in saga")
  // }
  try {
    const response = yield call(getDisclosures, limit, page)
    yield put(getDisclosuresSuccess(response))
  } catch (error) {
    console.log(error, "error")
    yield put(getDisclosuresFail(error))
  }
}

function* onAddNewDisclosure({ payload: { disclosure, history } }) {
  // console.log(disclosure, "disclosure")
  try {
    const response = yield call(addNewDisclosure, disclosure)
    yield put(addDisclosureSuccess(response))
    toastr.success("Disclosure Successfully Added.")
    history.push("/disclosures/all-disclosures")
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(addDisclosureFail(error))
  }
}

function* onUpdateDisclosure({ payload: { disclosure, history } }) {
  try {
    yield put(setLoading(true)); // Dispatch action to set loading state to true
    const response = yield call(updateDisclosure, disclosure);
    yield put(updateDisclosureSuccess(response));
    toastr.success("Disclosure Successfully Updated.");
    history.push("/disclosures/all-disclosures");
  } catch (error) {
    console.log(error, "error");
    ToastError(error);
    yield put(updateDisclosureFail(error));
  } finally {
    yield put(setLoading(false)); // Dispatch action to set loading state to false
  }
}

function* onDeleteDisclosure({ payload: { disclosure, history } }) {
  try {
    const response = yield call(deleteDisclosure, disclosure)
    console.log("response", response)
    yield put(deleteDisclosureSuccess(response))
    toastr.success("Disclosure Successfully Deleted.")
    // history.push('/disclosures/all-disclosures')
  } catch (error) {
    console.log("error", error)
     ToastError(error)
    yield put(deleteDisclosureFail(error))
  }
}

export function* watchFetchDisclosures() {
  yield takeEvery(GET_DISCLOSURES, fetchDisclosures)
}

function* disclosuresSaga() {
  yield all([fork(watchFetchDisclosures)])
  yield takeEvery(ADD_NEW_DISCLOSURE, onAddNewDisclosure)
  yield takeEvery(UPDATE_DISCLOSURE, onUpdateDisclosure)
  yield takeEvery(DELETE_DISCLOSURE, onDeleteDisclosure)
}

export default disclosuresSaga
