import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import toastr from "toastr"
import Flatpickr from "react-flatpickr"
import { Select, MenuItem, FormControl } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useSticky } from "react-table-sticky"
import Tooltip from "@material-ui/core/Tooltip"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Filter, DefaultColumnFilter } from "../../../components/Common/filters"
import Pagination from "../../../components/Common/Pagination"
import styled from "styled-components"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { format } from "date-fns"
import { Popper } from "@material-ui/core"
import FilterModal from "../../../components/FilterModal/FilterModal"
import axios from "axios"
import TableHeader from "components/Common/TableHeader"
import ReactDiffViewer from "react-diff-viewer"
import Prism from "prismjs"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { constant, map } from "lodash" 
import HtmlDiff from "htmldiff-js"
import PDFGenerator from "./PdfComponent"
import "./diff.css"
import { CheckValidValue, DisplayJustifyCenter, DownloadExelFrom, FormatedDate, RedirectOnUnAuthentication, RemoveMatchingKeysByValues, RemoveSpaces, RenameKeysOfObject, SeparateObjectByValueType, UpdateColumnNameRelationFeild, UpdateVariable, ValidCellValue, convertObjectValuesToArray, encryptData, handleNullAndUndefinedOfArray, hexHtmlToString, trimLeft } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
// import $ from "jquery";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const exportToExcel = rowData => {
  const customizedData = rowData.map(row => {
    const createdDate = FormatedDate(row?.obligation_updatedDate) 
    console.log(row, "row")
    return {
      // ...row,
      contentId: row?.disclosure_obligations_obligation_contentId || "-",
      obId: row?.disclosure_obligations_obligation_obId||"-",
      obligationName: row?.obligation_obligationName || "-",
      obligationDesc: ValidCellValue({ value: row?.obligation_obligationName ||"-" }),
      citationUrl: row?.obligation_citationUrl ||"-",
      issuingAuthority: row?.obligation_issuingAuthority ||"-",
      regLaw: row?.obligation_regLaw ||"-",
      citation: row?.obligation_citation||"-",
      disId: row?.disclosure_obligations_obligation_disId || "-",
      disclosureTitle: row?.disclosure_disclosureTitle || "-",
      disclosureDesc: ValidCellValue({
        value: row?.disclosure_disclosureDesc || "-",
      }),
      text: ValidCellValue({ value: row?.disclosure_text || "-" }),
      disclosureSpecificCitation:
        row?.disclosure_disclosureSpecificCitation || "-",
      ["Prior Updated Date"]: createdDate,
      ["Prior Citation Content"]: row?.newTestObligationReport_oldobligationDesc
        .replace(/<[^>]+>|&#xA7;/g, "")
        .substr(0, 31500),
      ["New Citation Content"]: row?.obligation_obligationDesc
        .replace(/<[^>]+>|&#xA7;/g, "")
        .substr(0, 31500),
    }
  })
  const filteredData = customizedData.map(
    ({ id, obligation, citationCount, ...rest }) => rest
  )
  console.log(customizedData, "customizedData")
  const arrangedColumns = [
    "contentId",
    "obId",
    "obligationName",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "regLaw",
    "citation",
    "disId",
    "disclosureTitle",
    "disclosureDesc",
    "text",
    "disclosureSpecificCitation",
    // "prevUpdatedDate",
    // "oldobligationDesc",
    // "obligationDesc",
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Content ID",
        "Obligation ID",
        "Obligation Name",
        "Obligation Description",
        "Citation URL",
        "Regulator Short Name",
        "Reg Law Short Name",
        "Citation",
        "Disclosure ID",
        "Disclosure Title",
        "Disclosure Description",
        "Text",
        "Disclosure-specific Citation",
        // "Prior Updated Date",
        // "Prior Citation Content",
        // "New Citation Content",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Disclosure Review Report.xlsx")
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :first-child {
        .td {
          padding-top: 10px;
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      padding-left: 10px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`
const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 110,
  },

  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}

// const highlightSyntax = str => (
//   <pre
//     style={{ display: 'inline' }}
//     dangerouslySetInnerHTML={{
//       __html: Prism.highlight(str, Prism.languages.javascript),
//     }}
//   />
// );

const TableContainer = ({
  loader,
  columns,
  reqresData,
  filterArray,
  setSortBy,
  handleSort,
  queryId,
  searchObject,
  downloadAccess,
  handleRender,
  props,
  customePageSize,
  setCustomePageSize,
  fetchData,
  dateQuery,
  setSearchObject,
  setCurrentPage,
  setFilterArray,
  data,
  pageCount: customePageCount,
  customPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      // disableRowSelect: true,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useFlexLayout,
    useBlockLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,

          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div style={{ backgroundColor: "#fff" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [btnLoaderExcel, setBtnLoaderExcel] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const [localStorageFilter, setLocalStorageFilter] = useState({})
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }

  const handleExportExcel = () => {

    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoaderExcel(true)

    const id_arr = map(selectedFlatRows, "original.newTestObligationReport_id")
    const ids = id_arr.toString()
    const obligationIds_arr = map(selectedFlatRows, "original.obligation_id")
    const obligationIds = obligationIds_arr.toString()
    console.log(  "filterArray",filterArray)
    let disclosureIds_arr = map(selectedFlatRows, "original.disclosure_id")
    disclosureIds_arr = disclosureIds_arr.filter(id => id != null)
    const disclosureIds = disclosureIds_arr.toString()
 

    const params = { 
      "contentId":"disclosure_obligations_obligation.contentId",
      "obId":"obligation.obId",
      "obligationName":"obligation.obligationName",
      "obligationDesc":"obligation.obligationDesc",
      "citationUrl":"obligation.citationUrl",
      "issuingAuthority":"obligation.issuingAuthority",
      "regLaw":"obligation.regLaw",
      "citation":"obligation.citation",
      "disId":"disclosure.disId",
      "disclosureTitle":"disclosure.disclosureTitle",
      "disclosureDesc":"disclosure.disclosureDesc",
      "text":"disclosure.text",
      "disclosureSpecificCitation":"disclosure.disclosureSpecificCitation",
      // Add more mappings as needed
  };
    const rawObject =    {
      ...searchObject,
      ...filterArray,
      reportId: queryId,
      ids: ids,
      obligationIds,
      disclosureIds,
    }
    const data = RenameKeysOfObject({object:rawObject, params:params})

    // setBtnLoaderExcel(false)
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/export-excel-new-test-obligation-report`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
          responseType:'blob'
        },
      )
      .then(response => {
        // exportToExcel(response.data.data)
        DownloadExelFrom({responseData:response.data,fileTitle: "Disclosure Review Report"})
        setBtnLoaderExcel(false)
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        setBtnLoaderExcel(false)
      })
  }

  useEffect(() => {
    console.log(selectedFlatRows, "selectedFlatRows")
  },[selectedFlatRows])

  useEffect(() => {
    const data = localStorage.getItem("ti-updated-obli-report-link");

    // Function to check if a value is valid
    const checkValidValue = ({ value }) => {
        return value && value !== "undefined" && value !== "null";
    };

    if (checkValidValue({ value: data })) {
        setLocalStorageFilter(checkValidValue({ value: data }));
    }

    let finalData;
    console.log(
        props?.location?.state?.rowData?.clear,
        "props?.location?.state?.rowData?.clear"
    );

    if (data != null && !props?.location?.state?.rowData?.clear) {
        finalData = JSON.parse(data);
        let modifiedObject = {};

        console.log(finalData, "finalData");

        for (let key in finalData) {
            if (finalData.hasOwnProperty(key)) {
                let newKey;
                let selectedkey;

                // if (
                //     key === "obligation.obId"
                // ) {
                //     newKey = "obligation_obId ";
                //     selectedkey = "obId";
                // } else if (
                //     key === "newErrorTestObligationReport.regulationSite" ||
                //     key === "regulationSite"
                // ) {
                //     newKey = "regulationSite";
                //     selectedkey = "regulationSite";
                // } else if (
                //     key === "newErrorTestObligationReport.citationUrl" ||
                //     key === "citationUrl"
                // ) {
                //     newKey = "citationUrl";
                //     selectedkey = "citationUrl";
                // } else if (key === "newErrorTestObligationReport.error" || key === "error") {
                //     newKey = "error";
                //     selectedkey = "error";
                // } else {
                    newKey = key.replace(/\./g, "_")
                    selectedkey = key;
                // }

                if (Array.isArray(finalData[key])) {
                    modifiedObject[newKey] = finalData[key][0];
                } else if (typeof finalData[key] === "string") {
                    modifiedObject[newKey] = finalData[key];
                } else {
                    console.log(`Value for key ${key} is not a valid string.`);
                }

                // Check if selectedkey matches any key in selectedFilterArray
                const selectedFilterArray = JSON.parse(localStorage.getItem("selectedFilterArray"));
                if (selectedFilterArray && selectedFilterArray.hasOwnProperty(selectedkey)) {
                    delete modifiedObject[newKey];
                }
            }
        }

        console.log(modifiedObject, "modifiedObject");
        setAutoEmpty(modifiedObject);
    }
}, [localStorage.getItem("ti-updated-obli-report-link"), props.location.state.rowData.clear]);

  // useEffect(() => {
  //   const data = localStorage.getItem("ti-updated-obli-report-link")
  //   let finalData
  //   if (data != null && !props?.location?.state?.rowData?.clear) {
  //     finalData = JSON.parse(data)
  //     let modifiedObject = {}

  //     for (let key in finalData) {
  //       if (finalData.hasOwnProperty(key)) {
  //         let newKey = key.replace(/\./g, "_")
  //         if (Array.isArray(finalData[key])) {
  //           // If it's an array, take the first element
  //           modifiedObject[newKey] = finalData[key][0];
  //         } else if (typeof finalData[key] === "string") {
  //           // If it's already a string, keep it as it is
  //           modifiedObject[newKey] = finalData[key];
  //         } else {
  //           console.log(`Value for key ${key} is not a valid string.`);
  //         }
  //         // modifiedObject[newKey] = finalData[key]
  //       }
  //     }

  //     setAutoEmpty(modifiedObject)
  //   }
  // }, [localStorage.getItem("ti-updated-obli-report-link")])

  const handleExport = () => {
    console.log("export function",selectedFlatRows)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)

    const id_arr = map(selectedFlatRows, "original.newTestObligationReport_obId")
    const ids = id_arr.toString()
    console.log(selectedFlatRows, "selectedFlatRows")

    const data = {
      ...searchObject,
      ...filterArray,
      reportId: queryId,
      isReviewReport:1,
      ids: ids,
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}get-test-download-report`, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
        responseType: "blob",
      })
      // .then(response => {
      //   // exportToExcel(response.data.data)
      //   setBtnLoader(false)
      //   response.json()
      // })
      .then(response => {
        setBtnLoader(false)
        toastr.success("Report Downloaded Successfully.")
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          "Disclosure Review Report.pdf"
        ) //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        RedirectOnUnAuthentication(error,navigateTo)
        setBtnLoader(false)
      })
  }

  const handleSearchQueryChange = (fldName, value) => {
    console.log(fldName, value, "check handleSearchQueryChange and value")

    const authToken = JSON.parse(localStorage.getItem("authUser"))

 
  // Example usage:

  // const fields = [
  //     ["obligation_obligationDesc", "columnName1", "relationFeild1"],
  //     ["obligation_obligationName", "columnName2", "relationFeild2"],

  //     ["obligation_citationUrl", "columnName3", "relationFeild3"]
  //     ["obligation_citation", "columnName1", "relationFeild1"],
  //     ["obligation_regLaw", "columnName2", "relationFeild2"],
  //     ["newObligationReport_prevUpdatedDate", "columnName3", "relationFeild3"]

  //     ["obligation_obId", "columnName1", "relationFeild1"], 
  //     ["disclosure_obligations_obligation_contentId", "columnName2", "relationFeild2"],
  //     ["obligation_issuingAuthority", "columnName3", "relationFeild3"]
  //     ["disclosure_disId", "columnName1", "relationFeild1"],
      
  //     ["disclosure_disclosureTitle", "columnName2", "relationFeild2"],
  //     ["disclosure_disclosureDesc", "columnName3", "relationFeild3"]
  //     ["disclosure_text", "columnName1", "relationFeild1"],
  //     ["disclosure_disclosureSpecificCitation", "columnName2", "relationFeild2"], 
  // ]; 
//  UpdateColumnNameRelationFeild({arr, fldName,relationField,columnName})

    let columnName,
      relationFeild = ""
    if (fldName === "obligation_obligationDesc") {
      columnName = "obligationDesc"
      relationFeild = "obligation"
    } else if (fldName === "obligation_obligationName") {
      columnName = "obligationName"
      relationFeild = "obligation"
    } else if (fldName === "obligation_citationUrl") {
      columnName = "citationUrl"
      relationFeild = "obligation"
    } else if (fldName === "obligation_citation") {
      columnName = "citation"
      relationFeild = "obligation"
    } else if (fldName === "obligation_regLaw") {
      columnName = "regLaw"
      relationFeild = "obligation"
    } else if (fldName === "newObligationReport_prevUpdatedDate") {
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)

      setSearchQuery(prev => ({ ...prev, ["prevUpdatedDate"]: formattedDate }))
    } else if (fldName === "obligation_obId") {
      columnName = "obId"
      relationFeild = "obligation"
      // const updatedSearchQuery = {
      //   ...searchQuery,
      //   ["obligation_obId"]: value,
      // }
      // setSearchQuery(updatedSearchQuery)
    } else if (fldName === "disclosure_obligations_obligation_contentId") {
      columnName = "contentId"
      relationFeild = "disclosure_obligations_obligation"
    }  else if (fldName === "obligation_issuingAuthority") {
      columnName = "issuingAuthority"
      relationFeild = "obligation"
    } else if (fldName === "disclosure_disId") {
      columnName = "disId"
      relationFeild = "disclosure"
    } else if (fldName === "disclosure_disclosureTitle") {
      columnName = "disclosureTitle"
      relationFeild = "disclosure"
    } else if (fldName === "disclosure_disclosureDesc") {
      columnName = "disclosureDesc"
      relationFeild = "disclosure"
    } else if (fldName === "disclosure_text") {
      columnName = "text"
      relationFeild = "disclosure"
    } else if (fldName === "disclosure_disclosureSpecificCitation") {
      columnName = "disclosureSpecificCitation"
      relationFeild = "disclosure"
    } else {
      columnName = fldName
    }

    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []

    const requestData ={
      tableName: "newTestObligationReport",
      reportId: queryId,
      feildName: columnName,//UpdateVariable update table name in particular case
      searchValue: value ? value : "",
      relationFeild: relationFeild,
      ids: idsArray,
      // relationFeild:
      //   columnName === "obligationName" ||
      //   columnName === "citationUrl" ||
      //   fldName === "obligation.obligationDesc" ||
      //   fldName === "obligation.regLaw" ||
      //   fldName === "obligation.citation" ||
      //   fldName === "oldobligationDesc"
      //     ? "obligation"
      //     : "",

      // isUnlink: false,
      isReview: 1,
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log(response,  "re ponse")
        if (fldName != "prevUpdatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [fldName]: response.data.data.map(item => item[columnName]),
          }))
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnName]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => {

    const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);
    if (optionExists || value === "") {
      console.log("selected from suggestion box")
      
      let updatedValue
    let columnName
    // console.log(columnId, typeof(columnId));
    // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
    if (value == "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
      // console.log("**************");
    } else {
      updatedValue = value
    }

    if (columnId == "obligation_obId") {
      columnName = "obligation.obId"
      setAutoEmpty(prev => ({ ...prev, ["obligation_obId"]: updatedValue }))
    } else if (columnId == "obligation_obligationName") {
      columnName = "obligation.obligationName"
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_obligationName"]: updatedValue,
      }))
    } else if (columnId == "obligation_obligationDesc") {
      columnName = "obligation.obligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_obligationDesc"]: updatedValue,
      }))
    } else if (columnId == "obligation_citationUrl") {
      columnName = "obligation.citationUrl"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_citationUrl"]: updatedValue,
      }))
    } else if (columnId == "obligation_citation") {
      columnName = "obligation.citation"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["obligation_citation"]: updatedValue }))
    } else if (columnId == "obligation_regLaw") {
      columnName = "obligation.regLaw"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["obligation_regLaw"]: updatedValue }))
    } else if (columnId == "oldobligationDesc") {
      columnName = "newObligationReport.oldobligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue }))
    } else if (columnId == "newObligationReport_prevUpdatedDate") {
      // Set the value to false when selecting "No"
      // console.log(value, 'value')

      const newDate = new Date(value) 
      const formattedDate = format(newDate, "yyyy-MM-dd")
      updatedValue = formattedDate
      columnName = "newObligationReport.prevUpdatedDate"
    } else if (columnId == "disclosure_obligations_obligation_contentId") {
      columnName = "disclosure_obligations_obligation.contentId"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_obligations_obligation_contentId"]: updatedValue,
      }))
    } else if (columnId == "obligation_issuingAuthority") {
      columnName = "obligation.issuingAuthority"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_issuingAuthority"]: updatedValue,
      }))
    } else if (columnId == "disclosure_disId") {
      columnName = "disclosure.disId"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["disclosure_disId"]: updatedValue }))
    } else if (columnId == "disclosure_disclosureTitle") {
      columnName = "disclosure.disclosureTitle"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_disclosureTitle"]: updatedValue,
      }))
    } else if (columnId == "disclosure_disclosureDesc") {
      columnName = "disclosure.disclosureDesc"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_disclosureDesc"]: updatedValue,
      }))
    } else if (columnId == "disclosure_text") {
      columnName = "disclosure.text"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["disclosure_text"]: updatedValue }))
    } else if (columnId == "disclosure_disclosureSpecificCitation") {
      columnName = "disclosure.disclosureSpecificCitation"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_disclosureSpecificCitation"]: updatedValue,
      }))
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
      columnName = columnId
    }

    // console.log(columnName,updatedValue, "updated alue")
    const updatedSearchQuery = { ...searchQuery, [columnName]: [updatedValue] }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    } else {
      let updatedValue
    let columnName
    // console.log(columnId, typeof(columnId));
    // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
    if (value == "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
      // console.log("**************");
    } else {
      updatedValue = value
    }

    if (columnId == "obligation_obId") {
      columnName = "obligation.obId"
      setAutoEmpty(prev => ({ ...prev, ["obligation_obId"]: updatedValue }))
    } else if (columnId == "obligation_obligationName") {
      columnName = "obligation.obligationName"
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_obligationName"]: updatedValue,
      }))
    } else if (columnId == "obligation_obligationDesc") {
      columnName = "obligation.obligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_obligationDesc"]: updatedValue,
      }))
    } else if (columnId == "obligation_citationUrl") {
      columnName = "obligation.citationUrl"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_citationUrl"]: updatedValue,
      }))
    } else if (columnId == "obligation_citation") {
      columnName = "obligation.citation"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["obligation_citation"]: updatedValue }))
    } else if (columnId == "obligation_regLaw") {
      columnName = "obligation.regLaw"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["obligation_regLaw"]: updatedValue }))
    } else if (columnId == "oldobligationDesc") {
      columnName = "newObligationReport.oldobligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue }))
    } else if (columnId == "newTestObligationReport_prevUpdatedDate") {
      // Set the value to false when selecting "No"
      // console.log(value, 'value')

      const newDate = new Date(value) 
      const formattedDate = format(newDate, "yyyy-MM-dd")
      updatedValue = formattedDate
      columnName = "newTestObligationReport.prevUpdatedDate"
    } else if (columnId == "disclosure_obligations_obligation_contentId") {
      columnName = "disclosure_obligations_obligation.contentId"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_obligations_obligation_contentId"]: updatedValue,
      }))
    } else if (columnId == "obligation_issuingAuthority") {
      columnName = "obligation.issuingAuthority"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["obligation_issuingAuthority"]: updatedValue,
      }))
    } else if (columnId == "disclosure_disId") {
      columnName = "disclosure.disId"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["disclosure_disId"]: updatedValue }))
    } else if (columnId == "disclosure_disclosureTitle") {
      columnName = "disclosure.disclosureTitle"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_disclosureTitle"]: updatedValue,
      }))
    } else if (columnId == "disclosure_disclosureDesc") {
      columnName = "disclosure.disclosureDesc"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_disclosureDesc"]: updatedValue,
      }))
    } else if (columnId == "disclosure_text") {
      columnName = "disclosure.text"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["disclosure_text"]: updatedValue }))
    } else if (columnId == "disclosure_disclosureSpecificCitation") {
      columnName = "disclosure.disclosureSpecificCitation"
      updatedValue = value
      setAutoEmpty(prev => ({
        ...prev,
        ["disclosure_disclosureSpecificCitation"]: updatedValue,
      }))
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
      columnName = columnId
    }

    // console.log(columnName,updatedValue, "updated alue")
    const updatedSearchQuery = { ...searchQuery, [columnName]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    }



    
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = {
        ...searchQuery,
        ["newObligationReport.prevUpdatedDate"]: "",
      }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const clearFilter = () => {
    setSearchQuery({})
    setSelectedHeader([])
    setAutoEmpty({})
    setAutoFill({})
    setFilterArray({})
    setSearchObject({})
    setSelectedDate(null)
    setCurrentPage(1)
    setSortBy({
      tableName: "obligation",
      fieldName: "obligationName",
      order: "ASC",
    })
    // fetchData({})
    localStorage.removeItem("ti-updated-obli-report-link")
    localStorage.removeItem("idsArray")
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("ti-updated-obli-report-link-filter-array")
    localStorage.removeItem("ti-updated-obli-report-link-search-object")
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  console.log(autoEmpty, "autoEmpty")
  // console.log('autoEmpty', autoEmpty);
  // console.log('searchQuery', searchQuery);
  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          border: "1px solid #ced4da",
          borderBottom: 0,
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          // '&&[class*="MuiOutlinedInput-root"] $input': {
          //   padding: "1px"
          // },
          "& .MuiAutocomplete-inputRoot": {
            color: "purple",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
              {
                // Default left padding is 6px
                padding: 26,
              },
          },
        },
      },
    },
  })

  const classes = useStyles()
  const SelectedFilterIcon = (filterArray,column_id)=>{
    const columnId = `${column_id}`.split("_").pop()

          try { 
              const IsColumnIdSelected = JSON.parse(localStorage.getItem("selectedFilterArray") || "[]")?.[columnId] || []
              const IsColumnIdSelectedObj = JSON.parse(localStorage.getItem("selectedFilterArray") ) || {}
              const IsColumnIdExistInFilterArray = Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(`${columnId}`.split("_").pop()))  
              console.log("oooooooooooooooooooootest1", Object.keys(IsColumnIdSelectedObj||{}),columnId)
              // console.log("oooooooooooooooooooootest2",  )
              console.log("oooooooooooooooooooootest3",  Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(columnId)))
              if (IsColumnIdExistInFilterArray && IsColumnIdSelected.length !=0) {
                return true
              } else {
                return false
              } 
          } catch (error) {
            console.log("ooooooooooooooooooooo", "error",error)
            return false
    }
    

  }

  // useEffect(()=>{
  //   console.log("test_12345 searchQuery",searchQuery);
  //  const storageSearchData =  localStorage.getItem("ti-updated-obli-report-link-search-object")
  //  if (storageSearchData) {

  //   const  localStorageData = JSON.parse(storageSearchData)
  //   console.log("test_12345",storageSearchData);
  //   const {stringValuesObjectConvertedWithDotFormateToDashFormate,stringValuesObjectConvertedWithArrayValue} = SeparateObjectByValueType(localStorageData)
  //   setAutoEmpty(stringValuesObjectConvertedWithDotFormateToDashFormate)
  //   setSearchQuery(stringValuesObjectConvertedWithArrayValue)
  //  }  
  // },[])


  const test2 = () =>{
    console.log("autoEmpty test_1234========>",autoEmpty, )
    console.log("searchObject test_123========>",searchObject )
    console.log("filterArray test_123========>",filterArray )
    console.log("searchQuery test_123========>",searchQuery )
    console.log("autoFill test_123========>",autoFill )
     const storageSearchData =  localStorage.getItem("tier-updated-obli-report-search-object")
   if (storageSearchData) {
    const  localStorageData = JSON.parse(storageSearchData)
    console.log("test_123",SeparateObjectByValueType(localStorageData));
    const {singleRecordArrayObjectConvertedWithStingValue,stringValuesObject,stringValuesObjectConvertedWithArrayValue} = SeparateObjectByValueType(localStorageData)
    // setAutoEmpty(stringValuesObject)
    // setSearchQuery(stringValuesObjectConvertedWithArrayValue)
    console.log("test_123 stringValuesObject", stringValuesObject); 
    console.log("test_123 singleRecordArrayObjectConvertedWithStingValue",singleRecordArrayObjectConvertedWithStingValue, ); 
  } 


     const elements = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
     for (let i = 0; i < elements.length; i++) {
      // Attach an onClick event handler to each element
      elements[i].addEventListener("click", function() {
        // Your onClick logic here
        console.log("test_1234Element clicked:", elements[i].textContent);
      });
    }


    $(".MuiAutocomplete-clearIndicator").click(function() {
      // Your onClick logic here
      console.log("test_1234Element clicked:", $(this).text());
    });
    ;
  }
  const [modal, setModal] = useState(false)
  const [newModal, setNewModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})

  const toggle = () => {
    setModal(!modal)
  }

  // const classes = useStyles();
  let UpdateValuePairs = [["newTestObligationReport_oldobligationDesc", "newTestObligationReport.oldobligationDesc"],["newTestObligationReport_obligationDesc","newTestObligationReport.obligationDesc"],["newTestObligationReport_prevUpdatedDate","newTestObligationReport.prevUpdatedDate"] ]; // If original value is 5, update to 10. If original value is 8, update to 20.
  return (
    <Fragment>
      <Row className="pt-3">
        {/* <button onClick={test2}>Test</button> */}
        <Col
          xs={12}
          md={12}
          lg={12}
          xl={6}
          className="d-flex flex-column flex-lg-row gap-3 align-items-center"
        >
          {/* <div className="d-flex  align-items-center ">
              <h5 className="font-size-18 mb-0">Updated Obligation Report</h5>
              <select
                className="form-select"
                value={customePageSize}
                onChange={onChangeInSelect}
                style={{width : "150px", marginLeft: "10px"}}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    No of Results {pageSize}
                  </option>
                ))}
              </select>
            </div> */}

          {/* <div className="d-flex justify-content-start ">
           
            </div> */}
          <TableHeader
            heading={""}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        {/* {console.log(headerGroups && headerGroups[0].headers[2], "headerGroups")} */}
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={6}
          className="justify-content-end py-2"
        >
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {/* {downloadAccess && downloadAccess.readAccess === true ? (
                 btnLoader ? (
                    <div className="ml-5">
                      <Spinner className="ms-2 " color="primary" />
                    </div>
                  ) : (
                      <button 
                      className="btn btn-primary"
                      onClick={handleScan}
                      >
                         <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                           Scan Now
                      </button>
                  )
                  )
                  :
                  ("")
                  } */}

            {/* {btnLoader ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <button className="btn btn-primary" onClick={handleExport}>
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Download PDF
              </button>
            )} */}
            {btnLoaderExcel ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <button className="btn btn-primary" onClick={handleExportExcel}>
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Download Report
              </button>
            )}
            {/* {btnLoader ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <PDFGenerator
              data={`<div >
                <div>
                  <div class="row"> 
                      <div class="col-12 col">
                          <div>
                              <h4 >Updated Obligation Detail</h4>
                              
                          </div>
                      </div>
                  </div>
                  `}
            />
            )} */}
          </div>
        </Col>
      </Row>

      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 500 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span>
                              <br /> */}
                      </div>
                      {/* {console.log(column.id, 'colims')} */}
                      {column.id != "selection" &&
                      column.id !== "newObligationReport_obligationDesc" &&
                      column.id !== "newObligationReport_oldobligationDesc" &&
                      column.id !== "newObligationReport_prevUpdatedDate" &&
                      column.id !== "newTestObligationReport_obligationDesc" &&
                      column.id !== "newTestObligationReport_oldobligationDesc" &&
                      column.id !== "newTestObligationReport_prevUpdatedDate" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "95%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <MuiThemeProvider theme={theme} >
                            <Autocomplete
                              // classes={classes}
                              style={{ width: "75%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              options={handleNullAndUndefinedOfArray(autoFill[column.id]||[])}
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value&&trimLeft(value))
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  disabled={loader}
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={handleKeyDown}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          {column.id !== "obligationDesc" &&
                          column.id !== "obligation.obligationDesc" ? (
                            <button
                              onClick={() => {
                                setShowModal(true)
                                setModalData(column)
                              }}
                              disabled={loader}
                              className={
                                // selectedHeader.includes(column.Header)
                                SelectedFilterIcon(filterArray,column.id)
                                  ? "filter_button_selected"
                                  : "filter_button"
                              }
                              // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            >
                              <i className="bx bx-filter font-size-18"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : column.id == "newTestObligationReport_prevUpdatedDate"  ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: "2023-07-19",
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                              <button
                                                type="button"
                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                // disabled
                                                style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                              >
                                                <i
                                                  className="fa fa-calendar"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                            </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          filterArray={filterArray}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={{...modalData,id:UpdateVariable({originalVariableName:modalData?.id, UpdateValuePairs:UpdateValuePairs})  }}
          setShowModal={setShowModal}
          tableName={"newTestObligationReport"}
          relationFeild={`${
            modalData.id === "obligation_obligationName" ||
            modalData.id === "obligation_obligationDesc" ||
            modalData.id === "obligation_citationUrl" ||
            modalData.id === "obligation_citation" ||
            modalData.id === "obligation_issuingAuthority" ||
            modalData.id === "obligation_regLaw"
              ? "obligation"
              : modalData?.id === "disclosure_obligations_obligation_contentId" ||
              modalData.id === "obligation_obId"
              ? "disclosure_obligations_obligation"
              : modalData?.id === "disclosure_disclosureSpecificCitation" ||
                modalData?.id === "disclosure_text" ||
                modalData?.id === "disclosure_disId" ||
                modalData?.id === "disclosure_disclosureTitle" ||
                modalData?.id === "disclosure_disclosureDesc"
              ? "disclosure"
              : ""
          }`}
          isUnlink={false}
          isObliReport={false}
          reportId={queryId}
          isRemoveDuplicates={true}
          isReview={1}
          emptyTable={data?.length == 0??false}

        />
      </Styles>
      {/* )} */}
    </Fragment>
  )
}

const LazyLoadedModal = ({ isOpen, toggle, title, desc }) => {
  const [modalContent, setModalContent] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      // Simulate fetching or processing modal content
      // You can replace this with your actual data loading logic
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        setModalContent(desc)
      }, 500) // Adjust the delay as needed
    }
  }, [isOpen, desc])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      style={{ borderRadius: "50px" }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="text-left">
        {loading ? (
          <div className="ml-5 d-flex justify-content-center">
            <Spinner className="ms-2 " color="primary" />
          </div>
        ) : (
          <div style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        )}
      </ModalBody>
      <ModalFooter className="mx-auto">
        <Button className="px-5" color="primary" onClick={toggle}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const LazyLoadedModalNew = ({
  isOpen,
  toggle,
  title,
  desc,
  oldString,
  newString,
}) => {
  const [modalContent, setModalContent] = useState({})
  const [show, setShow] = useState(false)

  function firstHtml() {
    // const oldContent = oldString.replace(/<[^>]*>/g,'')
    console.log("oldData 125456987",oldString);
    return { __html: oldString }
  }

  function secondHtml() {
    // const newContent = newString.replace(/<[^>]*>/g,'')
    console.log("newData 125456987",newString);
    return { __html: newString }
  }

  useEffect(() => {
    if (isOpen) {
      // Simulate fetching or processing modal content

      // function diffHtml() {
      //   // console.log(firstHtml(), secondHtml())
      // return {
      //   __html: HtmlDiff.execute(firstHtml().__html, secondHtml().__html),
      // }
      // }

      setTimeout(() => {
        setModalContent({
          __html: HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
        })
 
        setShow(true)
      }, 2000)
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      style={{ borderRadius: "50px" }}
    >
      <ModalHeader toggle={toggle}>{`${title}`}</ModalHeader>
      <ModalBody className="text-left" style={{ overflowX: "auto" }}>
        <div className="inner" onClick={()=>console.log("modalContent",modalContent)}>
          {show ? (
            <div dangerouslySetInnerHTML={modalContent} />
          ) : (
            <div className="ml-5 d-flex justify-content-center">
              <Spinner className="ms-2 " color="primary" />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="mx-auto">
        <Button className="px-5" color="primary" onClick={toggle}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const TI_NewObliReport = props => {
  const [modal, setModal] = useState(false)
  const [modaldesc, setModaldesc] = useState(false)
  const [newModal, setNewModal] = useState(false)
  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [disclosureDetail, setDisclosureDetail] = useState([])
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [queryId, setQueryId] = useState()
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [downloadAccess, setDownloadAccess] = useState([])
  const [oldString, setOldString] = useState("")
  const [newString, setNewString] = useState("")
  const [sortBy, setSortBy] = useState({})
  const [modalContent, setModalContent] = useState("")
  const [newModalContent, setNewModalContent] = useState("")
  const [newModalTitle, setNewModalTitle] = useState("")
  const [dateCreate, setDateCreate] = useState("")
  const dispatch = useDispatch()
  const location = useLocation()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  // $(function () {
  //   $('#tab-links a').click(function () {

  //     // Check for active
  //     $('.nav-link').removeClass('active');
  //     $(this).addClass('active');

  //     // Display active tab
  //     let currentTab = $(this).attr('href');
  //     $('.tab-pane').hide();
  //     $(currentTab).show();

  //     return false;
  //   });
  // });

  useEffect(() => {
    setDateCreate(props?.location?.state?.rowData?.createdDate)
  }, [])

  useEffect(() => {
    console.log(
      props?.location?.state?.rowData?.clear,
      "props?.location?.state?.rowData?.clear"
    )
    if (props?.location?.state?.rowData?.clear) {
      localStorage.removeItem("ti-updated-obli-report-link")
      localStorage.removeItem("selectedFilterArray")
    }
  }, [props?.location?.state?.rowData?.clear])

  const limit = 20

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  useEffect(() => {
    setSortBy({
      tableName: "obligation",
      fieldName: "obligationName",
      order: "ASC",
    })
  }, [])

  const handleSort = column => {
    console.log(column, "handleSort")
    let columnName, tableName
    if (column.id === "obligation_obId") {
      columnName = "obId"
      tableName = "obligation"
    } else if (column.id === "obligation_obligationDesc") {
      columnName = "obligationDesc"
      tableName = "obligation"
    } else if (column.id === "obligation_obligationName") {
      columnName = "obligationName"
      tableName = "obligation"
    } else if (column.id === "obligation_citationUrl") {
      columnName = "citationUrl"
      tableName = "obligation"
    } else if (column.id === "obligation_citation") {
      columnName = "citation"
      tableName = "obligation"
    } else if (column.id === "newObligationReport_oldobligationDesc") {
      columnName = "oldobligationDesc"
      tableName = "newObligationReport"
    } else if (column.id === "newTestObligationReport_oldobligationDesc") {
      columnName = "oldobligationDesc"
      tableName = "newTestObligationReport"
    }else if (column.id === "newTestObligationReport_obligationDesc") {
      columnName = "obligationDesc"
      tableName = "newTestObligationReport"
    }else if (column.id === "newObligationReport_obligationDesc") {
      columnName = "obligationDesc"
      tableName = "newObligationReport"
    } else if (column.id === "newTestObligationReport_prevUpdatedDate") {
      columnName = "prevUpdatedDate" 
      tableName = "newTestObligationReport"
      // const newDate = new Date(value)
      // console.log(newDate, "newDate")
      // const formattedDate = format(newDate, "yyyy-MM-dd")
      // console.log(formattedDate, "formattedDate")
      // updatedValue = formattedDate
     
    } else if (column.id === "obligation_regLaw") {
      columnName = "regLaw"
      tableName = "obligation"
    } else if (column.id === "disclosure_obligations_obligation_contentId") {
      columnName = "contentId"
      tableName = "disclosure_obligations_obligation"
    } else if (column.id === "obligation_issuingAuthority") {
      columnName = "issuingAuthority"
      tableName = "obligation"
    } else if (column.id === "disclosure_disId") {
      columnName = "disId"
      tableName = "disclosure"
    } else if (column.id === "disclosure_disclosureTitle") {
      columnName = "disclosureTitle"
      tableName = "disclosure"
    } else if (column.id === "disclosure_disclosureDesc") {
      columnName = "disclosureDesc"
      tableName = "disclosure"
    } else if (column.id === "disclosure_text") {
      columnName = "text"
      tableName = "disclosure"
    } else if (column.id === "disclosure_disclosureSpecificCitation") {
      columnName = "disclosureSpecificCitation"
      tableName = "disclosure"
    } else {
      columnName = column.id
    }
    const isAsc = sortBy.fieldName === columnName && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName: tableName,
        // columnName === "obligationName" ||
        // columnName === "obligationDesc" ||
        // columnName === "citationUrl" ||
        // columnName === "citation" ||
        // columnName === "regLaw"
        //   ? "obligation"
        //   : "newTestObligationReport",
        fieldName: columnName,
        order: sortOrder,
      })
    }
  }

  const customePageCount = Math.ceil(totalItems / limit)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const toggle = data => {
    setModal(!modal)
  }

  const toggleDesc = data => {
    setModaldesc(!modaldesc)
  }

  const newToggle = () => {
    setNewModal(!newModal)
  }

  const openModal = useCallback(content => {
    setModal(true)
    setModalContent(content)
    setTitle("Prior Citation Content")
  }, [])

  const openNewModal = useCallback((oldData, content) => {
    console.log("25456987",content)
    setNewModal(true)
    setOldString(oldData||"")
    setNewString(content||"")
    // setNewModalContent(diffHtml()); // You may need to re-generate the diff content here
    setNewModalTitle("New Citation Content")
  }, [])

  const contentRef = React.useRef(null);
 
  const setInnerText = (content) => {
    // if (contentRef.current) {
      const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    // }
  }

  const columns = [
    {
      Header: "Content ID",
      accessor: "disclosure_obligations_obligation_contentId",
      // width: 200,
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        return (<> {CheckValidValue({value:cellProps?.value })?cellProps?.value : DisplayJustifyCenter("-") } </>)
      },
    },
    {
      Header: "Obligation ID",
      // accessor: "obId",
      accessor: "obligation_obId",
      // width: 200,
      filterable: false,
      disableFilters: true,
      // sticky: "left",
    },
    {
      Header: "Obligation Name",
      // accessor: "obligation.obligationName",
      accessor: "obligation_obligationName",
      // width: 100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <LightTooltip title={cellProps.value}>
            <span style={{ color: "#000" }}   className="StringOverFlow1">
            {CheckValidValue({value:cellProps?.value })?cellProps?.value : DisplayJustifyCenter("-") } 
            </span>
          </LightTooltip>
        )
      },
    },
    {
      Header: "Obligation Description",
      width: 161, 
      filterable: false,
      disableFilters: true,
      accessor: "obligation_obligationDesc",
      Cell: cellProps => {
        return (<>
         {CheckValidValue({value:cellProps?.value })?    <DarkTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModaldesc(!modaldesc)
                  // setDesc(cellProps.value||"")
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Obligation Description")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
            View Details
        </UncontrolledTooltip> */}
            </div>
          </DarkTooltip> : DisplayJustifyCenter("-") } 
        </>)
        
        
      },
    },
    {
      Header: "Citation URL",
      // width: 125,
      filterable: false,
      disableFilters: true,
      // accessor: "obligation.citationUrl",
      accessor: "obligation_citationUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div>
             {CheckValidValue({value:cellProps?.value })? <Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                  className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {(cellProps.value &&
                    cellProps.value
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "") ) ||
                    ""}
                </div>
              </LightTooltip>
            </Link> : DisplayJustifyCenter("-")} 
          </div>
        )
      },
    },
    {
      Header: "Regulator Short Name",
      accessor: "obligation_issuingAuthority",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<> {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value  : DisplayJustifyCenter("-") } </>)
      },
    },
    {
      Header: "Reg Law Short Name",
      // accessor: "obligation.regLaw",
      accessor: "obligation_regLaw",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<> {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
        ? cellProps?.value?.substr(0, 18) + " ..."
        : cellProps.value  : DisplayJustifyCenter("-") } </>)
      },
    },
    {
      Header: "Citation",
      // accessor: "obligation.citation",
      accessor: "obligation_citation",
      // width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<> {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value  : DisplayJustifyCenter("-") } </>)
      },
    },
    {
      Header: "Disclosure ID",
      accessor: "disclosure_disId",
      // width:125,
      filterable: false,
      disableFilters: true,
      // sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <>
          {CheckValidValue({value:cellProps?.value })?cellProps?.value : DisplayJustifyCenter("-") } 
          </>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Title",
      accessor: "disclosure_disclosureTitle",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <>
            {cellProps.value ? (
              <>
                {/* <Link
            to={{
              pathname: "/disclosure-text/disclosure_view",
              state: { rowData },
            }}
          > */}
                <span style={{ color: "#000" }}   className="StringOverFlow1">
                  {cellProps.value && cellProps?.value?.length > 10
                    ? cellProps?.value 
                    : cellProps.value}
                </span>
                {/* </Link> */}
              </>
            ) : (
              <>-</>
            )}
          </>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Description",
      accessor: "disclosure_disclosureDesc",
      // width:175,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<>
         {CheckValidValue({value:cellProps?.value })?  <DarkTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModaldesc(!modal)
                  // setDesc(cellProps.value||"")
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Disclosure Description")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
            View Details
        </UncontrolledTooltip> */}
            </div>
          </DarkTooltip>: DisplayJustifyCenter("-") } </>)
      },
      show: true,
    },
    {
      Header: "Text",
      accessor: "disclosure_text",
      // width:120,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<>
         {CheckValidValue({value:cellProps?.value }) ? <DarkTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModaldesc(!modal)
                  // setDesc(cellProps.value||"")
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Disclosure Text")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
            View Details
        </UncontrolledTooltip> */}
            </div>
          </DarkTooltip>: DisplayJustifyCenter("-") } </>)
      },
      show: true,
    },
    {
      Header: "Disclosure specific Citation",
      accessor: "disclosure_disclosureSpecificCitation",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ textAlign: "center" }}   className="StringOverFlow1">
            {cellProps.value
              ? cellProps?.value?.length > 10
                ? cellProps?.value 
                : cellProps.value
              : "-"}
          </div>
        )
      },
      show: true,
    },
    {
      Header: "Prior Updated Date",
      // accessor: "prevUpdatedDate",
      accessor: "newTestObligationReport_prevUpdatedDate",
      width: 138,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const date1 = FormatedDate(cellProps.value) 
        if (cellProps.value) {
          return <div className="text-align-center">{date1}</div>
        } else {
          return <div style={{ paddingLeft: "100px" }}>-</div>
        }
      },
    },
    {
      Header: <div style={{marginLeft:'25px'}}>Prior Citation Content</div>,
      // accessor: "oldobligationDesc",
      accessor: "newTestObligationReport_oldobligationDesc",
      // width: 100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => { 
        return (<>
         {CheckValidValue({value:cellProps?.value })?   <DarkTooltip
            title="View Details"
            style={{ width: "64%", textAlign: "center" }}
          >
            <div style={{ textAlign: "center" }}>
              {/* <span>{date1}</span> */}
              <i
                onClick={() => {
                  // setModal(!modal)
                  // setOldString(cellProps.value)
                  // setDesc(cellProps.value)
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  openModal(cellProps.value||"")
                }}
                style={{
                  cursor: "pointer",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
            </div>
          </DarkTooltip> : DisplayJustifyCenter("-") } 
        </>)
      },
    },
    {
      Header: "New Citation Content",
      // accessor: "obligationDesc",
      accessor: "newTestObligationReport_obligationDesc",
      // width: 100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        // setNewString(cellProps.value)
        // console.log(cellProps, "new props")
        const oldData =
          cellProps.row.original.newTestObligationReport_oldobligationDesc
        return (<>
         {CheckValidValue({value:cellProps?.value })?    <DarkTooltip
            title="View Details"
            style={{ width: "64%", textAlign: "center" }}
          >
            <div>
              <i
                // onClick={() => {
                //   setNewModal(!newModal)
                //   setOldString(oldData)
                //   setNewString(cellProps.value)
                //   setTitle("New Citation Content")
                //   openNewModal(oldData,cellProps.value)
                // }}
                onClick={() =>{ openNewModal(oldData, cellProps.value) }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
            </div>
          </DarkTooltip>: DisplayJustifyCenter("-") } 
        </>)
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      sticky: "right",
      width: 100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <div style={{ display: "flex", gap: "4px" }}>
            <Badge className="font-size-15  badge-soft-primary" pill>
              <DarkTooltip title="View">
                <Link
                  to={{
                    pathname: "/ti-updated-obli-report-view",
                    state: { rowData: { ...rowData, queryId,createdDate:props?.location?.state?.rowData?.createdDate  } },
                  }}
                  onClick={() => {
                    const orderData = cellProps.row.original
                    // console.log(orderData, 'data')
                    // handleOrderClick(orderData);
                  }}
                >
                  <i
                    //   onClick={() => handleView(value)}
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-outline  font-size-18"
                    id="customerViewtooltip"
                  ></i>
                </Link>
              </DarkTooltip>
            </Badge>
            <Badge className="font-size-15  badge-soft-primary" pill>
              <DarkTooltip title="Disclosure View">
                <Link
                  to={{
                    pathname: "/ti-updated-obli-report-view-discro",
                    state: { rowData: { ...rowData, queryId, createdDate:props?.location?.state?.rowData?.createdDate } },
                  }}
                  onClick={() => {
                    const orderData = cellProps.row.original
                    // console.log(orderData, 'data')
                    // handleOrderClick(orderData);
                  }}
                >
                  <i
                    //   onClick={() => handleView(value)}
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-settings-outline font-size-18"
                    id="customerViewtooltip"
                  ></i>
                </Link>
              </DarkTooltip>
            </Badge>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    console.log(location.state, "locationData")
    if (location.state && location.state.rowData) {
      console.log(location.state.rowData, "locationData")
      // setSearchObject({...searchObject, reportId : locationData.id})
      setQueryId(location.state.rowData.id)
    }
  }, [])

  const fetchData = searchObject => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setLoader(true)

    // let finalData = Object.keys(filterArray).length ? filterArray : searchObject
    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData =   {...searchObject,...filterArray}
    let data = localStorage.getItem("ti-updated-obli-report-link")
    console.log(data, "finalData12545 10")
    if (data != null) {
     
      let parsedData = JSON.parse(data)
      console.log(finalData, "finalData12545 2",parsedData)
      finalData = {  ...parsedData, ...finalData }
      // if (Object.keys(finalData).length > 0) {
      //   console.log(finalData, "finalData12545 3",parsedData)
      //   if (
      //     !(Object.keys(finalData).length < Object.keys(parsedData).length) ||
      //     !(Object.keys(finalData).length > Object.keys(parsedData).length)
      //   ) {
      //     console.log(finalData, "finalData12545 3.1",data)
      //     data = finalData 
      //   } else {
      //     finalData = parsedData
      //     console.log(finalData, "finalData12545 3.2",data)
      //   }
      // } else {
      //   console.log(typeof parsedData, "finalData12545 4")
      //   finalData = {...parsedData}
        
       
      // }
    }
    // if (Object.keys(searchObject).length > 0) {
     
    //   const {singleRecordArrayObjectConvertedWithStingValue,stringValuesObject} = SeparateObjectByValueType(searchObject)
    //     localStorage.setItem("ti-updated-obli-report-link-search-object", JSON.stringify({...stringValuesObject,...singleRecordArrayObjectConvertedWithStingValue}))
    // }
    // if (Object.keys(filterArray).length > 0) { 
    //    localStorage.setItem("ti-updated-obli-report-link-filter-array", JSON.stringify({...filterArray}))
    // }

    if (Object.keys(finalData).length > 0) {
      localStorage.setItem(
        "ti-updated-obli-report-link",
        JSON.stringify(finalData)
      )
    }
    // else {
    //   localStorage.removeItem("ti-updated-obli-report-link")
    // }

  const rawData =  {
    ...finalData,
    // ...searchObject,
    reportId: queryId,
    orderBy: {
      ...sortBy,
    },
  }

  const params = {
    // "oldobligationDesc": "newTestObligationReport.oldobligationDesc",
    // "obligationDesc": "newTestObligationReport.obligationDesc", 
    // Add more mappings as needed
  }; 
    const requestData = rawData;

    if (queryId) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}new-test-obligation-report-with-disclosure/?limit=${customePageSize}&pageNo=${currentPage}`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // return response.data;
            if(response.data.data != null){
              if(!response.data.data.severity) {
                setDisclosureDetail(response?.data?.data )
              }else{
                setDisclosureDetail([])
              }
            }else{
              setDisclosureDetail([])
            }
            // setOldString(response.data.data[0].oldobligationDesc)
            // setNewString(response.data.data[0].obligationDesc)
            console.log(response.data, "response.data.data")
            var ids = []
            if (response.data.ids !== undefined && response.data.ids !== "") {
              ids = response.data.ids.split(",")
            }
            localStorage.setItem("idsArray", JSON.stringify(ids))
            setTotalItems(response?.data?.totalRecords||[])

            setLoader(false)
          }
          // throw response.data;
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          setLoader(false) 
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  useEffect(() => {
    console.log(searchObject, "finalData12545")
    fetchData(searchObject)

  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const handleRender = () => {
    setDelete(!is_delete)
  }
  let UpdateValuePairs = [["newTestObligationReport_oldobligationDesc", "newTestObligationReport.oldobligationDesc"],["newTestObligationReport_obligationDesc","newTestObligationReport.obligationDesc"] ]; // If original value is 5, update to 10. If original value is 8, update to 20.
  document.title = "Disclosure Review Report"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Scanned Report"
            // breadcrumbItem={updateTitle}
            path="ti-new-obli-link"
            breadcrumbItem={`Disclosure Review Report - ${FormatedDate(dateCreate) }`}
          />
          <Modal
            isOpen={modaldesc}
            toggle={toggleDesc}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggleDesc}>
              {/* Obligation Description */}
              {title}
            </ModalHeader>
            <ModalBody className="text-left">
              {/* <div style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggleDesc}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          {modal && (
            <LazyLoadedModal
              isOpen={modal}
              toggle={() => setModal(false)}
              title={title}
              desc={modalContent}
            />
          )}
          {newModal && (
            <LazyLoadedModalNew
              isOpen={newModal}
              toggle={() => setNewModal(false)}
              oldString={oldString}
              newString={newString}
              title={newModalTitle}
              desc={newModalContent}
            />
          )}
          {/* <Modal
            isOpen={newModal}
            toggle={newToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={newToggle}>{title}</ModalHeader>
            <ModalBody className="text-left">
              <div className="inner">

                <div dangerouslySetInnerHTML={diffHtml()} />
              </div>

            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={newToggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                    toggle={toggle}
                    queryId={queryId}
                    setModal={setModal}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    filterArray={filterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    setFilterArray={setFilterArray}
                    setCurrentPage={setCurrentPage}
                    columns={columns}
                    data={disclosureDetail}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    props={props}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        // name={console.log({ totalItems })}
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(TI_NewObliReport)



