import React, { useEffect, useState, useMemo, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import toastr from "toastr"
import axios from "axios"
import { withRouter, useLocation } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import { map } from "lodash"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { createTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import styled from "styled-components"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  Badge,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import Pagination from "../../components/Common/Pagination"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeLinkModal from "../../components/Common/DeLinkModal"
import DeleteModal from "../../components/Common/DeleteModal"
import { Popper } from "@material-ui/core"
import FilterModal from "components/FilterModal/FilterModal"
import TableHeader from "components/Common/TableHeader"
import { ColumnDataWithTooltip, DownloadExelFrom, RedirectOnUnAuthentication, RemoveSpaces, ToastError, convertObjectValuesToArray, encryptData, handleNullAndUndefinedOfArray, trimLeft } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const exportToExcel = rowData => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  const customizedData = rowData.map(row => {
    // console.log(row, 'row')
    return {
      ...row,
      disclosure:
        (row.disclosure && row.disclosure.disclosureTitle) ||
        row.custDisclosure.disclosureTitle,
      obligation:
        (row.obligation && row.obligation.obligationName) ||
        row.custObligation.obligationName,
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')
  const filteredData = customizedData.map(
    ({
      id,
      custObligation,
      custDisclosure,
      custOrganization,
      custContentId,
      ...rest
    }) => rest
  )
  const arrangedColumns = [
    "disId",
    "disclosure",
    "obId",
    "obligation",
    "contentId",
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Disclosure ID",
        "Disclosure Title",
        "Obligation ID",
        "Obligation Name",
        "Content ID",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Linked_IDs.xlsx")
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :first-child {
        .td {
          padding-top: 10px;
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 110,
  },

  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}

const TableContainer = ({
  loader,
  toggle,
  columns,
  fetchData,
  filterArray,
  checkOrg,
  setCheckOrg,
  handleSort,
  setSortBy,
  linkAccess,
  downloadAccess,
  setModal,
  searchObject,
  customePageSize,
  setCustomePageSize,
  pageCount: customePageCount,
  data,
  setSearchObject,
  handleRender,
  setFilterArray,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useFlexLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  // console.log(modalData, "modalData")
  // console.log(selectedHeader, "selectedHeader")

  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)

    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      disObliIds: ids,
      customerorgId:
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    let apiUrl
    if (checkOrg !== undefined && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/export-disclosure-obligation-link`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/export-disclosure-obligation-organization-link`
    }

    axios
      .post(apiUrl, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
        responseType:'blob'
      })
      .then(response => {
        // console.log(data.data.data, 'exporData');
        // exportToExcel(response.data.data)
        DownloadExelFrom({responseData:response.data,fileTitle: "Linked_IDs"})
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        // toastr.error(error, "Error Exporting Data")
        ToastError(error)
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }

  const handleSearchQueryChange = (fldName, value) => {
    console.log(fldName, value, "test fldname")

    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // console.log(fldName, value, 'check')
    let tbname
    console.log({ fldName })
    const field_name = `${
      fldName == "disclosure" || fldName == "custDisclosure"
        ? "disclosureTitle"
        : fldName == "obligation" || fldName == "custObligation"
        ? "obligationName"
        : fldName
    }`
    setSearchQuery(prev => ({ ...prev, [field_name]: value }))

    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []

    const data = {
      tableName: `${
        fldName === "disclosure" || fldName === "disclosureTitle"
          ? "Disclosure"
          : fldName === "custDisclosure" ||
            fldName === "disclosureTitle" ||
            fldName === "custObligation"
          ? "custLinkingCustDisclosureCustObligation"
          : fldName === "obligation" || fldName === "obligationName"
          ? "Obligation"
          : "disclosure_obligations_obligation"
      }`,
      relationFeild: `${
        fldName == "regLaw"
          ? "obligation"
          : fldName == "custDisclosure"
          ? "custDisclosure"
          : fldName == "custObligation"
          ? "custObligation"
          : ""
      }`,
      feildName: `${
        fldName == "disclosure" || fldName == "custDisclosure"
          ? "disclosureTitle"
          : fldName == "obligation" || fldName == "custObligation"
          ? "obligationName"
          : fldName
      }`,
      searchValue: value ? value : "",
      ids: idsArray,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        // {data:encryptData(data)},
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [fldName]:
            response && response.data.data.map(item => item[field_name]),
        }))
        setSearchQuery(prev => ({ ...prev, [field_name]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [field_name]: [],
        }))
      })
  }

  const handleKeyDown = (key, columnId, value) => { 
    if (key === "Enter") {
      // fetchData(searchQuery)
      console.log("handleAutocompleteChange",columnId, value)
      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        setSelectedDate(formattedDate)
        updatedValue = formattedDate
      } else {
        updatedValue = value // Set the value to true when selecting "Yes"
      }
  
      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
  
      console.log("API call triggered:", value)
    }
  }


  const handleAutocompleteChange = (columnKey, value) => {
    const optionExists = autoFill[columnKey] && autoFill[columnKey].includes(value);
    if (optionExists || value === "") {
      let fldName
      console.log(columnKey, "columnKey")
      const field_name = `${
        columnKey == "disclosure" || columnKey == "custDisclosure"
          ? "disclosureTitle"
          : columnKey == "obligation" || columnKey == "custObligation"
          ? "obligationName"
          : columnKey
      }`
      handleSearchQueryChange(field_name, value)
      setAutoEmpty(prev => ({ ...prev, [columnKey]: value }))
      if (value == null) {
        const updatedSearchQuery = { ...searchQuery, [field_name]: "" }
        setSearchObject(updatedSearchQuery)
      } else {
        const updatedSearchQuery = { ...searchQuery, [field_name]: [value.trim()] }
        setSearchObject(updatedSearchQuery)
      }
    } else {
      let fldName
      console.log(columnKey, "columnKey")
      const field_name = `${
        columnKey == "disclosure" || columnKey == "custDisclosure"
          ? "disclosureTitle"
          : columnKey == "obligation" || columnKey == "custObligation"
          ? "obligationName"
          : columnKey
      }`
      handleSearchQueryChange(field_name, value)
      setAutoEmpty(prev => ({ ...prev, [columnKey]: value }))
      if (value == null) {
        const updatedSearchQuery = { ...searchQuery, [field_name]: "" }
        setSearchObject(updatedSearchQuery)
      } else {
        const updatedSearchQuery = { ...searchQuery, [field_name]: value.trim() }
        setSearchObject(updatedSearchQuery)
      }
    }
    console.log("API call triggered:", value)
  }

  // const handleKeyDown = event => {
  //   if (event.key === "Enter") {
  //     fetchData(searchQuery)
  //   }
  // }

  const clearFilter = () => {
    setSearchQuery({})
    setAutoEmpty({})
    setFilterArray({})
    setAutoFill({})
    setSortBy({
      tableName: "disclosure",
      fieldName: "disclosureTitle",
      order: "ASC",
    })
    setSelectedHeader([])
    setSearchObject({})
    // setSelectedDate(null)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          border: "1px solid #ced4da",
          borderBottom: 0,
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          // '&&[class*="MuiOutlinedInput-root"] $input': {
          //   padding: "1px"
          // },
          "& .MuiAutocomplete-inputRoot": {
            color: "purple",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
              {
                // Default left padding is 6px
                padding: 26,
              },
          },
        },
      },
    },
  })

  const classes = useStyles()

  const [importModal, setImportModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)

  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggleImport = () => {
    setImportModal(!importModal)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-disclosure-obligation-links`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}` },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggleImport()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggleImport()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                message = `${err.response.data.message}`
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }

          ToastError(err)
          throw message
        })
    }
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "disclosure_obligations_obligation",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {

        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
              
            ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])
  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col
          xs={12}
          md={12}
          lg={12}
          xl={3}
          className="py-3 justify-content-end"
        >
          {/* <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">Linked List</h5>
            <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{width : "130px", marginLeft: "10px",}}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Results {pageSize}
                </option>
              ))}
            </select>
          </div> */}
          <TableHeader
            heading={"Linked List"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
            isLinkTable={true}
          />
        </Col>
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className="py-3 justify-content-end"
        >
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <div
              style={{
                display: "flex",
                alignItems: "end",
              }}
            >
              <MuiThemeProvider theme={theme}>
                <Autocomplete
                  // classes={classes}
                  style={{
                    width: 170,
                    padding: "5px",
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                  PopperComponent={PopperMy}
                  disabled={loader}
                  // disableClearable={true}
                  options={autoFill["regLaw"] || []}
                  // getOptionLabel={(option) => option || ""}
                  value={autoEmpty["regLaw"] || ""}
                  classes={{
                    option: classes.option,
                  }}
                  freeSolo
                  onChange={(event, value) =>
                    handleAutocompleteChange("regLaw",  value)
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Search Reg Law Short Name..."
                      disabled={loader}
                      variant="filled"
                      size="small"
                      // style={{padding: "1px"}}
                      // className="form-control"
                      className={classes.root}
                      value={searchQuery["regLaw"] || ""}
                      onChange={e =>
                        handleSearchQueryChange("regLaw", e.target.value)
                      }
                      InputLabelProps={{
                        shrink: false,
                        focused: false,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiThemeProvider>
              <button
                onClick={() => {
                  setShowModal(true)
                  setModalData({ id: "regLaw", Header: "Reg Law Short Name" })
                }}
                disabled={loader}
                style={{ height: "32px" }}
                className={
                  selectedHeader.includes("Reg Law Short Name")
                    ? "filter_button_selected"
                    : "filter_button"
                }
                // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
              >
                <i className="bx bx-filter font-size-18"></i>
              </button>
            </div>
            {/* {selectedFlatRows.length == 1 ? (
                  <>
                   <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                    //  marginLeft: 5,
                    //  marginRight: 5,
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button>
                 </>
                ) : selectedFlatRows.length >= 1 ? (
                  <Button
                    onClick={(e) => {
                      onAllDeleteRecord(e)
                    }}
                    style={{
                      backgroundColor: "#556EE6",
                      // marginLeft: 5,
                      // marginRight: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                    Delete
                  </Button>
                ) : (
                  ""
                )} */}
            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {linkAccess && linkAccess.writeAccess == true && checkOrg === 3 ? (
              <Button
                variant="contained"
                // onClick={toggle}
                onClick={() => setModal(true)}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                  // padding: "5px"
                }}
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Link
              </Button>
            ) : (
              ""
            )}
            {linkAccess && linkAccess.writeAccess == true && checkOrg === 3 ? (
              <Button
                onClick={() => {
                  setImportModal(!importModal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )}
            <Modal
              isOpen={importModal}
              toggle={toggleImport}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px" }}
            >
              <ModalHeader toggle={toggleImport}>{title}</ModalHeader>
              <ModalBody className="px-4 py-4 text-left">
                <h6>Import Data</h6>
                <div className="form-body mt-3">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-2"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button"
                    onClick={e => handleImportData(e)}
                  >
                    Import Data
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {downloadAccess && downloadAccess.readAccess === true ? (
              btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={handleExport}>
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Download
                </button>
              )
            ) : (
              ""
            )}
            {/* <button
                  type="button"
                  className="btn btn-primary "
                  // onClick={handleExport}
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </button>  */}
            {/* <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Buttn
                    variant="contained"
                    style={{
                      backgroundColor: "#556ee6",
                      color: "#fff",
                      textTransform: "none ",
                      fontFamily: "poppins",
                      fontWeight: 400,
                      fontSize: 15
                    }}
                    component="span"
                  >
                    <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                    Upload
                  </Buttn>
                </label> */}
          </div>
        </Col>
      </Row>

      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 600 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                              {" "}
                              {generateSortingIndicator(column)}
                            </span>
                            <br /> */}
                      </div>
                      {/* {console.log(column.id, 'column key')} */}
                      {column.id != "selection" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                            paddingInlineEnd: '20px'
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{ width: column.id === 'obligationDesc' ? '60%' : "85%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              openOnFocus={false}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              options={autoEmpty[column.id == "disclosure" ? "disclosureTitle" : column.id == "obligation" ? "obligationName" : column.id] || 
                                        searchQuery[column.id == "disclosure" ? "disclosureTitle" : column.id == "obligation" ? "obligationName" : column.id] ? 
                                        autoFill[column.id == "disclosure" ? "disclosureTitle" : column.id == "obligation" ? "obligationName" : column.id] || [] : []} // Show options only when there's input                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }} 
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value) 
                              } 
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  variant="filled"
                                  disabled={loader}
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={(e) => {
                                  //   if (e.key === 'Enter') {
                                  //     handleSearchQueryChange(column.id, searchQuery[column.id]);
                                  //   }
                                  // }}
                                  onKeyDown={(e) => {
                                   handleKeyDown(e.key,column.id,e.target.value)
                                  }}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          <button
                            onClick={() => {
                              setShowModal(true)
                              setModalData(column)
                            }}
                            disabled={loader}
                            // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            className={
                              selectedHeader.includes(column.Header)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <Filter column={column} /> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          relationFeild={`${
            modalData.id === "disclosure"
              ? "disclosure"
              : modalData.id === "obligation" || modalData.id === "regLaw"
              ? "obligation"
              : ""
          }`}
          tableName={`${
            // modalData.id === "disclosure"
            //   ? "Disclosure"
            //   : modalData.id === "obligation" ||
            //   modalData.id === "regLaw"
            //   ? "Obligation"
            //   :
            "disclosure_obligations_obligation"
          }`}
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0??false}
        />
      </Styles>
    </Fragment>
  )
}

const LinkTable = props => {
  const [fakeData, setFakeData] = useState([])

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [modalData, setModalData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delRegulation, setDelRegulation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [is_added, setIsadded] = useState(false)
  const [selectObligation, setSelectObligation] = useState([])
  const [selectRegLaw, setSelectRegLaw] = useState([])
  const [selectDisclosure, setSelectDisclosure] = useState([])
  const [regrId, setRegrID] = useState(null)
  const [modalDisID, setModalDisID] = useState("")
  const [modalObID, setModalObID] = useState("")
  const [modalRegLawID, setModalRegLawID] = useState("")
  const [withData, setWithData] = useState(false)
  const [rowID, setRowID] = useState()
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [linkAccess, setLinkAccess] = useState([])
  const [downloadAccess, setDownloadAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const location = useLocation()

  useEffect(() => {
    if (location.state && location.state.rowData) {
      setSearchObject({
        ...searchObject,
        ["regLaw"]: location.state.rowData.regShortName,
      })
    }
  }, [location])

  // console.log(typeof(process.env.REACT_APP_ADMIN_ID), "ADMIN URL")
  const [sortBy, setSortBy] = useState({
    // tableName: "disObjLink",
    tableName: "disclosure",
      fieldName: "disclosureTitle",
      order: "ASC",
  })

  useEffect(() => {
    setSortBy(prevSortBy => ({
      ...prevSortBy,
      tableName: "disclosure",
    }))
  }, [checkOrg])

  const dispatch = useDispatch()

  const limit = 20

  const handleSort = column => {
    console.log(column.id, "columns")
    var finalFieldName

    column.id === "disclosure"
      ? "disclosureTitle"
      : column.id === "obligation"
      ? "obligationName"
      : column.id

    if (column.id === "disclosure") {
      finalFieldName = "disclosureTitle"
    } else if (column.id === "obligation") {
      finalFieldName = "obligationName"
    } else {
      finalFieldName = column.id
    }

    const isAsc = sortBy.fieldName === finalFieldName && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName: `${
          column.id === "disclosure"
            ? "disclosure"
            : column.id === "obligation"
            ? "obligation"
            : "disObjLink"
        }`,
        // fieldName: column.id,
        fieldName: `${
          column.id === "disclosure"
            ? "disclosureTitle"
            : column.id === "obligation"
            ? "obligationName"
            : column.id
        }`,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setLinkAccess(authToken && authToken.data.role.rolesPermission[4])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const customePageCount = Math.ceil(totalItems / limit)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const toggle = data => {
    console.log(data, "data")
    setModal(!modal)
    setRowID(data.id)
    setWithData(true)
    if (data.disclosure) {
      setModalDisID(data.disclosure.id)
    }
    if (data.obligation) {
      setModalObID(data.obligation.id)
    }
    if (data.obligation && data.obligation.regulation) {
      setModalRegLawID(data.obligation.regulation.id)
    }
  }

  const closeModal = () => {
    setModal(false)
    resetFormValues()
  }

  const onClickDelete = reg => {
    // setOrder(order);
    console.log("reg", reg)
    setDelRegulation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')
    // setDelete(!is_delete)
    if (delRegulation.id) {
      // dispatch(onDeleteLink(delRegulation.id, props.history))
      deleteLink(delRegulation.id)
      setDeleteModal(false)
    }
  }

  const reg_data = [
    {
      id: 1,
      dis_id: "D_001",
      d_name: "Disclosure 01",
      ob_name: "Obligation 0001_0010",
      ob_id: "OB_0001_0010",
      con_id: "OB_0001_0010_D_001",
    },
    {
      id: 2,
      ob_id: "OB_0001_0020",
      dis_id: "D_002",
      d_name: "Disclosure 03",
      ob_name: "Obligation 0001_0020",
      con_id: "OB_0001_0020_D_002",
    },
    {
      id: 3,
      ob_id: "OB_0002_0010",
      dis_id: "D_003",
      d_name: "Disclosure 03",
      ob_name: "Obligation 0002_0010",
      con_id: "OB_0002_0010_D_003",
    },
    {
      id: 4,
      ob_id: "OB_0002_0020",
      dis_id: "D_004",
      d_name: "Disclosure 05",
      ob_name: "Obligation 0002_0020",
      con_id: "OB_0002_0020_D_004",
    },
  ]

  const columns = [
    {
      Header: "Disclosure ID",
      accessor: "disId",
      filterable: false,
      disableFilters: true,
      width:100,
    },
    {
      Header: "Disclosure Title",
      accessor: checkOrg === 3 ? "disclosure" : "custDisclosure",
      filterable: false,
      disableFilters: true,
      width:350,
      Cell: cellProps => {
        // console.log(cellProps.value, "dis props")
        // return cellProps.value && cellProps?.value?.disclosureTitle.length > 10
        // ? cellProps?.value?.disclosureTitle.substr(0, 18) + " ..."
        // : cellProps?.value?.disclosureTitle || "-"
        const title =
          (cellProps?.value && cellProps?.value?.disclosureTitle) || "-"
        return (
          ColumnDataWithTooltip({content:cellProps?.value && cellProps?.value?.disclosureTitle })  
        )
      },
    },
    {
      Header: "Obligation ID",
      accessor: "obId",
      width: 150,
      filterable: false,
      disableFilters: true,

    },
    {
      Header: "Obligation Name",
      accessor: "obligation",
      accessor: checkOrg === 3 ? "obligation" : "custObligation",
      filterable: false,
      disableFilters: true,
      width:300,
      Cell: cellProps => {
        // console.log(cellProps.value, "obs props")
        
        return ColumnDataWithTooltip({content:cellProps?.value && cellProps?.value?.obligationName })  
      },
    },
    {
      Header: "Content ID",
      accessor: "contentId",
      width: 150,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        // console.log(cellProps.value, "obs props")
        
        return ColumnDataWithTooltip({content:cellProps?.value   })  
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      sticky: "right",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "1.2rem" }}
          >
            {/* <LightTooltip  */}

            {linkAccess && linkAccess.writeAccess === true ? (
              <Badge className="font-size-15 badge-soft-success" pill>
                <LightTooltip title="Edit">
                  <i
                    //   onClick={() => handleView(value)}
                    onClick={() => {
                      toggle(rowData)
                    }}
                    style={{ color: "#34C38F", cursor: "pointer" }}
                    className="bx bx-edit-alt  font-size-18"
                    // id="edittooltip"
                  ></i>
                  {/* </Link> */}
                </LightTooltip>
              </Badge>
            ) : (
              ""
            )}

            {linkAccess && linkAccess.writeAccess === true ? (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                {/* <li
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                  > */}
                <LightTooltip title="De-Link">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </LightTooltip>
                {/* </li> */}
              </Badge>
            ) : (
              ""
            )}
          </ul>
        )
      },
    },
  ]
  // const data = makeData

  const modifiedColumns =
    linkAccess && linkAccess.writeAccess === true && checkOrg === 3
      ? columns
      : columns.filter(column => column.Header !== "Action")

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
    menuList: (base) => ({
      ...base,
      maxHeight: "150px" // your desired height
    })
  }

  const fetchData = searchObject => {
 
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log(filterArray, "filterArray")
    const storageFilter = JSON.parse(localStorage.getItem("home/link-table"))??{}
    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject
    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData=   {...searchObject,...filterArray}

    let apiUrl
    if (checkOrg !== undefined && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-disclosure-obligation-link/?limit=${customePageSize}&pageNo=${currentPage}`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-disclosure-obligation-organization-link/?limit=${customePageSize}&pageNo=${currentPage}`
    }

    setLoader(true)
    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } else {
      localStorage.removeItem("selectedFilterArray")
    }
    const requestData = {
      ...storageFilter,
      ...finalData,
      customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      orderBy: {
        ...sortBy,
      },
    }
    
    axios
      .post(apiUrl, 
        {data:encryptData(requestData)}
        // requestData
        , 
        {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        console.log({ response })
        if (response.status >= 200 || response.status <= 299) {
          localStorage.removeItem("home/link-table")
          // return response.data;
          setReqresData(response.data.data)
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteLink = link => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-disclosure-obligation-link?id=${link}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // if (response.status >= 200 || response.status <= 299) return response.data;
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Link Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        ToastError(err)
        // throw message;
      })
  }

  useEffect(() => {
    console.log("page is loading")
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (modal) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}get-all-disclosures`, {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        })
        .then(response => {
          // console.log('res',response.data)
          if (response.status >= 200 || response.status <= 299) {
            // return response.data;
            setSelectDisclosure(
              response.data.data.map(option => ({
                value: option.id,
                // label: `${option.disId} + ${option.id}`
                label: `${option.disId} (${
                  option.disclosureTitle && option.disclosureTitle.length > 8
                    ? option.disclosureTitle.substr(0, 45) + " ..."
                    : option.disclosureTitle || "-"
                })`,

                // label: option.disId
              }))
            )
            // setTotalItems(response.data.totalRecords)
            // setLoader(false)
          }
          // throw response.data;
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }, [modal])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (modal) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/get-all-regulations`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            console.log(response, "Regulations response")
            const optionData = response.data.data.map(option => ({
              value: option.id,
              label: option.regshortname,
            }))

            const modifiedOptions = [
              { label: "Select Reg Law Short Name", value: "" }, // 0 option
              ...optionData, // API options
            ]

            setSelectRegLaw(modifiedOptions)
          }

          //   setSelectRegLaw(response.data.data.map(option => ({
          //     value: option.id,
          //     label: option.regShortName
          //   })))
          // }
          // throw response.data;
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          // throw message;
          ToastError(err)
        })
    }
  }, [modal])

  const fetchObligations = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      regulationId: regrId,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-all-obligations`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        
        console.log("res", response.data)
        if (response.status >= 200 || response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: `${option.obId} (${
              option.obligationName && option.obligationName.length > 8
                ? option.obligationName.substr(0, 45) + " ..."
                : option.obligationName || "-"
            })`,
          }))

          const modifiedOptions = [
            { label: "Select Obligations", value: "" }, // 0 option
            ...optionData, // API options
          ]

          setSelectObligation(modifiedOptions)
          // return response.data;
          // setSelectObligation(response.data.data.map(option => ({
          //   value: option.id,
          //   label: `${option.obId} (${option.obligationName && option.obligationName.length > 8
          //     ? option.obligationName.substr(0, 45) + " ..."
          //     : option.obligationName ||'-'
          //   })`
          // }
          // )))
          // setTotalItems(response.data.totalRecords)
          // setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    if (regrId) {
      fetchObligations()
    }
  }, [regrId])

  const resetFormValues = () => {
    setModalDisID("")
    setModalRegLawID("")
    setModalObID("")
    validation.resetForm()
  }

  const handleRegrID = id => {
    if (id === 0 || id === null) {
      setRegrID(null)
      setSelectObligation([{ label: "Select Regulations", value: 0 }])
    } else {
      console.log("reg id", id)
      setRegrID(id)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      dis_id: (modalDisID && modalDisID) || "",
      reg_id: (modalRegLawID && modalRegLawID) || "",
      ob_id: (modalObID && modalObID) || "",
    },
    validationSchema: Yup.object({
      ob_id: Yup.string().required("Please Select Obligation ID"),
      dis_id: Yup.string().required("Please Select Disclosure ID"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      if (withData === false) {
        const addData = {
          ObligationIds: values.ob_id.toString(),
          disclosureId: values.dis_id.toString(),
        }
        console.log("addData", addData)
        const authToken = JSON.parse(localStorage.getItem("authUser"))
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}add-disclosure-obligation-link`,
            {data:encryptData(addData)},
            {
              headers: {
                Authorization: `Bearer ${authToken.data.authToken}`,
              },
            }
          )
          .then(response => {
            if (response.status >= 200 || response.status <= 299) {
              // setSuccess(true)
              toastr.success("Link Successfully Added.")
              setDelete(!is_delete)
              setModal(false)
              setModalDisID("")
              setModalObID("")
              setModalRegLawID("")
              validation.resetForm()
              return response.data
            }
          })
          .catch(err => {
            RedirectOnUnAuthentication(err,navigateTo)
            let message
            if (err.response && err.response.status) {
              switch (err.response.status) {
                case 404:
                  message =
                    "Sorry! the page you are looking for could not be found"
                  break
                case 400:
                  message = "Bad request! Already Linked"
                  break
                case 500:
                  message =
                    "Sorry! something went wrong, please contact our support team"
                  break
                case 401:
                  message = "Invalid credentials"
                  break
                default:
                  message = err[1]
                  break
              }
            }
            // return message
            // throw message;
            ToastError(err)
          })
      } else {
        // console.log(values, "values")
        const updateData = {
          id: rowID && rowID,
          ObligationIds: values.ob_id.toString(),
          disclosureId: values.dis_id.toString(),
        }
        console.log("update", updateData)
        const authToken = JSON.parse(localStorage.getItem("authUser"))
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}update-disclosure-obligation-link`,
            {data:encryptData(updateData)},
            {
              headers: {
                Authorization: `Bearer ${authToken.data.authToken}`,
              },
            }
          )
          .then(response => {
            if (response.status >= 200 || response.status <= 299) {
              toastr.success("Link Successfully Updated.")
              setDelete(!is_delete)
              setModal(false)
              setModalDisID("")
              setModalObID("")
              setModalRegLawID("")
              validation.resetForm()
              return response.data
            }
          })
          .catch(err => {
            RedirectOnUnAuthentication(err,navigateTo)
            let message
            if (err.response && err.response.status) {
              switch (err.response.status) {
                case 404:
                  message =
                    "Sorry! the page you are looking for could not be found"
                  break
                case 400:
                  message = "Bad request! Already Linked."
                  break
                case 500:
                  message =
                    "Sorry! something went wrong, please contact our support team"
                  break
                case 401:
                  message = "Invalid credentials"
                  break
                default:
                  message = err[1]
                  break
              }
            }
            ToastError(err)
            // return message
          })
      }
    },
  })
  const handleCloseModal = () => {
    console.log("close")
    validation.resetForm()
    setModal(false)
    setModalDisID("")
    setModalObID("")
    setModalRegLawID("")
  }

  const handleRender = () => {
    setDelete(!is_delete)
  }

  document.title = "Links Table"
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Regulations" breadcrumbItem="Regulations" /> */}
          <DeLinkModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            onClose={handleCloseModal}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader onClick={closeModal}>
              Link Obligation with Disclosure
            </ModalHeader>
            <ModalBody className="text-left">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Disclosure</Label>
                        <Select
                          options={selectDisclosure}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="dis_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={selectDisclosure.find(
                            option => option.value === validation.values.dis_id
                          )}
                          onChange={option => {
                            validation.setFieldValue("dis_id", option.value),
                              validation.setFieldValue("disId", option.label)
                          }}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.dis_id &&
                        validation.errors.dis_id ? (
                          <div className="error">
                            {validation.errors.dis_id}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Reg Law Short Name</Label>
                        <Select
                          options={selectRegLaw}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="reg_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={selectRegLaw.find(
                            option => option.value === validation.values.reg_id
                          )}
                          onChange={option => {
                            validation.setFieldValue("reg_id", option.value)
                            handleRegrID(option.value)
                            validation.setFieldValue("ob_id", "") // Reset regulationId to 0 when regulatorId changes
                          }}
                          // onChange={(option) =>  {validation.setFieldValue('reg_id', option.value),
                          //   validation.setFieldValue('disId', option.label)}}
                          // onBlur={validation.handleBlur}
                        />
                        {validation.touched.reg_id &&
                        validation.errors.reg_id ? (
                          <div className="error">
                            {validation.errors.reg_id}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Obligations</Label>
                        <Select
                          options={selectObligation}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="ob_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={selectObligation.find(
                            option => option.value === validation.values.ob_id
                          )}
                          onChange={option => {
                            validation.setFieldValue("ob_id", option.value),
                              validation.setFieldValue("obId", option.label)
                          }}
                          onBlur={validation.handleBlur}
                          isDisabled={!validation.values.reg_id}
                        />
                        {validation.touched.ob_id && validation.errors.ob_id ? (
                          <div className="error">{validation.errors.ob_id}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button type="submit" className="me-2" color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={closeModal}
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form>
            </ModalBody>
            {/* <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            </ModalFooter> */}
          </Modal>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                    toggle={toggle}
                    filterArray={filterArray}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    setModal={setModal}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    handleRender={handleRender}
                    searchObject={searchObject}
                    linkAccess={linkAccess}
                    downloadAccess={downloadAccess}
                    fetchData={fetchData}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    setSearchObject={setSearchObject}
                    setFilterArray={setFilterArray}
                    columns={modifiedColumns}
                    data={reqresData}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(LinkTable)
