import React, { useMemo, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  BreadcrumbItem,
  Spinner,
} from "reactstrap" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteModal from "../../../components/Common/DeleteModal"
import { useDispatch } from "react-redux"
import { deleteDisclosure as onDeleteDisclosure } from "../../../store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import HtmlDiff from "htmldiff-js"
import PDFGenerator from "./PdfComponent"
import "./diff.css"
import axios from "axios"
import { RedirectOnUnAuthentication, hexHtmlToString } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const CustDisclosureview = props => {
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [deleteDisc, setDeleteDiscl] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const [disclosureAccess, setDisclosureAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [newModal, setNewModal] = useState(false)
  const [oldString, setOldString] = useState("")
  const [newString, setNewString] = useState("")
  const [diffContent, setDiffContent] = useState("")
  const [disclosureDetail, setDisclosureDetail] = useState([])
  const Location = useLocation()
  const IsCustlink = Location.pathname.includes("cust-updated-obli-report-view-discro")
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const [loader, setLoader] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDisclosureAccess(authToken && authToken.data.role.rolesPermission[3])
  }, [])

  const toggle = () => setModal(!modal)

  const newToggle = () => {
    setNewModal(!newModal)
  }

  const data = location.state.rowData
  const tdata = location.state.rowData
  // console.log(data.obligation.disclosure_obligations_obligation, "data")
  let rowData = data
  document.title = "Disclosure Review Report"

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  function firstHtml() {
    // const oldContent = oldString.replace(/<[^>]*>/g,'')
    return { __html: oldString }
  }

  function secondHtml() {
    // const newContent = newString.replace(/<[^>]*>/g,'')
    return { __html: newString }
  }

  function diffHtml() {
    // console.log(firstHtml(), secondHtml())
    return {
      __html: HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
    }
  }

  const handleDiff = () => {
    const diffHtmlResult = diffHtml()
    setDiffContent(diffHtmlResult)
  }

  const disclosureList =
    data &&
    data?.obligation &&
    data?.obligation?.disclosure_obligations_obligation?.length > 0
      ? data?.obligation?.disclosure_obligations_obligation?.map(
          item =>
            `<tr>
      <th scope="row">${item.disId}</th>
      <td>${item.disclosure.disclosureTitle.substr(0, 25) + "..."}</td>
     
  </tr>`
        )
      : ` <tr>
      <th scope="row">No Records Found</th> 
  </tr>`

  const content = `<div className="inner">
  <div>DIFFERENCE</div>
  <div dangerouslySetInnerHTML={${diffHtml()}} />
</div>`

  console.log(content, "content")

  const fetchData = async obligationId => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setLoader(true)
    const requestData = {
      obligationId,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-disclosures-by-obligation/?limit=10&pageNo=1`,
        {data: encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          // if(requestData.data.data){
          setDisclosureDetail(response.data.data)
          // }else{
          //   setDisclosureDetail([])
          // }
          // setOldString(response.data.data[0].oldobligationDesc)
          // setNewString(response.data.data[0].obligationDesc)
          console.log(response.data, "response.data.data")
          // var ids = []
          // if (response.data.ids !== undefined && response.data.ids !== "") {
          //   ids = response.data.ids.split(",")
          // }
          // localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message

        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    console.log(data)
    if (data && data.custObligationId) {
      fetchData(data.custObligationId)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content" onClick={()=>console.log(disclosureDetail)}>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs
            title="Scanned Report"
            // breadcrumbItem={updateTitle}
            path="updated-obli-report"
            breadcrumbItem={"Updated Report List"}
          /> */}
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {"LINKED DISCLOSURE LIST"}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname:IsCustlink?"/cust-new-obli-link": "/new-obli-link",
                          // state: { rowData: {id: data.queryId} },
                        }}
                      >
                        {"Scanned Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: IsCustlink?"/cust-updated-obli-report-link":"/updated-obli-report-link",
                          state: {
                            rowData: {
                              id: data.queryId,
                              createdDate:props?.location?.state?.rowData?.createdDate ,
                              clear: false,
                            },
                          },
                        }}
                      >
                        {"Disclosure Review Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {"Linked Disclosures List"}
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}> Disclosure Text</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={newModal}
            toggle={newToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={newToggle}>New Citation Content</ModalHeader>
            <ModalBody className="text-left">
              <div className="inner">
                {/* <div>DIFFERENCE</div> */}
                <div dangerouslySetInnerHTML={diffHtml()} />
              </div>
              {/* <StringDiff oldValue={oldString} newValue={newString} /> */}
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={newToggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    <table className="table table-responsive mt-3 px-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Disclosure Name</th>
                          <th scope="col">Disclosure Text</th>
                        </tr>
                      </thead>
                      {loader ? (
                        <tbody>
                          <tr>
                            <td
                              className="container-fluid mt-5 mb-5"
                              colSpan={3}
                              style={{
                                width: "100%",
                                textAlign: "center",
                                paddingBlock: "50px",
                              }}
                            >
                              <Spinner className="ms-2" color="primary" />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <>
                          <tbody>
                            {data &&
                            disclosureDetail &&
                            disclosureDetail.length > 0 ? (
                              disclosureDetail.map(item => (
                                <tr key={item.id}>
                                  <td scope="row">{item.disclosure_id||"-"}</td>
                                  <td>
                                    {item.disclosure_disclosureTitle ?
                                     ( item.disclosure_disclosureTitle.substr(
                                        0,
                                        25
                                      ) + "..."):"-"}
                                  </td>
                                  <td>
                                    <DarkTooltip
                                      title="View Details"
                                      style={{ width: "15px" }}
                                    >
                                      <div>
                                        { item.disclosure_text?   <i
                                          onClick={() => {
                                            setModal(!modal)
                                            setDesc(
                                              item.disclosure_text
                                            )
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            textAlign: "center",
                                            padding: "0 5px",
                                            borderRadius: "20%",
                                            color: "#556ee6",
                                          }}
                                          className="bx bxs-info-circle  font-size-24"
                                          id="descToolTip"
                                        ></i>:"-"}
                                     
                                        <div
                                          onClick={() => {
                                            setModal(!modal)
                                            setDesc(
                                              item.disclosure_disclosureDesc
                                            )
                                          }}
                                        ></div>
                                      </div>
                                    </DarkTooltip>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan="3">
                                  <h4 style={{ marginTop: 5 }}>
                                    No Records Found
                                  </h4>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </>
                      )}
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustDisclosureview
