import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import "./_FilterModal.scss"
// import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import A2ZImg from "../../assets/images/FilterModalImg/A2Z.svg"
import Z2AImg from "../../assets/images/FilterModalImg/Z2A.svg"
import SearchImg from "../../assets/images/FilterModalImg/bx-search 1.png"
import axios from "axios"
import { Spinner } from "reactstrap"
import InfiniteScroll from "react-infinite-scroll-component"
import { CheckValidValue, CirculareLoader, GetObjectWithSpecificKeys, RedirectOnUnAuthentication, RenameKeysOfObject, SmoothSearchLoader, ToastError, TrimmedValue, UpdateObjectByKeyMatches, encryptData, removeDuplicatesRecords } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { HEADER } from "helpers/url_helper"
// import { debounce } from "lodash"

const FilterModal = ({
  isShowModal,
  filterData,
  setShowModal,
  tableName,
  setFilterArray,
  setSelectedHeader,
  clearFilterModal,
  relationFeild,
  isUnlink,
  isObliReport,
  custOrganizationId,
  reportId = "",
  isRemoveDuplicates,
  filterableObjects,
  isReview,
  emptyTable, 
}) => {
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [localSelectionData, setSelectionLocalData] = useState("")
  const [searchData, setSearchData] = useState("")
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [handleScrollLoader, setHandleScrollLoader] = useState(false)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const [checkOrg, setCheckOrg] = useState() 
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState("ASC")
  const [hasMore, setHasMore] = useState(true)
  const [firstTimeLoading, setFirstTimeLoading] = useState(true) 
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const filterObjects = filterableObjects?filterableObjects:{}
  const [selectionArr, setSelectionArr] = useState(() => {
    const splitValues = filterData.id && filterData.id.split(".")

    let columnName
    if (splitValues && splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData && filterData.id
    }
    return localSelectionData
      ? JSON.parse(localSelectionData)[columnName] || []
      : []
  })
  const [localData, setLocalData] = useState(
    JSON.parse(localStorage.getItem("selectedData")) || {}
  )
  const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : [] 
  const [selectFilterArr, setSelectFilterArr] = useState({})

  if (filterData && filterData?.id === "disclosure") {
    filterData = { ...filterData, id: "disclosureTitle" }
  }

  if (filterData && filterData?.id === "obligation") {
    filterData = { ...filterData, id: "obligationName" }
  }

  if (
    filterData &&
    filterData?.id === "disclosure_obligations_obligation_contentId"
  ) {
    filterData = { ...filterData, id: "contentId" }
  }

  if (filterData && filterData?.id === "obligation_obId") {
    filterData = { ...filterData, id: "obId" }
  }

  if (filterData && filterData?.id === "obligation_obligationName") {
    filterData = { ...filterData, id: "obligationName" }
  }

  if (filterData && filterData?.id === "obligation_obligationDesc") {
    filterData = { ...filterData, id: "obligationDesc" }
  }

  if (filterData && filterData?.id === "obligation_citationUrl") {
    filterData = { ...filterData, id: "citationUrl" }
  }
  if (filterData && filterData?.id === "obligation_issuingAuthority") {
    filterData = { ...filterData, id: "issuingAuthority" }
  }
  if (filterData && filterData?.id === "obligation_regLaw") {
    filterData = { ...filterData, id: "regLaw" }
  }
  if (filterData && filterData?.id === "obligation_citation") {
    filterData = { ...filterData, id: "citation" }
  }
  if (filterData && filterData?.id === "disclosure_disId") {
    filterData = { ...filterData, id: "disId" }
  }
  if (filterData && filterData?.id === "disclosure_disclosureTitle") {
    filterData = { ...filterData, id: "disclosureTitle" }
  }
  if (filterData && filterData?.id === "disclosure_disclosureDesc") {
    filterData = { ...filterData, id: "disclosureDesc" }
  }
  if (filterData && filterData?.id === "disclosure_text") {
    filterData = { ...filterData, id: "text" }
  }
  if (
    filterData &&
    filterData?.id === "disclosure_disclosureSpecificCitation"
  ) {
    filterData = { ...filterData, id: "disclosureSpecificCitation" }
  }

 
  const matchesAndNewValues = [
    ['custLinkingCustDisclosureCustObligation_contentId', 'contentId'], 
    ['custObligations_obligationName', 'obligationName'], 
    ['custObligations_obligationDesc', 'obligationDesc'], 
    ['custObligations_obId', 'obId'], 
    ['custObligations_citationUrl', 'citationUrl'], 
    ['obligation_citationUrl', 'citationUrl'], 
    ['custObligations_issuingAuthority', 'issuingAuthority'], 
    ['custObligations_regLaw', 'regLaw'],  
    ['custDisclosure_disId', 'disId'], 
    ['custObligations_citation', 'citation'], 
    ['custDisclosure_text', 'text'],  
    ['custDisclosure_disclosureTitle', 'disclosureTitle'], 
    ['custDisclosure_disclosureDesc', 'disclosureDesc'], 
    ['custDisclosure_disclosureSpecificCitation', 'disclosureSpecificCitation'],  

  ];
  const KeyRenew = UpdateObjectByKeyMatches( {...filterData},"id" ,matchesAndNewValues) 
  filterData = { ...KeyRenew  }
  // console.log("filterData12364",filterData);
  // console.log("filterData12364,KeyRenew",KeyRenew);
  var cnt = 0
  let localDataKey, localDataArr
  if (Object.keys(localData).length) {
    localDataKey = Object.keys(localData)[0]
    localDataArr = localData[filterData?.id]
  }
  const arrayOfselectedFilter = localStorage.getItem("selectedFilterArray")
    ? JSON.parse(localStorage.getItem("selectedFilterArray"))
    : {}

  useEffect(() => {
    setSelectionLocalData(localStorage.getItem("selectedFilterArray"))
    //  localStorage.removeItem('selectedFilterArray');
  }, [localStorage.getItem("selectedFilterArray")])

  useEffect(() => {
    // console.log('localSelectionData1',localSelectionData);
    // if (localSelectionData) {
    // console.log('localSelectionData','true');
    // console.log('localSelectionData',JSON.parse(localSelectionData || "[]")?.[filterData?.id]);

    const splitValues = filterData.id && filterData.id.split(".")

    let columnName
    if (splitValues && splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData && filterData.id
    }
    setSelectionArr(JSON.parse(localSelectionData || "[]")?.[columnName] || [])
    // } else {
    //     console.log('localSelectionData','false');
    // }
  }, [isShowModal])

  useEffect(() => {
    // if(clearFilterModal === true){
    setSelectionArr([])
    // console.log(clearFilterModal, "clearFilterModal")
    // }
  }, [clearFilterModal])
  // console.log('localSelectionData2',localSelectionData);
  useEffect(() => {
    setPage(1)
    setData([])

    if (isShowModal && filterData.id !== "" && tableName !== "") {
      handleChange()
    }
  }, [isShowModal])

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  // console.log(page, "page")
  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  const handleChange = e => {
    // console.log("handle chage function")
    setLoader(true)
    setPage(1)
    let searchVal
    if (e === undefined) {
      // console.log("e undefined1254")
      searchVal = ""
    } else {
      // console.log("edefined1254")
      searchVal = e.target.value
      setSearchData(e.target.value)
    }
    const splitValues = filterData?.id?.split(".")

    let columnName
    if (splitValues?.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData.id
    }

    // console.log("searchData", searchData,TrimmedValue(`${searchData}`))
    var requestData = {
      reportId,
      ...filterObjects, 
      tableName,
      feildName: columnName,
      searchValue:  TrimmedValue(searchData),
      ids: idsArray,
     
      relationFeild,
      isUnlink: isUnlink ? isUnlink : false,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
        isReview
      //   pageNo: page,
    }
    // if(columnName==='regulatorShortName') data={ tableName, feildName: columnName, searchValue: searchVal,
    //     customerorgId: checkOrg !== ""  && checkOrg !== 3 ? checkOrg !== ""  && parseInt(checkOrg) : "",}
    if (CheckValidValue({value:columnName})) {
      
   
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/autosearch?limit=20&pageNo=1&order=ASC`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const newData = Array.isArray(response.data.data)
            ? response.data.data
            : [] 
          setLoader(false)
          setHasMore(response.data.data.length > 0)
          setData([])
          setData(response.data.data)  
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        console.log({ error })
        ToastError(error)
        setLoader(false)
      })}
  }
// const CallToHandleChange = (e) =>{
//   const Fun = () => {
//     // if (optionShow) {
//       return  handleChange(e);
//     // }
//   };

//   const timeout = setTimeout(() => {
//     Fun();
//   }, 1000);
//   return () => clearTimeout(timeout);
// }
useEffect(()=>{
  const Fun = () => {
    // if (optionShow) {
      return  handleChange({ target: {value:searchData} });
    // }
  };

  const timeout = setTimeout(() => {
    Fun();
  }, 1000);
  return () => clearTimeout(timeout);
},[searchData])
  const handleScroll = e => {
    setLoader(true)
    setHandleScrollLoader(true)
    // setPage(1)
    let searchVal
    if (e === undefined) {
      searchVal = ""
    } else {
      searchVal = e.target.value
      setSearchData(e.target.value)
    }
    const splitValues = filterData?.id?.split(".")

    let columnName
    if (splitValues?.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData.id
    }

    // console.log(columnName, "columnNme")
    var requestData = {
      reportId,
      ...filterObjects,
      tableName,
      feildName: columnName,
      searchValue: searchData,
      ids: idsArray,
      
      relationFeild,
      isUnlink: isUnlink ? isUnlink : false,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
        isReview
      //   pageNo: page,
    }
    // if(columnName==='regulatorShortName') data={ tableName, feildName: columnName, searchValue: searchVal,
    //     customerorgId: checkOrg !== ""  && checkOrg !== 3 ? checkOrg !== ""  && parseInt(checkOrg) : "",}
    if (CheckValidValue({value:columnName})) {
      
   
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/autosearch?limit=20&pageNo=${page}&order=${order}`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          const newData = Array.isArray(response.data.data)
            ? response.data.data
            : []
            
          setData(prevData => [...prevData, ...newData])
          setLoader(false)
          setHandleScrollLoader(false)
          setHasMore(response.data.data.length > 0)
          
          // setData(response.data.data)
          // console.log(response.data.data)
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        console.log({ error })
        ToastError(error)
        setLoader(false)
        setHandleScrollLoader(false)
      })}
  }

  useEffect(() => {
    // console.log("25458, page change effect");
    if (isShowModal && filterData.id !== "" && tableName !== "") {
      handleScroll()
    }
    // handleScroll(); // Call handleChange to fetch data
  }, [page, isShowModal])
  //const debounceFn = useMemo(() => debounce(handleChange, 800), [])
  useEffect(() => {
    setSearchData("")
    setOrder("ASC")

  }, [  isShowModal])
  const handleCheckboxChange = e => {
    const { value, checked } = e.target
    // console.log(value)

    let selectedArr = [...selectionArr]; 

    if (selectedArr.includes(value) && !checked) {
      // setSelectionArr(selectedArr.filter(el => el != value))
      selectedArr = selectedArr.filter(el => el != value)
    }

    if (!selectedArr.includes(value) && checked) {
      // setSelectionArr(d => [...d, value])
      selectedArr = [...selectedArr, value]
    }

    const splitValues = filterData.id && filterData.id.split(".")
    let columnName
    if (splitValues && splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData && filterData.id
    }

    localStorage.setItem(
      "selectedFilterArray",
      JSON.stringify({
        ...arrayOfselectedFilter,
        [columnName]: selectedArr,
      })
    )
    setSelectionArr(selectedArr)
  }

  // console.log('selectionArr',selectionArr);
  const splitColumns = filterData && filterData?.id?.split(".")

  let columnSplited
  if (splitColumns && splitColumns.length > 1) {
    columnSplited = splitColumns[1]
  } else {
    columnSplited = filterData.id
  }

  const ArrObj = { [columnSplited]: selectionArr }

  const handleSubmit = () => {
    // setSelectionArr(d => [...d, arrayOfselectedFilter[Object.keys(arrayOfselectedFilter)[0]]])
    //  ArrObj[filterData.id].push(arrayOfselectedFilter[Object.keys(arrayOfselectedFilter)[0]]);
    //  arrayOfselectedFilter[Object.keys(arrayOfselectedFilter)[0]]

    if (selectionArr.length) {
      setSelectedHeader(d => [...d, filterData?.Header])
      setSelectionArr([])
      //setFilterArray(prevVal => ({...prevVal, [columnSplited] : ArrObj[columnSplited]}))
      // console.log(filterArray);

      if (columnSplited === "obId" && isObliReport === true) {
        if ( ["newErrorTestObligationReport","newErrorObligationReport"].includes(tableName)) {
          setFilterArray(prevVal => ({
            ...prevVal,
            [`${tableName}.obId`]: selectionArr,
          }))
        } else {
          setFilterArray(prevVal => ({
            ...prevVal,
            ["newObligationReport.obId"]: selectionArr,
          }))
        }
        
      } else if (columnSplited === "obligationName" && isObliReport === true) {
        setFilterArray(prevVal => ({
          ...prevVal,
          ["obligation.obligationName"]: selectionArr,
        }))
      } else if (columnSplited === "citationUrl" && isObliReport === true) {
        setFilterArray(prevVal => ({
          ...prevVal,
          ["obligation.citationUrl"]: selectionArr,
        }))
      } else if (columnSplited === "regulationSite" ){
        setFilterArray(prevVal => ({
          ...prevVal,
          ["newErrorTestObligationReport.regulationSite"]: selectionArr,
        }))
      } else {
        setFilterArray(prevVal => ({
          ...prevVal,
          [columnSplited]: selectionArr,
        }))
      }
      // setSelectFilterArr(prevVal => ({...prevVal, [filterData?.id] : ArrObj[filterData?.id]}))
      // console.log('selectFilterArr',selectFilterArr);
    }
    //  localStorage.setItem('selectedFilterArray', JSON.stringify(selectionArr));

    // axios
    //   .post(
    //     `http://35.238.71.28/v1/admin/get-regulators?limit=10&pageNo=1`,
    //     { ArrObj },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${authToken.data.authToken}`,
    //       },
    //     }
    //   )
    //   .then(response => {
    //     if (response.status === 200) {
    //       console.log(response.data.data)
    //     }
    //   })
    //   .catch(error => {
    //     console.log({ error })
    //   })
  }

  const AToZ = () => {
    setLoader(true)
    setOrder("ASC")
    setPage(1)
    setData([])
    const splitValues = filterData.id && filterData.id.split(".")
    let columnName
    if (splitValues && splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData && filterData.id
    }
    console.log(columnName, "columnName")
    var data = {
      reportId,
      ...filterObjects,
      tableName,
      feildName: columnName,
      searchValue: TrimmedValue(searchData),
      ids: idsArray,
   
      relationFeild,
      isUnlink: isUnlink ? isUnlink : false,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }
    // if(columnName==='regulatorShortName') data={ tableName, feildName: columnName,customerorgId: checkOrg !== ""  && checkOrg !== 3 ? checkOrg !== ""  && parseInt(checkOrg) : "",}
    if (CheckValidValue({value:columnName})) {
      
   
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/autosearch?limit=20&pageNo=1&order=ASC`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          setLoader(false)
          setData(response.data.data)
          // console.log(response.data.data)
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        console.log({ error })
        ToastError(error)
        setLoader(false)
      }) }
  }

  const ZToA = () => {
    // const ztoaData = data.sort((a, b) => {
    //   if (b[filterData?.id] < a[filterData?.id]) {
    //     return -1
    //   }
    //   if (b[filterData?.id] > a[filterData?.id]) {
    //     return 1
    //   }
    //   return 0
    // })
    // setData([...ztoaData])
    setOrder("DESC")
    setPage(1)
    setLoader(true)
    setData([])
    const splitValues = filterData.id && filterData.id.split(".")
    let columnName
    if (splitValues && splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = filterData && filterData.id
    }
    var data = {
      reportId,
      ...filterObjects,
      tableName,
      feildName: columnName,
      searchValue: TrimmedValue(searchData),
      ids: idsArray,
      
      relationFeild,
      isUnlink: isUnlink ? isUnlink : false,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }
    // if(columnName==='regulatorShortName') data={ tableName, feildName: columnName,customerorgId: checkOrg !== ""  && checkOrg !== 3 ? checkOrg !== ""  && parseInt(checkOrg) : "",}
    if (CheckValidValue({value:columnName})) {
      
   
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/autosearch?limit=20&pageNo=1&order=DESC`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status === 200) {
          setLoader(false)
          setData(response.data.data)
          // console.log(response.data.data)
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        console.log({ error })
        ToastError(error)
        setLoader(false)
      })}
  }

  const splitValues = filterData.id && filterData.id.split(".")
  let columnName
  if (splitValues && splitValues.length > 1) {
    columnName = splitValues[1]
  } else {
    columnName = filterData && filterData.id
  }
useEffect(()=>{
  page>2?setFirstTimeLoading(false):setFirstTimeLoading(true)
},[page])

 const test =() =>{
  console.log("test0502 page isRemoveDuplicates",data,); 
  console.log("test0502 page filterData.id",filterData.id,); 
 }
  return (
    <>
      <a id="Filter_Modal" onClick={handleShow}></a>
      <Modal
        show={isShowModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal_default"
        contentClassName="modal_default"
      >
        <Modal.Body>
          <div
            style={{ marginTop: "-10px" }}
            className="modal-title text-center"
            onClick={test}
          >
            {filterData?.Header}
          </div>
          <div className="d-flex align-items-center">
            <img src={A2ZImg} alt="a_to_z" />
            <h5
              style={{ cursor: "pointer",color:order=="ASC"?"#556ee6":"" }}
              className="modal-title px-1 my-2 ps-2"
              id="FilterModalLabel"
              onClick={AToZ}

            >
              Sort A to Z
            </h5>
          </div>
          <div className="modal-line" />
          <div className="d-flex align-items-center">
            <img src={Z2AImg} alt="z_to_a" />
            <h5
              style={{ cursor: "pointer",color:order=="DESC"?"#556ee6":"" }}
              className="modal-title px-1 my-2 ps-2"
              id="FilterModalLabel"
              onClick={ZToA}
            >
              Sort Z to A
            </h5>
          </div>
          <div className="modal-line" />
          <div className="modal-search position-relative w_sub">
            <input
              style={{width:'100%'}}
              type="text"
              placeholder="Search update sources"
              value={handleScrollLoader?"Records are loading...":searchData}
              disabled ={handleScrollLoader}
              className="modal-line search_dflt py-1 ps-2 py-2 mb-1 mt-2 w-100"
              onChange={(e)=>handleScrollLoader?()=>{}:setSearchData(`${e.target.value}`.trimStart())}
            />
            <img
              src={SearchImg}
              alt="search_box"
              className="pe-2 position-absolute search_icon"
            />
          </div>
          <div
            id="scrollCheck"
            className="modal-line overflow-auto w_sub h_sub bor rounded"
          >
            {/* <p className="text-center mt-3"> */}
           
            <InfiniteScroll
            id={"Style1"}
              dataLength={data.length}
              next={() => setPage(prevPage => prevPage + 1)} // Load next page on scrolling
              hasMore={hasMore} // Boolean indicating whether there is more data to load
              loader={<CirculareLoader   />} // Loader component to show while loading next page
              scrollableTarget="scrollCheck" // Provide the ID of the scrolling container
            >
              {loader ? <SmoothSearchLoader/> : ""}
              {/* </p> */}

              {emptyTable || data && data?.length === 0 && !loader ? (
                <h5 className="text-center mt-2">No Data Found!</h5>
              ) : (
                data &&
                data.length > 0 &&
                removeDuplicatesRecords(data,isRemoveDuplicates,filterData.id,firstTimeLoading)?.map((dt, i) => {
                  var checkedOpt = false
                  var neededKeys = [filterData.id]
                  console.log("neededKeys======>",neededKeys)
                  neededKeys.every(key => {
                    if (Object.keys(arrayOfselectedFilter).includes(key)) {
                        console.log(arrayOfselectedFilter[key], "arrayOfselectedFilter")
                      //   console.log(dt[filterData.id], "from local storage from list")
                      if (
                        arrayOfselectedFilter[key]&&   arrayOfselectedFilter[key].includes(dt[filterData.id])
                      ) {
                        checkedOpt = true
                        console.log(
                          checkedOpt,
                          dt[filterData.id],
                          "from local storage checkbox"
                        )
                      }
                    }
                  })
                  const splitValues = filterData.id && filterData.id.split(".")
                  let columnName
                  if (splitValues && splitValues.length > 1) {
                    columnName = splitValues[1]
                  } else {
                    columnName = filterData && filterData.id
                  }

                  return (
                    <div
                      key={dt + i}
                      className="form-check my-2 mx-1 d-flex align-items-center gap-2"
                    >
                      <label
                        className="form-check-label modal-text text-nowrap"
                        htmlFor={`item_${i}`}
                      >
                        <input
                          className="form-check-input mt-0"
                          // defaultChecked={checkedOpt}
                          checked={selectionArr.includes(dt[columnName])}
                          style={{ minWidth: "14px", minHeight: "14px" }}
                          type="checkbox"
                          value={dt[columnName]}
                          id={`item_${i}`}
                          onClick={e => handleCheckboxChange(e)}
                        />
                        {dt[columnName]}
                      </label>
                    </div>
                  )
                }) 
              )}
            </InfiniteScroll>
          </div>

          <div className="modal-btn py-3">
            <button
              type="button"
              className="model-button mx-2 btn btn-md btn-primary"
              onClick={() => {
                setShowModal(false)
                handleSubmit()
              }}
              disabled={selectionArr?.length > 0 ? false : true}
            >
              Ok
            </button>
            <button
              style={{ backgroundColor: "#74788d" }}
              type="button"
              className="model-button mx-2"
              data-bs-dismiss="modal"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FilterModal
