import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import debounce from "lodash/debounce"
import toastr from "toastr"
import PropTypes from "prop-types"
import axios from "axios"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom" 
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Tooltip from "@material-ui/core/Tooltip"
import { map } from "lodash"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  Badge,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import styled from "styled-components"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import Pagination from "../../../components/Common/Pagination"
import { Filter, DefaultColumnFilter } from "../../../components/Common/filters"
import JobListGlobalFilter from "../../../components/Common/GlobalSearchFilter"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { deleteRegulation as onDeleteRegulation } from "../../../store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import FilterModal from "../../../components/FilterModal/FilterModal"
// import { TableHeader, Search } from "../../Datatable/index"
import TableHeader from "components/Common/TableHeader"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { format } from "date-fns"
import { Popper } from "@material-ui/core"
import Flatpickr from "react-flatpickr"
import InfiniteScroll from "react-infinite-scroll-component"
import {
  CheckValidValue,
  ColumnDataWithTooltip,
  DisplayJustifyCenter,
  DownloadExelFrom,
  FormatedDate,
  RedirectOnUnAuthentication,
  RemoveSpaces,
  ToastError,
  convertObjectValuesToArray,
  copyObjectExceptKeys,
  encryptData,
  handleNullAndUndefinedOfArray,
  trimLeft,
} from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const csvHeader = [
  "ID",
  "Reg Law Short Name",
  "CFR Ref",
  "Reg Long Name",
  "Reg Description",
  "Reg URL", 
  "Reg Related Law",
  "Reg Effective Date",
  "Regulator Short Name",
  "Reg Primary Supervisor",
  "Status",
  "Reg Last Update Date",
  "Reg Compliance Guide URL",
  "Created At",
]
const exportToExcel = rowData => {
  console.log(rowData, "selectedrows")

  const customizedData = rowData.map(row => {
    const updatedDate =FormatedDate(row.updatedDate,true)  
    const createdDate =FormatedDate(row.createdDate,true)   
    const EffectiveDate =FormatedDate(row.regEffectiveDate,true)  

    const keysToExclude = ['regulator' ];
    const copiedObject = copyObjectExceptKeys({...row}, keysToExclude);
    // return {
    //   ...copiedObject,
    //   regDescription: row.regDescription.replace(/<[^>]+>/g, ""),
    //   status: row.status == true ? "Yes" : "No",
    //   regEffectiveDate: row.regEffectiveDate ? EffectiveDate : "-",
    //   updatedDate: row.updatedDate ? updatedDate : "-",
    //   createdDate: createdDate,
    //   // regulator: row.regulator ? row.regulator.regulatorShortName : "NA",
    // }

    return {
      regId: row.regId,
      regShortName: row.regShortName,
      cfrRef: row.cfrRef,
      regLongName: row.regLongName,
      regDescription: row.regDescription.replace(/<[^>]+>/g, ""),
      regUrl: row.regUrl, 
      regRelatedLaw: row.regRelatedLaw,
      regAuthoringRegulator: row.regAuthoringRegulator,
      regPrimarySupervisor: row.regPrimarySupervisor,
      regEffectiveDate: row.regEffectiveDate ? EffectiveDate : "-",
      regComplianceGuideUrl: row.regComplianceGuideUrl,
      updatedDate: row.updatedDate ? updatedDate : "-",
      createdDate: createdDate,
    }
  })
  const filteredData = customizedData.map(({ id, status, ...rest }) => rest)
  const worksheet = XLSX.utils.json_to_sheet(filteredData, { csvHeader })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Reg ID",
        "Reg Law Short Name",
        "CFR Ref",
        "Reg Long Name",
        "Reg Description",
        "Reg URL", 
        "Reg Related Law", 
        "Regulator Short Name",
        "Reg Primary Supervisor",
        "Reg Effective Date",
        "Reg Compliance Guide URL",
        "Reg Last Update Date", 
        "Created At",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Regulations.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 250, fontSize: 12 }}
      placement="bottom-start"
      id="popper-div"
    />
  )
}

const TableContainer = ({
  loader,
  columns,
  pageCount: customePageCount,
  totalItems,
  currentPage,
  data,
  handleRender,
  checkOrg,
  setCheckOrg,
  handleSort,
  setSortBy,
  setCurrentPage,
  regulationAccess,
  downloadAccess,
  customePageSize,
  setCustomePageSize,
  props,
  filterArray,
  fetchData,
  searchObject,
  dateQuery,
  setFilterArray,
  setSearchObject,
  dateEffQuery,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    // useFlexLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useFlexLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedEffDate, setSelectedEffDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }


  useEffect(() => {
    const data = localStorage.getItem("regulations/all-regulations");

    let finalData;

    if (data != null && props?.location?.state?.rowData?.isClear != undefined) {
        finalData = JSON.parse(data);
        let modifiedObject = {};

        console.log(finalData, "finalData");

        for (let key in finalData) {
            if (finalData.hasOwnProperty(key)) {
                let newKey;
                let selectedkey;

                    newKey = key
                    selectedkey = key
             
                if (Array.isArray(finalData[key])) {
                    modifiedObject[newKey] = finalData[key][0];
                } else if (typeof finalData[key] === "string") {
                    modifiedObject[newKey] = finalData[key];
                } else {
                    console.log(`Value for key ${key} is not a valid string.`);
                }

                // Check if selectedkey matches any key in selectedFilterArray
                const selectedFilterArray = JSON.parse(localStorage.getItem("regulations/all-regulations/selectedFilterArray"));
                if (selectedFilterArray && selectedFilterArray.hasOwnProperty(selectedkey)) {
                    delete modifiedObject[newKey];
                }
            }
        }

        console.log(modifiedObject, "modifiedObject");
        setAutoEmpty(modifiedObject);
    }
}, [localStorage.getItem("regulations/all-regulations")]);

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      regulationIds: ids,
      customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/export-regulation`, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
        responseType:'blob'
      })
      .then(response => {
        // console.log(data.data.data, 'exporData');
        // exportToExcel(response.data.data)
        DownloadExelFrom({responseData:response.data,fileTitle: "Regulation"})
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data") 
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }

  const handleDownloadFormat = () => {
    const csvData =
      "Reg Law Short Name,CFR Ref,Regulation Long Name,Regulation Description,Regulation URL,Regulation Compliance Guide URL,Regulation Related Law,Regulation Effective Date (YYYY-MM-DD HH:mm:ss),Regulator Short Name Short Name,Reguation  Primary Supervisor"
    const filename = "Regulatations_format.csv"
    const csvBlob = new Blob([csvData], { type: "text/csv" })
    const csvURL = window.URL.createObjectURL(csvBlob)

    const tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", filename)
    tempLink.click()

    // Cleanup
    window.URL.revokeObjectURL(csvURL)
    tempLink.remove()
  }

  const handleSearchQueryChange = (columnKey, value) => {
    console.log("call to handleSearchQueryChange")
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    if (columnKey == "updatedDate") {
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      setSearchQuery(prev => ({ ...prev, [columnKey]: formattedDate }))
    } else if (columnKey == "regEffectiveDate") {
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedEffDate(formattedDate)

      setSearchQuery(prev => ({ ...prev, [columnKey]: formattedDate }))
    } else if (value === null) {
      setSearchQuery(prev => ({ ...prev, [columnKey]: "" }))
      // console.log('nulll')
    } else {
      setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
    }

    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []
    const data = {
      tableName: "Regulations",
      feildName: columnKey,
      searchValue: value,
      ids: idsArray,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (columnKey != "updatedDate" && columnKey != "regEffectiveDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }

        setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  const handleKeyDown = (key, columnId, value) => { 
    if (key === "Enter") {
      // fetchData(searchQuery)
      console.log("handleAutocompleteChange",columnId, value)
      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        setSelectedDate(formattedDate)
        updatedValue = formattedDate
      } else {
        updatedValue = value.trim() // Set the value to true when selecting "Yes"
      }
  
      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
  
      console.log("API call triggered:", value)
    }
  }

  const handleAutocompleteChange = (columnId, value) => {
    console.log("call to handleAutocompleteChange", columnId, value)

    const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);

    if (optionExists || value === "") {
      console.log("selected from suggestion box")

      let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate" || columnId == "regEffectiveDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      updatedValue = formattedDate
    } else {
      updatedValue = value.trim() // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: [updatedValue] }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    } else {
      let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate" || columnId == "regEffectiveDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      updatedValue = formattedDate
    } else {
      updatedValue = value.trim() // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    }

    

    console.log("API call triggered:", value)
  }

  const handleDateChange = date => {
    const newDate = new Date(date)
    const formattedDate = format(newDate, "yyyy-MM-dd")
    console.log(formattedDate, "formattedDate")
    setSelectedDate(formattedDate)
    dateQuery(formattedDate)
  }

  const handleEffDateChange = date => {
    const newDate = new Date(date)
    const formattedDate = format(newDate, "yyyy-MM-dd")
    console.log(formattedDate, "formattedDate")
    setSelectedEffDate(formattedDate)
    dateEffQuery(formattedDate)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  console.log(selectedDate, "selectedate")
  const handleEffKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedEffDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  // const handleKeyDown = event => {
  //   if (event.key === "Enter") {
  //     fetchData(searchQuery)
  //   }
  // }

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    localStorage.removeItem("regulations/all-regulations")
    localStorage.removeItem("regulations/all-regulations/selectedFilterArray")
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setFilterArray({})
    setSelectedHeader([])
    setSelectedDate(null)
    setSelectedEffDate(null)
    setSearchObject({})
    setCurrentPage(1)
    setSortBy({
      tableName: "regulation",
      fieldName: "regShortName",
      order: "ASC",
    })
    // fetchData({})

    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  console.log(searchQuery, "searchQuery")
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [updatedData, setUpdatedData] = useState("new")
  const [blankColumn, setBlankColumn] = useState("0")

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = data => {
    setModal(!modal)
    setSelectedFile(!selectedFile)
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-regulations`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            toggle()
            handleRender()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          toggle()
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
        setUpdatedData("new")
        setBlankColumn("0")
    }
  }

  const handleImportEditData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("isBlankUpdatable", blankColumn)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-regulation-update`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}` },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
        setUpdatedData("new")
        setBlankColumn("0")
    }
  }

  const edit = () => {
    props.history.replace({
      pathname: "/regulations/regulation_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "Regulations",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        console.log(err.response.data.message, "mesg")
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
               ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  useEffect(() => {
    if (props.location.search.length > 5) {
      const Qurry = {}
      props.location?.search?.split("?").map(i => {
        Qurry[i?.split("=")[0]] = i?.split("=")[1]
      })
      handleAutocompleteChange("regShortName", Qurry.RegLaw)
    }
  }, [])

  const SelectedFilterIcon = (filterArray,column_id)=>{
    const columnId = `${column_id}`.split("_").pop()

          try { 
              const IsColumnIdSelected = JSON.parse(localStorage.getItem("regulations/all-regulations/selectedFilterArray") || "[]")?.[columnId] || []
              const IsColumnIdSelectedObj = JSON.parse(localStorage.getItem("regulations/all-regulations/selectedFilterArray") ) || {}
              const IsColumnIdExistInFilterArray = Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(`${columnId}`.split("_").pop()))  
              console.log("oooooooooooooooooooootest1", Object.keys(IsColumnIdSelectedObj||{}),columnId)
              // console.log("oooooooooooooooooooootest2",  )
              console.log("oooooooooooooooooooootest3",  Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(columnId)))
              if (IsColumnIdExistInFilterArray && IsColumnIdSelected.length !=0) {
                return true
              } else {
                return false
              } 
          } catch (error) {
            console.log("ooooooooooooooooooooo", "error",error)
            return false
    }
    

  }

  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col md={4} className="py-3">
          {/* <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">List of Regulations</h5>
            <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{width : "150px", marginLeft: "10px"}}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
          </div> */}
          <TableHeader
            heading={"List of Regulations"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        <Col md={8} className="py-3 justify-content-end">
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            {regulationAccess &&
            regulationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      // marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  {/* <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button> */}
                </>
              ) : (
                // :
                // selectedFlatRows.length >= 1 ? (
                //   <Button
                //     onClick={(e) => {
                //       onAllDeleteRecord(e)
                //     }}
                //     style={{
                //       backgroundColor: "#556EE6",
                //       marginLeft: 5,
                //       marginRight: 5,
                //       color: "#fff",
                //       textTransform: "none",
                //     }}
                //   >
                //     <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                //     Delete
                //   </Button>
                // )
                ""
              )
            ) : (
              ""
            )}
            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {/* <Button
                variant="contained"
                // onClick={toggle}
                onClick={() => setModal(true)}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                  // padding: "5px"
                }}
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Link
              </Button> */}
            {regulationAccess &&
            regulationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Link
                className="btn btn-primary"
                to="/regulations/regulation_update"
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Regulation
              </Link>
            ) : (
              ""
            )}
            {regulationAccess &&
            regulationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Button
                onClick={() => {
                  setModal(!modal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )}
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px", width: "800px" }}
            >
              <ModalHeader toggle={toggle}>{title}</ModalHeader>
              <ModalBody className="px-4 py-4 text-left">
                {/* <h6>Import Data</h6>
                <div className="form-body mt-3">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-2"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button"
                    onClick={e => handleImportData(e)}
                  >
                    Import Data
                  </button>
                </div> */}
                <div
                  style={{ marginBottom: "20px", display: "flex", gap: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="new-data"
                      name="form-radio-primary"
                      defaultChecked={updatedData == "new"}
                      value="new"
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload new data
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="edit-data"
                      name="form-radio-primary"
                      value="edit"
                      defaultChecked={updatedData == "edit"}
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="edit-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload Edit
                    </label>
                  </div>
                </div>
                {updatedData == "edit" && (
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <DarkTooltip title="Blank cells WILL NOT overwrite existing content">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="0"
                          name="blank-column"
                          defaultChecked={blankColumn == "0"}
                          value="0"
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="0"
                          style={{ marginBottom: "0px" }}
                        >
                          Ignore Blank Cells{" "}
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                    <DarkTooltip title="Blank cells WILL overwrite existing content">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="1"
                          name="blank-column"
                          value="1"
                          defaultChecked={blankColumn == "1"}
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="1"
                          style={{ marginBottom: "0px" }}
                        >
                          Load Blank Cells
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                  </div>
                )}
                <h6>Import Data</h6>
                <div className="form-body mt-2">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-3"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button"
                    onClick={e => {
                      updatedData == "new"
                        ? handleImportData(e)
                        : handleImportEditData(e)
                    }}
                  >
                    Import Data
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {downloadAccess && downloadAccess.readAccess === true ? (
              btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={handleExport}>
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Download
                </button>
              )
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>

      {/* <div className="table-responsive react-table"> */}
      {/* {loader ? (
         <div
         className="container-fluid mt-5"
         style={{
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
         }}
       >
         <Spinner className="ms-2" color="primary" />
     </div>
      ) : ( */}
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 600 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"  
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                              {" "}
                              {generateSortingIndicator(column)}
                            </span>
                            <br /> */}
                      </div>
                      {/* {console.log(column.id, 'idcolumn')} */}
                      {column.id != "selection" &&
                      column.id != "updatedDate" &&
                      column.id != "regEffectiveDate" &&
                      column.id != "updatedDate " &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            cursor : 'pointer',
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                            paddingInlineEnd: 
                            column.id === 'regId' ? '29px' :
                            column.id === 'regEffectiveDate' ? '0px' :
                            '7px'
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              style={{ width: "85%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              openOnFocus={false}
                              options= {
                                autoEmpty[column.id] || searchQuery[column.id]
                                  ? autoFill[column.id] || []
                                  : []
                              } 
                              value={autoEmpty[column.id] || ""}
                              classes={{ option: classes.option }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value)
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  variant="filled"
                                  disabled={loader}
                                  size="small"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  onKeyDown={e =>
                                    handleKeyDown(
                                      e.key,
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>

                          <button
                            onClick={() => {
                              setShowModal(true)
                              setModalData(column)
                            }}
                            disabled={loader}
                            className={
                              // selectedHeader.includes(column.Header)
                              SelectedFilterIcon(filterArray, column.id)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                            //  style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                      ) : column.id == "updatedDate" ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: '2023-05-15',
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                        </div>
                      ) : column.id == "regEffectiveDate" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedEffDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: '2023-05-15',
                            }}
                            onKeyDown={e => handleEffKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <Filter column={column} /> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          totalItems={totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setSelectedHeader={setSelectedHeader}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          tableName={"regulations"}
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0 ?? false}
        />
      </Styles>
      {/* )} */}
      {/* </div> */}

      {/* <Row className="mt-3 justify-content-md-center justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row> */}
    </Fragment>
  )
}

const AllRegulations = props => {
  const [fakeData, setFakeData] = useState([])

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")

  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delRegulation, setDelRegulation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [regulationAccess, setRegulationAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [downloadAccess, setDownloadAccess] = useState([])
  const [sortBy, setSortBy] = useState({
    tableName: "regulation",
    fieldName: "regShortName",
    order: "ASC",
  })
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const dispatch = useDispatch()

  const limit = 10

  const handleSort = column => {
    // console.log(column.id, "columns")
    const isAsc = sortBy.fieldName === column.id && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName: "regulation",
        fieldName: column.id,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    if(props?.location?.state?.rowData?.isClear == undefined){
      localStorage.removeItem("selectedFilterArray")
      localStorage.removeItem("regulations/all-regulations/selectedFilterArray")
      localStorage.removeItem("regulations/all-regulations");
    }
  },[props?.location?.state?.rowData?.isClear])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setRegulationAccess(authToken && authToken.data.role.rolesPermission[1])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const customePageCount = Math.ceil(totalItems / limit)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelRegulation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')
    // setDelete(!is_delete)
    if (delRegulation.id) {
      // dispatch(onDeleteRegulation(delRegulation.id, props.history))
      deleteRegulation(delRegulation.id)
      setDeleteModal(false)
    }
  }

  const deleteRegulation = regulation => {
    // console.log(regulation, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-regulation?id=${regulation}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Regulation Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {

        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        } 
        RedirectOnUnAuthentication(err,navigateTo,message)
      })
  }

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject
    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData =   {...searchObject,...filterArray}
    // console.log(data,"re-renderd")
    setLoader(true)
    let data = localStorage.getItem("regulations/all-regulations")
    if (data != null) {
      data = JSON.parse(data)
      finalData = { ...data, ...finalData }
    }
    if (Object.keys(filterArray).length > 0) {
      // console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } 
    // else {
    //   localStorage.removeItem("selectedFilterArray")
    // }
    if (Object.keys(filterArray).length > 0) {
      // console.log(finalData, "finalData")
      localStorage.setItem("regulations/all-regulations/selectedFilterArray", JSON.stringify(finalData))
    } 
    if (Object.keys(finalData).length > 0) {
      // console.log(finalData, "finalData")
      localStorage.setItem(
        "regulations/all-regulations",
        JSON.stringify(finalData)
      )
    }


    const requestData = {
      ...finalData,
      customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      orderBy: {
        ...sortBy,
      },
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-regulations/?limit=${customePageSize}&pageNo=${currentPage}`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const dateQuery = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // const data = JSON.stringify({searchQuery})
    console.log(searchQuery, "re-searchQuery")
    const data = { updatedDate: searchQuery }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-regulations/?limit=${limit}&pageNo=${currentPage}`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const dateEffQuery = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // const data = JSON.stringify({searchQuery})
    console.log(searchQuery, "re-searchQuery")
    const data = {regEffectiveDate: searchQuery}
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-regulations/?limit=${limit}&pageNo=${currentPage}`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    console.log("page is loading")
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const reg_data = [
    {
      id: 1,
      regulator: "check",
      cfr_Ref: "12 CFR 1005",
      reg_short: "FINRA 1010",
      reg_long: "Electronic Filing Requirements for Uniform Forms",
      reg_desc:
        "Establishes the rights, liabilities, and responsibilities of parties in electronic funds transfers and protects consumers when they use such systems.",
      reg_url:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      reg_related_law: "Electronic Funds Transfer Act",
      reg_effe_date: "-",
      reg_last_update: "29-11-2020",
      reg_auth_reg: "FINRA",
      reg_pri_super: "FINRA",
    },
    {
      id: 2,
      regulator: "CFPB",
      cfr_Ref: "12 CFR 1005",
      reg_short: "FINRA 1017",
      reg_long: "Electronic Filing Requirements for Uniform Forms",
      reg_desc:
        "Lorem Establishes the rights, liabilities, and responsibilities of parties in electronic funds transfers and protects consumers when they use such systems.",
      reg_url:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1017",
      reg_related_law: "Electronic Funds Transfer Act",
      reg_effe_date: "-",
      reg_last_update: "29-11-2020",
      reg_auth_reg: "FINRA",
      reg_pri_super: "FINRA",
    },
    {
      id: 3,
      regulator: "CFPB",
      cfr_Ref: "12 CFR 1005",
      reg_short: "FINRA 1047",
      reg_long: "Electronic Filing Requirements for Uniform Forms",
      reg_desc:
        "Ipsum Establishes the rights, liabilities, and responsibilities of parties in electronic funds transfers and protects consumers when they use such systems.",
      reg_url:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1020",
      reg_related_law: "Electronic Funds Transfer Act",
      reg_effe_date: "-",
      reg_last_update: "29-11-2020",
      reg_auth_reg: "FINRA",
      reg_pri_super: "FINRA",
    },
    {
      id: 4,
      regulator: "CFPB",
      cfr_Ref: "12 CFR 1005",
      reg_short: "FINRA 1057",
      reg_long: "Electronic Funds Transfers",
      reg_desc:
        "Conva Establishes the rights, liabilities, and responsibilities of parties in electronic funds transfers and protects consumers when they use such systems.",
      reg_url:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/11130",
      reg_related_law: "Electronic Funds Transfer Act",
      reg_effe_date: "-",
      reg_last_update: "29-11-2020",
      reg_auth_reg: "FINRA",
      reg_pri_super: "FINRA",
    },
  ]
  const test = () => {
    console.log(props.location.search.split("?")[1].split("=")[1])

    const Qurry = {}
    props.location.search.split("?").map(i => {
      Qurry[i?.split("=")[0]] = i?.split("=")[1]
    })
    console.log(Qurry.RegLaw)
    {
      regShortName: Qurry.RegLaw
    }
  }
  const history = useHistory()


  const GotoDiscolur = (data) => {
    console.log(data, "data132132");
    if (data.length > 0) {
      let disIdArray = [];
      disIdArray = data;
      const result = { "disId": disIdArray };
      
      console.log(result,"result");    
      localStorage.setItem("disclosures/all-disclosures", JSON.stringify(result))
      localStorage.setItem("disclosures/all-disclosures/selectedFilterArray", JSON.stringify(result))
      localStorage.setItem("selectedFilterArray", JSON.stringify(result))
      history.push({
        pathname: '/disclosures/all-disclosures',
        state: {
          rowData: {
            isClear: true
          }
        }
      });
    }
  }

  const columns = [
    {
      Header: "Reg ID",
      width: 125,
      accessor: "regId",
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/regulations/regulation_view",
              state: { rowData },
            }}
          > 
            {ColumnDataWithTooltip({content:cellProps?.value,attr:{style:{ color: "#000" }} })  }
          </Link>
        )
      },
    },
    {
      Header: "Reg Law Short Name",
      accessor: "regShortName",
      filterable: false,
      disableFilters: true,
      width: 250,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/regulations/regulation_view",
              state: { rowData },
            }}
          >
               {ColumnDataWithTooltip({content:cellProps?.value,attr:{style:{ color: "#000" }} })  }
          </Link>
        )
      },
    },
    {
      Header: "CFR Ref",
      accessor: "cfrRef",
      // width: 100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return    ColumnDataWithTooltip({content:cellProps?.value,attr:{style:{ color: "#000" }} })  
      },
    },
    {
      Header: "Reg Long Name",
      accessor: "regLongName",
      width: 250,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <>
          {/* <Link
             to={{
               pathname: "/regulations/regulation_view",
                state: { rowData },
              }}
           > */}
          {ColumnDataWithTooltip({content:cellProps?.value,attr:{style:{ color: "#000" }} })  }
          </>
          // {/* </Link> */}
        )
      },
    },
    {
      Header: "Reg Description",
      accessor: "regDescription",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value ? (
          <LightTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  setDesc(cellProps.value)
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
              View Details
          </UncontrolledTooltip> */}
            </div>
          </LightTooltip>
        ) : (
          DisplayJustifyCenter("-")
        )
      },
    },
    {
      Header: "Reg URL",
      filterable: false,
      disableFilters: true,
      accessor: "regUrl",
      width: 300,
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div>
            {CheckValidValue({ value: cellProps.value }) ? (
              <Link to={{ pathname: cellProps.value }} target="_blank">
                <LightTooltip title={rowData}>
                  <div
                    className="StringOverFlow1"
                    style={{
                      textDecoration: "underline",
                      fontSize: "13px",
                      color: "blue",
                    }}
                  >
                    {cellProps.value
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "") ||
                      cellProps.value ||
                      DisplayJustifyCenter("-")}
                  </div>
                </LightTooltip>
              </Link>
            ) : (
              DisplayJustifyCenter("-")
            )}
          </div>
        )
      },
    },

    {
      Header: "Reg Related Law",
      accessor: "regRelatedLaw",
      filterable: false,
      disableFilters: true,
      width: 250,
      Cell: cellProps => {
        return    ColumnDataWithTooltip({content:cellProps?.value,attr:{style:{ color: "#000" }} })  
      },
    },
   
    {
      Header: "Regulator Short Name",
      accessor: "regAuthoringRegulator",
      filterable: false,
      disableFilters: true,
      // width:120,
      Cell: cellProps => {
        // console.log(cellProps.value, "value")
        return   ColumnDataWithTooltip({content:cellProps?.value,attr:{style:{ color: "#000" }} })  
      },
    },
    {
      Header: "Reg Primary Supervisor",
      accessor: "regPrimarySupervisor",
      // width:120,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return ColumnDataWithTooltip({content:cellProps?.value })  
      },
    },
    {
      Header: "Reg Effective Date",
      width : 102,
      accessor: "regEffectiveDate",
      filterable: false, 
      disableFilters: true,
      Cell: cellProps => {
        const date1 =FormatedDate(cellProps.value)  
        if (cellProps.value) {
          return date1
        } else {
          return DisplayJustifyCenter("-")
        }
      },
    },
    {
      Header: "Reg Compliance Guide URL",
      filterable: false,
      disableFilters: true,
      accessor: "regComplianceGuideUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div>
            {cellProps.value ? (
              <Link to={{ pathname: cellProps.value }} target="_blank">
                <LightTooltip title={rowData}>
                  <div
                    className="StringOverFlow1"
                    style={{
                      textDecoration: "underline",
                      fontSize: "13px",
                      color: "blue",
                    }}
                  >
                    {cellProps.value
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "")}
                  </div>
                </LightTooltip>
              </Link>
            ) : (
              DisplayJustifyCenter("-")
            )}
          </div>
        )
      },
    },
    {
      Header: "Regulation Last Update Date",
      accessor: "updatedDate",
      filterable: false, 
      disableFilters: true,
      Cell: cellProps => {
        const date1 =FormatedDate(cellProps.value)   
        if (cellProps.value) {
          return date1
        } else {
          return DisplayJustifyCenter("-")
        }
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      sticky: "right",
      width: 180,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "1.2rem" }}
          >
            {/* <LightTooltip  */}
            {regulationAccess &&
            regulationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    to={{
                      pathname: "/regulations/regulation_update",
                      state: { rowData },
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}

            {regulationAccess && regulationAccess.readAccess === true ? (
              <Badge className="font-size-15  badge-soft-primary" pill>
                <DarkTooltip title="View">
                  <Link
                    to={{
                      pathname: "/regulations/regulation_view",
                      state: { rowData },
                    }}
                    onClick={() => {
                      const orderData = cellProps.row.original
                      // console.log(orderData, 'data')
                      // handleOrderClick(orderData);
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "blue", cursor: "pointer" }}
                      className="mdi mdi-eye-outline  font-size-18"
                      id="customerViewtooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}

            {regulationAccess &&
            regulationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                {/* <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                > */}
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            ) : (
              ""
            )}
            {regulationAccess &&
            regulationAccess.writeAccess === true && cellProps.row.original && cellProps.row.original.disIds.length > 0 ?
              <Badge className="font-size-15 badge-soft-primary" pill>
              <DarkTooltip title="Linked Disclosures">
                  <i
                      onClick={() => GotoDiscolur(cellProps.row.original.disIds)}
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-outline  font-size-18"
                    id="customerViewtooltip"
                  ></i>
              </DarkTooltip>
            </Badge>
            : null}
          </ul>
        )
      },
    },
  
  ]

  const Header = [
    { name: <input type="checkbox" />, field: "id", sortable: false },
    { name: "Regulator", field: "regulator", sortable: false },
    { name: "CFR Red", field: "cfr_ref", sortable: false },
    { name: "Reg Law Short Name", field: "reg_short", sortable: false },
    { name: "Reg Long Name", field: "reg_long", sortable: false },
    { name: "Reg Descripion", field: "reg_desc", sortable: false },
    { name: "Regulations URL", field: "reg_url", sortable: false },
    { name: "Reg Compliance Guide URL", field: "reg_com_url", sortable: false },
    { name: "Reg Related Law", field: "reg_law", sortable: false },
    { name: "Reg Effective Date", field: "reg_eff_date", sortable: false },
    { name: "Reg Last Update Date", field: "reg_last_date", sortable: false },
    { name: "Regulator Short Name", field: "reg_auth", sortable: false },
    { name: "Reg Primary Supervisor", field: "reg_pri_sup", sortable: false },
    { name: "Action", field: "Action", sortable: false },
  ]

  const reports = [
    {
      title: "Total Regulations",
      iconClass: "bx-copy-alt",
      description: totalItems,
    },
    { title: "New Regulations", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Regulation Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Regulation Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  document.title = "Regulations"
  const handleRender = () => {
    setDelete(!is_delete)
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Regulations" breadcrumbItem="Regulations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Regulation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          {/* <Row>
            {reports.map((report, key) => (
              <Col className="col-xl-3 col-md-6" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody
                    className="custome-card"
                    style={{ padding: "10px 11px !important" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-muted">{report.title}</h6>
                      </div>
                      <div
                        className="avatar-sm bg-primary align-self-center mini-stat-icon"
                        style={{ borderRadius: "100%" }}
                      >
                        <span
                          className="avatar-title bg-primary"
                          style={{ borderRadius: "100%" }}
                        >
                          <p className="mb-0 font-size-18">
                            {report.description}
                          </p>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                    columns={columns}
                    props={props}
                    data={reqresData}
                    filterArray={filterArray}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    regulationAccess={regulationAccess}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    setCurrentPage={setCurrentPage}
                    fetchData={fetchData}
                    setSearchObject={setSearchObject}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    setFilterArray={setFilterArray}
                    handleRender={handleRender}
                    dateQuery={dateQuery}
                    dateEffQuery={dateEffQuery}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    totalItems={totalItems}
                    currentPage={currentPage}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Regulations" breadcrumbItem="All Regulations" />
          <Row>
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-sm-0 font-size-18">
                        Regulation Details
                      </h5>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex flex-row-reverse py-3">



                        <Button
                          variant="contained"
                          //   onClick={handleAdd}
                          style={{
                            backgroundColor: "#556ee6",
                            marginLeft: 5,
                            color: "#fff",
                            textTransform: "none",
                            padding: "5px"
                          }}
                        >
                          <i className="bx bx-upload font-size-16 align-middle me-1"></i>{" "}
                          Upload
                        </Button>
                        <Button
                          variant="contained"
                          //   onClick={handleAdd}
                          style={{
                            backgroundColor: "#556ee6",
                            marginLeft: 5,
                            color: "#fff",
                            textTransform: "none",
                            padding: "5px"
                          }}
                        >
                          <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                          Download
                        </Button>
                        <Button
                          variant="contained"
                          //   onClick={handleAdd}
                          style={{
                            backgroundColor: "#556ee6",
                            marginLeft: 5,
                            color: "#fff",
                            textTransform: "none",
                            padding: "5px"
                          }}dango
                        >
                           <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                           Add Regulation
                        </Button>

                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered  ">
                      <TableHeader headers={Header} />
                      <tbody>
                        {reg_data.map((value, index) => (
                          <tr key={value.id}>
                            {/* <th scope="row">{value.id}</th> */}
      {/* <th scope="row">
                              <input type="checkbox" />
                            </th>
                            <td>{value.regulator}</td>
                            <td style={{ minWidth: "100px" }}>
                              {value.cfr_Ref}
                            </td>
                            <td style={{ minWidth: "90px" }}>
                              {value.reg_short}
                            </td>
                            <td>{value.reg_long}</td>
                            <td>{value.reg_desc}</td>
                            <td>
                              <button className="btn btn-primary btn-rounded p-1">
                                Go to URL
                              </button>
                            </td>
                            <td>
                              <button className="btn btn-primary btn-rounded p-1">
                                Go to URL
                              </button>
                            </td>
                            <td style={{ minWidth: "120px" }}>{value.reg_related_law}</td>
                            <td>{value.reg_effe_date}</td>
                            <td style={{ minWidth: "100px" }}>
                              {value.reg_last_update}
                            </td>
                            <td>{value.reg_auth_reg}</td>
                            <td>{value.reg_pri_super}</td>
                            <td>
                              <ul
                                className="list-unstyled hstack gap-2"
                                style={{ marginBottom: "1.2rem" }}
                              >
                                <Badge className="font-size-15 badge-soft-success" pill>
                                  <i
                                    //   onClick={() => handleView(value)}
                                    style={{ color: "#34C38F", cursor: "pointer" }}
                                    className="bx bx-edit-alt  font-size-18"
                                    id="customerViewtooltip"
                                  ></i>
                                </Badge>
                                <Badge className="font-size-15 badge-soft-success" pill>
                                  <i
                                    //   onClick={() => handleView(value)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                    className="mdi mdi-eye-outline  font-size-18"
                                    id="customerViewtooltip"
                                  ></i>
                                </Badge>
                                <Badge
                                  color="danger"
                                  className="font-size-15 badge-soft-danger"
                                  pill
                                >
                                  <li
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Edit"
                                  >
                                    <i
                                      // onClick={() => toggleViewModal(value)}
                                      className="bx bx-trash font-size-18"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                      id="edittooltip"
                                    />
                                  </li>
                                </Badge>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="customerViewtooltip"
                                >
                                  View
                                </UncontrolledTooltip>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="edittooltip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div> */}
    </React.Fragment>
  )
}

export default withRouter(AllRegulations)
