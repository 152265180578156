import React, { useEffect, useState } from "react"
// import { LongString,  } from "../../asset/longString";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Pagination from "../../components/Common/Pagination"
import { Button, Spinner } from "reactstrap"
import $ from "jquery";
function splitStringIntoParts(str, numberOfParts) {  
  const partSize = Math.ceil(str.length / numberOfParts)
  const partsObject = {}

  for (let i = 0; i < numberOfParts; i++) {
    const start = i * partSize
    const end = start + partSize
    partsObject[i + 1] = str.substring(start, end)
  }

  return partsObject
}
const CheckValidValue = ({value, defaultValue, notCheck,isReplace,replceFormula}) => {
    if (["null", null, undefined, "undefined", ""].includes(value)) {
      return defaultValue ? defaultValue : false;
    } else {
      return notCheck ? value : true;
    }
  };
function updateObjectKeyValue(obj, keyToUpdate, newValue) {
  // Check if the key exists in the object
  if (obj.hasOwnProperty(keyToUpdate)) {
    // Update the value of the key
    obj[keyToUpdate] = newValue
    return true // Return true indicating the update was successful
  } else {
    // Key does not exist in the object
    return false // Return false indicating the update was unsuccessful
  }
}
function calculatePageSize(string, divisor) {
    // Measure the length of the string
    const stringLength = string?.length;
   
    // Calculate the result by dividing the string length by the divisor
    // const tenp = divisor?divisor:50000
    const result = stringLength / (divisor?divisor:50000);
    
    // Round the result to the nearest integer
    const roundedResult = Math.round(result); 
    return roundedResult;
}
function concatenateObjectValues(obj) {
  // Get an array of the values from the object
  const valuesArray = Object.values(obj)

  // Join all values in sequence
  const concatenatedString = valuesArray.join("")

  return concatenatedString
}

function TextEditor({ onChange, textContent, setTextContent }) {
  const handleChange = newContent => {
    setTextContent(newContent)
    onChange ? onChange(newContent) : console.log("first")
  }

  useEffect(() => {
    setTextContent(textContent)
  }, [textContent])
  return (
    <>
      {/* <QuillEditor content={textContent} onChange={handleChange} /> */}

      <CKEditor
        editor={ClassicEditor}
        data={textContent}
        // data={citationContent2}
        config={{
          removePlugins: [
            "ImageCaption",
            "ImageStyle",
            "ImageToolbar",
            "ImageUpload",
            "MediaEmbed",
            "EasyImage",
            "UploadImage",
          ],
        }}
        onReady={editor => {
          editor.editing.view.change(writer => {
            writer.setStyle(
              "max-height",
              "300px",
              editor.editing.view.document.getRoot()
            )
          })
        }}
        onChange={(event, editor) => {
          const data = editor.getData()
          // validation.setFieldValue('obligationDesc', data);
       
          // Slice the string to remove the first <p> tag
          const modifyData = data //  data.slice( data.indexOf(">") + 1,  data.lastIndexOf("<") )
          handleChange(modifyData)
        }}
      />
    </>
  )
}
//divisor: how many character you keep in page
function EditorWithPagination({ onSave, contentData, buttonLoader,divisor }) {
  // State variables
  console.log("textEdit2545 contentData", )
  const LongString = CheckValidValue({value:contentData})?contentData:""
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(calculatePageSize(contentData,50000)) // Number of characters per page
  const [mainString, setMainString] = useState(LongString)
  const dividedStringInPart = splitStringIntoParts(mainString, pageSize==0?1:pageSize)
  const [stringCollaction, setStringCollaction] = useState(dividedStringInPart)
  const [textContent, setTextContent] = useState() 
  // Function to handle text editing
  const handleTextEdit = value => {
    const tempObj = { ...stringCollaction }
    updateObjectKeyValue(tempObj, currentPage, value)
    setStringCollaction(tempObj)
  }

  // Function to handle page navigation
  const goToPage = pageNumber => {
    setCurrentPage(pageNumber)
  }
  const updateAndScroll = () => {
    // setChatMessage("");
    // setTimeout(() => {
    //   scrollingDiv.scrollTop = scrollingDiv.scrollHeight;
    // }, 200);
  };
 
 
  $(".pagination-container").on("click", function() {
    $("div").scrollTop(0);
    console.log("scrollTop")
});
  
  // Paginate the main string based on current page and page size
  const startIndex = (currentPage - 1) * pageSize
  const endIndex = startIndex + pageSize
  useEffect(() => {
    setTextContent(stringCollaction[`${currentPage}`])
    // ScrollDown()
  }, [currentPage])

  const test = () => {
    // ScrollDown()
    updateAndScroll()
    console.log( "textEdit2545 ") 
  }

  const OnSubmit = () => {
    const joineEachString = concatenateObjectValues(stringCollaction)
    onSave(joineEachString)
  }
  return (
    <div onClick={test}>
      <TextEditor
        onChange={handleTextEdit}
        setTextContent={setTextContent}
        textContent={textContent}
      />
      <br />
      <div className="d-flex justify-content-center w-100">
      <Pagination
        onPageChange={page => setCurrentPage(page)}
        totalCount={Object.keys(stringCollaction).length}
        currentPage={currentPage}
        pageSize={1}
      /></div>
      <br />

      <div className="display-justify-center">
        {buttonLoader ? (
          <div className="ml-5">
            <Spinner className="ms-2 " color="primary" />
          </div>
        ) : (
          <Button className="px-5" color="primary" onClick={OnSubmit}>
            Save
          </Button>
        )}{" "}
      </div>
    </div>
  )
}

export default EditorWithPagination
