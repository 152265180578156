import React, { useMemo, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  BreadcrumbItem,
  Spinner,
} from "reactstrap"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteModal from "../../../components/Common/DeleteModal"
import { useDispatch } from "react-redux"
import { deleteDisclosure as onDeleteDisclosure } from "../../../store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import HtmlDiff from "htmldiff-js"
import PDFGenerator from "./PdfComponent"
import "./diff.css"
import {
  CheckValidValue,
  FormatedDate,
  InnerText,
  ToastSuccess,
  encryptData,
  hexHtmlToString,
} from "helpers/helper_function"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const UpdatedReportView = props => {
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [deleteDisc, setDeleteDiscl] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const [disclosureAccess, setDisclosureAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [newModal, setNewModal] = useState(false)
  const [disclosureModal, setDisclosureModal] = useState(false)

  const callUseNavigate = useHistory()
  const navigateTo = url => {
    callUseNavigate.push(url)
  }

  const [userListModal, setUserListModal] = useState(false)
  const [userListData, setUserListData] = useState({
    title: "",
    discription: "",
    userList: [],
  })

  console.log(userListData, "Here Is The User List Data")

  console.log(CheckValidValue, "Here We Check Value")
  const [obID, setObId] = useState("")
  const [disclosureChangeModal, setDisclosureChangeModal] = useState(false)

  const [oldString, setOldString] = useState("")
  const [newString, setNewString] = useState("")
  const [diffContent, setDiffContent] = useState("")
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(true)
  const [LodaerTextUpdated, setLodaerTextUpdated] = useState(false)
  const [LodaerRecommandetion, setLodaerRecommandetion] = useState(false)
  const [ButtonRecom, setButtonRecom] = useState(true)

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDisclosureAccess(authToken && authToken.data.role.rolesPermission[3])
  }, [])

  const toggle = () => setModal(!modal)
  const DisclosureToggle = () => setDisclosureModal(!disclosureModal)
  const UserListToggle = () => setUserListModal(!userListModal)
  const DisclosureChangeToggle = () =>
    setDisclosureChangeModal(!disclosureChangeModal)

  const apiUserListGet = async (disId, disclosureDesc) => {
    setLoader(true)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const requestData = { disId }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-clients-by-disclosure`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response.data.data)
          setUserListData({
            title: response.data.data.disclosure.disId,
            discription: response.data.data.disclosure.text,
            userList: response.data.data.clients,
          })
          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
    setLoader(false)
  }

  const UserListHanlder = async disId => {
    setUserListModal(!userListModal)
    apiUserListGet(disId, "")
  }

  const dataHandler = async (disId, disclosureDesc) => {
    DisclosureChangeToggle()
    apiUserListGet(disId, disclosureDesc)
  }

  const onRecommandetion = async customerOrganizationId => {
    setLodaerRecommandetion(true)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const requestData = { obId: obID, disId: userListData.title, customerOrganizationId };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}recommend-change-disclosure-request`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response.data.data)
          // setUserListData({
          //   title: disId,
          //   discription: disclosureDesc,
          //   userList: response.data.data,
          // })
          ToastSuccess(response)
          setLodaerRecommandetion(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        setLodaerRecommandetion(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const onDisclosureTextUpdated = async () => {
    setLodaerTextUpdated(true)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const requestData = { disId: userListData.title, text: userListData.discription }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}change-disclosure-text`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response.data.data)
          // setUserListData({
          //   title: disId,
          //   discription: disclosureDesc,
          //   userList: response.data.data,
          // })
          ToastSuccess(response)
          setButtonRecom(false)
          setLodaerTextUpdated(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        setLodaerTextUpdated(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
    // setLodaerTextUpdated(false)
  }

  const newToggle = () => {
    setNewModal(!newModal)
  }

  const data = location.state.rowData

  console.log(data, "Here We Are Geting Da")

  const tdata = location.state.rowData
  // console.log(data.obligation.disclosure_obligations_obligation, "data")
  let rowData = data
  document.title = "Reg Content Change Report"

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  function firstHtml() {
    // const oldContent = oldString.replace(/<[^>]*>/g,'')
    return { __html: oldString }
  }

  function secondHtml() {
    // const newContent = newString.replace(/<[^>]*>/g,'')
    return { __html: newString }
  }

  function diffHtml() {
    // console.log(firstHtml(), secondHtml())
    return {
      __html: HtmlDiff.execute(
        hexHtmlToString(firstHtml().__html),
        hexHtmlToString(secondHtml().__html)
      ),
    }
  }

  const handleDiff = () => {
    const diffHtmlResult = diffHtml()
    setDiffContent(diffHtmlResult)
  }

  const contentRef = React.useRef(null);
 
  const setInnerText = (content) => {
    // if (contentRef.current) {
      const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    // }
  }

const disclosureList =
    data &&
    data?.obligation &&
    data?.obligation?.disclosure_obligations_obligation?.length > 0
      ? data?.obligation?.disclosure_obligations_obligation?.map(
          item =>
            `<tr>
      <th scope="row">${item.disId}</th>
      <td>${item.disclosure.disclosureTitle.substr(0, 25) + "..."}</td>
     
  </tr>`
        )
      : ` <tr>
      <th scope="row">No Records Found</th> 
  </tr>`

  const content = `<div className="inner">
  <div>DIFFERENCE</div>
  <div dangerouslySetInnerHTML={${diffHtml()}} />
</div>`

  console.log(content, "content")

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs
            title="Scanned Report"
            // breadcrumbItem={updateTitle}
            path="updated-obli-report"
            breadcrumbItem={"Updated Report List"}
          /> */}
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {"Updated Obligation Detail"}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "/new-obli-report",
                          // state: { rowData: {id: data.queryId} },
                        }}
                      >
                        {"Scanned Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "/updated-obli-report",
                          state: {
                            rowData: {
                              id: data.queryId,
                              // createdDate: data.obligation.updatedDate,
                              clear: false,
                              createdDate:
                                props?.location?.state?.rowData?.createdDate,
                            },
                          },
                        }}
                      >
                        {"Reg Content Change Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {"Updated Obligation Detail"}
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Updated Obligation Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
              </div>
            </div>
          </div> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "25%" }}>Obligation ID</th>
                          <td style={{ width: "65%" }}>
                            {" "}
                            {CheckValidValue({ value: data?.obId })
                              ? data?.obId
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Obligation Name</th>
                          <td style={{ width: "65%" }}>
                            {CheckValidValue({
                              value: data?.obligation.obligationName,
                            })
                              ? data?.obligation.obligationName
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Prior Updated Date</th>
                          <td style={{ width: "65%" }}>
                            {/* {(data && data.updatedDate)?data.updatedDate:'-'} */}
                            {CheckValidValue({ value: data?.prevUpdatedDate })
                              ? FormatedDate(data && data.prevUpdatedDate)
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>
                            Prior Citation Content
                          </th>
                          <td style={{ width: "65%" }}>
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: data && data.oldobligationDesc,
                              }}
                            ></div> */}
                            {CheckValidValue({
                              value: data?.oldobligationDesc,
                            }) ? (
                              <DarkTooltip
                                title="View Details"
                                style={{ width: "15px" }}
                              >
                                <div>
                                  <i
                                    onClick={() => {
                                      setModal(!modal)
                                      setDesc(data.oldobligationDesc)
                                  
                                      setTitle("Prior Citation Content")
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                      padding: "0 5px",
                                      borderRadius: "20%",
                                      color: "#556ee6",
                                    }}
                                    className="bx bxs-info-circle  font-size-24"
                                    id="descToolTip"
                                  ></i>
                                  {/* <div
                                         onClick={() => {
                                           setModal(!modal)
                                           setDesc(data.oldobligationDesc)
                                         }}
                                       ></div> */}
                                </div>
                              </DarkTooltip>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>New Citation Content</th>
                          <td style={{ width: "65%" }}>
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: data && data.obligationDesc,
                              }}
                            ></div> */}
                            {CheckValidValue({
                              value: data?.obligationDesc,
                            }) ? (
                              <DarkTooltip
                                title="View Details"
                                style={{ width: "15px" }}
                              >
                                <div>
                                  <i
                                    onClick={() => {
                                      // setModal(!modal)
                                      // setDesc(data.obligationDesc)
                                      setNewModal(!newModal)
                                      setOldString(data.oldobligationDesc || "")
                                      setNewString(data.obligationDesc || "")
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                      padding: "0 5px",
                                      borderRadius: "20%",
                                      color: "#556ee6",
                                    }}
                                    className="bx bxs-info-circle  font-size-24"
                                    id="descToolTip"
                                  ></i>
                                  {/* <div
                                        onClick={() => {
                                          setNewModal(!newModal)
                                          setDesc(data.obligationDesc)
                                        }}
                                      ></div> */}
                                </div>
                              </DarkTooltip>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "25%" }}>Citation</th>
                          <td style={{ width: "65%" }}>
                            {CheckValidValue({
                              value: data?.obligation.citation,
                            })
                              ? data?.obligation.citation
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Citation URL</th>
                          <td style={{ width: "65%" }}>
                            {CheckValidValue({
                              value: data?.obligation.citationUrl,
                            }) ? (
                              <Link
                                to={{ pathname: data.obligation.citationUrl }}
                                target="_blank"
                              >
                                {(data && data.obligation.citationUrl) || "-"}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Reg Law Short Name</th>
                          <td style={{ width: "65%" }}>
                            {CheckValidValue({ value: data?.obligation.regLaw })
                              ? data?.obligation.regLaw
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Regulator Short Name</th>
                          <td style={{ width: "65%" }}>
                            {CheckValidValue({
                              value: data?.obligation.issuingAuthority,
                            })
                              ? data?.obligation.issuingAuthority
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row> */}
          {/*page-content table-striped */}
          {/* <Col lg="12"> */}
          {/* <PDFDownloadLink
                document={<PDFDocument data={data} />} // Pass the data to the PDFDocument component
                fileName="updated_report.pdf" // Specify the file name for the downloaded PDF
              >
                {({ loading }) =>
                  loading ? "Loading document..." : <Button color="primary">Download PDF</Button>
                } 
              </PDFDownloadLink> */}

          {/* <PDFGenerator
                setOldString={setOldString}
                setNewString={setNewString}
                diffHtml={diffHtml}
                handleDiff={handleDiff}
                details={data}
                data={`<div >
<div>
    <div class="row"> 
        <div class="col-12 col">
            <div>
                <h4 >Updated Obligation Detail</h4>
                
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div >
                        <table class="table-striped px-0 table">
                            <tbody>
                                <tr>
                                    <th style="width: 25%;">Obligation ID</th>
                                    <td style="width: 65%;">${
                                      data && data?.obId
                                    }</td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Obligation Name</th>
                                    <td style="width: 65%;">${
                                      data && data?.obligation.obligationName
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Prior Updated Date</th>
                                    <td style="width: 65%;">${
                                      data && data?.prevUpdatedDate
                                        ? )
                                        : "-"
                                    }</td>
                                </tr>
                             
                                <tr>
                                    <th style="width: 25%;">Citation</th>
                                    <td style="width: 65%;">${
                                      (data && data?.obligation.citation) || "-"
                                    }</td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Citation URL</th>
                                    <td style="width: 65%;"><a target="_blank"
                                            href="https://www.consumerfinance.gov/rules-policy/regulations/1026/18/">${
                                              (data &&
                                                data?.obligation.citationUrl) ||
                                              "-"
                                            }</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Reg Law Short Name</th>
                                    <td style="width: 65%;"> ${
                                      (data && data?.obligation.regLaw) || "-"
                                    }</td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Regulator Short Name</th>
                                    <td style="width: 65%;"> ${
                                      (data &&
                                        data?.obligation.issuingAuthority) ||
                                      "-"
                                    }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="row">
        <div class="col-12 col">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Linked Disclosures List</h4>
            </div>
        </div>
    </div> 
    <div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="table-responsivepx-0">
                    <table class="table table-responsive px-0">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Disclosure Name</th>
                            </tr>
                        </thead>
                        <tbody>
                           ${disclosureList}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div ></div> 
      ${oldString}                  
    </div>
              </div>`}
              /> */}
          {/* </Col>
          </Row> */}
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4
                  className="mb-sm-0 font-size-18"
                  onClick={() => console.log(data)}
                >
                  Linked Disclosures List
                </h4>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={disclosureModal}
            toggle={DisclosureToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={DisclosureToggle}>
              {"Disclosure Text"}
            </ModalHeader>
            <ModalBody className="text-left">
              {/* <div style={{ whiteSpace: "break-spaces" }}  dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button
                className="px-5"
                color="primary"
                onClick={DisclosureToggle}
              >
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>
              {"Prior Citation Content"}
            </ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={newModal}
            toggle={newToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={newToggle}>New Citation Content</ModalHeader>
            <ModalBody className="text-left">
              <div className="inner">
                <div dangerouslySetInnerHTML={diffHtml()} />
              </div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={newToggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={userListModal}
            toggle={UserListToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={UserListToggle}>
              <div style={{ display: "flex", gap: "2px" }}>
                User Link List -{" "}
                <div style={{ fontWeight: "bold" }}>{userListData.title}</div>
              </div>
            </ModalHeader>
            <ModalBody className="text-left">
              {loader ? (
                <div
                  className="container-fluid mt-5 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : (
                <div
                  className="inner"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  {userListData.userList.length > 0 ? (
                    <div>
                      <div style={{ display: "grid", gap: "5px" }}>
                        {userListData.userList.map((item, index) => (
                          <div key={`${index}-${item.companyName}`}>
                            {item.companyName}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      No user found
                    </div>
                  )}
                  {/* <div dangerouslySetInnerHTML={diffHtml()} /> */}
                </div>
              )}
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={UserListToggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={disclosureChangeModal}
            toggle={DisclosureChangeToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={DisclosureChangeToggle}>
              <div style={{ display: "flex", gap: "2px" }}>
                Disclosure Change Request -{" "}
                <div style={{ fontWeight: "bold" }}>{userListData.title}</div>
              </div>
            </ModalHeader>
            <ModalBody className="text-left">
              {loader ? (
                <div
                  className="container-fluid mt-5 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : (
                <div>
                  {userListData.userList.length > 0 ? (
                    <>
                      <CKEditor
                        editor={ClassicEditor}
                        // data={validation.values.text || ''}
                        data={
                          // (userListData.discription &&
                          //   userListData.discription) ||
                          // ""
                          InnerText(userListData.discription)
                        }
                        config={{ 
                          removePlugins: [ 
                            "ImageCaption",
                            "ImageStyle",
                            "ImageToolbar",
                            "ImageUpload",
                            "MediaEmbed",
                            "EasyImage",
                            "UploadImage",
                          ],
                        }}
                        onReady={editor => {
                          editor.editing.view.change(writer => {
                            writer.setStyle(
                              "min-height",
                              "200px",
                              editor.editing.view.document.getRoot()
                            )
                          })
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setUserListData({
                            discription: data,
                            title: userListData.title,
                            userList: userListData.userList,
                          })
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "10px",
                        }}
                      >
                        {LodaerTextUpdated ? (
                          <div className="ml-5">
                            <Spinner className="ms-2 " color="primary" />
                          </div>
                        ) : (
                          <Button
                            className="p-2"
                            color="primary"
                            onClick={onDisclosureTextUpdated}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </>
                  ) : null}
                  <div
                    className="inner"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    {userListData.userList.length > 0 ? (
                      <table className="table table-responsive mt-3 px-0">
                        <thead>
                          <tr>
                            <th scope="col">Client Name</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userListData.userList.map((item, index) => (
                            <tr key={`${index}`}>
                              <td>{item.companyName}</td>
                              <td>
                                {LodaerRecommandetion ? (
                                  <div className="ml-5">
                                    <Spinner
                                      className="ms-2 "
                                      color="primary"
                                    />
                                  </div>
                                ) : (
                                  <Button
                                    className="p-1"
                                    color="primary"
                                    disabled={ButtonRecom}
                                    onClick={() => {
                                      onRecommandetion(item.id)
                                    }}
                                  >
                                    Assign to Client
                                  </Button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                    {userListData.userList.length > 0 ? null : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          fontWeight: "bold",
                        }}
                      >
                        No user found
                      </div>
                    )}

                    {/* <div dangerouslySetInnerHTML={diffHtml()} /> */}
                  </div>
                </div>
              )}
            </ModalBody>
            {/* <ModalFooter className="mx-auto">
              <Button
                className="px-5"
                color="primary"
                onClick={DisclosureChangeToggle}
              >
                Ok
              </Button>{" "}
            </ModalFooter> */}
          </Modal>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    {/* <div style={{
                      display: 'flex',
                      justifyContent: 'end'
                    }}>
                      <button type="button" className="btn btn-primary">
                        Disclosure
                      </button>
                    </div> */}
                    <table className="table table-responsive mt-3 px-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Disclosure Title</th>
                          <th scope="col">Disclosure Text</th>
                          <th scope="col">User Link List</th>
                          <th scope="col">Disclosure Change Request</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                        data.obligation &&
                        data.obligation.disclosure_obligations_obligation
                          .length > 0 ? (
                          data.obligation.disclosure_obligations_obligation.map(
                            item => (
                              <tr key={item.id}>
                                <th scope="row">{item.disId}</th>
                                <td>
                                  {/* <div className="StringOverFlow1">
                                    {item.disclosure.disclosureTitle}
                                  </div> */}
                                  <LightTooltip title={item.disclosure.disclosureTitle}>
                                    <span style={{ color: "#000" }}   className="StringOverFlow1">
                                      {CheckValidValue({value:item.disclosure.disclosureTitle})?item.disclosure.disclosureTitle : DisplayJustifyCenter("-")}
                                    </span>
                                  </LightTooltip>
                                </td>
                                <td>
                                  <DarkTooltip
                                    title="View Details"
                                    style={{ width: "15px" }}
                                  >
                                    <div>
                                      <i
                                        onClick={() => {
                                          setDisclosureModal(!disclosureModal)
                
                                          // setDesc(
                                          //   item.disclosure.text
                                          // )
                                          setTimeout(() => {
                                            console.log(item.disclosure.text, "Here Is Item Disclosure")
                                            setInnerText(item.disclosure.text)
                                          },500)
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                          padding: "0 5px",
                                          borderRadius: "20%",
                                          color: "#556ee6",
                                        }}
                                        className="bx bxs-info-circle  font-size-24"
                                        id="descToolTip"
                                      ></i>
                                      <div
                                        onClick={() => {
                                          setDesc(
                                            item.disclosure.text
                                          )
                                          setModal(!modal)
                                        }}
                                      ></div>
                                    </div>
                                  </DarkTooltip>
                                </td>
                                <td>
                                {item.clients.length > 0 ? (
                                    <DarkTooltip title="View User List" key={item.desId} style={{ width: "15px" }}>
                                      <div>
                                        <i
                                          onClick={() => {
                                            UserListHanlder(item.disId);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            textAlign: "center",
                                            padding: "0 5px",
                                            borderRadius: "20%",
                                            color: "#556ee6",
                                          }}
                                          className="bx bxs-info-circle font-size-24"
                                          id="descToolTip"
                                        ></i>
                                        <div
                                          onClick={() => {
                                            UserListHanlder(item.disId);
                                          }}
                                        ></div>
                                      </div>
                                    </DarkTooltip>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {/* <DarkTooltip
                                    title="View User List"
                                    style={{ width: "15px" }}
                                  >
                                    <div>
                                      <i
                                        onClick={() => {
                                          // setDisclosureModal(!disclosureModal)
                                          // setDesc(
                                          //   item.disclosure.disclosureDesc
                                          // )
                                          dataHandler(
                                            item.disId,
                                            item.disclosure.disclosureDesc
                                          )
                                          setObId(item.obId)
                                          setButtonRecom(true);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                          padding: "0 5px",
                                          borderRadius: "20%",
                                          color: "#556ee6",
                                        }}
                                        className="bx bxs-info-circle  font-size-24"
                                        id="descToolTip"
                                      ></i>
                                      <div
                                        onClick={() => {
                                          dataHandler(
                                            item.disId,
                                            item.disclosure.disclosureDesc
                                          )
                                          // setDesc(
                                          //   item.disclosure.disclosureDesc
                                          // )
                                          // setModal(!modal)
                                        }}
                                      ></div>
                                    </div>
                                  </DarkTooltip> */}
                                       <Button
                                         className="p-2"
                                         color="primary"
                                          onClick={() => {
                                          dataHandler(
                                            item.disId,
                                            item.disclosure.disclosureDesc
                                          )
                                          setObId(item.obId)
                                          setButtonRecom(true);
                                          // setDesc(
                                          //   item.disclosure.disclosureDesc
                                          // )
                                          // setModal(!modal)
                                        }}
                                        disabled={
                                          data.obligation.disclosure_obligations_obligation.map((item) => 
                                            item.clients.length === 0).some((disabled) => disabled)
                                        }
                                      >
                                        Disclosure Text Updated
                                      </Button>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr style={{ textAlign: "center" }}>
                            <td colSpan="5">
                              <h4 style={{ marginTop: 5 }}>No Records Found</h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdatedReportView
