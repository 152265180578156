// import React from 'react'
// const WorkFlowChart = () => {
//   return (
//     <div>WorkFlowChart</div>
//   )
// }
// export default WorkFlowChart
import React from "react"
import { Row, Col, Card, Spinner } from "react-bootstrap"
import "./updateWorkFlow.css"
import PieChart from "../../../AllCharts/apex/PieChart"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { useSelector } from "react-redux"
import { CardBody, CardFooter, CardTitle, Progress } from "reactstrap"
import RadialSeparators from "../../../../components/Common/RadialSeparators"
import obligationImg from "../../../../assets/images/WorkFlowReviewDashboard/Obligation.jpg"
import disclosureImg from "../../../../assets/images/WorkFlowReviewDashboard/Disclosure.jpg"
import fileImg from "../../../../assets/images/WorkFlowReviewDashboard/LinkDisclosure.jpg"
import requiredImg from "../../../../assets/images/WorkFlowReviewDashboard/Required.jpg"
import { size } from "lodash"
// import { CircularProgressBar, LinearProgressBar } from "react-percentage-bar"

export function TableSideChart() {
  const { dashboardData, loading } = useSelector(
    state => state.workFlowReport || {}
  )
  console.log(dashboardData.chartData, "Here Is The Chart Data")
  if (loading || !dashboardData || !dashboardData.progressData) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }
  return (
    <div>
      {dashboardData &&
        dashboardData.progressData.map((item, index) => (
          <div key={index} className="pt-1">
            <Card
              className="card-data rounded-4"
              style={{
                height: "240px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CardBody
                style={{ width: "190px", height: "200px" }}
                className="chart-content"
              >
                {/* <CircularProgressBar
                  color={["#164297", "#F9C01F"]}
                  loopCount={false}
                  percentage={item.value}
                  roundLineCap={false}
                  shadow={true}
                  showPercentage={false}
                  startPosition={0}
                  styles="separators"
                  text={`${item.value}%`}
                /> */}
              </CardBody>
              <p className="border-top w-100 text-center py-2 h6 h6-sm h6-md h6-lg">
                {" "}
                {item.label}
              </p>
            </Card>
          </div>
        ))}
    </div>
  )
}
const WorkFlowChart = () => {
  const { dashboardData, loading } = useSelector(
    state => state.workFlowReport || {}
  )
  console.log(dashboardData.chartData, "Here Is The Chart Data")
  const existingIconSrc = [obligationImg, disclosureImg, fileImg, requiredImg]
  return (
    <>
      {/* {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : ( */}
      <>
        {dashboardData && dashboardData.statistics ? (
          <div style={{ marginTop: "10px" }}>
            <Row xs={1} md={4} className="g-4">
              {dashboardData.statistics.map((data, index) => (
                <Col xl="3" lg="6" md="6" sm="6" xs="12" key={index}>
                  <Card>
                    <Card.Body className="d-flex">
                      <div className="col-3">
                        {/* <Card.Title className="statics-card p-2"> */}
                        <Card.Title className="statics-card  p-2">
                          {/* <div className="rounded-pill"> */}
                          <img
                            src={
                              existingIconSrc[index % existingIconSrc.length]
                            }
                            alt="array icon"
                            className="w-100 h-100"
                          />
                          {/* </div> */}
                        </Card.Title>
                      </div>
                      <div className="col-9">
                        <span>
                          <div
                            className="h6 h6-sm h6-md h6-lg "
                            style={{ color: "#777A8B", fontSize: "0.8rem" }}
                          >
                            {data.label}
                          </div>
                          <div
                            className="p-0 fw-bold fs-4"
                            style={{ color: "#222529" }}
                          >
                            {data.value}
                          </div>
                          {/* <span className="position-absolute bottom-0 end-0 m-2">
                              +5.25%
                            </span> */}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {/* <div className="col-12"> */}
                <div className="row">
                  {dashboardData &&
                    dashboardData.chartData.map((data, index) => (
                      <Col key={index} lg="4" md="6" sm="6" xs="12">
                        <Card
                          className="py-2 rounded-4"
                          // id="workflow-report-cards"
                        >
                          {/* <Row> */}
                          <div
                            className="mx-2"
                            style={{ fontFamily: "Poppins" }}
                          >
                            <p className="h5 h6-sm h6-md h5-lg">{data.label}</p>
                          </div>
                          <div className="d-flex  justify-content-around">
                            <div style={{ width: "40%" }} className="d-flex justify-content-center">
                              <div
                                className="m-2 fs-5"
                                style={{
                                  height: "10rem",
                                  width: "10rem",
                                }}
                              >
                                <CircularProgressbar
                                  className="shadow-sm  p-1 mb-5 bg-body-tertiary rounded-pill"
                                  value={parseFloat(data.valueWithPR)}
                                  text={`${parseFloat(data.valueWithPR)}%`}
                                  strokeWidth={15}
                                  styles={buildStyles({
                                    pathColor: "#556EE6",
                                    textColor: "black",
                                    trailColor: "#D6D6D6",

                                    // Customize stroke-linecap to have square edges instead of rounded
                                    strokeLinecap: "butt", // 'butt' | 'round' | 'square'
                                  })}
                                />
                              </div>
                            </div>
                            <div style={{ width: "60%" }}>
                              {/* {console.log(data.details)} */}
                              {data.details.map((item, index) => (
                                <div key={index} className="px-2 pt-3">
                                  <div>{item.label}</div>
                                  {/* <div className="progress-parent"> */}
                                  <Progress
                                    // className={"my-1 bg-white"}
                                    className={`my-1 `
                                    // ${
                                    //   item.label == "Pending" ? "bg-white" : ""
                                    // }
                                    // `
                                  }
                                    color={`${
                                      item.label == "Completed"
                                        ? "primary"
                                        : "secondary"
                                    }`}
                                    style={{ height: "20px" }}
                                    value={item.valueWithPR}
                                  />
                                  {/* </div> */}
                                  {/* <div style={{ marginLeft: "-30px" }}>
                                          <LinearProgressBar
                                            showPercentage={false}
                                            // color='#556EE6'
                                            color={`${
                                              item.label == "Completed"
                                                ? "#556EE6"
                                                : "#CBCBCB"
                                            }`}
                                            roundLineCap={false}
                                            shadow={true}
                                            percentage={100}
                                            width="80%"
                                            height="20px"
                                            trackColor="rgba(236, 236, 236, 0.418)"
                                          />
                                        </div> */}
                                  <div>
                                    {item.text}
                                    {/* {`${
                                            item.label == "Completed"
                                              ? "Completed"
                                              : "Pending"
                                          }`} */}
                                  </div>
                                </div>
                              ))}

                              {/* <div className="px-2 pt-3">
                                    <div>Completed</div>
                                    <Progress
                                      className="my-1"
                                      color="primary"
                                      style={{ height: "20px" }}
                                      value={parseFloat(data.valueWithPR)}
                                    />
                                    <div>120 Obligations are Linked</div>
                                  </div> */}
                            </div>
                          </div>

                          {/* </Row> */}
                        </Card>
                      </Col>
                    ))}
                </div>
              </div>
        ) : (
          // </div>
          <div>No data available</div>
        )}
      </>
      {/* )} */}
    </>
  )
}
export default WorkFlowChart
