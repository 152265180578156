import React from 'react'
import ReviewDetails from './components/ReviewDetails'
import ReviewUnlikeModal from './components/ReviewUnlikeModal'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
const ReviewDetailList = () => {
  document.title = "Reg Content Change Review Summary"
  return (
    <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs
              title="Reg Content Change Review Summary"
              // breadcrumbItem={updateTitle}
              path="reg-review-report"
              breadcrumbItem={`Review Details`}
            /> */}
            <ReviewUnlikeModal/>
            <ReviewDetails/>
        </div>
    </div>
  )
}

export default ReviewDetailList