import React, { useEffect, useState } from "react"
import { ReactSearchAutocomplete } from "react-search-autocomplete"
import ReactSelect from "react-select"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { Popper } from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { handleNullAndUndefinedOfArray } from "helpers/helper_function"
const theme = createMuiTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "#fff", // Set your desired background color here
        // height: "35px",
      },
    },
  },
})

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 250, fontSize: 12 }}
      placement="bottom-start"
      id="popper-div"
    />
  )
}

export function RecordSelector({
  RecordList,
  field,
  setSelectedScanRec,
  selectedScanRec,
  setSearchScanRec,
}) {
  // note: the id field is mandatory

  const [seletOpt, setSelectOpt] = useState("")
  const classes = useStyles()

  // const items = RecordList.map((record, index) => ({
  //   id: index,
  //   name: record[field],
  // }))

  const items = RecordList
    ? RecordList.map(item => {
        let numericPart = ""
        if (item[`regulations_${field}`]) {
          numericPart = item[`regulations_${field}`]
        } else if (field == "regUrl") {
          numericPart = item[field]
        } else {
          numericPart = item[`obligations_${field}`]
        }
        if (numericPart) {
          return numericPart
        }
      })
    : []

  const handleOnSearch = value => {
    // setSelectedScanRec(string)
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // setSelectedScanRec(value)
    setSearchScanRec(value)
    console.log("test2545 handleOnSearch", value)
  }

  const handleOnSelect = item => {
    // the item selected
    if (handleNullAndUndefinedOfArray(items).includes(item)) {
       console.log("test2545 handleNullAndUndefinedOfArray: ", handleNullAndUndefinedOfArray(items))
    console.log("test2545 handleOnSelect: ", item)
    if (item === null ) {
      setSelectedScanRec("")
      setSearchScanRec("")
    } else {
      setSelectedScanRec(item)
      setSearchScanRec(item)
    }
    } 
   
  }

  const test = () => {
    console.log("RecordList", RecordList, items)
    console.log(`regulations_${field}`)
  }
  return (
    <div className="App" onClick={test}>
      <header className="App-header">
        <div style={{ width: 400 }}>
          {/* <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
          /> */}

          <MuiThemeProvider theme={theme}>
            <Autocomplete
              // classes={classes}
              style={{ width: "100%" }}
              PopperComponent={PopperMy}
              // disableClearable={true}
              options={handleNullAndUndefinedOfArray(items)}
              // getOptionLabel={(option) => option || ""}
              value={selectedScanRec}
              classes={{
                option: classes.option,
              }}
              freeSolo
              onChange={(event, value) => {
                value && handleOnSelect(value && value.trim())
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  variant="filled"
                  size="small"
                  // className="form-control"
                  className={classes.root}
                  value={selectedScanRec}
                  onChange={e => handleOnSearch(e.target.value)}
                  // onKeyDown={handleKeyDown}
                  // disableClearable={true}
                  InputLabelProps={{
                    shrink: false,
                    focused: false,
                  }}
                />
              )}
            />
          </MuiThemeProvider>
        </div>
      </header>
    </div>
  )
}

const demoData = [
  { regulations_regShortName: "12 CFR 1" },
  { regulations_regShortName: "12 CFR 12" },
  { regulations_regShortName: "12 CFR 21" },
  { regulations_regShortName: "12 CFR 28" },
  { regulations_regShortName: "12 CFR 3" },
  { regulations_regShortName: "12 CFR 30" },
  { regulations_regShortName: "12 CFR 31" },
  { regulations_regShortName: "12 CFR 32" },
  { regulations_regShortName: "12 CFR 34" },
  { regulations_regShortName: "12 CFR 43" },
].map(item => ({
  label: item.regulations_regShortName,
  value: item.regulations_regShortName,
}))

const demoData2 = [
  "12 CFR 1",
  "12 CFR 12",
  "12 CFR 21",
  "12 CFR 28",
  "12 CFR 3",
  "12 CFR 30",
  "12 CFR 31",
  "12 CFR 32",
  "12 CFR 34",
  "12 CFR 43",
]
