import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, CardTitle, Col, Row, Spinner } from 'reactstrap';
import { reviewLike, setReviewUnlikeModal, updateReview } from 'store/slices/workFlowReportSlice';
import {classifications, hexHtmlToString } from "helpers/helper_function"
import HtmlDiff from "htmldiff-js"
import toastr from "toastr"
import ListGroup from 'react-bootstrap/ListGroup';
// import { setReviewUnlikeModal } from 'store/slices/authorReviewSlice';

const ReviewDetails = () => {
  const location = useLocation()
  const redirect = useHistory()
  const dispatch = useDispatch()
  const rowData = location.state.rowData;
  const reportId = localStorage.getItem('rowDataId')
  const {reviewDetail, loading} = useSelector((state) => state.workFlowReport)
  const [reviewData , setReviewData] = useState('')
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const dropdownRef = useRef(null);

  const [selectedClassification, setSelectedClassification] = useState(0);
  
  useEffect(() => {
    setReviewData(reviewDetail.data ? 
      reviewDetail.data : rowData)
  }, [reviewDetail, rowData]);




  useEffect(() => {
    if (isOpen) {
      setHeight(dropdownRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };


  const handleReviewLike = useCallback(async () => {
    try {
      const response = await dispatch(reviewLike({
        reportId: reportId,
        obId: reviewData.obId,
        reviewStatus: 3
      }));
      toastr.success(response.payload.message);
      const res = await dispatch(updateReview({ reportId: reportId }));
      if (res.data === null) {
        history.push('/reg-review-report');
      }
    } catch (error) {
      toastr.success("You have already review done for this obligation.");
      throw error;
    }
  }, [dispatch, reportId, reviewData.obId, history]);

  // function firstHtml() {
  //   // const oldContent = oldString.replace(/<[^>]*>/g,'')
  //   return { __html: reviewDetail.data ? reviewDetail.data.oldobligationDesc : rowData.oldobligationDesc }
  // }

  // function secondHtml() {
  //   // const newContent = newString.replace(/<[^>]*>/g,'')
  //   return { __html: reviewDetail.data ? reviewDetail.data.obligationDesc : rowData.obligationDesc }
  // }

  // function diffHtml() {
  //   // console.log(firstHtml(), secondHtml())
  //   return {
  //     __html: HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
  //   }
  // }


  // useEffect(() => {
  //   diffHtml()
  // }, [reviewDetail])
  
  document.title="Review Details"
  return (
    <>
      {/* <h4 className="mb-sm-0 font-size-18 mb-md-2">{rowData && rowData.obId}</h4> */}
      <Row>
        <Col md="6">
          <Card style={{ height: '400px' }} className="overflow-auto">
            <CardTitle
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 6px',
                height: '40px',
                position: 'sticky',
                top: '0',
                background: 'white',
              }}
            >
              {loading ? (
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
                  <Spinner/>
                </div>
              ) : (
              <p style={{ marginLeft: '12px', marginTop: '6px' }}>Prior Citation Content</p>
              )}
            </CardTitle>
            <CardBody>
              <p className="card-title-desc">
              <div dangerouslySetInnerHTML={{ __html:  reviewData.oldobligationDesc }}></div>
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card style={{ height: '400px' }} className="overflow-auto">
            <CardTitle
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 6px',
                height: '40px',
                position: 'sticky',
                top: '0',
                background: 'white',
              }}
            >
              <p style={{ marginLeft: '12px', marginTop: '6px' }}>Disclosure Text Content</p>
            </CardTitle>
            <CardBody>
                 
              <div dangerouslySetInnerHTML=
              {{ __html: 
                reviewData.obligation?.disclosure_obligations_obligation?.[0]?.disclosure.text ?? "" }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card style={{ height: '400px' }} className="overflow-auto">
            <CardTitle
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 6px',
                height: '40px',
                position: 'sticky',
                top: '0',
                background: 'white',
              }}
            >
              <p style={{ marginLeft: '12px', marginTop: '6px' }}>New Citation Content</p>
            </CardTitle>
            <CardBody>
              <p className="card-title-desc"> 
              <div dangerouslySetInnerHTML={{ __html: reviewData.obligationDesc }}></div>
              {/* <div dangerouslySetInnerHTML={diffHtml()} /> */}
              </p>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card style={{ height: '330px' }} className="overflow-auto">
            <CardTitle
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 6px',
                height: '40px',
                position: 'sticky',
                top: '0',
                background: 'white',
              }}
            >
              <div style={{display:'flex', justifyContent:'space-between'}}>
              <p style={{ marginLeft: '12px', marginTop: '6px' }}>Intent</p>
              <div style={{display:'flex'}}>
              {reviewData.aiLikelihood &&  (
                <>        
                <p style={{marginTop: '6px', marginRight:'12px'}}>Likely Hood of Change :</p>
                <p style={{marginTop: '6px', marginRight: '16px', 
                border:'1px solid #F85656', 
                color:'#F85656',
                paddingRight:'10px',
                paddingLeft:'10px',
                borderRadius:'10px'
                }}>{reviewData.aiLikelihood}</p>
                </>
              )}
              </div>
              </div>
            </CardTitle>
            <CardBody>
              <p className="card-title-desc">
                  {reviewData.aiImpact ?? ""}
              </p>
            </CardBody>
          </Card>
          <Card style={{ height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '97%' }}>
            <span style={{fontSize:'14px', fontWeight: 600}}>Status</span>
            <div style={{display:'flex'}}>
            <div
              style={{
                width: '90px',
                border: '2px solid #E7E9EB',
                display: 'flex',
                justifyContent: 'space-around',
                borderRadius: '10px',
                cursor: 'pointer'
              }}
            >
              <button 
                disabled={reviewData.isReviewed == 1} 
                style={{ 
                  border: 'none', 
                  background: 'none', 
                  cursor: reviewData.isReviewed == 1 ? 'no-drop' : 'pointer'
                }}
              >
                <i 
                  onClick={handleReviewLike} 
                  style={{ 
                    fontSize: '22px', 
                    color: reviewData.isReviewed == 1 ? 'grey' : '#556EE6' 
                  }} 
                  className="mdi mdi-thumb-up-outline" 
                />
              </button>
              <i  onClick={() => { dispatch(setReviewUnlikeModal(true)) }} 
              style={{ color: '#556EE6', fontSize: '22px' }} 
              className="mdi mdi-thumb-down-outline" />
            </div>
            <i style={{display:'flex', alignItems:'center', 
              marginLeft:'10px', fontSize:'24px', 
              color:'#556EE6'}} className="bx bx-info-circle"></i>
            </div>
          </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ReviewDetails;
