import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Button,
  Label,
  Form,
  BreadcrumbItem,
  Table,
  Badge,
  Card,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ActionModal } from "./ActionModal"
import { RecordSelector } from "./RecordSelector"
import {
  CirculareLoader,
  POST_API,
  RedirectOnUnAuthentication,
  ToastError,
  ToastSuccess,
  encryptData,
} from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Pagination from "../../../components/Common/Pagination"
import { LOCAL_API_END_POINT } from "helpers/general_constant"
const AddRecordComponent = ({
  scanList,
  setSelectedScanRec,
  selectedScanRec,
  fieldName,
  setSearchScanRec,
}) => {
  return (
    <>
      <RecordSelector
        RecordList={scanList}
        field={fieldName}
        setSelectedScanRec={setSelectedScanRec}
        selectedScanRec={selectedScanRec}
        setSearchScanRec={setSearchScanRec}
      />
    </>
  )
}

const DeleteRecordComponent = ({
  scanList,
  setSelectedScanRec,
  selectedScanRec,
  deleteData,
}) => {
  return (
    <>
      <h5 onClick={() => console.log(deleteData)}>
        Are you want to delete this {deleteData ?? "record"}?
      </h5>
    </>
  )
}

const EditSingleRecord = ({ AllProps }) => {
  const {
    setEditData,
    editData,
    setCurrentPage,
    editSingleData,
    scanTypeData,
  } = AllProps
  const [selectedState, setSelectedState] = useState()
  const [modalShow, setModalShow] = useState(false)
  const JSONTokenData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : { data: { authToken: "" } }
  const authToken = `Bearer ${JSONTokenData.data.authToken}`
  const [modalData, setModalData] = useState({})
  const [scanList, setScanList] = useState([])
  const [editScanList, setEditScanList] = useState([])
  const [modelLoader, setModelLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [customePageSize, setCustomePageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [currentPagination, setCurrentPagination] = useState(1)
  const [selectedScanRec, setSelectedScanRec] = useState("")
  const [searchScanRec, setSearchScanRec] = useState("")
  const [deleteData, setDeleteData] = useState({})
  const callUseNavigate = useHistory()
  const navigateTo = url => {
    callUseNavigate.push(url)
  }

  const FatchScanRecord = () => {
    setLoader(true)
    const bodyData = {
      flag: editSingleData.fieldName,
      search: searchScanRec,
      type: scanTypeData.type,
    }
    POST_API({
      endPoint: `${process.env.REACT_APP_BASE_URL}/get-scan-settings-details/?limit=100`,
      body: {data:encryptData(bodyData)},
      providedAuthToken: authToken,
    })
      .then(res => {
        console.log("get-scan", res.data.data)
        setScanList(res?.data?.data ?? [])
      
        setLoader(false)
      })
      .catch(err => {
        console.log(err)
        setLoader(false)
        // ToastError(err)
        // RedirectOnUnAuthentication(err,navigateTo)
      })
  }

  const FatchEditScanSettings = () => {
    setLoader(true)
    const bodyData = {
      type: scanTypeData.type,
      flag: editSingleData.fieldName,
    }
    POST_API({
      endPoint: `${process.env.REACT_APP_BASE_URL}/edit-scan-settings?limit=${customePageSize}&pageNo=${currentPagination}`,
      body: {data:encryptData(bodyData)},
      providedAuthToken: authToken,
    })
      .then(res => {
        console.log("FatchEditScanSettings", res?.data?.totalRecords,res?.data?.data?.length )
        setEditScanList(res?.data?.data ?? [])
        setTotalItems(res?.data?.totalRecords ?? 0)

        if (currentPagination != 1) {
          if (res?.data?.data?.length == 0) {
            setCurrentPagination(currentPagination - 1)
          }
        }
        setLoader(false)
      })
      .catch(err => {
        console.log(err)
        // ToastError(err)
        setLoader(false)
        RedirectOnUnAuthentication(err, navigateTo)
      })
  }

  const handleSubmit = () => {
    if (selectedScanRec.length > 0) {
      setModelLoader(true)
      setModalShow(false)
      console.log("submit")
      const bodyData = {
        type: scanTypeData.type,
        flag: editSingleData.fieldName,
        values: [selectedScanRec],
      }
      POST_API({
        endPoint: `${process.env.REACT_APP_BASE_URL}/add-scan-settings`,
        body: {data:encryptData(bodyData)},
        providedAuthToken: authToken,
      })
        .then(res => {
          console.log("add-scan-settings", res)
          FatchEditScanSettings()
          ToastSuccess({ message: "Record Added Successfully." })
          setSelectedScanRec("")
          setModelLoader(false)
          // setScanList(res?.data?.data??[])
        })
        .catch(err => {
          console.log(err)
          ToastError(err)
          // ToastError(err)
          setModelLoader(false)
          RedirectOnUnAuthentication(err, navigateTo)
        })
    }
  }

  const handleDelete = () => {
    setModelLoader(true)
    setModalShow(false)
    console.log("handleDelete")
    const bodyData = {
      type: scanTypeData.type,
      flag: editSingleData.fieldName,
      values: [deleteData],
    }
    POST_API({
      endPoint: `${process.env.REACT_APP_BASE_URL}/delete-scan-settings`,
      body: {data:encryptData(bodyData)},
      providedAuthToken: authToken,
    })
      .then(res => {
        console.log("delete-scan-settings", res)
        // setScanList(res?.data?.data ?? [])
        FatchEditScanSettings()
        FatchScanRecord()
        ToastError({ message: "Record Delete successfully" })
        setModelLoader(false)
      })
      .catch(err => {
        console.log(err)
        ToastError(err)
        RedirectOnUnAuthentication(err, navigateTo)
        setModelLoader(false)
      })
  }

  const OnDelete = data => {
    setModalShow(true)
    setModalData({
      title: `Delete ${editSingleData["lableName"]}`,
      type: "delete",
      submit: handleDelete,
    })
    setDeleteData(data)
  }
  const OnAdd = () => {
    setSelectedScanRec("")
    setModalData({
      title: `Add ${editSingleData["lableName"]}`,
      type: "add",
      submit: handleSubmit,
    })
    setModalShow(true)
  }
  useEffect(() => {
    console.log("selectedScanRec", selectedScanRec)
    FatchScanRecord()
  }, [searchScanRec, editSingleData])
  useEffect(() => {
    FatchEditScanSettings()
  }, [currentPagination])
  useEffect(() => {
    setSelectedScanRec("")
    setSearchScanRec("")
  }, [modalShow])
  const test = () => {
    console.log(editSingleData["fieldName"], "selectedScanRec", selectedScanRec)
  }
  return (
    <>
      <div className="RegulationScanEdit">
        <Row className=" d-flex align-items-center">
          <Col className="col-12 ">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18 w-30">
                Update {editSingleData.lableName}
              </h4>
              <div className="page-title-right  w-70 breadCrumbBox">
                <span
                  className="  m-0 w-100 breadCrumbOL"
                  style={{ width: "100% !important" }}
                >
                  <BreadcrumbItem>
                    <span
                      className="cusorPointer"
                      onClick={() => setCurrentPage(0)}
                    >
                      {" "}
                      &nbsp;{" "}
                      {scanTypeData.type == 1 ? "Production Scan" : "Test Scan"}
                      &nbsp;
                    </span>
                  </BreadcrumbItem>

                  <BreadcrumbItem>
                    <span
                      className="cusorPointer"
                      onClick={() => setCurrentPage(2)}
                    >
                      &nbsp;{`Regulation Scan`}&nbsp;
                    </span>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    &nbsp;Update {editSingleData.lableName}
                  </BreadcrumbItem>
                </span>
              </div>
            </div>
          </Col>
          <Col md={6} className="  justify-content-end"></Col>
          <Col md={6} className=" justify-content-end">
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              <div className="btn btn-primary" onClick={OnAdd}>
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add {editSingleData.lableName}
              </div>
            </div>
          </Col>
        </Row>
        <br />
        {editScanList.length === 0 ? (
          <div className="noRecord">
            {loader ? <CirculareLoader /> : <h2>No Record</h2>}
          </div>
        ) : (
          ""
        )}
        <div
          className={
            editScanList.length === 0 ? "d-none" : "form-group OverFlowAuto"
          }
        >
          <Row className="mb-3">
            <Card className="shadow-none border mb-0">
              <Table className="table align-middle mb-0 table-nowrap">
                {/* <Col xs="12" md="12"  > */}
                <thead className="table-light">
                  <tr>
                    <th scope="col">
                      <Label className="form-label th">
                        {editSingleData.lableName}
                      </Label>
                    </th>
                    {/* <th scope="col">Product Desc</th> */}
                    <th scope="col" colSpan="2">
                      <Label className="form-label th">Action</Label>
                    </th>
                  </tr>
                </thead>
                {/* </Col> */}
                <tbody>
                  {editScanList.map((i, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th scope="row">
                            <Label className="form-label overFlowWrap">
                              {i[editSingleData.fieldName]}
                            </Label>
                          </th>
                          <td>
                            <Badge
                              className="font-size-15 badge-soft-danger"
                              pill
                            >
                              <i
                                style={{ color: "red", cursor: "pointer" }}
                                className="bx bx-trash font-size-18   "
                                onClick={() =>
                                  OnDelete(i[editSingleData.fieldName])
                                }
                                // id="edittooltip"
                              ></i>
                            </Badge>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </Table>
            </Card>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col className="col-auto">
              <Pagination
                className="pagination-bar"
                currentPage={currentPagination}
                totalCount={totalItems}
                pageSize={customePageSize}
                onPageChange={page => setCurrentPagination(page)}
              />
            </Col>
          </Row>
        </div>
        {/* <Col xs="12" md="12">
          <div className="text-end">
            <Button
              onClick={() => setCurrentPage(2)}
              className="btn btn-secondary waves-effect waves-light "
              color="secondnary"
            >
              Cancel
            </Button>
          </div>
        </Col> */}
      </div>

      <>
        <ActionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          Body={
            modalData.type == "add" ? (
              <AddRecordComponent
                scanList={scanList}
                setSelectedScanRec={setSelectedScanRec}
                selectedScanRec={selectedScanRec}
                fieldName={editSingleData["fieldName"]}
                setSearchScanRec={setSearchScanRec}
              />
            ) : (
              <DeleteRecordComponent
                scanList={scanList}
                setSelectedScanRec={setSelectedScanRec}
                selectedScanRec={selectedScanRec}
                deleteData={deleteData}
              />
            )
          }
          ModalHeading={modalData.title}
          handleSubmit={modalData.type == "add" ? handleSubmit : handleDelete}
          btnMassege={modalData.type == "add" ? "Submit" : "Yes"}
          modelLoader={modelLoader}
        />
      </>
    </>
  )
}

export default EditSingleRecord
