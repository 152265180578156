import React from 'react'
import AuthorReviewTable from './components/AuthorReviewTable'
import AuthorReviewModal from './components/AuthorReviewModal'

const AuthorReviewList = () => {
  return (
    <div>
        <AuthorReviewTable/>
        <AuthorReviewModal/>
    </div>
  )
}

export default AuthorReviewList