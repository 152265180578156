import React, { useMemo, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  BreadcrumbItem,
} from "reactstrap" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Tooltip from "@material-ui/core/Tooltip"
import DeleteModal from "../../../components/Common/DeleteModal"
import { useDispatch } from "react-redux"
import { deleteDisclosure as onDeleteDisclosure } from "../../../store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import HtmlDiff from "htmldiff-js"
import PDFGenerator from "./PdfComponent"
import "./diff.css"
import { CheckValidValue, DisplayJustifyCenter, FormatedDate, hexHtmlToString } from "helpers/helper_function"

const UpdatedReportView = props => {
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [deleteDisc, setDeleteDiscl] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [title, setTitle] = useState(false)
  const [disclosureAccess, setDisclosureAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [newModal, setNewModal] = useState(false)
  const [oldString, setOldString] = useState("")
  const [newString, setNewString] = useState("")
  const [diffContent, setDiffContent] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDisclosureAccess(authToken && authToken.data.role.rolesPermission[3])
  }, [])

  const toggle = () => setModal(!modal)

  const newToggle = () => {
    setNewModal(!newModal)
  }

  const data = location.state.rowData
  const tdata = location.state.rowData
  // console.log(data.obligation.disclosure_obligations_obligation, "data")
  let rowData = data
  document.title = "Test Reg Content Change Report"

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  function firstHtml() {
    // const oldContent = oldString.replace(/<[^>]*>/g,'')
    return { __html: oldString }
  }

  function secondHtml() {
    // const newContent = newString.replace(/<[^>]*>/g,'')
    return { __html: newString }
  }

  function diffHtml() {
    // console.log(firstHtml(), secondHtml())
    return {
      __html: HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
    }
  }

  const contentRef = React.useRef(null);
 
  const setInnerText = (content) => {
    // if (contentRef.current) {
      const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    // }
  }
  
  const handleDiff = () => {
    const diffHtmlResult = diffHtml()
    setDiffContent(diffHtmlResult)
  }

  const disclosureList =
    data &&
    data?.obligation &&
    data?.obligation?.disclosure_obligations_obligation?.length > 0
      ? data?.obligation?.disclosure_obligations_obligation?.map(
          item =>
            `<tr>
      <th scope="row">${item.disId}</th>
      <td>${item.disclosure.disclosureTitle.substr(0, 25) + "..."}</td>
     
  </tr>`
        )
      : ` <tr>
      <th scope="row">No Records Found</th> 
  </tr>`

  const content = `<div className="inner">
  <div>DIFFERENCE</div>
  <div dangerouslySetInnerHTML={${diffHtml()}} />
</div>`

  console.log(content, "content")
  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs
            title="Scanned Report"
            // breadcrumbItem={updateTitle}
            path="updated-obli-report"
            breadcrumbItem={"Updated Report List"}
          /> */}
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {"Updated Obligation Detail"}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "/test-new-obli-report",
                          // state: { rowData: {id: data.queryId} },
                        }}
                      >
                        {"Scanned Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "/test-updated-obli-report",
                          state: {
                            rowData: {
                              id: data.queryId,
                              createdDate:props?.location?.state?.rowData?.createdDate ,
                              clear: false,
                            },
                          },
                        }}
                      >
                        {"Test Reg Content Change Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {"Updated Obligation Detail"}
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
          {/* <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Updated Obligation Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
              </div>
            </div>
          </div> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "25%" }}>Obligation ID</th>
                          <td style={{ width: "65%" }}>  {CheckValidValue({value:data.obId })?data.obId: "-"  } </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Obligation Name</th>
                          <td style={{ width: "65%" }}>
                          {CheckValidValue({value:data.obligation.obligationName   })?data.obligation.obligationName  : "-"  } 
                         
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Prior Updated Date</th>
                          <td style={{ width: "65%" }}>
                            {/* {(data && data.updatedDate)?data.updatedDate:'-'} */}
                            {data && data.prevUpdatedDate
                              ? FormatedDate(data && data.prevUpdatedDate) 
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>
                            Prior Citation Content
                          </th>
                          <td style={{ width: "65%" }}>
                          {CheckValidValue({value:data?.oldobligationDesc })?     <DarkTooltip
                              title="View Details"
                              style={{ width: "15px" }}
                            >
                              <div>
                                <i
                                  onClick={() => {
                                    setModal(!modal)
                                    // setDesc(data.oldobligationDesc||"")
                                    setTimeout(() => {
                                      setInnerText(data.oldobligationDesc||"")
                                    },500)
                                    setTitle("Prior Citation Content")
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    padding: "0 5px",
                                    borderRadius: "20%",
                                    color: "#556ee6",
                                  }}
                                  className="bx bxs-info-circle  font-size-24"
                                  id="descToolTip"
                                ></i>
                                {/* <div
                                        onClick={() => {
                                          setModal(!modal)
                                          setDesc(data.oldobligationDesc)
                                        }}
                                      ></div> */}
                              </div>
                            </DarkTooltip>: "-"  } 
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: data && data.oldobligationDesc,
                              }}
                            ></div> */}
                       
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>New Citation Content</th>
                          <td style={{ width: "65%" }}>
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: data && data.obligationDesc,
                              }}
                            ></div> */}

{CheckValidValue({value:data.obId })?  <DarkTooltip
                              title="View Details"
                              style={{ width: "15px" }}
                            >
                              <div>
                                <i
                                  onClick={() => {
                                    // setModal(!modal)
                                    // setDesc(data.obligationDesc)
                                    setNewModal(!newModal)
                                    setOldString(data.oldobligationDesc)
                                    setNewString(data.obligationDesc)
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    padding: "0 5px",
                                    borderRadius: "20%",
                                    color: "#556ee6",
                                  }}
                                  className="bx bxs-info-circle  font-size-24"
                                  id="descToolTip"
                                ></i>
                                {/* <div
                                        onClick={() => {
                                          setNewModal(!newModal)
                                          setDesc(data.obligationDesc)
                                        }}
                                      ></div> */}
                              </div>
                            </DarkTooltip>: "-"  } 
                          
                          </td>
                        </tr>

                        <tr>
                          <th style={{ width: "25%" }}>Citation</th>
                          <td style={{ width: "65%" }}> 
                            {CheckValidValue({value:data.obligation.citation  })?data.obligation.citation : "-"  } 
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Citation URL</th>
                          <td style={{ width: "65%" }}>
                          {CheckValidValue({value:data.obligation.citationUrl   })?  <Link
                              to={{ pathname: data.obligation.citationUrl }}
                              target="_blank"
                            >
                              {(data && data.obligation.citationUrl)  } 
                            </Link>: "-"  } 
                          
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Reg Law Short Name</th>
                          <td style={{ width: "65%" }}> 
                            {CheckValidValue({value:data.obligation.regLaw  })?data.obligation.regLaw : "-"  } 
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "25%" }}>Regulator Short Name</th>
                          <td style={{ width: "65%" }}> 

                            {CheckValidValue({value:data.obligation.issuingAuthority  })?data.obligation.issuingAuthority : "-"  } 
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row> */}
          {/*page-content table-striped */}
          {/* <Col lg="12"> */}
          {/* <PDFDownloadLink
                document={<PDFDocument data={data} />} // Pass the data to the PDFDocument component
                fileName="updated_report.pdf" // Specify the file name for the downloaded PDF
              >
                {({ loading }) =>
                  loading ? "Loading document..." : <Button color="primary">Download PDF</Button>
                } 
              </PDFDownloadLink> */}

          {/* <PDFGenerator
                setOldString={setOldString}
                setNewString={setNewString}
                diffHtml={diffHtml}
                handleDiff={handleDiff}
                details={data}
                data={`<div >
<div>
    <div class="row"> 
        <div class="col-12 col">
            <div>
                <h4 >Updated Obligation Detail</h4>
                
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div >
                        <table class="table-striped px-0 table">
                            <tbody>
                                <tr>
                                    <th style="width: 25%;">Obligation ID</th>
                                    <td style="width: 65%;">${
                                      data && data?.obId
                                    }</td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Obligation Name</th>
                                    <td style="width: 65%;">${
                                      data && data?.obligation.obligationName
                                    }
                                    </td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Prior Updated Date</th>
                                    <td style="width: 65%;">${
                                      data && data?.prevUpdatedDate
                                        ?  
                                     data && data?.prevUpdatedDate   : "-"
                                    }</td>
                                </tr>
                             
                                <tr>
                                    <th style="width: 25%;">Citation</th>
                                    <td style="width: 65%;">${
                                      (data && data?.obligation.citation) || "-"
                                    }</td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Citation URL</th>
                                    <td style="width: 65%;"><a target="_blank"
                                            href="https://www.consumerfinance.gov/rules-policy/regulations/1026/18/">${
                                              (data &&
                                                data?.obligation.citationUrl) ||
                                              "-"
                                            }</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Reg Law Short Name</th>
                                    <td style="width: 65%;"> ${
                                      (data && data?.obligation.regLaw) || "-"
                                    }</td>
                                </tr>
                                <tr>
                                    <th style="width: 25%;">Regulator Short Name</th>
                                    <td style="width: 65%;"> ${
                                      (data &&
                                        data?.obligation.issuingAuthority) ||
                                      "-"
                                    }</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <div class="row">
        <div class="col-12 col">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Linked Disclosures List</h4>
            </div>
        </div>
    </div> 
    <div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="table-responsivepx-0">
                    <table class="table table-responsive px-0">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Disclosure Name</th>
                            </tr>
                        </thead>
                        <tbody>
                           ${disclosureList}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div ></div> 
      ${oldString}                  
    </div>
              </div>`}
              /> */}
          {/* </Col>
          </Row> */}
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  Linked Disclosures List
                </h4>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}> {title}</ModalHeader>
            <ModalBody className="text-left">
              {/* <div style={{ whiteSpace: "break-spaces" }}  dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={newModal}
            toggle={newToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={newToggle}>New Citation Content</ModalHeader>
            <ModalBody className="text-left">
              <div className="inner">
                {/* <div>DIFFERENCE</div> */}
                <div dangerouslySetInnerHTML={diffHtml()} />
              </div>
              {/* <StringDiff oldValue={oldString} newValue={newString} /> */}
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={newToggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    <table className="table table-responsive mt-3 px-0">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Disclosure Title</th>
                          <th scope="col">Disclosure Text</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                        data.obligation &&
                        data.obligation.disclosure_obligations_obligation
                          .length > 0 ? (
                          data.obligation.disclosure_obligations_obligation.map(
                            item => (
                              <tr key={item.id}>
                                <th scope="row">{item.disId}</th>
                                <td>
                                  {/* <div   className="StringOverFlow1">
{item.disclosure.disclosureTitle }
                                  </div> */}
                                  <LightTooltip title={item.disclosure.disclosureTitle}>
                                        <span style={{ color: "#000" }}   className="StringOverFlow1">
                                          {CheckValidValue({value:item.disclosure.disclosureTitle})?item.disclosure.disclosureTitle : DisplayJustifyCenter("-")}
                                        </span>
                                  </LightTooltip>
                                  
                                </td>
                                <td>
                                  <DarkTooltip
                                    title="View Details"
                                    style={{ width: "15px" }}
                                  >
                                    <div>
                                      <i
                                        onClick={() => {
                                          console.log(item);
                                          setModal(!modal)
                                          // setDesc(
                                          //   item.disclosure.text
                                          // )
                                          setTimeout(() => {
                                            setInnerText(item.disclosure.text)
                                          },500)
                                          setTitle("Disclosure Text")
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          textAlign: "center",
                                          padding: "0 5px",
                                          borderRadius: "20%",
                                          color: "#556ee6",
                                        }}
                                        className="bx bxs-info-circle  font-size-24"
                                        id="descToolTip"
                                      ></i>
                                      <div
                                        onClick={() => {
                                          setModal(!modal)
                                          setDesc(
                                            item.disclosure.text
                                          )
                                          setTitle("Disclosure Text")
                                        }}
                                      ></div>
                                    </div>
                                  </DarkTooltip>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr style={{ textAlign: "center" }}>
                            <td colSpan="3">
                              <h4 style={{ marginTop: 5 }}>No Records Found</h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdatedReportView
