import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import toastr from "toastr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import Switch from "react-switch"
import { fetchOrgOptions } from "../../../store/actions"
import { setOrgOptions } from "../../../store/actions"
import {
  addNewRegulator as onAddNewRegulator,
  updateRegulator as onUpdateRegulator,
} from "store/actions"
import axios from "axios"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { CheckValidValue, NotNull, RedirectOnUnAuthentication, encryptData } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const OrganizationUpdate = props => {
  const { setShowOrgaTable, editOrgaData } = props
  const [dropdownState, setDropdownState] = useState(false)
  const [ckData, setCkData] = useState("")
  const [selectedJuris, setSelectedJuris] = useState()
  const [selectedCntry, setSelectedCntry] = useState()
  const [selectedState, setSelectedState] = useState()
  const [btnDisable, setBtnDisable] = useState(false)
  const [roleOption, setRoleOption] = useState([])
  const [orgOption, setOrgOption] = useState([])
  const [status, setStatus] = useState(true)
  const [userId, setUserId] = useState("")
  const [checkAdmin, setCheckAdmin] = useState(false)
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const dispatch = useDispatch()

  console.log(editOrgaData, "editOrgaData")

  const location = useLocation()

  const [data, setData] = useState()

  const menu = true


  const datas = {
    tableName: "roles",
    fieldName: ["roles.id", "roles.name"],
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-all`,
        {data:encryptData(datas)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data)
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;

          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.name,
          }))
          // const modifiedOptions = [
          //   { label: 'Select Regulator', value: 0 }, // 0 option
          //   ...optionData // API options
          // ];
          setRoleOption(optionData)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const datas = {
      tableName: "customerOrganization",
      fieldName: [
        "customerOrganization.id",
        "customerOrganization.companyName",
      ],
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-all`,
        {data:encryptData(datas)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data)
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;

          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.companyName,
          }))
          // const modifiedOptions = [
          //   { label: 'Select Regulator', value: 0 }, // 0 option
          //   ...optionData // API options
          // ];
          setOrgOption(optionData)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (
      authToken &&
      authToken.data.customerorg.id === 3 &&
      authToken.data.role.id !== 17
    ) {
      setCheckAdmin(true)
    }
  }, [])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (authToken && authToken.data.role.id === 17) {
      setUserId(authToken.data.id)
      setCheckUser(true)
    }
  }, [])

  // console.log(roleOption, "roleOption")
  useEffect(() => {
    if (editOrgaData) {
      setStatus(editOrgaData.status)
    }

    if (location.state) {
      setData(location.state.rowData)
      const cntry = countryOptions.find(
        name =>
         `${NotNull(name?.value)}`.toLowerCase() ===
        `${NotNull(location?.state?.rowData?.country)}`.toLowerCase()
      )
      const forState = stateOptions.find(
        name =>
        `${NotNull(name?.value)}`.toLowerCase() ===
        `${NotNull(location?.state?.rowData?.state)}`.toLowerCase() 
      )

      if (cntry !== undefined) {
        setSelectedCntry(cntry)
      }
      if (forState !== undefined) {
        setSelectedState(forState)
      }
    }
  }, [])

  // const addNewOrganization = addData => {
  //     const authToken = JSON.parse(localStorage.getItem('authUser'))
  //     return axios
  //         .post(`${process.env.REACT_APP_BASE_URL}add-organization`, addData,{
  //             headers: {
  //                 'Authorization':`Bearer ${authToken.data.authToken}`
  //             }
  //         })
  //         .then(response => {
  //             if (response.status >= 200 || response.status <= 299){
  //                 console.log(response, "response")
  //                 setShowOrgaTable("table")
  //                 toastr.success("Organization successfully Added")
  //                 dispatch(fetchOrgOptions(userId, checkAdmin, menu));

  //                 const options = response.data.map((option) => ({
  //                     value: option.id,
  //                     label: option.companyName,
  //                 }));
  //                 localStorage.setItem('orgOptions', JSON.stringify(options));
  //                 dispatch(setOrgOptions(options));

  //             }
  //         })
  //         .catch(err => {
  //             let message;
  //             console.log(err, "err")
  //             if (err.response && err.response.status) {
  //                 switch (err.response.status) {
  //                     case 404:
  //                         message = "Sorry! the page you are looking for could not be found";
  //                         break;
  //                     case 400:
  //                         message =err.response.data.message
  //                         break;
  //                     case 500:
  //                         message =
  //                             "Sorry! something went wrong, please contact our support team";
  //                         break;
  //                     case 401:
  //                         message = "Invalid credentials";
  //                         break;
  //                     default:
  //                         message = err[1];
  //                         break;
  //                 }
  //             }
  //             // return message
  //             setShowOrgaTable("table")
  //             console.log(message, "message")
  //             toastr.error(message)
  //             // throw message;
  //         });
  // };

  const addNewOrganization = addData => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}add-organization`, {data:encryptData(addData)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response.data)
          //   console.log(response.data.companyName, "response.data.companyName");
          setShowOrgaTable("table")
          toastr.success("Organization successfully Added")

          // Retrieve existing options from localStorage
          const cachedOptions = localStorage.getItem("orgOptions")
          const existingOptions = cachedOptions ? JSON.parse(cachedOptions) : []

          // Create a new option for the added organization
          const newOption = {
            value: response.data.data.id,
            label: response.data.data.companyName,
          }

          //   const newOption = response.data.map((option) => ({
          //     value: option.id,
          //     label: option.companyName,
          // }));

          console.log(newOption, "newOption")

          // Update the options with the new option
          const updatedOptions = [...existingOptions, newOption]

          // Update localStorage with the updated options
          localStorage.setItem("orgOptions", JSON.stringify(updatedOptions))
          localStorage.setItem("testDeply", ["test", "deploy"])

          // Update Redux state with the updated options
          dispatch(setOrgOptions(updatedOptions))
        }
      })
      .catch(err => {
        let message
        console.log(err, "err")
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        setShowOrgaTable("table")
        console.log(message, "message")
        RedirectOnUnAuthentication(err,navigateTo,message)
        // throw message;
      })
  }

  const updateOrganization = editData => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return (
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}update-organization`,
          {data:encryptData(editData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        //   .then(response => {
        //     if (response.status >= 200 || response.status <= 299){
        //       console.log(response, "response")
        //       toastr.success("Organization Successfully Updated")
        //       setShowOrgaTable("table")
        //     }
        //   })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            console.log(response, "response")
            toastr.success("Organization Successfully Updated")
            setShowOrgaTable("table")

            // Retrieve existing options from localStorage
            const cachedOptions = localStorage.getItem("orgOptions")
            const existingOptions = cachedOptions
              ? JSON.parse(cachedOptions)
              : []

            // Find and update the edited organization option
            console.log(editData.id, "editData.id")
            const updatedOptions = existingOptions.map(option => {
              if (option.value === editData.id) {
                return {
                  ...option,
                  label: editData.companyName, // Update the label with the new company name
                }
              }
              return option
            })
            console.log(updatedOptions, "updatedOptions")
            // Update localStorage with the updated options
            localStorage.setItem("orgOptions", JSON.stringify(updatedOptions))

            // Update Redux state with the updated options
            dispatch(setOrgOptions(updatedOptions))
          }
        })
        .catch(err => {
          let message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = err.response.data.message
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          // return message
          console.log(message, "message")
          // setShowOrgaTable("table")
          RedirectOnUnAuthentication(err,navigateTo,message)
        })
    )
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: (editOrgaData && editOrgaData.companyName) || "",
      email: (editOrgaData && editOrgaData.email) || "",
      phone: (editOrgaData && editOrgaData.phone) || "",
      // jurisdiction: selectedJuris || "",
      //   idx : idx || '',
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Please Enter Company Name"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Please Enter Email"),
      // phone: Yup.number().min(10, "Must be minimum 10 digits").required("Please Enter Contact Number"),
      phone: Yup.string()
        .required("Contact number is required")
        .max(10, "Must be maximum 10 digits")
        .matches(
          /^\+?\d{8,14}$/,
          "Invalid phone number. Please enter a valid phone number."
        ),
      // role: Yup.object().required("Please Select Role"),
      // customerorg: Yup.object().required("Please Select Organization"),
      // country: Yup.object().required("Please Select country"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      if (!editOrgaData) {
        const addData = {
          companyName: values.companyName,
          email: values.email,
          phone: values.phone,
          status: status,
        }
        // console.log(addData, 'addData')
        addNewOrganization(addData)
      } else {
        const updateData = {
          id: editOrgaData && editOrgaData.id,
          companyName: values.companyName,
          email: values.email,
          phone: values.phone,
          status: status,
        }
        console.log(updateData, "updateData")
        updateOrganization(updateData)
      }
    },
  })

  const addTitle = "Add Organization"
  const updateTitle = "Update Organization"
  //meta title
  //   document.title = "Users"

  return (
    <>
      {/* <div className="page-content"> */}
      <Container fluid={true}>
        <Breadcrumbs
          title="Organization"
          path="#"
          // breadcrumbItem={updateTitle}
          breadcrumbItem={editOrgaData ? updateTitle : addTitle}
          setShowOrgaTable={setShowOrgaTable}
        />
        {/* write Html code or structure */}
        {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Password Changed Successfully.</Alert>
              ) : null}
            </Col>
          </Row> */}
        <Card>
          {/* <CardBody> */}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Company Name</Label>
                    <Input
                      name="companyName"
                      // value={data && data.short_name}
                      className="form-control"
                      placeholder="Enter Company Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.companyName || ""}
                      invalid={
                        validation.touched.companyName &&
                        validation.errors.companyName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.companyName &&
                    validation.errors.companyName ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.companyName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      // value={data && data.short_name}
                      className="form-control"
                      placeholder="Enter Email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Phone</Label>
                    <Input
                      name="phone"
                      // value={data && data.long_name}
                      className="form-control"
                      placeholder="Enter Contact Number"
                      type="tel"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Label check>Status</Label>
                  {/* <FormGroup switch> */}
                  <div className="mt-2">
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setStatus(!status)
                      }}
                      checked={status}
                    />
                  </div>

                  {/* </FormGroup> */}
                </Col>
              </Row>
            </div>
            <Col xs="12" md="12">
              <div className="text-end">
                <Button
                  disabled={btnDisable}
                  type="submit"
                  className="me-2"
                  color="primary"
                >
                  {editOrgaData ? "Update Organization" : "Add Organization"}
                </Button>
                <Link>
                  <Button
                    onClick={() => setShowOrgaTable("table")}
                    className="btn btn-secondary waves-effect waves-light "
                    color="secondnary"
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Form>
          {/* </CardBody> */}
        </Card>
      </Container>
      {/* </div> */}
    </>
  )
}

export default OrganizationUpdate
