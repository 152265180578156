// reducer.js
import { SET_ORG_OPTIONS } from "./actionTypes"

const initialState = {
  orgOptions: [],
}

const ObsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORG_OPTIONS:
      console.log(action, "action")
      return {
        ...state,
        orgOptions: action.payload,
      }
    default:
      return state
  }
}

export default ObsReducer
