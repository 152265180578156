import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap" 
import { useDispatch } from "react-redux"
import { deleteObligation as onDeleteObligation } from "../../store/actions"
import DeleteModal from "../../components/Common/DeleteModal"
import { Link } from "react-router-dom"
import { CheckValidValue, ColumnDataWithTooltip, FormatedDate } from "helpers/helper_function"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
const ObligationView = props => {
  const [delObligation, setDelObligation] = useState(null)
  const [modal, setModal] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const [obligationAccess, setObligationAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [desc, setDesc] = useState("")
  const toggle = () => setModal(!modal)
  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setObligationAccess(authToken && authToken.data?.role.rolesPermission[2])
  }, [])

  const dispatch = useDispatch()
  const location = useLocation()
  const data = location.state.rowData
  let rowData = data
  document.title = "Obligations"
  const editObligation = () => {
    props.history.replace({
      pathname: "/obligations/obligation_update",
      state: { rowData },
    })
  }

  const FieldName = checkOrg === 3?"disclosure":"custDisclosure"
  // const LightTooltip = withStyles(theme => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.common.white,
  //     color: "rgba(0, 0, 0, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }))(Tooltip)

  // const DarkTooltip = withStyles(theme => ({
  //   tooltip: {
  //     backgroundColor: theme.palette.common.black,
  //     color: "rgba(255, 255, 255, 0.87)",
  //     boxShadow: theme.shadows[1],
  //     fontSize: 11,
  //   },
  // }))(Tooltip)
  const onClickDelete = rowData => {
    setDelObligation(rowData)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    setDelete(!is_delete)
    if (delObligation.id) {
      dispatch(onDeleteObligation(delObligation.id, props.history))
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data?.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data?.customerorg.id)
    }
  }, [])

  const contentRef = React.useRef(null);
 
  const setInnerText = (content) => {
    // if (contentRef.current) {
      const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    // }
  }

  // const test = () => {
  //   console.log("data[ checkOrg === 3", data[ checkOrg === 3 ?"disclosure_obligations_obligation":"custLinkingCustDisclosureCustObligation"])
  // }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligation Details" path="/obligations/all-obligations" /> */}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Obligation Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
                <ul className="nav nav-pills" style={{ justifyContent: "end" }}>
                  {obligationAccess &&
                  obligationAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => editObligation(rowData)}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                        Edit
                      </Button>
                    </li>
                  ) : (
                    ""
                  )}
                  {obligationAccess &&
                  obligationAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => {
                          onClickDelete(rowData)
                        }}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          marginRight: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                        Delete
                      </Button>
                      <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteOrder}
                        onCloseClick={() => setDeleteModal(false)}
                      />
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <div className="row">
                      <div className="col-12 col">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className=""></h4>
                          <div
                            className="page-title-right"
                            style={{ marginTop: "7px" }}
                          >
                            <ol className="breadcrumb m-0">
                              <li className="breadcrumb-item">
                                <Link to={{pathname:"/obligations/all-obligations", state: { rowData: {isClear: true} }}}>
                                  Obligation
                                </Link>
                              </li>
                              <li
                                className="active breadcrumb-item"
                                aria-current="page"
                              >
                                Obligation Details
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Row>
                        <Col md={4} className="px-4">
                        <h5 className="mb-sm-0 font-size-18">
                        Obligation Details
                      </h5>
                        </Col> */}
                  {/* <Col md={8} className="d-flex flex-wrap gap-2 justify-content-end">
                            <button className="btn btn-primary">
                            <i className="bx bx-printer font-size-16 align-middle me-1"></i>
                                Print
                            </button>
                        </Col> */}
                  {/* </Row> */}
                  <div className="table-responsive mt-3 px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        {/* <tr>
                                <th style={{ width: "15%" }}>Disclosure Obl_ID</th>
                                <td style={{width:"75%"}}>{data && data?.dis_Obl_ID}</td>
                            </tr> */}
                        <tr>
                          <th style={{ width: "15%" }}>Obligation ID</th>
                          <td style={{ width: "75%" }}>{data && data?.obId}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Obligation Name</th>
                          <td style={{ width: "75%" }}>
                            {data && data?.obligationName}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Obligation Description
                          </th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data?.obg_desc} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CheckValidValue({
                                  value: data?.obligationDesc,
                                })
                                  ? data?.obligationDesc
                                  : "-",
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Citation URL</th>
                          <td style={{ width: "75%" }}>
                            <div style={{ width: "50%" }}>
                              {CheckValidValue({ value: data?.citationUrl })
                                ? data?.citationUrl
                                : "-"}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulator Short Name</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({ value: data?.issuingAuthority })
                              ? data?.issuingAuthority
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Law Short Name</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({ value: data?.regLaw })
                              ? data?.regLaw
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Citation</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({ value: data?.citation })
                              ? data?.citation
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Obligation Notes</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({ value: data?.obligationNote })
                              ? data?.obligationNote
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Status</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data?.status || "-"} */}
                            {data && data?.status == 1
                              ? "Pending"
                              : data && data?.status == 2
                              ? "Approved"
                              : "Removed"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Last Updated Date</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data?.regEffectiveDate} */}
                            {data && data?.updatedDate
                              ?
                              FormatedDate(data.updatedDate) 
                          
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Disclosure List</h4>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
            <ModalBody className="text-left">
              {/* <div style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>

            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive mt-3 px-0">
                    {
                      // data.disclosure_obligations_obligation && data.disclosure_obligations_obligation?.length || 0 ==0 ?

                      // "No Record":

                      <table className="table table-responsive mt-3 px-0">
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Disclosure Title</th>
                            <th scope="col">Disclosure Note</th>
                            {/* <th scope="col">Obligation Notes</th> */}
                            <th scope="col">Disclosure specific Citation</th>
                            <th scope="col">Disclosure specific URL</th>
                            <th scope="col">Status</th>
                            <th scope="col"> Life - Close-out End </th>
                            <th scope="col"> Life - Marketing </th>
                            <th scope="col"> Life - Open Onboard</th>
                            <th scope="col">Life - Servicing</th>
                            <th scope="col"> Product Group Auto</th>
                            <th scope="col"> Product Group Card</th>
                            <th scope="col">Product Group Deposit</th>
                            <th scope="col"> Product Group Investments </th>
                            <th scope="col"> Product Group Mortgage </th>
                            <th scope="col"> Product Group Other </th>

                            <th scope="col">Communication Types</th>
                            <th scope="col">Last Updated Date</th>
                            <th scope="col">Attachment</th>
                            {/* <th scope="col">createdDate</th>
                        <th scope="col">disId</th>
                        <th scope="col">disclaimer</th> */}
                            <th scope="col">Disclosure Description</th>
                            <th scope="col">Disclosure Type</th>
                            {/* <th scope="col">
createdDate
</th> */}
                            <th scope="col">Effective Until Date</th>
                            {/* <th scope="col">id</th> */}
                            <th scope="col">Safe Harbor</th>
                            <th scope="col">Sample</th>
                            <th scope="col">Sample URL-1</th>
                            <th scope="col">Sample URL-2</th>
                            <th scope="col">Sample URL-3</th>

                            <th scope="col">Disclosure Text</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          {data &&
                             data[ checkOrg === 3 ?"disclosure_obligations_obligation":"custLinkingCustDisclosureCustObligation"]  &&
                            data[ checkOrg === 3 ?"disclosure_obligations_obligation":"custLinkingCustDisclosureCustObligation"].map(item => (
                              <tr key={item.id}>
                                <th scope="row">
                                  {" "}
                                  {CheckValidValue({
                                    value: item[FieldName]?.disId,
                                  })
                                    ? item[FieldName]?.disId
                                    : "-"}
                                </th>
                                <td>
                                  {ColumnDataWithTooltip({
                                    content: item[FieldName]?.disclosureTitle,
                                  })}
                                </td>
                                <td>
                                  <div style={{ textAlign: "center" }}>
                                    {item[FieldName]?.disclosureNote ? ColumnDataWithTooltip({
                                      content: (
                                        <i
                                          onClick={() => {
                                            setModal(!modal)
                                            setModalTitle("Disclosure Note")
                                            setTimeout(() => {
                                              setInnerText(item[FieldName]?.disclosureNote)
                                            },500)
                                            // setDesc(
                                            //   item[FieldName]?.disclosureNote
                                            // )
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            textAlign: "center",
                                            padding: "0 5px",
                                            borderRadius: "20%",
                                            color: "#556ee6",
                                          }}
                                          className="bx bxs-info-circle  font-size-24"
                                          id="descToolTip"
                                        ></i>
                                      ),
                                      onlyMessage: "View details",
                                    }) : "-"}

                                    {/* <div
                                      onClick={() => {
                                        setModal(!modal)
                                        setDesc(item.obligationDesc)
                                      }}
                                    ></div> */}
                                  </div>
                                </td>
                                {/* <td >
                              <div   >
                             { ColumnDataWithTooltip({content:item[FieldName]?.obligationNote }) }

                              </div>
                              
                            </td> */}
                                <td>
                                  {ColumnDataWithTooltip({
                                    content:
                                      item?.disclosure
                                        ?.disclosureSpecificCitation,
                                  })}
                                </td>
                                <td>
                                  {/* {item.citationUrl.substr(0, 15) + "..."} */}
                                  {ColumnDataWithTooltip({
                                    content:
                                      item[FieldName]?.disclosureSpecificUrl,
                                  })}
                                  {/* <LightTooltip title={item.citationUrl}>

                              {  CheckValidValue({value:item?.citationUrl})? <div
                                  
                                  style={{
                                    textDecoration: "underline",
                                    fontSize: "13px",
                                    color: "blue",
                                  }}
                                >
                                  {item?.citationUrl
                                    .replaceAll("https://", "")
                                    .replaceAll("http://", "")
                                    .replaceAll("www.", "") }
                                </div> :  "-"  }
                               
                              </LightTooltip> */}
                                </td>

                                {[
                                  item[FieldName]?.status,
                                  item[FieldName]?.lifeCloseOutEnd,
                                  item[FieldName]?.lifeMarketing,
                                  item[FieldName]?.lifeOpenOnboard,
                                  item[FieldName]?.lifeServicing,
                                  item[FieldName]?.prodGroupAuto,
                                  item[FieldName]?.prodGroupCard,
                                  item[FieldName]?.prodGroupDeposit,
                                  item[FieldName]?.prodGroupInvestments,
                                  item[FieldName]?.prodGroupMortgage,
                                  item[FieldName]?.prodGroupOther,
                                ].map((itemData, index) => {
                                  return (
                                    <>
                                      <td key={index}>
                                        <Badge
                                          className={
                                            itemData
                                              ? "font-size-11 badge-soft-success"
                                              : //  "font-size-11 badge-soft-danger"
                                                "font-size-11 badge-soft-danger"
                                          }
                                        >
                                          {index == 0
                                            ? itemData
                                              ? "Approved"
                                              : "Pending"
                                            : itemData
                                            ? "Yes"
                                            : "No"}
                                        </Badge>
                                      </td>
                                    </>
                                  )
                                })}

                                {[
                                  {
                                    title: "communicationTypes",
                                    value: item[FieldName]?.communicationTypes,
                                  },

                                  {
                                    title: "updatedDate",
                                    value: item[FieldName]?.updatedDate
                                      ? 
                                      
                                      FormatedDate(item[FieldName]?.updatedDate) 
                                     
                                      : "-",
                                  },
                                  {
                                    title: "attachment",
                                    value:  <td style={{ width: "75%" }}>
                                    {item[FieldName] && item[FieldName]?.attachment ? (
                                      // <DarkTooltip title="Open Attachment">
                                        <Link
                                          to={{
                                            pathname: `${process.env.REACT_APP_ATTACHMENT_URL}/attachment/${item[FieldName].attachment}`,
                                          }}
                                          target="_blank"
                                        >
                                          {/* <Button
                                            // onClick={() => viewAttachMent(rowData)}
                                            style={{
                                              backgroundColor: "#556ee6",
                                              marginLeft: 5,
                                              color: "#fff",
                                              textTransform: "none",
                                            }}
                                          > */}
                                          {
                                            ["doc","docx"].includes(item[FieldName].attachment.split(".").pop()) ? <i className="bx bxs-file-doc font-size-24"></i> : <i className="bx bxs-file-pdf font-size-24"></i>
                                            }
        
                                          {/* </Button> */}
                                        </Link>  
                                      // </DarkTooltip>
                                    ) : (
                                      "-"
                                    )}
                                  </td>,
                                  },
                                  // {title:"createdDate",value: item[FieldName]?.createdDate},
                                  // {title:"disId",value: item[FieldName]?.disId},
                                  // {title:"disclaimer",value: item[FieldName]?.disclaimer},
                                  {
                                    title: "disclosureDesc",
                                    value: item[FieldName]?.disclosureDesc,
                                  },
                                  {
                                    title: "disclosureType",
                                    value:
                                      item[FieldName]?.disclosureType === 1
                                        ? "Text"
                                        : item[FieldName]?.disclosureType === 2
                                        ? "Doc"
                                        : "Other",
                                  },
                                  {
                                    title: "effectiveUntilDate",
                                    value: item[FieldName]?.effectiveUntilDate,
                                  },
                                  // {title:"id",value: item[FieldName]?.id},
                                  {
                                    title: "safeHarbor",
                                    value:
                                      item[FieldName]?.safeHarbor == 1
                                        ? "All"
                                        : item[FieldName]?.safeHarbor === 2
                                        ? "Part"
                                        : "None",
                                  },
                                  {
                                    title: "sample",
                                    value: item[FieldName]?.sample,
                                  },
                                  {
                                    title: "sampleUrl1",
                                    value: item[FieldName]?.sampleUrl1,
                                  },
                                  {
                                    title: "sampleUrl2",
                                    value: item[FieldName]?.sampleUrl2,
                                  },
                                  {
                                    title: "sampleUrl3",
                                    value: item[FieldName]?.sampleUrl3,
                                  },
                                  {
                                    title: "text",
                                    value: item[FieldName]?.text,
                                  },
                                ].map(itemData => {
                                  return (
                                    <>
                                      {(itemData.title == "disclosureDesc" || itemData.title == "text") ? <td>
                                      <div style={{ textAlign: "center" }}>
                                          {itemData.value ? ColumnDataWithTooltip({
                                            content: (
                                              <i
                                                onClick={() => {
                                                  setModal(!modal)
                                                  setModalTitle(itemData.title == "disclosureDesc" ? "Disclosure Description" : "Disclosure Text")
                                                  // setDesc(
                                                  //   itemData.value
                                                  // )
                                                  setTimeout(() => {
                                                    setInnerText(itemData.value)
                                                  },500)
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  textAlign: "center",
                                                  padding: "0 5px",
                                                  borderRadius: "20%",
                                                  color: "#556ee6",
                                                }}
                                                className="bx bxs-info-circle  font-size-24"
                                                id="descToolTip"
                                              ></i>
                                            ),
                                            onlyMessage: "View details",
                                          }) : "-"}

                                      {/* <div
                                        onClick={() => {
                                          setModal(!modal)
                                          setDesc(item.obligationDesc)
                                        }}
                                      ></div> */}
                                        </div>
                                      </td> : <td>
                                      {ColumnDataWithTooltip({
                                          content: itemData.value,
                                        })}
                                          
                                      </td>
                                      
                                      }
                                    </>
                                    
                                  )
                                })}
                              </tr>
                            ))}
                          {/* {data &&
                            checkOrg != 3 &&
                            data.custLinkingCustDisclosureCustObligation.map(
                              item => (
                                <tr key={item.id}>
                                  <th scope="row">
                                    {" "}
                                    {CheckValidValue({
                                      value: item?.custDisclosure?.disId,
                                    })
                                      ? item?.custDisclosure?.disId
                                      : "-"}
                                  </th>
                                  <td>
                                    {ColumnDataWithTooltip({
                                      content:
                                        item?.custDisclosure?.disclosureTitle,
                                    })}
                                  </td>
                                  <td>
                                    <div style={{ textAlign: "center" }}>
                                      {ColumnDataWithTooltip({
                                        content: (
                                          <i
                                            onClick={() => {
                                              setModal(!modal)
                                              setDesc(
                                                item?.custDisclosure
                                                  ?.disclosureNote
                                              )
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              textAlign: "center",
                                              padding: "0 5px",
                                              borderRadius: "20%",
                                              color: "#556ee6",
                                            }}
                                            className="bx bxs-info-circle  font-size-24"
                                            id="descToolTip"
                                          ></i>
                                        ),
                                        onlyMessage: "View details",
                                      })}

                                      <div
                                        onClick={() => {
                                          setModal(!modal)
                                          setDesc(item.obligationDesc)
                                        }}
                                      ></div>
                                    </div>
                                  </td>
                    
                                  <td>
                                    {ColumnDataWithTooltip({
                                      content:
                                        item?.custDisclosure
                                          ?.disclosureSpecificCitation,
                                    })}
                                  </td>
                                  <td>
                                 
                                    {ColumnDataWithTooltip({
                                      content:
                                        item?.custDisclosure
                                          ?.disclosureSpecificUrl,
                                    })}
                                    
                                  </td>

                                  {[
                                    item?.custDisclosure?.status,
                                    item?.custDisclosure?.lifeCloseOutEnd,
                                    item?.custDisclosure?.lifeMarketing,
                                    item?.custDisclosure?.lifeOpenOnboard,
                                    item?.custDisclosure?.lifeServicing,
                                    item?.custDisclosure?.prodGroupAuto,
                                    item?.custDisclosure?.prodGroupCard,
                                    item?.custDisclosure?.prodGroupDeposit,
                                    item?.custDisclosure?.prodGroupInvestments,
                                    item?.custDisclosure?.prodGroupMortgage,
                                    item?.custDisclosure?.prodGroupOther,
                                  ].map((itemData, index) => {
                                    return (
                                      <>
                                        <td key={index}>
                                          <Badge
                                            className={
                                              itemData
                                                ? "font-size-11 badge-soft-success"
                                                : //  "font-size-11 badge-soft-danger"
                                                  "font-size-11 badge-soft-danger"
                                            }
                                          >
                                            {index == 0
                                              ? itemData
                                                ? "Approved"
                                                : "Pending"
                                              : itemData
                                              ? "Yes"
                                              : "No"}
                                          </Badge>
                                        </td>
                                      </>
                                    )
                                  })}

                                  {[
                                    {
                                      title: "communicationTypes",
                                      value:
                                        item?.custDisclosure
                                          ?.communicationTypes,
                                    },

                                    {
                                      title: "updatedDate",
                                      value: item?.custDisclosure?.updatedDate
                                        ? moment(
                                            new Date(
                                              item &&
                                                item?.custDisclosure
                                                  ?.updatedDate
                                            )
                                          ).format("MMM DD Y")
                                        : "-",
                                    },
                                    {
                                      title: "attachment",
                                      value: item?.custDisclosure?.attachment,
                                    },
                                    // {title:"createdDate",value: item[FieldName]?.createdDate},
                                    // {title:"disId",value: item[FieldName]?.disId},
                                    // {title:"disclaimer",value: item[FieldName]?.disclaimer},
                                    {
                                      title: "disclosureDesc",
                                      value:
                                        item?.custDisclosure?.disclosureDesc,
                                    },
                                    {
                                      title: "disclosureType",
                                      value:
                                        item?.custDisclosure?.disclosureType ===
                                        1
                                          ? "Text"
                                          : item?.custDisclosure
                                              ?.disclosureType === 2
                                          ? "Doc"
                                          : "Other",
                                    },
                                    {
                                      title: "effectiveUntilDate",
                                      value:
                                        item?.custDisclosure
                                          ?.effectiveUntilDate,
                                    },
                                    // {title:"id",value: item[FieldName]?.id},
                                    {
                                      title: "safeHarbor",
                                      value:
                                        item?.custDisclosure?.safeHarbor == 1
                                          ? "All"
                                          : item?.custDisclosure?.safeHarbor ===
                                            2
                                          ? "Part"
                                          : "None",
                                    },
                                    {
                                      title: "sample",
                                      value: item?.custDisclosure?.sample,
                                    },
                                    {
                                      title: "sampleUrl1",
                                      value: item?.custDisclosure?.sampleUrl1,
                                    },
                                    {
                                      title: "sampleUrl2",
                                      value: item?.custDisclosure?.sampleUrl2,
                                    },
                                    {
                                      title: "sampleUrl3",
                                      value: item?.custDisclosure?.sampleUrl3,
                                    },
                                    {
                                      title: "text",
                                      value: item?.custDisclosure?.text,
                                    },
                                  ].map(itemData => {
                                    return (
                                      <>
                                        <td>
                                          {ColumnDataWithTooltip({
                                            content: itemData.value,
                                          })}
                                        </td>
                                      </>
                                    )
                                  })}
                                </tr>
                              )
                            )} */}
                        </tbody>
                      </table>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ObligationView
