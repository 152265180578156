import { takeEvery, put, call, all, fork } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_LINKS,
  ADD_NEW_LINK,
  UPDATE_LINK,
  DELETE_LINK,
} from "./actionTypes"

import {
  getLinksSuccess,
  getLinksFail,
  addLinkSuccess,
  addLinkFail,
  updateLinkSuccess,
  updateLinkFail,
  deleteLinkSuccess,
  deleteLinkFail,
  updateShowModal,
} from "./actions"

import {
  getLinks,
  addNewLink,
  updateLink,
  deleteLink,
} from "../../helpers/fakebackend_helper"
import { ToastError } from "helpers/helper_function"

function* fetchLinks({ urlData }) {
  // if(urlData){
  const limit = urlData.limit
  const page = urlData.currentPage
  // console.log(limit, page,"page in saga")
  // }
  try {
    const response = yield call(getLinks, limit, page)
    yield put(getLinksSuccess(response))
    yield put(updateShowModal(false))
  } catch (error) {
    console.log(error, "error")
    yield put(getLinksFail(error))
  }
}

function* onAddNewLink({ payload: { link, history } }) {
  // console.log(setDelete, 'saga check')
  try {
    const response = yield call(addNewLink, link)
    yield put(addLinkSuccess(response))
    toastr.success("Link Successfully Added.")
    history.push("/link-table")
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(addLinkFail(error))
  }
}

function* onUpdateLink({ payload: { link, history } }) {
  try {
    const response = yield call(updateLink, link)
    yield put(updateLinkSuccess(response))
    toastr.success("Link Successfully Updated.")
    history.push("/link-table")
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(updateLinkFail(error))
  }
}

function* onDeleteLink({ payload: { link, history } }) {
  try {
    const response = yield call(deleteLink, link)
    console.log("response", response)
    yield put(deleteLinkSuccess(response))
    toastr.success("Link Successfully Deleted.")
    history.push("/link-table")
  } catch (error) {
    console.log("error", error)
     ToastError(error)
    yield put(deleteLinkFail(error))
  }
}

export function* watchFetchLinks() {
  yield takeEvery(GET_LINKS, fetchLinks)
}

function* linksSaga() {
  yield all([fork(watchFetchLinks)])
  yield takeEvery(ADD_NEW_LINK, onAddNewLink)
  yield takeEvery(UPDATE_LINK, onUpdateLink)
  yield takeEvery(DELETE_LINK, onDeleteLink)
}

export default linksSaga
