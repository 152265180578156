import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import toastr from "toastr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { TableHeader, Search } from "../../Datatable/index"
import axios from "axios"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, encryptData } from "helpers/helper_function"

const RolesUpdate = props => {
  const { setShowRolesTable, editRoleData } = props
  const [btnDisable, setBtnDisable] = useState(false)
  const [roleOption, setRoleOption] = useState([])
  const [orgOption, setOrgOption] = useState([])
  const [permissions, setPermissions] = useState([])
  const [editData, setEditData] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const dispatch = useDispatch()
  console.log(editRoleData, "editRoleData")

  useEffect(() => {
    if (editRoleData) {
      setEditData(editRoleData)
    }
  }, [])
  const permisionsList =
    editData.rolesPermission !== undefined ? editData.rolesPermission : []

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data ={
      tableName: "permission",
      fieldName: ["permission.id", "permission.moduleName"],
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-all`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data)
        if (response.status >= 200 || response.status <= 299) {
          setPermissions(response.data.data)
        }
      })
      .catch(err => {
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    let finalData= {
      orderBy: {
        tableName: "roles",
        fieldName: "id",
        order: "DESC",
      },
    } //Object.keys(filterArray).length ? filterArray : searchObject
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-roles/?limit=${500}&pageNo=${1}`,
        {data:encryptData(finalData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          //   setReqresData(response.data.data)
          //   setTotalItems(response.data.totalRecords)
          console.log(response, "response")
          setLoader(false)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  const addRole = addData => {
    console.log(addRole, "addRole")
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}add-role`, {data:encryptData(addData)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response, "response")
          toastr.success("Role Successfully Added")
          setShowRolesTable("table")
        }
      })
      .catch(err => {
        let message
        console.log(err, "err")
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        console.log(message, "message")
        RedirectOnUnAuthentication(err,navigateTo,message)
        // throw message;
      })
  }
  const updateRole = updateData => {
    console.log(updateRole, "updateRole")
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}update-role`, {data:encryptData(updateData)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response, "response")
          toastr.success("Role Successfully Updated")
          setShowRolesTable("table")
        }
      })
      .catch(err => {
        let message
        console.log(err, "err")
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        console.log(message, "message")
        RedirectOnUnAuthentication(err,navigateTo,message)
        // throw message;
      })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (editRoleData && editRoleData.name) || "",

      //   idx : idx || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Role Name"),
    }),
    onSubmit: values => {
      if (!editRoleData) {
        var permisionsList = []
        permissions.map((value, index) => {
          permisionsList.push({
            permissionId: document.getElementById("permissionId_" + value.id)
              .value,
            access: document.getElementById("access_" + value.id).checked,
            modify: document.getElementById("modify_" + value.id).checked,
          })
        })
        const addData = {
          name: values.name,
          permissions: permisionsList,
        }
        console.log(addData, "addData")
        addRole(addData)
      } else {
        console.log(values, "values")
        var permisionsList = []
        permissions.map((value, index) => {
          permisionsList.push({
            permissionId: document.getElementById("permissionId_" + value.id)
              .value,
            access: document.getElementById("access_" + value.id).checked,
            modify: document.getElementById("modify_" + value.id).checked,
          })
        })
        const updateData = {
          name: values.name,
          permissions: permisionsList,
          id: editData.id,
        }
        console.log(updateData, "updateData")
        updateRole(updateData)
      }
    },
  })
  const PermissionHeader = [
    { name: "Give Permissions", field: "id", sortable: false },
    { name: "View", field: "access", sortable: false },
    { name: "Modify", field: "modify", sortable: false },
  ]
  const addTitle = "Add Role"
  const updateTitle = "Update Role"

  var access = false
  var modify = false
  return (
    <>
      {/* <div className="page-content"> */}
      <Container fluid={true}>
        <Breadcrumbs
          title="Roles"
          path="#"
          // breadcrumbItem={updateTitle}
          breadcrumbItem={editRoleData ? updateTitle : addTitle}
          setShowRolesTable={setShowRolesTable}
        />
        <Card>
          {/* <CardBody> */}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Role Name</Label>
                    <Input
                      name="name"
                      // value={data && data.short_name}
                      className="form-control"
                      placeholder="Enter Role Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  <table className="table table-striped">
                    <TableHeader headers={PermissionHeader} />
                    {/* {console.log(editData, "editData")}
                    {console.log(permisionsList, "permisionsList")} */}
                    {permissions.map((value, index) => {
                      access = false
                      modify = false
                      permisionsList.map(val => {
                        // console.log(value.id,'===',val.permission.id);
                        if (value.id === val.permission.id) {
                          access = val.readAccess
                          modify = val.writeAccess
                        }
                      })
                      // permisionsList
                      // {editData && editData.map((editValue) => (
                      return (
                        <tr key={index++}>
                          <td>
                            <div>
                              {value.moduleName}
                              {/* {console.log(editRoleData, "editRoleData")} */}
                              <input
                                type={"hidden"}
                                id={"permissionId_" + value.id}
                                value={value.id}
                                name={"permissionId_" + value.id}
                              />
                            </div>
                          </td>
                          <td style={{ paddingLeft: "30px" }}>
                            <input
                              id={"access_" + value.id}
                              type="checkbox"
                              defaultChecked={access}
                              value={access}
                            />
                          </td>
                          <td style={{ paddingLeft: "30px" }}>
                            <input
                              id={"modify_" + value.id}
                              type="checkbox"
                              defaultChecked={modify}
                              value={modify}
                            />
                          </td>
                        </tr>
                      )
                      // ))}
                    })}
                  </table>
                </Col>
              </Row>
            </div>
            <Col xs="12" md="12">
              <div className="text-end">
                <Button
                  disabled={btnDisable}
                  type="submit"
                  className="me-2"
                  color="primary"
                >
                  {editRoleData ? "Update Role" : "Add Role"}
                </Button>
                <Link>
                  <Button
                    onClick={() => setShowRolesTable("table")}
                    className="btn btn-secondary waves-effect waves-light "
                    color="secondnary"
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Form>
          {/* </CardBody> */}
        </Card>
      </Container>
      {/* </div> */}
    </>
  )
}

export default RolesUpdate
