import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetAiData } from "services/regChangeService";
export const SLICE_NAME = "regChangeSlice";


// export const fetchAiData = createAsyncThunk(
//   `${SLICE_NAME}/AiData`,
//   async ({ reportId, obId }) => {
//     try {
//       const response = await apiGetAiData({ reportId, obId });
//       return response?.data;
//     } catch (error) {
//       console.log(error, "From Function");
//       throw error;
//     }
//   }
// );

const initialState = {
  loading : false,
  aiModalData : [],
  dashboardData : [],
  openAiModal : false,
  openLinkDisclosure : false,
  reportsId : null
};

const RegChangeListSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAiModal:(state, action) => {
        state.openAiModal = action.payload
    },
    setAiModalData : (state, action) => {
          state.aiModalData = action.payload
    },
    setDashboardData: (state, action) => { 
        state.dashboardData = action.payload
    },
    setOpenLinkDisclosure : (state, action) => {
        state.openLinkDisclosure = action.payload
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchAiData.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(fetchAiData.fulfilled, (state , action) => {
    //   state.loading = false ;
    //   state.testingList = action.payload;
    // });
    // builder.addCase(fetchAiData.rejected, (state) => {
    //   state.loading = false;
    // });
  }
});

export const { setLoading, setAiModal, setAiModalData , setDashboardData , setOpenLinkDisclosure} = RegChangeListSlice.actions;

export default RegChangeListSlice.reducer;
