export default function authHeader() {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  let api_key = {}
  if (obj && obj.data.authToken) {
    api_key = `Bearer ${obj.data.authToken}`
    // api_key = `Bearer authToken`
    return api_key
    // } else {
    //   return {}
  }
}
