import React, {useEffect, useRef, useState} from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';

 

function removeInvertedCommas(strLine) {
    const str = strLine
    if (str.startsWith('"<') && str.endsWith('>"')) {
  str = str.substring(1, str.length - 1);
}
    return str; // Output: <p>aasfas<p/>
  }


const TableToExel = ({downloadTableData,onHandleClick}) =>  {
    const [newData,setNewData] = useState(downloadTableData || [])
    useEffect(()=>{
        setNewData(downloadTableData)

    },[downloadTableData])
    const tableRef = useRef(null);
const test = () =>{
    console.log("downloadTableData25456",newData)
}
        return (
            <div  onClick={test} >
                <DownloadTableExcel
                    filename="Disclosure Text"
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <button id='downLoadXlxs'  >   </button>

                </DownloadTableExcel>

                <button className='btn btn-primary'    onClick={onHandleClick}>
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                     Download Disclosure Text 
                     
                   
                     </button>
                    
                <table className='d-none'  ref={tableRef}>
                
                   
                    <tr>
                        <th>Disclosure Id</th>
                        <th>Text</th> 
                    </tr>
                    <tbody>
                        {newData.map((i, index) => {
                            return (<tr key={index}> 
                                <td>{i.disId}</td>
                                {/* <td><div>{removeInvertedCommas(i.text)}</div> </td>  */}
                                {/* <td><div>{i.text}</div> </td>  */}
                                <td><div dangerouslySetInnerHTML={{ __html: i.text }}></div> </td> 
                            </tr>)
                        })}
                        {}
                    {/* {newData.map((i,index)=>{
                        <tr key={index}> 
                         <td>{i.disId}</td>
                         <td>{i.text}</td> 
                      </tr>
                    })} */}
                     {/* <tr> 
                         <td style={{color:"red"}}>{'i.disId'}</td>
                         <td> <p>AUTHORIZATION TO <strong>CONVERT </strong><i>YOUR </i>CHECK TO AN ELECTRONIC TRANSFER DEBIT: When you provide a check as payment, you authorize us either to use information from your check to make a one-time electronic fund transfer from your account or to process the payment as a check. Your bank account may be debited as soon as the same day we receive your payment. You will not receive our check back from your institution.</p></td> 
                      </tr> */}
                    
                  </tbody>
                </table>

            </div>
        );
    }
 

export default TableToExel