import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Table,
  Row,
  TabContent,
  TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  Form,
  FormGroup,
  CardBody,
  CardTitle,
  NavLink,
} from "reactstrap"
import Select from "react-select"
import toastr from "toastr"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, ToastError } from "helpers/helper_function"

const Language = props => {
  const [modal, setModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [selectedFile, setSelectedFile] = useState("")
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const toggle = () => {
    setModal(!modal)
    setErrors({})
    setSelectedFile(!selectedFile)
  }

  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }

  const handleImportData = e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-disclosure-text`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}` },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          toggle()
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  return (
    <React.Fragment>
      <Card className="shadow-none border mb-0">
        <CardBody>
          <Row>
            <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
              <div className="mb-3">
                <h5 className="font-size-18 mb-0">
                  Import Disclosure Text File
                </h5>
              </div>
            </Col>
            <Col md={12} style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => setModal(!modal)}
                >
                  <i className="bx bx-upload font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </button>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>
              Import Disclosure Text
            </ModalHeader>
            <ModalBody className="px-4 py-4 text-left">
              <h6>Select File</h6>
              <div className="form-body mt-3">
                <input
                  type="file"
                  id="csvFileInput"
                  accept=".xlsx"
                  name="file"
                  onChange={onFileChange}
                  className="form-control mb-2"
                />
                <div className="error">{errors.file}</div>
              </div>
              <div className="px-6 mt-3 mb-1 text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  name="button"
                  onClick={e => handleImportData(e)}
                >
                  Import Data
                </button>
              </div>
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Language
