import {
  GET_REGULATIONS_SUCCESS,
  GET_REGULATIONS_FAIL,
  ADD_REGULATION_SUCCESS,
  ADD_REGULATION_FAIL,
  UPDATE_REGULATION_SUCCESS,
  UPDATE_REGULATION_FAIL,
  DELETE_REGULATION_SUCCESS,
  DELETE_REGULATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  regulationsData: [],
  loading: true,
  error: false,
  errorValue: "",
  success: "",
}

const Regulations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGULATIONS_SUCCESS:
      return {
        ...state,
        regulationsData: action.payload,
        loading: false,
      }

    case GET_REGULATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorValue: action.payload,
      }

    case ADD_REGULATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case ADD_REGULATION_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case UPDATE_REGULATION_SUCCESS:
      // console.log(action.payload.message, 'see')
      return {
        ...state,
        loading: false,
      }
    case UPDATE_REGULATION_FAIL:
      // console.log(action, 'error')
      return {
        // state ={
        ...state,
        loading: false,
        error: true,
        // }
      }
    case DELETE_REGULATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_REGULATION_FAIL:
      return {
        ...state,
        error: true,
        errorValue: action.payload,
      }
    // break
    default:
      return state
  }
}

export default Regulations
