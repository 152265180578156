import React, { useEffect, useState } from "react"

const TableHeader = ({
  heading,
  onChangeInSelect,
  customePageSize,
  isLinkTable,
}) => {
  const currentPageRecord = localStorage.getItem("currentPageRecord")?localStorage.getItem("currentPageRecord"):50 
  const [pageRecord,setPageRecord] = useState(currentPageRecord??50)
  const handleChange = (e) =>{
    const records = e.target.value
    setPageRecord(records)
    localStorage.setItem("currentPageRecord",records)
    
  }

  useEffect(()=>{
    onChangeInSelect({target:{value:pageRecord}})

  },[pageRecord])
  useEffect(()=>{
    setPageRecord(currentPageRecord)
    
  },[])
  return (
    <div className="d-flex flex-wrap align-items-center   justify-content-start">
      <h5 className="font-size-18 mb-0">{heading ? heading : ""}</h5>
      <select
        className="form-select"
        value={pageRecord??customePageSize}
        onChange={handleChange}
        style={{ width: isLinkTable ? "160px" : "160px", marginLeft: "10px" }}
      >
        {/* {[10, 20, 30, 40, 50].map(pageSize => ( */}
        {[25, 50, 100, 150].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            No of Results {pageSize}
          </option>
        ))}
      </select>
    </div>
  )
}

export default TableHeader
