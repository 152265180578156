// sagas.js
import { takeLatest, call, put, select, all, fork } from "redux-saga/effects"
import { FETCH_ORG_OPTIONS } from "./actionTypes"
import { setOrgOptions } from "./actions"

import { fetchOrgOptions } from "../../helpers/fakebackend_helper"

function* fetchOrgOptionsSaga(states) {
  console.log(states.payload, "states payload")
  try {
    const { userId, checkAdmin, menu } = states.payload
    // console.log( userId, checkAdmin, menu, " userId, checkAdmin, menu")
    // Check if options are already cached
    const cachedOptions = localStorage.getItem("orgOptions")
    if (cachedOptions) {
      yield put(setOrgOptions(JSON.parse(cachedOptions)))
    } else {
      //   Make the API call
      const response = yield call(fetchOrgOptions, userId, checkAdmin, menu)
      console.log(response.data, "response")
      if (response) {
        const options = response.data.map(option => ({
          value: option.id,
          label: option.companyName,
        }))
        localStorage.setItem("orgOptions", JSON.stringify(options))
        yield put(setOrgOptions(options))
      }

      // Store the options in the cache and update Redux state
    }
  } catch (error) {
    // Handle error
    console.log(error, "error")
  }
}

export function* watchOrgOptions() {
  yield takeLatest(FETCH_ORG_OPTIONS, fetchOrgOptionsSaga)
}

function* obsOptionsSaga() {
  yield all([fork(watchOrgOptions)])
}

export default obsOptionsSaga
