import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { map } from "lodash"
import { useDispatch } from "react-redux"
import toastr from "toastr"
import debounce from "lodash/debounce"
import axios from "axios"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Flatpickr from "react-flatpickr"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import styled from "styled-components"
import { getOrders as onGetOrders } from "store/actions"
import { Popper } from "@material-ui/core"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Pagination from "../../components/Common/Pagination"
import DeleteModal from "../../components/Common/DeleteModal"
import { deleteObligation as onDeleteObligation } from "../../store/actions"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { format } from "date-fns"
import { Select, MenuItem, FormControl } from "@material-ui/core"

// import TableContainer from "../../../components/Common/TableContainer"
import { TableHeader, Search } from "../Datatable/index"
import FilterModal from "components/FilterModal/FilterModal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, ToastError, encryptData } from "helpers/helper_function"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const csvHeader = [
  "ID",
  "Obligation Name",
  "Obligation Description",
  "Citation URL",
  "Regulator Short Name",
  "Reg Law Short Name",
  "Citation",
  "Obligation Notes",
  "Status",
  "Created At",
  "Last Updated At",
  "Regulations",
  "Regulators",
]

const exportToExcel = rowData => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  const customizedData = rowData.map(row => {
    // console.log(row, 'row')
    const updatedDate = FormatedDate(row.updatedDate) 
    const createdDate = FormatedDate(row.createdDate) 

    const EffectiveDate = FormatedDate(row.regEffectiveDate) 
    return {
      ...row,
      obligationDesc: row.obligationDesc.replace(/<[^>]+>/g, ""),
      status:
        row.status == 1 ? "Pending" : row.status == 2 ? "Approved" : "Removed",
      createdDate: createdDate,
      updatedDate: row.updatedDate ? updatedDate : "-",
      regulation: row.regulation ? row.regulation.regShortName : "NA",
      regulator: row.regulator ? row.regulator.regulatorShortName : "NA",
      obligationNote: row.obligationNote ? row.obligationNote : "-",
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')
  const filteredData = customizedData.map(
    ({ incrementId, id, regulator, regulation, ...rest }) => rest
  )
  const arrangedColumns = [
    "obId",
    "obligationName",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "regLaw",
    "citation",
    "obligationNote",
    "status",
    "createdDate",
    "updatedDate",
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "OB_ID",
        "Obligation Name",
        "Obligation Description",
        "Citation URL",
        "Regulator Short Name",
        "Reg Law Short Name",
        "Citation",
        "Obligation Notes",
        "Status",
        "Created At",
        "Last Updated At",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Obligations.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :first-child {
        padding-left: 15px;
      }
      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      padding-top: 10px;
      // height: 80px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  setShowTable,
  pageCount: customePageCount,
  filterArray,
  setFilterArray,
  checkOrg,
  checkAdmin,
  columns,
  user,
  data,
  fetchData,
  searchObject,
  props,
  handleRender,
  setSearchObject,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useResizeColumns,
    // useFlexLayout,
    useSticky,
    useRowSelect
    //   hooks => {
    //     hooks.visibleColumns.push(columns => [
    //       // Let's make a column for selection
    //       {
    //         id: "selection",
    //         sticky: "left",
    //         width: 37,
    //         // The header can use the table's getToggleAllRowsSelectedProps method
    //         // to render a checkbox
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <div>
    //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //           </div>
    //         ),
    //         // The cell can use the individual row's getToggleRowSelectedProps method
    //         // to the render a checkbox
    //         Cell: ({ row }) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         ),
    //       },
    //       ...columns,
    //     ])
    //   }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      obligationIds: ids,
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/export-obligations`, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        // console.log(data.data.data, 'exporData');
        exportToExcel(response.data.data)
        setBtnLoader(false)
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }

  const handleDownloadFormat = () => {
    const csvData =
      "Obligation Name,Obligation Description,Citation URL,Citation,Obligation Notes,Regulator Short Name,Reg Law Short Name,Status"
    const filename = "Obligations_format.csv"
    const csvBlob = new Blob([csvData], { type: "text/csv" })
    const csvURL = window.URL.createObjectURL(csvBlob)

    const tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", filename)
    tempLink.click()

    // Cleanup
    window.URL.revokeObjectURL(csvURL)
    tempLink.remove()
  }

  const handleSearchQueryChange = (columnKey, value) => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    setSearchQuery(prev => ({ ...prev, [columnKey]: value }))

    const data ={
      tableName: "Obligation",
      feildName: columnKey,
      searchValue: value,
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (columnKey != "updatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }
        setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => {
    let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      updatedValue = formattedDate
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)

    console.log("API call triggered:", value)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  // console.log(selectedDate, 'selecteddate')

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      fetchData(searchQuery)
    }
  }

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setSearchObject({})
    setSelectedHeader([])
    setFilterArray({})
    setSelectedDate(null)
    // fetchData({})
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "users",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        console.log(err.response.data.message, "mesg")
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
              ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }
  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col md={3} className="py-3 justify-content-end">
          <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">All Users List</h5>
          </div>
        </Col>
        {/* </div>
          </Col> */}
        {isJobListGlobalFilter && <JobListGlobalFilter />}
        {isAddOptions && (
          <Col md={9} className="py-3 justify-content-end">
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              {selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  <Button
                    onClick={e => {
                      onAllDeleteRecord(e)
                    }}
                    style={{
                      backgroundColor: "#556EE6",
                      marginLeft: 5,
                      marginRight: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                    Delete
                  </Button>
                </>
              ) : selectedFlatRows.length >= 1 ? (
                <Button
                  onClick={e => {
                    onAllDeleteRecord(e)
                  }}
                  style={{
                    backgroundColor: "#556EE6",
                    marginLeft: 5,
                    marginRight: 5,
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                  Delete
                </Button>
              ) : (
                ""
              )}
              <DeleteModal
                show={deleteModal}
                onDeleteClick={e => onAllDelete(e)}
                onCloseClick={() => setDeleteModal(false)}
              />
              {
                // checkOrg === 3 &&
                // checkAdmin === 1 &&

                user === 19 || user === 1 ? (
                  <Link
                    className="btn btn-primary"
                    //   to="/obligations/obligation_update"
                    onClick={() => setShowTable("addTable")}
                  >
                    <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                    Add New User
                  </Link>
                ) : (
                  ""
                )
              }
            </div>
          </Col>
        )}
      </Row>
      {loader ? (
        <div
          className="container-fluid mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <Styles>
          <div style={{ minHeight: "25rem" }}>
            <div
              className="sticky table"
              {...getTableProps()}
              style={{ height: 500 }}
            >
              <div className="table-light header" style={{ fontWeight: 600 }}>
                {headerGroups.map(headerGroup => (
                  <div
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map(column => (
                      <div
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="th"
                      >
                        <div
                          className="mb-2 mt-0"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {rows.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <div {...row.getRowProps()} className="tr">
                          {row.cells.map(cell => {
                            return (
                              <div
                                key={cell.id}
                                {...cell.getCellProps({
                                  style: {
                                    backgroundColor: "#fff",
                                  },
                                })}
                                className="td"
                              >
                                {cell.render("Cell")}
                              </div>
                            )
                          })}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <Row className="mt-5">
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "150px 0",
                      fontSize: "25px",
                    }}
                  >
                    No Records Found
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <FilterModal
            setSelectedHeader={setSelectedHeader}
            setFilterArray={setFilterArray}
            isShowModal={showModal}
            filterData={modalData}
            setShowModal={setShowModal}
            tableName={"Obligation"}
            emptyTable={data?.length == 0??false}

          />
        </Styles>
      )}
      {/* </div> */}
    </Fragment>
  )
}

const UsersTable = props => {
  const { setShowTable, setEditData, setUsersViewData, checkOrg, checkAdmin } =
    props
  const [fakeData, setFakeData] = useState([])

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")

  const toggle = () => setModal(!modal)

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delObligation, setDelObligation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [orgName, setOrgName] = useState("")
  const [filterArray, setFilterArray] = useState({})
  const [orgNum, setOrgNum] = useState()
  const [user, setUser] = useState()
  const dispatch = useDispatch()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const limit = 20

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const customePageCount = Math.ceil(totalItems / limit)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelObligation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')
    // setDelete(!is_delete)
    if (delObligation.id) {
      // dispatch(onDeleteObligation(delObligation.id, props.history))
      deleteObligation(delObligation.id)
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setOrgNum(initialOrgID && initialOrgID.orgId)
      setUser(authToken.data.role.id)
    } else if (authToken) {
      setOrgNum(authToken.data.customerorg.id)
      setUser(authToken.data.role.id)
    }
  }, [])

  // console.log(checkOrg, "checkOrg id")

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log({ searchQuery })
    // let finalData= Object.keys(filterArray).length ? filterArray : searchObject
    let finalData= {
      orderBy: {
        tableName: "customerOrganization",
        fieldName: "id",
        order: "DESC",
      },
      // customerOrganization :  orgNum === 5 ? "Client PVT LTD" : orgNum === 3 ? "" : "",
      customerOrganization: orgNum !== 3 ? orgNum : "",
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-users/?limit=${limit}&pageNo=${currentPage}`,
        {data:encryptData(finalData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          setTotalItems(response.data.totalRecords)
          setLoader(false)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteObligation = user => {
    console.log(user, "for delete")
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    // const id = user.toString()
    // console.log(id, "delete ID")
    setLoader(true)
    const data = {
      tableName: "users",
      ids: user.toString(),
    }
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("User Successfully Deleted.")
          setReqresData(prevData => prevData.filter(entry => entry.id !== user))
          setLoader(false)
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        let message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        ToastError(err)
      })
  }

  // console.log(reqresData, "reqresData")
  useEffect(() => {
    console.log("page is loading")
    if (
      user !== undefined &&
      orgNum !== undefined &&
      checkAdmin !== undefined
    ) {
      fetchData(searchObject)
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    orgNum,
    checkAdmin,
    user,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray])

  const reg_data = [
    {
      id: 1,
      dis_Obl_ID: "OB_011930",
      obg_name:
        "FINRA Rule 3160 - Standards for Member Conduct - Customer Disclosure and Communications with the Public",
      obg_desc:
        "As a party to a networking arrangement and FINRA member broker dealer, the bank must disclose to each customer that the broker-dealer services are being provided by the member and not by the financial institution, and that the securities products purchased or sold in a transaction are: (i) not insured by the Federal Deposit Insurance Corporation (ii) not deposits or other obligations of the financial institution and are not guaranteed by the financial institution; and (iii) subject to investment risks, including possible loss of the principal invested.",
      citation_URL:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/2262",
      issuing_Authority: "FINRA",
      regLaw: "FINRA 3230",
      Citation: "FINRA 3230(b)  ",
      obg_Notes: "US",
    },
    {
      id: 2,
      dis_Obl_ID: "OB_012040",
      obg_name:
        "FINRA Rule 3230 - Telemarketing - National Do-Not-Call List Exceptions",
      obg_desc:
        "The bank must disclose to the customer of a security in writing if it is controlled by, controlling or under a FINRA member.",
      citation_URL:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/3160",
      issuing_Authority: "FINRA",
      regLaw: "FINRA 2262   ",
      Citation: "FINRA 3160",
      obg_Notes: "US",
    },
    {
      id: 3,
      dis_Obl_ID: "OB_012060",
      obg_name: "FINRA Rule 3230 - Telemarketing - Procedure Requirement",
      obg_desc:
        "As a FINRA member broker dealer, the bank must deliver to each customer in the form prescribed by the Rule.",
      citation_URL:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/2360",
      issuing_Authority: "FINRA",
      regLaw: "FINRA 2360",
      Citation: "FINRA 2360",
      obg_Notes: "-",
    },
    {
      id: 4,
      dis_Obl_ID: "OB_011090",
      obg_name:
        "FINRA Rule 2214 - Requirements for the Use of Investment Analysis Tools - Disclosure Requirements    ",
      obg_desc:
        "As a FINRA member broker dealer, the bank must provide in writing, in a clear and prominent manner and must be in written (which may be electronic) narrative form.",
      citation_URL:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/2214",
      issuing_Authority: "FINRA",
      regLaw: "FINRA 2214",
      Citation: "FINRA 2214",
      obg_Notes: "-",
    },
  ]

  const columns = [
    {
      Header: "First Name",
      width: 120,
      accessor: "firstName",
      filterable: false,
      disableFilters: true,
      //   sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          // <Link
          //   to={{
          //     pathname: "/obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}>{cellProps.value}</span>
          // </Link>
        )
      },
    },
    {
      Header: "Last Name",
      width: 120,
      accessor: "lastName",
      filterable: false,
      disableFilters: true,
      //   sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          // <Link
          //   to={{
          //     pathname: "/obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}>{cellProps.value}</span>
          // </Link>
        )
      },
    },
    {
      Header: "User URL",
      accessor: "website",
      filterable: false,
      disableFilters: true,
      // width: 200,
      Cell: cellProps => {
        return (
          // <Link
          //   to={{
          //     pathname: "/obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}   className="StringOverFlow1">
            {cellProps.value && cellProps?.value?.length > 15
              ? cellProps?.value 
              : cellProps.value || "-"}
          </span>
          // </Link>
        )
      },
    },
    {
      Header: "Role",
      width: 120,
      filterable: false,
      disableFilters: true,
      accessor: "role.name",
      Cell: cellProps => {
        return (
          // <Link
          //   to={{
          //     pathname: "/obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}   className="StringOverFlow1">
            {cellProps.value && cellProps?.value?.length > 15
              ? cellProps?.value 
              : cellProps.value || "-"}
          </span>
          // </Link>
        )
      },
    },
    {
      Header: "Organization",
      width: 120,
      filterable: false,
      disableFilters: true,
      accessor: "customerorg.companyName",
      Cell: cellProps => {
        return (
          // <Link
          //   to={{
          //     pathname: "/obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}   className="StringOverFlow1">
            {cellProps.value && cellProps?.value?.length > 15
              ? cellProps?.value 
              : cellProps.value || "-"}
          </span>
          // </Link>
        )
      },
    },
    {
      Header: "Email",
      width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "email",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          // <Link
          //   to={{
          //     pathname: "/obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}   className="StringOverFlow1">
            {cellProps.value && cellProps?.value?.length > 15
              ? cellProps?.value 
              : cellProps.value || "-"}
          </span>
          // </Link>
        )
      },
    },
    {
      Header: "Contact Details",
      accessor: "phone",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value || "-"
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      disableFilters: true,
      sticky: "right",
      filterable: false,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        // console.log(rowData, "row data")
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "0px" }}
          >
            {/* <LightTooltip  */}

            {checkOrg === 3 && checkAdmin === 1 && (
              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    //   to={{
                    //     pathname: "/obligations/obligation_update",
                    //     state: { rowData },
                    //   }}
                    onClick={() => {
                      setEditData(rowData)
                      setShowTable("editTable")
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            )}

            <Badge className="font-size-15 badge-soft-primary" pill>
              <DarkTooltip title="View">
                <Link
                  //   to={{
                  //     pathname: "/obligations/obligation_view",
                  //     state: { rowData },
                  //   }}
                  onClick={() => {
                    // const orderData = cellProps.row.original

                    setShowTable("viewTable")
                    setUsersViewData(rowData)
                    // console.log(orderData, 'data')
                    // handleOrderClick(orderData);
                  }}
                >
                  <i
                    //   onClick={() => handleView(value)}
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-outline  font-size-18"
                    id="customerViewtooltip"
                  ></i>
                </Link>
              </DarkTooltip>
            </Badge>
            {checkOrg === 3 && checkAdmin === 1 && (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            )}
          </ul>
        )
      },
    },
  ]

  const reports = [
    {
      title: "Total Obligations",
      iconClass: "bx-copy-alt",
      description: totalItems && totalItems,
    },
    { title: "New Obligations", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Obligations Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Obligations Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  // console.log(fakeData, "fakeData")
  // document.title = "Obligations"
  const handleRender = () => {
    setDelete(!is_delete)
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Obligation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
              Cancel
            </Button> */}
            </ModalFooter>
          </Modal>
          {/* <Row>
              {reports.map((report, key) => (
                <Col className="col-xl-3 col-md-6" key={"_col_" + key}>
                  <Card className="mini-stats-wid">
                    <CardBody
                      className="custome-card"
                      style={{ padding: "10px 11px !important" }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h6 className="text-muted">{report.title}</h6>
                        </div>
                        <div
                          className="avatar-sm bg-primary align-self-center mini-stat-icon"
                          style={{ borderRadius: "100%" }}
                        >
                          <span
                            className="avatar-title bg-primary"
                            style={{ borderRadius: "100%" }}
                          >
                            <p className="mb-0 font-size-18">
                              {report.description}
                            </p>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                {/* <CardBody className="table-class"> */}
                {/* <div className="row">
                      <div className="col-md-6">
                        <h5 className="mb-sm-0 font-size-18">
                          List of Obligations
                        </h5>
                      </div>
                    </div> */}
                <TableContainer
                  columns={columns}
                  data={reqresData}
                  checkOrg={checkOrg}
                  checkAdmin={checkAdmin}
                  setShowTable={setShowTable}
                  user={user}
                  searchObject={searchObject}
                  filterArray={filterArray}
                  setFilterArray={setFilterArray}
                  props={props}
                  fetchData={fetchData}
                  handleRender={handleRender}
                  setSearchObject={setSearchObject}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  customPageSize={10}
                  loader={loader}
                />
                <Row className="justify-content-center mt-3">
                  <Col className="col-auto">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={limit}
                      onPageChange={page => setCurrentPage(page)}
                    />
                  </Col>
                </Row>
                {/* </CardBody> */}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

//   Obligations.propTypes = {
//     orders: PropTypes.array,
//     onGetOrders: PropTypes.func,
//   }

export default withRouter(UsersTable)
