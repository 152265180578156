// get REGULATORS
export const GET_REGULATORS = "GET_REGULATORS"
export const GET_REGULATORS_SUCCESS = "GET_REGULATORS_SUCCESS"
export const GET_REGULATORS_FAIL = "GET_REGULATORS_FAIL"

// add REGULATOR

export const ADD_NEW_REGULATOR = "ADD_NEW_REGULATOR"
export const ADD_REGULATOR_SUCCESS = "ADD_REGULATOR_SUCCESS"
export const ADD_REGULATOR_FAIL = "ADD_REGULATOR_FAIL"

// edit REGULATOR
export const UPDATE_REGULATOR = "UPDATE_REGULATOR"
export const UPDATE_REGULATOR_SUCCESS = "UPDATE_REGULATOR_SUCCESS"
export const UPDATE_REGULATOR_FAIL = "UPDATE_REGULATOR_FAIL"

/**
 * Delete REGULATOR
 */
export const DELETE_REGULATOR = "DELETE_REGULATOR"
export const DELETE_REGULATOR_SUCCESS = "DELETE_REGULATOR_SUCCESS"
export const DELETE_REGULATOR_FAIL = "DELETE_REGULATOR_FAIL"
