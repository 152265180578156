import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { map } from "lodash"
import { useDispatch } from "react-redux"
import toastr from "toastr"
import axios from "axios"
import { Link } from "react-router-dom"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { createMuiTheme } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import { Filter, DefaultColumnFilter } from "../../../components/Common/filters"
import JobListGlobalFilter from "../../../components/Common/GlobalSearchFilter"
import styled from "styled-components"
import { Popper } from "@material-ui/core"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Pagination from "../../../components/Common/Pagination"
import DeleteModal from "../../../components/Common/DeleteModal"

// import TableContainer from "../../../components/Common/TableContainer"
import FilterModal from "components/FilterModal/FilterModal"
import RolesUpdate from "./update"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, encryptData } from "helpers/helper_function"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :first-child {
        padding-left: 15px;
      }

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      // height: 80px;
      padding-top: 10px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  setShowRolesTable,
  pageCount: customePageCount,
  filterArray,
  setFilterArray,
  columns,
  data,
  fetchData,
  searchObject,
  props,
  handleRender,
  setSearchObject,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useResizeColumns,
    // useFlexLayout,
    useSticky,
    useRowSelect
  )

  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }
  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col md={3} className="py-3 justify-content-end">
          <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">Roles List</h5>
          </div>
        </Col>
        <Col md={9} className="py-3 justify-content-end">
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <Link
              className="btn btn-primary"
              //   to="/obligations/obligation_update"
              onClick={() => setShowRolesTable("addTable")}
            >
              <i className="bx bx-plus font-size-16 align-middle me-1"></i>
              Add New Role
            </Link>
          </div>
        </Col>
      </Row>
      {loader ? (
        <div
          className="container-fluid mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <Styles>
          <div style={{ minHeight: "25rem" }}>
            <div
              className="sticky table"
              {...getTableProps()}
              style={{ height: 500 }}
            >
              <div className="table-light header" style={{ fontWeight: 600 }}>
                {headerGroups.map(headerGroup => (
                  <div
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map(column => (
                      <div
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="th"
                      >
                        {/* <div> */}
                        <div
                          className="mb-2 mt-0"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {rows.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <div {...row.getRowProps()} className="tr">
                          {row.cells.map(cell => {
                            return (
                              <div
                                key={cell.id}
                                {...cell.getCellProps({
                                  style: {
                                    backgroundColor: "#fff",
                                  },
                                })}
                                className="td"
                              >
                                {cell.render("Cell")}
                              </div>
                            )
                          })}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <Row className="mt-5">
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "150px 0",
                      fontSize: "25px",
                    }}
                  >
                    No Records Found
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <FilterModal
            setSelectedHeader={setSelectedHeader}
            setFilterArray={setFilterArray}
            isShowModal={showModal}
            filterData={modalData}
            setShowModal={setShowModal}
            tableName={"Obligation"}
            emptyTable={data?.length == 0??false}

          />
        </Styles>
      )}
      {/* </div> */}
    </Fragment>
  )
}

const RoleTable = props => {
  const { setShowRolesTable, setEditRoleData } = props
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")

  const toggle = () => setModal(!modal)

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delObligation, setDelObligation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const dispatch = useDispatch()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const limit = 20

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const customePageCount = Math.ceil(totalItems / limit)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelObligation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (delObligation.id) {
      // dispatch(onDeleteObligation(delObligation.id, props.history))
      deleteRole(delObligation.id)
      //   console.log(delObligation.id, "delObligation.id")
      setDeleteModal(false)
    }
  }

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log({ searchQuery })
    let finalData= {
      orderBy: {
        tableName: "roles",
        fieldName: "id",
        order: "DESC",
      },
    } //Object.keys(filterArray).length ? filterArray : searchObject
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-roles/?limit=${limit}&pageNo=${currentPage}`,
        {data:encryptData(finalData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteRole = roleID => {
    // console.log(user, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    // const id = user.toString()
    // console.log(id, "delete ID")
    setLoader(true)
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-role?id=${roleID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Role Successfully Deleted.")
          setLoader(false)
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        let message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        RedirectOnUnAuthentication(err,navigateTo,message)
      })
  }

  console.log(reqresData, "reqresData")
  useEffect(() => {
    console.log("page is loading")
    fetchData(searchObject)
  }, [currentPage, is_delete, searchObject, filterArray])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray])

  const columns = useMemo(
    () => [
      {
        Header: "Role Name",
        width: 120,
        accessor: "name",
        filterable: false,
        disableFilters: true,
        //   sticky: "left",
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return <span style={{ color: "#000" }}>{cellProps.value}</span>
        },
      },
      {
        Header: "Action",
        textAlign: "top",
        disableFilters: true,
        sticky: "right",
        filterable: false,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <ul
              className="list-unstyled hstack gap-2"
              style={{ marginBottom: "0px" }}
            >
              {/* <LightTooltip  */}

              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link>
                    <i
                      onClick={() => {
                        setEditRoleData(rowData)
                        setShowRolesTable("editTable")
                      }}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",

                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            </ul>
          )
        },
      },
    ],
    []
  )

  const handleRender = () => {
    setDelete(!is_delete)
  }
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Obligation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Row>
            <Col xs="12">
              <Card>
                <TableContainer
                  columns={columns}
                  data={reqresData}
                  setShowRolesTable={setShowRolesTable}
                  searchObject={searchObject}
                  filterArray={filterArray}
                  setFilterArray={setFilterArray}
                  props={props}
                  fetchData={fetchData}
                  handleRender={handleRender}
                  setSearchObject={setSearchObject}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  customPageSize={10}
                  loader={loader}
                />
                <Row className="justify-content-center mt-3">
                  <Col className="col-auto">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={limit}
                      onPageChange={page => setCurrentPage(page)}
                    />
                  </Col>
                </Row>
                {/* </CardBody> */}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RoleTable
