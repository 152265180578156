import React, { useState, useEffect } from "react"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import axios from "axios"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import {
  addNewRegulation as onAddNewRegulation,
  updateRegulation as onUpdateRegulation,
} from "store/actions" 
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { add } from "lodash"
import { FormatedDate, RedirectOnUnAuthentication } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const RegulationUpdate = props => {
  const [dropdownState, setDropdownState] = useState(false)
  const [selectData, setSelectData] = useState([])
  const [selectClicked, setSelecClicked] = useState(false)
  const [dateSelect, setDateSelect] = useState()
  const [btnDisable, setBtnDisable] = useState(false)
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }/get-regulators/?limit=${1000}&pageNo=${1}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data.data)
        if (response.status >= 200 || response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.regulatorShortName,
          }))
          // console.log(optionData, 'optionData')
          const modifiedOptions = [
            { label: "Select Authority Regulator", value: "" }, // 0 option
            ...optionData, // API options
          ]

          setSelectData(modifiedOptions)

          // setSelectData(response.data.data.map(option => ({
          //   value: option.id,
          //   label: option.regulatorShortName
          // })))
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  // console.log(selectData, 'selecData')
  const location = useLocation()
  const dispatch = useDispatch()

  const [data, setData] = useState()
  useEffect(() => {
    if (location.state) {
      setData(location.state.rowData)
      // console.log(location.state, 'eff date')
      if (location.state.rowData.regEffectiveDate) {
        const d = new Date(location.state.rowData.regEffectiveDate)
        const check = FormatedDate(d) 
        setDateSelect(check)
      } else {
        setDateSelect("")
      }
    }
  }, [])

  // console.log(data, 'data')

  const dropdownToggle = () => {
    setDropdownState(!dropdownState)
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
    menuList: (base) => ({
      ...base,
      maxHeight: "150px" // your desired height
    })
  }

  console.log(dateSelect, "date select")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      cfrRef: (data && data.cfrRef) || "",
      regAuthoringRegulator: (data && data.regAuthoringRegulator) || "",
      regComplianceGuideUrl: (data && data.regComplianceGuideUrl) || "",
      regDescription: (data && data.regDescription) || "",
      regEffectiveDate: dateSelect || "",
      regLongName: (data && data.regLongName) || "",
      regPrimarySupervisor: (data && data.regPrimarySupervisor) || "",
      regRelatedLaw: (data && data.regRelatedLaw) || "",
      regShortName: (data && data.regShortName) || "",
      regUrl: (data && data.regUrl) || "",
      regulatorId: (data && data.regulator && data.regulator.id) || "",
      updatedDate: data && data.updatedDate || "",
    },
    validationSchema: Yup.object({
      cfrRef: Yup.string().matches(
        /^[a-zA-Z0-9_ ( ) - . -]+$/,
        "Special characters are not allowed"
      ),
      // regAuthoringRegulator: Yup.string().required("Please Select Auth Regulator"),
      // regEffectiveDate: Yup.date().required("Please Enter Effective Date"),
      // regComplianceGuideUrl: Yup.string().required("Please Enter URL"),
      // regDescription: Yup.string().required("Please Enter Description"),
      regLongName: Yup.string().matches(
        /^[a-zA-Z0-9_ ( ) - . -]+$/,
        "Special characters are not allowed"
      ),
      // .required("Please Enter Long Name"),
      // regPrimarySupervisor: Yup.string().required("Please Select Supervisor"),
      // regRelatedLaw: Yup.string().matches(/^[a-zA-Z0-9_ ( ) - . -]+$/, 'Special characters are not allowed').required("Please Enter related LAW"),
      regShortName: Yup.string().matches(
        /^[a-zA-Z0-9_ ( ) - . -]+$/,
        "Special characters are not allowed"
      ),
      // .required("Please Enter Short Name"),
      // regUrl: Yup.string().required("Please Enter reg URL"),
      //   confirm: Yup.string().required("Please Re-Enter New Password"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      const d = values.regEffectiveDate ? new Date(values.regEffectiveDate) : ""
      const p = values.updatedDate ? new Date(values.updatedDate) : ""
      //  console.log(d, 'dataa')
      const check = d ? FormatedDate(d) : ""
      const checl = p ? FormatedDate(p) : ""
      //  console.log(check, 'checkk')

      if (!data) {
        const addData = {
          regShortName: values.regShortName,
          regLongName: values.regLongName,
          regDescription: values.regDescription,
          regUrl: values.regUrl,
          regComplianceGuideUrl: values.regComplianceGuideUrl,
          regRelatedLaw: values.regRelatedLaw,
          regEffectiveDate: check ? check : "",
          regAuthoringRegulator:
            values.regAuthoringRegulator == "Select Authority Regulator"
              ? ""
              : values.regAuthoringRegulator,
          regPrimarySupervisor:
            values.regPrimarySupervisor == "Select Authority Regulator"
              ? ""
              : values.regPrimarySupervisor,
          cfrRef: values.cfrRef,
          status: true,
          regulatorId: values.regulatorId == "" ? 0 : values.regulatorId,
          updatedDate: checl ? checl : ""
        }
        console.log(addData, "addta")
        dispatch(onAddNewRegulation(addData, props.history))
        // setBtnDisable(true)
        // validation.resetForm();
      } else {
        // console.log(values);
        const updateData = {
          regShortName: values.regShortName,
          regLongName: values.regLongName,
          regDescription: values.regDescription,
          regUrl: values.regUrl,
          regComplianceGuideUrl: values.regComplianceGuideUrl,
          regRelatedLaw: values.regRelatedLaw,
          regEffectiveDate: check,
          regAuthoringRegulator:
            values.regAuthoringRegulator == "Select Authority Regulator"
              ? ""
              : values.regAuthoringRegulator,
          regPrimarySupervisor:
            values.regPrimarySupervisor == "Select Authority Regulator"
              ? ""
              : values.regPrimarySupervisor,
          cfrRef: values.cfrRef,
          status: true,
          regulatorId: values.regulatorId == "" ? 0 : values.regulatorId,
          id: data && data.id,
          updatedDate: checl ? checl : ""
        }
        dispatch(onUpdateRegulation(updateData, props.history))
      }
      // dispatch(editProfile(values))
    },
  })
  const addTitle = "Add Regulation"
  const updateTitle = "Update Regulation"
  //meta title
  document.title = "Regulations"

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Regulations"
            // breadcrumbItem={updateTitle}
            path={{pathname:"/regulations/all-regulations", state: { rowData: {isClear: true} }}}
            breadcrumbItem={data ? updateTitle : addTitle}
          />
          {/* write Html code or structure */}
          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Password Changed Successfully.</Alert>
              ) : null}
            </Col>
          </Row> */}
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Reg Law Short Name
                        </Label>
                        <Input
                          name="regShortName"
                          // value={data && data.regShortName}
                          className="form-control"
                          placeholder="Enter Reg Law Short Name"
                          type="text"
                          onChange={e =>
                            validation.handleChange(e)
                          }
                          onBlur={validation.handleBlur}
                          value={validation.values.regShortName || ""}
                          invalid={
                            validation.touched.regShortName &&
                            validation.errors.regShortName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.regShortName &&
                        validation.errors.regShortName ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.regShortName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">CFR Ref</Label>
                        <Input
                          name="cfrRef"
                          // value={data && data.cfrRef}
                          className="form-control"
                          placeholder="Enter CFR Ref"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.cfrRef || ""}
                          invalid={
                            validation.touched.cfrRef &&
                            validation.errors.cfrRef
                              ? true
                              : false
                          }
                        />
                        {validation.touched.cfrRef &&
                        validation.errors.cfrRef ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.cfrRef}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulation Long Name
                        </Label>
                        <Input
                          name="regLongName"
                          // value={data && data.regLongName}
                          className="form-control"
                          placeholder="Enter Regulation Long Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.regLongName || ""}
                          invalid={
                            validation.touched.regLongName &&
                            validation.errors.regLongName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.regLongName &&
                        validation.errors.regLongName ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.regLongName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulation Description
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          regDescription
                          data={validation.values.regDescription || ""}
                          config={{
                            removePlugins: [
                              "ImageCaption",
                              "ImageStyle",
                              "ImageToolbar",
                              "ImageUpload",
                              "MediaEmbed",
                              "EasyImage",
                              "UploadImage",
                            ],
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("regDescription", data)
                            // setCkData(data)
                          }}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "300px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                        />
                        {validation.touched.regDescription &&
                        validation.errors.regDescription ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.regDescription}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">Regulation URL</Label>
                        <Input
                          name="regUrl"
                          // value={data && data.reg_url}
                          className="form-control"
                          placeholder="Enter Regulation main URL"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.regUrl || ""}
                          invalid={
                            validation.touched.regUrl &&
                            validation.errors.regUrl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.regUrl &&
                        validation.errors.regUrl ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.regUrl}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulation Compliance Guide URL
                        </Label>
                        <Input
                          name="regComplianceGuideUrl"
                          // value={data && data.pack_size}
                          className="form-control"
                          placeholder="Enter Regulation update source"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.regComplianceGuideUrl || ""}
                          invalid={
                            validation.touched.regComplianceGuideUrl &&
                            validation.errors.regComplianceGuideUrl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.regComplianceGuideUrl &&
                        validation.errors.regComplianceGuideUrl ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.regComplianceGuideUrl}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      {/* <label
                      htmlFor="example-datetime-local-input"
                      className="col-md-6 col-form-label"
                    > */}

                      {/* </label> */}
                      <div className="mb-3">
                        <Label className="form-label">
                          {" "}
                          Regulation Effective Date
                        </Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select Effective Date"
                          name="regEffectiveDate"
                          // defaultDate = "2016-10-20"
                          onChange={date => {
                            // this.setState({ start_date: formatDate(date[0]) })
                            validation.setFieldValue("regEffectiveDate", date)
                          }}
                          value={validation.values.regEffectiveDate}
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: "today",
                          }}
                        />
                        {validation.touched.regEffectiveDate &&
                        validation.errors.regEffectiveDate ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.regEffectiveDate}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulation Related Law
                        </Label>
                        <Input
                          name="regRelatedLaw"
                          // value={data && data.reg_related_low}
                          className="form-control"
                          placeholder="Enter Regulation Related LAW"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.regRelatedLaw || ""}
                          invalid={
                            validation.touched.regRelatedLaw &&
                            validation.errors.regRelatedLaw
                              ? true
                              : false
                          }
                        />
                        {validation.touched.regRelatedLaw &&
                        validation.errors.regRelatedLaw ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.regRelatedLaw}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Regulator Short Name
                        </Label>
                        <Select
                          options={selectData}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="regAuthoringRegulator"
                          value={selectData.find(
                            option =>
                              option.label ==
                              validation.values.regAuthoringRegulator
                          )}
                          onChange={option => {
                            validation.setFieldValue(
                              "regAuthoringRegulator",
                              option.label
                            ),
                              validation.setFieldValue(
                                "regulatorId",
                                option.value
                              )
                          }}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.regAuthoringRegulator &&
                        validation.errors.regAuthoringRegulator ? (
                          <div className="error">
                            {validation.errors.regAuthoringRegulator}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Reguation Primary Supervisor
                        </Label>

                        <Select
                          options={selectData}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="regAuthoringRegulator"
                          value={selectData.find(
                            option =>
                              option.label ===
                              validation.values.regPrimarySupervisor
                          )}
                          onChange={option =>
                            validation.setFieldValue(
                              "regPrimarySupervisor",
                              option.label
                            )
                          }
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.regPrimarySupervisor &&
                        validation.errors.regPrimarySupervisor ? (
                          <div className="error">
                            {validation.errors.regPrimarySupervisor}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      {/* <label
                      htmlFor="example-datetime-local-input"
                      className="col-md-6 col-form-label"
                    > */}

                      {/* </label> */}
                      <div className="mb-3">
                        <Label className="form-label">
                          Reg Last Update Date
                        </Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select Reg Last Update Date"
                          name="updatedDate"
                          // defaultDate = "2016-10-20"
                          onChange={date => {
                            // this.setState({ start_date: formatDate(date[0]) })
                            validation.setFieldValue("updatedDate", date)
                          }}
                          value={validation.values.updatedDate}
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: "today",
                          }}
                        />
                        {validation.touched.updatedDate &&
                        validation.errors.updatedDate ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.updatedDate}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row></Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button
                      disabled={btnDisable}
                      type="submit"
                      className="me-2"
                      color="primary"
                    >
                      Submit
                    </Button>
                    <Link to="/regulations/all-regulations">
                      <Button
                        className="btn btn-secondary waves-effect waves-light "
                        color="secondnary"
                      >
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default RegulationUpdate
