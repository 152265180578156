import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
  useRef,
} from "react"
import { map } from "lodash"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import axios from "axios"
import toastr from "toastr" 
import { withRouter } from "react-router-dom"
import { Link, useLocation } from "react-router-dom"
import Flatpickr from "react-flatpickr"
import { format } from "date-fns"
import { Popper } from "@material-ui/core"
import { useFormik } from "formik"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"   
import ReactQuill from 'react-quill';
import * as Yup from "yup"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useAbsoluteLayout,
  useRowSelect,
} from "react-table"
import { MenuItem, FormControl } from "@material-ui/core"
import { Select as MaterialUISelect } from "@material-ui/core"
import Select from "react-select"
import { useSticky } from "react-table-sticky"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Form,
} from "reactstrap"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import styled from "styled-components"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Pagination from "../../components/Common/Pagination"
import DeleteModal from "../../components/Common/DeleteModal"
import FilterModal from "components/FilterModal/FilterModal"
import TableHeader from "components/Common/TableHeader"
import { CheckValidValue, ColumnDataWithTooltip, DisplayJustifyCenter, DownloadExelFrom, FormatedDate, RedirectOnUnAuthentication, RemoveSpaces, RenameKeysOfObject, SeparateObjectByValueType, ToastError, convertObjectValuesToArray, encryptData, filterFieldsFromArray, getObjectWithSpecificKeys, handleNullAndUndefinedOfArray, removeKeysFromObject, trimLeft } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import TableToExel from "./tableToExel"
import { LOCAL_API_END_POINT, QuilRichEditorModules } from "helpers/general_constant"  
import TextEditor from "./textEditor"
import { HEADER } from "helpers/url_helper"
import useFilterIndicator from "hooks/useFilterIndicator"
// import TableContainer from "../../../components/Common/TableContainer"
// import { TableHeader, Search } from "../../Datatable/index"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

 
const exportToExcel = (rowData, checkOrg,dtext,setDownloadTableData) => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  const customizedData = rowData.map(row => {

    const updatedDate = FormatedDate(row.updatedDate, true)   
    const createdDate = FormatedDate(row.createdDate, true)  
    const effectiveUntilDate = FormatedDate(row.effectiveUntilDate, true)  
    const disclosureTextLanguage = {}
    if (
      row.DisclosureTextLanguage &&
      Array.isArray(row.DisclosureTextLanguage)
    ) {
      for (const obj of row.DisclosureTextLanguage) {
        const language = obj.language.name
        const text = obj.text.replace(/<\/?p>/g, "")
        // disclosureTextLanguage[language] = text
        console.log("object language",language);
        // if (condition) {
          
        // } else {
        //   disclosureTextLanguage[language] = text
        // }
       
      }
    }
    console.log(row, 'row2548756')
    const newRow = removeKeysFromObject(row,["Text (Spanish)","Text (French)","textWithStyle","oldText" ])
    return {
      ...newRow,
      disclosureDesc: row.disclosureDesc.replace(/<[^>]+>/g, ""),
      text: row.text.replace(/<[^>]+>/g, ""),
      // text: row.text,
      // text: <div >gdgdgd</div>,
      status:
        row.status == 1 ? "Pending" : row.status == 2 ? "Approved" : "Removed",
      safeHarbor:
        row.safeHarbor == 1 ? "All" : row.safeHarbor == 2 ? "Part" : "None",
      disclosureType:
        row.disclosureType == 1
          ? "Text"
          : row.disclosureType == 2
          ? "Doc"
          : "Other",
      createdDate: createdDate,
      effectiveUntilDate: row.effectiveUntilDate ? effectiveUntilDate : "-",
      updatedDate: row.updatedDate ? updatedDate : "-",
      communicationTypes: row.communicationTypes ? row.communicationTypes : "-",
      obligations: row.obligations
        ? row.obligations.map(item => item.obligationName)
        : "NA",
      lifeCloseOutEnd: row.lifeCloseOutEnd == false ? "" : "Y",
      lifeMarketing: row.lifeMarketing == false ? "" : "Y",
      lifeOpenOnboard: row.lifeOpenOnboard == false ? "" : "Y",
      lifeServicing: row.lifeServicing == false ? "" : "Y",
      prodGroupAuto: row.prodGroupAuto == false ? "" : "Y",
      prodGroupCard: row.prodGroupCard == false ? "" : "Y",
      prodGroupDeposits: row.prodGroupDeposits == false ? "" : "Y",
      prodGroupInvestments: row.prodGroupInvestments == false ? "" : "Y",
      prodGroupMortgage: row.prodGroupMortgage == false ? "" : "Y",
      prodGroupOther: row.prodGroupOther == false ? "" : "Y",
      disclaimer: row.disclaimer == false ? "" : "Y",
      obligations: (row.obligations && row.obligations.length) || "-",
      // textFrench: disclosureTextLanguage["French"] || "-",
      // textSpanish: disclosureTextLanguage["Spanish"] || "-",
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')
  const filteredData = customizedData.map(
    ({ incrementId, id, DisclosureTextLanguage, ...rest }) => rest
  )
  const clientFilterData = customizedData.map(
    ({
      incrementId,
      id,
      DisclosureTextLanguage,
      disclosureNote,
      lifeCloseOutEnd,
      lifeMarketing,
      lifeOpenOnboard,
      lifeServicing,
      prodGroupAuto,
      prodGroupCard,
      prodGroupDeposits,
      prodGroupInvestments,
      prodGroupMortgage,
      prodGroupOther,
      communicationTypes,
      sample,
      sampleUrl1,
      sampleUrl2,
      sampleUrl3,
      ...rest
    }) => rest
  )

  const dislosureFieldArray  = ["disId", "text"];
  const dislosureFilteredData = filterFieldsFromArray( checkOrg !== 3 ? clientFilterData : filteredData,dislosureFieldArray)
  const arrangedColumns = [
    "disId",
    "disclosureTitle",
    "disclosureDesc",
    "disclosureType",
    "text",
    // "textFrench",
    // "textSpanish",
    "sample",
    "sampleUrl1",
    "sampleUrl2",
    "sampleUrl3",
    "safeHarbor",
    "disclosureNote",
    "disclaimer",
    "disclosureSpecificUrl",
    "disclosureSpecificCitation",
    "prodGroupInvestments",
    "prodGroupDeposits",
    "prodGroupAuto",
    "prodGroupMortgage",
    "prodGroupCard",
    "prodGroupOther",
    "lifeMarketing",
    "lifeOpenOnboard",
    "lifeServicing",
    "lifeCloseOutEnd",
    "communicationTypes",
    "obligations",
    "status",
    "effectiveUntilDate",
    "createdDate",
    "updatedDate",
  ]

  const clientArrangedColumns = [
    "disId",
    "disclosureTitle",
    "disclosureDesc",
    "disclosureType",
    "DisclosureText",
    "text",
    // "textFrench",
    // "textSpanish",
    "safeHarbor",
    "disclaimer",
    "disclosureSpecificUrl",
    "disclosureSpecificCitation",
    "obligations",
    "status",
    "effectiveUntilDate",
    "createdDate",
    "updatedDate",
  ]

  const disclosureColumns = [
    "disId", 
    "text", 
  ]

  console.log("dislosureFilteredData",dislosureFilteredData)
  // setDownloadTableData(dislosureFilteredData)

  const worksheet = XLSX.utils.json_to_sheet(
    dtext?dislosureFilteredData: checkOrg !== 3 ? clientFilterData : filteredData,
    {
      header: dtext?disclosureColumns :  checkOrg !== 3 ? clientArrangedColumns : arrangedColumns,
    }
  )



  const columns = [
    "Disclosure ID",
    "Disclosure Title",
    "Disclosure Description",
    "Disclosure Type",
    "Disclosure Text",
    // "Text (English)",
    // "Text (French)",
    // "Text (Spanish)",
    "Sample",
    "Sample URL1",
    "Sample URL2",
    "Sample URL3",
    "Safe Harbor (All, Part, None)",
    "Disclosure Notes",
    "Disclaimer",
    "Disclosure specific URL",
    "Disclosure specific Citation",
    "Prod Group Inv",
    "Prod Group Dep",
    "Prod Group Auto",
    "Pro Group Mort",
    "Pro Group Card",
    "Prod Group Other",
    "Life - Marketing",
    "Life - Open Onboard",
    "Life - Servicing",
    "Life - Close-out End",
    "Communication Types",
    "# of Linked Obligations",
    "Status",
    "Effective Until Date",
    "Created Date",
    "Updated Date",
  ]

  const clientColumns = [
    "Disclosure ID",
    "Disclosure Title",
    "Disclosure Description",
    "Disclosure Type",
    "Text",
    // "Text (English)",
    // "Text (French)",
    // "Text (Spanish)",
    "Safe Harbor (All, Part, None)",
    "Disclaimer",
    "Disclosure specific URL",
    "Disclosure specific Citation",
    "# of Linked Obligations",
    "Status",
    "Effective Until Date",
    "Created Date",
    "Updated Date",
  ]

  const discloSurecolumns = [
    "Disclosure ID", 
    "Disclosure Text", 
  ]
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [dtext?discloSurecolumns:checkOrg !== 3 ? clientColumns : columns],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  // const ws = XLSX.utils.json_to_sheet(data);
  // worksheet.Cells["A2"].HtmlString = "<i>Bold Text</i>"
  // worksheet['A2'].v = "<i>Italic Text</i>"
  // worksheet['A1'].HtmlString = "<b>Bold Text</b>"
  // worksheet['A2'].s = { font: { bold: true } }; 
  // console.log("worksheet========workbook",workbook)
  // console.log("worksheet========worksheet",worksheet)

    // Apply styles to specific cells
// Example: make cell B2 bold
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Disclosures.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

 

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :first-child {
        .td {
          padding-top: 10px;
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 98px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 110,
  },

  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}

const TableContainer = ({
  loader,
  columns,
  fetchData,
  filterArray,
  handleSort,
  setAddModal,
  setUploadModal,
  uploadModal,
  setSortBy,
  checkOrg,
  checkAdmin,
  setCheckOrg,
  disclosureAccess,
  downloadAccess,
  props,
  searchObject,
  customePageSize,
  setCustomePageSize,
  handleRender,
  dateQuery,
  setSearchObject,
  setFilterArray,
  pageCount: customePageCount,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  localStorageObjectBoleanAndNumber
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useFlexLayout,
    useResizeColumns,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [btnDisLoader, setBtnDisLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState(localStorageObjectBoleanAndNumber??{})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedUntilDate, setSelectedUntilDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [updatedData, setUpdatedData] = useState("new")
  const [blankColumn, setBlankColumn] = useState("0")
  const [modalData, setModalData] = useState({})
  const [downloadTableData,setDownloadTableData]= useState([])
  const [selectedHeader, setSelectedHeader] = useState([])
  const [linkedDisclosures, setLinkedDisclosures] = useState('All');
  const { generateSortingIndicator, toggleIndicator } = useFilterIndicator();
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }

  useEffect(() => {
    const data = localStorage.getItem("disclosures/all-disclosures");

    let finalData;

    if (data != null && props?.location?.state?.rowData?.isClear != undefined) {
        finalData = JSON.parse(data);
        let modifiedObject = {};

        console.log(finalData, "finalData");

        for (let key in finalData) {
            if (finalData.hasOwnProperty(key)) {
                let newKey;
                let selectedkey;

                    newKey = key
                    selectedkey = key
             
                if (Array.isArray(finalData[key])) {
                    modifiedObject[newKey] = finalData[key][0];
                } else if (typeof finalData[key] === "string") {
                    modifiedObject[newKey] = finalData[key];
                } else {
                    console.log(`Value for key ${key} is not a valid string.`);
                }

                // Check if selectedkey matches any key in selectedFilterArray
                const selectedFilterArray = JSON.parse(localStorage.getItem("selectedFilterArray"));
                if (selectedFilterArray && selectedFilterArray.hasOwnProperty(selectedkey)) {
                    delete modifiedObject[newKey];
                }
            }
        }

        console.log(modifiedObject, "modifiedObject");
        setAutoEmpty(modifiedObject);
    }
    
}, [localStorage.getItem("disclosures/all-disclosures")]);

const localStorageData = localStorage.getItem("disclosures/all-disclosures")
useEffect(() => { 
  if (localStorageData != null) {
    const parse_data = JSON.parse(localStorageData) 
    const {otherValuesObject} = SeparateObjectByValueType(parse_data)  
    setSearchQuery(otherValuesObject) 
  }
}, [localStorageData]);

 
  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = ({dtext}) => {
    try {
       const authToken = JSON.parse(localStorage.getItem("authUser"))
       !dtext?setBtnLoader(true):setBtnDisLoader(true)

    console.log("88888dtext",dtext)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      disclosuresIds: ids,
      customerorgId:
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    let apiUrl
    if (checkOrg !== undefined && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/${!dtext?"export-disclosures":"export-style-disclosure-text"}`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/${!dtext?"export-disclosure-organization":"export-style-disclosure-text-organization"}`
    }

    axios
      .post(apiUrl, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`, 
          ...HEADER
        } ,
        responseType: 'blob'
      })
      .then(response => {
        // console.log(response.data.data, "exporData254566")
        setBtnDisLoader(false)
        setBtnLoader(false) 
        console.log("response.data",response.data);
        // if (!dtext) {
        //    setDownloadTableData(response.data.data)
        //    exportToExcel(response.data.data, checkOrg,dtext,setDownloadTableData)

          // try {
            // const response = await axios.get('backend_endpoint_to_download_xls_file', {
            //   responseType: 'blob' // Set responseType to 'blob' to handle binary data
            // });
           
          //   const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          //   const url = window.URL.createObjectURL(blob);
          //   const link = document.createElement('a');
          //   link.href = url;
          //   link.setAttribute('download', 'filename.xlsx'); // Set filename for downloaded file
          //   document.body.appendChild(link);
          //   link.click();
          // } catch (error) {
          //   console.error('Error downloading Excel file:', error);
          // }
    
           
        // }else{

      //       setTimeout(()=>{
      //   const button = document.getElementById('downLoadXlxs');
       
      //   console.log("dtext========",dtext)
      //   button.click();
      //  },3000)
      DownloadExelFrom({responseData:response.data,fileTitle:!dtext?"disclosure":"disclosure_text"})
      // try {
      //   // const response = await axios.get('backend_endpoint_to_download_xls_file', {
      //   //   responseType: 'blob' // Set responseType to 'blob' to handle binary data
      //   // });
      //   const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //   const url = window.URL.createObjectURL(blob);
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute('download', 'disclosure_text.xlsx'); // Set filename for downloaded file
      //   document.body.appendChild(link);
      //   link.click();
      // } catch (error) {
      //   console.error('Error downloading Excel file:', error);
      // }
       
        setBtnDisLoader(false)
        setBtnLoader(false) 
        // }
       
       

     
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        //toastr.error(error, "Error Exporting Data")
        ToastError(error)
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
    } catch (error) {
      console.error('Error in handleExport:', error);
    }
   
  }
//   const handleExportWithStyle = ({dtext}) => {
//     const authToken = JSON.parse(localStorage.getItem("authUser"))
//     setBtnLoader(true)
//     console.log("88888dtext",dtext)
//     const id_arr = map(selectedFlatRows, "original.id")
//     const ids = id_arr.toString()

//     const data = {
//       ...searchObject,
//       ...filterArray,
//       disclosuresIds: ids,
//       customerorgId:
//         checkOrg !== undefined && checkOrg !== 3
//           ? checkOrg !== "" && parseInt(checkOrg)
//           : "",
//     }

//     let apiUrl
//     if (checkOrg !== undefined && checkOrg === 3) {
//       apiUrl = `${LOCAL_API_END_POINT}/export-disclosures`
//     } else {
//       apiUrl = `${LOCAL_API_END_POINT}/export-disclosure-organization`
//     }

//     axios
//       .post(apiUrl, data, {
//         headers: {
//           Authorization: `Bearer ${authToken.data.authToken}`
//         },
//         responseType:'blob'
//       })
//       .then(response => {
//         console.log(response?.data, "exporData254566")
        

 
// try {
//   // const response = await axios.get('backend_endpoint_to_download_xls_file', {
//   //   responseType: 'blob' // Set responseType to 'blob' to handle binary data
//   // });
//   const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   const url = window.URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', 'filename.xlsx'); // Set filename for downloaded file
//   document.body.appendChild(link);
//   link.click();
// } catch (error) {
//   console.error('Error downloading Excel file:', error);
// }
 
 

//       //   if (!dtext) {
//       //      exportToExcel(response.data.data, checkOrg,dtext,setDownloadTableData)
           
//       //   }else{

//       //       setTimeout(()=>{
//       //   const button = document.getElementById('downLoadXlxs');
       
//       //   console.log("dtext========",dtext)
//       //   button.click();
//       //  },3000)
//       //   }
       
//         setBtnLoader(false) 

     
//       })
//       .catch(error => {
//         RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
//         // toastr.error(error, "Error Exporting Data")
//         ToastError(error)
//         setBtnLoader(false)
//       }) 
//   }
  const handleDownloadFormat = () => {
    const csvData =
      'Disclosure Title,Disclosure Description,Disclosure Type,Text,Sample,Sample URL1,Sample URL2,Sample URL3,"Safe Harbor (All, Part, None)",Disclosure Notes,Disclaimer,Disclosure specific URL,Disclosure specific Citation,Prod Group Inv,Prod Group Dep,Prod Group Auto,Pro Group Mort,Pro Group Card,Prod Group Other,Life - Marketing,Life - Open Onboard,Life - Servicing,Life - Close-out End,Communication Types,Status'
    const filename = "Disclosures_format.csv"
    const csvBlob = new Blob([csvData], { type: "text/csv" })
    const csvURL = window.URL.createObjectURL(csvBlob)

    const tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", filename)
    tempLink.click()

    // Cleanup
    window.URL.revokeObjectURL(csvURL)
    tempLink.remove()
  }

  const handleSearchQueryChange = (columnKey, value) => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    setSearchQuery(prev => ({ ...prev, [columnKey]: value }))

    
    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []

    const data = {
      tableName:
        checkOrg !== undefined && checkOrg === 3
          ? "Disclosure"
          : "custDisclosures",
      feildName: columnKey,
      searchValue: value,
      ids: idsArray,
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (columnKey != "updatedDate" && columnKey != "effectiveUntilDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }
        setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  const handleKeyDown = (key, columnId, value) => { 
    console.log(key, "key***************")
    if (key === "Enter") {
      // fetchData(searchQuery)
      console.log("handleAutocompleteChange",columnId, value)
      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        setSelectedDate(formattedDate)
        updatedValue = formattedDate
      } else {
        updatedValue = value.trim() // Set the value to true when selecting "Yes"
      }
  
      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
  
      console.log("API call triggered:", value)
    }
  }

  const handleAutocompleteChange = (columnId, value) => {

    const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);
    if (optionExists || value === "") {
      console.log("selected from suggestion box")

      let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      updatedValue = formattedDate
    } else if (columnId == "effectiveUntilDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedUntilDate(formattedDate)
      updatedValue = formattedDate
    } else {
      updatedValue = value.trim() // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: [updatedValue.trim()] }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    } else {
      let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      updatedValue = formattedDate
    } else if (columnId == "effectiveUntilDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedUntilDate(formattedDate)
      updatedValue = formattedDate
    } else {
      updatedValue = value.trim() // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    }

    

    console.log("API call triggered:", value)
  }

  const handleDateChange = date => {
    const newDate = new Date(date)
    const formattedDate = format(newDate, "yyyy-MM-dd")
    console.log(formattedDate, "formattedDate")
    setSelectedDate(formattedDate)
    dateQuery(formattedDate)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      setSelectedUntilDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const handleKeyDownUntilDate =(columnId, e) => {
    console.log("handleKeyDownUntilDate",e.keyCode , e.keyCode )
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      setSelectedUntilDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const valueOptions = [
    { value: "all", label: "All" },
    { value: true, label: "True" },
    { value: false, label: "False" },
  ]

  const handleInput = (e, columnid) => {
    const value = e.target && e.target.value
    console.log(columnid, value)
    handleSearchQueryChange(columnid, value)
    setInputValue(value)
  }

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value")
    // if (value == false) {
    //   const updatedSearchQuery = { ...searchQuery, [columnKey]: false }
    //   setSearchQuery(prevSearchQuery => ({
    //     ...prevSearchQuery,
    //     [columnKey]: false,
    //   }))
    //   // fetchData(updatedSearchQuery)
    //   setSearchObject(updatedSearchQuery);
    // } else if (value == true) {
    //   const updatedSearchQuery = { ...searchQuery, [columnKey]: true }
    //   setSearchQuery(prevSearchQuery => ({
    //     ...prevSearchQuery,
    //     [columnKey]: true,
    //   }))
    //   // fetchData(updatedSearchQuery)
    //   setSearchObject(updatedSearchQuery);
    // } else {
    //   const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
    //   setSearchQuery(prevSearchQuery => ({
    //     ...prevSearchQuery,
    //     [columnKey]: "",
    //   }))
    //   // fetchData(updatedSearchQuery)
    //   setSearchObject(updatedSearchQuery);
    // }

    // console.log(searchQuery[columnKey], "column id")
    let updatedValue
    if (value === "") {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (value === false) {
      updatedValue = false // Set the value to false when selecting "No"
    } else {
      updatedValue = true // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnKey]: updatedValue }
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
  }

  const handleNumberChange = (columnKey, value) => {
    console.log(value, "value")
    // console.log(searchQuery[columnKey], "column id")
    let updatedValue
    if (value === "All") {
      updatedValue = "All" 
    } else if (value === false) {
      updatedValue = "No" 
    } else {
      updatedValue = "Yes" 
    }
    const updatedSearchQuery = { ...searchQuery, ["link_status"]: updatedValue }
    setLinkedDisclosures(updatedValue)
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
  }

  const handleStatusChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }

  const handleTypeChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }

  const handleSafeHarborChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }
  const clearDate = () => {
    setSelectedDate(null)
    setSearchQuery({})
  }

  // const handleKeyDown = event => {
  //   if (event.key === "Enter") {
  //     fetchData(searchQuery)
  //   }
  // }

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    console.log("call cler function")
    setLinkedDisclosures('All')
    setSearchQuery({})
    setSelectedHeader([])
    setAutoEmpty({})
    setAutoFill({})
    setFilterArray({})
    setSortBy({
      tableName:
        checkOrg !== undefined && checkOrg === 3
          ? "disclosure"
          : "custDisclosures",
      fieldName: "disclosureTitle",
      order: "ASC",
    })
    setSearchObject({})
    setSelectedDate(null)
    setSelectedUntilDate(null)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    localStorage.removeItem("disclosures/all-disclosures")
    localStorage.removeItem("disclosures/all-disclosures/selectedFilterArray")
    dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const customStyles = {
    control: provided => ({
      ...provided,
      minWidth: 200,
    }),
  }

  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-disclosures`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}`, ...HEADER },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            toggle()
            handleRender()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }
  const handleImportEditData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("isBlankUpdatable", blankColumn)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-disclosure-update`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }
  const handleDisImportData = e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-disclosure-text`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            toggle()
            handleRender()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          toggle()
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }
  const edit = () => {
    props.history.replace({
      pathname: "/disclosures/disclosure_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "Disclosure",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
        }
      )
      .then(response => {
        
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
              ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      let newCheckOrg

      if (obj2) {
        newCheckOrg = obj2.orgId
      } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
      }

      if (newCheckOrg !== checkOrg) {
        setCheckOrg(newCheckOrg)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])


  const SelectedFilterIcon = (filterArray,column_id)=>{
    const columnId = `${column_id}`.split("_").pop()

          try { 
              const IsColumnIdSelected = JSON.parse(localStorage.getItem("selectedFilterArray") || "[]")?.[columnId] || []
              const IsColumnIdSelectedObj = JSON.parse(localStorage.getItem("selectedFilterArray") ) || {}
              const IsColumnIdExistInFilterArray = Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(`${columnId}`.split("_").pop()))  
                if (IsColumnIdExistInFilterArray && IsColumnIdSelected.length !=0) {
                return true
              } else {
                return false
              } 
          } catch (error) {
            console.log("ooooooooooooooooooooo", "error",error)
            return false
    }
    

  }
  console.log("showFilterModal",showFilterModal)

  // const  [autocompleteSearch, setAutoCompleteSearch] = useState([])

  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col md={4} className="py-3 justify-content-end">
          {/* <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">List of Disclosures</h5>
            <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{width : "150px", marginLeft: "10px"}}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
          </div> */}
          <TableHeader
            heading={"List of Disclosures"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        <Col md={8} className="py-3 justify-content-end">
          <div className="d-flex flex-wrap gap-2 justify-content-end">
         
            {disclosureAccess &&
            disclosureAccess.writeAccess === true &&
            checkOrg === 3 ? (
              selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      // marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  {/* <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button> */}
                </>
              ) : (
                // :
                // selectedFlatRows.length >= 1 ? (
                //   <Button
                //     onClick={(e) => {
                //       onAllDeleteRecord(e)
                //     }}
                //     style={{
                //       backgroundColor: "#556EE6",
                //       marginLeft: 5,
                //       marginRight: 5,
                //       color: "#fff",
                //       textTransform: "none",
                //     }}
                //   >
                //     <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                //     Delete
                //   </Button>
                // )
                ""
              )
            ) : (
              ""
            )}
             
            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {disclosureAccess &&
            disclosureAccess.writeAccess == true &&
            checkOrg !== 3 ? (
              <Button
                variant="contained"
                // onClick={toggle}
                onClick={() => setUploadModal(true)}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                  // padding: "5px"
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload Disclosure Texts
              </Button>
             ) : (
              ""
            )}  

            {disclosureAccess &&
            disclosureAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Link
                className="btn btn-primary"
                to="/disclosures/disclosure_update"
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Disclosures
              </Link>
            ) : (
              ""
            )}
            {disclosureAccess &&
            disclosureAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Button
                onClick={() => {
                  setModal(!modal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )}
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px" }}
            >
              <ModalHeader toggle={toggle}>{title}</ModalHeader>
              <ModalBody className="px-4 py-4 text-left">
                 <div
                  style={{ marginBottom: "20px", display: "flex", gap: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="new-data"
                      name="form-radio-primary"
                      defaultChecked={updatedData == "new"}
                      value="new"
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload new data
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="edit-data"
                      name="form-radio-primary"
                      value="edit"
                      defaultChecked={updatedData == "edit"}
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="edit-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload Edit
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="disclosure-data"
                      name="form-radio-primary"
                      value="disclosure"
                      defaultChecked={updatedData == "disclosure"}
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="disclosure-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Disclosure Text
                    </label>
                  </div>
                </div>
                {updatedData == "edit" && (
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <DarkTooltip title="Blank cells WILL NOT overwrite existing content">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="0"
                          name="blank-column"
                          defaultChecked={blankColumn == "0"}
                          value="0"
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="0"
                          style={{ marginBottom: "0px" }}
                        >
                          Ignore Blank Cells{" "}
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                    <DarkTooltip title="Blank cells WILL overwrite existing content">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="1"
                          name="blank-column"
                          value="1"
                          defaultChecked={blankColumn == "1"}
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="1"
                          style={{ marginBottom: "0px" }}
                        >
                          Load Blank Cells
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                  </div>
                )}
                <h6>Import Data</h6>
                <div className="form-body mt-3">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-2"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button" 
                    onClick={e => {
                      updatedData == "new"
                        ? handleImportData(e)
                        : updatedData == "edit"?handleImportEditData(e):handleDisImportData(e)
                    }}
                  >
                    Import Data
                  </button>
                </div>
              </ModalBody>
            </Modal>

            {   btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) :(<>
              

              {downloadAccess && downloadAccess.readAccess === true ? (
           
                <button className="btn btn-primary" onClick={handleExport}>
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Download
                </button>
             
            ) : (
              ""
            )}

           

              </>)
            
            
            }




{   btnDisLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) :(<>
              

              {downloadAccess && downloadAccess.readAccess === true &&  
              
              <button className= "btn btn-primary"  onClick={()=>handleExport({dtext:true})}>
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Download Disclosure Text 
              </button>
          }

           

              </>)
            
            
            }
         
         
 
         {/* <TableToExel downloadTableData={downloadTableData} onHandleClick={()=>handleExport({dtext:true})}/> */}
            {/* <button
                type="button"
                className="btn btn-primary "
                // onClick={handleExport}
              >
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Upload
              </button>  */}
            {/* <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Buttn
                  variant="contained"
                  style={{
                    backgroundColor: "#556ee6",
                    color: "#fff",
                    textTransform: "none ",
                    fontFamily: "poppins",
                    fontWeight: 400,
                    fontSize: 15
                  }}
                  component="span"
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </Buttn>
              </label> */}
          </div>
        </Col>
      </Row>

      {/* <div className="table-responsive react-table"> */}
      {/* {loader ? (
        <div
          className="container-fluid mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : ( */}
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 600 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => {
                          handleSort(column);
                          toggleIndicator(column.id); 
                        }}
                      >
                        {column.render("Header")}
                        <span style={{ color: "#556ee6" }}>
                        {generateSortingIndicator(column)}
                        </span>
                      </div>
                      {console.log(column.id, 'Here Is The Selected column id')}
                      {column.id != "selection" &&
                      column.id != "updatedDate" &&
                      column.id != "effectiveUntilDate" &&
                      column.id != "custObligations" &&
                      column.id != "prodGroupInvestments" &&
                      column.id != "prodGroupDeposits" &&
                      column.id != "prodGroupAuto" &&
                      column.id != "prodGroupMortgage" &&
                      column.id != "prodGroupCard" &&
                      column.id != "prodGroupOther" &&
                      column.id != "lifeMarketing" &&
                      column.id != "lifeOpenOnboard" &&
                      column.id != "lifeOpenOnboard" &&
                      column.id != "lifeServicing" &&
                      column.id != "lifeCloseOutEnd" &&
                      column.id != "disclaimer" &&
                      column.id != "status" &&
                      column.id != "disclosureType" &&
                      column.id != "safeHarbor" &&
                      column.id != "obligations" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                            paddingInlineEnd:
                            column.id === 'disclosureType' ? '5px' :
                            column.id === 'disId' ? '2px' :
                            column.id === 'disclaimer' ? '12px' :
                            '6px'                        
                          }}
                        >
                          {/* {(column.id != "disclosureDesc" || autocompleteSearch.includes(column.id)) ?  */}
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{
                                width: column.id === 'text' ? '65%' : column.id === 'disId' ?  '70%' : column.id === 'disclosureDesc' ? '68%' : '100%'
                              }}                              
                              disabled={loader}
                              PopperComponent={PopperMy}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              // options={handleNullAndUndefinedOfArray(autoFill[column.id]||[])}
                              options={autoEmpty[column.id] || searchQuery[column.id] ? autoFill[column.id] || [] : []} // Show options only when there's input                              // getOptionLabel={(option) => option || ""}
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value) 
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  disabled={loader}
                                  onKeyDown={(e) => {
                                    handleKeyDown(e.key,column.id,e.target.value)
                                   }}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider> 
                          {/* : 
                          <div className="filter_button" onClick={() => {setAutoCompleteSearch((prev) => [...prev, column.id])}} style={{ borderRadius: 0, marginRight: "5px", padding: "2px", cursor: "pointer"  }}> <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth={0}
                                              id="search"
                                              x="0px"
                                              y="0px"
                                              viewBox="0 0 24 24"
                                              height="20px"
                                              width="20px"
                                              xmlns="http://www.w3.org/2000/svg"
                                              {...props}
                                            >
                                              <g>
                                                <path d="M20.031,20.79c0.46,0.46,1.17-0.25,0.71-0.7l-3.75-3.76c1.27-1.41,2.04-3.27,2.04-5.31 c0-4.39-3.57-7.96-7.96-7.96s-7.96,3.57-7.96,7.96c0,4.39,3.57,7.96,7.96,7.96c1.98,0,3.81-0.73,5.21-1.94L20.031,20.79z  M4.11,11.02c0-3.84,3.13-6.96,6.96-6.96c3.84,0,6.96,3.12,6.96,6.96c0,3.84-3.12,6.96-6.96,6.96C7.24,17.98,4.11,14.86,4.11,11.02 z" />
                                              </g>
                                            </svg>
                                            </div>
                                          } */}
                          <button
                            disabled={loader}
                            onClick={() => {
                              setShowFilterModal(true)
                              setModalData(column)
                            }}
                            // style={{
                            //   backgroundColor: "#fff",
                            //   border: "1px solid #ADB5BD",
                            //   borderRadius: "0px 4px 4px 0px",
                            // }}
                            className={
                              // selectedHeader.includes(column.Header)
                              SelectedFilterIcon(filterArray,column.id)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                      ) : column.id == "updatedDate" ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: "2023-05-15",
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />
                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary docs-datepicker-trigger"
                                              // disabled
                                              style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div> */}
                        </div>
                      ) : column.id == "effectiveUntilDate" ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedUntilDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: "2023-05-15",
                            }}
                            onKeyDown={e =>
                              handleKeyDownUntilDate(column.id, e)
                            }
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary docs-datepicker-trigger"
                                              // disabled
                                              style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div> */}
                        </div>
                      ) : column.id == "prodGroupInvestments" ||
                        column.id == "prodGroupDeposits" ||
                        column.id == "prodGroupAuto" ||
                        column.id == "prodGroupMortgage" ||
                        column.id == "prodGroupCard" ||
                        column.id == "prodGroupOther" ||
                        column.id == "lifeMarketing" ||
                        column.id == "lifeOpenOnboard" ||
                        column.id == "lifeOpenOnboard" ||
                        column.id == "lifeServicing" ||
                        column.id == "lifeCloseOutEnd" ||
                        column.id == "disclaimer" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            style={{minWidth: (column.id === 'disclaimer' 
                            || column.id === 'lifeMarketing' 
                            || column.id === 'lifeOpenOnboard' 
                            || column.id === 'lifeServicing'
                            ||  column.id === "obligations" ) 
                            ? '100px' : '85px'}}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              value={
                                searchQuery[column.id] != undefined
                                  ? searchQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleValueChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value={""}>All</MenuItem>{" "}
                              <MenuItem value={true}>Yes</MenuItem>{" "}
                              <MenuItem value={false}>No</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) : column.id == "obligations" || 
                      column.id == "custObligations" ? (
                        <div
                        style={{
                          width: "80%",
                          position: "absolute",
                          bottom: "10px",
                        }}
                      >
                        <FormControl fullWidth className={classes.formControl}>
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              // value={ 
                              //   (() => {
                              //     console.log(searchQuery[column.id], "Here We Are Checking Search ")
                              //     searchQuery[column.id] !== undefined ? searchQuery[column.id] : ""
                              //   })()
                              //  }
                              value={linkedDisclosures === 'All' ? 'All' 
                                  : linkedDisclosures === 'Yes' ? true : false
                              } 
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={(event) => {
                                const selectedValue = event.target.value;
                                console.log("Selected value:", selectedValue);
                                handleNumberChange(column.id, selectedValue);
                              }}
                            >
                              <MenuItem value={"All"}>All</MenuItem>
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </MaterialUISelect>
                        </FormControl>
                      </div>
                      )
                      :
                      column.id == "disclosureType" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}d
                              value={
                                searchQuery[column.id] != undefined
                                  ? searchQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleTypeChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Text</MenuItem>
                              <MenuItem value={2}>Doc</MenuItem>
                              <MenuItem value={3}>Other</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) : column.id == "status" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              value={
                                searchQuery[column.id] != undefined
                                  ? searchQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleStatusChange(
                                  column.id,
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Pending</MenuItem>
                              <MenuItem value={2}>Approved</MenuItem>
                              <MenuItem value={3}>Removed</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) : column.id == "safeHarbor" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              value={
                                searchQuery[column.id] != undefined
                                  ? searchQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleSafeHarborChange(
                                  column.id,
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value="">Default</MenuItem>
                              <MenuItem value={1}>All</MenuItem>
                              <MenuItem value={2}>Part</MenuItem>
                              <MenuItem value={3}>None</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <Filter column={column} /> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          setFilterArray={setFilterArray}
          isShowModal={showFilterModal}
          filterData={modalData}
          setShowModal={setShowFilterModal}
          tableName={
            checkOrg !== undefined && checkOrg === 3
              ? "Disclosure"
              : "custDisclosures"
          }
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0??false}
        />
      
      </Styles>
      {/* )} */}
      {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre> */}
      {/* </div> */}
    </Fragment>
  )
}

const Disclosures = props => {
  const [fakeData, setFakeData] = useState([])
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const obj2 = JSON.parse(localStorage.getItem("orgID"))
  let newCheckOrg

  if (obj2) {
    newCheckOrg = obj2.orgId
  } else if (!obj2 && authToken) {
    newCheckOrg = authToken.data.customerorg.id
  }
  const [modal, setModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [selectedFile, setSelectedFile] = useState("")
  const [selectDisclosure, setSelectDisclosure] = useState([])
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [is_delete, setDelete] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [deleteDisc, setDeleteDiscl] = useState(null)
  const [searchObject, setSearchObject] = useState({})
  const [localStorageObjectBoleanAndNumber, setLocalStorageObjectBoleanAndNumber] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [disclosureAccess, setDisclosureAccess] = useState([])
  const [downloadAccess, setDownloadAccess] = useState([])
  const [checkAdmin, setCheckAdmin] = useState(false)
  const [checkOrg, setCheckOrg] = useState( obj2?.orgId|| authToken?.data?.customerorg?.id)
  const [textId, setTextId] = useState({})
  const [value2, setValue2] = useState('');
  const location = useLocation()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const history = useHistory();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('selectedFilterArray'));
      if(data && props?.location?.state?.rowData?.isClear){
        setFilterArray(data);
      }
  },[localStorage.getItem('selectedFilterArray'), props?.location?.state?.rowData?.isClear])
  
  // useEffect(() => {
  //   if (location.state && location.state.rowData) {
  //     // setSearchObject({ ...searchObject, ["regLaw"]: location.state.rowData.regShortName })
  //     // console.log(location.state.rowData)
  //     // if(checkOrg === 3) {
  //       setFilterArray({ ["disId"]: location.state.rowData.disclosuresIds })
  //     // }else{
  //     //   setFilterArray({ ["contentId"]: location.state.rowData.custContentIds })
  //     // }
  //   }
  // }, [])

 

  useEffect(() => {
    const handleStorageChange = () => {
     

      if (obj2) {
        newCheckOrg = obj2.orgId
      } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
      }

      if (newCheckOrg !== checkOrg) {
        setCheckOrg(newCheckOrg)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])
  // const [sortBy, setSortBy] = useState({
  //   tableName: checkOrg === 3 ? "disclosure" : "custDisclosures",
  //   fieldName: "id",
  //   order: "DESC",
  // });

  const [sortBy, setSortBy] = useState({fieldName   :  "disclosureTitle",
    order :   "ASC",
    tableName :  checkOrg !== "" && checkOrg === 3 ? "disclosure" : "custDisclosures"})

  // useEffect(() => {
  //   if (checkOrg !== undefined) {
  //     setSortBy(prevSortBy => ({
  //       ...prevSortBy,
  //       tableName: checkOrg === 3 ? "disclosure" : "custDisclosures",
  //     }));
  //   }
  // }, [checkOrg]);

  useEffect(() => {
    // setSortBy(prevSortBy => ({
    //   ...prevSortBy,
    //   tableName: checkOrg !== undefined  && checkOrg === 3 ? "obligation" : 'custObligations',
    // }));
    if (checkOrg !== undefined && checkOrg === 3) {
      setSortBy({
        tableName: "disclosure",
        fieldName: "disclosureTitle",
        order: "ASC",
      })
    } else  {
      setSortBy({
        tableName: "custDisclosures",
        fieldName: "id",
        order: "ASC",
      })
    }
  }, [checkOrg])

  const handleSort = column => {
    const isAsc = sortBy.fieldName === column.id && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (
      column.id !== "selection" &&
      column.id !== "obligations" &&
      column.id !== "Action"
    ) {
      setSortBy({
        tableName:  checkOrg !== "" && checkOrg === 3  ? "disclosure" : "custDisclosures",
        fieldName: column.id,
        order: sortOrder,
      })
    }
  }

    
  useEffect(() => {
    if(props?.location?.state?.rowData?.isClear == undefined){
      localStorage.removeItem("selectedFilterArray")
      localStorage.removeItem("disclosures/all-disclosures/selectedFilterArray")
      localStorage.removeItem("disclosures/all-disclosures");
    }
  },[props?.location?.state?.rowData?.isClear])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDisclosureAccess(authToken && authToken.data.role.rolesPermission[3])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const toggle = () => setModal(!modal)
  const addToggle = () => setAddModal(!addModal)
  const uploadToggle = () => setUploadModal(!uploadModal)

  const closeAddModal = () => {
    setAddModal(false)
    validation.resetForm()
  }

  const [deleteModal, setDeleteModal] = useState(false)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const onClickDelete = reg => {
    // setOrder(order);
    setDeleteDiscl(reg)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')

    if (deleteDisc.id) {
      // dispatch(onDeleteDisclosure(deleteDisc.id, props.history))
      deleteDisclosure(deleteDisc.id)
      setDeleteModal(false)
    }
  }

  const limit = 20
  const customePageCount = Math.ceil(totalItems / limit)

  const deleteDisclosure = disclosure => {
    // console.log(regulation, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    // setLoader(true)
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-disclosure?id=${disclosure}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Disclosure Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        let message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
         ToastError(err)
        // throw message;
      })
  }

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const storageFilter = JSON.parse(localStorage.getItem("home/disclosures/all-disclosures"))??{}
    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject
    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
        let finalData =   {...searchObject,...filterArray}
        setLoader(true)

    let apiUrl
    if (checkOrg !== "" && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-disclosures/?limit=${customePageSize}&pageNo=${currentPage}`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-disclosure-organization/?limit=${customePageSize}&pageNo=${currentPage}`
    }


    let data = localStorage.getItem("disclosures/all-disclosures")
    if (data != null) {
      const parse_data = JSON.parse(data)
      console.log(SeparateObjectByValueType(parse_data),'test123_0data1234567')
      const {otherValuesObject} = SeparateObjectByValueType(parse_data)
      // setLocalStorageObjectBoleanAndNumber(otherValuesObject)
      console.log(parse_data,'test123_0')
      finalData = { ...parse_data, ...finalData }
    }
  
    if (Object.keys(filterArray).length > 0) {
      console.log(filterArray,'test123_1filterArray')
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    }
    //  else {
    //   localStorage.removeItem("selectedFilterArray")
    // }
    // if (Object.keys(filterArray).length > 0) {
    //   console.log(finalData, "finalData")
    //   localStorage.setItem("disclosures/all-disclosures/selectedFilterArray", JSON.stringify(finalData))
    // } 
    if (Object.keys(finalData).length > 0) {
      console.log(finalData,'test123_2finalData')
      // console.log(finalData, "finalData")
      localStorage.setItem(
        "disclosures/all-disclosures",
        JSON.stringify(finalData)
      )
    }

    const requestData = {
      ...storageFilter,
      ...finalData,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
      orderBy: {
        ...sortBy,
      },
    }
   const newdata = encryptData(requestData);
    axios
      .post(apiUrl, {data: newdata}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
          ...HEADER
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          localStorage.removeItem("home/disclosures/all-disclosures")
          setReqresData(response.data.data)
          console.log(response.data.data,'response.data.data')
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    console.log("page is loading")
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const reg_data = [
    {
      discl_id: "DODB000640",
      discl_obg_id: "OB_011930",
      discl_title: "OCC Interagency (Miranda) Long Version_ENG",
      discl_desc:
        "Required when the FI needs to advertise investment-related material.",
      discl_link_reg: "FINRA",
      discl_spcfc_url:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      discl_type: "Text",
      discl_citation: "47 CFR 64.1200(d)(4), FINRA 3230(d)(4) ",
      status: "Approved",
      lang: "English",
      discl_inv: "Yes",
      discl_dep: "Yes",
      discl_auto: "No",
      discl_mort: "Yes",
      discl_card: "No",
      discl_other: "Yes",
      life_markt: "Yes",
      life_serv: "Yes",
      life_open: "Yes",
      life_close: "Yes",
      comm_types:
        "Account Opening Agreements for Products with Networking Arrangements ",
    },
    {
      discl_id: "DODB000650",
      discl_obg_id: "OB_011280",
      discl_title: "TCPA PEWC for ATDS/PRMs for Phone ",
      discl_desc:
        "Must be provided to obtain the prior express written consent (PEWC) for Telemarketing or advertising calls made using an ATDS to cell phones, and PRMs to cell phones and residential lines.",
      discl_link_reg: "FINRA",
      discl_spcfc_url:
        "https://www.ecfr.gov/current/title-47/chapter-I/subchapter-B/part-64#p-64.1200(a) ",
      discl_type: "Text",
      discl_citation: "47 CFR 64.1200(a)(7)(i), FINRA 3230(j)(2)(C)",
      status: "Approved",
      lang: "English",
      discl_inv: "No",
      discl_dep: "Yes",
      discl_auto: "No",
      discl_mort: "Yes",
      discl_card: "No",
      discl_other: "Yes",
      life_markt: "Yes",
      life_serv: "Yes",
      life_open: "Yes",
      life_close: "Yes",
      comm_types:
        "All Scripts utilized by telemarketing staff during outbound calls",
    },
    {
      discl_id: "DODB006180",
      discl_obg_id: "OB_011930",
      discl_title: "OCC PB and Securities Interagency and USP (Long)",
      discl_desc:
        "Required when the FI needs to present both the OCC Interagency and Service Provider Disclosures.",
      discl_link_reg: "FINRA",
      discl_spcfc_url:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/2214",
      discl_type: "Text",
      discl_citation: "FINRA 2214(c)",
      status: "Approved",
      lang: "English",
      discl_inv: "Yes",
      discl_dep: "Yes",
      discl_auto: "No",
      discl_mort: "Yes",
      discl_card: "No",
      discl_other: "Yes",
      life_markt: "Yes",
      life_serv: "Yes",
      life_open: "Yes",
      life_close: "Yes",
      comm_types:
        "Account Applications, Consent to contact customer forms, Call scripts",
    },
    {
      discl_id: "DODB000650",
      discl_obg_id: "OB_011280",
      discl_title: "TCPA PEWC for ATDS/PRMs for Phone ",
      discl_desc:
        "Must be provided to obtain the prior express written consent (PEWC) for Telemarketing or advertising calls made using an ATDS to cell phones, and PRMs to cell phones and residential lines.",
      discl_link_reg: "FINRA",
      discl_spcfc_url:
        "https://www.ecfr.gov/current/title-47/chapter-I/subchapter-B/part-64#p-64.1200(a) ",
      discl_type: "Text",
      discl_citation: "47 CFR 64.1200(a)(7)(i), FINRA 3230(j)(2)(C)",
      status: "Approved",
      lang: "English",
      discl_inv: "Yes",
      discl_dep: "Yes",
      discl_auto: "No",
      discl_mort: "Yes",
      discl_card: "No",
      discl_other: "Yes",
      life_markt: "Yes",
      life_serv: "Yes",
      life_open: "Yes",
      life_close: "Yes",
      comm_types:
        "All Scripts utilized by telemarketing staff during outbound calls",
    },
  ]

  const GotoObligation = (data) => {
    console.log(data, "data132132");
    if (data.length > 0) {
      let disIdArray = [];
      disIdArray = data.map(item => item.obId);
      const result = { "obId": disIdArray };
      
      console.log(result,"result");    
      localStorage.setItem("obligations/all-obligations", JSON.stringify(result))
      localStorage.setItem("obligations/all-obligations/selectedFilterArray", JSON.stringify(result))
      localStorage.setItem("selectedFilterArray", JSON.stringify(result))
      history.push({
        pathname: '/obligations/all-obligations',
        state: {
          rowData: {
            isClear: true
          }
        }
      });
    }
  }

  const contentRef = useRef(null);
 
  const setInnerText = (content) => {
    // if (contentRef.current) {
      const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    // }
  }

  const columns = [
    {
      Header: "Disclosure ID",
      accessor: "disId",
      width:124,
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/disclosure-text/disclosure_view",
              state: { rowData },
            }}
          >
            <span style={{ color: "#000" }}>{ ColumnDataWithTooltip({content:cellProps?.value })   }</span>
          </Link>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Title",
      accessor: "disclosureTitle",
      width: 250,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <LightTooltip title={cellProps?.value}><Link
            to={{
              pathname: "/disclosure-text/disclosure_view",
              state: { rowData },
            }}
          >
            <span style={{ color: "#000" }}   className="StringOverFlow1">
              

{   CheckValidValue({value:cellProps?.value})?cellProps?.value : DisplayJustifyCenter("-")  }
            </span> 
          </Link></LightTooltip>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Description",
      accessor: "disclosureDesc",
      width: 116,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value ? (
          <LightTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  // setDesc(cellProps.value)
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Disclosure Description")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
              View Details
          </UncontrolledTooltip> */}
            </div>
          </LightTooltip>
        ) : (
           DisplayJustifyCenter("-")  
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Type",
      accessor: "disclosureType",
      // width:140,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value == 1
          ? "Text"
          : cellProps.value == 2
          ? "Doc"
          : "Other"
      },
      show: true,
    },
    {
      Header: "Text",
      accessor: "text",
      // width:120,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value ? (
          <LightTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Disclosure Text")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
              View Details
          </UncontrolledTooltip> */}
            </div>
          </LightTooltip>
        ) : (
              DisplayJustifyCenter("-")  
        )
      },
      show: true,
    },
    {
      Header: "Sample",
      accessor: "sample",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return   ColumnDataWithTooltip({content:cellProps?.value })  
          
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Sample URL1",
      filterable: false,
      disableFilters: true,
      accessor: "sampleUrl1",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return cellProps.value ? (
          <div>
           {  CheckValidValue({value:cellProps?.value})? <Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                  className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {cellProps.value
                    .replaceAll("https://", "")
                    .replaceAll("http://", "")
                    .replaceAll("www.", "") }
                </div>
              </LightTooltip>
            </Link>: DisplayJustifyCenter("-") }
           
          </div>
        ) : (
          DisplayJustifyCenter("-")
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Sample URL2",
      filterable: false,
      disableFilters: true,
      accessor: "sampleUrl2",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return cellProps.value ? (
          <div>
            { CheckValidValue({value:cellProps?.value})? <Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                  className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {cellProps.value
                    .replaceAll("https://", "")
                    .replaceAll("http://", "")
                    .replaceAll("www.", "") }
                </div>
              </LightTooltip>
            </Link>: DisplayJustifyCenter("-") }
           
          </div>
        ) : (
          DisplayJustifyCenter("-")
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Sample URL3",
      filterable: false,
      disableFilters: true,
      accessor: "sampleUrl3",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return cellProps.value ? (
          <div>
            { CheckValidValue({value:cellProps?.value})?<Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                  className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {cellProps.value
                    .replaceAll("https://", "")
                    .replaceAll("http://", "")
                    .replaceAll("www.", "") }
                </div>
              </LightTooltip>
            </Link>: DisplayJustifyCenter("-") }
            
          </div>
        ) : (
          DisplayJustifyCenter("-")
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Safe Harbor (All, Part, None)",
      accessor: "safeHarbor",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value == 1
          ? "All"
          : cellProps.value == 2
          ? "Part"
          : "None"
      },
      show: true,
    },
    {
      Header: "Disclosure Notes",
      width : 118,
      accessor: "disclosureNote",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return  ColumnDataWithTooltip({content:cellProps?.value })  
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Disclaimer",
      filterable: false,
      // width:100,
      disableFilters: true,
      accessor: "disclaimer",
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure specific URL",
      filterable: false,
      disableFilters: true,
      accessor: "disclosureSpecificUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div style={{ textAlign: "center" }}>
            {cellProps.value ? (
              <Link to={{ pathname: cellProps.value }} target="_blank">
                <LightTooltip title={rowData}>
                  <div
                    className="StringOverFlow1"
                    style={{
                      textDecoration: "underline",
                      fontSize: "13px",
                      color: "blue",
                    }}
                  >
                    {cellProps.value
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "") }
                  </div>
                </LightTooltip>
              </Link>
            ) : (
              DisplayJustifyCenter("-") 
            )}
          </div>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure specific Citation",
      accessor: "disclosureSpecificCitation",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return ColumnDataWithTooltip({content:cellProps?.value })  
      },
      show: true,
    },
    {
      Header: "Prod Group Inv",
      accessor: "prodGroupInvestments",
      // width:90,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Prod Group Dep",
      accessor: "prodGroupDeposits",
      // width:90,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Prod Group Auto",
      accessor: "prodGroupAuto",
      // width:100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Pro Group Mort",
      accessor: "prodGroupMortgage",
      // width:90,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Pro Group Card",
      accessor: "prodGroupCard",
      // width:90,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Prod Group Other",
      accessor: "prodGroupOther",
      // width:100,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Life - Marketing",
      accessor: "lifeMarketing",
      width:110,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Life - Open Onboard",
      accessor: "lifeOpenOnboard", 
      width:98,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Life - Servicing",
      accessor: "lifeServicing",
      width:102,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Life - Close-out End",
      accessor: "lifeCloseOutEnd",
      width: 91,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ paddingLeft: 15, fontSize: "16px" }}>
            {cellProps.value == false ? DisplayJustifyCenter("-") : DisplayJustifyCenter("Y") }
          </div>
        )
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "Communication Types",
      accessor: "communicationTypes",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return ColumnDataWithTooltip({content:cellProps?.value })  
      },
      show: checkOrg === 3 ? true : false,
    },
    {
      Header: "# of Linked Obligations",
      accessor: checkOrg !== undefined && checkOrg === 3 ? "obligations" : "custObligations",
      width:117,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div style={{ textAlign: "center", cursor: cellProps?.value?.length > 0 ? "pointer" : "default" }} onClick={()=>GotoObligation(cellProps.value)}>
            {cellProps.value && cellProps?.value?.length > 0
              ? cellProps?.value?.length
              : "0"}
          </div>
        )
      },
      show: true,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 95,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <Badge
            className={
              cellProps.value === 2
                ? "font-size-11 badge-soft-success"
                : cellProps.value == 3
                ? "font-size-11 badge-soft-danger"
                : "font-size-11 badge-soft-warning"
            }
          >
            {cellProps.value == 1
              ? "Pending"
              : cellProps.value == 2
              ? "Approved"
              : "Removed"}
          </Badge>
        )
      },
      show: true,
    },
    {
      Header: "Last Update Date",
      width : 96,
      accessor: "updatedDate",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const date1 =  FormatedDate(cellProps.value)   
        if (cellProps.value) {
          return date1
        } else {
          return "-"
        }
      },
      show: true,
    },
    {
      Header: "Effective Until Date",
      accessor: "effectiveUntilDate",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const date1 =FormatedDate(cellProps.value)
        if (cellProps.value) {
          return date1
        } else {
          return "-"
        }
      },
      show: true,
    },
    {
      Header: "Action",
      filterable: false,
      sticky: "right",
      width: 180,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "1.2rem" }}
          >
            {/* <LightTooltip  */}
            {disclosureAccess &&
            disclosureAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    to={{
                      pathname: "/disclosures/disclosure_update",
                      state: { rowData },
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}
            {disclosureAccess &&
            disclosureAccess.writeAccess === true &&
            checkOrg !== 3 ? (
              <DarkTooltip title="Edit">
                <Badge className="font-size-15 badge-soft-success" pill>
                  <i
                    //   onClick={() => handleView(value)}
                    onClick={() => {
                      setAddModal(true)
                      setTextId(rowData)
                    }}
                    style={{ color: "#34C38F", cursor: "pointer" }}
                    className="bx bx-edit-alt  font-size-18"
                    // id="edittooltip"
                  ></i>
                </Badge>
              </DarkTooltip>
            ) : (
              ""
            )}

            {disclosureAccess && disclosureAccess.readAccess === true ? (
              <Badge className="font-size-15  badge-soft-primary" pill>
                <DarkTooltip title="View">
                  <Link
                    to={{
                      pathname: "/disclosure-text/disclosure_view",
                      state: { rowData },
                    }}
                    onClick={() => {
                      const orderData = cellProps.row.original
                      // console.log(orderData, 'data')
                      // handleOrderClick(orderData);
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "blue", cursor: "pointer" }}
                      className="mdi mdi-eye-outline  font-size-18"
                      id="customerViewtooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}

            {disclosureAccess &&
            disclosureAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                {/* <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                > */}
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            ) : (
              ""
            )}
            {disclosureAccess && disclosureAccess.readAccess === true && cellProps.row.original && checkOrg !== undefined && checkOrg === 3 ? cellProps.row.original.obligations.length > 0 ? (
             <Badge className="font-size-15 badge-soft-primary" pill>
             <DarkTooltip title="Linked Obligations">
                 <i
                     onClick={() => GotoObligation(cellProps.row.original.obligations)}
                   style={{ color: "blue", cursor: "pointer" }}
                   className="mdi mdi-eye-outline  font-size-18"
                   id="customerViewtooltip"
                 ></i>
             </DarkTooltip>
           </Badge>
            ) : null : cellProps.row.original.custObligations.length > 0 ? <Badge className="font-size-15 badge-soft-primary" pill>
            <DarkTooltip title="Linked Obligations">
                <i
                    onClick={() => GotoObligation(cellProps.row.original.custObligations)}
                  style={{ color: "blue", cursor: "pointer" }}
                  className="mdi mdi-eye-outline  font-size-18"
                  id="customerViewtooltip"
                ></i>
            </DarkTooltip>
          </Badge>: null}
          </ul>
        )
      },
      show: true,
    },
  ]

  const filteredColumns = columns.filter(column => column.show)

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }

  const reports = [
    {
      title: "Total Disclosures",
      iconClass: "bx-copy-alt",
      description: totalItems,
    },
    { title: "New Disclosures", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Disclosure Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Disclosure Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  document.title = "Disclosures"
  const handleRender = () => {
    setDelete(!is_delete)
  }

  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }

  const handleImportData = e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append(
        "custOrgId",
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : ""
      )
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/import-customer-disclosure-text`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            uploadToggle()
          }
        })
        .catch(err => {
          var message
          uploadToggle()
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  // useEffect(() => {
  //   const authToken = JSON.parse(localStorage.getItem('authUser'))
  //   axios.get(`${process.env.REACT_APP_BASE_URL}get-all-disclosures`,{
  //     headers: {
  //       'Authorization':`Bearer ${authToken.data.authToken}`
  //     }
  //   })
  //   .then(response => {
  //     // console.log('res',response.data)
  //     if (response.status >= 200 || response.status <= 299) {
  //     // return response.data;
  //     setSelectDisclosure(response.data.data.map(option => ({
  //       value: option.id,
  //       // label: `${option.disId} + ${option.id}`
  //       label: `${option.disId} (${option.disclosureTitle && option.disclosureTitle.length > 8
  //         ? option.disclosureTitle.substr(0, 45) + " ..."
  //         : option.disclosureTitle ||'-'
  //       })`

  //       // label: option.disId
  //     })))
  //     // setTotalItems(response.data.totalRecords)
  //     // setLoader(false)
  //   }
  //     // throw response.data;
  //   })
  //   .catch(err => {
  //     var message;
  //     if (err.response && err.response.status) {
  //       switch (err.response.status) {
  //         case 404:
  //           message = "Sorry! Network Error(Status:404)";
  //           break;
  //         case 500:
  //           message =
  //             "Sorry! something went wrong, please contact our support team, Status-500   ";
  //           break;
  //         case 401:
  //           message = "You are not authorized to view this data.";
  //           break;
  //         default:
  //           message = err[1];
  //           break;
  //       }
  //     }
  //     throw message;
  //   });
  // },[])

  // console.log(textId, "textId")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      text: textId && textId.text,
    },
    validationSchema: Yup.object({
      text: Yup.string().required("Please Enter Disclosure Text"),
    }),
    onSubmit: values => {
      console.log(values, "values")

      const addData = {
        id: textId && textId.id,
        text: values.text,
      }
      console.log("addData", addData)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}update-disclosure-organization`,
          {data:encryptData(addData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
              ...HEADER
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // setSuccess(true)
            toastr.success("Disclosure Successfully Updated.")
            setDelete(!is_delete)
            setAddModal(false)
            validation.resetForm()
            return response.data
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          let message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = "Bad request! Already Linked"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          // return message
          // throw message;
           ToastError(err)
        })
    },
  })


  const editorConfig = {
    removePlugins: [
      // "ImageCaption",
      // "ImageStyle",
      // "ImageToolbar",
      // "ImageUpload",
      // "MediaEmbed",
      // "EasyImage",
      // "UploadImage",
    ],
    toolbar: [
      'heading', '|',
      'bold', 'italic', 'underline', '|',
      'fontColor', 'fontSize', '|',
      'bulletedList', 'numberedList', '|',
      'indent', 'outdent', '|',
      'link', '|',
      'undo', 'redo'
    ],
    fontSize: {
      options: [10, 12, 14, 16, 18, 20, 'default'],
    },
    fontColor: {
      colors: [
          {
              color: 'hsl(0, 0%, 0%)',
              label: 'Black'
          },
          {
              color: 'hsl(0, 0%, 30%)',
              label: 'Dim grey'
          },
          {
              color: 'hsl(0, 0%, 60%)',
              label: 'Grey'
          },
          {
              color: 'hsl(0, 0%, 90%)',
              label: 'Light grey'
          },
          {
              color: 'hsl(0, 0%, 100%)',
              label: 'White',
              hasBorder: true
          },
          // More colors.
          // ...
      ]
  },
  fontBackgroundColor: {
      colors: [
          {
              color: 'hsl(0, 75%, 60%)',
              label: 'Red'
          },
          {
              color: 'hsl(30, 75%, 60%)',
              label: 'Orange'
          },
          {
              color: 'hsl(60, 75%, 60%)',
              label: 'Yellow'
          },
          {
              color: 'hsl(90, 75%, 60%)',
              label: 'Light green'
          },
          {
              color: 'hsl(120, 75%, 60%)',
              label: 'Green'
          }, 
      ]}
  //   fontFamily: {
  //     options: [
  //         'default',
  //         'Ubuntu, Arial, sans-serif',
  //         'Ubuntu Mono, Courier New, Courier, monospace'
  //     ]
  // },

  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],
    // ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, ], //{ 'list': 'check' }
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] },],    // { 'background': [] }      // dropdown with defaults from theme 
    // [{ 'font': [] }],
    [{ 'align': [] }],
  
    // ['clean']                                         // remove formatting button
  ];
 
  const Test = () =>{
    console.log("filterArray test123===>",filterArray)
    console.log("searchObject test123===>",searchObject)

  }
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Disclosures" breadcrumbItem="Disclosures" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <div className="extraModelWidth">
          <Modal
            isOpen={modal}
            // size={"xl"}
            toggle={toggle}
            className="modal-dialog-centered extraModelWidth"
            style={{ width: "800px" }}
          >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody className="text-left">
              {/* <div style={{ whiteSpace: "break-spaces" }} dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                OK
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal> 
          </div>
          <TextEditor
            id={textId.id}
            content={textId.text}
            setAddModal={setAddModal}
            setDelete={setDelete}
            navigateTo={navigateTo}
            closeAddModal={closeAddModal}
            addToggle={addToggle} 
            addModal={addModal}
            is_delete={is_delete}
          /> 
{/*  
          <Modal
            isOpen={addModal}
            // toggle={toggle}
            toggle={addToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={closeAddModal}>
              Update Disclosure Text
            </ModalHeader>
            <ModalBody className="text-left">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label" onClick={()=>console.log("validation123",validation.values.text)}>
                          Update Disclosure Text
                        </Label>
                       

                         
                        <ReactQuill 
                         modules={QuilRichEditorModules}
                        
                        theme="snow" 
                        value={validation.values.text || ""} 
                        // value={`<p>Initial &nbsp; Value</p>`} 
                        onChange={(data)=>{validation.setFieldValue("text", data)}} 
                        />
                        {validation.touched.text && validation.errors.text ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.text}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button type="submit" className="me-2" color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={closeAddModal}
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form>
            </ModalBody>
          </Modal>*/}
          <Modal
            isOpen={uploadModal}
            toggle={uploadToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={uploadToggle}>
              Import Disclosure Texts
            </ModalHeader>
            <ModalBody className="px-4 py-4 text-left">
              <h6>Select File</h6>
              <div className="form-body mt-3">
                <input
                  type="file"
                  id="csvFileInput"
                  accept=".xlsx"
                  name="file"
                  onChange={onFileChange}
                  className="form-control mb-2"
                />
                <div className="error">{errors.file}</div>
              </div>
              <div className="px-6 mt-3 mb-1 text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  name="button"
                  onClick={e => handleImportData(e)}
                >
                  Import Data
                </button>
              </div>
            </ModalBody>
          </Modal>
          {/* <Row>
            {reports.map((report, key) => (
              <Col className="col-xl-3 col-md-6" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody
                    className="custome-card"
                    style={{ padding: "10px 11px !important" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-muted">{report.title}</h6>
                      </div>
                      <div
                        className="avatar-sm bg-primary align-self-center mini-stat-icon"
                        style={{ borderRadius: "100%" }}
                      >
                        <span
                          className="avatar-title bg-primary"
                          style={{ borderRadius: "100%" }}
                        >
                          <p className="mb-0 font-size-18">
                            {report.description}
                          </p>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row>
            <Col xs="12">
              <Card> 
                <CardBody className="table-class">
                  <TableContainer
                    columns={filteredColumns}
                    props={props}
                    setAddModal={setAddModal}
                    addModal={addModal}
                    setUploadModal={setUploadModal}
                    uploadModal={uploadModal}
                    filterArray={filterArray}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    checkOrg={checkOrg}
                    checkAdmin={checkAdmin}
                    setCheckOrg={setCheckOrg}
                    disclosureAccess={disclosureAccess}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    setFilterArray={setFilterArray}
                    setSearchObject={setSearchObject}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    // dateQuery={dateQuery}
                    data={reqresData}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    localStorageObjectBoleanAndNumber={localStorageObjectBoleanAndNumber}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                       
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

Disclosures.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Disclosures)
