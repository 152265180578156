import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Badge, Button } from "reactstrap"
import Tooltip from "@material-ui/core/Tooltip"
import { Link } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"
import { deleteRegulator as onDeleteRegulator } from "../../store/actions"
import { useDispatch } from "react-redux"
import { CheckValidValue, FormatedDate } from "helpers/helper_function"

const RegulatorView = props => {
  const location = useLocation()
  const [delRegulator, setDelRegulator] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const [checkOrg, setCheckOrg] = useState()
  const [regulatorAccess, setRegulatorAccess] = useState([])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setRegulatorAccess(authToken && authToken.data.role.rolesPermission[0])
  }, [])
  const dispatch = useDispatch()

  document.title = "Regulators"
  const data = location.state.rowData
  let rowData = data
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const editRegulator = rowData => {
    console.log({ rowData })
    props.history.replace({
      pathname: "/regulators/regulator_update",
      state: { rowData },
    })
  }
  const onClickDelete = rowData => {
    setDelRegulator(rowData)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    setDelete(!is_delete)
    const deleteId = delRegulator.id
    if (deleteId) {
      dispatch(onDeleteRegulator(delRegulator.id, props.history))
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Regulator Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
                <ul className="nav nav-pills" style={{ justifyContent: "end" }}>
                  {regulatorAccess &&
                  regulatorAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => editRegulator(rowData)}
                        style={{
                          backgroundColor: "#556ee6",
                          // marginLeft: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                        Edit
                      </Button>
                    </li>
                  ) : (
                    ""
                  )}
                  {regulatorAccess &&
                  regulatorAccess.writeAccess &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => {
                          onClickDelete(rowData)
                        }}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          marginRight: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                        Delete
                      </Button>
                      <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteOrder}
                        onCloseClick={() => setDeleteModal(false)}
                      />
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <div className="row">
                      <div className="col-12 col">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className=""></h4>
                          <div
                            className="page-title-right"
                            style={{ marginTop: "7px" }}
                          >
                            <ol className="breadcrumb m-0">
                              <li className="breadcrumb-item">
                                <Link to={{pathname:"/regulators/all-regulators", state: { rowData: {isClear: true} }}}>
                                  Regulators
                                </Link>
                              </li>
                              <li
                                className="active breadcrumb-item"
                                aria-current="page"
                              >
                                Regulator Details
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Row>
                        <Col md={4} className="px-4">
                        <h5 className="mb-sm-0 font-size-18">
                        Regulator Details
                      </h5>
                        </Col> */}
                  {/*<Col md={8} className="d-flex flex-wrap gap-2 justify-content-end">*/}
                  {/*    <button className="btn btn-primary">*/}
                  {/*    <i className="bx bx-printer font-size-16 align-middle me-1"></i>*/}
                  {/*        Print*/}
                  {/*    </button>*/}
                  {/*</Col>*/}
                  {/* </Row> */}
                  <div className="table-responsive px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Law Short Name</th>
                          <td style={{ width: "75%" }}>
                            {data && data.regulatorShortName == null
                              ? "-"
                              : data.regulatorShortName}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Long Name</th>
                          <td style={{ width: "75%" }}>
                             {CheckValidValue({value:data?.regulatorLongName}) ?data?.regulatorLongName:"-" }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Description</th>
                          <td style={{ width: "75%" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:   CheckValidValue({value:data.regulatorDesc})?data.regulatorDesc:"-"  ,
                              }}
                            ></div>
                            {/* {data && data.regulatorDesc} */}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Main URL</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({value:data?.regulatorMainUrl})?data?.regulatorMainUrl:"-" }
                          </td>
                        </tr>
                        {/* <tr>
                          <th style={{ width: "15%" }}>
                            Regulator Update Source
                          </th>
                          <td style={{ width: "75%" }}>
                           
                            {CheckValidValue({value: data?.regulatorUpdateSource}) ?data?.regulatorUpdateSource : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Regulator Update Contact
                          </th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({value: data?.regulatorUpdateContact}) ?data.regulatorUpdateContact : "-"}
                          </td>
                        </tr> */}
                        <tr>
                          <th style={{ width: "15%" }}>
                            Regulator Last Update Date
                          </th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.updatedDate || '-'} */}
                            {data && data.updatedDate
                              ?FormatedDate(data && data.updatedDate)  
                               
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulator Country</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({value:data?.country})?data?.country:"-" }
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulator State</th>
                          <td style={{ width: "75%" }}>{CheckValidValue({value:data?.state})?data?.state:"-" }</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulator County</th>
                          <td style={{ width: "75%" }}>
                            {CheckValidValue({value:data.county})? data.county: "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegulatorView
