import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import jobsSaga from "./jobs/saga"
import contactsSaga from "./contacts/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import regulatorsSaga from "./regulators/saga"
import regulationsSaga from "./regulations/saga"
import disclosuresSaga from "./disclosures/saga"
import obligationsSaga from "./obligations/saga"
import linksSaga from "./link/saga"
import obsOptionsSaga from "./OrgOptions/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(chatSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(jobsSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(regulatorsSaga),
    fork(regulationsSaga),
    fork(disclosuresSaga),
    fork(obligationsSaga),
    fork(linksSaga),
    fork(obsOptionsSaga),
  ])
}
