import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"

const PieChart = ({ dataColors, series }) => {
  const PieApexChartColors = getChartColorsArray(dataColors)

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    labels: ['Link', 'Unlink'],
    colors: PieApexChartColors,
    legend: {
      show: false,
      position: "center",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 100,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -10,
          enabled: false,
      }
    }
    },
    // dataLabels: {
    //   enabled: true 
    // },
    tooltip: {
      enabled: true,
    }
  };
  
  return (
    <ReactApexChart options={options} series={series} style={{ minWidth: 260 }} type="pie" /> 
  )
}

export default PieChart
