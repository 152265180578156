import React from 'react'
import AuthorReviewDetail from './components/AuthorReviewDetail'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import AuthorUnlikeModal from './components/AuthorUnlikeModal'
import AuthorUpdateModal from './components/AuthorUpdateModal'

const AuthorReviewDetailList = () => {
  document.title = "Author Review Summary"
  return (
    <div className="page-content">
      <div className="container-fluid">
       {/* <Breadcrumbs
            title="Reg Content Change Report"
            // breadcrumbItem={updateTitle}
            path="reg-review-report"
            breadcrumbItem={`Review Details`}
          />  */}
        <AuthorUnlikeModal/>
        <AuthorUpdateModal/>
        <AuthorReviewDetail/>
      </div>
    </div>
  )
}

export default AuthorReviewDetailList