import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { setOpenAuthorModal, setReviewDetail } from 'store/slices/authorReviewSlice'
// import { setOpenLinkDisclosure, setReviewDetail } from 'store/slices/workFlowReportSlice'


const AuthorReviewModal = () => {
  const {openAuthor, dashboardData} = useSelector((state) => state.AuthorReview || [])
  const [review, setReview] = useState("")
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch(setReviewDetail(review))
    dispatch(setOpenAuthorModal(false))
  }
  return (
    <div>
      <Modal
        isOpen={openAuthor}
        toggle={toggle}
        className="modal-dialog-centered"
        style={{ borderRadius: "50px", width:'28%' }}
        >
        <ModalHeader>
          <b style={{fontSize:'16px'}}>Link with Disclosure For All</b>
        </ModalHeader>
        <ModalBody className="text-left">
         <FormControl style={{width:'100%',}}>
         <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="all"
          name="radio-buttons-group"
        >
          {dashboardData.reviewDetails && dashboardData.reviewDetails.length > 0 ? (
            dashboardData.reviewDetails.map((detail, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <FormControlLabel onChange={(e) => setReview(e.target.value)} value={detail.value} control={<Radio />} label={detail.label} />
                <b style={{ fontSize: '16px' }}>{detail.count}</b>
              </div>
            ))
          ) : (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <b style={{ fontSize: '16px' }}>No data found</b>
            </div>
          )}
        </RadioGroup>
          </FormControl>
         </ModalBody>
         <ModalFooter className="mx-auto">
            <Button className="px-4 rounded-pill" style={{background:'#B6B6B6',
             color:'black', border:'none'}} onClick={toggle} >
                Cancel
            </Button>
            <Button className="px-4 rounded-pill" color="primary" 
                onClick={toggle}
            >
               Continue
            </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AuthorReviewModal