import axios from "axios"
import { QuilRichEditorModules } from "helpers/general_constant"
import { RedirectOnUnAuthentication, ToastError, ToastSuccess, encryptData } from "helpers/helper_function"
import React, { useEffect, useState } from "react"
import ReactQuill from "react-quill"
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

const TextEditor = ({
  id,
  content,
  setAddModal,
  setDelete,
  navigateTo,
  closeAddModal,
  addToggle, addModal,is_delete
}) => {
  const [fieldValue, setFieldValue] = useState("")
  const onHandleSubmit = () => { 

    const addData = {
      id: id,
      text: fieldValue,
    }
    console.log("addData", addData)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}update-disclosure-organization`,
        {data:encryptData(addData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // setSuccess(true) 
          ToastSuccess({message:"Disclosure Successfully Updated."})
          setDelete(!is_delete)
          setAddModal(false)
          return response.data
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = "Bad request! Already Linked"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        // throw message;
        ToastError(err)
      })
  }

  useEffect(() => {
    setFieldValue(content)
  }, [id])
  return (
    <>
      <Modal
        isOpen={addModal}
        // toggle={toggle}
        toggle={addToggle}
        className="modal-dialog-centered"
        style={{ borderRadius: "50px" }}
      >
        <ModalHeader toggle={closeAddModal}>Update Disclosure Text</ModalHeader>
        <ModalBody className="text-left">
          <div
            className="form-horizontal" 
          >
            <div className="form-group">
              <Row>
                <Col xs="12">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      onClick={() =>
                        console.log("validation123", validation.values.text)
                      }
                    >
                      Update Disclosure Text
                    </Label>
 {/* <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.text || ""}
                          // config={editorConfig}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "300px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log(data)
                            validation.setFieldValue("text", data)
                          }}
                        /> */}
                    <ReactQuill
                      modules={QuilRichEditorModules}
                      theme="snow"
                      value={fieldValue || ""}
                      // value={`<p>Initial &nbsp; Value</p>`}
                      onChange={data => {
                        setFieldValue( data)
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <Col xs="12" md="12">
              <div className="text-end">
                <Button onClick={onHandleSubmit} className="me-2" color="primary">
                  Submit
                </Button>
                <Button
                  onClick={closeAddModal}
                  className="btn btn-secondary waves-effect waves-light "
                  color="secondnary"
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default TextEditor
