import {
  GET_REGULATIONS,
  GET_REGULATIONS_SUCCESS,
  GET_REGULATIONS_FAIL,
  ADD_NEW_REGULATION,
  ADD_REGULATION_SUCCESS,
  ADD_REGULATION_FAIL,
  UPDATE_REGULATION,
  UPDATE_REGULATION_SUCCESS,
  UPDATE_REGULATION_FAIL,
  DELETE_REGULATION,
  DELETE_REGULATION_SUCCESS,
  DELETE_REGULATION_FAIL,
} from "./actionTypes"

export const getRegulations = urlData => ({
  type: GET_REGULATIONS,
  urlData,
})

export const getRegulationsSuccess = data => ({
  type: GET_REGULATIONS_SUCCESS,
  payload: data,
})

export const getRegulationsFail = error => ({
  type: GET_REGULATIONS_FAIL,
  payload: error,
})

export const addNewRegulation = (regulation, history) => ({
  type: ADD_NEW_REGULATION,
  payload: { regulation, history },
})

export const addRegulationSuccess = regulation => ({
  type: ADD_REGULATION_SUCCESS,
  payload: regulation,
})

export const addRegulationFail = error => ({
  type: ADD_REGULATION_FAIL,
  payload: error,
})

export const updateRegulation = (regulation, history) => ({
  type: UPDATE_REGULATION,
  payload: { regulation, history },
})

export const updateRegulationSuccess = regulation => ({
  type: UPDATE_REGULATION_SUCCESS,
  payload: regulation,
})

export const updateRegulationFail = error => ({
  type: UPDATE_REGULATION_FAIL,
  payload: error,
})

export const deleteRegulation = (regulation, history) => ({
  type: DELETE_REGULATION,
  payload: { regulation, history },
})

export const deleteRegulationSuccess = regulation => ({
  type: DELETE_REGULATION_SUCCESS,
  payload: regulation,
})

export const deleteRegulationFail = error => ({
  type: DELETE_REGULATION_FAIL,
  payload: error,
})
