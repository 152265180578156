import React, { useState, useEffect } from "react"
import axios from "axios"
import toastr from "toastr"
import { Spinner } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, ToastError, encryptData } from "helpers/helper_function"

const StackedColumnChart = ({ tabIndex, checkOrg, customePageSize }) => {
  const [regulationsData, setRegulationsData] = useState(
    JSON.parse(localStorage.getItem("formattedData")) || []
  )
  const [tabIn, setTabIn] = useState([])
  const [series, setSeries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [resData, setResData] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const [sortBy, setSortBy] = useState({
    tableName: "regulation",
    fieldName: "",
    order: "DESC",
  })

  useEffect(() => {
    let fldName
    if (tabIndex === 0) {
      fldName = "obligations"
    } else if (tabIndex === 1) {
      fldName = "disclosures"
    } else if (tabIndex === 2) {
      fldName = "combinations"
    } else if (tabIndex === 3) {
      fldName = "disclosures"
    }
    setSortBy({
      tableName: "regulation",
      fieldName: fldName,
      order: "DESC",
    })
  }, [tabIndex])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    //  if (regulationsData.length == 0 || regulationsData == "undefined") {
    if (checkOrg !== undefined) {
      setIsLoading(true) // Start loading

      const requestData = {
        customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
        orderBy: {
          ...sortBy,
        },
      }
      const newdata = encryptData(requestData);
      axios
        .post(
          `${
            process.env.REACT_APP_BASE_URL
          }get-dashboard-regulations/?limit=${customePageSize}&pageNo=${1}`,
          {data: newdata},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            // console.log(response.data.data, "response");
            // const sortDesc = response.data.data
            // const sorted = sortDesc.sort((a, b) => b.disclosures - a.disclosures);
            // console.log(sorted, "sorted")
            // const formattedData = sorted.map(regulation => ({
            //   name: regulation.regShortName,
            //   data: [
            //     parseInt(regulation.obligations),
            //     parseInt(regulation.disclosures),
            //     parseInt(regulation.combinations),
            //     // Add more values here as needed
            //   ],
            // }));
            setResData(response.data.data)
            // localStorage.setItem("formattedData", JSON.stringify(formattedData))
          } else {
        
            toastr.error("Error fetching data")
          }
        })
        .catch(err => {
          let message = "An error occurred while fetching data."
          RedirectOnUnAuthentication(err,navigateTo)
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error (Status: 404)"
                break
              case 500:
                message =
                  "Sorry! Something went wrong, please contact our support team (Status: 500)"
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = "An error occurred."
                break
            }
          }
           ToastError(err)
        })
        .finally(() => {
          setIsLoading(false) // Stop loading
        })
    }
  }, [checkOrg, customePageSize, sortBy])

  useEffect(() => {
    // const sortDesc = response.data.data
    if (resData) {
      let sorted
      if (tabIndex === 0) {
        sorted = resData.sort((a, b) => b.obligations - a.obligations)
      } else if (tabIndex === 1) {
        sorted = resData.sort((a, b) => b.disclosures - a.disclosures)
      } else if (tabIndex === 2) {
        sorted = resData.sort((a, b) => b.combinations - a.combinations)
      } else {
        sorted = resData.sort((a, b) => b.disclosures - a.disclosures)
      }

      console.log(sorted, "sorted")
      const formattedData = sorted.map(regulation => ({
        name: regulation.regShortName,
        data: [
          parseInt(regulation.obligations),
          parseInt(regulation.disclosures),
          parseInt(regulation.combinations),
          // Add more values here as needed
        ],
      }))
      setRegulationsData(formattedData)
    }
  }, [tabIndex, resData])

  useEffect(() => {
    if (resData) {
      setSeries([
        {
          name: "Obligations",
          data: regulationsData.map(regulation => parseInt(regulation.data[0])),
        },
      ])
    }
  }, [resData])

  useEffect(() => {
    if (regulationsData) {
      if (tabIndex === 0) {
        setTabIn(["#34c38f"])
      } else if (tabIndex === 1) {
        setTabIn(["#556ee6"])
      } else if (tabIndex === 2) {
        setTabIn(["#f46a6a"])
      } else {
        setTabIn(["#34c38f", "#556ee6", "#f46a6a"])
      }
    }
  }, [tabIndex, regulationsData])

  useEffect(() => {
    if (resData && regulationsData) {
      if (tabIndex !== 3) {
        const sortBy = regulationsData.map(regulation =>
          parseInt(regulation.data[tabIndex])
        )
        setSeries([
          {
            name: "Total",
            data: sortBy.sort(function (a, b) {
              return b - a
            }),
          },
        ])
      } else {
        setSeries([
          {
            name: "Obligations",
            data: regulationsData.map(regulation =>
              parseInt(regulation.data[0])
            ),
          },
          {
            name: "Disclosures",
            data: regulationsData.map(regulation =>
              parseInt(regulation.data[1])
            ),
          },
          {
            name: "Combinations",
            data: regulationsData.map(regulation =>
              parseInt(regulation.data[2])
            ),
          },
        ])
      }
    }
  }, [tabIndex, regulationsData, resData])

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
        rangeBarGroupRows: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: true,

      style: {
        colors: ["#adb5bd"],
        fontWeight: 400,
      },

      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    // colors: ["#34c38f", "#556ee6", "#f46a6a"],
    colors: tabIn,
    // colors: [tabIndex === 0 ? "#34c38f" : tabIndex === 1 ? "#556ee6" : tabIndex === 2 ? "#f46a6a" : ""],
    xaxis: {
      categories: regulationsData.map(regulation =>
        regulation.name.length > 10
          ? regulation.name.substr(0, 20) + ".."
          : regulation.name
      ),
      labels: {
        show: true,
        formatter: function (value, { seriesIndex, dataPointIndex }) {
          // Check if the dataPointIndex is within valid bounds
          if (dataPointIndex >= 0 && dataPointIndex < regulationsData.length) {
            const categoryName = regulationsData[dataPointIndex].name
            return categoryName
          }
          return value // Return the value if dataPointIndex is invalid
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: "",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
  }

  if (isLoading) {
    return (
      <div
        className="container-fluid mt-5 mb-5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner className="ms-2" color="primary" />
      </div>
    )
  }

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </>
  )
}

export default StackedColumnChart
