import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"
import toastr from "toastr"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
import LatestTranaction from "./LatestTranaction"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import RagulationCountTable from "./DashboardTable/RagulationCountList/RagulationCountTable"
import ScanCountTable from "./DashboardTable/ScanCountList/ScanCountTable"
import { RedirectOnUnAuthentication, RemoveFalseElementsFromArray, ToastError, encryptData } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { ExportDisclosureReadWriteAccess, ExportDownloadAccess, ExportIdUserRole1, ExportLinkReadWriteAccess, ExportObligationReadWriteAccess, ExportRegulationReadWriteAccess } from "components/VerticalLayout/SidebarContent"
import { HEADER } from "helpers/url_helper"

const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [regulationCount, setRegulationCount] = useState(0)
  const [obligationCount, setObligationCount] = useState(0)
  const [disclosureCounte, setDisclosureCount] = useState(0)
  const [linkCount, setLinkCount] = useState(0)
  const [comboCount, setComboCount] = useState(0)
  const [tabIndex, setTabIndex] = useState(0)
  const [tabColor, setTabColor] = useState("")
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [checkOrg, setCheckOrg] = useState()
  const [customePageSize, setCustomePageSize] = useState(10)
  const [accessData, setAccessData] = useState({
    ObligationReadWriteAccess:false,
    DisclosureReadWriteAccess:false,
    RegulationReadWriteAccess:false,
    LinkReadWriteAccess:false,
    DownloadAccess:false,
    UserRole1:10000
 
   })
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (checkOrg !== undefined) {
      const requestData = {
        customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      }
      const newdata = encryptData(requestData);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/get-dashboard-counts`,
          {
            data: newdata,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
              ...HEADER
            },
          }
        )
        .then(response => {
          // console.log('res',response.data)
          if (response.status >= 200 || response.status <= 299) {
            console.log(response.data.data.disclosures, "reponse")
            setRegulationCount(response.data.data.regulations)
            setObligationCount(response.data.data.obligations)
            setDisclosureCount(response.data.data.disclosures)
            setLinkCount(response.data.data.linkDisclosures)
            setComboCount(response.data.data.combinations)
            // return response.data;
            // setTotalItems(response.data.totalRecords)
            // setLoader(false)
          }
          // throw response.data;
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
           ToastError(err)
          throw message
        })
    }
  }, [checkOrg])
  useEffect(() => {
    setTabColor(
      [
        { tab: 0, color: "#34c38f" },
        { tab: 1, color: "#556ee6" },
        { tab: 2, color: "#f46a6a" },
        { tab: 3, color: "#ffffff" },
      ].filter(i => i.tab == tabIndex)[0]?.color
    )
  }, [tabIndex])
  const reports = RemoveFalseElementsFromArray([
    
    ...[accessData.RegulationReadWriteAccess&&{
      title: "Total Regulations",
      iconClass: "bx-copy-alt",
      description: regulationCount,
    }],
    ...[accessData.ObligationReadWriteAccess&& {
      title: "Total Obligations",
      iconClass: "bx-archive-in",
      description: obligationCount,
    }],
    ...[accessData.DisclosureReadWriteAccess&& {
      title: "Total Disclosures",
      iconClass: "bx-purchase-tag-alt",
      description: disclosureCounte,
    }],
    ...[accessData.LinkReadWriteAccess&& {
      title: "Linked Disclosures",
      iconClass: "bx-copy-alt",
      description: linkCount,
    }],
    ...[accessData.LinkReadWriteAccess&& {
      title: "Combinations",
      iconClass: "bx-archive-in",
      description: comboCount,
    }],

   
  ])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribemodal(true);
  //   }, 2000);
  // }, []);

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard"
  // if(tabIndex === 0){
  //   setTabIn(["#34c38f"])
  // }else if(tabIndex === 1) {
  //   setTabIn(["#556ee6"])
  // }else if(tabIndex === 2){
  //   setTabIn(["#f46a6a"])
  // }else {
  //   setTabIn([{tab:0,color:"#34c38f"},{tab:1,color:"#556ee6"},{tab:2,color:"#f46a6a"} ])
  // }

  const test = () => {
   
    console.log("accessData", accessData)
  }


  useEffect(()=>{
    setAccessData({
      ObligationReadWriteAccess:ExportObligationReadWriteAccess,
      DisclosureReadWriteAccess:ExportDisclosureReadWriteAccess,
      LinkReadWriteAccess:ExportLinkReadWriteAccess,
      RegulationReadWriteAccess:ExportRegulationReadWriteAccess,
      DownloadAccess:ExportDownloadAccess,
      UserRole1:ExportIdUserRole1
     })


     if (ExportObligationReadWriteAccess) {
      setTabIndex(0)
      
     } else if(ExportDisclosureReadWriteAccess){
      setTabIndex(1)
      
     }else if(ExportLinkReadWriteAccess){
      setTabIndex(3)
     }else{
      setTabIndex(-1)
     }

  },[ExportObligationReadWriteAccess,
    ExportDisclosureReadWriteAccess,
    ExportLinkReadWriteAccess,ExportRegulationReadWriteAccess,ExportDownloadAccess,ExportIdUserRole1])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            // title={props.t("Dashboards")}
            breadcrumbItem={props.t("Home")}
          /> */}

          <Row>
            <Col xl="12">
              <div className="numberGrig">
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <div
                    className="numberLable col col-xs-12"
                    key={"_col_" + key}
                  >
                    <span className="text-muted fw-medium">{report.title}</span>

                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i className={"bx " + +" font-size-24"}>
                          {report.description}
                        </i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4" onClick={test}>
                      Stats for Top Regulations
                    </h4>

                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className={`nav-item ${accessData.ObligationReadWriteAccess ? '' : 'd-none'}`}>
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 0 },
                              "nav-link"
                            )}
                            onClick={() => {
                              // onChangeChartPeriod("weekly");
                              setTabIndex(0)
                            }}
                            id="one_month"
                            style={{
                              backgroundColor: tabIndex == 0 ? `#556ee6` : "",
                              color: tabIndex == 0 ? "#ffffff" : "",
                            }}
                          >
                            Obligations
                          </Link>{" "}
                        </li>
                        <li className={`nav-item ${accessData.DisclosureReadWriteAccess ? '' : 'd-none'}`}>
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 1 },
                              "nav-link"
                            )}
                            onClick={() => {
                              // onChangeChartPeriod("monthly");
                              setTabIndex(1)
                            }}
                            id="one_month"
                            style={{
                              backgroundColor: tabIndex == 1 ? `#556ee6` : "",
                              color: tabIndex == 1 ? "#ffffff" : "",
                            }}
                          >
                            Disclosures
                          </Link>
                        </li>
                        <li className={`nav-item ${accessData.LinkReadWriteAccess ? '' : 'd-none'}`}>
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 2 },
                              "nav-link"
                            )}
                            onClick={() => {
                              // onChangeChartPeriod("yearly");
                              setTabIndex(2)
                            }}
                            id="one_month"
                            style={{
                              backgroundColor: tabIndex == 2 ? `#556ee6` : "",
                              color: tabIndex == 2 ? "#ffffff" : "",
                            }}
                          >
                            Combinations
                          </Link>
                        </li>
                        <li className={`nav-item ${(accessData.ObligationReadWriteAccess && accessData.DisclosureReadWriteAccess && accessData.LinkReadWriteAccess )? '' : 'd-none'}`}>
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === 3 },
                              "nav-link"
                            )}
                            onClick={() => {
                              // onChangeChartPeriod("all");
                              setTabIndex(3)
                            }}
                            id="one_month"
                            style={{
                              backgroundColor: tabIndex == 3 ? `#556ee6` : "",
                              color: tabIndex == 3 ? "#ffffff" : "",
                            }}
                          >
                            All
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <select
                        className="form-select"
                        value={customePageSize}
                        onChange={onChangeInSelect}
                        style={{ width: "100px" }}
                      >
                        {[10, 20, 30].map(pageSize => (
                          <option key={pageSize} value={pageSize}>
                            Top {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart
                    customePageSize={customePageSize}
                    checkOrg={checkOrg}
                    tabIndex={tabIndex}
                    periodData={periodData}
                    dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={checkOrg !== 3 ? "12" :accessData.UserRole1?"7": "12"}>
              <RagulationCountTable checkOrg={checkOrg} />
            </Col>
            <Col lg={checkOrg !== 3 ? "" : "5"} className={accessData.UserRole1?"":"d-none"}>
              {checkOrg === 3 ? <ScanCountTable accessData={accessData}/> : ""}
            </Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal)
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div className="input-group rounded bg-light">
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
