import {
  GET_LINKS,
  GET_LINKS_SUCCESS,
  GET_LINKS_FAIL,
  ADD_NEW_LINK,
  ADD_LINK_SUCCESS,
  ADD_LINK_FAIL,
  UPDATE_LINK,
  UPDATE_LINK_SUCCESS,
  UPDATE_LINK_FAIL,
  DELETE_LINK,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_FAIL,
  UPDATE_MODAL,
} from "./actionTypes"

export const updateShowModal = data => ({
  type: UPDATE_MODAL,
  payload: data,
})

export const getLinks = urlData => ({
  type: GET_LINKS,
  urlData,
})

export const getLinksSuccess = data => ({
  type: GET_LINKS_SUCCESS,
  payload: data,
})

export const getLinksFail = error => ({
  type: GET_LINKS_FAIL,
  payload: error,
})

export const addNewLink = (link, history) => ({
  type: ADD_NEW_LINK,
  payload: { link, history },
})

export const addLinkSuccess = link => ({
  type: ADD_LINK_SUCCESS,
  payload: link,
})

export const addLinkFail = error => ({
  type: ADD_LINK_FAIL,
  payload: error,
})

export const updateLink = (link, history) => ({
  type: UPDATE_LINK,
  payload: { link, history },
})

export const updateLinkSuccess = link => ({
  type: UPDATE_LINK_SUCCESS,
  payload: link,
})

export const updateLinkFail = error => ({
  type: UPDATE_LINK_FAIL,
  payload: error,
})

export const deleteLink = (link, history) => ({
  type: DELETE_LINK,
  payload: { link, history },
})

export const deleteLinkSuccess = link => ({
  type: DELETE_LINK_SUCCESS,
  payload: link,
})

export const deleteLinkFail = error => ({
  type: DELETE_LINK_FAIL,
  payload: error,
})
