import React, { useEffect, useState, useMemo } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import axios from "axios"
import { Button, Card, CardBody, Row, Col } from "reactstrap"
import CustomeTableContainer from "../../../../components/Common/CustomeTableContainer"
import Pagination from "../../../../components/Common/Pagination"
import { Link } from "react-router-dom"
import toastr from "toastr"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { DownloadExelFrom, RedirectOnUnAuthentication, RemoveFalseElementsFromArray, ToastError, encryptData } from "helpers/helper_function"
import { ExportDisclosureReadWriteAccess, ExportDownloadAccess, ExportLinkReadWriteAccess, ExportObligationReadWriteAccess, ExportRegulationReadWriteAccess } from "components/VerticalLayout/SidebarContent"
import { HEADER } from "helpers/url_helper"


 
const RagulationCountTable = ({ checkOrg }) => {
  const history = useHistory()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const [regulationData, setRegulationData] = useState([])
  const [accessData, setAccessData] = useState({
   ObligationReadWriteAccess:false,
   DisclosureReadWriteAccess:false,
   RegulationReadWriteAccess:false,
   LinkReadWriteAccess:false,
   DownloadAccess:false

  })
  const [currentPage, setCurrentPage] = useState(1)
  const [sortBy, setSortBy] = useState({
    tableName: "regulation",
    fieldName: "disclosures",
    order: "DESC",
  })

  const handleSort = column => {
    console.log(column.id, "columns")
    const isAsc = sortBy.fieldName === column.id && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"

    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName: "regulation",
        fieldName: column.id,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setLoader(true)
    if (checkOrg !== undefined) {
      const requestData = {
        customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
        orderBy: {
          ...sortBy,
        },
      }
      const newdata = encryptData(requestData);
      axios
        .post(
          `${
            process.env.REACT_APP_BASE_URL
          }get-dashboard-regulations/?limit=${10}&pageNo=${currentPage}`,
          // requestData,
          {data: newdata},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
              ...HEADER
            },
          }
        )
        .then(response => {
          if (response.status >= 200 && response.status <= 299) {
            console.log(response, "response")
            setRegulationData(response.data.data)
            setTotalItems(response.data.totalRecords)
            setLoader(false)
          } else {
            toastr.error("Error fetching data")
            
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          let message = "An error occurred while fetching data."
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error (Status: 404)"
                break
              case 500:
                message =
                  "Sorry! Something went wrong, please contact our support team (Status: 500)"
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = "An error occurred."
                break
            }
          }
           ToastError(err)
          setLoader(false)
        })
    }
  }, [currentPage, checkOrg, sortBy])

 
  const [modal1, setModal1] = useState(false)

  const [totalItems, setTotalItems] = useState(0)

  const [loader, setLoader] = useState(false)

  const toggleViewModal = () => setModal1(!modal1)

  const [btnLoader, setBtnLoader] = useState(false)

  const csvHeader = [
    "Reg Law Short Name",
    "Obligation Count",
    "Disclosure Count",
    "Combinations Count",
  ]

  const exportToExcel = rowData => {
    console.log(rowData, "selectedrows")

    const customizedData = rowData.map(row => {
      return {
        ...row,
        regShortName: row.regShortName,
        obligations: row.obligations,
        disclosures: row.disclosures,
        combinations: row.combinations,
      }
    })
    const filteredData = customizedData.map(({ id, ...rest }) => rest)

    const arrangedColumns = [
      "regShortName",
      "obligations",
      "disclosures",
      "combinations",
    ]
    const worksheet = XLSX.utils.json_to_sheet(filteredData, {
      header: arrangedColumns,
    })
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Reg Law Short Name",
          "Obligation Count",
          "Disclosure Count",
          "Combinations Count",
        ],
      ],
      { origin: "A1" }
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    })
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
    FileSaver.saveAs(blob, "Regulations Statistics.xlsx")
  }

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const columns = 
  useMemo(() => RemoveFalseElementsFromArray(
    [
    // {
    //   Header: "Reg Law Short Name",
    //   accessor: "regShortName",
    //   filterable: false,
    //   disableFilters: true,
    //   width: 220,
    //   Cell: cellProps => {
    //     const rowData = cellProps.row.original
    //     return (
    //       // <Link
    //       // to={{
    //       //   pathname: "/regulations/regulation_view",
    //       //   state: { rowData },
    //       // }}
    //       // >
    //       <span style={{ color: "#000", cursor: "pointer" }}>
    //         {cellProps.value && cellProps?.value?.length > 15
    //           ? cellProps.value && cellProps?.value?.substr(0, 30) + " ..."
    //           : cellProps.value || "-"}
    //       </span>
    //       // </Link>
    //     )
    //   },
    // },
    ...[(accessData.RegulationReadWriteAccess??false)  && {
      Header: "Reg Law Short Name",
      accessor: "regShortName",
      filterable: false,
      disableFilters: true,
      width: 220,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          // <Link
          // to={{
          //   pathname: "/regulations/regulation_view",
          //   state: { rowData },
          // }}
          // >
          <span style={{ color: "#000", cursor: "pointer" }}>
            {cellProps.value && cellProps?.value?.length > 15
              ? cellProps.value && cellProps?.value?.substr(0, 30) + " ..."
              : cellProps.value || "-"}
          </span>
          // </Link>
        )
      },
    }],

    // ...[(accessData.ObligationReadWriteAccess??false) &&  {
    //   Header: "Obligation Count",
    //   accessor: "obligations",
    //   disableFilters: true,
    //   width: 120,
    //   Cell: cellProps => {
    //     const rowData = cellProps.row.original
    //     return (
    //       <div style={{ textAlign: "center" }}>
    //         <Link
    //           // to={{
    //           //   pathname: "/obligations/all-obligations",
    //           //   state: { rowData },
    //           // }}
    //           to={""}
    //           style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
    //         >
    //           <Button
    //             type="button"
    //             color="primary"
    //             className="btn-sm btn-rounded"
    //             disabled={cellProps.value > 0 ? false : true}
    //              >
    //             {cellProps.value + " " + ">"}
    //           </Button>
    //         </Link>
    //       </div>
    //     )
    //   },
    // },],

    {
      Header: "Obligation Count",
      accessor: "obligations",
      disableFilters: true,
      width: 120,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <div style={{ textAlign: "center" }} >
            <Link
              to={{
                pathname: "obligations/all-obligations",
                state: { rowData },
              }}
              // to={""}
              style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
            >
              <Button
                onClick={()=>localStorage.setItem("home/obligations/all-obligations",JSON.stringify({regLaw:rowData?.regShortName}))}
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                disabled={cellProps.value > 0 ? false : true}
                 >
                {cellProps.value + " " + ">"}
              </Button>
            </Link>
          </div>
        )
      },
    },

    ...[(accessData.DisclosureReadWriteAccess??false) && {
      Header: "Disclosure Count",
      disableFilters: true,
      accessor: "disclosures",
      width: 120,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        console.log("test123 rowData",rowData);
        return (
          <div style={{ textAlign: "center" }}>
            <Link
              to={{
                pathname: "disclosures/all-disclosures",
                state: { rowData },
              }}
              style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
            >
              <Button
                onClick={()=>localStorage.setItem("home/disclosures/all-disclosures",JSON.stringify({disId:rowData?.disclosuresIds}))}
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                disabled={cellProps.value > 0 ? false : true}
              >
                {cellProps.value + " " + ">"}
              </Button>
            </Link>
          </div>
        )
      },
    },],
    ...[(accessData.LinkReadWriteAccess??false) &&{
      Header: "Combinations Count",
      accessor: "combinations",
      disableFilters: true,
      width: 120,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        const linkPath =
          checkOrg !== undefined && checkOrg === 3
            ? "link-table"
            : "cust-link-table"
        return (
          <div style={{ textAlign: "center" }}>
            <Link
              to={{
                pathname: linkPath,
                state: { rowData },
              }}
              style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
            >
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={()=>localStorage.setItem("home/link-table",JSON.stringify({regLaw:rowData?.regShortName}))}
                // onClick={toggleViewModal}
                disabled={cellProps.value > 0 ? false : true}
              >
                {cellProps.value + " " + ">"}
              </Button>
            </Link>
          </div>
        )
      },
    },], 
  
   
    

  ]
  // ),
  // [checkOrg,accessData]
), [checkOrg,accessData])
  // useMemo(
    // () => 
    RemoveFalseElementsFromArray(
      [
      // {
      //   Header: "Reg Law Short Name",
      //   accessor: "regShortName",
      //   filterable: false,
      //   disableFilters: true,
      //   width: 220,
      //   Cell: cellProps => {
      //     const rowData = cellProps.row.original
      //     return (
      //       // <Link
      //       // to={{
      //       //   pathname: "/regulations/regulation_view",
      //       //   state: { rowData },
      //       // }}
      //       // >
      //       <span style={{ color: "#000", cursor: "pointer" }}>
      //         {cellProps.value && cellProps?.value?.length > 15
      //           ? cellProps.value && cellProps?.value?.substr(0, 30) + " ..."
      //           : cellProps.value || "-"}
      //       </span>
      //       // </Link>
      //     )
      //   },
      // },
      ...[(accessData.RegulationReadWriteAccess??false)  && {
        Header: "Reg Law Short Name",
        accessor: "regShortName",
        filterable: false,
        disableFilters: true,
        width: 220,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            // <Link
            // to={{
            //   pathname: "/regulations/regulation_view",
            //   state: { rowData },
            // }}
            // >
            <span style={{ color: "#000", cursor: "pointer" }}>
              {cellProps.value && cellProps?.value?.length > 15
                ? cellProps.value && cellProps?.value?.substr(0, 30) + " ..."
                : cellProps.value || "-"}
            </span>
            // </Link>
          )
        },
      }],

      // ...[(accessData.ObligationReadWriteAccess??false) &&  {
      //   Header: "Obligation Count",
      //   accessor: "obligations",
      //   disableFilters: true,
      //   width: 120,
      //   Cell: cellProps => {
      //     const rowData = cellProps.row.original
      //     return (
      //       <div style={{ textAlign: "center" }}>
      //         <Link
      //           // to={{
      //           //   pathname: "/obligations/all-obligations",
      //           //   state: { rowData },
      //           // }}
      //           to={""}
      //           style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
      //         >
      //           <Button
      //             type="button"
      //             color="primary"
      //             className="btn-sm btn-rounded"
      //             disabled={cellProps.value > 0 ? false : true}
      //              >
      //             {cellProps.value + " " + ">"}
      //           </Button>
      //         </Link>
      //       </div>
      //     )
      //   },
      // },],

      {
        Header: "Obligation Count",
        accessor: "obligations",
        disableFilters: true,
        width: 120,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <div style={{ textAlign: "center" }}>
              <Link
                to={{
                  pathname: "obligations/all-obligations",
                  state: { rowData },
                }}
                // to={""}
                style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
              >
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  disabled={cellProps.value > 0 ? false : true}
                   >
                  {cellProps.value + " " + ">"}
                </Button>
              </Link>
            </div>
          )
        },
      },

      ...[(accessData.DisclosureReadWriteAccess??false) && {
        Header: "Disclosure Count",
        disableFilters: true,
        accessor: "disclosures",
        width: 120,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <div style={{ textAlign: "center" }}>
              <Link
                to={{
                  pathname: "disclosures/all-disclosures",
                  state: { rowData },
                }}
                style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
              >
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  disabled={cellProps.value > 0 ? false : true}
                >
                  {cellProps.value + " " + ">"}
                </Button>
              </Link>
            </div>
          )
        },
      },],
      ...[(accessData.LinkReadWriteAccess??false) &&{
        Header: "Combinations Count",
        accessor: "combinations",
        disableFilters: true,
        width: 120,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          const linkPath =
            checkOrg !== undefined && checkOrg === 3
              ? "link-table"
              : "cust-link-table"
          return (
            <div style={{ textAlign: "center" }}>
              <Link
                to={{
                  pathname: linkPath,
                  state: { rowData },
                }}
                style={{ pointerEvents: cellProps.value > 0 ? "" : "none" }}
              >
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  // onClick={toggleViewModal}
                  disabled={cellProps.value > 0 ? false : true}
                >
                  {cellProps.value + " " + ">"}
                </Button>
              </Link>
            </div>
          )
        },
      },], 
    
     
      
  
    ]
    // ),
    // [checkOrg,accessData]
  )

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/export-dashboard-regulations`,
        { orderBy: sortBy, customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "", },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
          responseType:'blob'
        }
      )
      .then(response => {
        // console.log(data.data.data, 'exporData');
        // exportToExcel(response.data.data)
        DownloadExelFrom({responseData:response.data,fileTitle: "Regulations Statistics"})
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        // toastr.error(error, "Error Exporting Data")
        ToastError(error)
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }
  console.log(ExportObligationReadWriteAccess,
    ExportDisclosureReadWriteAccess,
    ExportLinkReadWriteAccess);
 
  const test = () =>{
    console.log("ExportDownloadAccess",ExportDownloadAccess); 
  }

  useEffect(()=>{
    setAccessData({
      ObligationReadWriteAccess:ExportObligationReadWriteAccess,
      DisclosureReadWriteAccess:ExportDisclosureReadWriteAccess,
      LinkReadWriteAccess:ExportLinkReadWriteAccess,
      RegulationReadWriteAccess:ExportRegulationReadWriteAccess,
      DownloadAccess:ExportDownloadAccess
     })

  },[ExportObligationReadWriteAccess,
    ExportDisclosureReadWriteAccess,
    ExportLinkReadWriteAccess,ExportRegulationReadWriteAccess])
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <Card>
        <CardBody>
          {/* <div className="mb-4 h4 card-title">Regulation Statistics</div> */}

          <CustomeTableContainer
            loader={loader}
            columns={columns}
            btnLoader={btnLoader}
            data={regulationData}
            isGlobalFilter={false}
            isAddOptions={false}
            handleExport={handleExport}
            customPageSize={10}
            handleSort={handleSort}
            setSortBy={setSortBy}
            ExportDownloadAccess={accessData.DownloadAccess}
          />
          <Row className={`justify-content-center mt-3 ${(accessData.ObligationReadWriteAccess||accessData.DisclosureReadWriteAccess||accessData.LinkReadWriteAccess||accessData.RegulationReadWriteAccess)?"":"d-none"}`} onClick={test}>
            <Col className="col-auto">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalItems}
                pageSize={10}
                onPageChange={page => setCurrentPage(page)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(RagulationCountTable)
