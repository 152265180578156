import {
  GET_DISCLOSURES,
  GET_DISCLOSURES_SUCCESS,
  GET_DISCLOSURES_FAIL,
  ADD_NEW_DISCLOSURE,
  ADD_DISCLOSURE_SUCCESS,
  ADD_DISCLOSURE_FAIL,
  UPDATE_DISCLOSURE,
  UPDATE_DISCLOSURE_SUCCESS,
  UPDATE_DISCLOSURE_FAIL,
  DELETE_DISCLOSURE,
  DELETE_DISCLOSURE_SUCCESS,
  DELETE_DISCLOSURE_FAIL,
  SET_LOADING,
} from "./actionTypes"

export const getDisclosures = urlData => ({
  type: GET_DISCLOSURES,
  urlData,
})

export const getDisclosuresSuccess = data => ({
  type: GET_DISCLOSURES_SUCCESS,
  payload: data,
})

export const getDisclosuresFail = error => ({
  type: GET_DISCLOSURES_FAIL,
  payload: error,
})

export const addNewDisclosure = (disclosure, history) => ({
  type: ADD_NEW_DISCLOSURE,
  payload: { disclosure, history },
})

export const addDisclosureSuccess = disclosure => ({
  type: ADD_DISCLOSURE_SUCCESS,
  payload: disclosure,
})

export const addDisclosureFail = error => ({
  type: ADD_DISCLOSURE_FAIL,
  payload: error,
})

export const updateDisclosure = (disclosure, history) => ({
  type: UPDATE_DISCLOSURE,
  payload: { disclosure, history },
})

export const updateDisclosureSuccess = disclosure => ({
  type: UPDATE_DISCLOSURE_SUCCESS,
  payload: disclosure,
})

export const updateDisclosureFail = error => ({
  type: UPDATE_DISCLOSURE_FAIL,
  payload: error,
})

export const deleteDisclosure = (disclosure, history) => ({
  type: DELETE_DISCLOSURE,
  payload: { disclosure, history },
})

export const deleteDisclosureSuccess = disclosure => ({
  type: DELETE_DISCLOSURE_SUCCESS,
  payload: disclosure,
})

export const deleteDisclosureFail = error => ({
  type: DELETE_DISCLOSURE_FAIL,
  payload: error,
})


// Loading State 
export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

