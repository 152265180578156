import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import toastr from "toastr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Select from "react-select"
import Switch from "react-switch"
import {
  addNewRegulator as onAddNewRegulator,
  updateRegulator as onUpdateRegulator,
} from "store/actions"
import axios from "axios"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, encryptData } from "helpers/helper_function"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const UsersUpdate = props => {
  const { setShowTable, editData } = props
  const [dropdownState, setDropdownState] = useState(false)
  const [ckData, setCkData] = useState("")
  const [selectedJuris, setSelectedJuris] = useState()
  const [selectedCntry, setSelectedCntry] = useState()
  const [selectedState, setSelectedState] = useState()
  const [btnDisable, setBtnDisable] = useState(false)
  const [roleOption, setRoleOption] = useState([])
  const [orgOption, setOrgOption] = useState([])
  const [status, setStatus] = useState(true)
  const [orgNum, setOrgNum] = useState()
  const [isOrgMultipleSelect, setIsOrgMultipleSelect] = useState(false)
  const [defaultOrgOption, setDefaultOrgOption] = useState(null)
  const [orgValue, setOrgValue] = useState(null)
  const [selectedOrgOptions, setSelectedOrgOptions] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const dispatch = useDispatch()

  // console.log(editData, "editData")

  const location = useLocation()

  const [data, setData] = useState()

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setOrgNum(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setOrgNum(authToken.data.customerorg.id)
    }
  }, [])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      tableName: "roles",
      fieldName: ["roles.id", "roles.name"],
      isClient: orgNum !== 3 ? true : false,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-all`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data)
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;

          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.name,
          }))
          // const modifiedOptions = [
          //   { label: 'Select Regulator', value: 0 }, // 0 option
          //   ...optionData // API options
          // ];
          setRoleOption(optionData)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [orgNum])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      tableName: "customerOrganization",
      fieldName: [
        "customerOrganization.id",
        "customerOrganization.companyName",
      ],
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-all`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.companyName,
          }))

          if (
            validation.values.role === 17 ||
            (editData && editData.role && editData.role.id === 17)
          ) {
            setIsOrgMultipleSelect(true)
            const organizationIds = editData.organizationAccess.map(
              access => access.custOrg.id
            )
            const selectedOrgOptions = optionData.filter(option =>
              organizationIds.includes(option.value)
            )
            const preselectedOrgs = selectedOrgOptions.map(
              option => option.value
            )

            // Add option with ID 3 to the selected options
            if (!preselectedOrgs.includes(3)) {
              preselectedOrgs.push(3)
            }

            setOrgValue(preselectedOrgs)
            validation.setFieldValue("customerorg", preselectedOrgs)
          } else {
            if (isOrgMultipleSelect && defaultOrgOption) {
              setOrgValue([defaultOrgOption.value])
              validation.setFieldValue("customerorg", [defaultOrgOption.value])
            } else if (!isOrgMultipleSelect && defaultOrgOption) {
              setOrgValue(defaultOrgOption.value)
              validation.setFieldValue("customerorg", defaultOrgOption.value)
            }
          }

          setOrgOption(optionData)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error (Status: 404)"
              break
            case 500:
              message =
                "Sorry! Something went wrong, please contact our support team, Status-500"
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  // console.log(roleOption, "roleOption")
  useEffect(() => {
    console.log(editData, "edit data")
    if (editData) {
      setStatus(editData.status)
    }
  }, [])

  const addNewUser = addData => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}add-user`, {data:encryptData(addData)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response, "response")
          toastr.success("User Succefully Added")
          setShowTable("table")
        }
      })
      .catch(err => {
        let message
        console.log(err, "err")
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        console.log(message, "message")
        RedirectOnUnAuthentication(err,navigateTo,message)
        // throw message;
      })
  }

  const updateUser = editData => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}update-user`, {data:encryptData(editData)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response, "response")
          toastr.success("User Succefully Updated")
          setShowTable("table")
        }
      })
      .catch(err => {
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        console.log(message, "message")
        RedirectOnUnAuthentication(err,navigateTo,message)
      })
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: (editData && editData.firstName) || "",
      lastName: (editData && editData.lastName) || "",
      website: (editData && editData.website) || "",
      email: (editData && editData.email) || "",
      phone: (editData && editData.phone) || "",
      note: (editData && editData.note) || "",
      // password: editData && editData.password || "",
      role: (editData && editData.role && editData.role.id) || null,
      customerorg:
        (editData && editData.customerorg && editData.customerorg.id) || orgNum,
      // jurisdiction: selectedJuris || "",
      //   idx : idx || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      // website: Yup.string().required("Please Enter Website"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Please Enter Email"),
      phone: Yup.string()
        // .required('Contact number is required')
        .min(10, "Must be minimum 10 digits")
        .matches(
          /^\+?\d{8,14}$/,
          "Invalid phone number. Please enter a valid phone number."
        ),
      password: Yup.string()
        // .required("Please enter your password")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
      // note: Yup.string().required("Please Enter Note"),
      // role: Yup.object().required("Please Select Role"),
      // customerorg: Yup.object().required("Please Select Organization"),
      // country: Yup.object().required("Please Select country"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      if (!editData) {
        const addData = {
          firstName: values.firstName,
          lastName: values.lastName,
          website: values.website,
          email: values.email,
          phone: values.phone,
          note: values.note,
          roleId: values.role,
          customerorgId: isOrgMultipleSelect ? 3 : values.customerorg,
          accessCustOrgIds: isOrgMultipleSelect
            ? values.customerorg.toString()
            : "",
          status: status,
        }
        console.log(addData, "addData")
        addNewUser(addData)
        // dispatch(onAddNewRegulator(addData, props.history))
        // setBtnDisable(true)
        // validation.resetForm();
      } else {
        const updateData = {
          id: editData && editData.id,
          firstName: values.firstName,
          lastName: values.lastName,
          website: values.website,
          email: values.email,
          phone: values.phone,
          note: values.note,
          roleId: values.role,
          customerorgId: isOrgMultipleSelect ? 3 : values.customerorg,
          accessCustOrgIds: isOrgMultipleSelect
            ? values.customerorg.toString()
            : "",
          status: status,
          password: values.password,
        }
        // dispatch(onUpdateRegulator(updateData, props.history))
        // validation.resetForm();
        console.log(updateData, "updateData")
        updateUser(updateData)
      }
    },
  })

  const addTitle = "Add New User"
  const updateTitle = "Update User"

  return (
    <>
      {/* <div className="page-content"> */}
      <Container fluid={true}>
        <Breadcrumbs
          title="Users"
          path="#"
          // breadcrumbItem={updateTitle}
          breadcrumbItem={editData ? updateTitle : addTitle}
          setShowTable={setShowTable}
        />
        <Card>
          {/* <CardBody> */}
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <div className="form-group">
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">First Name</Label>
                    <Input
                      name="firstName"
                      // value={data && data.short_name}
                      className="form-control"
                      placeholder="Enter First Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                      invalid={
                        validation.touched.firstName &&
                        validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Last Name</Label>
                    <Input
                      name="lastName"
                      // value={data && data.short_name}
                      className="form-control"
                      placeholder="Enter Last Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        validation.touched.lastName &&
                        validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Website</Label>
                    <Input
                      name="website"
                      // value={data && data.long_name}
                      className="form-control"
                      placeholder="Enter Website"
                      type="url"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.website || ""}
                      invalid={
                        validation.touched.website && validation.errors.website
                          ? true
                          : false
                      }
                    />
                    {validation.touched.website && validation.errors.website ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.website}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      // value={data && data.short_name}
                      className="form-control"
                      placeholder="Enter Email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Phone</Label>
                    <Input
                      name="phone"
                      // value={data && data.long_name}
                      className="form-control"
                      placeholder="Enter Contact Number"
                      type="phone"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      // value={data && data.long_name}
                      className="form-control"
                      placeholder="Enter Password"
                      type="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="12">
                  <div className="mb-3">
                    <Label className="form-label">Notes</Label>
                    <Input
                      name="note"
                      // value={data && data.long_name}
                      className="form-control"
                      placeholder="Enter Notes"
                      type="textarea"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.note || ""}
                      invalid={
                        validation.touched.note && validation.errors.note
                          ? true
                          : false
                      }
                    />
                    {validation.touched.note && validation.errors.note ? (
                      <div className="error" style={{ fontSize: "12px" }}>
                        {validation.errors.note}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="12" md="6">
                  <div className="mb-3">
                    <Label className="form-label">Role of User</Label>
                    <Select
                      options={roleOption}
                      name="role"
                      styles={customStyles}
                      placeholder="Select Role of User"
                      // defaultValue={countryOptions.find(({ value }) => value == data && data.country)}
                      value={roleOption.find(
                        option => option.value === validation.values.role
                      )}
                      onChange={option => {
                        validation.setFieldValue("role", option.value)
                        if (option.value === 17) {
                          setIsOrgMultipleSelect(true)
                          setDefaultOrgOption(
                            orgOption.find(o => o.value === 3)
                          )
                        } else {
                          setIsOrgMultipleSelect(false)
                          setDefaultOrgOption(null)
                        }
                        // handleRegrID(option.value)
                        // validation.setFieldValue('regulationId', 0); // Reset regulationId to 0 when regulatorId changes
                      }}
                      onBlur={validation.handleBlur}
                    />
                    {/* jurisdiction */}
                    {validation.touched.role && validation.errors.role ? (
                      <div className="error" style={{ fontSize: "12px" }}>
                        {validation.errors.role}
                      </div>
                    ) : null}
                  </div>
                </Col>
                {orgNum === 3 && (
                  <Col xs="12" md="6">
                    <div className="mb-3">
                      <Label className="form-label">Organization</Label>
                      {isOrgMultipleSelect ? (
                        <Select
                          options={orgOption}
                          name="customerorg"
                          styles={customStyles}
                          placeholder="Select Organization of User"
                          isMulti={true}
                          // value={defaultOrgOption ? [defaultOrgOption] : null}
                          value={
                            isOrgMultipleSelect
                              ? orgValue
                                ? orgOption.filter(option =>
                                    orgValue.includes(option.value)
                                  )
                                : [defaultOrgOption].filter(Boolean) // Ensure the default option is preselected
                              : orgOption.find(
                                  option => option.value === orgValue
                                ) || defaultOrgOption
                          }
                          // onChange={(options) => {
                          //   validation.setFieldValue('customerorg', options ? options.map(o => o.value) : null);
                          // }}
                          onChange={option => {
                            if (isOrgMultipleSelect) {
                              // For multiple select, option will be an array of selected options
                              const selectedValues = option
                                ? option.map(opt => opt.value)
                                : []
                              setDefaultOrgOption(
                                orgOption.find(o => o.value === 3)
                              )
                              // Ensure that the default option is always selected
                              if (
                                defaultOrgOption &&
                                !selectedValues.includes(defaultOrgOption.value)
                              ) {
                                selectedValues.push(defaultOrgOption.value)
                              }
                              setOrgValue(selectedValues)
                              validation.setFieldValue(
                                "customerorg",
                                selectedValues
                              )
                            } else {
                              // For single select, option will be the selected option object
                              const selectedValue = option ? option.value : null
                              // Ensure that the default option is always selected
                              const finalValue =
                                selectedValue === defaultOrgOption?.value
                                  ? selectedValue
                                  : defaultOrgOption?.value || selectedValue
                              setOrgValue(finalValue)
                              validation.setFieldValue(
                                "customerorg",
                                finalValue
                              )
                            }
                          }}
                          onBlur={validation.handleBlur}
                        />
                      ) : (
                        <Select
                          options={orgOption}
                          name="customerorg"
                          styles={customStyles}
                          placeholder="Select Organization of User"
                          // defaultValue={countryOptions.find(({ value }) => value == data && data.country)}
                          value={orgOption.find(
                            option =>
                              option.value === validation.values.customerorg
                          )}
                          onChange={option => {
                            validation.setFieldValue(
                              "customerorg",
                              option.value
                            )
                            // handleRegrID(option.value)
                            // validation.setFieldValue('regulationId', 0); // Reset regulationId to 0 when regulatorId changes
                          }}
                          onBlur={validation.handleBlur}
                        />
                      )}
                      {/* jurisdiction */}
                      {validation.touched.customerorg &&
                      validation.errors.customerorg ? (
                        <div className="error" style={{ fontSize: "12px" }}>
                          {validation.errors.customerorg}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={12}>
                  <Label check>Status</Label>
                  {/* <FormGroup switch> */}
                  <div className="mt-2">
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#02a499"
                      onChange={() => {
                        setStatus(!status)
                      }}
                      checked={status}
                    />
                  </div>

                  {/* </FormGroup> */}
                </Col>
              </Row>
            </div>
            <Col xs="12" md="12">
              <div className="text-end">
                <Button
                  disabled={btnDisable}
                  type="submit"
                  className="me-2"
                  color="primary"
                >
                  Update User
                </Button>
                <Link>
                  <Button
                    onClick={() => setShowTable("table")}
                    className="btn btn-secondary waves-effect waves-light "
                    color="secondnary"
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Form>
          {/* </CardBody> */}
        </Card>
      </Container>
      {/* </div> */}
    </>
  )
}

export default UsersUpdate
