import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { map } from "lodash"
import { useDispatch } from "react-redux"
import toastr from "toastr"
import debounce from "lodash/debounce"
import axios from "axios"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Flatpickr from "react-flatpickr"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../../components/Common/filters"
import JobListGlobalFilter from "../../../components/Common/GlobalSearchFilter"
import styled from "styled-components"
import { getOrders as onGetOrders } from "store/actions"
import { Popper } from "@material-ui/core"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Pagination from "../../../components/Common/Pagination"
import DeleteModal from "../../../components/Common/DeleteModal"
import { fetchOrgOptions } from "../../../store/actions"
import { setOrgOptions } from "../../../store/actions"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { format } from "date-fns"
import { Select, MenuItem, FormControl } from "@material-ui/core"

// import TableContainer from "../../../components/Common/TableContainer"
import { TableHeader, Search } from "../../Datatable/index"
import FilterModal from "components/FilterModal/FilterModal"
import RolesUpdate from "./update"
import { FormatedDate, RedirectOnUnAuthentication, ToastError, encryptData } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const csvHeader = [
    "ID",
    "Obligation Name",
    "Obligation Description",
    "Citation URL",
    "Regulator Short Name",
    "Reg Law Short Name",
    "Citation",
    "Obligation Notes",
    "Status",
    "Created At",
    "Last Updated At",
    "Regulations",
    "Regulators",
]

const exportToExcel = rowData => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  const customizedData = rowData.map(row => {
    // console.log(row, 'row')
    const updatedDate =FormatedDate(row.updatedDate,true) 
    const createdDate =FormatedDate(row.createdDate,true) 

    const EffectiveDate =FormatedDate(row.regEffectiveDate,true) 
    return {
      ...row,
      obligationDesc: row.obligationDesc.replace(/<[^>]+>/g, ""),
      status:
        row.status == 1 ? "Pending" : row.status == 2 ? "Approved" : "Removed",
      createdDate: createdDate,
      updatedDate: row.updatedDate ? updatedDate : "-",
      regulation: row.regulation ? row.regulation.regShortName : "NA",
      regulator: row.regulator ? row.regulator.regulatorShortName : "NA",
      obligationNote: row.obligationNote ? row.obligationNote : "-",
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')
  const filteredData = customizedData.map(
    ({ incrementId, id, regulator, regulation, ...rest }) => rest
  )
  const arrangedColumns = [
    "obId",
    "obligationName",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "regLaw",
    "citation",
    "obligationNote",
    "status",
    "createdDate",
    "updatedDate",
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "OB_ID",
        "Obligation Name",
        "Obligation Description",
        "Citation URL",
        "Regulator Short Name",
        "Reg Law Short Name",
        "Citation",
        "Obligation Notes",
        "Status",
        "Created At",
        "Last Updated At",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Obligations.xlsx")
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :first-child {
        padding-left: 15px;
      }

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      // height: 80px;
      padding-top: 10px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  setShowOrgaTable,
  pageCount: customePageCount,
  filterArray,
  setFilterArray,
  columns,
  data,
  fetchData,
  searchObject,
  props,
  handleRender,
  setSearchObject,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useResizeColumns,
    // useFlexLayout,
    useSticky,
    useRowSelect
    //   hooks => {
    //     hooks.visibleColumns.push(columns => [
    //       // Let's make a column for selection
    //       {
    //         id: "selection",
    //         sticky: "left",
    //         width: 37,
    //         // The header can use the table's getToggleAllRowsSelectedProps method
    //         // to render a checkbox
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <div>
    //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //           </div>
    //         ),
    //         // The cell can use the individual row's getToggleRowSelectedProps method
    //         // to the render a checkbox
    //         Cell: ({ row }) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         ),
    //       },
    //       ...columns,
    //     ])
    //   }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      obligationIds: ids,
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/export-obligations`, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
      })
      .then(response => {
        // console.log(data.data.data, 'exporData');
        exportToExcel(response.data.data)
        setBtnLoader(false)
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }

  const handleDownloadFormat = () => {
    const csvData =
      "Obligation Name,Obligation Description,Citation URL,Citation,Obligation Notes,Regulator Short Name,Reg Law Short Name,Status"
    const filename = "Obligations_format.csv"
    const csvBlob = new Blob([csvData], { type: "text/csv" })
    const csvURL = window.URL.createObjectURL(csvBlob)

    const tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", filename)
    tempLink.click()

    // Cleanup
    window.URL.revokeObjectURL(csvURL)
    tempLink.remove()
  }

  const handleSearchQueryChange = (columnKey, value) => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    setSearchQuery(prev => ({ ...prev, [columnKey]: value }))

    const data = {
      tableName: "Obligation",
      feildName: columnKey,
      searchValue: value,
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (columnKey != "updatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }
        setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => {
    let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      updatedValue = formattedDate
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)

    console.log("API call triggered:", value)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  console.log(selectedDate, "selecteddate")

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      fetchData(searchQuery)
    }
  }

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setSearchObject({})
    setSelectedHeader([])
    setFilterArray({})
    setSelectedDate(null)
    // fetchData({})
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-obligations`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  const edit = () => {
    props.history.replace({
      pathname: "/obligations/obligation_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "Obligation",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400: 
              ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }
  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col md={3} className="py-3 justify-content-end">
          <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">Organization List</h5>
          </div>
        </Col>
        {/* <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col> */}
        {/* {isGlobalFilter ? (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isJobListGlobalFilter={isJobListGlobalFilter}
            />
          ) : (
            <Col md={4}></Col>
          )} */}
        {/* <Col md={4} className="justify-content-start">
            <div className="d-flex flex-wrap gap-1  justify-content-start">
              <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">
                      Search this table
                    </span>
                    <input
                      // onChange={e => {
                      //   setValue(e.target.value)
                      //   onChange(e.target.value)
                      // }}
                      id="search-bar-0"
                      type="text"
                      className="form-control"
                      style={{ backgroundColor: "#F3F3F9" }}
                      placeholder={`${count} records...`}
                      // value={value || ""}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div> */}
        {/* <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">

                <Dropdown
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                  className="d-inline-block"
                >
                  <DropdownToggle
                    className="btn btn-rounded btn-outline"
                    style={{ border: "1px solid black" }}
                    id="page-header-user-dropdown"
                    tag="button"
                    // onClick={makeBlur1}
                  >
                    <i className="bx bx-filter font-size-16 align-middle me-1"></i>
                    Filter
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href="/profile">
                      <div>
                        <div>
                          <IndeterminateCheckbox
                            {...getToggleHideAllColumnsProps()}
                          />{" "}
                          Toggle All
                        </div>
                        {allColumns.map(column => (
                          <div key={column.id}>
                            <label>
                              <input
                                type="checkbox"
                                {...column.getToggleHiddenProps()}
                              />{" "}
                              {column.id}
                            </label>
                          </div>
                        ))}
                        <br />
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div> */}
        {/* </div>
          </Col> */}
        {isJobListGlobalFilter && <JobListGlobalFilter />}
        {isAddOptions && (
          <Col md={9} className="py-3 justify-content-end">
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              {selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  <Button
                    onClick={e => {
                      onAllDeleteRecord(e)
                    }}
                    style={{
                      backgroundColor: "#556EE6",
                      marginLeft: 5,
                      marginRight: 5,
                      color: "#fff",
                      textTransform: "none",
                    }}
                  >
                    <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                    Delete
                  </Button>
                </>
              ) : selectedFlatRows.length >= 1 ? (
                <Button
                  onClick={e => {
                    onAllDeleteRecord(e)
                  }}
                  style={{
                    backgroundColor: "#556EE6",
                    marginLeft: 5,
                    marginRight: 5,
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                  Delete
                </Button>
              ) : (
                ""
              )}
              <DeleteModal
                show={deleteModal}
                onDeleteClick={e => onAllDelete(e)}
                onCloseClick={() => setDeleteModal(false)}
              />
              {/* <button
                      type="button"
                      className="btn btn-primary "
                      onClick={clearFilter}
                    >
                      <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
                      Clear Filter
                    </button> */}
              <Link
                className="btn btn-primary"
                //   to="/obligations/obligation_update"
                onClick={() => setShowOrgaTable("addTable")}
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Organization
              </Link>
              {/* <Button
                  onClick={() => {
                    setModal(!modal);
                    setDesc("Import Data");
                    setTitle("Import Data");
                  }}
                  style={{
                    backgroundColor: "#556ee6",
                    marginLeft: 5,
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                  Upload
                </Button> */}
              {/* <Modal
                  isOpen={modal}
                  toggle={toggle}
                  className="modal-dialog-centered"
                  style={{ borderRadius: "50px" }}
                >
                  <ModalHeader toggle={toggle}>{title}</ModalHeader>
                  <ModalBody className="px-4 py-4 text-left">
                    <h6>Import Data</h6>
                    <div className="form-body mt-2">
                      <input
                        type="file"
                        id="csvFileInput"
                        accept=".xlsx"
                        name="file"
                        onChange={onFileChange}
                        className="form-control mb-3"
                      />
                      <div className="error">{errors.file}</div>
                    </div>
                    <div className="px-6 mt-3 mb-1 text-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        name="button"
                        onClick={(e) => handleImportData(e)}
                      >
                        Import Data
                      </button>
                    </div>
                  </ModalBody>
                </Modal> */}
              {/* {btnLoader ? (
                  <div className="ml-5">
                    <Spinner className="ms-2 " color="primary" />
                  </div>
               ) : (
                <Dropdown isOpen={dropdownOpen} toggle={downloadToggle} direction={"down"}>
                  <DropdownToggle size="md" style={{padding: "10px 8px"}} caret color="primary">
                    <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                    Download
                  </DropdownToggle>
                    <DropdownMenu >
                      <DropdownItem onClick={handleExport}>Download Report</DropdownItem>
                      <DropdownItem onClick={handleDownloadFormat}>Download Upload Format</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
              )} */}
              {/* <button
                  type="button"
                  className="btn btn-primary "
                  // onClick={handleExport}
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </button>  */}
              {/* <input
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="contained-button-file">
                  <Buttn
                    variant="contained"
                    style={{
                      backgroundColor: "#556ee6",
                      color: "#fff",
                      textTransform: "none ",
                      fontFamily: "poppins",
                      fontWeight: 400,
                      fontSize: 15
                    }}
                    component="span"
                  >
                    <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                    Upload
                  </Buttn>
                </label> */}
            </div>
          </Col>
        )}
      </Row>

      {/* <div className="table-responsive react-table"> */}
      {loader ? (
        <div
          className="container-fluid mt-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <Styles>
          <div style={{ minHeight: "25rem" }}>
            <div
              className="sticky table"
              {...getTableProps()}
              style={{ height: 500 }}
            >
              <div className="table-light header" style={{ fontWeight: 600 }}>
                {headerGroups.map(headerGroup => (
                  <div
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map(column => (
                      // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                      <div
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="th"
                      >
                        {/* <div> */}
                        <div
                          className="mb-2 mt-0"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                          {/* <span style={{ color: "#556ee6" }}>
                                  {" "}
                                  {generateSortingIndicator(column)}
                                </span>
                                <br /> */}
                        </div>

                        {/* {column.id != "selection" &&
                          column.id != "updatedDate" &&
                          column.id != "status" &&

                          column.Header !== "Action" ? (
                            <div style={{ width: "100%", position: 'absolute', bottom: '10px', display:'flex'}} >
                            <MuiThemeProvider theme={theme} >
                          <Autocomplete

                          style={{width:'70%'}}
                          PopperComponent={PopperMy}

                            options={autoFill[column.id] || []}

                            value={autoEmpty[column.id] || ""}
                            classes={{
                              option: classes.option
                            }}
                            freeSolo
                            onChange={(event, value) => handleAutocompleteChange(column.id, value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search..."
                                variant="filled"
                                size="small"

                                className={classes.root}
                                value={searchQuery[column.id] || ""}
                                onChange={(e) => handleSearchQueryChange(column.id, e.target.value)}

                                InputLabelProps={{
                                  shrink: false,
                                  focused: false,
                                }}
                              />
                            )}
                          />
                          </MuiThemeProvider>
                          <button
                          onClick={() => {
                              setShowModal(true);
                              setModalData(column)
                            }}
                            className= {selectedHeader.includes(column.Header) ? "filter_button_selected" : "filter_button"}
                            >
                          <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                          ) : column.id == "updatedDate" ? (
                            <div style={{ width: "75%", position: 'absolute', bottom: '10px', display:'flex'}}>
                                              <Flatpickr
                                                className="form-control  d-block flatpickr-input"
                                                placeholder="Select Date..."
                                                style={{fontSize: '12px', paddingLeft: 4, paddingBottom:4, paddingTop:8, borderBottom: '1px solid #000',}}
                                                onChange={(e) => handleAutocompleteChange(column.id, e)}
                                                value={selectedDate}
                                                options={{
                                                  dateFormat: "Y-m-d",
                                                  defaultDate: '2023-05-15',
                                                }}
                                                onKeyDown={(e) => handleKeyDownDate(column.id, e)}
                                            />

                                      <div className="icon-container" style={{position:'absolute', right: 6, top:8}}>

                                                <i
                                                  className="fa fa-calendar"

                                                />
                                              </div>

                                                  </div>
                          )
                          :
                          column.id == "status" ? (
                            <div
                            style={{
                              width: "80%",
                              position: "absolute",
                              bottom: "10px",
                            }}
                          >
                           <FormControl
                                fullWidth
                                className={classes.formControl}
                              >

                                <Select
                                  className={classes.select}
                                  value={(searchQuery[column.id] != undefined) ? searchQuery[column.id] : ""}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                  displayEmpty
                                  onChange={event =>
                                    handleValueChange(
                                      column.id,
                                      event.target.value
                                    )
                                  }

                                >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Pending</MenuItem>
                              <MenuItem value={2}>Approved</MenuItem>
                               <MenuItem value={3}>Removed</MenuItem>
                            </Select>
                            </FormControl>
                            </div>
                          )
                          : (
                            ""
                          )} */}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {rows.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <div {...row.getRowProps()} className="tr">
                          {row.cells.map(cell => {
                            return (
                              <div
                                key={cell.id}
                                {...cell.getCellProps({
                                  style: {
                                    backgroundColor: "#fff",
                                  },
                                })}
                                className="td"
                              >
                                {cell.render("Cell")}
                              </div>
                            )
                          })}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <Row className="mt-5">
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "150px 0",
                      fontSize: "25px",
                    }}
                  >
                    No Records Found
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <FilterModal
            setSelectedHeader={setSelectedHeader}
            setFilterArray={setFilterArray}
            isShowModal={showModal}
            filterData={modalData}
            setShowModal={setShowModal}
            tableName={"Obligation"}
            emptyTable={data?.length == 0??false}

          />
        </Styles>
      )}
      {/* </div> */}
    </Fragment>
  )
}

const OrganizationTable = props => {
  const { setShowOrgaTable, setEditOrgaData } = props
  const [fakeData, setFakeData] = useState([])

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")

  const toggle = () => setModal(!modal)

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delObligation, setDelObligation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const dispatch = useDispatch()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const limit = 20

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const customePageCount = Math.ceil(totalItems / limit)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelObligation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    if (delObligation.id) {
      // dispatch(onDeleteObligation(delObligation.id, props.history))
      deleteOrganization(delObligation.id)
      setDeleteModal(false)
    }
  }

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log({ searchQuery })
    let finalData= Object.keys(filterArray).length
      ? filterArray
      : searchObject
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-organization?limit=10&pageNo=1`,
        {data:encryptData(finalData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteOrganization = organization => {
    // console.log(user, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    // const id = user.toString()
    // console.log(id, "delete ID")
    setLoader(true)
    const data = {
      tableName: "customerOrganization",
      ids: organization.toString(),
    }
    return (
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}delete-data`,
          {data:encryptData(data)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        //   .then(response => {
        //     if (response.status >= 200 || response.status <= 299) {
        //       toastr.success('Organization Successfully Deleted.')
        //       setLoader(false)
        //       setDelete(!is_delete)

        //     }
        //   })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success("Organization Successfully Deleted.")
            setLoader(false)
            setDelete(!is_delete)

            // Retrieve existing options from localStorage
            const cachedOptions = localStorage.getItem("orgOptions")
            const existingOptions = cachedOptions
              ? JSON.parse(cachedOptions)
              : []

            // Find and remove the deleted organization option
            const updatedOptions = existingOptions.filter(
              option => option.value !== organization
            )

            // Update localStorage with the updated options
            localStorage.setItem("orgOptions", JSON.stringify(updatedOptions))

            // Update Redux state with the updated options
            dispatch(setOrgOptions(updatedOptions))
          }
        })
        .catch(err => {
          let message
          setLoader(false)
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = err.response.data.message
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          RedirectOnUnAuthentication(err,navigateTo,message)
        })
    )
  }

  console.log(reqresData, "reqresData")
  useEffect(() => {
    console.log("page is loading")
    fetchData(searchObject)
  }, [currentPage, is_delete, searchObject, filterArray])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray])

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        width: 130,
        accessor: "companyName",
        filterable: false,
        disableFilters: true,
        //   sticky: "left",
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return <span style={{ color: "#000" }}>{cellProps.value}</span>
        },
      },
      {
        Header: "Email",
        width: 135,
        filterable: false,
        disableFilters: true,
        accessor: "email",
        Cell: cellProps => {
          const rowData = cellProps.value
          // console.log(rowData, 'celpops')
          return (
            <span style={{ color: "#000" }}   className="StringOverFlow1">
              {cellProps.value && cellProps?.value?.length > 18
                ? cellProps?.value 
                : cellProps.value || "-"}
            </span>
          )
        },
      },
      {
        Header: "Contact Details",
        accessor: "phone",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return cellProps.value && cellProps?.value?.length > 15
            ? cellProps?.value?.substr(0, 18) + " ..."
            : cellProps.value || "-"
        },
      },
      {
        Header: "Action",
        textAlign: "top",
        disableFilters: true,
        sticky: "right",
        filterable: false,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <ul
              className="list-unstyled hstack gap-2"
              style={{ marginBottom: "0px" }}
            >
              {/* <LightTooltip  */}

              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    //   to={{
                    //     pathname: "/obligations/obligation_update",
                    //     state: { rowData },
                    //   }}
                    onClick={() => {
                      setEditOrgaData(rowData)
                      setShowOrgaTable("editTable")
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>

              {/* <Badge className="font-size-15 badge-soft-primary" pill>
                                <DarkTooltip title="View">
                                    <Link
                                        onClick={() => {
                                            // const orderData = cellProps.row.original
                                            setShowRolesTable("viewTable")
                                            // console.log(orderData, 'data')
                                            // handleOrderClick(orderData);
                                        }}
                                    >
                                        <i
                                            //   onClick={() => handleView(value)}
                                            style={{ color: "blue", cursor: "pointer" }}
                                            className="mdi mdi-eye-outline  font-size-18"
                                            id="customerViewtooltip"
                                        ></i>
                                    </Link>
                                </DarkTooltip>
                            </Badge> */}
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",

                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            </ul>
          )
        },
      },
    ],
    []
  )

  const handleRender = () => {
    setDelete(!is_delete)
  }
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Obligation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Row>
            <Col xs="12">
              <Card>
                <TableContainer
                  columns={columns}
                  data={reqresData}
                  setShowOrgaTable={setShowOrgaTable}
                  searchObject={searchObject}
                  filterArray={filterArray}
                  setFilterArray={setFilterArray}
                  props={props}
                  fetchData={fetchData}
                  handleRender={handleRender}
                  setSearchObject={setSearchObject}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  customPageSize={10}
                  loader={loader}
                />
                <Row className="justify-content-center mt-3">
                  <Col className="col-auto">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={totalItems}
                      pageSize={limit}
                      onPageChange={page => setCurrentPage(page)}
                    />
                  </Col>
                </Row>
                {/* </CardBody> */}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OrganizationTable
