import axios from "axios";
import { getUserDetails } from "helpers/helper_function";
const TOKEN_TYPE = "Bearer";
const REQUEST_HEADER_AUTH_KEY = "Authorization";


const BaseService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_BASE_URL,
});


BaseService.interceptors.request.use(
  (config) => {
    const accessToken = getUserDetails();
    console.log(accessToken, 'Please Verify That This Is Token')
    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE} ${accessToken}`; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      return Promise.reject(error); 
    } else {
      return Promise.reject(error); 
    }
  },
);

export default BaseService;
