// get LINKS
export const GET_LINKS = "GET_LINKS"
export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS"
export const GET_LINKS_FAIL = "GET_LINKS_FAIL"

// add LINK

export const ADD_NEW_LINK = "ADD_NEW_LINK"
export const ADD_LINK_SUCCESS = "ADD_LINK_SUCCESS"
export const ADD_LINK_FAIL = "ADD_LINK_FAIL"

// edit LINK
export const UPDATE_LINK = "UPDATE_LINK"
export const UPDATE_LINK_SUCCESS = "UPDATE_LINK_SUCCESS"
export const UPDATE_LINK_FAIL = "UPDATE_LINK_FAIL"

/**
 * Delete LINK
 */
export const DELETE_LINK = "DELETE_LINK"
export const DELETE_LINK_SUCCESS = "DELETE_LINK_SUCCESS"
export const DELETE_LINK_FAIL = "DELETE_LINK_FAIL"
export const UPDATE_MODAL = "UPDATE_MODAL"
