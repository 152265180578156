import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import { Link } from "react-router-dom"
import DeleteModal from "../../components/Common/DeleteModal"
import { deleteRegulator as onDeleteRegulator } from "../../store/actions"
import { useDispatch } from "react-redux"
import axios from "axios"
import toastr from "toastr"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, encryptData } from "helpers/helper_function"

const UserView = props => {
  const location = useLocation()
  const { userViewData, setShowTable } = props
  const [delRegulator, setDelRegulator] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const dispatch = useDispatch()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  //   document.title = "Regulators";
  //   const data = location.state.rowData;
  //   let rowData = data;
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  const onClickDelete = rowData => {
    setDelRegulator(rowData)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    setDelete(!is_delete)
    const deleteId = delRegulator.id
    if (deleteId) {
      dispatch(onDeleteRegulator(delRegulator.id, props.history))
      setDeleteModal(false)
    }
  }

  const sendMail = rowData => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = { email: rowData.email };
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}send`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Password sent to mail Successfully")
          setShowTable("table")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        RedirectOnUnAuthentication(err,navigateTo,message)
      })
  }

  console.log(userViewData, "userViewData")

  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <Container fluid>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">User Details</h4>
          <div className="page-title-right">
            <div className="ms-auto">
              <ul className="nav nav-pills" style={{ justifyContent: "end" }}>
                <li>
                  <div className="row">
                    <div className="col-12 col">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className=""></h4>
                        <div
                          className="page-title-right"
                          // style={{ marginTop: "7px" }}
                        >
                          <div className="ms-auto">
                            <ul
                              className="nav nav-pills"
                              style={{ justifyContent: "end" }}
                            >
                              <li className="nav-item">
                                <Button
                                  onClick={() => sendMail(userViewData)}
                                  style={{
                                    backgroundColor: "#556ee6",
                                    marginRight: 5,
                                    color: "#fff",
                                    textTransform: "none",
                                  }}
                                >
                                  {/* <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i> */}
                                  Send Password
                                </Button>
                              </li>
                              {/* <li>
                                <div className="row">
                                  <div className="col-12 col">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                      <h4 className=""></h4>
                                      <div
                                        className="page-title-right"
                                        style={{ marginTop: "7px" }}
                                      >
                                        <ol className="breadcrumb m-0">
                                          <li className="breadcrumb-item">
                                            <Link
                                              onClick={() =>
                                                setShowTable("table")
                                              }
                                            >
                                              Users
                                            </Link>
                                          </li>
                                          <li
                                            className="active breadcrumb-item"
                                            aria-current="page"
                                          >
                                            User Details
                                          </li>
                                        </ol>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <div className="table-responsive px-0">
                <Table className="table-striped px-0">
                  <tbody>
                    <tr>
                      <th style={{ width: "15%" }}>First Name</th>
                      <td style={{ width: "75%" }}>
                        {userViewData && userViewData.firstName == null
                          ? "-"
                          : userViewData.firstName}
                        {/* Financial Industry Regulatory Authority */}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>Last Name</th>
                      <td style={{ width: "75%" }}>
                        {userViewData && userViewData.lastName == null
                          ? "-"
                          : userViewData.lastName}
                        {/* Financial Industry Regulatory Authority */}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>URL</th>
                      <td style={{ width: "75%" }}>
                        {userViewData && userViewData.website}
                        {/* finra.org... */}
                      </td>
                    </tr>

                    <tr>
                      <th style={{ width: "15%" }}>Email ID</th>
                      <td style={{ width: "75%" }}>
                        {userViewData && userViewData.email}
                        {/* mailto:finra@somemail.com */}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>Contact</th>
                      <td style={{ width: "75%" }}>
                        {(userViewData && userViewData.phone) || "-"}
                        {/* +1 2526 525214 */}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>Notes</th>
                      <td style={{ width: "75%" }}>
                        {(userViewData && userViewData.note) || "-"}
                        {/* +1 2526 525214 */}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>Role</th>
                      <td style={{ width: "75%" }}>
                        {/* Admin */}
                        {userViewData &&
                          userViewData.role &&
                          userViewData.role.name}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>Organization</th>
                      <td style={{ width: "75%" }}>
                        {/* Admin */}
                        {userViewData &&
                          userViewData.customerorg &&
                          userViewData.customerorg.companyName}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "15%" }}>Status</th>
                      <td style={{ width: "75%" }}>
                        {/* Admin */}
                        {userViewData && userViewData.status === true
                          ? "Active"
                          : "InActive"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </React.Fragment>
  )
}

export default UserView
