import {
  GET_LINKS_SUCCESS,
  GET_LINKS_FAIL,
  ADD_LINK_SUCCESS,
  ADD_LINK_FAIL,
  UPDATE_LINK_SUCCESS,
  UPDATE_LINK_FAIL,
  DELETE_LINK_SUCCESS,
  DELETE_LINK_FAIL,
  UPDATE_MODAL,
} from "./actionTypes"

const INIT_STATE = {
  linksData: [],
  loading: true,
  error: null,
  errorValue: "",
  success: false,
  showModal: null,
}

const Links = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LINKS_SUCCESS:
      return {
        ...state,
        linksData: action.payload,
        loading: false,
        error: false,
        success: true,
      }

    case UPDATE_MODAL:
      return {
        ...state,
        data: action.payload,
      }

    case GET_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        errorValue: action.payload,
      }

    case ADD_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        showModal: false,
      }

    case ADD_LINK_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case UPDATE_LINK_SUCCESS:
      // console.log(action.payload.message, 'see')
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      }
    case UPDATE_LINK_FAIL:
      // console.log(action, 'error')
      return {
        // state ={
        ...state,
        loading: false,
        error: true,
        // }
      }
    case DELETE_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      }

    case DELETE_LINK_FAIL:
      return {
        ...state,
        error: true,
        errorValue: action.payload,
      }
    // break
    default:
      return state
  }
}

export default Links
