import React from 'react'
import RegReviewTable from './components/RegReviewTable'
import LinkDisclosureModal from './components/LinkDisclosureModal'

const RegReviewList = () => {
  return (
    <div>
      <RegReviewTable/>
      <LinkDisclosureModal/>
    </div>
  )
}

export default RegReviewList