import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
  useRef,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes, { string } from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import toastr from "toastr"
import debounce from "lodash/debounce"
import Flatpickr from "react-flatpickr"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useSticky } from "react-table-sticky"
import Tooltip from "@material-ui/core/Tooltip"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import { map } from "lodash"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import Pagination from "../../components/Common/Pagination"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import styled from "styled-components"
import { getRegulators } from "../../store/actions"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { deleteRegulator as onDeleteRegulator } from "../../store/actions"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { format } from "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import { Popper } from "@material-ui/core"
import FilterModal from "../../components/FilterModal/FilterModal"
// import { TableHeader, Search } from "../Datatable/index"
import TableHeader from "components/Common/TableHeader"
import axios from "axios"
import AutoComplete from "components/Common/AutoComplete"
import { autofill } from "redux-form"
import {
  CheckValidValue,
  ColumnDataWithTooltip,
  DisplayJustifyCenter,
  DownloadExelFrom,
  FormatedDate,
  RedirectOnUnAuthentication,
  RemoveSpaces,
  ToastError,
  convertObjectValuesToArray,
  encryptData,
  handleNullAndUndefinedOfArray,
  removeKeysFromObject,
  trimLeft,
} from "helpers/helper_function"
import { error } from "jquery"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const csvHeader = [
  "ID",
  "Regulator Short Name",
  "Regulator Long Name",
  "Regulator Description",
  "Regulator Main URL",
  // "Regulator Update Source",
  // "Regulator Update Contact",
  "Regulator Country",
  "Regulator State",
  "Regulator County",
  "Created At",
  "Last Updated At",
]
const exportToExcel = rowData => {
  console.log(rowData, "selectedrows")
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  const customizedData = rowData.map(row => {
    const updatedDate = FormatedDate(row.updatedDate, true)
    const createdDate = FormatedDate(row.createdDate, true)
    console.log("exportToExcel", row)
    const newRow = removeKeysFromObject(row, [
      "regulatorUpdateSource",
      "regulatorUpdateContact",
    ])
    return {
      ...newRow,
      regulatorDesc: row.regulatorDesc.replace(/<\/?[^>]+>/gi, ""),
      createdDate: createdDate,
      updatedDate: row.updatedDate ? updatedDate : DisplayJustifyCenter("-"),
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')
  const filteredData = customizedData.map(({ status, ...rest }) => rest)
  const worksheet = XLSX.utils.json_to_sheet(filteredData, { csvHeader })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "ID",
        "Regulator Short Name",
        "Regulator Long Name",
        "Regulator Description",
        "Regulator Main URL",
        // "Regulator Update Source",
        // "Regulator Update Contact",
        "Regulator Country",
        "Regulator State",
        "Regulator County",
        "Created At",
        "Last Updated At",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Regulators.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 108px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  console.log(props, "props")
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  columns,
  reqresData,
  handleSort,
  setSortBy,
  checkOrg,
  setCheckOrg,
  regulatorAccess,
  downloadAccess,
  filterArray,
  props,
  customePageSize,
  setCustomePageSize,
  fetchData,
  dateQuery,
  searchObject,
  setSearchObject,
  setCurrentPage,
  handleRender,
  setFilterArray,
  data,
  pageCount: customePageCount,
  customPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      // disableRowSelect: true,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useFlexLayout,
    useBlockLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,

          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div style={{ backgroundColor: "#fff" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const [exportObject, setExportObject] = useState({})
  const [updatedData, setUpdatedData] = useState("new")
  const [blankColumn, setBlankColumn] = useState("0")
  const [clearFilterModal, setClearFilterModal] = useState(false)
  const callUseNavigate = useHistory()
  const navigateTo = url => {
    callUseNavigate.push(url)
  }
  const flatpickrRef = useRef(null)
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  // const generateSortingIndicator = column => {
  //   return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  // }

  useEffect(() => {
    const data = localStorage.getItem("regulators/all-regulators")

    let finalData

    if (data != null && props?.location?.state?.rowData?.isClear != undefined) {
      finalData = JSON.parse(data)
      let modifiedObject = {}

      console.log(finalData, "finalData")

      for (let key in finalData) {
        if (finalData.hasOwnProperty(key)) {
          let newKey
          let selectedkey

          newKey = key
          selectedkey = key

          if (Array.isArray(finalData[key])) {
            modifiedObject[newKey] = finalData[key][0]
          } else if (typeof finalData[key] === "string") {
            modifiedObject[newKey] = finalData[key]
          } else {
            console.log(`Value for key ${key} is not a valid string.`)
          }

          // Check if selectedkey matches any key in selectedFilterArray
          const selectedFilterArray = JSON.parse(
            localStorage.getItem(
              "regulators/all-regulators/selectedFilterArray"
            )
          )
          if (
            selectedFilterArray &&
            selectedFilterArray.hasOwnProperty(selectedkey)
          ) {
            delete modifiedObject[newKey]
          }
        }
      }

      console.log(modifiedObject, "modifiedObject")
      setAutoEmpty(modifiedObject)
    }
  }, [localStorage.getItem("regulators/all-regulators")])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    console.log(checkOrg, "checkOrg export")

    const data = {
      ...searchObject,
      ...filterArray,

      regulatorIds: ids,
      customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
    }

    console.log(
      "filterArray2545",
      { ...filterArray },
      "searchObject",
      { ...searchObject },
      "data",
      data
    )

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/export-regulators`,
        { data: encryptData(data) },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
          responseType: "blob",
        }
      )
      .then(response => {
        // console.log(data.data.data, 'exporData');
        // exportToExcel(response.data.data)
        DownloadExelFrom({
          responseData: response.data,
          fileTitle: "Regulators",
        })
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error, navigateTo, "Error Exporting Data")
        ToastError(error)
        setBtnLoader(false)
      })
  }

  const handleSearchQueryChange = (columnKey, value) => {
    console.log(columnKey, value, "check cross")
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (columnKey == "updatedDate") {
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)

      setSearchQuery(prev => ({ ...prev, [columnKey]: formattedDate }))
    } else if (value === null) {
      setSearchQuery(prev => ({ ...prev, [columnKey]: "" }))
    } else {
      setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
    }

    const idsArray = localStorage.getItem("idsArray")
      ? JSON.parse(localStorage.getItem("idsArray"))
      : []

    const data = {
      tableName: "Regulators",
      feildName: columnKey,
      searchValue: value,
      ids: idsArray,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {
          data: encryptData(data),
        },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        console.log(response, "response")
        if (columnKey != "updatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }

        setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
        // setSearchPage((prev) => ({ ...prev, [columnKey]: value }));
      })
      .catch(error => {
        RedirectOnUnAuthentication(error, navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  // console.log(autoFill, "auto fill")

  const handleKeyDown = (key, columnId, value) => {
    if (key === "Enter") {
      // fetchData(searchQuery)
      console.log("handleAutocompleteChange", columnId, value)
      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        setSelectedDate(formattedDate)
        updatedValue = formattedDate
      } else {
        updatedValue = value.trim() // Set the value to true when selecting "Yes"
      }

      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)

      console.log("API call triggered:", value)
    }
  }

  const handleAutocompleteChange = (columnId, value) => {
    const optionExists =
      autoFill[columnId] && autoFill[columnId].includes(value)

    if (optionExists || value === "") {
      console.log("selected from suggestion box")

      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        updatedValue = formattedDate
      } else {
        updatedValue = value.trim() // Set the value to true when selecting "Yes"
      }

      const updatedSearchQuery = { ...searchQuery, [columnId]: [updatedValue] }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
      setExportObject(updatedSearchQuery)
    } else {
      console.log("User typed and hit enter manually:", value)

      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        updatedValue = formattedDate
      } else {
        updatedValue = value.trim() // Set the value to true when selecting "Yes"
      }

      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
      setExportObject(updatedSearchQuery)
    }

    console.log("API call triggered:", value)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const handleKeyDownIcon = e => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault()
      flatpickrRef.current.flatpickr.open()
    }
  }

  // useEffect(() => {
  //   const handleClickOutside = (e) => {
  //     if (
  //       flatpickrRef.current &&
  //       !flatpickrRef.current.contains(e.target)
  //     ) {
  //       flatpickrRef.current.flatpickr.close();
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          border: "1px solid #ced4da",
          borderBottom: 0,
          // height: "35px",
        },
      },
    },
  })

  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  }

  const clearFilter = () => {
    //   toastr.options = {
    //     "closeButton": true,
    //     "timeOut": "0",
    //     "extendedTimeOut": "0",
    //   }
    // toastr.success('Error showing this taoster!');
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setSelectedHeader([])
    setFilterArray({})
    setSearchObject({})
    setSelectedDate(null)
    setClearFilterModal(true)
    setSortBy({
      tableName: "regulator",
      fieldName: "regulatorShortName",
      order: "ASC",
    })
    setCurrentPage(1)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    localStorage.removeItem("regulators/all-regulators")
    localStorage.removeItem("regulators/all-regulators/selectedFilterArray")
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }

  const classes = useStyles()

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [filterID, setFilterID] = useState([])

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)

  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-regulators`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err, navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }
  const handleImportEditData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("isBlankUpdatable", blankColumn)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-regulator-update`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}` },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err, navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }
  const edit = () => {
    props.history.replace({
      pathname: "/regulators/regulator_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "Regulators",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        { data: encryptData(data) },
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
              ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])
  // const isAutofillDisabled = column.id === 'your-column-id';

  const test = () => {
    console.log("data25456985", data)
  }

  const SelectedFilterIcon = (filterArray, column_id) => {
    const columnId = `${column_id}`.split("_").pop()

    try {
      const IsColumnIdSelected =
        JSON.parse(
          localStorage.getItem(
            "regulators/all-regulators/selectedFilterArray"
          ) || "[]"
        )?.[columnId] || []
      const IsColumnIdSelectedObj =
        JSON.parse(
          localStorage.getItem("regulators/all-regulators/selectedFilterArray")
        ) || {}
      const IsColumnIdExistInFilterArray = Object.keys(
        IsColumnIdSelectedObj || {}
      ).some(i => i.includes(`${columnId}`.split("_").pop()))
      if (IsColumnIdExistInFilterArray && IsColumnIdSelected.length != 0) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }

  return (
    <>
      <Fragment>
        <Row className=" d-flex align-items-center" onClick={test}>
          <Col md={4} className="py-3">
            {/* <div className="d-flex flex-wrap align-items-center   justify-content-start">
              <h5 className="font-size-18 mb-0">List of Regulators</h5>
              <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{width : "150px", marginLeft: "10px"}}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
            </div> */}
            <TableHeader
              heading={"List of Regulators"}
              onChangeInSelect={onChangeInSelect}
              customePageSize={customePageSize}
            />
          </Col>
          {/* <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col> */}
          {/* {isGlobalFilter ? (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        ) : (
          <Col md={4}></Col>
        )} */}
          {/*<Col md={4} className="justify-content-start">*/}
          {/*  <div className="d-flex flex-wrap gap-1  justify-content-start">*/}
          {/*    <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">*/}
          {/*      <div className="position-relative">*/}
          {/*        <label htmlFor="search-bar-0" className="search-label">*/}
          {/*          <span id="search-bar-0-label" className="sr-only">*/}
          {/*            Search this table*/}
          {/*          </span>*/}
          {/*          <input*/}
          {/*            // onChange={e => {*/}
          {/*            //   setValue(e.target.value)*/}
          {/*            //   onChange(e.target.value)*/}
          {/*            // }}*/}
          {/*            id="search-bar-0"*/}
          {/*            type="text"*/}
          {/*            className="form-control"*/}
          {/*            style={{ backgroundColor: "#F3F3F9" }}*/}
          {/*            placeholder={`${count} records...`}*/}
          {/*            // value={value || ""}*/}
          {/*          />*/}
          {/*        </label>*/}
          {/*        <i className="bx bx-search-alt search-icon"></i>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/* <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">

              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn btn-rounded btn-outline"
                  style={{ border: "1px solid black" }}
                  id="page-header-user-dropdown"
                  tag="button"
                  // onClick={makeBlur1}
                >
                  <i className="bx bx-filter font-size-16 align-middle me-1"></i>
                  Filter
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem tag="a" href="/profile">
                    <div>
                      <div>
                        <IndeterminateCheckbox
                          {...getToggleHideAllColumnsProps()}
                        />{" "}
                        Toggle All
                      </div>
                      {allColumns.map(column => (
                        <div key={column.id}>
                          <label>
                            <input
                              type="checkbox"
                              {...column.getToggleHiddenProps()}
                            />{" "}
                            {column.id}
                          </label>
                        </div>
                      ))}
                      <br />
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}
          {/*  </div>*/}
          {/*</Col>*/}

          <Col md={8} className="py-3 justify-content-end">
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              {regulatorAccess &&
              regulatorAccess.writeAccess === true &&
              checkOrg === 3 ? (
                selectedFlatRows.length == 1 ? (
                  <>
                    <Button
                      onClick={() => edit()}
                      style={{
                        backgroundColor: "#556EE6",
                        // marginLeft: 5,
                        color: "#fff",
                        textTransform: "none",
                      }}
                    >
                      <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                      Edit
                    </Button>
                    {/* <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button> */}
                  </>
                ) : (
                  // :
                  // selectedFlatRows.length >= 1 ? (
                  //   <Button
                  //     onClick={(e) => {
                  //       onAllDeleteRecord(e)
                  //     }}
                  //     style={{
                  //       backgroundColor: "#556EE6",
                  //       marginLeft: 5,
                  //       marginRight: 5,
                  //       color: "#fff",
                  //       textTransform: "none",
                  //     }}
                  //   >
                  //     <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                  //     Delete
                  //   </Button>
                  // )
                  ""
                )
              ) : (
                ""
              )}
              <DeleteModal
                show={deleteModal}
                onDeleteClick={e => onAllDelete(e)}
                onCloseClick={() => setDeleteModal(false)}
              />
              <button
                type="button"
                className="btn btn-primary "
                onClick={clearFilter}
              >
                <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
                Clear Filter
              </button>
              {regulatorAccess &&
              regulatorAccess.writeAccess === true &&
              checkOrg === 3 ? (
                <Link
                  className="btn btn-primary"
                  to="/regulators/regulator_update"
                >
                  <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                  Add Regulator
                </Link>
              ) : (
                ""
              )}
              {regulatorAccess &&
              regulatorAccess.writeAccess === true &&
              checkOrg === 3 ? (
                <Button
                  onClick={() => {
                    setModal(!modal)
                    setDesc("Import Data")
                    setTitle("Import Data")
                  }}
                  style={{
                    backgroundColor: "#556ee6",
                    // marginLeft: 5,
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                  Upload
                </Button>
              ) : (
                ""
              )}

              <Modal
                isOpen={modal}
                toggle={toggle}
                className="modal-dialog-centered"
                style={{ borderRadius: "50px" }}
              >
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className="px-4 py-4 text-left">
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "2px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="radio"
                        id="new-data"
                        name="form-radio-primary"
                        defaultChecked={updatedData == "new"}
                        value="new"
                        onChange={e => {
                          setUpdatedData(e.target.value)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="new-data"
                        style={{ marginBottom: "0px" }}
                      >
                        Upload new data
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="radio"
                        id="edit-data"
                        name="form-radio-primary"
                        value="edit"
                        defaultChecked={updatedData == "edit"}
                        onChange={e => {
                          setUpdatedData(e.target.value)
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="edit-data"
                        style={{ marginBottom: "0px" }}
                      >
                        Upload Edit
                      </label>
                    </div>
                  </div>
                  {updatedData == "edit" && (
                    <div
                      style={{
                        marginBottom: "20px",
                        display: "flex",
                        gap: "30px",
                      }}
                    >
                      <DarkTooltip title="Blank cells WILL NOT overwrite existing content">
                        <div
                          style={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            type="radio"
                            id="0"
                            name="blank-column"
                            defaultChecked={blankColumn == "0"}
                            value="0"
                            onChange={e => {
                              setBlankColumn(e.target.value)
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="0"
                            style={{ marginBottom: "0px" }}
                          >
                            Ignore Blank Cells{" "}
                          </label>
                          <i className="bx bx-info-circle"></i>
                        </div>
                      </DarkTooltip>
                      <DarkTooltip title="Blank cells WILL overwrite existing content">
                        <div
                          style={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            type="radio"
                            id="1"
                            name="blank-column"
                            value="1"
                            defaultChecked={blankColumn == "1"}
                            onChange={e => {
                              setBlankColumn(e.target.value)
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="1"
                            style={{ marginBottom: "0px" }}
                          >
                            Load Blank Cells
                          </label>
                          <i className="bx bx-info-circle"></i>
                        </div>
                      </DarkTooltip>
                    </div>
                  )}
                  <h6>Import Data</h6>
                  <div className="form-body mt-3">
                    <input
                      type="file"
                      id="csvFileInput"
                      accept=".xlsx"
                      name="file"
                      onChange={onFileChange}
                      className="form-control mb-2"
                    />
                    <div className="error">{errors.file}</div>
                  </div>
                  <div className="px-6 mt-3 mb-1 text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      name="button"
                      // onClick={e => handleImportData(e)}
                      onClick={e => {
                        updatedData == "new"
                          ? handleImportData(e)
                          : handleImportEditData(e)
                      }}
                    >
                      Import Data
                    </button>
                  </div>
                </ModalBody>
              </Modal>

              {downloadAccess && downloadAccess.readAccess === true ? (
                btnLoader ? (
                  <div className="ml-5">
                    <Spinner className="ms-2 " color="primary" />
                  </div>
                ) : (
                  <button className="btn btn-primary" onClick={handleExport}>
                    <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                    Download
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Styles>
          <div style={{ minHeight: "25rem" }}>
            <div
              className="sticky table"
              {...getTableProps()}
              style={{ height: 600 }}
            >
              <div className="table-light header" style={{ fontWeight: 600 }}>
                {headerGroups.map(headerGroup => (
                  <div
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map(column => (
                      // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                      <div
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="th"
                      >
                        {/* <div> */}
                        <div
                          className="mb-2 mt-0"
                          {...column.getSortByToggleProps()}
                          onClick={() => handleSort(column)}
                          style={{
                            cursor:'pointer',
                            paddingInlineEnd: `${
                              column.id == "country" ||
                              column.id == "state" ||
                              column.id == "county"
                                ? "10px"
                                : "0px"
                            }`,
                          }}
                        >
                          {column.render("Header")}

                          {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span> */}
                          <br />
                        </div>
                        {console.log(column.id, 'colims')}
                        {column.id != "selection" &&
                        column.id != "updatedDate" &&
                        column.Header !== "Action" ? (
                          <div
                            style={{
                              cursor : 'pointer',
                              width: "100%",
                              position: "absolute",
                              bottom: "10px",
                              display: "flex",
                              paddingInlineEnd: `${
                                column.id == "country" ||
                                column.id == "state" ||
                                column.id == "county"
                                  ? "10px"
                                  : "0px"
                              }`,
                            }}
                          >
                            <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                style={{ width: "85%" }}
                                PopperComponent={PopperMy}
                                disabled={loader}
                                openOnFocus={false}
                                options={
                                  autoEmpty[column.id] || searchQuery[column.id]
                                    ? autoFill[column.id] || []
                                    : []
                                } // Show options only when there's input
                                value={autoEmpty[column.id] || ""}
                                classes={{ option: classes.option }}
                                freeSolo
                                onChange={(event, value) =>
                                  handleAutocompleteChange(column.id, value)
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder="Search..."
                                    variant="filled"
                                    disabled={loader}
                                    size="small"
                                    className={classes.root}
                                    value={searchQuery[column.id] || ""}
                                    onChange={e =>
                                      handleSearchQueryChange(
                                        column.id,
                                        e.target.value
                                      )
                                    }
                                    onKeyDown={e =>
                                      handleKeyDown(
                                        e.key,
                                        column.id,
                                        e.target.value
                                      )
                                    }
                                    InputLabelProps={{
                                      shrink: false,
                                      focused: false,
                                    }}
                                  />
                                )}
                              />
                            </MuiThemeProvider>

                            <button
                              onClick={() => {
                                setShowModal(true)
                                setModalData(column)
                              }}
                              disabled={loader}
                              className={
                                // selectedHeader.includes(column.Header)
                                SelectedFilterIcon(filterArray, column.id)
                                  ? "filter_button_selected"
                                  : "filter_button"
                              }
                              // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            >
                              <i className="bx bx-filter font-size-18"></i>
                            </button>
                          </div>
                        ) : column.id == "updatedDate" ? (
                          <div
                            style={{
                              width: "75%",
                              position: "absolute",
                              bottom: "10px",
                              display: "flex",
                            }}
                          >
                            <Flatpickr
                              className="form-control  d-block flatpickr-input"
                              placeholder="Select Date..."
                              disabled={loader}
                              style={{
                                fontSize: "12px",
                                paddingLeft: 4,
                                paddingBottom: 4,
                                paddingTop: 8,
                                borderBottom: "1px solid #000",
                              }}
                              // onChange={date => handleDateChange(date)}
                              onChange={e =>
                                handleAutocompleteChange(column.id, e)
                              }
                              value={selectedDate}
                              options={{
                                dateFormat: "Y-m-d",
                                // defaultDate: '2023-05-15',
                              }}
                              onKeyDown={e => handleKeyDownDate(column.id, e)}
                              // ref={flatpickrRef}
                            />

                            <div
                              className="icon-container"
                              // onClick={() => flatpickrRef.current.flatpickr.open()}
                              // onKeyDown={handleKeyDownIcon}
                              // tabIndex={0}
                              style={{ position: "absolute", right: 6, top: 8 }}
                            >
                              {/* Replace with Boxicons or Font Awesome icon component */}
                              <i
                                className="fa fa-calendar"
                                // aria-hidden="true"
                              />
                            </div>
                            {/* <div className="input-group-append">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary docs-datepicker-trigger"
                                              // disabled
                                              style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              {loader ? (
                <div
                  className="container-fluid mt-5 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : rows.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <div {...row.getRowProps()} className="tr">
                          {row.cells.map(cell => {
                            return (
                              <div
                                key={cell.id}
                                {...cell.getCellProps({
                                  style: {
                                    backgroundColor: "#fff",
                                  },
                                })}
                                className="td"
                              >
                                {cell.render("Cell")}
                              </div>
                            )
                          })}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <Row className="mt-5">
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "150px 0",
                      fontSize: "25px",
                    }}
                  >
                    No Records Found
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <FilterModal
            clearFilterModal={clearFilterModal}
            reqresData={reqresData}
            setSelectedHeader={setSelectedHeader}
            setFilterArray={setFilterArray}
            isShowModal={showModal}
            filterData={modalData}
            setShowModal={setShowModal}
            tableName={"Regulators"}
            isRemoveDuplicates={true}
            emptyTable={data?.length == 0 ?? false}
            // filterableObjects = {{...searchObject,...filterArray}}
          />
        </Styles>

        {/* </div> */}

        {/* {!fetchError && totalItems ? ( */}

        {/* // ) : null} */}
        {/* <Row className="mt-3 justify-content-md-center justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row> */}
      </Fragment>
    </>
  )
}

const Regulators = props => {
  const [modal, setModal] = useState(false)
  const callUseNavigate = useHistory()
  const navigateTo = url => {
    callUseNavigate.push(url)
  }
  const [desc, setDesc] = useState("")
  const [searching, setSearching] = useState()

  useEffect(() => {
    if (props?.location?.state?.rowData?.isClear == undefined) {
      localStorage.removeItem("selectedFilterArray")
      localStorage.removeItem("regulators/all-regulators/selectedFilterArray")
      localStorage.removeItem("regulators/all-regulators")
    }
  }, [props?.location?.state?.rowData?.isClear])

  const toggle = () => setModal(!modal)

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delRegulator, setDelRegulator] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [regulatorAccess, setRegulatorAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [downloadAccess, setDownloadAccess] = useState([])
  const [sortBy, setSortBy] = useState({
    tableName: "regulator",
    // fieldName: "id",
    // order: "DESC",
    fieldName: "regulatorShortName",
    order: "ASC",
  })

  const dispatch = useDispatch()

  const limit = 5

  const handleSort = column => {
    console.log(column.id, "columns")
    const isAsc = sortBy.fieldName === column.id && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName: "regulator",
        fieldName: column.id,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setRegulatorAccess(authToken && authToken.data.role.rolesPermission[0])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  // console.log(regulatorAccess, "regulatorAccess")
  // console.log(downloadAccess, "downloadAccess")

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const handleDeleteOrder = () => {
    if (delRegulator.id) {
      // dispatch(onDeleteRegulator(delRegulator.id, props.history))
      deleteRegulator(delRegulator.id)
      setDeleteModal(false)
    }
  }

  const customePageCount = Math.ceil(totalItems / limit)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelRegulator(reg)
    setDeleteModal(true)
  }

  const deleteRegulator = regulator => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-regulator?id=${regulator}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Regulator Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        // return message
        ToastError(err)
        // throw message;
      })
  }

  console.log(checkOrg, "checkOrg")

  const fetchData = searchObject => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log(filterArray, "filterArray")
    // const data = JSON.stringify({searchQuery})
    console.log(searchObject, "re-searchQuery")
    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject
    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData = { ...searchObject, ...filterArray }
    // console.log(finalData, "finalData")
    let data = localStorage.getItem("regulators/all-regulators")
    setLoader(true)
    if (data != null) {
      data = JSON.parse(data)
      finalData = { ...data, ...finalData }
    }

    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    }
    // else {
    //     localStorage.removeItem("selectedFilterArray")
    // }

    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem(
        "regulators/all-regulators/selectedFilterArray",
        JSON.stringify(finalData)
      )
    }
    if (Object.keys(finalData).length > 0) {
      // console.log(finalData, "finalData")
      localStorage.setItem(
        "regulators/all-regulators",
        JSON.stringify(finalData)
      )
    }

    const requestData = {
      ...finalData,
      customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      orderBy: {
        ...sortBy,
      },
    }
    const newdata = encryptData(requestData)
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-regulators/?limit=${customePageSize}&pageNo=${currentPage}`,
        { data: newdata },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)
          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        var message
        setLoader(false)
        console.log("test258456987", err)
        RedirectOnUnAuthentication(err, navigateTo)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const dateQuery = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // const data = JSON.stringify({searchQuery})
    console.log(searchQuery, "re-searchQuery")
    const requestData = { updatedDate: searchQuery }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-regulators/?limit=${limit}&pageNo=${currentPage}`,
        { data: encryptData(requestData) },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }
  useEffect(() => {
    console.log("page is loading")
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const columns = [
    {
      Header: "Regulator Short Name",
      accessor: "regulatorShortName",
      filterable: false,
      width: 200,
      disableFilters: true,
      sticky: "left",
      Cell: props => {
        const rowData = props.row.original
        return (
          <Link
            to={{
              pathname: "/regulators/regulator_view",
              state: { rowData },
            }}
          >
            <span style={{ color: "#000" }}>
              {ColumnDataWithTooltip({ content: props?.value })}{" "}
            </span>
          </Link>
        )
      },
    },
    {
      Header: "Regulator Long Name",
      accessor: "regulatorLongName",
      width: 300,
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/regulators/regulator_view",
              state: { rowData },
            }}
          >
            <div style={{ color: "#000" }}>
              {ColumnDataWithTooltip({
                content: cellProps?.value,
                attr: { style: { color: "#000" } },
              })}
            </div>
          </Link>
        )
      },
    },
    {
      Header: "Regulator Description",
      accessor: "regulatorDesc",
      disableFilters: true,
      filterable: false,
      // width:100,
      Cell: cellProps => {
        return cellProps.value ? (
          <div style={{ textAlign: "center" }}>
            <DarkTooltip title="View Details">
              <i
                onClick={() => {
                  setModal(!modal)
                  setDesc(cellProps.value)
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
            </DarkTooltip>
            {/* <UncontrolledTooltip placement="top" target="descToolTip">
              View Details
          </UncontrolledTooltip> */}
          </div>
        ) : (
          DisplayJustifyCenter("-")
        )
      },
    },
    {
      Header: "Regulator Main URL",
      disableFilters: true,
      accessor: "regulatorMainUrl",
      // sticky: "right",
      width: 250,
      filterable: false,
      Cell: cellProps => {
        const rowData = cellProps.row.original.regulatorMainUrl
        // console.log(rowData, 'celpops')
        return (
          <div>
            {cellProps.row.original.regulatorMainUrl &&
            cellProps.row.original.regulatorMainUrl ? (
              <Link
                to={{ pathname: cellProps.row.original.regulatorMainUrl }}
                target="_blank"
              >
                <LightTooltip title={rowData}>
                  <div
                    className="StringOverFlow1"
                    style={{
                      textDecoration: "underline",
                      fontSize: "13px",
                      color: "blue",
                    }}
                  >
                    {cellProps.row.original.regulatorMainUrl
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "")}
                  </div>
                </LightTooltip>
              </Link>
            ) : (
              DisplayJustifyCenter("-")
            )}
          </div>
        )
      },
    },
    // {
    //   Header: "Regulator Update Source",
    //   accessor: "regulatorUpdateSource",
    //   disableFilters: true,
    //   filterable: false,
    //   Cell: cellProps => {
    //     return ColumnDataWithTooltip({content:cellProps?.value })
    //   },
    // },
    // {
    //   Header: "Regulator Update Contact",
    //   accessor: "regulatorUpdateContact",
    //   disableFilters: true,
    //   filterable: false,
    //   Cell: cellProps => {
    //     return ColumnDataWithTooltip({content:cellProps?.value })
    //   },
    // },

    {
      Header: "Regulator Country",
      accessor: "country",
      disableFilters: true,
      width: 115,
    },
    {
      Header: "Regulator State",
      accessor: "state",
      disableFilters: true,
      width: 115,
    },
    {
      Header: "Regulator County",
      accessor: "county",
      filterable: false,
      disableFilters: true,
      width: 115,
      // sticky: "left",
      Cell: cellProps => {
        return ColumnDataWithTooltip({ content: cellProps?.value })
      },
    },
    {
      Header: "Regulator Last Update Date",
      width: 102,
      accessor: "updatedDate",
      disableFilters: true,
      Cell: cellProps => {
        const date1 = FormatedDate(cellProps.value)
        // const dateFormat = format ? format : ;
        // return date1 ? date1 : '-'
        if (cellProps.value) {
          return date1
        } else {
          return DisplayJustifyCenter("-")
        }
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      disableFilters: true,
      sticky: "right",
      filterable: false,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "1.2rem" }}
          >
            {/* <LightTooltip  */}
            {regulatorAccess &&
            regulatorAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    to={{
                      pathname: "/regulators/regulator_update",
                      state: { rowData },
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}

            {regulatorAccess && regulatorAccess.readAccess === true ? (
              <Badge className="font-size-15 badge-soft-primary " pill>
                <DarkTooltip title="View">
                  <Link
                    to={{
                      pathname: "/regulators/regulator_view",
                      state: { rowData },
                    }}
                    // style={{backgroundColor: "rgba(85, 110, 230, 0.2)"}}
                    onClick={() => {
                      const orderData = cellProps.row.original
                      // console.log(orderData, 'data')
                      // handleOrderClick(orderData);
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "blue", cursor: "pointer" }}
                      className="mdi mdi-eye-outline  font-size-18"
                      id="customerViewtooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}

            {regulatorAccess &&
            regulatorAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                {/* <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                > */}
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            ) : (
              ""
            )}
          </ul>
        )
      },
    },
  ]
  const reports = [
    {
      title: "Total Regulators",
      iconClass: "bx-copy-alt",
      description: totalItems,
    },
    { title: "New Regulators", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Regulators Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Regulators Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  // useEffect(() => {
  //   toastr.success("testing")
  // },[])

  const handleRender = () => {
    setDelete(!is_delete)
  }
  document.title = "Regulators"
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Regulator Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
              {/* {desc && desc} */}
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          {/* <Row>
            {reports.map((report, key) => (
              <Col
                xs={12}
                className="col-xl-3 col-md-6 col-sm-12"
                key={"_col_" + key}
              >
                <Card className="mini-stats-wid">
                  <CardBody
                    className="custome-card"
                    style={{ padding: "10px 11px !important" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-muted">{report.title}</h6>
                      </div>
                      <div
                        className="avatar-sm bg-primary align-self-center mini-stat-icon"
                        style={{ borderRadius: "100%" }}
                      >
                        <span
                          className="avatar-title bg-primary"
                          style={{ borderRadius: "100%" }}
                        >
                          <p className="mb-0 font-size-18">
                            {report.description}
                          </p>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                    columns={columns}
                    reqresData={reqresData}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    props={props}
                    filterArray={filterArray}
                    regulatorAccess={regulatorAccess}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    handleRender={handleRender}
                    setCurrentPage={setCurrentPage}
                    setFilterArray={setFilterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    fetchData={fetchData}
                    dateQuery={dateQuery}
                    setSearching={setSearching}
                    data={reqresData}
                    pageCount={customePageCount}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    loader={loader}
                  />
                  <Row className="justify-content-center mt-3">
                    {/* <Col className="col-auto" >
                      Showing page of 1
                    </Col> */}
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => {
                          setCurrentPage(page)
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Regulators
