import { takeEvery, put, call, all, fork } from "redux-saga/effects"
import toastr from "toastr"
import {
  GET_OBLIGATIONS,
  ADD_NEW_OBLIGATION,
  UPDATE_OBLIGATION,
  DELETE_OBLIGATION,
} from "./actionTypes"

import {
  getObligationsSuccess,
  getObligationsFail,
  addObligationSuccess,
  addObligationFail,
  updateObligationSuccess,
  updateObligationFail,
  deleteObligationSuccess,
  deleteObligationFail,
} from "./actions"

import {
  getObligations,
  addNewObligation,
  updateObligation,
  deleteObligation,
} from "../../helpers/fakebackend_helper"
import { ToastError } from "helpers/helper_function"

function* fetchObligations({ urlData }) {
  // if(urlData){
  const limit = urlData.limit
  const page = urlData.currentPage
  // console.log(limit, page,"page in saga")
  // }
  try {
    const response = yield call(getObligations, limit, page)
    yield put(getObligationsSuccess(response))
  } catch (error) {
    console.log(error, "error")
    yield put(getObligationsFail(error))
  }
}

function* onAddNewObligation({ payload: { obligation, history,path } }) {
  const resirectUrl = path?path:"/obligations/all-obligations"
  try {
    const response = yield call(addNewObligation, obligation)
    yield put(addObligationSuccess(response))
    toastr.success("Obligation Successfully Added.")
    history.push(resirectUrl)
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(addObligationFail(error))
  }
}

function* onUpdateObligation({ payload: { obligation, history,path } }) {
  console.log("path58456",path);
  const resirectUrl = path?path:"/obligations/all-obligations"
  try {
    const response = yield call(updateObligation, obligation)
    yield put(updateObligationSuccess(response))
    toastr.success("Obligation Successfully updated.")
    history.push(resirectUrl)
  } catch (error) {
    console.log(error, "error")
     ToastError(error)
    yield put(updateObligationFail(error))
  }
}

function* onDeleteObligation({ payload: { obligation, history,path } }) {
  const resirectUrl = path?path:"/obligations/all-obligations"
  try {
    const response = yield call(deleteObligation, obligation)
    console.log("response", response)
    yield put(deleteObligationSuccess(response))
    toastr.success("Obligation Successfully Deleted.")
    history.push(resirectUrl)
  } catch (error) {
    console.log("error", error)
     ToastError(error)
    yield put(deleteObligationFail(error))
  }
}

export function* watchFetchObligations() {
  yield takeEvery(GET_OBLIGATIONS, fetchObligations)
}

function* obligationsSaga() {
  yield all([fork(watchFetchObligations)])
  yield takeEvery(ADD_NEW_OBLIGATION, onAddNewObligation)
  yield takeEvery(UPDATE_OBLIGATION, onUpdateObligation)
  yield takeEvery(DELETE_OBLIGATION, onDeleteObligation)
}

export default obligationsSaga
