import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap" 
import { useDispatch } from "react-redux"
import { deleteObligation as onDeleteObligation } from "../../store/actions"
import DeleteModal from "../../components/Common/DeleteModal"
import { Link } from "react-router-dom"
import { FormatedDate } from "helpers/helper_function"

const ObligationView = props => {
  const [delObligation, setDelObligation] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [is_delete, setDelete] = useState(false)
  const [obligationAccess, setObligationAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setObligationAccess(authToken && authToken.data.role.rolesPermission[2])
  }, [])

  const dispatch = useDispatch()
  const location = useLocation()
  const data = location.state.rowData
  let rowData = data
  document.title = "Obligations"
  const editObligation = () => {
    props.history.replace({
      pathname: "/test-obligations/obligation_update",
      state: { rowData },
    })
  }

  const onClickDelete = rowData => {
    setDelObligation(rowData)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    setDelete(!is_delete)
    if (delObligation.id) {
      dispatch(onDeleteObligation(delObligation.id, props.history))
      setDeleteModal(false)
    }
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligation Details" path="/obligations/all-obligations" /> */}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Obligation Details</h4>
            <div className="page-title-right">
              <div className="ms-auto">
                <ul className="nav nav-pills" style={{ justifyContent: "end" }}>
                  {obligationAccess &&
                  obligationAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => editObligation(rowData)}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                        Edit
                      </Button>
                    </li>
                  ) : (
                    ""
                  )}
                  {obligationAccess &&
                  obligationAccess.writeAccess == true &&
                  checkOrg === 3 ? (
                    <li className="nav-item">
                      <Button
                        onClick={() => {
                          onClickDelete(rowData)
                        }}
                        style={{
                          backgroundColor: "#556ee6",
                          marginLeft: 5,
                          marginRight: 5,
                          color: "#fff",
                          textTransform: "none",
                        }}
                      >
                        <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                        Delete
                      </Button>
                      <DeleteModal
                        show={deleteModal}
                        onDeleteClick={handleDeleteOrder}
                        onCloseClick={() => setDeleteModal(false)}
                      />
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <div className="row">
                      <div className="col-12 col">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                          <h4 className=""></h4>
                          <div
                            className="page-title-right"
                            style={{ marginTop: "7px" }}
                          >
                            <ol className="breadcrumb m-0">
                              <li className="breadcrumb-item">
                                <Link to="/test-obligations/all-obligations">
                                  Obligation
                                </Link>
                              </li>
                              <li
                                className="active breadcrumb-item"
                                aria-current="page"
                              >
                                Obligation Details
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Row>
                        <Col md={4} className="px-4">
                        <h5 className="mb-sm-0 font-size-18">
                        Obligation Details
                      </h5>
                        </Col> */}
                  {/* <Col md={8} className="d-flex flex-wrap gap-2 justify-content-end">
                            <button className="btn btn-primary">
                            <i className="bx bx-printer font-size-16 align-middle me-1"></i>
                                Print
                            </button>
                        </Col> */}
                  {/* </Row> */}
                  <div className="table-responsive mt-3 px-0">
                    <Table className="table-striped px-0">
                      <tbody>
                        {/* <tr>
                                <th style={{ width: "15%" }}>Disclosure Obl_ID</th>
                                <td style={{width:"75%"}}>{data && data.dis_Obl_ID}</td>
                            </tr> */}
                        <tr>
                          <th style={{ width: "15%" }}>Obligation ID</th>
                          <td style={{ width: "75%" }}>{data && data.obId}</td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Obligation Name</th>
                          <td style={{ width: "75%" }}>
                            {data && data.obligationName}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>
                            Obligation Description
                          </th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.obg_desc} */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data && data.obligationDesc,
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Citation URL</th>
                          <td style={{ width: "75%" }}>
                            <div style={{ width: "50%" }}>
                              {data && data.citationUrl}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Regulator Short Name</th>
                          <td style={{ width: "75%" }}>
                            {data && data.issuingAuthority}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Reg Law Short Name</th>
                          <td style={{ width: "75%" }}>
                            {data && data.regLaw}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Citation</th>
                          <td style={{ width: "75%" }}>
                            {data && data.citation}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Obligation Notes</th>
                          <td style={{ width: "75%" }}>
                            {(data && data.obligationNote) || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Status</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.status || "-"} */}
                            {data && data.status == 1
                              ? "Pending"
                              : data && data.status == 2
                              ? "Approved"
                              : "Removed"}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ width: "15%" }}>Last Updated Date</th>
                          <td style={{ width: "75%" }}>
                            {/* {data && data.regEffectiveDate} */}
                            {data && data.updatedDate

                              ?
                              
                              FormatedDate(data && data.updatedDate )  
                             
                              : "-"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ObligationView
