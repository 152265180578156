import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes, { string } from "prop-types"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import toastr from "toastr"
import debounce from "lodash/debounce"
import Flatpickr from "react-flatpickr"
import { Select, MenuItem, FormControl } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useSticky } from "react-table-sticky"
import Tooltip from "@material-ui/core/Tooltip"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import { map, split } from "lodash"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import Pagination from "../../components/Common/Pagination"

import styled from "styled-components"

import * as moment from "moment"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { format } from "date-fns"
import DateFnsUtils from "@date-io/date-fns"
//   import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Popper } from "@material-ui/core"
import FilterModal from "../../components/FilterModal/FilterModal"
import axios from "axios"
import { DisplayJustifyCenter, DownloadExelFrom, RedirectOnUnAuthentication, RemoveSpaces, ToastError, encryptData, trimLeft } from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import TableHeader from "components/Common/TableHeader"
import { HEADER } from "helpers/url_helper"

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const exportToExcel = (rowData, checkOrg) => {
  const customizedData = rowData.map(row => {
    const disclosure =
      checkOrg !== 3 ? row.custDisclosure : row.disclosure || {} // Add a check for disclosure object
    const obligation =
      checkOrg !== 3 ? row.custObligation : row.obligation || {}
    return {
      ...row,
      disclosureTitle: disclosure.disclosureTitle || "", // Add a default value if undefined
      disclosureDesc: disclosure.disclosureDesc
        ? disclosure.disclosureDesc.replace(/<[^>]+>/g, "")
        : "", // Add a check for disclosureDesc
      text:
        disclosure && disclosure.text
          ? disclosure.text.replace(/<[^>]+>/g, "")
          : "",
      disclosureSpecificUrl: disclosure.disclosureSpecificUrl,
      disclosureSpecificCitation: disclosure.disclosureSpecificCitation,
      status:
        disclosure.status == 1
          ? "Pending"
          : disclosure.status == 2
          ? "Approved"
          : "Removed",
      safeHarbor:
        disclosure.safeHarbor == 1
          ? "All"
          : disclosure.safeHarbor == 2
          ? "Part"
          : "None",
      disclosureType:
        disclosure.disclosureType == 1
          ? "Text"
          : disclosure.disclosureType == 2
          ? "Doc"
          : "Other",
      language: "English",
      prodGroupInvestments: disclosure.prodGroupInvestments == true ? "Y" : "",
      prodGroupDeposits: disclosure.prodGroupDeposits == true ? "Y" : "",
      prodGroupAuto: disclosure.prodGroupAuto == true ? "Y" : "",
      prodGroupMortgage: disclosure.prodGroupMortgage == true ? "Y" : "",
      prodGroupCard: disclosure.prodGroupCard == true ? "Y" : "",
      prodGroupOther: disclosure.prodGroupOther == true ? "Y" : "",
      lifeMarketing: disclosure.lifeMarketing == true ? "Y" : "",
      lifeOpenOnboard: disclosure.lifeOpenOnboard == true ? "Y" : "",
      lifeServicing: disclosure.lifeServicing == true ? "Y" : "",
      lifeCloseOutEnd: disclosure.lifeCloseOutEnd == true ? "Y" : "",
      sample: disclosure.sample,
      sampleUrl1: disclosure.sampleUrl1,
      sampleUrl2: disclosure.sampleUrl2,
      sampleUrl3: disclosure.sampleUrl3,
      communicationTypes: disclosure.communicationTypes
        ? disclosure.communicationTypes
        : "-",
      disclosureNote: disclosure.disclosureNote,
      obligationName: obligation.obligationName || "", // Add a default value if undefined
      obligationDesc: obligation.obligationDesc
        ? obligation.obligationDesc.replace(/<[^>]+>/g, "")
        : "", // Add a check for obligationDesc
      citationUrl: obligation.citationUrl,
      issuingAuthority: obligation.issuingAuthority,
      regLaw: obligation.regLaw,
      citation: obligation.citation,
      obligationNote: obligation.obligationNote,
    }
  })
  const filteredData = customizedData.map(
    ({ incrementId, disclosure, obligation, id, language, ...rest }) => rest
  )

  const clientFilterData = customizedData.map(
    ({
      incrementId,
      id,
      disclosure,
      obligation,
      language,
      disclosureNote,
      lifeCloseOutEnd,
      lifeMarketing,
      lifeOpenOnboard,
      lifeServicing,
      prodGroupAuto,
      prodGroupCard,
      prodGroupDeposits,
      prodGroupInvestments,
      prodGroupMortgage,
      prodGroupOther,
      communicationTypes,
      sample,
      sampleUrl1,
      sampleUrl2,
      sampleUrl3,
      custContentId,
      custDisclosure,
      custObligation,
      custOrganization,
      ...rest
    }) => rest
  )

  const arrangedColumns = [
    "contentId",
    "regLaw",
    "disId",
    "obId",
    "obligationName",
    "disclosureTitle",
    "disclosureDesc",
    "disclosureType",
    "disclosureSpecificUrl",
    "disclosureSpecificCitation",
    "status",
    // "language",
    "prodGroupInvestments",
    "prodGroupDeposits",
    "prodGroupAuto",
    "prodGroupMortgage",
    "prodGroupCard",
    "prodGroupOther",
    "lifeMarketing",
    "lifeOpenOnboard",
    "lifeServicing",
    "lifeCloseOutEnd",
    "communicationTypes",
    "text",
    "sample",
    "sampleUrl1",
    "sampleUrl2",
    "sampleUrl3",
    "safeHarbor",
    "disclosureNote",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "citation",
    "obligationNote",
  ]

  const clientArrangedColumns = [
    "contentId",
    "regLaw",
    "disId",
    "obId",
    "obligationName",
    "disclosureTitle",
    "disclosureDesc",
    "disclosureType",
    "disclosureSpecificUrl",
    "disclosureSpecificCitation",
    "status",
    // "language",
    "text",
    "safeHarbor",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "citation",
    "obligationNote",
  ]

  const worksheet = XLSX.utils.json_to_sheet(
    checkOrg !== 3 ? clientFilterData : filteredData,
    {
      header: checkOrg !== 3 ? clientArrangedColumns : arrangedColumns,
    }
  )

  const columns = [
    "Content ID",
    "Reg Law Short Name",
    "Disclosure ID",
    "Obligation ID",
    "Obligation Name",
    "Disclosure Title",
    "Disclosure Description",
    "Disclosure Type",
    "Disclosure-specific URL",
    "Disclosure-specific Citation",
    "Status",
    // "Language",
    "Discl Inv",
    "Discl Dep",
    "Discl Auto",
    "Discl Mort",
    "Discl Card",
    "Discl Other",
    "Life - Marketing",
    "Life - Open Onboard",
    "Life - Servicing",
    "Life - Close-out End",
    "Communication Types",
    "Text",
    "Sample",
    "Sample URL1",
    "Sample URL2",
    "Sample URL3",
    "Safe Harbor (All, Part, None)",
    "Disclosure Notes",
    "Obligation Description",
    "Citation URL",
    "Regulator Short Name",
    "Citation",
    "Obligation Notes",
  ]

  const clientColumns = [
    "Content ID",
    "Reg Law Short Name",
    "Disclosure ID",
    "Obligation ID",
    "Obligation Name",
    "Disclosure Title",
    "Disclosure Description",
    "Disclosure Type",
    "Disclosure-specific URL",
    "Disclosure-specific Citation",
    "Status",
    // "Language",
    "Text",
    "Safe Harbor (All, Part, None)",
    "Obligation Description",
    "Citation URL",
    "Regulator Short Name",
    "Citation",
    "Obligation Notes",
  ]

  XLSX.utils.sheet_add_aoa(
    worksheet,
    [checkOrg !== 3 ? clientColumns : columns],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Linked Report.xlsx")
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 110,
  },

  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}

const TableContainer = ({
  loader,
  columns,
  reqresData,
  checkOrg,
  setCheckOrg,
  filterArray,
  handleSort,
  setSortBy,sortBy,
  searchObject,
  downloadAccess,
  props,
  customePageSize,
  setCustomePageSize,
  fetchData,
  dateQuery,
  setSearchObject,
  setCurrentPage,
  handleRender,
  setFilterArray,
  data,
  pageCount: customePageCount,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      // disableRowSelect: true,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useFlexLayout,
    useBlockLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,

          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div style={{ backgroundColor: "#fff" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const [clearFilterModal, setClearFilterModal] = useState(false)
  const [exportObject, setExportObject] = useState({})
  const [typeQuery, setTypeQuery] = useState({})
  const [valueQuery, setValueQuery] = useState({})
  const [harborQuery, setHarborQuery] = useState({})
  const [statusQuery, setStatusQuery] = useState({})
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      disObliIds: ids,
      orderBy: {
        ...sortBy,
      },
      customerorgId:
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/export-disclosure-obligation-organization-link-report`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
          responseType:'blob'
        }
      )
      .then(response => {
        // exportToExcel(response.data.data, checkOrg)
        DownloadExelFrom({responseData:response.data,fileTitle: "Linked Report"})
        setBtnLoader(false)
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        ToastError(error)
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        setBtnLoader(false)
      })
  }

  const handleDownloadFormat = () => {
    const csvData =
      "Regulator Short Name,Regulator Long Name,Regulator Description,Regulator Main URL,Regulator Update Source,Regulator Update Contact,Regulator Country,Regulator State"
    const filename = "Regulators_format.csv"
    const csvBlob = new Blob([csvData], { type: "text/csv" })
    const csvURL = window.URL.createObjectURL(csvBlob)

    const tempLink = document.createElement("a")
    tempLink.href = csvURL
    tempLink.setAttribute("download", filename)
    tempLink.click()

    // Cleanup
    window.URL.revokeObjectURL(csvURL)
    tempLink.remove()
  }

  const handleSearchQueryChange = (fldName, value) => {
    console.log(fldName, value, "test fldname")
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    const splitValues = fldName.split(".")
    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = fldName
    }
    console.log(columnName, value, "check")

    setSearchQuery(prev => ({ ...prev, [columnName]: value }))
    const requestData = {
      tableName: `${"custLinkingCustDisclosureCustObligation"}`,
      feildName: `${columnName}`,
      searchValue: value ? value : "",
      relationFeild: `${
        columnName === "disclosureTitle" ||
        columnName === "disclosureDesc" ||
        columnName === "disclosureSpecificUrl" ||
        columnName === "disclosureSpecificCitation" ||
        columnName === "disclosureType" ||
        columnName === "communicationTypes" ||
        columnName === "text" ||
        columnName === "disclosureNote"
          ? "custDisclosure"
          : columnName === "obligation" ||
            columnName === "obligationName" ||
            columnName === "obligationDesc" ||
            columnName === "citationUrl" ||
            columnName === "issuingAuthority" ||
            columnName === "regLaw" ||
            columnName === "citation" ||
            columnName === "obligationNote"
          ? "custObligation"
          : ""
      }`,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [fldName]: response.data.data.map(item => item[columnName]),
        }))
        setSearchQuery(prev => ({ ...prev, [columnName]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnName]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => {
    const splitValues = columnId.split(".")

    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = columnId
    }

    const field_name = `${
      columnName == "disclosure"
        ? "disclosureTitle"
        : columnName == "obligation"
        ? "obligationName"
        : columnName
    }`
    handleSearchQueryChange(columnName, value)

    setAutoEmpty(prev => ({ ...prev, [columnId]: value }))

    if (value == null) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: "" }
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else {
      const updatedSearchQuery = { ...searchQuery, [columnName]: value }
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    console.log("API call triggered:", value)
  }

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value")
    const splitValues = columnKey.split(".")

    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = columnKey
    }
    let updatedValue
    if (value === "") {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (value === false) {
      updatedValue = false // Set the value to false when selecting "No"
    } else {
      updatedValue = true // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnName]: updatedValue }
    setSearchQuery(prevSearchQuery => ({
      ...prevSearchQuery,
      [columnName]: updatedValue,
    }))
    setValueQuery(prevSearchQuery => ({
      ...prevSearchQuery,
      [columnKey]: updatedValue,
    }))
    setSearchObject(updatedSearchQuery)
  }

  const handleStatusChange = (columnKey, value) => {
    console.log(value, "value")

    const splitValues = columnKey.split(".")

    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = columnKey
    }
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnName]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: "",
      }))
      setStatusQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 1,
      }))
      setStatusQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 2,
      }))
      setStatusQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 3,
      }))
      setStatusQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnName], "column id")
  }

  const handleTypeChange = (columnKey, value) => {
    console.log(columnKey, "columnKey")

    const splitValues = columnKey.split(".")

    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = columnKey
    }

    console.log(columnName, "columnName")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnName]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: "",
      }))
      setTypeQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 1,
      }))
      setTypeQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 2,
      }))
      setTypeQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 3,
      }))
      setTypeQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const handleSafeHarborChange = (columnKey, value) => {
    console.log(value, "value")

    const splitValues = columnKey.split(".")

    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = columnKey
    }
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnName]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: "",
      }))
      setHarborQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 1,
      }))
      setHarborQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 2,
      }))
      setHarborQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnName]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnName]: 3,
      }))
      setHarborQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }

  const clearFilter = () => {
    setSearchQuery({})
    setTypeQuery({})
    setValueQuery({})
    setHarborQuery({})
    setSortBy({
      tableName: "custObligation",
      fieldName: "regLaw",
      order: "ASC",
    })
    setStatusQuery({})
    setSelectedHeader([])
    setAutoEmpty({})
    setAutoFill({})
    setFilterArray({})
    setSearchObject({})
    setSelectedDate(null)
    setCurrentPage(1)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          border: "1px solid #ced4da",
          borderBottom: 0,
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          // '&&[class*="MuiOutlinedInput-root"] $input': {
          //   padding: "1px"
          // },
          "& .MuiAutocomplete-inputRoot": {
            color: "purple",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
              {
                // Default left padding is 6px
                padding: 26,
              },
          },
        },
      },
    },
  })

  const classes = useStyles()

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)

  const authToken = JSON.parse(localStorage.getItem("authUser"))

  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  // const classes = useStyles();

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])

  return (
    <Fragment>
      <Row className="pt-3">
        {/* <Col
          xs={12}
          md={12}
          lg={12}
          xl={6}
          className="d-flex flex-column flex-lg-row gap-3 align-items-center"
        >
          <div className="d-flex  align-items-center ">
            <h5 className="font-size-18 mb-0">Linked Report</h5>
            <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{ width: "150px", marginLeft: "10px" }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
          </div> 
        </Col> */}


      
        {/* {console.log(headerGroups && headerGroups[0].headers[2], "headerGroups")} */}
        <Col
          // sm={12}
          // md={12}
          // lg={12}
          // xl={6}
          className="LDCR_Table_Header py-2"
        >
          <div>
              <TableHeader
            heading={"Linked Report"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
          </div>
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <div
              style={{
                display: "flex",
                alignItems: "end",
              }}
            >
              <MuiThemeProvider theme={theme}>
                <Autocomplete
                  // classes={classes}
                  style={{
                    width: 170,
                    padding: "5px",
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                  disabled={loader}
                  PopperComponent={PopperMy}
                  // disableClearable={true}
                  options={autoFill["regLaw"] || []}
                  // getOptionLabel={(option) => option || ""}
                  value={autoEmpty["regLaw"] || ""}
                  classes={{
                    option: classes.option,
                  }}
                  // freeSolo
                  onChange={(event, value) =>
                    handleAutocompleteChange("regLaw", value&&trimLeft(value))
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Search Reg Law Short Name..."
                      variant="filled"
                      disabled={loader}
                      size="small"
                      // style={{padding: "1px"}}
                      // className="form-control"
                      className={classes.root}
                      value={searchQuery["regLaw"] || ""}
                      onChange={e =>
                        handleSearchQueryChange("regLaw", e.target.value)
                      }
                      InputLabelProps={{
                        shrink: false,
                        focused: false,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </MuiThemeProvider>
              <button
                onClick={() => {
                  setShowModal(true)
                  setModalData(headerGroups && headerGroups[0].headers[2])
                }}
                disabled={loader}
                style={{ height: "32px" }}
                className={
                  selectedHeader.includes(
                    headerGroups && headerGroups[0].headers[2].Header
                  )
                    ? "filter_button_selected"
                    : "filter_button"
                }
                // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
              >
                <i className="bx bx-filter font-size-18"></i>
              </button>
            </div>
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {downloadAccess && downloadAccess.readAccess === true ? (
              btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={handleExport}>
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Download
                </button>
              )
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>

      {/* {loader ? (
          <div
            className="container-fluid mt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner className="ms-2" color="primary" />
          </div>
        ) : ( */}
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 500 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span>
                              <br /> */}
                      </div>
                      {/* {console.log(column.id, 'colims')} */}
                      {column.id != "selection" &&
                      column.id != "custDisclosure.prodGroupInvestments" &&
                      column.id != "custDisclosure.prodGroupDeposits" &&
                      column.id != "custDisclosure.prodGroupAuto" &&
                      column.id != "custDisclosure.prodGroupMortgage" &&
                      column.id != "custDisclosure.prodGroupCard" &&
                      column.id != "custDisclosure.prodGroupOther" &&
                      column.id != "custDisclosure.lifeMarketing" &&
                      column.id != "custDisclosure.lifeOpenOnboard" &&
                      column.id != "custDisclosure.lifeOpenOnboard" &&
                      column.id != "custDisclosure.lifeServicing" &&
                      column.id != "custDisclosure.lifeCloseOutEnd" &&
                      column.id != "custDisclosure.disclaimer" &&
                      column.id != "custDisclosure.status" &&
                      column.id != "custDisclosure.disclosureType" &&
                      column.id != "custDisclosure.safeHarbor" &&
                      column.id != "custDisclosure.obligations" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{ width: "70%" }}
                              disabled={loader}
                              PopperComponent={PopperMy}
                              // disableClearable={true}
                              options={autoFill[column.id] || []}
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              // freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value&&trimLeft(value))
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  disabled={loader}
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={handleKeyDown}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          <button
                            onClick={() => {
                              setShowModal(true)
                              setModalData(column)
                            }}
                            disabled={loader}
                            className={
                              selectedHeader.includes(column.Header)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                            // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                      ) : column.id == "custDisclosure.prodGroupInvestments" ||
                        column.id == "custDisclosure.prodGroupDeposits" ||
                        column.id == "custDisclosure.prodGroupAuto" ||
                        column.id == "custDisclosure.prodGroupMortgage" ||
                        column.id == "custDisclosure.prodGroupCard" ||
                        column.id == "custDisclosure.prodGroupOther" ||
                        column.id == "custDisclosure.lifeMarketing" ||
                        column.id == "custDisclosure.lifeOpenOnboard" ||
                        column.id == "custDisclosure.lifeOpenOnboard" ||
                        column.id == "custDisclosure.lifeServicing" ||
                        column.id == "custDisclosure.lifeCloseOutEnd" ||
                        column.id == "custDisclosure.disclaimer" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <Select
                              className={classes.select}
                              disabled={loader}
                              value={
                                valueQuery[column.id] != undefined
                                  ? valueQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleValueChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value={""}>All</MenuItem>{" "}
                              <MenuItem value={true}>Yes</MenuItem>{" "}
                              <MenuItem value={false}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ) : column.id == "custDisclosure.disclosureType" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <Select
                              className={classes.select}
                              disabled={loader}
                              value={
                                typeQuery[column.id] != undefined
                                  ? typeQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleTypeChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Text</MenuItem>
                              <MenuItem value={2}>Doc</MenuItem>
                              <MenuItem value={3}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ) : column.id == "custDisclosure.status" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <Select
                              className={classes.select}
                              disabled={loader}
                              value={
                                statusQuery[column.id] != undefined
                                  ? statusQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleStatusChange(
                                  column.id,
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Pending</MenuItem>
                              <MenuItem value={2}>Approved</MenuItem>
                              <MenuItem value={3}>Removed</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ) : column.id == "custDisclosure.safeHarbor" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <Select
                              className={classes.select}
                              disabled={loader}
                              value={
                                harborQuery[column.id] != undefined
                                  ? harborQuery[column.id]
                                  : ""
                              }
                              displayEmpty
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              onChange={event =>
                                handleSafeHarborChange(
                                  column.id,
                                  event.target.value
                                )
                              }
                            >
                              <MenuItem value="">Default</MenuItem>
                              <MenuItem value={1}>All</MenuItem>
                              <MenuItem value={2}>Part</MenuItem>
                              <MenuItem value={3}>None</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        {console.log(modalData.id,'modalData.id')}
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          filterArray={filterArray}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          isRemoveDuplicates={true}
          relationFeild={`${
            modalData.id === "disclosure" ||
            modalData.id === "custDisclosure.disclosureTitle" ||
            modalData.id === "custDisclosure.disclosureDesc" ||
            modalData.id === "custDisclosure.disclosureSpecificUrl" ||
            modalData.id === "custDisclosure.disclosureSpecificCitation" ||
            modalData.id === "custDisclosure.disclosureType" ||
            modalData.id === "custDisclosure.communicationTypes" ||
            modalData.id === "custDisclosure.text" ||
            
            modalData.id === "custDisclosure.disclosureNote"
              ? "custDisclosure"
              : modalData.id === "custObligation" ||
                modalData.id === "custObligation.obligationName" ||
                modalData.id === "custObligation.obligationDesc" ||
                modalData.id === "obligation.citationUrl" ||
                modalData.id === "custObligation.issuingAuthority" ||
                modalData.id === "custObligation.regLaw" ||
                modalData.id === "custObligation.citation" ||
                modalData.id === "custObligation.obligationNote"
              ? "custObligation"
              : ""
          }`}
          tableName={
            //  `${
            // modalData.id === "disclosure" ||
            // modalData.id === "disclosure.disclosureTitle" ||
            // modalData.id === "disclosure.disclosureDesc" ||
            // modalData.id === "disclosure.disclosureSpecificUrl" ||
            // modalData.id === "disclosure.disclosureSpecificCitation" ||
            // modalData.id === "disclosure.communicationTypes" ||
            // modalData.id === "disclosure.text" ||
            // modalData.id === "disclosure.sample" ||
            // modalData.id === "disclosure.sampleUrl1" ||
            // modalData.id === "disclosure.sampleUrl2" ||
            // modalData.id === "disclosure.sampleUrl3" ||
            // modalData.id === "disclosure.disclosureNote"
            //   ? "Disclosure"
            //   : modalData.id === "obligation" ||
            //     modalData.id === "obligation.obligationName" ||
            //     modalData.id === "obligation.obligationDesc" ||
            //     modalData.id === "obligation.citationUrl" ||
            //     modalData.id === "obligation.issuingAuthority" ||
            //     modalData.id === "obligation.regLaw" ||
            //     modalData.id === "obligation.citation" ||
            //     modalData.id === "obligation.obligationNote"
            //   ? "Obligation"
            // :
            "custLinkingCustDisclosureCustObligation"
            // }`
          }
        />
      </Styles>
      {/* )} */}
    </Fragment>
  )
}

const CustLinkedReport = props => {
  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [modalDisID, setModalDisID] = useState("")
  const [modalObID, setModalObID] = useState("")
  const [withData, setWithData] = useState(false)
  const [rowID, setRowID] = useState()
  const [disclosureDetail, setDisclosureDetail] = useState([])
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [downloadAccess, setDownloadAccess] = useState([])
  const [checkOrg, setCheckOrg] = useState()
  const [sortBy, setSortBy] = useState({
    // tableName: "disObjLink",
    tableName: "custObligation",
    fieldName: "regLaw",
    order: "ASC",
  })
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  useEffect(() => {
    setSortBy(prevSortBy => ({
      ...prevSortBy,
      // tableName: "custDisObli",
      tableName:"custObligation"
    }))
  }, [checkOrg])

  const dispatch = useDispatch()

  const limit = 20

  const handleSort = column => {
    console.log(column.id, "columns")

    const fldName = column && column.id

    const splitValues = fldName.split(".")

    let columnName
    if (splitValues.length > 1) {
      columnName = splitValues[1]
    } else {
      columnName = fldName
    }

    console.log(columnName, "columnName")

    const isAsc = sortBy.fieldName === columnName && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (columnName !== "selection") {
      setSortBy({
        tableName: `${
          columnName === "disclosureTitle" ||
          columnName === "disclosureDesc" ||
          columnName === "status" ||
          columnName === "disclosureType" ||
          columnName === "disclosureSpecificUrl" ||
          columnName === "disclosureSpecificCitation" ||
          columnName === "disclosureType" ||
          columnName === "communicationTypes" ||
          columnName === "text" ||
          columnName === "sample" ||
          columnName === "sampleUrl1" ||
          columnName === "sampleUrl2" ||
          columnName === "sampleUrl3" ||
          columnName === "disclosureNote"
            ? "custDisclosure"
            : columnName === "obligation" ||
              columnName === "obligationName" ||
              columnName === "obligationDesc" ||
              columnName === "citationUrl" ||
              columnName === "issuingAuthority" ||
              columnName === "regLaw" ||
              columnName === "citation" ||
              columnName === "obligationNote"
            ? "custObligation"
            : "custDisObli"
        }`,
        // fieldName: column.id,
        fieldName: columnName,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const customePageCount = Math.ceil(totalItems / limit)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const toggle = data => {
    setModal(!modal)
    setRowID(data.id)
    setWithData(true)
    if (data.disclosure) {
      setModalDisID(data.disclosure.id)
    }
    if (data.obligation) {
      setModalObID(data.obligation.id)
    }
  }

  useEffect(() => {
    if (modal === false) {
      setModalObID("")
      setModalDisID("")
      setWithData(false)
    }
  }, [modal])


  const contentRef = React.useRef(null);
 
  const setInnerText = (content) => {
    // if (contentRef.current) {
      const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    // }
  }


  const columns = [
    {
      Header: "Content ID",
      accessor: "contentId",
      // width: 200,
      filterable: false,
      disableFilters: true,
      sticky: "left",
      show: true,
    },
    {
      Header: "Reg Law Short Name",
      accessor: "custObligation.regLaw",
      filterable: false,
      disableFilters: true,
      
      Cell: cellProps => {
        return cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value || "-"
      },
      show: true,
    },
    {
      Header: "Disclosure ID",
      accessor: "disId",
      // width: 120,
      filterable: false,
      disableFilters: true,
      show: true,
    },
    {
      Header: "Obligation ID",
      accessor: "obId",
      // width: 140,
      filterable: false,
      disableFilters: true,
      show: true,
    },
    {
      Header: "Obligation Name",
      accessor: "custObligation.obligationName",
      filterable: false,
      disableFilters: true,
      // width: 140,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <span style={{ color: "#000" }}   className="StringOverFlow1">
            {cellProps.value && cellProps?.value?.length > 15
              ? cellProps?.value 
              : cellProps.value || "-"}
          </span>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Title",
      accessor: "custDisclosure.disclosureTitle",
      // width: 150,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <LightTooltip title={cellProps.value}>
            <span style={{ color: "#000" }}   className="StringOverFlow1">
              {(cellProps.value && cellProps?.value) ||
                "-"}
            </span>
          </LightTooltip>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Description",
      accessor: "custDisclosure.disclosureDesc",
      // width: 140,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value ? (
          <DarkTooltip
            title="View Details"
            style={{ width: "64%", textAlign: "center" }}
          >
            <div>
              <i
                onClick={() => {
                  setModal(!modal)
                  // setDesc(cellProps.value)
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Linked Description")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
            </div>
          </DarkTooltip>
        ) : (
          "-"
        )
      },
      show: true,
    },
    {
      Header: "Disclosure Type",
      accessor: "custDisclosure.disclosureType",
      // width: 110,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value == 1
          ? "Text"
          : cellProps.value == 2
          ? "Doc"
          : "Other"
      },
      show: true,
    },
    {
      Header: "Disclosure-specific URL",
      filterable: false,
      // width: 140,
      disableFilters: true,
      accessor: "custDisclosure.disclosureSpecificUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        return cellProps.value ? (
          <div>
            <Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                  className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {cellProps.value
                    .replaceAll("https://", "")
                    .replaceAll("http://", "")
                    .replaceAll("www.", "") }
                </div>
              </LightTooltip>
            </Link>
          </div>
        ) : (
          <p style={{ textAlign: "center", width: 100 }}>-</p>
        )
      },
      show: true,
    },
    {
      Header: "Disclosure-specific Citation",
      accessor: "custDisclosure.disclosureSpecificCitation",
      filterable: false,
      // width: 140,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value ? (
          cellProps.value
        ) : (
          <p style={{ textAlign: "center", width: 55 }}>-</p>
        )
      },
      show: true,
    },
    {
      Header: "  Status (Approved, Pending, Removed)",
      accessor: "custDisclosure.status",
      filterable: false,
      // width: 152,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value == 1
          ? "Pending"
          : cellProps.value == 2
          ? "Approved "
          : "Removed"
      },
      show: true,
    },
    {
      Header: "Text",
      accessor: "custDisclosure.text",
      // width: 95,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value ? (
          <DarkTooltip title="View Details">
            <div style={{ textAlign: "center", width: "35%" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  // setDesc(cellProps.value)
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Linked Text")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
                View Details
            </UncontrolledTooltip> */}
            </div>
          </DarkTooltip>
        ) : (
          "-"
        )
      },
      show: true,
    },
    {
      Header: "Safe Harbor (All, Part, None)",
      accessor: "custDisclosure.safeHarbor",
      filterable: false,
      // width: 125,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value == 1
          ? "All"
          : cellProps.value == 2
          ? "Part"
          : "None"
      },
      show: true,
    },
    {
      Header: "Obligation Description",
      // width: 140,
      filterable: false,
      disableFilters: true,
      accessor: "custObligation.obligationDesc",
      Cell: cellProps => {
        return cellProps.value ? (
          <DarkTooltip title="View Details">
            <div style={{ textAlign: "center", width: "70%" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  // setDesc(cellProps.value)
                  setTimeout(() => {
                    setInnerText(cellProps.value)
                  },500)
                  setTitle("Obligation Description")
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
            </div>
          </DarkTooltip>
        ) : (
          "-"
        )
      },
      show: true,
    },
    {
      Header: "Citation URL",
      // width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "obligation.citationUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        return (
          <div>
{cellProps.value ? <Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                  className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {(cellProps.value &&
                    cellProps.value
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "")  ) ||
                    "-"}
                </div>
              </LightTooltip>
            </Link> : DisplayJustifyCenter("-")}
          </div>
        )
      },
      show: true,
    },
    {
      Header: "Regulator Short Name",
      accessor: "custObligation.issuingAuthority",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value || "-"
      },
      show: true,
    },
    {
      Header: "Citation",
      accessor: "custObligation.citation",
      // width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value || "-"
      },
      show: true,
    },
    {
      Header: "Obligation Notes",
      accessor: "custObligation.obligationNote",
      // width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return cellProps.value
          ? cellProps?.value?.length > 10
            ? cellProps.value.substr(0, 15) + " ..."
            : cellProps.value
          : "-"
      },
      show: true,
    },
  ]

  const filteredColumns = columns.filter(column => column.show)

  const fetchData = searchObject => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // const finalData = Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject 
    let finalData =   {...searchObject,...filterArray}
    setLoader(true)
    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } else {
      localStorage.removeItem("selectedFilterArray")
    }

    const requestData = {
      ...finalData,
      customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      orderBy: {
        ...sortBy,
      },
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-disclosure-obligation-organization-link/?limit=${customePageSize}&pageNo=${currentPage}`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setDisclosureDetail(response.data.data)
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }
  useEffect(() => {
    console.log("page is loading")
    fetchData(searchObject)
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  document.title = "Linked Report"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody className="text-left">
              {/* <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                    toggle={toggle}
                    setModal={setModal}
                    fetchData={fetchData}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    filterArray={filterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    setFilterArray={setFilterArray}
                    setCurrentPage={setCurrentPage}
                    columns={filteredColumns}
                    data={disclosureDetail}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        name={console.log({ totalItems })}
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CustLinkedReport)
