import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
  } from 'reactstrap';
import { setReviewUpdate } from 'store/slices/authorReviewSlice';

const AuthorUpdateModal = () => {
    const {reviewUpdate} = useSelector((state) => state.AuthorReview)
    const dispatch = useDispatch()

    const toggle = () => {
        dispatch(setReviewUpdate(false))
    }   

    const handleUpdate = () => {
        dispatch(setReviewUpdate(false))
    }
 
  return (
    <div>
        <Modal
        isOpen={reviewUpdate}
        toggle={toggle}
        scrollable={true}
        className="modal-dialog-centered"
        style={{ borderRadius: '10px', width: '30%' }}
      >
        <ModalHeader toggle={toggle} className="d-flex justify-content-between align-items-center">
                <p  style={{fontSize:'18px'}}>
                     Generate Ai text
                </p> 
        </ModalHeader>
        <ModalBody>
                <h5>Ai Generated Text</h5>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button
            className="px-4 rounded-pill"
            style={{ background: '#B6B6B6', color: 'black', border: 'none' }}
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            className="px-4 rounded-pill"
            color="primary"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AuthorUpdateModal