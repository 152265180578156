import React, {
    useEffect,
    useState,
    useMemo,
    Fragment,
    useCallback,
  } from "react"
  import { useSelector, useDispatch } from "react-redux"
  import { withRouter } from "react-router-dom"
  import toastr from "toastr"
  import Flatpickr from "react-flatpickr"
  import { MenuItem, FormControl } from "@material-ui/core"
  import { createTheme } from "@material-ui/core/styles"
  import { Select as MaterialUISelect } from "@material-ui/core"
  import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useBlockLayout,
    useRowSelect,
  } from "react-table"
  import TextField from "@material-ui/core/TextField"
  import Autocomplete from "@material-ui/lab/Autocomplete"
  import { useSticky } from "react-table-sticky"
  import Tooltip from "@material-ui/core/Tooltip"
  import {
    Table,
    Row,
    Col,
    Input,
    CardBody,
    Button,
    UncontrolledTooltip,
    Badge,
    Card,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    BreadcrumbItem
  } from "reactstrap"
  import { withStyles, makeStyles } from "@material-ui/core/styles"
  import { Filter, DefaultColumnFilter } from "../../../../components/Common/filters"
  import Pagination from "../../../../components/Common/Pagination"
  import styled from "styled-components"
  import { createMuiTheme } from "@material-ui/core/styles"
  import { MuiThemeProvider } from "@material-ui/core/styles"
  import { format } from "date-fns"
  import { Popper } from "@material-ui/core"
  import FilterModal from "../../../../components/FilterModal/FilterModal"
  import axios from "axios"
  import TableHeader from "components/Common/TableHeader"
  import ReactDiffViewer from "react-diff-viewer"
  import Prism from "prismjs"
  import XLSX from "xlsx"
  import FileSaver from "file-saver"
  import { Link } from "react-router-dom"
  import { useLocation } from "react-router-dom"
  import Breadcrumbs from "../../../../components/Common/Breadcrumb"
  
  import { map } from "lodash" 
  import HtmlDiff from "htmldiff-js"
  // import PDFGenerator from "./PdfComponent"
  import "./regReview.css"
  import { CheckValidValue, DisplayJustifyCenter, FormatedDate, RedirectOnUnAuthentication, RemoveSpaces, ReportId, ValidObject, convertObjectValuesToArray, encryptData, handleNullAndUndefinedOfArray, hexHtmlToString, trimLeft } from "helpers/helper_function"
  import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
  import { setDashboardData, setOpenLinkDisclosure, setReviewDeail, setReviewStatus } from "store/slices/workFlowReportSlice"
  import useFilterIndicator from "hooks/useFilterIndicator"

  // import $ from "jquery";
  
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef
  
      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])
  
      const [done, setDone] = React.useState(true)
      return (
        <>
          <input
            type="checkbox"
            value={done}
            onChange={e => setDone(e.target.value)}
            ref={resolvedRef}
            {...rest}
          />
        </>
      )
    }
  )
  
  IndeterminateCheckbox.displayName = "IndeterminateCheckbox"
  
  
  const Styles = styled.div`
    /* This is required to make the table full-width */
    display: block;
    max-width: 100%;
  
    // padding: 1rem;
  
    .table {
      border: 1px solid #ddd;
      border-radius: 5px;
      .tr {
        min-width: 100%;
        :first-child {
          .td {
            padding-top: 10px;
          }
        }
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
  
      .th,
      .td {
        padding: 5px;
        padding-left: 10px;
        border-bottom: 1px solid #eff2f7;
        // border-bottom: 1px solid #ddd;
        // border-right: 1px solid #ddd;
        // background-color: #fff;
        overflow: hidden;
  
        :last-child {
          border-right: 0;
        }
  
        :not([data-sticky-td]) {
          flex-grow: 1;
        }
  
        .resizer {
          display: inline-block;
          width: 5px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          z-index: 1;
  
          &.isResizing {
            background: red;
          }
        }
      }
  
      .th {
        position: relative;
        height: 100px;
      }
  
      &.sticky {
        overflow: scroll;
        .header,
        .footer {
          position: sticky;
          z-index: 1;
          width: fit-content;
          min-width: 100%;
        }
  
        .header {
          top: 0;
          // box-shadow: 0px 3px 3px #ccc;
        }
  
        .footer {
          bottom: 0;
          box-shadow: 0px -3px 3px #ccc;
        }
  
        .body {
          position: relative;
          z-index: 0;
        }
  
        [data-sticky-td] {
          position: sticky;
        }
  
        [data-sticky-last-left-td] {
          // box-shadow: 1px 0px 2px #ccc;
        }
  
        [data-sticky-first-right-td] {
          // box-shadow: -2px 0px 3px #ccc;
        }
      }
    }
  `
  const useStyles = makeStyles({
    root: {
      "& .MuiInputBase-root": {
        padding: "2px", // Adjust the padding value as per your requirement
      },
      "& input::placeholder": {
        fontSize: "12px",
        fontFamily: "poppins",
      },
    },
    datePickerInput: {
      backgroundColor: "#fff", // Set your desired background color here
      // borderRadius: theme.shape.borderRadius,
      // padding: theme.spacing(1),
    },
    option: {
      fontFamily: "poppins",
      fontSize: "13px",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 110,
    },
  
    select: {
      backgroundColor: "#fff", // Set your desired background color her
      paddingLeft: 5,
      fontSize: "14px",
      lineHeight: "20px",
      fontFamily: "poppins",
    },
  })
  
  const PopperMy = function (props) {
    // console.log(props, 'props')
    return (
      <Popper
        {...props}
        style={{ width: 250, color: "red" }}
        placement="bottom-start"
      />
    )
  }
  
  
  const TableContainer = ({
    loader,
    columns,
    reqresData,
    filterArray,
    setSortBy,
    handleSort,
    queryId,
    searchObject,
    downloadAccess,
    handleRender,
    props,
    customePageSize,
    setCustomePageSize,
    fetchData,
    dateQuery,
    setSearchObject,
    setCurrentPage,
    setFilterArray,
    data,
    pageCount: customePageCount,
    customPageSize,
  }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      rows,
      selectedFlatRows,
      state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        // disableRowSelect: true,
        manualPagination: true,
        defaultColumn: { Filter: DefaultColumnFilter },
        initialState: {
          pageIndex: 0,
          pageSize: customPageSize,
          // sortBy: [
          //   {
          //     desc: false,
          //   },
          // ],
        },
        pageCount: customePageCount,
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
      // useFlexLayout,
      useBlockLayout,
      useSticky,
      useRowSelect,
      hooks => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: "linktoDisc",
            sticky: "left",
            width: 180,
  
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: "Linked to Disc.",
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div style={{ backgroundColor: "#fff" }}>
                {row.original.obligation.disclosure_obligations_obligation
                  .length > 0
                  ? "Y"
                  : "N"}
              </div>
            ),
          },
          {
            id: "selection",
            sticky: "left",
            width: 37,
  
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div style={{ backgroundColor: "#fff" }}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    )
  
    const [btnLoader, setBtnLoader] = useState(false)
    const [searchQuery, setSearchQuery] = useState({})
    const [autoEmpty, setAutoEmpty] = useState({})
    const [autoFill, setAutoFill] = useState({})
    const [selectedDate, setSelectedDate] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [selectedHeader, setSelectedHeader] = useState([])
    const callUseNavigate = useHistory()
   const { generateSortingIndicator, toggleIndicator } = useFilterIndicator();
   const {review} = useSelector((state) => state.workFlowReport)
   const dispatch = useDispatch()
    const navigateTo = (url) =>{
      callUseNavigate.push(url)
    }
    let parse_localData={}
    let localData = localStorage.getItem("updated-obli-report")
    if (localData != null) {
      parse_localData = JSON.parse(localData)
      // setSearchQuery(localData)
    }
  
    const handleExport = () => {
      console.log("export function")
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      setBtnLoader(true)
  
      const id_arr = map(selectedFlatRows, "original.id")
      const ids = id_arr.toString()
      console.log(ids, "selectedFlatRows")
  
      const data = {
        ...searchObject,
        ...filterArray,
        reportId: queryId,
        ids: ids,
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}get-download-report`, {data:encryptData(data)}, {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
          responseType: "blob",
        })
       
        .then(response => {
          setBtnLoader(false)
          toastr.success("Report Downloaded Successfully.")
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", "Reg Content Change Report.pdf") //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          // toastr.error(error, "Error Exporting Data")
          RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
          setBtnLoader(false)
        })
    }
  
    useEffect(() => {
      const data = localStorage.getItem("updated-obli-report")
      let finalData
      console.log(
        props?.location?.state?.rowData?.clear,
        "props?.location?.state?.rowData?.clear"
      )
      if (data != null && !props?.location?.state?.rowData?.clear) {
        finalData = JSON.parse(data)
        let modifiedObject = {}
  
        for (let key in finalData) {
          if (finalData.hasOwnProperty(key)) {
            let newKey
            // if (key == "obligation.obId") {
            //   newKey = "obId"
            //   if (Array.isArray(finalData[key])) {
            //     // If it's an array, take the first element
            //     modifiedObject[newKey] = finalData[key][0];
            //   } else if (typeof finalData[key] === "string") {
            //     // If it's already a string, keep it as it is
            //     modifiedObject[newKey] = finalData[key];
            //   } else {
            //     console.log(`Value for key ${key} is not a valid string.`);
            //   }
            // } else {
              newKey = key
              if (Array.isArray(finalData[key])) {
                // If it's an array, take the first element
                modifiedObject[newKey] = finalData[key][0];
              } else if (typeof finalData[key] === "string") {
                // If it's already a string, keep it as it is
                modifiedObject[newKey] = finalData[key];
              } else {
                console.log(`Value for key ${key} is not a valid string.`);
              }
            // }
            // modifiedObject[newKey] = finalData[key]
            console.log(modifiedObject, "modifiedObject")
          }
        }
        setAutoEmpty(modifiedObject)
      }
    }, [localStorage.getItem("updated-obli-report")])
  
    const handleKeyDown = (key, columnId, value) => { 
      if (key === "Enter") {
        let updatedValue
        if (value === "" || value == null) {
          updatedValue = "" 
        } else if (columnId == "updatedDate") {
          const newDate = new Date(value)
          const formattedDate = format(newDate, "yyyy-MM-dd")
          console.log(formattedDate, "formattedDate")
          setSelectedDate(formattedDate)
          updatedValue = formattedDate
        } else {
          updatedValue = value
        }
    
        const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
        handleSearchQueryChange(columnId, updatedValue)
        setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
        setSearchQuery(updatedSearchQuery)
        setSearchObject(updatedSearchQuery)
    
        console.log("API call triggered:", value)
      }
    }
  
    const handleSearchQueryChange = (fldName, value) => {
      console.log(fldName, value, "check handleSearchQueryChange and value")
  
      const authToken = JSON.parse(localStorage.getItem("authUser"))
  
      let columnName
      if (fldName === "obligation.obligationDesc") {
        columnName = "obligationDesc"
      } else if (fldName === "obligation.obligationName") {
        columnName = "obligationName"
      } else if (fldName === "obligation.citationUrl") {
        columnName = "citationUrl"
      } else if (fldName === "obligation.citation") {
        columnName = "citation"
      } else if (fldName === "obligation.regLaw") {
        columnName = "regLaw"
      } else if (fldName === "prevUpdatedDate") {
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        setSelectedDate(formattedDate)
        setSearchQuery(prev => ({ ...prev, [fldName]: formattedDate }))
      } else if (fldName === "obId") {
        columnName = "obId"
        const updatedSearchQuery = {
          ...searchQuery,
          ["newObligationReport.obId"]: value,
        }
        setSearchQuery(updatedSearchQuery)
      } else {
        columnName = fldName
      }
  
      console.log(columnName, value, "check")
  
      const idsArray = localStorage.getItem("idsArray")
      ? JSON.parse(localStorage.getItem("idsArray"))
      : []
  
      const requestData = {
        tableName: "newObligationReport",
        reportId: ReportId(),
        feildName: columnName ==="obligation.obId"?"obId":columnName,
        // ids: idsArray,
        searchValue: value ? value : "",
        relationFeild:
          columnName === "obligationName" ||
          columnName === "citationUrl" ||
          columnName === "obligation.obId" ||
          fldName === "obligation.obligationDesc" ||
          fldName === "obligation.regLaw" ||
          fldName === "obligation.citation" ||
          fldName === "oldobligationDesc"
            ? "obligation"
            : "",
        isUnlink: false,
        isReview: 0,
      }
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
          {data:encryptData(requestData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          // console.log(response,  "re ponse")
          if (fldName != "prevUpdatedDate") {
            setAutoFill(prevAutoFill => ({
              ...prevAutoFill,
              [fldName]: response.data.data.map(item => item[columnName ==="obligation.obId"?"obId":columnName]),
            }))
          }
        })
        .catch(error => {
          RedirectOnUnAuthentication(error,navigateTo)
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnName ==="obligation.obId"?"obId":columnName]: [],
          }))
        })
    }
  
    const handleAutocompleteChange = (columnId, value) => {
  
      const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);
      if (optionExists || value === "") {
        console.log("selected from suggestion box")
        
        let updatedValue
        let columnName
        // console.log(columnId, typeof(columnId));
        // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
        if (value == "" || value == null) {
          updatedValue = "" // Set the value to blank when selecting "All"
          // console.log("**************");
        } else {
          updatedValue = value
        }
    
        if (columnId == "obId") {
          console.log(columnId == "obId", "check", "newObligationReport.obId")
          columnName = "newObligationReport.obId"
          setAutoEmpty(prev => ({ ...prev, ["obId"]: updatedValue.trim() }))
          //  updatedValue = value
        } else if (columnId == "obligationDesc") {
          columnName = "newObligationReport.obligationDesc"
          updatedValue = value
          setAutoEmpty(prev => ({ ...prev, ["obligationDesc"]: updatedValue.trim() }))
        } else if (columnId == "oldobligationDesc") {
          columnName = "newObligationReport.oldobligationDesc"
          updatedValue = value
          setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue.trim() }))
        } else if (columnId == "prevUpdatedDate") {
          // Set the value to false when selecting "No"
          // console.log(value, 'value')
    
          const newDate = new Date(value)
          console.log(newDate, "newDate")
          const formattedDate = format(newDate, "yyyy-MM-dd")
          console.log(formattedDate, "formattedDate")
          updatedValue = formattedDate
          columnName = "newObligationReport.prevUpdatedDate"
        } else {
          updatedValue = value
          columnName = columnId
        }
    
        // console.log(columnName,updatedValue, "updated alue")
        const updatedSearchQuery = { ...searchQuery, [columnName]: [updatedValue.trim()] }
        handleSearchQueryChange(columnId, updatedValue)
        setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue.trim() }))
        setSearchQuery(updatedSearchQuery)
        setSearchObject(updatedSearchQuery)
      } else {
  
        let updatedValue
        let columnName
        // console.log(columnId, typeof(columnId));
        // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
        if (value == "" || value == null) {
          updatedValue = "" // Set the value to blank when selecting "All"
          // console.log("**************");
        } else {
          updatedValue = value
        }
    
        if (columnId == "obId") {
          console.log(columnId == "obId", "check", "newObligationReport.obId")
          columnName = "newObligationReport.obId"
          setAutoEmpty(prev => ({ ...prev, ["obId"]: updatedValue.trim() }))
          //  updatedValue = value
        } else if (columnId == "obligationDesc") {
          columnName = "newObligationReport.obligationDesc"
          updatedValue = value
          setAutoEmpty(prev => ({ ...prev, ["obligationDesc"]: updatedValue.trim() }))
        } else if (columnId == "oldobligationDesc") {
          columnName = "newObligationReport.oldobligationDesc"
          updatedValue = value
          setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue.trim() }))
        } else if (columnId == "prevUpdatedDate") {
          // Set the value to false when selecting "No"
          // console.log(value, 'value')
    
          const newDate = new Date(value)
          console.log(newDate, "newDate")
          const formattedDate = format(newDate, "yyyy-MM-dd")
          console.log(formattedDate, "formattedDate")
          updatedValue = formattedDate
          columnName = "newObligationReport.prevUpdatedDate"
        } else {
          updatedValue = value
          columnName = columnId
        }
    
        // console.log(columnName,updatedValue, "updated alue")
        const updatedSearchQuery = { ...searchQuery, [columnName]: updatedValue&&trimLeft(updatedValue) }
        handleSearchQueryChange(columnId, updatedValue)
        setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
        setSearchQuery(updatedSearchQuery)
        setSearchObject(updatedSearchQuery)
      }
     
    }

    useMemo(() => {
      const updatedSearchQuery = { ...searchQuery, ['linktoDisc']: review }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }, [review])
    
    const handleValueChange = (columnKey, value) => {    
      let updatedValue
      if (value === "") {
        updatedValue = "" 
      } else if (value === false) {
        updatedValue = "false" 
      } else {
        updatedValue = "true" 
      }
      const updatedSearchQuery = { ...searchQuery, [columnKey]: updatedValue }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    const [status, setStatus] = useState('')

    const handleStatusChange = (columnKey, value) => {    
      setStatus(value);
      const updatedSearchQuery = { ...searchQuery, ["newObligationReport.reviewStatus"]: value }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    const handleKeyDownDate = (columnId, e) => {
      if (e.keyCode === 27 || e.keyCode === 8) {
        setSelectedDate(null)
        const updatedSearchQuery = {
          ...searchQuery,
          ["newObligationReport.prevUpdatedDate"]: "",
        }
        setSearchQuery(updatedSearchQuery)
        setSearchObject(updatedSearchQuery)
      }
    }
  
    const clearFilter = () => {
      setSearchQuery({})
      setSelectedHeader([])
      setStatus('')
      setAutoEmpty({})
      setAutoFill({})
      setFilterArray({})
      setSearchObject({})
      setSelectedDate(null)
      setCurrentPage(1)
      setSortBy({
        tableName: "obligation",
        fieldName: "obligationName",
        order: "ASC",
      })
      localStorage.removeItem("updated-obli-report")
      localStorage.removeItem("idsArray")
      const textFields = document.querySelectorAll('input[type="text"]')
      textFields.forEach(textField => {
        textField.value = ""
      })
    }
    // console.log('autoEmpty', autoEmpty);
    // console.log('searchQuery', searchQuery);
    const onChangeInSelect = event => {
      // setPageSize(Number(event.target.value))
      setCustomePageSize(Number(event.target.value))
    }
  
    const theme = createTheme({
      overrides: {
        MuiFilledInput: {
          root: {
            backgroundColor: "#fff", 
            border: "1px solid #ced4da",
            borderBottom: 0,
          },
        },
        MuiAutocomplete: {
          inputRoot: {
            // '&&[class*="MuiOutlinedInput-root"] $input': {
            //   padding: "1px"
            // },
            "& .MuiAutocomplete-inputRoot": {
              color: "purple",
              // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
              '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
                {
                  // Default left padding is 6px
                  padding: 26,
                },
            },
          },
        },
      },
    })
  
    const classes = useStyles()
  
    const [modal, setModal] = useState(false)
    const [newModal, setNewModal] = useState(false)
    const [selectedFile, setSelectedFile] = useState("")
    const [errors, setErrors] = useState({})
    const {reviewStatus} = useSelector((state) => state.workFlowReport)
    
  
    const toggle = () => {
      setModal(!modal)
    }
  
    // const classes = useStyles();
  
    return (
      <Fragment>
        <Row className="pt-3">
          <Col
            xs={12}
            md={12}
            lg={12}
            xl={6}
            className="d-flex flex-column flex-lg-row gap-3 align-items-center"
          >
            {/* <div className="d-flex  align-items-center ">
                <h5 className="font-size-18 mb-0">Updated Obligation Report</h5>
                <select
                  className="form-select"
                  value={customePageSize}
                  onChange={onChangeInSelect}
                  style={{width : "150px", marginLeft: "10px"}}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      No of Results {pageSize}
                    </option>
                  ))}
                </select>
              </div> */}
  
            {/* <div className="d-flex justify-content-start ">
             
              </div> */}
            <TableHeader
              heading={""}
              onChangeInSelect={onChangeInSelect}
              customePageSize={customePageSize}
            />
          </Col>
          {/* {console.log(headerGroups && headerGroups[0].headers[2], "headerGroups")} */}
          <Col
            sm={12}
            md={12}
            lg={12}
            xl={6}
            className="justify-content-end py-2"
          >
            <div className="d-flex flex-wrap gap-2 justify-content-end ">
              <button
               style={{width:'130px', height:"33px", fontSize:'13px', display:'flex', justifyContent:'center'}}
                type="button"
                className="btn btn-primary rounded-pill"
                onClick={clearFilter}
              >
                <i className="mdi mdi-filter-remove-outline mx-1"></i>{" "}
                Clear Filter
              </button>
              <button       
                style={{
                  color : '#495057',
                  width: '120px',
                  height: '33px',
                  fontSize: '13px',
                  background: '#FBFBFF',
                  fontWeight : '500',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}      
                type="button"
                className="btn rounded-pill"
                onClick={() => navigateTo('/author-review-report')}
              >
                Author Review
              </button>
              <>
                    {loader ? (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <i className="bx bx-loader bx-spin" style={{ fontSize: '24px' }}></i>
                      </div>
                    ) : (
                      <>
                        {reviewStatus === 0 ? (
                          <button type="button"
                            style={{
                              width: '162px',
                              height: "33px",
                              fontSize: '13px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                            // onClick={handleNewReview}
                            className="btn btn-primary btn-sm rounded-pill">
                            Start New Review
                            <i style={{ fontSize: '14px' }} className="bx bx-loader mx-1" />
                          </button>
                        ) : reviewStatus === 1 ? (
                          <button type="button"
                            style={{
                              color: 'black',
                              width: '162px',
                              height: '33px',
                              fontSize: '13px',
                              fontFamily: 'Poppins, sans-serif'
                            }}
                            onClick={() => {
                              dispatch(setOpenLinkDisclosure(true));
                            }}
                            className="btn btn-warning rounded-pill">
                            Continue Review
                            <i className="bx bxs-hourglass mx-1"></i>
                          </button>
                        ) : (
                          <button
                            style={{
                              color: 'white',
                              width: '165px',
                              height: '33px',
                              fontSize: '13px',
                              background: '#28A745',
                              fontWeight: '500',
                              // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', 
                            }}
                            type="button"
                            className="btn rounded-pill">
                            Complete Review
                            <i style={{ fontSize: '14px' }} className="bx bx-check-circle mx-2"></i>
                          </button>
                        )}
                      </>
                    )}
                  </>
              {/* {downloadAccess && downloadAccess.readAccess === true ? (
                   btnLoader ? (
                      <div className="ml-5">
                        <Spinner className="ms-2 " color="primary" />
                      </div>
                    ) : (
                        <button 
                        className="btn btn-primary"
                        onClick={handleScan}
                        >
                           <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                             Scan Now
                        </button>
                    )
                    )
                    :
                    ("")
                    } */}
  
  {selectedFlatRows.length>0?(<>
    {btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <></>
                // <button className="btn btn-primary" onClick={handleExport}>
                //   <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                //   Download Pdf
                // </button>
              )}
        </>) :""}
              {/* {btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <PDFGenerator
                data={`<div >
                  <div>
                    <div class="row"> 
                        <div class="col-12 col">
                            <div>
                                <h4 >Updated Obligation Detail</h4>
                                
                            </div>
                        </div>
                    </div>
                    `}
              />
              )} */}
            </div>
          </Col>
        </Row>
  
        <Styles>
          <div style={{ minHeight: "25rem" }}>
            <div
              className="sticky table"
              {...getTableProps()}
              style={{ height: 500 }}
            >
              <div className="table-light header" style={{ fontWeight: 600 }}>
                {headerGroups.map(headerGroup => (
                  <div
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map(column => (
                      // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                      <div
                        key={column.id}
                        {...column.getHeaderProps()}
                        className="th"
                      >
                        {/* <div> */}
                        <div
                          className="mb-2 mt-0"
                          {...column.getSortByToggleProps()}
                          onClick={() => {
                            handleSort(column)
                            toggleIndicator(column.id);
                          }}
                        >
                          {column.render("Header")}
                          {/* <span style={{ color: "#556ee6" }}> */}
                           {generateSortingIndicator(column)}
                          {/* </span> */}
                        </div>
                        {/* {console.log(column.id, 'colims')} */}
                        {column.id != "selection" &&
                        column.id !== "linktoDisc" &&
                        column.id !== "obligationDesc" &&
                        column.id !== "oldobligationDesc" &&
                        column.id !== "prevUpdatedDate" &&
                        column.id !== "reviewStatus" &&
                        column.Header !== "Action" ? (
                          <div
                            style={{
                              width: "100%",
                              position: "absolute",
                              bottom: "10px",
                              display: "flex",
                            }}
                          > 
                            <MuiThemeProvider theme={theme}>
                              <Autocomplete
                                // classes={classes}
                                style={{ width: "75%" }}
                                PopperComponent={PopperMy}
                                disabled={loader}
                                // disableClearable={true}
                                // options={autoFill[column.id] || []}
                                options={handleNullAndUndefinedOfArray(autoFill[column.id]||[])}
                                // options={autoEmpty[column.id] || searchQuery[column.id] ? autoFill[column.id] || [] : []} // Show options only when there's input
                                // getOptionLabel={(option) => option || ""}
                                value={autoEmpty[column.id] || ""}
                                classes={{
                                  option: classes.option,
                                }}
                                freeSolo
                                onChange={(event, value) =>
                                  handleAutocompleteChange(column.id, value)
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    placeholder="Search..."
                                    variant="filled"
                                    disabled={loader}
                                    size="small"
                                    // className="form-control"
                                    className={classes.root}
                                    value={searchQuery[column.id] || ""}
                                    onChange={e =>
                                      handleSearchQueryChange(
                                        column.id,
                                        e.target.value
                                      )
                                    }
                                    // onKeyDown={handleKeyDown}
                                    onKeyDown={(e) => {
                                      handleKeyDown(e.key,column.id,e.target.value)
                                     }}
                                    // disableClearable={true}
                                    InputLabelProps={{
                                      shrink: false,
                                      focused: false,
                                    }}
                                  />
                                )}
                              />
                            </MuiThemeProvider>
                            {/* {
                                  column.id !== "obligationDesc" 
                                  && column.id !== "obligation.obligationDesc" ?
                                  (
                                <button
                                  onClick={() => {
                                    setShowModal(true)
                                    setModalData(column)
                                  }}
                                  className={
                                    selectedHeader.includes(column.Header)
                                      ? "filter_button_selected"
                                      : "filter_button"
                                  }
                                  // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                                >
                                  <i className="bx bx-filter font-size-18"></i>
                                </button>
  
                                  )
                                :
                                ("")
                                } */}
                          </div>
                        ) : column.id == "prevUpdatedDate" ? (
                          <div
                            style={{
                              width: "80%",
                              position: "absolute",
                              bottom: "10px",
                              display: "flex",
                            }}
                          >
                            <Flatpickr
                              className="form-control  d-block flatpickr-input"
                              placeholder="Select Date..."
                              disabled={loader}
                              style={{
                                fontSize: "12px",
                                paddingLeft: 4,
                                paddingBottom: 4,
                                paddingTop: 8,
                                borderBottom: "1px solid #000",
                              }}
                              onChange={e =>
                                handleAutocompleteChange(column.id, e)
                              }
                              value={selectedDate}
                              options={{
                                dateFormat: "Y-m-d",
                                // defaultDate: "2023-07-19",
                              }}
                              onKeyDown={e => handleKeyDownDate(column.id, e)}
                            />
  
                            <div
                              className="icon-container"
                              style={{ position: "absolute", right: 6, top: 8 }}
                            >
                              {/* Replace with Boxicons or Font Awesome icon component */}
                              <i
                                className="fa fa-calendar"
                                // aria-hidden="true"
                              />
                            </div>
                            {/* <div className="input-group-append">
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-secondary docs-datepicker-trigger"
                                                  // disabled
                                                  style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                                >
                                                  <i
                                                    className="fa fa-calendar"
                                                    aria-hidden="true"
                                                  />
                                                </button>
                                              </div> */}
                          </div>
                        ) :   column.id == "linktoDisc"?(
                        <>
                          
                          <div
                              style={{
                                width: "80%",
                                position: "absolute",
                                bottom: "10px",
                              }}
                            >
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                              >
                                {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                                <MaterialUISelect
                                  className={classes.select}
                                  disabled={loader}
                                  value={
                                    searchQuery[column.id] != undefined
                                      ? searchQuery[column.id]
                                      : parse_localData[column.id]??""
                                  }
                                  displayEmpty
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                  onChange={event =>
                                    handleValueChange(column.id, event.target.value)
                                  }
                                >
                                  <MenuItem value={""}>All</MenuItem>{" "}
                                  <MenuItem value={true}>Yes</MenuItem>{" "}
                                  <MenuItem value={false}>No</MenuItem>
                                </MaterialUISelect>
                              </FormControl>
                          </div>
                          
                        </>):
                          column.id == "reviewStatus"? (
                            <div
                            style={{
                              width: "80%",
                              position: "absolute",
                              bottom: "10px",
                            }}
                          >
                            <FormControl
                              fullWidth
                              className={classes.formControl}
                            >
                              {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                              <MaterialUISelect
                                className={classes.select}
                                disabled={loader}
                                value={
                                  status 
                                }
                                displayEmpty
                                InputLabelProps={{
                                  shrink: false,
                                  focused: false,
                                }}
                                onChange={event =>
                                  handleStatusChange(column.id, event.target.value)
                                }
                              >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value={'0'}>Pending Review</MenuItem>
                                <MenuItem value={'1'}>Author Review Required</MenuItem>
                                <MenuItem value={'2'}>AI Recommend</MenuItem>
                                <MenuItem value={'3'}>Review Complete</MenuItem>
                                <MenuItem value={'4'}>Author Review Complete</MenuItem>
                              </MaterialUISelect>
                            </FormControl>
                        </div>
                          )
                          :
                        ""}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {loader ? (
                <div
                  className="container-fluid mt-5 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : rows.length > 0 ? (
                <div {...getTableBodyProps()} className="body">
                  {page.map(row => {
                    prepareRow(row)
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <div {...row.getRowProps()} className="tr">
                          {row.cells.map(cell => {
                            return (
                              <div
                                key={cell.id}
                                {...cell.getCellProps({
                                  style: {
                                    backgroundColor: "#fff",
                                  },
                                })}
                                className="td"
                              >
                                {cell.render("Cell")}
                              </div>
                            )
                          })}
                        </div>
                      </Fragment>
                    )
                  })}
                </div>
              ) : (
                <Row className="mt-5">
                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      padding: "150px 0",
                      fontSize: "25px",
                    }}
                  >
                    No Records Found
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <FilterModal
            setSelectedHeader={setSelectedHeader}
            filterArray={filterArray}
            setFilterArray={setFilterArray}
            isShowModal={showModal}
            filterData={modalData}
            setShowModal={setShowModal}
            tableName="newObligationReport"
            emptyTable={data?.length == 0??false}
  
            relationFeild={`${
              modalData.id === "obligation.obligationName" ||
              modalData.id === "obligation.obligationDesc" ||
              modalData.id === "obligation.citationUrl"
                ? "obligation"
                : ""
            }`}
            isUnlink={false}
            isObliReport={true}
            isReview={0}
          />
        </Styles>
        {/* )} */}
      </Fragment>
    )
  }
  
  const LazyLoadedModal = ({ isOpen, toggle, title, desc }) => {
    const [modalContent, setModalContent] = useState("")
    const [loading, setLoading] = useState(false)
  
    useEffect(() => {
      if (isOpen) {
        // Simulate fetching or processing modal content
        // You can replace this with your actual data loading logic
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
          setModalContent(desc)
        }, 500) // Adjust the delay as needed
      }
    }, [isOpen, desc])
  
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-dialog-centered"
        style={{ borderRadius: "50px" }}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="text-left">
          {loading ? (
            <div className="ml-5 d-flex justify-content-center">
              <Spinner className="ms-2 " color="primary" />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
          )}
        </ModalBody>
        <ModalFooter className="mx-auto">
          <Button className="px-5" color="primary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  const LazyLoadedModalNew = ({
    isOpen,
    toggle,
    title,
    desc,
    oldString,
    newString,
  }) => {
    const [modalContent, setModalContent] = useState({})
    const [show, setShow] = useState(false)
  
    function firstHtml() {
      // const oldContent = oldString.replace(/<[^>]*>/g,'')
      return { __html: oldString }
    }
  
    function secondHtml() {
      // const newContent = newString.replace(/<[^>]*>/g,'')
      return { __html: newString }
    }
  
    useEffect(() => {
      if (isOpen) {
        // Simulate fetching or processing modal content
  
        // function diffHtml() {
        //   // console.log(firstHtml(), secondHtml())
        // return {
        //   __html: HtmlDiff.execute(firstHtml().__html, secondHtml().__html),
        // }
        // }
  
        setTimeout(() => {
          setModalContent({
            __html: HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
          })
          setShow(true)
        }, 2000)
      }
    }, [isOpen])
  
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-dialog-centered"
        style={{ borderRadius: "50px" }}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="text-left" style={{ overflowX: "auto" }}>
          <div className="inner">
            {show ? (
              <div dangerouslySetInnerHTML={modalContent} />
            ) : (
              <div className="ml-5 d-flex justify-content-center">
                <Spinner className="ms-2 " color="primary" />
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="mx-auto">
          <Button className="px-5" color="primary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
  
  const RegReviewTable = props => {
    const [modal, setModal] = useState(false)
    const [newModal, setNewModal] = useState(false)
    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [loader, setLoader] = useState(true)
    const [disclosureDetail, setDisclosureDetail] = useState([])
    const [is_delete, setDelete] = useState(false)
    const [searchObject, setSearchObject] = useState({})
    const [queryId, setQueryId] = useState()
    const [filterArray, setFilterArray] = useState({})
    const [customePageSize, setCustomePageSize] = useState(50)
    const [downloadAccess, setDownloadAccess] = useState([])
    const [oldString, setOldString] = useState("")
    const [newString, setNewString] = useState("")
    const [sortBy, setSortBy] = useState({})
    const [modalContent, setModalContent] = useState("")
    const [newModalContent, setNewModalContent] = useState("")
    const [newModalTitle, setNewModalTitle] = useState("")
    const [dateCreate, setDateCreate] = useState("")
    const dispatch = useDispatch()
    const location = useLocation()
    const callUseNavigate = useHistory()
    const navigateTo = (url) =>{
      callUseNavigate.push(url)
    }
    // $(function () {
    //   $('#tab-links a').click(function () {
  
    //     // Check for active
    //     $('.nav-link').removeClass('active');
    //     $(this).addClass('active');
  
    //     // Display active tab
    //     let currentTab = $(this).attr('href');
    //     $('.tab-pane').hide();
    //     $(currentTab).show();
  
    //     return false;
    //   });
    // });
  
    const limit = 20
  
    console.log(props, "props")
  
    useEffect(() => {
      setDateCreate(props?.location?.state?.rowData?.createdDate)
    }, [])
  
    useEffect(() => {
      console.log(
        props?.location?.state?.rowData?.clear,
        "props?.location?.state?.rowData?.clear"
      )
      if (props?.location?.state?.rowData?.clear) {
        localStorage.removeItem("updated-obli-report")
      }
    }, [props?.location?.state?.rowData?.clear])
  
    useEffect(() => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
    }, [])
  
    const [sorting, setSorting] = useState('ASC')
  
  //   useEffect(() => {
  //   // const initialSorting = sorting === 'ASC' ? 'DESC' : 'ASC';
  //   //  console.log(initialSorting , "Here Is the initalSorting");
  //     setSortBy({
  //         tableName: "obligation",
  //         fieldName: "obligationName",
  //         order: 'ASC', 
  //     })
  //     setSorting(initialSorting); 
  // }, [])
  
    const handleSort = useCallback((column) => {
      setSorting(prevSorting => (prevSorting === 'ASC' ? 'DESC' : 'ASC'));    
      let columnName;
      if (column.id === "obligation.obligationDesc") {
          columnName = "obligationDesc";
      } else if (column.id === "obligation.obligationName") {
          columnName = "obligationName";
      } else if (column.id === "obligation.citationUrl") {
          columnName = "citationUrl";
      } else if (column.id === "obligation.citation") {
          columnName = "citation";
      } else if (column.id === "obligation.regLaw") {
          columnName = "regLaw";
      } else if (column.id === "obligation.obId") {
          columnName = "obId";  
      } else {
          columnName = column.id;
      }
      const sortOrder = sorting;
      if (
          column.id !== "selection" &&
          column.id !== "Action" &&
          column.id !== "linktoDisc"
      ) {
          setSortBy({
              tableName:
                  columnName === "obligationName" ||
                  columnName === "obligationDesc" ||
                  columnName === "citationUrl" ||
                  columnName === "citation" ||
                  columnName === "regLaw"
                      ? "obligation"
                      : "newObligationReport",
              fieldName: columnName,
              order: sorting,
          });
      }
  }, [sorting, setSortBy]);
  
  
  
    const customePageCount = Math.ceil(totalItems / limit)
  
    const LightTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }))(Tooltip)
    const DarkTooltip = withStyles(theme => ({
      tooltip: {
        backgroundColor: theme.palette.common.black,
        color: "rgba(255, 255, 255, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
      },
    }))(Tooltip)
  
    const toggle = data => {
      setModal(!modal)
    }
  
    const newToggle = () => {
      setNewModal(!newModal)
    }
  
    const openModal = useCallback(content => {
      console.log(content , "Here We Are Getting Content")
      setModal(true)
      setModalContent(content)
      setTitle("Prior Citation Content")
    }, [])
  
    const openNewModal = useCallback((oldData, content) => {
      setNewModal(true)
      setOldString(oldData||"")
      setNewString(content||"")
      setNewModalTitle("New Citation Content")
    }, [])
    
    const columns = [
      {
        Header: "Obligation ID",
        accessor: "obligation.obId",
        // width: 200,
        filterable: false,
        disableFilters: true,
        sticky: "left",
      },
      {
        Header: "Obligation Name",
        accessor: "obligation.obligationName",
        // width: 100,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <LightTooltip title={cellProps.value}>
              <span style={{ color: "#000" }}   className="StringOverFlow1">
              {CheckValidValue({value:cellProps?.value })?cellProps?.value : DisplayJustifyCenter("-") } 
              </span>
            </LightTooltip>
          )
        },
      },
      // {
      //   Header: "Citation",
      //   accessor: "obligation.citation",
      //   // width: 115,
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return cellProps.value && cellProps?.value?.length > 15
      //       ? cellProps?.value?.substr(0, 18) + " ..."
      //       : cellProps.value || "-"
      //   },
      // },
      // {
      //   Header: "Citation URL",
      //   // width: 125,
      //   filterable: false,
      //   disableFilters: true,
      //   accessor: "obligation.citationUrl",
      //   Cell: cellProps => {
      //     const rowData = cellProps.value
      //     // console.log(rowData, 'celpops')
      //     return (
      //       <div>
      //         <Link to={{ pathname: cellProps.value }} target="_blank">
      //           <LightTooltip title={rowData}>
      //             <div
      //               className="StringOverFlow1"
      //               style={{
      //                 textDecoration: "underline",
      //                 fontSize: "13px",
      //                 color: "blue",
      //               }}
      //             >
      //               {(cellProps.value &&
      //                 cellProps.value
      //                   .replaceAll("https://", "")
      //                   .replaceAll("http://", "")
      //                   .replaceAll("www.", "") ) ||
      //                 ""}
      //             </div>
      //           </LightTooltip>
      //         </Link>
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: "Prior Updated Date",
      //   accessor: "prevUpdatedDate",
      //   width: 122,
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     const date1 =FormatedDate(cellProps.value) 
      //     if (cellProps.value) {
      //       return <div className="text-align-center">{date1}</div>
      //     } else {
      //       return <div style={{ paddingLeft: "100px" }}>-</div>
      //     }
      //   },
      // },
      {
        Header: "Prior Citation Content",
        accessor: "oldobligationDesc",
        // width: 100,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => { 
          return (<>
           {CheckValidValue({value:cellProps?.value })?  <DarkTooltip
              title="View Details"
              style={{ width: "64%", textAlign: "center" }}
            >
              <div style={{ textAlign: "center" }}>
                {/* <span>{date1}</span> */}
                <i
                  onClick={() => {
                    // setModal(!modal)
                    // setOldString(cellProps.value)
                    // setDesc(cellProps.value)
                    openModal(cellProps.value)
                  }}
                  style={{
                    cursor: "pointer",
                    padding: "0 5px",
                    borderRadius: "20%",
                    color: "#556ee6",
                  }}
                  className="bx bxs-info-circle  font-size-24"
                  id="descToolTip"
                ></i>
              </div>
            </DarkTooltip> : DisplayJustifyCenter("-") } 
          </>)
        
        },
      },
      {
        Header: "New Citation Content",
        accessor: "obligationDesc",
        // width: 100,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          // setNewString(cellProps.value)
          // console.log(cellProps, "new props")
          const oldData = cellProps.row.original.oldobligationDesc
          return (<>
           {CheckValidValue({value:cellProps?.value })?   <DarkTooltip
              title="View Details"
              style={{ width: "64%", textAlign: "center" }}
            >
              <div>
                <i
                  // onClick={() => {
                  //   setNewModal(!newModal)
                  //   setOldString(oldData)
                  //   setNewString(cellProps.value)
                  //   setTitle("New Citation Content")
                  //   openNewModal(oldData,cellProps.value)
                  // }}
                  onClick={() => openNewModal(oldData, cellProps.value||"")}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "0 5px",
                    borderRadius: "20%",
                    color: "#556ee6",
                  }}
                  className="bx bxs-info-circle  font-size-24"
                  id="descToolTip"
                ></i>
              </div>
            </DarkTooltip> : DisplayJustifyCenter("-") } 
          </>)
          
        
        },
      },
      {
        Header: "Status",
        accessor: "reviewStatus",
        width: 180,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Badge
              className={
                cellProps.value === 0
                  ? "font-size-11 badge-soft-warning rounded-pill border border-warning"
                  : cellProps.value == 1
                  ? "font-size-11 badge-soft-danger rounded-pill border border-danger"
                  :  cellProps.value == 2
                  ? "font-size-11 badge-soft-primary rounded-pill border border-primary"
                  :  cellProps.value == 3
                  ? "font-size-11 badge-soft-success rounded-pill border border-success"
                  : "font-size-11 badge-soft-success rounded-pill border border-success"
              }
            >
              { cellProps.value == 0
                ? "Pending Review" :
                cellProps.value == 1
                ? "Author Review Required"
                : cellProps.value == 2
                ? "AI Recommend" :
                cellProps.value == 3
                 ? 'Review Complete'
                : "Author Review Complete"}
            </Badge>
          )
        },
      }, 
      {
        Header: "Action",
        // textAlign: "top",
        // sticky: "right",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          const rowData = cellProps.row.original;
          {console.log(rowData, "+++++>")}
          return (
            <div style={{cursor:'pointer'}}>
              <span style={{color:'#556EE6', fontSize:'20px'}}>
                {rowData.reviewStatus == 3 ? <i className="mdi mdi-thumb-up"/>  : 
                  <i className="mdi mdi-thumb-up-outline"/>
                } 
              </span>
              <span style={{marginLeft:'10px', fontSize:'20px', color:'#556EE6'}}>
              {rowData.reviewStatus === 3 ? (
                <i className="mdi mdi-thumb-down-outline"/>
              ) : (
                rowData.reviewStatus === 0 ? (
                  <i className="mdi mdi-thumb-down-outline"/>
                ) : (
                  <i className="mdi mdi-thumb-down"/>
                )
              )}</span>
              <Badge style={{marginLeft:'10px'}} className="font-size-15 badge-soft-primary" pill>
              <DarkTooltip onClick={()=> {
                  dispatch(setReviewDeail([]))
              }}  title="View">
                <Link
                  to={{
                    pathname: "/review-Details",
                    state: { rowData: { ...rowData, clear: true } },
                  }}
                >
                  <i
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-outline  font-size-18"
                    id="customerViewtooltip"
                  ></i>
                </Link>
              </DarkTooltip>
            </Badge>
            </div>
          );
        },
      },    
    ]

      
    useEffect(() => {
      console.log(location.state, "locationData")
      if (location.state && location.state.rowData) {
        console.log(location.state.rowData, "locationData")
        setQueryId(newQuery)
        console.log(localStorage.getItem('rowDataId'))
      }
    }, [])
  

    const newQuery = localStorage.getItem('rowDataId')


    const fetchData = searchObject => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      setLoader(true)
      console.log("searchObject1256",searchObject);
      let finalData = {...searchObject,...filterArray}
      let data = localStorage.getItem("updated-obli-report")
      if (data != null) {
        data = JSON.parse(data)
        finalData = {  ...data, ...finalData }
      }
      if (Object.keys(finalData).length > 0) {
        localStorage.setItem("updated-obli-report", JSON.stringify(finalData))
      }  
      const requestData = {
        ...finalData,
        ...searchObject,
        reportId: newQuery,
        orderBy: {
          ...sortBy,
        },
      }
      if (newQuery) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}new-obligation-report/?limit=${customePageSize}&pageNo=${currentPage}`,
            // {data:encryptData(requestData)},
            requestData,
            {
              headers: {
                Authorization: `Bearer ${authToken.data.authToken}`,
              },
            }
          )
          .then(response => {
            if (response.status >= 200 || response.status <= 299) {
              // return response.data;
              console.log(response.data.data.reviewStatus, "Here We Are Getting Response")
              setDisclosureDetail(ValidObject  ({object:response.data.data.obligations}));
              dispatch(setReviewStatus(response.data.data.reviewStatus))
              dispatch(setDashboardData(response.data.data.dashboard))
              var ids = []
              if (response.data.ids !== undefined && response.data.ids !== "") {
                ids = response.data.ids.split(",")
              }
              localStorage.setItem("idsArray", JSON.stringify(ids))
              setTotalItems(response.data.totalRecords??0)
              setLoader(false)
            }
            // dispatch(setLoading(false))
            // throw response.data;
          })
          .catch(err => {
            RedirectOnUnAuthentication(err,navigateTo)
            var message
            // dispatch(setLoading(false))
            setLoader(false)
            if (err.response && err.response.status) {
              switch (err.response.status) {
                case 404:
                  message = "Sorry! Network Error(Status:404)"
                  break
                case 500:
                  message =
                    "Sorry! something went wrong, please contact our support team, Status-500   "
                  break
                case 401:
                  message = "You are not authorized to view this data."
                  break
                default:
                  message = err[1]
                  break
              }
            }
            throw message
          })
      }
    }
  
    useEffect(() => {
      fetchData(searchObject)
    }, [
      currentPage,
      is_delete,
      searchObject,
      filterArray,
      customePageSize,
      sortBy,
    ])
  
    useEffect(() => {
      setCurrentPage(1)
    }, [searchObject, filterArray, customePageSize])
  
    const handleRender = () => {
      setDelete(!is_delete)
    }

    document.title = "Reg Content Change Review Summary"
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  {"REG CONTENT CHANGE REVIEW SUMMARY"}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "/work-flow-report",
                          // state: { rowData: {id: data.queryId} },
                        }}
                      >
                        {"Work Flow Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link
                        to={{
                          pathname: "updated-workflow-report",
                        }}
                      >
                        {"List Reg Content Change Report"}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {"Reg Content Change Review Summary"}
                    </BreadcrumbItem>
                  </ol>
                </div>
              </div>

            {/* <Breadcrumbs          
              title="List Reg Content Change Report"
              // - ${FormatedDate(dateCreate) 
              path="updated-workflow-report"
              breadcrumbItem={`Reg Content Change Review Summary`}
            /> */}
            {modal && (
              <LazyLoadedModal
                isOpen={modal}
                toggle={() => setModal(false)}
                title={title}
                desc={modalContent}
              />
            )}
            {newModal && (
              <LazyLoadedModalNew
                isOpen={newModal}
                toggle={() => setNewModal(false)}
                oldString={oldString}
                newString={newString}
                title={newModalTitle}
                desc={newModalContent}
              />
            )}
            {/* <Modal
              isOpen={newModal}
              toggle={newToggle}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px" }}
            >
              <ModalHeader toggle={newToggle}>{title}</ModalHeader>
              <ModalBody className="text-left">
                <div className="inner">
  
                  <div dangerouslySetInnerHTML={diffHtml()} />
                </div>
  
              </ModalBody>
              <ModalFooter className="mx-auto">
                <Button className="px-5" color="primary" onClick={newToggle}>
                  Ok
                </Button>{" "}
              </ModalFooter>
            </Modal> */}
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody className="table-class" style={{background:'white'}}>
                    <TableContainer
                      toggle={toggle}
                      queryId={queryId}
                      setModal={setModal}
                      fetchData={fetchData}
                      handleRender={handleRender}
                      handleSort={handleSort}
                      setSortBy={setSortBy}
                      downloadAccess={downloadAccess}
                      searchObject={searchObject}
                      setSearchObject={setSearchObject}
                      filterArray={filterArray}
                      customePageSize={customePageSize}
                      setCustomePageSize={setCustomePageSize}
                      setFilterArray={setFilterArray}
                      setCurrentPage={setCurrentPage}
                      columns={columns}
                      data={disclosureDetail}
                      isGlobalFilter={true}
                      isAddOptions={true}
                      customPageSize={10}
                      loader={loader}
                      props={props}
                    />
                    <Row className="justify-content-center mt-3">
                      <Col className="col-auto">
                        <Pagination
                          className="pagination-bar"
                          // name={console.log({ totalItems })}
                          currentPage={currentPage}
                          totalCount={totalItems}
                          pageSize={customePageSize}
                          onPageChange={page => setCurrentPage(page)}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
  
  export default withRouter(RegReviewTable)
  