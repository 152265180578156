import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { map } from "lodash"
import { useDispatch } from "react-redux"
import toastr from "toastr"
import debounce from "lodash/debounce"
import axios from "axios"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link, useLocation } from "react-router-dom" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Form,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import styled from "styled-components"
import { Popper } from "@material-ui/core"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Pagination from "../../components/Common/Pagination"
import DeleteModal from "../../components/Common/DeleteModal"
import Select from "react-select"
import { format } from "date-fns"
import { MenuItem, FormControl } from "@material-ui/core"
import { Select as MaterialUISelect } from "@material-ui/core"

// import TableContainer from "../../../components/Common/TableContainer"
import TableHeader from "components/Common/TableHeader"
import FilterModal from "components/FilterModal/FilterModal"
import { init } from "i18next"
import { cellValueLimit } from "helpers/general_constant"
import {
  CheckValidValue,
  ColumnDataWithTooltip,
  DisplayJustifyCenter,
  DownloadExelFrom,
  FormatedDate,
  RedirectOnUnAuthentication,
  RemoveSpaces,
  RemoveTestContentFromArray,
  SeparateObjectByValueType,
  ToastError,
  ValidCellValue,
  convertObjectValuesToArray,
  encryptData,
  handleNullAndUndefinedOfArray,
  removeKeysFromObject,
  trimLeft,
} from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import FilterButton from "components/Common/FilterButton"
import useFilterIndicator from "hooks/useFilterIndicator"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const csvHeader = [
  "ID",
  "Obligation Name",
  "Obligation Description",
  "Citation URL",
  "Regulator Short Name",
  "Reg Law Short Name",
  "Citation",
  "Obligation Notes",
  "Status",
  "Created At",
  "Last Updated At",
  "Regulations",
  "Regulators",
]

const exportToExcel = rowData => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])

  const modifiedRowData = RemoveTestContentFromArray(rowData)
  const customizedData = modifiedRowData.map(row => {
    // console.log(row, 'row')
    const updatedDate =  FormatedDate(row.updatedDate,true)   
    const createdDate =  FormatedDate(row.createdDate,true)   
    const EffectiveDate = FormatedDate(row.regEffectiveDate,true)   
    console.log("Linked Disclosure",row);

    const newRow = removeKeysFromObject(row,["disclosure_obligations_obligation","custLinkingCustDisclosureCustObligation","citationCount","citationContent"])
    return {
      ...newRow,
      obligationDesc: ValidCellValue({ value: row.obligationDesc }), //row.obligationDesc  ? row.obligationNote.replace(/<[^>]+>/g, "").slice(0,cellValueLimit) :   DisplayJustifyCenter("-"),
      // citationContent: ValidCellValue({ value: row.citationContent }),
      status:
        row.status == 1 ? "Pending" : row.status == 2 ? "Approved" : "Removed",
      createdDate: createdDate,
      updatedDate: row.updatedDate ? updatedDate :   DisplayJustifyCenter("-"),
      regulation: row.regulation ? row.regulation.regShortName : "NA",
      regulator: row.regulator ? row.regulator.regulatorShortName : "NA",
      obligationNote: ValidCellValue({ value: row.obligationNote }),
      ["# of Linked Disclosures"]:row?.disclosure_obligations_obligation && row?.disclosure_obligations_obligation?.length||0
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')

  console.log("OB_0415_0010 exportToExcel", customizedData)
  const filteredData = customizedData.map(
    ({
      incrementId,
      id,
      regulator,
      regulation,
      customerOrganization,
      obligationId,
      ...rest
    }) => rest
  )
  const arrangedColumns = [
    "obId",
    "obligationName",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "regLaw",
    "citation",
    "obligationNote",
    "status",
    "createdDate",
    "updatedDate",
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "OB_ID",
        "Obligation Name",
        "Obligation Description",
        "Citation URL",
        "Regulator Short Name",
        "Reg Law Short Name",
        "Citation",
        "Obligation Notes",
        "Status",
        "Created At",
        "Last Updated At",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  console.log("filteredData", filteredData)
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Obligations.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 108px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 80,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  setLoader,
  pageCount: customePageCount,
  filterArray,
  checkOrg,
  setCheckOrg,
  checkAdmin,
  setAddModal,
  addModal,
  setFilterArray,
  handleSort,
  setSortBy,
  obligationAccess,
  downloadAccess,
  columns,
  customePageSize,
  setCustomePageSize,
  data,
  fetchData,
  searchObject,
  props,
  handleRender,
  setSearchObject,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,setIsFilter,isFilter
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useResizeColumns,
    // useFlexLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const [linkedDisclosures, setLinkedDisclosures] = useState('All');
  const { generateSortingIndicator, toggleIndicator } = useFilterIndicator();
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      obligationIds: ids,
      customerorgId:
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    let apiUrl
    if (checkOrg !== undefined && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/export-obligations`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/export-obligation-organization`
    }

    axios
      .post(apiUrl, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
        responseType:'blob'
      })
      .then(response => {
        // console.log(data.data.data, 'exporData');
        // exportToExcel(response.data.data)
        DownloadExelFrom({responseData:response.data,fileTitle: "Obligation"})
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        // toastr.error(error, "Error Exporting Data")
        ToastError(error)
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }

  const handleSearchQueryChange = (columnKey, value) => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    // console.log(columnKey, value, "**********************************")
    // console.log("123checkOrg !== undefined && checkOrg === 3",checkOrg !== undefined && checkOrg === 3);
  
    setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []

    const data = {
      // tableName: "Obligation",
      tableName:
        checkOrg !== undefined && checkOrg === 3
          ? "Obligation"
          : "custObligations",
      feildName: columnKey,
      searchValue: value&&trimLeft(value),
      ids: idsArray
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=100000&pageNo=1`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (columnKey != "updatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }
        setSearchQuery(prev => ({ ...prev, [columnKey]: value&&trimLeft(value) }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => {
    console.log("handleAutocompleteChange", columnId, value);
    // Check if the value exists in the options
    const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);
    if (optionExists || value === "") {
      console.log("selected from suggestion box")
      // User has selected a value from suggestions or cleared the input
      let updatedValue;
      if (value === "") {
        updatedValue = ""; // Set the value to blank when selecting nothing
      } else if (columnId === "updatedDate") {
        // Set the value to the formatted date when selecting a date
        const newDate = new Date(value);
        const formattedDate = format(newDate, "yyyy-MM-dd");
        setSelectedDate(formattedDate);
        updatedValue = formattedDate;
      } else {
        updatedValue = value.trim(); // Set the value to the selected option
      }

      console.log(updatedValue, "after value trimeed")
      const updatedSearchQuery = { ...searchQuery, [columnId]: [updatedValue] };
      handleSearchQueryChange(columnId, updatedValue);
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }));
      setSearchQuery(updatedSearchQuery);
      setSearchObject(updatedSearchQuery, true);
      // console.log("API call triggered:", value);
    } else {
      // User has typed and hit enter manually
      console.log("User typed and hit enter manually:", value);
      // You can handle this case as per your requirement
      let updatedValue;
      if (value === "") {
        updatedValue = ""; // Set the value to blank when selecting nothing
      } else if (columnId === "updatedDate") {
        // Set the value to the formatted date when selecting a date
        const newDate = new Date(value);
        const formattedDate = format(newDate, "yyyy-MM-dd");
        setSelectedDate(formattedDate);
        updatedValue = formattedDate;
      } else {
        updatedValue = value&&value.trim() // Set the value to the selected option
      }

      console.log(updatedValue, "trimmed")
      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue };
      handleSearchQueryChange(columnId, updatedValue);
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }));
      setSearchQuery(updatedSearchQuery);
      setSearchObject(updatedSearchQuery, false);
    }
  };



  const handleKeyDown = (key, columnId, value) => { 
    if (key === "Enter") {
      // fetchData(searchQuery)
      console.log("handleAutocompleteChange",columnId, value)
      let updatedValue
      if (value === "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
      } else if (columnId == "updatedDate") {
        // Set the value to false when selecting "No"
        const newDate = new Date(value)
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        setSelectedDate(formattedDate)
        updatedValue = formattedDate
      } else {
        updatedValue = value.trim() // Set the value to true when selecting "Yes"
      }
  
      const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
  
      console.log("API call triggered:", value)
    }
  }
  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }

  
  const handleNumberChange = (columnKey, value) => {
    console.log(value, "value is here ")
    // console.log(searchQuery[columnKey], "column id")
    let updatedValue
    if (value === "All") {
      updatedValue = "All" // Set the value to blank when selecting "All"
    } else if (value === false) {
      updatedValue = "No" // Set the value to false when selecting "No"
    } else {
      updatedValue = "Yes" // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, ["link_status"]: updatedValue }
    setLinkedDisclosures(updatedValue)
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
  }

  console.log(searchQuery , linkedDisclosures,  "When Change")


  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    localStorage.removeItem("obligations/all-obligations/selectedFilterArray")
    localStorage.removeItem("obligations/all-obligations")
    setLinkedDisclosures('All');
    setLoader(true)
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setSearchObject({})
    setSelectedHeader([])
    setFilterArray({})
    setSortBy({
      tableName:
        checkOrg !== "" && checkOrg === 3 ? "obligation" : "custObligations",
      fieldName: "obligationName",
      order: "ASC",
    })
    setSelectedDate(null)
    // fetchData()


    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const history = useHistory()
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [modalEdit, setModalEdit] = useState(false)
  const [descEdit, setDescEdit] = useState("")
  const [titleEdit, setTitleEdit] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [updatedData, setUpdatedData] = useState("new")
  const [blankColumn, setBlankColumn] = useState("0")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const localStorageData = localStorage.getItem("obligations/all-obligations")
  useEffect(() => { 
    if (localStorageData != null) {
      const parse_data = JSON.parse(localStorageData) 
      const {otherValuesObject} = SeparateObjectByValueType(parse_data)  
      setSearchQuery(otherValuesObject) 
    }
  }, [localStorageData]);
  useEffect(() => {
    const data = localStorage.getItem("obligations/all-obligations");

    let finalData;

    if (data != null && props?.location?.state?.rowData?.isClear != undefined) {
        finalData = JSON.parse(data);
        let modifiedObject = {};

        console.log(finalData, "finalData");

        for (let key in finalData) {
            if (finalData.hasOwnProperty(key)) {
                let newKey;
                let selectedkey;

                    newKey = key
                    selectedkey = key
             
                if (Array.isArray(finalData[key])) {
                    modifiedObject[newKey] = finalData[key][0];
                } else if (typeof finalData[key] === "string") {
                    modifiedObject[newKey] = finalData[key];
                } else {
                    console.log(`Value for key ${key} is not a valid string.`);
                }

                // Check if selectedkey matches any key in selectedFilterArray
                const selectedFilterArray = JSON.parse(localStorage.getItem("obligations/all-obligations/selectedFilterArray"));
                if (selectedFilterArray && selectedFilterArray.hasOwnProperty(selectedkey)) {
                    delete modifiedObject[newKey];
                }
            }
        }

        console.log(modifiedObject, "modifiedObject");
        setAutoEmpty(modifiedObject);
    }
}, [localStorage.getItem("obligations/all-obligations")]);


  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  const toggleEdit = () => {
    setModalEdit(!modalEdit)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-obligations`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }
  const handleImportEditData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("isBlankUpdatable", blankColumn)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-obligation-update`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}` },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  const edit = () => {
    props.history.replace({
      pathname: "/obligations/obligation_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const mutiedit = () => {
    console.log(selectedFlatRows, "selectedFlatRows")
    const data = selectedFlatRows.map(item => item.original)
    history.push("/obligations/obligation_muti_edit", { rowData: data })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const data = {
      tableName: "Obligation",
      ids: ids,
    }
    
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
               ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const obj2 = JSON.parse(localStorage.getItem("orgID"))

      let newCheckOrg

      if (obj2) {
        newCheckOrg = obj2.orgId
      } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
      }

      if (newCheckOrg !== checkOrg) {
        setCheckOrg(newCheckOrg)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])
  //   useEffect(()=>{
  //     if (props.location.search.length > 5) {
  //       const Qurry = {}
  //       props.location?.search?.split("?").map((i)=>{Qurry[i?.split("=")[0]]= i?.split("=")[1]})
  //       handleAutocompleteChange("obligations",Qurry.obligations)
  //     }
  // },[])

  const SelectedFilterIcon = (filterArray,column_id)=>{
    const columnId = `${column_id}`.split("_").pop()

          try { 
              const IsColumnIdSelected = JSON.parse(localStorage.getItem("obligations/all-obligations/selectedFilterArray") || "[]")?.[columnId] || []
              const IsColumnIdSelectedObj = JSON.parse(localStorage.getItem("obligations/all-obligations/selectedFilterArray") ) || {}
              const IsColumnIdExistInFilterArray = Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(`${columnId}`.split("_").pop()))  
               if (IsColumnIdExistInFilterArray && IsColumnIdSelected.length !=0) {
                return true
              } else {
                return false
              } 
          } catch (error) {
            console.log("ooooooooooooooooooooo", "error",error)
            return false
    }
    

  }
  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={4}
          className="py-3 justify-content-end"
        >
          {/* <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">List of Obligations</h5>
            <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{width : "150px", marginLeft: "10px"}}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
          </div> */}
          <TableHeader
            heading={"List of Obligations"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={8}
          className="py-3 justify-content-end"
        >
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      // marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                     
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  {/* <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button> */}
                </>
              ) : (
                // :
                // selectedFlatRows.length >= 1 ? (
                //   <Button
                //     onClick={(e) => {
                //       onAllDeleteRecord(e)
                //     }}
                //     style={{
                //       backgroundColor: "#556EE6",
                //       marginLeft: 5,
                //       marginRight: 5,
                //       color: "#fff",
                //       textTransform: "none",
                //     }}
                //   >
                //     <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                //     Delete
                //   </Button>
                // )
                ""
              )
            ) : (
              ""
            )}
            {selectedFlatRows.length >= 1 && (
              <>
                <Button
                  onClick={() => mutiedit()}
                  style={{
                    backgroundColor: "#556EE6",
                    // marginLeft: 5,
                    color: "#fff",
                    textTransform: "none",
                    
                  }}
                >
                  {/* <Link
                          to={{
                            pathname: "/obligations/obligation_muti_edit",
                            state: { selectedFlatRows },
                          }}
                          style={{
                            color: "#fff",
                          }}
                        > */}
                  <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                  Multiple Edit
                  {/* </Link> */}
                </Button>
              </>
            )}

            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg !== 3 ? (
              <Button
                variant="contained"
                // onClick={toggle}
                onClick={() => setAddModal(true)}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                  // padding: "5px"
                }}
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Obligations
              </Button>
            ) : (
              ""
            )}

            {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Link
                className="btn btn-primary"
                to="/obligations/obligation_update"
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Obligation
              </Link>
            ) : (
              "" || ""
            )}
            {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Button
                onClick={() => {
                  setModal(!modal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )}
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px" }}
            >
              <ModalHeader toggle={toggle}>{title}</ModalHeader>
              <ModalBody className="px-4 py-4 text-left">
                <div
                  style={{ marginBottom: "20px", display: "flex", gap: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="new-data"
                      name="form-radio-primary"
                      defaultChecked={updatedData == "new"}
                      value="new"
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload new data
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="edit-data"
                      name="form-radio-primary"
                      value="edit"
                      defaultChecked={updatedData == "edit"}
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="edit-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload Edit
                    </label>
                  </div>
                </div>
                {updatedData == "edit" && (
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <DarkTooltip title="Blank cells WILL NOT overwrite existing content">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="0"
                          name="blank-column"
                          defaultChecked={blankColumn == "0"}
                          value="0"
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="0"
                          style={{ marginBottom: "0px" }}
                        >
                          Ignore Blank Cells{" "}
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                    <DarkTooltip title="Blank cells WILL overwrite existing content">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="1"
                          name="blank-column"
                          value="1"
                          defaultChecked={blankColumn == "1"}
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="1"
                          style={{ marginBottom: "0px" }}
                        >
                          Load Blank Cells
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                  </div>
                )}
                <h6>Import Data</h6>
                <div className="form-body mt-2">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-3"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button"
                    onClick={e => {
                      updatedData == "new"
                        ? handleImportData(e)
                        : handleImportEditData(e)
                    }}
                  >
                    Import Data
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {/* {obligationAccess && obligationAccess.writeAccess == true && checkOrg === 3 ?
              <Button
                onClick={() => {
                  setModalEdit(!modalEdit);
                  setDescEdit("Import Data for Update");
                  setTitleEdit("Import Data for Update");
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload for Update
              </Button>
              :
              ""
                } 
              <Modal
                isOpen={modalEdit}
                toggle={toggleEdit}
                className="modal-dialog-centered"
                style={{ borderRadius: "50px" }}
              >
                <ModalHeader toggle={toggleEdit}>{titleEdit}</ModalHeader>
                <ModalBody className="px-4 py-4 text-left">
                  <h6>Import Data for Update</h6>
                  <div className="form-body mt-2">
                    <input
                      type="file"
                      id="csvFileInput"
                      accept=".xlsx"
                      name="file"
                      onChange={onFileChange}
                      className="form-control mb-3"
                    />
                    <div className="error">{errors.file}</div>
                  </div>
                  <div className="px-6 mt-3 mb-1 text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      name="button"
                      onClick={(e) => handleImportEditData(e)}
                    >
                      Import Data
                    </button>
                  </div>
                </ModalBody>
              </Modal> */}
            {downloadAccess && downloadAccess.readAccess === true ? (
              btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={handleExport}>
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Download
                </button>
              )
            ) : (
              ""
            )}
            {/* <button
                type="button"
                className="btn btn-primary "
                // onClick={handleExport}
              >
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Upload
              </button>  */}
            {/* <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Buttn
                  variant="contained"
                  style={{
                    backgroundColor: "#556ee6",
                    color: "#fff",
                    textTransform: "none ",
                    fontFamily: "poppins",
                    fontWeight: 400,
                    fontSize: 15
                  }}
                  component="span"
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </Buttn>
              </label> */}
          </div>
        </Col>
      </Row>
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 600 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => {
                          console.log("Is Passing Please")
                          handleSort(column);
                          toggleIndicator(column.id); 
                        }}
                      >
                        {column.render("Header")}
                        <span style={{ color: "#556ee6" }}>
                        {generateSortingIndicator(column)}
                        </span>
                      </div>
                      {console.log(column.id, 'column id')}
                      {column.id != "selection" &&
                      column.id != "updatedDate" &&
                      column.id != "status" &&
                      column.id != "custLinkingCustDisclosureCustObligation" &&
                      column.id != "disclosure_obligations_obligation" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                            paddingInlineEnd: 
                            column.id === "obId" ? '7px' :
                            column.id === "obligationName" ? "2px" : 
                            (column.id === "obligationNote") ? "6px" :
                            (column.id === "regLaw" || column.id === "issuingAuthority") ? '22px' : '0px'
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{ width: column.id === 'obligationDesc' ? '60%' : "85%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              openOnFocus={false}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              options={autoEmpty[column.id] || searchQuery[column.id] ? autoFill[column.id] || [] : []} // Show options only when there's input                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value) 
                              } 
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  variant="filled"
                                  disabled={loader}
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={(e) => {
                                  //   if (e.key === 'Enter') {
                                  //     handleSearchQueryChange(column.id, searchQuery[column.id]);
                                  //   }
                                  // }}
                                  onKeyDown={(e) => {
                                   handleKeyDown(e.key,column.id,e.target.value)
                                  }}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          <button
                            onClick={() => {
                              setShowModal(true)
                              setModalData(column)
                            }}
                            disabled={loader}
                            // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            className={
                              // selectedHeader.includes(column.Header)
                              SelectedFilterIcon(filterArray,column.id)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                      ) : column.id == "updatedDate" ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: '2023-05-15',
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary docs-datepicker-trigger"
                                              // disabled
                                              style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div> */}
                        </div>
                      ) : column.id == "status" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              value={
                                searchQuery[column.id] != undefined
                                  ? searchQuery[column.id]
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              displayEmpty
                              onChange={event => {
                                handleValueChange(column.id, event.target.value);
                              }}                              
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Pending</MenuItem>
                              <MenuItem value={2}>Approved</MenuItem>
                              <MenuItem value={3}>Removed</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) :
                      column.id == "custLinkingCustDisclosureCustObligation" ||
                      column.id == "disclosure_obligations_obligation" ?
                      (
                        <div
                        style={{
                          width: "90%",
                          position: "absolute",
                          bottom: "10px",
                        }}
                      >
                        <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              // value={
                              //   (() => {
                              //     console.log(searchQuery.link_status, "Here Is The Search Query")
                              //     return searchQuery && searchQuery.link_status !== undefined
                              //     ? searchQuery.link_status
                              //     : ""
                              //   })()
                              // }
                              value={linkedDisclosures === 'All' ? 'All' 
                                : linkedDisclosures === 'Yes' ? true : false 
                              }
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              displayEmpty
                              onChange={event =>
                                handleNumberChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value='All'>All</MenuItem>
                              <MenuItem value={true}>Yes</MenuItem>
                              <MenuItem value={false}>No</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                      </div>
                      )
                      :
                      (
                        ""
                      )}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <Filter column={column} /> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          tableName={
            checkOrg !== "" && checkOrg === 3 ? "Obligation" : "custObligations"
          }
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0??false}
          // tableName={"Obligation"}
        />
      </Styles>
      {/* )} */}
      {/* </div> */}
    </Fragment>
  )
}

const Obligations = props => {
  const [fakeData, setFakeData] = useState([])
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const obj2 = JSON.parse(localStorage.getItem("orgID"))

  let newCheckOrg

  if (obj2) {
    newCheckOrg = obj2.orgId
  } else if (!obj2 && authToken) {
    newCheckOrg = authToken.data.customerorg.id
  }
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")

  const toggle = () => setModal(!modal)

  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [addModal, setAddModal] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delObligation, setDelObligation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [selectDisclosure, setSelectDisclosure] = useState([])
  const [customePageSize, setCustomePageSize] = useState(50)
  const [obligationAccess, setObligationAccess] = useState([])
  const [regrId, setRegrID] = useState(null)
  const [selectRegLaw, setSelectRegLaw] = useState([])
  const [organizationID, setOrganizationID] = useState(false)
  const [isCheckOrgSet, setIsCheckOrgSet] = useState(false)
  const [checkOrg, setCheckOrg] = useState( obj2?.orgId|| authToken?.data?.customerorg?.id)
  const [downloadAccess, setDownloadAccess] = useState([])
  const [checkAdmin, setCheckAdmin] = useState(false)
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const history = useHistory();

  const location = useLocation()
  
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('selectedFilterArray'));
      if(data && props?.location?.state?.rowData?.isClear){
        setFilterArray(data);
      }
  },[localStorage.getItem('selectedFilterArray'), props?.location?.state?.rowData?.isClear])

  useEffect(() => {
    if(props?.location?.state?.rowData?.isClear == undefined){
      localStorage.removeItem("selectedFilterArray")
      localStorage.removeItem("obligations/all-obligations/selectedFilterArray")
      localStorage.removeItem("obligations/all-obligations");
    }
  },[props?.location?.state?.rowData?.isClear])

  // console.log(location.state.rowData)

  // useEffect(() => {
  //   if (location.state && location.state.rowData) {
  //     setSearchObject({
  //       ...searchObject,
  //       ["regLaw"]: location.state.rowData.regShortName,
  //     })
  //   }
  // }, [location])

  useEffect(() => {
    const handleStorageChange = () => {
     

      if (obj2) {
        newCheckOrg = obj2.orgId
      } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
      }

      if (newCheckOrg !== checkOrg) {
        setCheckOrg(newCheckOrg)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  console.log(checkOrg, "checkOrg")

  const [sortBy, setSortBy] = useState({
    fieldName: "obligationName",
    order: "ASC",
    tableName: checkOrg !== "" && checkOrg === 3 ? "obligation" : "custObligations",
  })

  useEffect(() => {
    // setSortBy(prevSortBy => ({
    //   ...prevSortBy,
    //   tableName: checkOrg !== undefined  && checkOrg === 3 ? "obligation" : 'custObligations',
    // }));
    console.log("1234checkOrg !== undefined && checkOrg === 3",checkOrg !== undefined && checkOrg === 3);
    if (checkOrg !== undefined && checkOrg === 3) {
      setSortBy({
        tableName:   "obligation"  ,
        fieldName: "obligationName",
        order: "ASC",
      })
    } else  
      setSortBy({
        tableName:  "custObligations",
        fieldName: "obligationName",
        order: "ASC",
      })
    
  }, [checkOrg])

  const limit = 20

  const handleSort = column => { 
    console.log(column, column.Cell({}).value, "Column is here");
    if (!["disclosure_obligations_obligation"].includes(column.id)) { 
    const isAsc = sortBy.fieldName === column.id && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName:
        checkOrg !== "" && checkOrg === 3 ? "obligation" : "custObligations",
        fieldName: column.id,
        order: sortOrder,
      })
    }}  
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setObligationAccess(authToken && authToken.data.role.rolesPermission[2])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelObligation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')
    // setDelete(!is_delete)
    if (delObligation.id) {
      // dispatch(onDeleteObligation(delObligation.id, props.history))
      deleteObligation(delObligation.id)
      setDeleteModal(false)
    }
  }

  const addToggle = () => setAddModal(!addModal)

  const closeAddModal = () => {
    setAddModal(false)
    validation.resetForm()
  }
  // const test = () =>{
  //   console.log("props===>",props)

  // }
  const fetchData = searchQuery => {
    console.log(
      "currentPage123",
      currentPage,
      is_delete,
      searchObject,
      filterArray,
      customePageSize,
      sortBy,
      checkOrg,
    )
    const authToken = JSON.parse(localStorage.getItem("authUser")) 
    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   :  searchObject
    const storageFilter = JSON.parse(localStorage.getItem("home/obligations/all-obligations"))??{}
    // const searchDataObject = isFilter?convertObjectValuesToArray(searchObject):searchObject
    let finalData =   {...searchObject,...filterArray} 
    // const data = JSON.stringify({searchQuery})
    console.log(storageFilter,"test123 storageFilter")

    let apiUrl
    if (checkOrg !== "" && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-obligations/?limit=${customePageSize}&pageNo=${currentPage}`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-obligation-organization/?limit=${customePageSize}&pageNo=${currentPage}`
    }

    let data = localStorage.getItem("obligations/all-obligations")
    setLoader(true)
    if (data != null) {
      const parse_data = JSON.parse(data)
      finalData = {  ...parse_data, ...finalData }
      console.log(finalData, "test123_1")
    }
    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "test123_2")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } 
    // else {
    //   localStorage.removeItem("selectedFilterArray")
    // }

    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "test123_3")
      localStorage.setItem("obligations/all-obligations/selectedFilterArray", JSON.stringify(finalData))
    } 
    console.log(finalData,'test123_4')
    if (Object.keys(finalData).length > 0) {
      // console.log(finalData, "finalData")
      console.log(finalData,'test123_5')
      localStorage.setItem(
        "obligations/all-obligations",
        JSON.stringify(finalData)
      )
    }

    const requestData = {
      ...storageFilter,
      ...finalData,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
      orderBy: {
        ...sortBy,
      },
    }

    axios
      .post(
        //   checkOrg !== ""  && checkOrg === 3?
        //    `${process.env.REACT_APP_BASE_URL}/get-obligations/?limit=${customePageSize}&pageNo=${currentPage}`
        // : `${process.env.REACT_APP_BASE_URL}/get-obligation-organization/?limit=${customePageSize}&pageNo=${currentPage}`
        apiUrl,
        {data: encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log(response, "obs issue response")
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          // console.log(response.data.data, "obs issue response")
          setReqresData(response.data.data)
          localStorage.removeItem("home/obligations/all-obligations")
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)
          setIsFilter(false)
          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        setIsFilter(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteObligation = obligation => {
    // console.log(regulation, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-obligation?id=${obligation}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Obligation Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        ToastError(err)
      })
  }

  useEffect(() => {
    // console.log('page is loading')
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])
  const GotoDiscolur = (data) => {
    console.log(data, "data132132");
    if (data.length > 0) {
      let disIdArray = [];
      disIdArray = data.map(item => item.disId);
      const result = { "disId": disIdArray };
      console.log(result,"result");    
      localStorage.setItem("disclosures/all-disclosures", JSON.stringify(result))
      localStorage.setItem("disclosures/all-disclosures/selectedFilterArray", JSON.stringify(result))
      localStorage.setItem("selectedFilterArray", JSON.stringify(result))
      history.push({
        pathname: '/disclosures/all-disclosures',
        state: {
          rowData: {
            isClear: true
          }
        }
      });
    }
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const columns = [
    {
      Header:'Obligation ID',
      width: 125,
      accessor: "obId",
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/obligations/obligation_view",
              state: { rowData },
            }}
          >
            <span style={{ color: "#000" }}>{cellProps.value}</span>
          </Link>
        )
      },
      // width: 150
      // Cell: cellProps => {
      //   return (
      //    cellProps?.value?.length > 15 ?  cellProps?.value?.substr(0, 18) + " ..." : cellProps.value
      //   )
      // }
    },
    {
      Header: 'Obligation Name',
      accessor: "obligationName",
      filterable: false,
      disableFilters: true,
      width: 240,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/obligations/obligation_view",
              state: { rowData },
            }}
          >
            <LightTooltip title={cellProps.value}>
            <span style={{ color: "#000" }}   className="StringOverFlow1">
              {cellProps.value && cellProps?.value?.length > 15
                ? cellProps?.value 
                : cellProps.value || ""}
            </span>
              
            </LightTooltip>
          </Link>
        )
      },
    },
    {
      Header: 'Obligation Description',
      width: 102,  
      filterable: false,
      disableFilters: true,
      accessor: "obligationDesc",
      Cell: cellProps => {
        return cellProps.value ? (
          <LightTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  setDesc(cellProps.value)
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
                View Details
              </UncontrolledTooltip> */}
              {/* <div style={{ textAlign: "center", marginTop: "5px" }}>{cellProps.value}</div> */}
            </div>
          </LightTooltip>
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        )
      },
    },    
    {
      Header: 'Citation URL',
      width: 250,
      filterable: false,
      disableFilters: true,
      accessor: "citationUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div>
          
            {(CheckValidValue({value:cellProps.value})?
                    <Link to={{ pathname: cellProps.value }} target="_blank">
                    <LightTooltip title={rowData}>
                      <div
                        className="StringOverFlow1"
                        style={{
                          textDecoration: "underline",
                          fontSize: "13px",
                          color: "blue",
                        }}
                      >
                        {(cellProps.value &&
                          cellProps.value
                            .replaceAll("https://", "")
                            .replaceAll("http://", "")
                            .replaceAll("www.", "") ) ||
                          ""}
                      </div>
                    </LightTooltip>
                  </Link>
                   :
                      DisplayJustifyCenter("-"))}
          </div>
        )
      },
    },
    {
      Header: 'Regulator Short Name',
      accessor: "issuingAuthority",
      filterable: false,
      disableFilters: true,
      width:118,
      Cell: cellProps => {
        return  ColumnDataWithTooltip({content:cellProps?.value }) 
      },
    },
    {
      Header: "Reg Law Short Name",
      accessor: "regLaw",
      filterable: false,
      disableFilters: true,
      width:118,
      Cell: cellProps => {
        return  ColumnDataWithTooltip({content:cellProps?.value }) 
      },
    },
    {
      Header: "Citation",
      accessor: "citation",
      width: 250,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return ColumnDataWithTooltip({content:cellProps?.value }) 
        
        
      },
    },
    {
      Header: "Obligation Notes",
      width : 117,
      accessor: "obligationNote",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return  ColumnDataWithTooltip({content:cellProps?.value }) 
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: 90,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <Badge
            className={
              cellProps.value === 2
                ? "font-size-11 badge-soft-success"
                : cellProps.value == 3
                ? "font-size-11 badge-soft-danger"
                : "font-size-11 badge-soft-warning"
            }
          >
            {cellProps.value == 1
              ? "Pending"
              : cellProps.value == 2
              ? "Approved"
              : "Removed"}
          </Badge>
        )
      },
    },      
    // {
    //   Header: "Linked Disclosure",
    //   accessor: "linked_disclosure",
    //   filterable: false,
    //   disableFilters: true,
    //   width:175,
    //   Cell: cellProps => {
    //     return  ColumnDataWithTooltip({content:cellProps?.value }) 
    //   },
    // },
    {
      Header: "# of Linked Disclosures",
      accessor: checkOrg !== undefined && checkOrg === 3 ? "disclosure_obligations_obligation" : "custLinkingCustDisclosureCustObligation",
      width:102,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <div onClick={() => {GotoDiscolur(cellProps.value)}} style={{ textAlign: "center", cursor: cellProps?.value?.length > 0 ? "pointer" : "default" }}>
            {cellProps.value && cellProps?.value?.length > 0
              ? cellProps?.value?.length
              : "0"}
          </div>
        )
      },
      show: true,
    },
    {
      Header: "Last Update Date",
      width : 120,
      accessor: "updatedDate",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const date1 = FormatedDate(cellProps.value)   
        if (cellProps.value) {
          return date1
        } else {
          return  DisplayJustifyCenter("-")
        }
      },
    },
   
    {
      Header: "Action",
      textAlign: "top",
      disableFilters: true,
      sticky: "right",
      width: 180,
      filterable: false,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        // console.log(cellProps.row.original,'cellProps.row.original')
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "1.2rem" }}
          >
            {/* <LightTooltip  */}
            {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    to={{
                      pathname: "/obligations/obligation_update",
                      state: { rowData },
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}
            {obligationAccess && obligationAccess.readAccess === true ? (
              <Badge className="font-size-15 badge-soft-primary" pill>
                <DarkTooltip title="View">
                  <Link
                    to={{
                      pathname: "/obligations/obligation_view",
                      state: { rowData },
                    }}
                    onClick={() => {
                      const orderData = cellProps.row.original
                      // console.log(orderData, 'data')
                      // handleOrderClick(orderData);
                    }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "blue", cursor: "pointer" }}
                      className="mdi mdi-eye-outline  font-size-18"
                      id="customerViewtooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )}
            {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                {/* <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                > */}
                <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip>
                {/* </li> */}
              </Badge>
            ) : (
              ""
            )}
            {obligationAccess && obligationAccess.readAccess === true && cellProps.row.original && checkOrg !== undefined && checkOrg === 3 ? cellProps.row.original.disclosure_obligations_obligation.length > 0 ?
              <Badge className="font-size-15 badge-soft-primary" pill>
              <DarkTooltip title="Linked Disclosures">
                  <i
                      onClick={() => GotoDiscolur(cellProps.row.original.disclosure_obligations_obligation)}
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-outline  font-size-18"
                    id="customerViewtooltip"
                  ></i>
              </DarkTooltip>
            </Badge>
            : null : cellProps.row.original.custLinkingCustDisclosureCustObligation.length > 0 ? <Badge className="font-size-15 badge-soft-primary" pill>
            <DarkTooltip title="Linked Disclosures">
                <i
                    onClick={() => GotoDiscolur(cellProps.row.original.custLinkingCustDisclosureCustObligation)}
                  style={{ color: "blue", cursor: "pointer" }}
                  className="mdi mdi-eye-outline  font-size-18"
                  id="customerViewtooltip"
                ></i>
            </DarkTooltip>
          </Badge>: null }
          </ul>
        )
      },
    },
  ]

  const reports = [
    {
      title: "Total Obligations",
      iconClass: "bx-copy-alt",
      description: totalItems && totalItems,
    },
    { title: "New Obligations", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Obligations Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Obligations Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  // console.log(fakeData, "fakeData")
  document.title = "Obligations"
  const handleRender = () => {
    setDelete(!is_delete)
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }

  const handleObsChange = option => {
    console.log(option.value, "opionss")
    validation.setFieldValue("obIds", option ? option.map(x => x.value) : [])
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (checkOrg !== undefined && addModal) {
      const data = {
        customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      }
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/get-all-regulations`,
          {data:encryptData(data)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            console.log(response, "Regulations response")
            const optionData = response.data.data.map(option => ({
              value: option.id,
              label: option.regshortname,
            }))

            const modifiedOptions = [
              { label: "Select Reg Law Short Name", value: "" }, // 0 option
              ...optionData, // API options
            ]

            setSelectRegLaw(optionData)
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          // throw message;
          ToastError(err)
        })
    }
  }, [checkOrg, addModal])

  const fetchObligations = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      regulationId: regrId,
      customerorgId: checkOrg && parseInt(checkOrg),
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-all-obligations`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        console.log("res", response.data)
        if (response.status >= 200 || response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: `${option.obId} (${
              option.obligationName && option.obligationName.length > 8
                ? option.obligationName.substr(0, 45) + " ..."
                : option.obligationName ||   DisplayJustifyCenter("-")
            })`,
          }))

          const modifiedOptions = [
            { label: "Select Obligations", value: "" }, // 0 option
            ...optionData, // API options
          ]

          setSelectDisclosure(optionData)
          // return response.data;
          // setSelectObligation(response.data.data.map(option => ({
          //   value: option.id,
          //   label: `${option.obId} (${option.obligationName && option.obligationName.length > 8
          //     ? option.obligationName.substr(0, 45) + " ..."
          //     : option.obligationName ||'-'
          //   })`
          // }
          // )))
          // setTotalItems(response.data.totalRecords)
          // setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    if (regrId) {
      fetchObligations()
    }
  }, [regrId])

  const handleRegrID = id => {
    if (id === 0 || id === null) {
      setRegrID(null)
      // setSelectDisclosure([{ label: 'Select Regulations', value: 0 }]);
    } else {
      console.log("reg id", id)
      setRegrID(id)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reg_id: "",
      obIds: [],
    },
    validationSchema: Yup.object({
      obIds: Yup.array()
        .of(Yup.string().required("Please Select Obligation(s)"))
        .min(1, "Please Select at least one Obligation"),
    }),
    onSubmit: values => {
      console.log(values, "values")

      const addData = {
        ids: values.obIds.toString(),
        customerorgId: checkOrg,
      }
      console.log("addData", addData)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/add-obligation-organization`,
          {data:encryptData(addData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // setSuccess(true)
            toastr.success("Obligations Successfully Added.")
            setDelete(!is_delete)
            setAddModal(false)
            validation.resetForm()
            return response.data
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          let message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = "Bad request! Already Linked"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          // return message
          // throw message;
          ToastError(err)
        })
    },
  })
  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Obligation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={addModal}
            // toggle={toggle}
            toggle={addToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={closeAddModal}>
              Add Record from Admin DB
            </ModalHeader>
            <ModalBody className="text-left">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Select Reg Law Short Name
                        </Label>
                        <Select
                          options={selectRegLaw}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="reg_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={selectRegLaw.find(
                            option => option.value === validation.values.reg_id
                          )}
                          onChange={option => {
                            validation.setFieldValue("reg_id", option.value)
                            handleRegrID(option.value)
                            validation.setFieldValue("ob_id", "") // Reset regulationId to 0 when regulatorId changes
                          }}
                        />
                        {validation.touched.reg_id &&
                        validation.errors.reg_id ? (
                          <div className="error">
                            {validation.errors.reg_id}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Obligations</Label>
                        <Select
                          options={selectDisclosure}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="obIds"
                          isMulti
                          value={selectDisclosure.filter(option =>
                            validation.values.obIds.includes(option.value)
                          )}
                          // value={selectDisclosure.find(
                          //   option => option.value === validation.values.obIds
                          // )}
                          onChange={handleObsChange}
                          onBlur={validation.handleBlur}
                          isDisabled={!validation.values.reg_id}
                        />
                        {validation.touched.obIds && validation.errors.obIds ? (
                          <div className="error">{validation.errors.obIds}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button type="submit" className="me-2" color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={closeAddModal}
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form>
            </ModalBody>
          </Modal>
          {/* <Row>
            {reports.map((report, key) => (
              <Col className="col-xl-3 col-md-6" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody
                    className="custome-card"
                    style={{ padding: "10px 11px !important" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-muted">{report.title}</h6>
                      </div>
                      <div
                        className="avatar-sm bg-primary align-self-center mini-stat-icon"
                        style={{ borderRadius: "100%" }}
                      >
                        <span
                          className="avatar-title bg-primary"
                          style={{ borderRadius: "100%" }}
                        >
                          <p className="mb-0 font-size-18">
                            {report.description}
                          </p>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row>
            <Col xs="12" >
              <Card>
                <CardBody className="table-class">
                  {/* <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-sm-0 font-size-18">
                        List of Obligations
                      </h5>
                    </div>
                  </div> */}
                  <TableContainer
                    columns={columns}
                    data={reqresData}
                    searchObject={searchObject}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    checkAdmin={checkAdmin}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    setAddModal={setAddModal}
                    addModal={addModal}
                    filterArray={filterArray}
                    obligationAccess={obligationAccess}
                    downloadAccess={downloadAccess}
                    setFilterArray={setFilterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    props={props}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    setSearchObject={setSearchObject}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    setLoader={setLoader}
                    isFilter={isFilter}
                    setIsFilter={setIsFilter}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Obligations)
