import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next" 
// import { useSelector } from "react-redux"

export let  readWriteAccessForExport  = ""

export let  ExportObligationReadWriteAccess  = ""
export let  ExportDisclosureReadWriteAccess  = ""
export let  ExportLinkReadWriteAccess  = ""
export let  ExportRegulationReadWriteAccess  = ""
export let  ExportIdUserRole1 = ""
export let  ExportDownloadAccess  = ""

const SidebarContent = props => {
  const ref = useRef()
  const [checkAdmin, setCheckAdmin] = useState(false)
  const [regulatorAccess, setRegulatorAccess] = useState([])
  const [regulationAccess, setRegulationAccess] = useState([])
  const [obligationAccess, setObligationAccess] = useState([])
  const [disclosureAccess, setDisclosureAccess] = useState([])
  const [linkAccess, setLinkAccess] = useState([])
  const [downloadAccess, setDownloadAccess] = useState([])
  const [unLinkAccess, setUnLinkAccess] = useState([])
  const [activeMenuItem, setActiveMenuItem] = useState("")
  console.log(activeMenuItem, "ManuItem")
  const [user, setUser] = useState()
  const [checkOrg, setCheckOrg] = useState()
  const JSONTokenData = localStorage.getItem("authUser")?JSON.parse(localStorage.getItem("authUser")):{data:{authToken:""}}
  const userRoleId =   JSONTokenData?.data?.role?.id??1000000

  // const {sidebarClose} = useSelector((state) => state.Dashboard)

  const pathName = props.location.pathname
  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (
      (authToken && authToken.data.role.id === 1) ||
      authToken.data.role.id === 17
    ) {
      // console.log(authToken.data.role.id, "authToken.data.role.id")
      setCheckAdmin(true)
    }
    setRegulatorAccess(authToken && authToken.data.role.rolesPermission[0])
    setRegulationAccess(authToken && authToken.data.role.rolesPermission[1])
    setObligationAccess(authToken && authToken.data.role.rolesPermission[2])
    setDisclosureAccess(authToken && authToken.data.role.rolesPermission[3])
    setLinkAccess(authToken && authToken.data.role.rolesPermission[4])
    setUnLinkAccess(authToken && authToken.data.role.rolesPermission[6])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem && matchingMenuItem.pathname === activeMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])
  useEffect(() => {
    ref.current.recalculate()
  })
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  function activateParentDropdown(item) {
    setActiveMenuItem(item.pathname)
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
      setUser(authToken.data.role.id)
    }
  }, [])
 

  const ApogeeUserCondition = (linkAccess.writeAccess === true || linkAccess.readAccess === true) && checkOrg === 3

const readWriteAccess =  regulationAccess.writeAccess === true ||  regulationAccess.readAccess === true 
const IdUserRole1 = userRoleId ===1


const obligationReadWriteAccess =  obligationAccess.writeAccess === true ||  obligationAccess.readAccess === true 
const disclosureReadWriteAccess =  disclosureAccess.writeAccess === true ||  disclosureAccess.readAccess === true 
const linkReadWriteAccess =  linkAccess.writeAccess === true ||  linkAccess.readAccess === true 
const regulationReadWriteAccess =  regulationAccess.writeAccess === true ||  regulationAccess.readAccess === true 
const downloadAccessReadWriteAccess =  downloadAccess.writeAccess === true ||  downloadAccess.readAccess === true 

useEffect(()=>{
  ExportObligationReadWriteAccess = obligationReadWriteAccess
  ExportDisclosureReadWriteAccess = disclosureReadWriteAccess
  ExportLinkReadWriteAccess = linkReadWriteAccess
  ExportRegulationReadWriteAccess = regulationReadWriteAccess
  ExportIdUserRole1 = IdUserRole1
  // setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  ExportDownloadAccess= downloadAccessReadWriteAccess
},[obligationReadWriteAccess,
  disclosureReadWriteAccess,
  linkReadWriteAccess,IdUserRole1,regulationReadWriteAccess])

  const test = () =>{
    console.log("downloadAccess",downloadAccess);
  }
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" onClick={test}>
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li className={pathName === "/home" ? "mm-active" : ""}>
              {/* <li> */}
              <Link to="/home">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {regulatorAccess.writeAccess === true ||
            regulatorAccess.readAccess === true ? (
              <li
                className={pathName.includes("/regulators") ? "mm-active" : ""}
              >
                {/* // <li> */}
                <Link to="/regulators/all-regulators">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Regulators")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {regulationAccess.writeAccess === true ||
            regulationAccess.readAccess === true ? (
              <li
                className={pathName.includes("/regulations") ? "mm-active" : ""}
              >
                {/* // <li> */}
                <Link to="/regulations/all-regulations">
                  <i className="bx bx-notepad"></i>
                  <span>{props.t("Regulations")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {obligationAccess.writeAccess === true ||
            obligationAccess.readAccess === true ? (
              <li
                className={pathName.includes("/obligations") ? "mm-active" : ""}
              >
                {/* // <li> */}
                <Link to="/obligations/all-obligations">
                {/* <Link to=""> */}
                  <i className="bx bx-receipt"></i>
                  <span>{props.t("Obligations")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {disclosureAccess.writeAccess === true ||
            disclosureAccess.readAccess === true ? (
              <li
                className={pathName.includes("/disclosures")||pathName.includes("/disclosure_view") ? "mm-active" : ""}
              >
                {/* // <li> */}
                <Link to="/disclosures/all-disclosures">
                  <i className="bx bx-copy-alt"></i>
                  <span>{props.t("Disclosures")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {(linkAccess.writeAccess === true ||
              linkAccess.readAccess === true) &&
            checkOrg === 3 ? (
              <li
                className={pathName.includes("/link-table") ? "mm-active" : ""}
              >
                <Link to="/link-table">
                  <i className="bx bx-link-alt"></i>
                  <span>{props.t("Link Table")}</span>
                </Link>
              </li>
            ) : checkOrg !== 3 ? (
              <li
                className={
                  pathName.includes("/cust-link-table") ? "mm-active" : ""
                }
              >
                <Link to="/cust-link-table">
                  <i className="bx bx-link-alt"></i>
                  <span>{props.t("Link Table")}</span>
                </Link>
              </li>
            ) : (
              ""
            )}







            {/* {checkOrg === 3 && (
              <li
                className={
                  pathName.includes("/pages-comingsoon") ? "mm-active" : ""
                }
              > 

                <Link to="/pages-comingsoon">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Customers")}</span>
                </Link>
              </li>
            )} */}
            {/* <li className={pathName.includes("/reports") ? "mm-active" : ""}> */}
            {/* <li>
            <Link to="/reports">
                <i className="bx bx-file"></i>
                <span>{props.t("Reports")}</span>
              </Link>
            </li> */}

            <li
              className={
               `${ pathName.includes("/unlinked-report") ||
               pathName.includes("/reports")
                 ? "mm-active"
                 : ""}   `
              }

           
            >
              <Link to="/#"  className="has-arrow">
                <i className="bx bx-file"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul  className="sub-menu">
                {(linkAccess.writeAccess === true ||
                  linkAccess.readAccess === true) &&
                checkOrg === 3 ? (
                  <li
                    className={pathName.includes("/reports") ? "mm-active" : ""}
                  >
                    <Link to="/reports">{props.t("Linked Report")}</Link>
                  </li>
                ) : checkOrg !== 3 ? (
                  <li
                    className={
                      pathName.includes("/cust-reports") ? "mm-active" : ""
                    }
                  >
                    <Link to="/cust-reports">{props.t("Linked Report")}</Link>
                  </li>
                ) : (
                  ""
                )}

                {(unLinkAccess.writeAccess === true ||
                  unLinkAccess.readAccess === true) &&
                checkOrg === 3 ? (
                  <li
                    className={
                      pathName.includes("/unlinked-report") ? "mm-active" : ""
                    }
                  >
                    <Link to="/unlinked-report">
                      {props.t("Unlinked Disclosures")}
                      <br />
                      <span className="report-text">Report</span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </li>

            
            
        

 

{/* ggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg */}





{/* { checkOrg !== 3 && (
              <li  className={
                pathName.includes("/cust-new-obli-link") ||
                pathName.includes("/cust-updated-obli-report-link") ||
                pathName.includes("/cust-updated-obli-report-view-discro") ||
                pathName.includes("/cust-updated-obli-report-view-link") 
                
                  ? "mm-active"
                  : ""
              }> 
                <Link to="/cust-new-obli-link">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Disclosure Review Report")}</span>
                </Link>
              </li>
            )} */}

            { checkOrg !== 3 && (
              <li  className={
                pathName.includes("/client-disclosure-change-request")                 
                  ? "mm-active"
                  : ""
              }> 
                <Link to="/client-disclosure-change-request">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Disclosure Change Request")}</span>
                </Link>
              </li>
            )}







 


{/* ggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg */}
            {user !== 20 && (
              <li className={`${pathName.includes("/settings") ? "mm-active" : ""} `}>
                {/* <li> */}
                <Link to="/settings">
                  <i className="bx bx-list-ul "></i>
                  <span>{props.t("Settings")}</span>
                </Link>
              </li>
            )}
 
              
                <>  
                  <li
                    className={
                      `${
                        pathName.includes("/new-obli-report") ||
                      pathName.includes("/updated-obli-report") ||
                      pathName.includes("/updated-obli-report-link") ||
                      pathName.includes("/new-obli-link") ||
                      pathName.includes("/updated-obli-report-view-discro") ||
                      pathName.includes("/updated-obli-report-view-link") ||
                      pathName.includes("/updated-obli-report") ||
                      pathName.includes("/updated-obli-report-view") ||
                      pathName.includes("/rer-new-obli-report") ||
                      pathName.includes("/rer-updated-obli-report") ||
                      pathName.includes("/rer-updated-obli-report-view")||
                      pathName.includes("/url-new-obli-report") ||
                      pathName.includes("/url-updated-obli-report") ||
                      pathName.includes("/url-updated-obli-report-view")  ||
                      pathName.includes("/disclosure-change-request") ||
                      pathName.includes("/work-flow-report") ||
                      pathName.includes("/updated-workflow-report") ||
                      pathName.includes("/reg-review-report")||
                      pathName.includes('/updated-workflow-report-view') ||
                      pathName.includes('/review-Details') ||
                      pathName.includes('/author-review-report') ||
                      pathName.includes('/author-review-detail')
                        ? "mm-active"
                        : ""
                      }
                      
                      ${
                        IdUserRole1?"":"d-none"
                      }
                      `
                    }
                  >
                    <Link to="/#"  className={`has-arrow ${  checkOrg === 3 ?"":"d-none"}`}>
                      <i className="mdi mdi-radar"></i>
                      <span>{props.t("Production Scan")}</span>
                    </Link>
                    <ul  className="sub-menu">
                      {/* {checkOrg === 3 && ( */}
                      <>
                      {/* {checkOrg === 3 && ( */}
                        <li
                          className={ 
                            pathName.includes("/new-obli-report") ||
                            pathName=="/updated-obli-report" ||
                            pathName== "/updated-obli-report-view"   
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/new-obli-report">
                            {props.t("Reg Content Change ")}
                            <br/>
                            <span>Report</span>
                          </Link>
                        </li>
                        <li
                          className={ 
                            pathName.includes("/work-flow-report") ||
                            pathName=="/updated-workflow-report" ||
                            pathName== '/reg-review-report' ||
                            pathName == '/updated-workflow-report-view' ||
                            pathName == '/review-Details'|| 
                            pathName == '/author-review-report'||
                            pathName == '/author-review-detail'
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/work-flow-report">
                            {props.t("Work Flow")}
                            <br/>
                          </Link>
                        </li>
                        {/* <li
                          className={ 
                            pathName.includes("/url-new-obli-report") ||
                            pathName=="/url-updated-obli-report" ||
                            pathName== "/url-updated-obli-report-view"   
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/url-new-obli-report">
                            {props.t("Reg Content Change")}
                            <br/>
                            <span>Report With Citation</span>
                          </Link>
                        </li> */}
                    {/* //   )}
                    //  {checkOrg === 3 && ( */}
                        <li
                          className={
                            pathName.includes("/new-obli-link") ||
                            pathName.includes("/updated-obli-report-link") ||
                            pathName.includes("/updated-obli-report-view-discro") ||
                            pathName.includes("/updated-obli-report-view-link") 
                            
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/new-obli-link">
                            {props.t("Disclosure Review Report")}
                          </Link>
                        </li>
                        <li
                          className={
                            pathName.includes("/disclosure-change-request")
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/disclosure-change-request">
                            {props.t("Disclosure Change")}
                            <br/>
                            <span>Request</span>
                          </Link>
                        </li>
                      {/* // )}

                      // {checkOrg === 3 && ( */}
                        <li
                          className={
                            pathName.includes("/rer-new-obli-report") ||
                            pathName.includes("/rer-updated-obli-report") ||
                            pathName.includes("/rer-updated-obli-report-view")
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/rer-new-obli-report">
                            <span>{props.t("Regulation Scan Error")}</span>
                            <br/>
                            <span>Report</span> 
                          </Link>
                        </li>
                      {/* // )} */}

                        {/* // )} */}
                      </>
                      {/* )} */}
                    </ul>
                  </li>
                  <li
                    className={ 
                    `${  pathName.includes("/test-obligations/all-obligations")  ||
                    pathName.includes("/test-new-obli-report") ||
                    pathName.includes("/ti-new-obli-link") ||
                    pathName.includes("/ti-updated-obli-report-view-discro") ||
                    pathName.includes("/ti-updated-obli-report-view") ||
                    pathName.includes("/ti-updated-obli-report-link") ||
                    pathName.includes("/test-updated-obli-report-view-link") ||
                    pathName.includes("/test-updated-obli-report") ||
                    pathName.includes("/test-new-obli-report") ||
                    pathName.includes("/test-updated") ||
                    pathName.includes("/tier-new-obli-report") ||
                    pathName.includes("/tier-updated-obli-report") ||
                    pathName.includes("/tier-updated-obli-report-view")
                      ? "mm-active"
                      : ""}
                      
                      ${
                        IdUserRole1?"":"d-none"
                      }
                      `
                    }
                  >
                    <Link to="/#" className={`has-arrow ${  checkOrg === 3 ?"":"d-none"}`}>
                      <i className="mdi mdi-radar"></i>
                      <span>{props.t("Test Scan")}</span>
                    </Link>
                    <ul  className="sub-menu">
                      {/* {checkOrg === 3 && ( */}
                      <>
                      <li
                          className={
                            pathName.includes("/test-obligations") ? "mm-active" : ""
                          }
                        >
                          <Link to="/test-obligations/all-obligations">
                            <span>{props.t("Test Obligations")}</span>
                          </Link>
                        </li>
                        <li
                          className={
                            pathName.includes("/test-new-obli-report") ||
                            pathName.includes("/test-updated-obli-report") ||
                            pathName.includes("/test-updated-obli-report-view-link")  
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/test-new-obli-report">
                            {props.t("Test Reg Content Change")}
                            <br/>
                            <span>Report</span>
                          </Link>
                        </li>
                        {/* {checkOrg === 3 && ( */}
                        <li
                          className={
                            [
                              "/ti-new-obli-link",
                              "/ti-updated-obli-report-link",
                              "/ti-updated-obli-report-view",
                              "/ti-updated-obli-report-view-discro",
                            ].includes(pathName)
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/ti-new-obli-link">
                            {/* {props.t("Test Reg Change Ob and Disc Review Report")} */}
                            {props.t("Disclosure Review Report")}
                          </Link>
                        </li>

                        <li
                          className={
                            pathName.includes("/tier-new-obli-report") ||
                            pathName.includes("/tier-updated-obli-report") ||
                            pathName.includes("/tier-updated-obli-report-view")
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link to="/tier-new-obli-report">
                            <span>
                              {props.t("Test Error Reg Content")}
                              <br/>
                              <span>Change Report</span>
                            </span>
                          </Link>
                        </li>

                        {/* // )} */}
                      </>
                      {/* )} */}
                    </ul>
                  </li>
                </>
            
        
            {/* <li
              className={
                pathName.includes("/test-obligations/all-obligations")  
               
                  ? "mm-active"
                  : ""
              }
            >
              <Link to="/#" className="has-arrow">
                <i className="bx bx-file"></i>
                <span>{props.t("Test Interface")}</span>
              </Link>
              <ul className="sub-menu"> 
                <>
                  <li
                    className={
                      pathName.includes("/test-obligations") ? "mm-active" : ""
                    }
                  >
                    <Link to="/test-obligations/all-obligations">
                      <span>{props.t("Test Obligations")}</span>
                    </Link>
                  </li>
                   
                </> 
              </ul>
            </li> */}

        
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}
export default withRouter(withTranslation()(SidebarContent))
