import {
  GET_REGULATORS_SUCCESS,
  GET_REGULATORS_FAIL,
  ADD_REGULATOR_SUCCESS,
  ADD_REGULATOR_FAIL,
  UPDATE_REGULATOR_SUCCESS,
  UPDATE_REGULATOR_FAIL,
  DELETE_REGULATOR_SUCCESS,
  DELETE_REGULATOR_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  regulatorsData: [],
  loading: true,
  error: null,
  errorValue: "",
  success: true,
}

const Regulators = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REGULATORS_SUCCESS:
      return {
        ...state,
        regulatorsData: action.payload,
        loading: false,
        error: false,
        success: true,
      }

    case GET_REGULATORS_FAIL:
      return {
        ...state,
        loading: false,
        errorValue: action.payload,
      }

    case ADD_REGULATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      }

    case ADD_REGULATOR_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case UPDATE_REGULATOR_SUCCESS:
      // console.log(action.payload.message, 'see')
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      }
    case UPDATE_REGULATOR_FAIL:
      // console.log(action, 'error')
      return {
        // state ={
        ...state,
        loading: false,
        error: true,
        // }
      }
    case DELETE_REGULATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      }

    case DELETE_REGULATOR_FAIL:
      return {
        ...state,
        error: true,
        errorValue: action.payload,
      }
    // break
    default:
      return state
  }
}

export default Regulators
