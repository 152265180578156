import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const { setShowTable, setShowOrgaTable, setShowRolesTable } = props
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {setShowTable ? (
                <BreadcrumbItem>
                  <Link onClick={() => setShowTable("table")} to={props.path}>
                    {props.title}
                  </Link>
                </BreadcrumbItem>
              ) : setShowOrgaTable ? (
                <BreadcrumbItem>
                  <Link
                    onClick={() => setShowOrgaTable("table")}
                    to={props.path}
                  >
                    {props.title}
                  </Link>
                </BreadcrumbItem>
              ) : setShowRolesTable ? (
                <BreadcrumbItem>
                  <Link
                    onClick={() => setShowRolesTable("table")}
                    to={props.path}
                  >
                    {props.title}
                  </Link>
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem>
                  <Link to={props.path}>{props.title}</Link>
                </BreadcrumbItem>
              )}

              <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
