// get REGULATIONS
export const GET_REGULATIONS = "GET_REGULATIONS"
export const GET_REGULATIONS_SUCCESS = "GET_REGULATIONS_SUCCESS"
export const GET_REGULATIONS_FAIL = "GET_REGULATIONS_FAIL"

// add REGULATION

export const ADD_NEW_REGULATION = "ADD_NEW_REGULATION"
export const ADD_REGULATION_SUCCESS = "ADD_REGULATION_SUCCESS"
export const ADD_REGULATION_FAIL = "ADD_REGULATION_FAIL"

// edit REGULATION
export const UPDATE_REGULATION = "UPDATE_REGULATION"
export const UPDATE_REGULATION_SUCCESS = "UPDATE_REGULATION_SUCCESS"
export const UPDATE_REGULATION_FAIL = "UPDATE_REGULATION_FAIL"

/**
 * Delete REGULATION
 */
export const DELETE_REGULATION = "DELETE_REGULATION"
export const DELETE_REGULATION_SUCCESS = "DELETE_REGULATION_SUCCESS"
export const DELETE_REGULATION_FAIL = "DELETE_REGULATION_FAIL"
