import { ButtonLoader, CirculareLoader } from 'helpers/helper_function';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function ActionModal(props) {
  const {Body,ModalHeading,modelLoader} = props
  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"  >
        {ModalHeading??""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
       {Body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleSubmit}> {modelLoader?<ButtonLoader/>:""}  {props.btnMassege}</Button>
      </Modal.Footer>
    </Modal>
  );
}

 