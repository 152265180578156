import React, { useEffect, useState, useMemo, Fragment } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"

import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"

import { getOrders as onGetOrders } from "store/actions"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

// import TableContainer from "../../../components/Common/TableContainer"
// import { TableHeader, Search } from "../../Datatable/index"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const exportToExcel = selectedRows => {
  const data = selectedRows.map(row => row.original)
  const header = Object.keys(data[0])
  const worksheet = XLSX.utils.json_to_sheet(data, { header })
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "selected_rows.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useBlockLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    exportToExcel(selectedFlatRows)
  }

  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)
  const [menu, setMenu] = useState(false)
  // const [toggle, settoggle] = useState(0.5)

  return (
    <Fragment>
      <Row className="mt-3">
        {/* <Col md={customPageSizeOptions ? 2 : 1}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col> */}
        <Col md={4} className="justify-content-start">
          <div className="d-flex flex-wrap gap-1  justify-content-start">
            <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
              <div className="position-relative">
                <label htmlFor="search-bar-0" className="search-label">
                  <span id="search-bar-0-label" className="sr-only">
                    Search this table
                  </span>
                  <input
                    // onChange={e => {
                    //   setValue(e.target.value)
                    //   onChange(e.target.value)
                    // }}
                    id="search-bar-0"
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: "#F3F3F9" }}
                    placeholder={`${count} records...`}
                    // value={value || ""}
                  />
                </label>
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
            {/* <div className="me-xxl-2 my-3 my-xxl-0 d-inline-block">
            

              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn btn-rounded btn-outline"
                  style={{ border: "1px solid black" }}
                  id="page-header-user-dropdown"
                  tag="button"
                  // onClick={makeBlur1}
                >
                  <i className="bx bx-filter font-size-16 align-middle me-1"></i>
                  Filter
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem tag="a" href="/profile">
                    <div>
                      <div>
                        <IndeterminateCheckbox
                          {...getToggleHideAllColumnsProps()}
                        />{" "}
                        Toggle All
                      </div>
                      {allColumns.map(column => (
                        <div key={column.id}>
                          <label>
                            <input
                              type="checkbox"
                              {...column.getToggleHiddenProps()}
                            />{" "}
                            {column.id}
                          </label>
                        </div>
                      ))}
                      <br />
                    </div>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}
          </div>
        </Col>
        {isJobListGlobalFilter && <JobListGlobalFilter />}
        {isAddOptions && (
          <Col md={8} className="py-3 justify-content-end">
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              {/* <Button
            variant="contained"
            //   onClick={handleAdd}
            style={{
              backgroundColor: "#556ee6",
              marginLeft: 5,
              color: "#fff",
              textTransform: "none",
              // padding: "5px"
            }}
          >
            <i className="bx bx-upload font-size-16 align-middle me-1"></i>{" "}
            Upload
          </Button> */}
              <Link
                className="btn btn-primary"
                to="/disclosure-text/disclosure-text_update"
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Disclosure Text
              </Link>
              {/* <button
                type="button"
                className="btn btn-primary "
                onClick={handleExport}
              >
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Download
              </button>
              <button
                type="button"
                className="btn btn-primary "
                // onClick={handleExport}
              >
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Upload
              </button> */}
              {/* <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Buttn
                  variant="contained"
                  style={{
                    backgroundColor: "#556ee6",
                    color: "#fff",
                    textTransform: "none ",
                    fontFamily: "poppins",
                    fontWeight: 400,
                    fontSize: 15
                  }}
                  component="span"
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </Buttn>
              </label> */}
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id} {...column.getHeaderProps()}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      <span style={{ color: "#556ee6" }}>
                        {" "}
                        {generateSortingIndicator(column)}
                      </span>
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {/* <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre> */}
      </div>

      <Row className="mt-3 justify-content-md-center justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

const DisclosureText = props => {
  const [fakeData, setFakeData] = useState([])

  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")

  const toggle = () => setModal(!modal)

  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = () => {
    // setOrder(order);
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    setDeleteModal(false)

    // if (order.id) {
    //   dispatch(onDeleteOrder(order));
    // }
  }

  const reg_data = [
    {
      discl_id: "DODB000644",
      text: "By providing your contact information, THE BANK has your consent for you to receive {{{Telemarketing and Advertising calls or advirtisemnts}}} to your  {{{cell phone/residential line}}} by {{{an automatic dialing system or prerecorded messages}}} and has notified you that providing this consent is not a condition of purchasing any BANK product or service. {{{Message and data rates may apply.}}}",
      sample: "Text",
      sample_url1:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url2:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url3:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      safe_harb: "-",
      discl_notes: "FINRA 2242",
    },
    {
      discl_id: "DODB000646",
      text: "By providing your contact information, THE BANK has your consent for you to receive {{{Telemarketing and Advertising calls or advirtisemnts}}} to your  {{{cell phone/residential line}}} by {{{an automatic dialing system or prerecorded messages}}} and has notified you that providing this consent is not a condition of purchasing any BANK product or service. {{{Message and data rates may apply.}}}",
      sample: "Text",
      sample_url1:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url2:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url3:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      safe_harb: "-",
      discl_notes: "FINRA 2242",
    },
    {
      discl_id: "DODB000642",
      text: "By providing your contact information, THE BANK has your consent for you to receive {{{Telemarketing and Advertising calls or advirtisemnts}}} to your  {{{cell phone/residential line}}} by {{{an automatic dialing system or prerecorded messages}}} and has notified you that providing this consent is not a condition of purchasing any BANK product or service. {{{Message and data rates may apply.}}}",
      sample: "Text",
      sample_url1:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url2:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url3:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      safe_harb: "-",
      discl_notes: "FINRA 2242",
    },
    {
      discl_id: "DODB000648",
      text: "By providing your contact information, THE BANK has your consent for you to receive {{{Telemarketing and Advertising calls or advirtisemnts}}} to your  {{{cell phone/residential line}}} by {{{an automatic dialing system or prerecorded messages}}} and has notified you that providing this consent is not a condition of purchasing any BANK product or service. {{{Message and data rates may apply.}}}",
      sample: "Text",
      sample_url1:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url2:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      sample_url3:
        "https://www.finra.org/rules-guidance/rulebooks/finra-rules/1010",
      safe_harb: "-",
      discl_notes: "FINRA 2242",
    },
  ]

  const columns = useMemo(
    () => [
      {
        Header: "Disclosure ID",
        accessor: "discl_id",
        filterable: true,
        disableFilters: false,
        // Cell: cellProps => {
        //   return <Regulator {...cellProps} />
        // },
      },
      {
        Header: "Text",
        accessor: "text",
        disableFilters: false,
        // filterable: false,
        Cell: cellProps => {
          return (
            <Paper
              elevation={3}
              style={{ padding: 5, cursor: "pointer", borderRadius: "25px" }}
              onClick={() => {
                setModal(!modal)
                setDesc(cellProps.value)
              }}
            >
              <div   className="StringOverFlow1"> {cellProps.value ? cellProps?.value  : ""}</div>
             
            </Paper>
          )
        },
      },
      {
        Header: "Sample",
        accessor: "sample",
        // disableFilters: true,
        filterable: true,
      },
      {
        Header: "Sample URL1",
        disableFilters: false,
        accessor: "sample_url1",
        Cell: cellProps => {
          return (
            <div style={{ textAlign: "center" }}>
              <Button
                type="button"
                color="primary"
                className="btn btn-sm btn-rounded"
                //   onClick={toggleViewModal}
              >
                Go to URL
              </Button>
            </div>
          )
        },
      },
      {
        Header: "Sample URL2",
        disableFilters: false,
        accessor: "sample_url2",
        Cell: cellProps => {
          return (
            <div style={{ textAlign: "center" }}>
              <Button
                type="button"
                color="primary"
                className="btn btn-sm btn-rounded"
                //   onClick={toggleViewModal}
              >
                Go to URL
              </Button>
            </div>
          )
        },
      },
      {
        Header: "Sample URL3",
        disableFilters: false,
        accessor: "sample_url3",
        Cell: cellProps => {
          return (
            <div style={{ textAlign: "center" }}>
              <Button
                type="button"
                color="primary"
                className="btn btn-sm btn-rounded"
                //   onClick={toggleViewModal}
              >
                Go to URL
              </Button>
            </div>
          )
        },
      },
      {
        Header: "Safe Harbor (All, Part, None)",
        accessor: "safe_harb",
        disableFilters: false,
      },
      {
        Header: "Disclosure Notes",
        accessor: "discl_notes",
        disableFilters: false,
        // filterable: false,
      },
      {
        Header: "Action",
        textAlign: "top",
        disableFilters: true,
        // sticky: "right",
        filterable: false,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <ul
              className="list-unstyled hstack gap-2"
              style={{ marginBottom: "1.2rem" }}
            >
              <Badge className="font-size-15 badge-soft-success" pill>
                <Link
                  to={{
                    pathname: "/disclosure-text/disclosure-text_update",
                    state: { rowData },
                  }}
                >
                  <i
                    //   onClick={() => handleView(value)}
                    style={{ color: "#34C38F", cursor: "pointer" }}
                    className="bx bx-edit-alt  font-size-18"
                    id="customerViewtooltip"
                  ></i>
                </Link>
              </Badge>
              <Badge className="font-size-15 badge-soft-success" pill>
                <Link
                  to={{
                    pathname: "/disclosure-text/disclosure_text_view",
                    state: { rowData },
                  }}
                  onClick={() => {
                    const orderData = cellProps.row.original
                    // console.log(orderData, 'data')
                    // handleOrderClick(orderData);
                  }}
                >
                  <i
                    //   onClick={() => handleView(value)}
                    style={{ color: "blue", cursor: "pointer" }}
                    className="mdi mdi-eye-outline  font-size-18"
                    id="edittooltip"
                  ></i>
                </Link>
              </Badge>
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              >
                <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                >
                  <i
                    // onClick={() => toggleViewModal(value)}
                    className="bx bx-trash font-size-18"
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </li>
              </Badge>
              <UncontrolledTooltip placement="top" target="customerViewtooltip">
                View
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="top" target="removetooltip">
                Remove
              </UncontrolledTooltip>
            </ul>
          )
        },
      },
    ],
    []
  )

  const reports = [
    {
      title: "Total Disclosure Texts",
      iconClass: "bx-copy-alt",
      description: "10",
    },
    {
      title: "New Disclosure Texts",
      iconClass: "bx-archive-in",
      description: "10",
    },
    {
      title: "Recent Disclosure Text Changed",
      iconClass: "bx-archive-in",
      description: "35",
    },
    {
      title: "Disclosure Text Effecting Custmers",
      iconClass: "bx-purchase-tag-alt",
      description: "16",
    },
  ]

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Disclosure Texts"
            breadcrumbItem="All Disclosure Texts"
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Reguation Description</ModalHeader>
            <ModalBody className="text-center">{desc && desc}</ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>
          <Row>
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{report.title}</p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-sm-0 font-size-18">
                        Disclosure Text Details
                      </h5>
                    </div>
                  </div>
                  <TableContainer
                    columns={columns}
                    data={reg_data}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

DisclosureText.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(DisclosureText)
