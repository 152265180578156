import React, { useEffect, useState, useMemo } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import axios from "axios"
import { isEmpty } from "lodash"
import * as moment from "moment"
import { Button, Card, CardBody, Badge } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../../../Ecommerce/EcommerceOrders/EcommerceOrdersModal"

import TableContainer from "../../../../components/Common/TableContainer"
import ScanTableContainer from "../../../../components/Common/ScanTableContainer"
import { Link } from "react-router-dom"
import { RedirectOnUnAuthentication, ToastError } from "helpers/helper_function"
import { HEADER } from "helpers/url_helper"

const ScanCountTable = props => {
  const history = useHistory()
  const [regulationData, setRegulationData] = useState([])
  const [modal1, setModal1] = useState(false)
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const toggleViewModal = () => setModal1(!modal1)

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}new-report/?limit=${10}&pageNo=${1}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          console.log(response.data.data, "response")
          setRegulationData(response.data.data)
        } else {
          toastr.error("Error fetching data")
          
        }
      })
      .catch(err => {
        let message = "An error occurred while fetching data."
        RedirectOnUnAuthentication(err,navigateTo)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error (Status: 404)"
              break
            case 500:
              message =
                "Sorry! Something went wrong, please contact our support team (Status: 500)"
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = "An error occurred."
              break
          }
        }
         ToastError(err)
      })
  }, [])

  // console.log(regulationData, 'regulationdata')

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
        // width: 400,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          const date1 = moment.utc(new Date(cellProps.value)).format(
            "MMM DD Y hh:mm a"
          )
          if (cellProps.value) {
            return (
              <div className="text-align-center" style={{ fontSize: "12px" }}>
                {date1}
              </div>
            )
          } else {
            return <div style={{ paddingLeft: "100px" }}>-</div>
          }
        },
      },
      {
        Header: "Status",
        accessor: "status",
        // width: 115,
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          // setScanBtn(cellProps.value)
          return (
            <Badge
              className={
                cellProps.value === 1
                  ? "font-size-12 badge-soft-success"
                  : cellProps.value == 0
                  ? "font-size-11 badge-soft-danger"
                  : "font-size-11 badge-soft-warning"
              }
            >
              {cellProps.value == 0
                ? "Processing..."
                : cellProps.value == 1
                ? "Processed"
                : ""}
            </Badge>
          )
        },
      },
      {
        Header: "Action",
        textAlign: "top",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          // setScanBtn(rowData.status === 0 ? true : false)
          return (
            <Link
              to={{
                pathname: "/updated-obli-report",
                state: { rowData },
              }}
              style={{ pointerEvents: rowData.status === 0 ? "none" : "" }}
              // disabled={rowData.status === 0 ? true : false}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ padding: "5px 15px" }}
                disabled={rowData.status === 0 ? true : false}
                // onClick={() => handleReport(rowData)}
              >
                {/* <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "} */}
                View
              </button>
            </Link>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Last Scan</div>
          <ScanTableContainer
            columns={columns}
            data={regulationData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={10}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(ScanCountTable)
