import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import toastr from "toastr"
import Switch from "react-switch"
import moment from "moment"
import Select from "react-select"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ReactQuill from 'react-quill';
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import {
  addNewDisclosure as onAddNewDisclosure,
  updateDisclosure as onUpdateDisclosure,
} from "store/actions"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, ToastError, encryptData } from "helpers/helper_function"
import { QuilRichEditorModules } from "helpers/general_constant"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const DisclosureUpdate = props => {
  const [dropdownState, setDropdownState] = useState(false)
  const [ckData, setCkData] = useState("")
  const [textData, setTextData] = useState("")
  const [proGrpInv, setProGrpInv] = useState(true)
  const [proGrpDip, setproGrpDip] = useState(true)
  const [proGrpAuto, setproGrpAuto] = useState(true)
  const [proGrpMort, setproGrpMort] = useState(true)
  const [proGrpCard, setproGrpCard] = useState(true)
  const [proGrpOther, setproGrpOther] = useState(true)
  const [lifeMarket, setlifeMarket] = useState(true)
  const [lifeServ, setlifeServ] = useState(true)
  const [lifeClosEnd, setlifeClosEnd] = useState(true)
  const [lifeOpenBrd, setlifeOpenBrd] = useState(true)
  const [disclaimerState, setDisclaimerState] = useState(true)
  const [selectedType, setSelectedType] = useState()
  const [selectedStatus, setSelectedStatus] = useState()
  const [selectedSafe, setSelectedSafe] = useState()
  const [selectObligation, setSelectObligation] = useState([])
  const [existingObs, setExistingObs] = useState()
  const [dateSelect, setDateSelect] = useState()
  const [addModal, setAddModal] = useState(false)
  const [langOptions, setLangOptions] = useState([])
  const [fetchedLangData, setFetchedLangData] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const location = useLocation()
  const dispatch = useDispatch()

  const {loading} = useSelector((state)=> state.Disclosures)
  
  const [data, setData] = useState()
  // useEffect(() => {
  //   const authToken = JSON.parse(localStorage.getItem('authUser'))
  //   axios.get(`${process.env.REACT_APP_BASE_URL}/get-obligations?limit=${500}&pageNo=${1}`,{
  //     headers: {
  //       'Authorization':`Bearer ${authToken.data.authToken}`
  //     }
  //   })
  //   .then(response => {
  //     console.log('res',response.data)
  //     if (response.status >= 200 || response.status <= 299) {
  //     // return response.data;
  //     setSelectObligation(response.data.data.map(option => ({
  //       value: option.id,
  //       label: option.obligationName
  //     })))
  //     setReqresData(response.data.data)
  //     setTotalItems(response.data.totalRecords)
  //     setLoader(false)
  //   }
  //     // throw response.data;
  //   })
  //   .catch(err => {
  //     var message;
  //     if (err.response && err.response.status) {
  //       switch (err.response.status) {
  //         case 404:
  //           message = "Sorry! Network Error(Status:404)";
  //           break;
  //         case 500:
  //           message =
  //             "Sorry! something went wrong, please contact our support team, Status-500   ";
  //           break;
  //         case 401:
  //           message = "You are not authorized to view this data.";
  //           break;
  //         default:
  //           message = err[1];
  //           break;
  //       }
  //     }
  //     throw message;
  //   });
  // },[])

  // console.log(selectObligation, 'selectobligation')

  useEffect(() => {
    if (location.state) {
      setData(location.state.rowData)
      setExistingObs(
        location.state.rowData.obligations &&
          location.state.rowData.obligations.map(item => item.id)
      )
      const typeSelct = typeOptions.find(
        name => name.value === location.state.rowData.disclosureType
      )
      if (typeSelct !== undefined) {
        setSelectedType(typeSelct)
      }
      const statusSelct = statusOptions.find(
        name => name.value === location.state.rowData.status
      )
      if (statusSelct !== undefined) {
        setSelectedStatus(statusSelct)
      }
      const safeSelct = safeOptions.find(
        name => name.value === location.state.rowData.safeHarbor
      )
      if (safeSelct !== undefined) {
        setSelectedSafe(safeSelct)
      }
      // const d = new Date(location.state.rowData.effectiveUntilDate)
      // const check = moment(d).format('YYYY-MM-DD')
      // setDateSelect(check)

      if (location.state.rowData.effectiveUntilDate) {
        const d = new Date(location.state.rowData.effectiveUntilDate)
        const check = moment(d).format("YYYY-MM-DD")
        setDateSelect(check)
      } else {
        setDateSelect("")
      }

      console.log(location.state.rowData)
      setProGrpInv(location.state.rowData.prodGroupInvestments)
      setproGrpDip(location.state.rowData.prodGroupDeposits)
      setproGrpAuto(location.state.rowData.prodGroupAuto)
      setproGrpAuto(location.state.rowData.prodGroupAuto)
      setproGrpMort(location.state.rowData.prodGroupMortgage)
      setproGrpCard(location.state.rowData.prodGroupCard)
      setproGrpOther(location.state.rowData.prodGroupOther)
      setlifeMarket(location.state.rowData.lifeMarketing)
      setlifeServ(location.state.rowData.lifeServicing)
      setlifeServ(location.state.rowData.lifeServicing)
      setlifeClosEnd(location.state.rowData.lifeCloseOutEnd)
      setlifeOpenBrd(location.state.rowData.lifeOpenOnboard)
      setDisclaimerState(location.state.rowData.disclaimer)
      // setSelectedFile(location.state.rowData.attachment)
    }
  }, [])

  // console.log(data, 'data')
  // console.log(selectObligation, 'selectObligation')

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const typeOptions = [
    { value: 1, label: "Text" },
    { value: 2, label: "Doc" },
    { value: 3, label: "Other" },
  ]

  const statusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Approved" },
    { value: 3, label: "Removed" },
  ]

  const safeOptions = [
    { value: 1, label: "ALL" },
    { value: 2, label: "Part" },
    { value: 3, label: "None" },
  ]

  const obgOptions = [
    { value: 1, label: "ALL" },
    { value: 2, label: "Part" },
    { value: 3, label: "None" },
  ]

  const handleTypeChange = option => {
    validation.setFieldValue("disclosureType", option)
  }

  const handleStatusChange = option => {
    validation.setFieldValue("status", option)
  }

  const handleSafeChange = option => {
    validation.setFieldValue("safeHarbor", option)
  }

  const handleObsChange = option => {
    console.log(option.value, "opionss")
    validation.setFieldValue(
      "ObligationIds",
      option ? option.map(x => x.value) : []
    )
  }

  const addToggle = () => setAddModal(!addModal)
  const closeAddModal = () => {
    setAddModal(false)
    validation.resetForm()
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
    menu: base => ({
      ...base,
      zIndex: 9999,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px" // your desired height
    })
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const addData = {
      tableName: "language",
      fieldName: ["language.id", "language.name"],
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}get-all`,
        {data:encryptData(addData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        // console.log('res',response.data)
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;

          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: option.name,
          }))
          // const modifiedOptions = [
          //   { label: 'Select Regulator', value: 0 }, // 0 option
          //   ...optionData // API options
          // ];
          setLangOptions(optionData)
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }, [])

  const langValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      disclosureText: "",
      languageId: null,
    },
    validationSchema: Yup.object({
      disclosureText: Yup.string().required("Please Enter Title"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      // if(!data) {
      const addData = {
        disclosureId: data && data.id,
        disclosureText: values.disclosureText,
        languageId: values.languageId,
      }
      console.log(addData, "addData")
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}add-language-disclosure-text`,
          {data:encryptData(addData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success("Language Successfully Updated.")
            setAddModal(false)
            langValidation.resetForm()
            //  return response.data;
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          let message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = "Bad request! Already Linked."
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          ToastError(err)
          // return message
        })
      // }
    },
  })

  // const onFileChange = ({ target }) => {
  //   if (!selectedFile) {
  //     // setErrors({});
  //     const value = target && target.files[0];
  //     setSelectedFile(value);
  //   }
  // };

  const onFileChange = event => {
    // Get the selected file from the input element
    const file = event.target.files[0]

    // You can perform any validation or checks on the selected file here if needed.
    // For example, check file size, file type, etc.

    // Update the state with the selected file
    setSelectedFile(file)
  }

  useEffect(() => {
    const fetchLangText = language_Id => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      if (language_Id) {
        const data = {
          languageId: language_Id,
          disclosureId: data && data.id,
        }
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}get-language-wise-disclosure-text`,
            {data:encryptData(data)},
            {
              headers: {
                Authorization: `Bearer ${authToken.data.authToken}`,
              },
            }
          )
          .then(response => {
            // console.log('res',response.data)
            if (response.status >= 200 || response.status <= 299) {
              console.log(response, "response")
              setFetchedLangData(response.data.data.DisclosureTextLanguage_text)
            }
          })
          .catch(err => {
            RedirectOnUnAuthentication(err,navigateTo)
            var message
            if (err.response && err.response.status) {
              switch (err.response.status) {
                case 404:
                  message = "Sorry! Network Error(Status:404)"
                  break
                case 500:
                  message =
                    "Sorry! something went wrong, please contact our support team, Status-500   "
                  break
                case 401:
                  message = "You are not authorized to view this data."
                  break
                default:
                  message = err[1]
                  break
              }
            }
            throw message
          })
      }
    }

    fetchLangText(langValidation.values.languageId)
  }, [langValidation.values.languageId])

  console.log(fetchedLangData, "fetchedLangData")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      disclosureTitle: (data && data.disclosureTitle) || "",
      disclosureDesc: (data && data.disclosureDesc) || "",
      disclosureSpecificUrl: (data && data.disclosureSpecificUrl) || "",
      disclosureSpecificCitation:
        (data && data.disclosureSpecificCitation) || "",
      communicationTypes: (data && data.communicationTypes) || "",
      text: (data && data.text) || "",
      effectiveUntilDate: dateSelect || "",
      sample: (data && data.sample) || "",
      sampleUrl1: (data && data.sampleUrl1) || "",
      sampleUrl2: (data && data.sampleUrl2) || "",
      sampleUrl3: (data && data.sampleUrl3) || "",
      safeHarbor: selectedSafe || "",
      disclosureNote: (data && data.disclosureNote) || "",
      status: selectedStatus || "",
      disclosureType: selectedType || "",
      ObligationIds: existingObs || [],
      // file: selectedFile || ""
      // ObligationIds: data && data.obligations.map(item => item.id) || ""
      //   idx : idx || '',
    },
    validationSchema:  data
    ?Yup.object({  })
    :Yup.object({
      disclosureTitle: Yup.string()
        .matches(
          /^[a-zA-Z0-9_ ( ) - . -]+$/,
          "Special characters are not allowed"
        )
        .required("Please Enter Title"),
      disclosureNote: Yup.string()
        .matches(
          /^[a-zA-Z0-9_ ( ) - . -]+$/,
          "Special characters are not allowed"
        )
        .required("Please Enter Disclosure Note"),
      disclosureDesc: Yup.string().required(
        "Please Enter Disclosure Description"
      ),
      text: Yup.string().required("Please Enter Disclosure Text"),
      sample: Yup.string()
        .matches(
          /^[a-zA-Z0-9_ ( ) - . -]+$/,
          "Special characters are not allowed"
        )
        .required("Please Enter sample"),
      sampleUrl1: Yup.string().required("Please Enter sample Url1"),
      sampleUrl2: Yup.string().required("Please Enter sample Url2"),
      sampleUrl3: Yup.string().required("Please Enter sample Url3"),
      disclosureType: Yup.object().required("Please Select Disclosure Type"),
      status: Yup.object().required("Please Select Status"),
      disclosureSpecificUrl: Yup.string().required(
        "Please Enter Disclosure SpecificUrl"
      ),
      disclosureSpecificCitation: Yup.string()
        .matches(
          /^[a-zA-Z0-9_ ( ) - . -]+$/,
          "Special characters are not allowed"
        )
        .required("Please Enter Disclosure SpecificCitation"),
      safeHarbor: Yup.object().required("Please Select safeHarbor"),
      communicationTypes: Yup.string()
        .matches(
          /^[a-zA-Z0-9_ ( ) - . -]+$/,
          "Special characters are not allowed"
        )
        .required("Please Enter communication Types"),
        file: Yup.mixed()
          .test('fileSize', 'File size is too large', (value) => {
            if (!value) return true; // Handle empty file
            return value.size <= 25 * 1024 * 1024; // 25MB in bytes
          })
      //   confirm: Yup.string().required("Please Re-Enter New Password"),
    }),
    onSubmit: values => {
     console.log(values, "values")
      // const obsString = values.ObligationIds.map(item => item.value)
      const checkType = values.ObligationIds.toString()
      console.log(typeof checkType, "checktyp")
      const d = values.effectiveUntilDate
        ? new Date(values.effectiveUntilDate)
        : ""
      //  console.log(d, 'dataa')
      const check = d ? moment(d).format() : ""
      const formData = new FormData()
      formData.append("disclosureTitle", values.disclosureTitle)
      formData.append("disclosureDesc", values.disclosureDesc)
      formData.append("disclosureSpecificUrl", values.disclosureSpecificUrl)
      formData.append(
        "disclosureSpecificCitation",
        values.disclosureSpecificCitation
      )
      formData.append("communicationTypes", values.communicationTypes)
      formData.append("text", values.text)
      formData.append("effectiveUntilDate", values.effectiveUntilDate)
      formData.append("sample", values.sample)
      formData.append("sampleUrl1", values.sampleUrl1)
      formData.append("sampleUrl2", values.sampleUrl2)
      formData.append("sampleUrl3", values.sampleUrl3)
      formData.append("safeHarbor", values.safeHarbor.value)
      formData.append("disclosureNote", values.disclosureNote)
      formData.append("disclosureType", values.disclosureType.value)
      formData.append("status", values.status.value)
      formData.append("ObligationIds", values.ObligationIds.toString())
      formData.append("disclaimer", disclaimerState)
      formData.append("prodGroupInvestments", proGrpInv)
      formData.append("prodGroupDeposits", proGrpDip)
      formData.append("prodGroupAuto", proGrpAuto)
      formData.append("prodGroupMortgage", proGrpMort)
      formData.append("prodGroupCard", proGrpCard)
      formData.append("prodGroupOther", proGrpOther)
      formData.append("lifeMarketing", lifeMarket)
      formData.append("lifeOpenOnboard", lifeOpenBrd)
      formData.append("lifeServicing", lifeServ)
      formData.append("lifeCloseOutEnd", lifeClosEnd)
      formData.append("file", selectedFile)
      if (!data) {
        // const addData = {
        //   disclosureTitle : values.disclosureTitle,
        //   disclosureDesc: values.disclosureDesc,
        //   disclosureSpecificUrl: values.disclosureSpecificUrl,
        //   disclosureSpecificCitation: values.disclosureSpecificCitation,
        //   communicationTypes: values.communicationTypes,
        //   text: values.text,
        //   effectiveUntilDate: check ? check : "",
        //   sample : values.sample,
        //   sampleUrl1: values.sampleUrl1,
        //   sampleUrl2: values.sampleUrl2,
        //   sampleUrl3: values.sampleUrl3,
        //   safeHarbor: values.safeHarbor.value,
        //   disclosureNote: values.disclosureNote,
        //   disclaimer: disclaimerState,
        //   prodGroupInvestments: proGrpInv,
        //   prodGroupDeposits: proGrpDip,
        //   prodGroupAuto: proGrpAuto,
        //   prodGroupMortgage: proGrpMort,
        //   prodGroupCard: proGrpCard,
        //   prodGroupOther: proGrpOther,
        //   lifeMarketing: lifeMarket,
        //   lifeOpenOnboard: lifeOpenBrd,
        //   lifeServicing: lifeServ,
        //   lifeCloseOutEnd: lifeClosEnd,
        //   file:selectedFile,
        //   disclosureType: values.disclosureType.value,
        //   status: values.status.value,
        //   ObligationIds: values.ObligationIds.toString()
        // }
        console.log(formData, "formData")
        dispatch(onAddNewDisclosure(formData, props.history))
      } else {
        // const updateData = {
        //   id: data && data.id,
        //   disclosureTitle : values.disclosureTitle,
        //   disclosureDesc: values.disclosureDesc,
        //   disclosureSpecificUrl: values.disclosureSpecificUrl,
        //   disclosureSpecificCitation: values.disclosureSpecificCitation,
        //   communicationTypes: values.communicationTypes,
        //   text: values.text,
        //   effectiveUntilDate: check ? check : "",
        //   sample : values.sample,
        //   sampleUrl1: values.sampleUrl1,
        //   sampleUrl2: values.sampleUrl2,
        //   sampleUrl3: values.sampleUrl3,
        //   safeHarbor: values.safeHarbor.value,
        //   disclosureNote: values.disclosureNote,
        //   disclaimer: disclaimerState,
        //   prodGroupInvestments: proGrpInv,
        //   prodGroupDeposits: proGrpDip,
        //   prodGroupAuto: proGrpAuto,
        //   prodGroupMortgage: proGrpMort,
        //   prodGroupCard: proGrpCard,
        //   prodGroupOther: proGrpOther,
        //   lifeMarketing: lifeMarket,
        //   lifeOpenOnboard: lifeOpenBrd,
        //   lifeServicing: lifeServ,
        //   lifeCloseOutEnd: lifeClosEnd,
        //   disclosureType: values.disclosureType.value,
        //   status: values.status.value,
        //   ObligationIds: values.ObligationIds.toString()
        // }
        formData.append("id", data.id)
        console.log(formData, "formData update")
        dispatch(onUpdateDisclosure(formData, props.history))
      }
    },
  })


  const addTitle = "Add Disclosure"
  const updateTitle = "Update Disclosure"
  //meta title
  document.title = "Disclosures"

  // console.log(data, 'dta')
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Disclosures"
            // breadcrumbItem={updateTitle}
            path={{pathname:"/disclosures/all-disclosures", state: { rowData: {isClear: true} }}}
            breadcrumbItem={data ? updateTitle : addTitle}
          />
          {/* write Html code or structure */}
          {/* <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? (
                <Alert color="success">Password Changed Successfully.</Alert>
              ) : null}
            </Col>
          </Row> */}
          <Modal
            isOpen={addModal}
            // toggle={toggle}
            toggle={addToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={closeAddModal}>Add Variation</ModalHeader>
            <ModalBody className="text-left">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  langValidation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Language</Label>
                        <Select
                          options={langOptions}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="dis_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={langOptions.find(
                            option =>
                              option.value === langValidation.values.languageId
                          )}
                          onChange={option => {
                            langValidation.setFieldValue(
                              "languageId",
                              option.value
                            )
                            // fetchLangText(option.value)
                          }}
                          onBlur={langValidation.handleBlur}
                        />
                        {langValidation.touched.languageId &&
                        langValidation.errors.languageId ? (
                          <div className="error">
                            {langValidation.errors.languageId}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label"> Disclosure Text</Label>
                        <CKEditor
                          editor={ClassicEditor}
                          // data={validation.values.text || ''}
                          data={(fetchedLangData && fetchedLangData) || ""}
                          config={{
                            removePlugins: [
                              "ImageCaption",
                              "ImageStyle",
                              "ImageToolbar",
                              "ImageUpload",
                              "MediaEmbed",
                              "EasyImage",
                              "UploadImage",
                            ],
                          }}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "200px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            langValidation.setFieldValue("disclosureText", data)
                          }}
                        />
                        {langValidation.touched.text &&
                        langValidation.errors.text ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {langValidation.errors.text}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button type="submit" className="me-2" color="primary">
                      Add
                    </Button>
                    <Button
                      onClick={closeAddModal}
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form>
            </ModalBody>
          </Modal>
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">Disclosure Title</Label>
                        <Input
                          name="disclosureTitle"
                          // value={data && data.name}
                          className="form-control"
                          placeholder="Enter Disclosure Title"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.disclosureTitle || ""}
                          invalid={
                            validation.touched.disclosureTitle &&
                            validation.errors.disclosureTitle
                              ? true
                              : false
                          }
                        />
                        {validation.touched.disclosureTitle &&
                        validation.errors.disclosureTitle ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.disclosureTitle}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">Disclosure Note</Label>
                        <Input
                          name="disclosureNote"
                          // value={data && data.cfr_Ref}
                          className="form-control"
                          placeholder="Enter Disclosure Note"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.disclosureNote || ""}
                          invalid={
                            validation.touched.disclosureNote &&
                            validation.errors.disclosureNote
                              ? true
                              : false
                          }
                        />
                        {validation.touched.disclosureNote &&
                        validation.errors.disclosureNote ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.disclosureNote}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Disclosure Description
                        </Label>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.disclosureDesc || ""}
                          config={{
                            removePlugins: [
                              "ImageCaption",
                              "ImageStyle",
                              "ImageToolbar",
                              "ImageUpload",
                              "MediaEmbed",
                              "EasyImage",
                              "UploadImage",
                            ],
                          }}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "300px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("disclosureDesc", data)
                          }}
                        /> */}
                               <ReactQuill 
                         modules={QuilRichEditorModules}
                        
                        theme="snow" 
                        value={validation.values.disclosureDesc || ""} 
                        onChange={(data)=>{
                          console.log("data5555",data)
                          validation.setFieldValue("disclosureDesc", data)}} />
                        {validation.touched.disclosureDesc &&
                        validation.errors.disclosureDesc ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.disclosureDesc}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Disclosure Text
                          {/* {data && (
                            <Button
                              variant="contained" 
                              onClick={() => setAddModal(true)}
                              style={{
                                backgroundColor: "#556ee6",
                                marginLeft: 5,
                                color: "#fff",
                                textTransform: "none", 
                              }}
                            >
                               Add Variation
                            </Button>
                          )} */}
                        </Label>
                        {/* <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.text || ""}
                          config={{
                            removePlugins: [
                              "ImageCaption",
                              "ImageStyle",
                              "ImageToolbar",
                              "ImageUpload",
                              "MediaEmbed",
                              "EasyImage",
                              "UploadImage",
                            ],
                          }}
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                "min-height",
                                "300px",
                                editor.editing.view.document.getRoot()
                              )
                            })
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("text", data)
                          }}
                        /> */}
                               <ReactQuill 
                         modules={QuilRichEditorModules}
                        
                        theme="snow" 
                        value={validation.values.text || ""} 
                        onChange={(data)=>{validation.setFieldValue("text", data)}} />
                        {validation.touched.text && validation.errors.text ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.text}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      {/* <label
                      htmlFor="example-datetime-local-input"
                      className="col-md-6 col-form-label"
                    > */}

                      {/* </label> */}
                      <div className="mb-3">
                        <Label className="form-label">
                          Effective Until Date
                        </Label>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Select Effective Until Date"
                          name="effectiveUntilDate"
                          // defaultDate = "2016-10-20"
                          onChange={date => {
                            // this.setState({ start_date: formatDate(date[0]) })
                            validation.setFieldValue("effectiveUntilDate", date)
                          }}
                          value={validation.values.effectiveUntilDate}
                          options={{
                            dateFormat: "Y-m-d",
                            defaultDate: "today",
                          }}
                        />
                        {validation.touched.effectiveUntilDate &&
                        validation.errors.effectiveUntilDate ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.effectiveUntilDate}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="3">
                      <div className="mb-3">
                        <Label className="form-label">Sample</Label>
                        <Input
                          name="sample"
                          // value={data && data.cfr_Ref}
                          className="form-control"
                          placeholder="Enter Sample"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sample || ""}
                          invalid={
                            validation.touched.sample &&
                            validation.errors.sample
                              ? true
                              : false
                          }
                        />
                        {validation.touched.sample &&
                        validation.errors.sample ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.sample}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="3">
                      <div className="mb-3">
                        <Label className="form-label">Sample URL1</Label>
                        <Input
                          name="sampleUrl1"
                          // value={data && data.reg_url}
                          className="form-control"
                          placeholder="Enter Sample URL1"
                          type="url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sampleUrl1 || ""}
                          invalid={
                            validation.touched.sampleUrl1 &&
                            validation.errors.sampleUrl1
                              ? true
                              : false
                          }
                        />
                        {validation.touched.sampleUrl1 &&
                        validation.errors.sampleUrl1 ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.sampleUrl1}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="3">
                      <div className="mb-3">
                        <Label className="form-label">Sample URL2</Label>
                        <Input
                          name="sampleUrl2"
                          // value={data && data.pack_size}
                          className="form-control"
                          placeholder="Enter Sample URL2"
                          type="url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sampleUrl2 || ""}
                          invalid={
                            validation.touched.sampleUrl2 &&
                            validation.errors.sampleUrl2
                              ? true
                              : false
                          }
                        />
                        {validation.touched.sampleUrl2 &&
                        validation.errors.sampleUrl2 ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.sampleUrl2}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="3">
                      <div className="mb-3">
                        <Label className="form-label">Sample URL3</Label>
                        <Input
                          name="sampleUrl3"
                          // value={data && data.reg_related_low}
                          className="form-control"
                          placeholder="Enter Sample URL3"
                          type="url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.sampleUrl3 || ""}
                          invalid={
                            validation.touched.sampleUrl3 &&
                            validation.errors.sampleUrl3
                              ? true
                              : false
                          }
                        />
                        {validation.touched.sampleUrl3 &&
                        validation.errors.sampleUrl3 ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.sampleUrl3}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">Disclosure Type</Label>
                        <Select
                          options={typeOptions}
                          styles={customStyles}
                          name="disclosureType"
                          value={validation.values.disclosureType}
                          onChange={handleTypeChange}
                          onBlur={validation.handleBlur}
                        />
                        {/* jurisdiction */}
                        {validation.touched.disclosureType &&
                        validation.errors.disclosureType ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.disclosureType}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    {/* <Col xs="12" md="4">
                      <div  className="mb-3">
                        <Label className="form-label">Disclosure Linked Reg</Label>
                        <Input
                          name="current"
                          value={data && data.pack_size}
                          className="form-control"
                          placeholder="Enter Disclosure Linked Reg"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // value={validation.values.username || ""}
                          invalid={
                            validation.touched.current &&
                            validation.errors.current
                              ? true
                              : false
                          }
                        />
                        {validation.touched.current &&
                        validation.errors.current ? (
                          <FormFeedback type="invalid" style={{fontSize: '12px'}}>
                            {validation.errors.current}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col> */}
                    <Col xs="12" md="4">
                      <div className="mb-3">
                        <Label className="form-label">
                          Disclosure-specific URL
                        </Label>
                        <Input
                          name="disclosureSpecificUrl"
                          // value={data && data.reg_related_low}
                          className="form-control"
                          placeholder="Enter Disclosure-specific URL"
                          type="url"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.disclosureSpecificUrl || ""}
                          invalid={
                            validation.touched.disclosureSpecificUrl &&
                            validation.errors.disclosureSpecificUrl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.disclosureSpecificUrl &&
                        validation.errors.disclosureSpecificUrl ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.disclosureSpecificUrl}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="4">
                      {/* <div  className="mb-3"> */}
                      <Label className="form-label">
                        Disclosure-specific Citation
                      </Label>
                      <Input
                        name="disclosureSpecificCitation"
                        // value={data && data.pack_size}
                        className="form-control"
                        placeholder="Enter Disclosure-specific Citation"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.disclosureSpecificCitation || ""
                        }
                        invalid={
                          validation.touched.disclosureSpecificCitation &&
                          validation.errors.disclosureSpecificCitation
                            ? true
                            : false
                        }
                      />
                      {validation.touched.disclosureSpecificCitation &&
                      validation.errors.disclosureSpecificCitation ? (
                        <FormFeedback
                          type="invalid"
                          style={{ fontSize: "12px" }}
                        >
                          {validation.errors.disclosureSpecificCitation}
                        </FormFeedback>
                      ) : null}
                      {/* </div> */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label htmlFor="reason">Status</Label>
                        <Select
                          styles={customStyles}
                          options={statusOptions}
                          name="status"
                          value={validation.values.status}
                          onChange={handleStatusChange}
                          onBlur={validation.handleBlur}
                        />
                        {/* jurisdiction */}
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <div className="error" style={{ fontSize: "12px" }}>
                            {validation.errors.status}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <Label htmlFor="reason">Safe Harbor</Label>
                      <Select
                        styles={customStyles}
                        options={safeOptions}
                        name="safeHarbor"
                        value={validation.values.safeHarbor}
                        onChange={handleSafeChange}
                        onBlur={validation.handleBlur}
                      />
                      {/* jurisdiction */}
                      {validation.touched.safeHarbor &&
                      validation.errors.safeHarbor ? (
                        <div className="error" style={{ fontSize: "12px" }}>
                          {validation.errors.safeHarbor}
                        </div>
                      ) : null}
                      {/* <select name="reason" className={"form-control"}>
                        <option selected={true}>
                          Select Safe Harbor
                        </option>
                        <option value={1}>ALL</option>
                        <option value={2}>Part</option>
                        <option value={3}>None</option>
                      </select> */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="4" md="2">
                      <Label check>Pro Group Inv</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setProGrpInv(!proGrpInv)
                          }}
                          checked={proGrpInv}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Pro Group Dep</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setproGrpDip(!proGrpDip)
                          }}
                          checked={proGrpDip}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Pro Group Auto</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setproGrpAuto(!proGrpAuto)
                          }}
                          checked={proGrpAuto}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Pro Group Mort</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setproGrpMort(!proGrpMort)
                          }}
                          checked={proGrpMort}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Pro Group Card</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setproGrpCard(!proGrpCard)
                          }}
                          checked={proGrpCard}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Pro Group Other</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setproGrpOther(!proGrpOther)
                          }}
                          checked={proGrpOther}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs="4" md="2">
                      <Label check>Life - Marketing</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setlifeMarket(!lifeMarket)
                          }}
                          checked={lifeMarket}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Life - Open Onboard</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setlifeOpenBrd(!lifeOpenBrd)
                          }}
                          checked={lifeOpenBrd}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Life - Servicing</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setlifeServ(!lifeServ)
                          }}
                          checked={lifeServ}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                    <Col xs="4" md="2">
                      <Label check>Life - Close-out End</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setlifeClosEnd(!lifeClosEnd)
                          }}
                          checked={lifeClosEnd}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>

                    <Col xs="4" md="2">
                      <Label check>Disclaimer</Label>
                      {/* <FormGroup switch> */}
                      <div className="mt-2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#02a499"
                          onChange={() => {
                            setDisclaimerState(!disclaimerState)
                          }}
                          checked={disclaimerState}
                        />
                      </div>

                      {/* </FormGroup> */}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="12" md="6">
                      <div className="mt-2">
                        <Label className="form-label">
                          Communication Types
                        </Label>
                        <Input
                          name="communicationTypes"
                          // value={data && data.pack_size}
                          className="form-control"
                          placeholder="Enter Communication Types"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.communicationTypes || ""}
                          invalid={
                            validation.touched.communicationTypes &&
                            validation.errors.communicationTypes
                              ? true
                              : false
                          }
                        />
                        {validation.touched.communicationTypes &&
                        validation.errors.communicationTypes ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.communicationTypes}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div>
                        <Label htmlFor="formFile" className="form-label">
                          Attach File
                          {data && data.attachment ? (
                            <DarkTooltip title="Open Attachment">
                              <Link
                                to={{
                                  pathname: `${process.env.REACT_APP_ATTACHMENT_URL}/attachment/${data.attachment}`,
                                }}
                                target="_blank"
                              >
                                {/* <Button
                                    variant="contained"
                                    // onClick={toggle}
                                    // onClick={() => setAddModal(true)}
                                    style={{
                                      backgroundColor: "#556ee6",
                                      marginLeft: 5,
                                      color: "#fff",
                                      textTransform: "none",
                                      // padding: "0"
                                    }}
                                  > */}
                                {/* <i className="bx bx-plus font-size-16 align-middle me-1"></i> */}
                                <i
                                  className="bx bxs-file-pdf font-size-22"
                                  style={{ marginLeft: 5 }}
                                ></i>
                                {/* Add Variation */}
                                {/* </Button> */}

                                {/* </Button> */}
                              </Link>
                            </DarkTooltip>
                          ) : (
                            ""
                          )}
                        </Label>
                        <Input
                          name="file"
                          className="form-control"
                          accept=".pdf,.doc,.docx"
                          onChange={onFileChange}
                          // onBlur={validation.handleBlur}
                          // value={validation.values.file || ""}
                          invalid={
                            validation.touched.file && validation.errors.file
                              ? true
                              : false
                          }
                          type="file"
                          id="formFile"
                        />
                      </div>
                    </Col>
                    {/* <Col xs="12" md="6">
                      <Label htmlFor="reason">Obligations</Label>
                      <Select
                        // styles={customStyles}
                        isMulti
                        options={selectObligation}
                        name="ObligationIds"
                        // value={selectObligation.find(option =>  option.value === validation.values.ObligationIds)}
                        value={selectObligation.filter(option => validation.values.ObligationIds.includes(option.value))}
                        onChange={handleObsChange}
                        onBlur={validation.handleBlur}
                      />
                      {validation.touched.obligations && validation.errors.obligations ? (
                        <div className="error" style={{fontSize: '12px'}}>{validation.errors.obligations}</div>
                      ) : null}
                    </Col> */}
                  </Row>
                  {/* <Row className="mb-3">
                    <Col xs="12" md="6">
                    <div className="mb-3">
                          <Label className="control-label">Features</Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Choose..."
                            title="Country"
                            options={options}
                            isMulti
                          />
                        </div>
                    </Col>
                    <Col xs="12" md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          De-Link Current Obligation
                        </Label>
                        <div>
                        <Button
                          className="btn btn-secondary waves-effect waves-light "
                          color="info"
                        >
                          De-Link
                        </Button>
                        </div>
                        
                      </div>
                    </Col>
                  </Row> */}
                  <Row></Row>
                </div>
                <div className="text-end">
                  {loading ? (
                  <Spinner className="ms-2" style={{marginRight:'20px'}} color="primary" />
                  ) : (
                  <Button type="submit" className="me-2" color="primary">
                    Submit
                  </Button>
                  )}
                  <Link to="/disclosures/all-disclosures">
                    <Button
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </Link>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default DisclosureUpdate
