import React, { useEffect, useState } from "react"
import UpdateWorkFlowTable from "./components/UpdateWorkFlowTable"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import UpdateWorkFlowChart from "./components/UpdateWorkFlowChart"
import { Spinner } from "reactstrap"
import { useSelector } from "react-redux"

const UpdateWorkFlowList = () => {

  const { loading } = useSelector(
    state => state.workFlowReport || {}
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
              title="Work Flow Report"
              // breadcrumbItem={updateTitle}
              path="work-flow-report"
              breadcrumbItem={`List Reg Content Change Report`}
            />
        {loading ? (
        <div className="w-100 h-50" style={{ display: "flex", justifyContent: "center", marginBottom: "20px"}}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        )
        :
        (

          <UpdateWorkFlowChart />
        )
      }
        <UpdateWorkFlowTable />
      </div>
    </div>
  )
}

export default UpdateWorkFlowList