import { FETCH_ORG_OPTIONS, SET_ORG_OPTIONS } from "./actionTypes"

export const fetchOrgOptions = (userId, checkAdmin, menu) => ({
  type: FETCH_ORG_OPTIONS,
  payload: { userId, checkAdmin, menu },
})

export const setOrgOptions = options => ({
  type: SET_ORG_OPTIONS,
  payload: options,
})
