export const cellValueLimit = 32000
export const LOCAL_API_END_POINT = 'http://192.168.29.39:3003/v1/admin/'
export const JSONorgID =  localStorage.getItem("orgID")?JSON.parse(localStorage.getItem("orgID")):{orgId:""}
export const CustomerorgId =  ""
export const  CheckOrgId =  JSONorgID?.orgId||"" 
const JSONTokenData = localStorage.getItem("authUser")?JSON.parse(localStorage.getItem("authUser")):{data:{authToken:""}}
export const userRoleId =   JSONTokenData?.data?.role?.id??1000000
console.log("JSONTokenData",JSONTokenData)
// process.env.REACT_APP_BASE_URL


const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],
    // ['link', 'image', 'video', 'formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, ], //{ 'list': 'check' }
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction
  
    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] },],    // { 'background': [] }      // dropdown with defaults from theme 
    // [{ 'font': [] }],
    [{ 'align': [] }],
  
    // ['clean']                                         // remove formatting button
  ];
  export const QuilRichEditorModules = {
    toolbar:toolbarOptions
  };
 
