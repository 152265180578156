import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { map } from "lodash"
import { useDispatch } from "react-redux"
import toastr from "toastr"
import debounce from "lodash/debounce"
import axios from "axios"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link, useLocation } from "react-router-dom" 
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Form,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import styled from "styled-components"
import { Popper } from "@material-ui/core"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Pagination from "../../components/Common/Pagination"
import DeleteModal from "../../components/Common/DeleteModal"
import Select from "react-select"
import { format } from "date-fns"
import { MenuItem, FormControl } from "@material-ui/core"
import { Select as MaterialUISelect } from "@material-ui/core"

// import TableContainer from "../../../components/Common/TableContainer"
import TableHeader from "components/Common/TableHeader"
import FilterModal from "components/FilterModal/FilterModal"
import { init } from "i18next"
import { LOCAL_API_END_POINT, cellValueLimit } from "helpers/general_constant"
import {
  CheckValidValue,
  DisplayJustifyCenter,
  DownloadExelFrom,
  FormatedDate,
  POST_API_HANDLER,
  RedirectOnUnAuthentication,
  RemovePtag,
  RemoveSpaces,
  SaperateObjectBasedOnValueLength,
  ScrollDown,
  ToastError,
  ValidCellValue,
  convertObjectValuesToArray,
  encryptData,
  handleNullAndUndefinedOfArray,
  removeKeysFromObject,
  trimLeft, 
} from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"  
import EditorWithPagination from "components/Common/EditorWithPagination"


// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

const csvHeader = [
  "ID",
  "Obligation Name",
  "Obligation Description",
  "Citation URL",
  "Regulator Short Name",
  "Reg Law Short Name",
  "Citation",
  "Obligation Notes",
  "Status",
  "Created At",
  "Last Updated At",
  "Regulations",
  "Regulators",
]

const exportToExcel = rowData => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  console.log(rowData, "row2584")
  const ArrayWithSaperateObjects = rowData // SaperateObjectBasedOnValueLength(  rowData,  32000  )

  const customizedData = ArrayWithSaperateObjects.map(row => {
    const updatedDate =FormatedDate(row.updatedDate,true )    
    const createdDate =FormatedDate( row.createdDate,true)    

    const EffectiveDate =FormatedDate(row.regEffectiveDate,true )   
    const newRow = removeKeysFromObject(row,["disclosure_obligations_obligation","testCitationCount","citationCount"])
    return {
      ...newRow,
      obligationDesc: ValidCellValue({ value: row.obligationDesc }), //row.obligationDesc  ? row.obligationNote.replace(/<[^>]+>/g, "").slice(0,cellValueLimit) : "-",
      citationContent: ValidCellValue({ value: row.citationContent }),
      status:
        row.id != ""
          ? row.status == 1
            ? "Pending"
            : row.status == 2
            ? "Approved"
            : "Removed"
          : "",
      createdDate: row.id != "" ? createdDate : "",
      updatedDate: row.id != "" ? (row.updatedDate ? updatedDate : "-") : "",
      regulation:
        row.id != ""
          ? row.regulation
            ? row.regulation.regShortName
            : "NA"
          : "",
      regulator:
        row.id != ""
          ? row.regulator
            ? row.regulator.regulatorShortName
            : "NA"
          : "",
      obligationNote: ValidCellValue({ value: row.obligationNote }),
      testCitationContent: ValidCellValue({ value: row.testCitationContent }),
    }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  // console.log(filteredData, 'filteredData')
  const filteredData = customizedData.map(
    ({
      incrementId,
      id,
      regulator,
      regulation,
      customerOrganization,
      obligationId,
      ...rest
    }) => rest
  )
  const arrangedColumns = [
    "obId",
    "obligationName",
    "obligationDesc",
    "citationUrl",
    "issuingAuthority",
    "regLaw",
    "citation",
    "obligationNote",
    "status",
    "createdDate",
    "updatedDate",
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "OB_ID",
        "Obligation Name",
        "Obligation Description",
        "Citation URL",
        "Regulator Short Name",
        "Reg Law Short Name",
        "Citation",
        "Obligation Notes",
        "Status",
        "Created At",
        "Last Updated At",
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  console.log("filteredData", filteredData)
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Obligations.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  pageCount: customePageCount,
  filterArray,
  checkOrg,
  setCheckOrg,
  checkAdmin,
  setAddModal,
  addModal,
  setFilterArray,
  handleSort,
  setSortBy,
  obligationAccess,
  downloadAccess,
  columns,
  customePageSize,
  setCustomePageSize,
  data,
  fetchData,
  searchObject,
  props,
  handleRender,
  setSearchObject,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  setLoader,setMultyLoader,multyLoader,ChangeLoader
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useResizeColumns,
    // useFlexLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const handleExport = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()

    const data = {
      ...searchObject,
      ...filterArray,
      obligationIds: ids,
      customerorgId:
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    let apiUrl
    if (checkOrg !== undefined && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/export-obligations`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/export-obligation-organization`
    }

    axios
      .post(apiUrl,  { data: encryptData(data) }, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
        responseType:'blob'
      })
      .then(response => {
        // console.log(data.data.data, 'exporData');
        // exportToExcel(response.data.data)
        DownloadExelFrom({responseData:response.data,fileTitle: "Test obligation"})
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data") 
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }

  const handleSearchQueryChange = (columnKey, value) => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setSearchQuery(prev => ({ ...prev, [columnKey]: value }))

    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {
          // tableName: "Obligation",
          tableName:
            checkOrg !== undefined && checkOrg === 3
              ? "Obligation"
              : "custObligations",
          feildName: columnKey,
          ids: idsArray,
          searchValue: value,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (columnKey != "updatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [columnKey]: response.data.data.map(item => item[columnKey]),
          }))
        }
        setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnKey]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => {

    const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);
    if (optionExists || value === "") {
      console.log("selected from suggestion box")

      let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      updatedValue = formattedDate
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: [updatedValue] }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)

    } else {

      let updatedValue
    if (value === "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
    } else if (columnId == "updatedDate") {
      // Set the value to false when selecting "No"
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)
      updatedValue = formattedDate
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
    }

    const updatedSearchQuery = { ...searchQuery, [columnId]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)

    }
    
    console.log("API call triggered:", value)
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value")
    if (value == "") {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 1 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 1,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 2 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 2,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, [columnKey]: 3 }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        [columnKey]: 3,
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery[columnKey], "column id")
  }
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      fetchData(searchQuery)
    }
  }

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    setLoader(true)
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setSearchObject({})
    setSelectedHeader([])
    setFilterArray({})
    setSortBy({
      tableName:
        checkOrg !== "" && checkOrg === 3 ? "obligation" : "custObligations",
      fieldName: "obligationName",
      order: "ASC",
    })
    setSelectedDate(null)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")

    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const history = useHistory()
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [modalEdit, setModalEdit] = useState(false)
  const [descEdit, setDescEdit] = useState("")
  const [titleEdit, setTitleEdit] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [updatedData, setUpdatedData] = useState("new")
  const [blankColumn, setBlankColumn] = useState("0")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)

  const [multyResponce, setMultyResponce] = useState({
    REC:{},RACD:{}
  })
  const [dropdownOpen, setDropdownOpen] = useState(false) 

  const SaveResponce = (keyName, response)=>{ 
    setMultyResponce({...multyResponce,[keyName]:response})
  }
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  const toggleEdit = () => {
    setModalEdit(!modalEdit)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-obligations`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  const handleImportEditData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("isBlankUpdatable", blankColumn)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-obligation-update`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}` },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                 ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  const edit = () => {
    props.history.replace({
      pathname: "/test-obligations/obligation_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const mutiedit = () => {
    console.log(selectedFlatRows, "selectedFlatRows")
    const data = selectedFlatRows.map(item => item.original)
    history.push("/test-obligations/obligation_muti_edit", { rowData: data })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {
          tableName: "Obligation",
          ids: ids,
        },
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
              ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  const Reload_Empty_Citation = () =>{
    POST_API_HANDLER({
      loaderStart: () => ChangeLoader("REC",true),
      loaderClose: () => ChangeLoader("REC",false),
      errorMessage: true,
      ApiEndPoint: `${process.env.REACT_APP_BASE_URL}scan-test-data`,
      setState: (response)=>{SaveResponce("REC",response)},
      successMessage:true,
      errorMessage:true,
      bodyData:{"subType": "empty-citation"},
      navigateTo:navigateTo
    });
  }

  const Reload_All_Citation_Data = () =>{
    POST_API_HANDLER({
      loaderStart: () => ChangeLoader("RACD",true),
      loaderClose: () => ChangeLoader("RACD",false),
      errorMessage: true,
      ApiEndPoint: `${process.env.REACT_APP_BASE_URL}scan-test-data`,//LOCAL_API_END_POINT
      setState: (response)=>{SaveResponce("RACD",response)},
      successMessage:true,
      errorMessage:true,
      bodyData:{"subType": "all-citation"},
      navigateTo:navigateTo
    });
  }
  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const obj2 = JSON.parse(localStorage.getItem("orgID"))

      let newCheckOrg

      if (obj2) {
        newCheckOrg = obj2.orgId
      } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
      }

      if (newCheckOrg !== checkOrg) {
        setCheckOrg(newCheckOrg)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])
  //   useEffect(()=>{
  //     if (props.location.search.length > 5) {
  //       const Qurry = {}
  //       props.location?.search?.split("?").map((i)=>{Qurry[i?.split("=")[0]]= i?.split("=")[1]})
  //       handleAutocompleteChange("obligations",Qurry.obligations)
  //     }
  // },[])
  
  return (
    <Fragment>
      <Row className=" d-flex align-items-center">
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={4}
          className="py-3 justify-content-end"
        >
          {/* <div className="d-flex flex-wrap align-items-center   justify-content-start">
            <h5 className="font-size-18 mb-0">List of Obligations</h5>
            <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{width : "150px", marginLeft: "10px"}}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
          </div> */}
          <TableHeader
            heading={"List of Obligations"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={8}
          className="py-3 justify-content-end"
        >
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      // marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                      display:"none"
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  {/* <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button> */}
                </>
              ) : (
                // :
                // selectedFlatRows.length >= 1 ? (
                //   <Button
                //     onClick={(e) => {
                //       onAllDeleteRecord(e)
                //     }}
                //     style={{
                //       backgroundColor: "#556EE6",
                //       marginLeft: 5,
                //       marginRight: 5,
                //       color: "#fff",
                //       textTransform: "none",
                //     }}
                //   >
                //     <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                //     Delete
                //   </Button>
                // )
                ""
              )
            ) : (
              ""
            )}
            {selectedFlatRows.length >= 1 && (
              <>
                <Button
                  onClick={() => mutiedit()}
                  style={{
                    backgroundColor: "#556EE6",
                    // marginLeft: 5,
                    color: "#fff",
                    textTransform: "none",
                    display:"none"
                  }}
                >
                  {/* <Link
                          to={{
                            pathname: "/test-obligations/obligation_muti_edit",
                            state: { selectedFlatRows },
                          }}
                          style={{
                            color: "#fff",
                          }}
                        > */}
                  <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                  Multiple Edit
                  {/* </Link> */}
                </Button>
              </>
            )}

            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {/* {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg !== 3 ? (
              <Button
                variant="contained" 
                onClick={() => setAddModal(true)}
                style={{
                  backgroundColor: "#556ee6", 
                  color: "#fff",
                  textTransform: "none", 
                }}
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Obligations
              </Button>
            ) : (
              ""
            )} */}

            {/* {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Link
                className="btn btn-primary"
                to="/test-obligations/obligation_update"
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Obligation
              </Link>
            ) : (
              "" || ""
            )} */}
            {/* {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Button
                onClick={() => {
                  setModal(!modal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6", 
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )} */}
            <Modal
              isOpen={modal}
              toggle={toggle}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px" }}
            >
              <ModalHeader toggle={toggle}>{title}</ModalHeader>
              <ModalBody className="px-4 py-4 text-left">
                <div
                  style={{ marginBottom: "20px", display: "flex", gap: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="new-data"
                      name="form-radio-primary"
                      defaultChecked={updatedData == "new"}
                      value="new"
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="new-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload new data
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      id="edit-data"
                      name="form-radio-primary"
                      value="edit"
                      defaultChecked={updatedData == "edit"}
                      onChange={e => {
                        setUpdatedData(e.target.value)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="edit-data"
                      style={{ marginBottom: "0px" }}
                    >
                      Upload Edit
                    </label>
                  </div>
                </div>
                {updatedData == "edit" && (
                  <div
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      gap: "30px",
                    }}
                  >
                    <DarkTooltip title="should not updatable blank column">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="0"
                          name="blank-column"
                          defaultChecked={blankColumn == "0"}
                          value="0"
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="0"
                          style={{ marginBottom: "0px" }}
                        >
                          Blank not allowed{" "}
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                    <DarkTooltip title="should updatable blank column">
                      <div
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="radio"
                          id="1"
                          name="blank-column"
                          value="1"
                          defaultChecked={blankColumn == "1"}
                          onChange={e => {
                            setBlankColumn(e.target.value)
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="1"
                          style={{ marginBottom: "0px" }}
                        >
                          Blank allowed
                        </label>
                        <i className="bx bx-info-circle"></i>
                      </div>
                    </DarkTooltip>
                  </div>
                )}
                <h6>Import Data</h6>
                <div className="form-body mt-2">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-3"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button"
                    onClick={e => {
                      updatedData == "new"
                        ? handleImportData(e)
                        : handleImportEditData(e)
                    }}
                  >
                    Import Data
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {/* {obligationAccess && obligationAccess.writeAccess == true && checkOrg === 3 ?
              <Button
                onClick={() => {
                  setModalEdit(!modalEdit);
                  setDescEdit("Import Data for Update");
                  setTitleEdit("Import Data for Update");
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload for Update
              </Button>
              :
              ""
                } 
              <Modal
                isOpen={modalEdit}
                toggle={toggleEdit}
                className="modal-dialog-centered"
                style={{ borderRadius: "50px" }}
              >
                <ModalHeader toggle={toggleEdit}>{titleEdit}</ModalHeader>
                <ModalBody className="px-4 py-4 text-left">
                  <h6>Import Data for Update</h6>
                  <div className="form-body mt-2">
                    <input
                      type="file"
                      id="csvFileInput"
                      accept=".xlsx"
                      name="file"
                      onChange={onFileChange}
                      className="form-control mb-3"
                    />
                    <div className="error">{errors.file}</div>
                  </div>
                  <div className="px-6 mt-3 mb-1 text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      name="button"
                      onClick={(e) => handleImportEditData(e)}
                    >
                      Import Data
                    </button>
                  </div>
                </ModalBody>
              </Modal> */}
                {  
              multyLoader.REC ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={Reload_Empty_Citation}>
                  <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                  Reload Empty Citation
                </button>
              )
           }

{  
              multyLoader.RACD ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={Reload_All_Citation_Data}>
                  <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                  Reload all Citation Data
                </button>
              )
           }




            {downloadAccess && downloadAccess.readAccess === true ? (
              btnLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={handleExport}>
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Download
                </button>
              )
            ) : (
              ""
            )}
            {/* <button
                type="button"
                className="btn btn-primary "
                // onClick={handleExport}
              >
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Upload
              </button>  */}
            {/* <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Buttn
                  variant="contained"
                  style={{
                    backgroundColor: "#556ee6",
                    color: "#fff",
                    textTransform: "none ",
                    fontFamily: "poppins",
                    fontWeight: 400,
                    fontSize: 15
                  }}
                  component="span"
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </Buttn>
              </label> */}
          </div>
        </Col>
      </Row>
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 600 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span>
                              <br /> */}
                      </div>
                      {/* {console.log(column.id, 'column id')} */}
                      {column.id != "selection" &&
                      column.id != "updatedDate" &&
                      column.id != "status" &&
                      // column.id != "status" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{ width: "70%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              // options={handleNullAndUndefinedOfArray(autoFill[column.id]||[])}
                              options={autoEmpty[column.id] || searchQuery[column.id] ? autoFill[column.id] || [] : []}
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value&&trimLeft(value))
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  disabled={loader}
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={handleKeyDown}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          <button
                            onClick={() => {
                              setShowModal(true)
                              setModalData(column)
                            }}
                            disabled={loader}
                            // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            className={
                              selectedHeader.includes(column.Header)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button>
                        </div>
                      ) : column.id == "updatedDate" ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: '2023-05-15',
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary docs-datepicker-trigger"
                                              // disabled
                                              style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div> */}
                        </div>
                      ) : column.id == "status" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              value={
                                searchQuery[column.id] != undefined
                                  ? searchQuery[column.id]
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              displayEmpty
                              onChange={event =>
                                handleValueChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={1}>Pending</MenuItem>
                              <MenuItem value={2}>Approved</MenuItem>
                              <MenuItem value={3}>Removed</MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <Filter column={column} /> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          tableName={
            checkOrg !== "" && checkOrg === 3 ? "Obligation" : "custObligations"
          }
          // tableName={"Obligation"}
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0??false}

        />
      </Styles>
      {/* )} */}
      {/* </div> */}
    </Fragment>
  )
}



const Obligations = props => {
  // 
  const [part, setPart] = useState('');
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
const content = "Your large content string here...";
const numberOfParts = 5000; // Divide content into 5 parts
const interval = 5000; // Interval between adding parts (in milliseconds)

  // 
  const [fakeData, setFakeData] = useState([])
  const [modal, setModal] = useState(false)
  const [modalTest, setModalTest] = useState(false)
  const [show, setShow] = useState(false)
  const [desc, setDesc] = useState("")
  const [citationContent, setcitationContent] = useState("") 
  const [citationContent2, setcitationContent2] = useState("")
  const [citationContentId, setcitationContentId] = useState("")
  const [citationContentLoader, setCitationContentLoader] = useState(false)
  const [ckEditorErrorShow,setCkEditorErrorShow]=useState(false)
  const toggle = () => setModal(!modal)
  var primaryEditorContent = citationContent2
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [addModal, setAddModal] = useState(false)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [delObligation, setDelObligation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [selectDisclosure, setSelectDisclosure] = useState([])
  const [customePageSize, setCustomePageSize] = useState(50)
  const [obligationAccess, setObligationAccess] = useState([])
  const [regrId, setRegrID] = useState(null)
  const [selectRegLaw, setSelectRegLaw] = useState([])
  const [organizationID, setOrganizationID] = useState(false)
  const [isCheckOrgSet, setIsCheckOrgSet] = useState(false)
  const [checkOrg, setCheckOrg] = useState()
  const [downloadAccess, setDownloadAccess] = useState([])
  const [checkAdmin, setCheckAdmin] = useState(false)
  const callUseNavigate = useHistory()
const navigateTo = (url) =>{
  callUseNavigate.push(url)
}
  const [multyLoader, setMultyLoader] = useState({
    REC:false,RACD:false
  })
 
  const location = useLocation()
  const ChangeLoader = (loaderName, value)=>{
    const loaderValue = value || false
    setMultyLoader({...multyLoader,[loaderName]:loaderValue})
  }
  // console.log(location.state.rowData)

  useEffect(() => {
    if (location.state && location.state.rowData) {
      setSearchObject({
        ...searchObject,
        ["regLaw"]: location.state.rowData.regShortName,
      })
    }
  }, [location])

  // useEffect(() => {
  //   const org_id = JSON.parse(localStorage.getItem("orgID"));
  //   const authToken = JSON.parse(localStorage.getItem("authUser"));

  //   if(org_id && org_id.orgId === 3){
  //     setCheckAdmin(true)
  //   } else if(authToken && authToken.data.customerorg.id === 3){
  //     setCheckAdmin(true)
  //   }
  // },[])
  function updateStringParts({originalString, numParts, interval}) {
    let currentIndex = 0;
    let parts = [];

    // Divide the original string into equal parts
    for (let i = 0; i < numParts; i++) {
        const partLength = Math.ceil(originalString.length / numParts);
        parts.push(originalString.slice(currentIndex, currentIndex + partLength));
        currentIndex += partLength;
    }

    // Function to add parts at specified intervals
    const addPartInterval = setInterval(() => {
        // Check if there are remaining parts to add
        if (parts.length > 0) {
            primaryEditorContent += parts.shift(); // Add next part to the original string
            setcitationContent2(primaryEditorContent)
            console.log("parts.length",parts.length); // Output updated string
        } else {
            clearInterval(addPartInterval); // Stop interval when all parts added
        }
    }, interval);

    return primaryEditorContent;
}
  const TestSetData = (value, id) => {

    console.log("object open 2546985")
    setcitationContentId(id)
    setcitationContent(value)
    setTimeout(() => {
      setModalTest(true)
    
      // updateStringParts({ originalString:value, numParts:100, interval:5000})

      ScrollDown()
    }, 2000)
  }
  // const LoadTestSetData = (value, id) => {
  //   setcitationContentId(id)
  //   TestSetData(value, id)
  //   setTimeout(() => {
  //     setModalTest(true)
  //   }, 1000)
  // }

  const toggleCitationModael = () => {
    primaryEditorContent = ""
    setModalTest(!modalTest)
    setShow(!show)
    setcitationContentId("")
    setcitationContent("")
    setCkEditorErrorShow(false)
  }

  const onClickCitationSave = (editedContent,newFunction) => {
    const submitableValue = newFunction?editedContent : citationContent
    // const submitableValue =   citationContent
    console.log("testCitationContent",RemovePtag(submitableValue));
    if (CheckValidValue({value:RemovePtag(submitableValue)})) {
      setCitationContentLoader(true)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const requestData = {
        obligation_id: citationContentId,
        testCitationContent:RemovePtag(submitableValue)
 
      }
  
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/update-test-obligation`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          console.log(response, "obs issue response")
          if (response.status >= 200 || response.status <= 299) {
            // return response.data;
            toastr.success("Citation Content Updated")
            toggleCitationModael()
  
            setCitationContentLoader(false)
            fetchData()
          }
          // throw response.data;
        })
        .catch(err => {
          RedirectOnUnAuthentication(err,navigateTo)
          var message
          setLoader(false) 
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          setCitationContentLoader(false)
          toggleCitationModael()
          throw message
        })
    } else {
      ToastError({message:"Content Value is require; can`t save empty"})
      setCkEditorErrorShow(true)
      toggleCitationModael()
    }

  
  }

  console.log(checkOrg, "checkOrg")

  const [sortBy, setSortBy] = useState({
    fieldName :  "obligationName",
    order :  "ASC",
    tableName :  "obligation"
  })

  useEffect(() => {
    // setSortBy(prevSortBy => ({
    //   ...prevSortBy,
    //   tableName: checkOrg !== undefined  && checkOrg === 3 ? "obligation" : 'custObligations',
    // }));
    if (checkOrg !== undefined && checkOrg === 3) {
      setSortBy({
        tableName: "obligation",
        fieldName: "obligationName",
        order: "ASC",
      })
    } else if (checkOrg !== undefined && checkOrg !== 3) {
      setSortBy({
        tableName: "custObligations",
        fieldName: "obligationName",
        order: "ASC",
      })
    }
  }, [checkOrg])

  const limit = 20

  const handleSort = column => {
    const isAsc = sortBy.fieldName === column.id && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      setSortBy({
        tableName:
          checkOrg !== "" && checkOrg === 3 ? "obligation" : "custObligations",
        fieldName: column.id,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setObligationAccess(authToken && authToken.data.role.rolesPermission[2])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelObligation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')
    // setDelete(!is_delete)
    if (delObligation.id) {
      // dispatch(onDeleteObligation(delObligation.id, props.history))
      deleteObligation(delObligation.id)
      setDeleteModal(false)
    }
  }

  const addToggle = () => setAddModal(!addModal)

  const closeAddModal = () => {
    setAddModal(false)
    validation.resetForm()
  }

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    // console.log({searchQuery});
    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject

    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData=   {...searchObject,...filterArray}
    // const data = JSON.stringify({searchQuery})
    // console.log(data,"re-renderd")
    let apiUrl
    if (checkOrg !== "" && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-test-obligations/?limit=${customePageSize}&pageNo=${currentPage}`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}/get-obligation-organization/?limit=${customePageSize}&pageNo=${currentPage}`
    }

    setLoader(true)
    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } else {
      localStorage.removeItem("selectedFilterArray")
    }

    const requestData = {
      ...finalData,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
      orderBy: {
        ...sortBy,
      },
    }

    axios
      .post(
        //   checkOrg !== ""  && checkOrg === 3?
        //    `${process.env.REACT_APP_BASE_URL}/get-obligations/?limit=${customePageSize}&pageNo=${currentPage}`
        // : `${process.env.REACT_APP_BASE_URL}/get-obligation-organization/?limit=${customePageSize}&pageNo=${currentPage}`
        apiUrl,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        console.log(response, "obs issue response")
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          setReqresData(response.data.data)
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)
          if (response.data.isProcessing) {
            setTimeout(() => {
              setMultyLoader("RACD",true)
              setMultyLoader("REC",true)
            }, 7000)
            
          }else{
            setTimeout(() => {
              setMultyLoader("RACD",false)
              setMultyLoader("REC",false)
            }, 7000)

          }
         
          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteObligation = obligation => {
    // console.log(regulation, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-obligation?id=${obligation}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Obligation Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
     
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        RedirectOnUnAuthentication(err,navigateTo,message)
      })
  }

  useEffect(() => {
    // console.log('page is loading')
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const columns = [
    {
      Header: "Obligation ID",
      width: 130,
      accessor: "obId",
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Link
            to={{
              pathname: "/test-obligations/obligation_view",
              state: { rowData },
            }}
          >
            <span style={{ color: "#000" }}> {CheckValidValue({value:cellProps?.value })?cellProps?.value : DisplayJustifyCenter("-") } </span>
          </Link>
        )
      },
      // width: 150
      // Cell: cellProps => {
      //   return (
      //    cellProps?.value?.length > 15 ?  cellProps?.value?.substr(0, 18) + " ..." : cellProps.value
      //   )
      // }
    },
    {
      Header: "Obligation Name",
      accessor: "obligationName",
      filterable: false,
      disableFilters: true,
      width: 125,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          
          <Link
            to={{
              pathname: "/test-obligations/obligation_view",
              state: { rowData },
            }}
          >
            <LightTooltip title={cellProps?.value}>
            <span style={{ color: "#000" }}   className="StringOverFlow1">
            {CheckValidValue({value:cellProps?.value })?cellProps.value && cellProps?.value?.length > 15
                ? cellProps?.value 
                : cellProps.value || "" 
                : DisplayJustifyCenter("-") }
          
            </span> 
            </LightTooltip>
          </Link>
        )
      },
    },
    {
      Header: "Obligation Description",
      width: 123,
      filterable: false,
      disableFilters: true,
      accessor: "obligationDesc",
      Cell: cellProps => {
        return (<>
        {CheckValidValue({value:cellProps?.value })?  <DarkTooltip title="View Details">
            <div style={{ textAlign: "center" }}>
              <i
                onClick={() => {
                  setModal(!modal)
                  setDesc(cellProps.value)
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  padding: "0 5px",
                  borderRadius: "20%",
                  color: "#556ee6",
                }}
                className="bx bxs-info-circle  font-size-24"
                id="descToolTip"
              ></i>
              {/* <UncontrolledTooltip placement="top" target="descToolTip">
              View Details
          </UncontrolledTooltip> */}
            </div>
          </DarkTooltip> : DisplayJustifyCenter("-") }
        
        </>)
        
    
      },
    },
    {
      Header: "Citation URL",
      width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "citationUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div>
            {CheckValidValue({value:cellProps?.value })?    <Link to={{ pathname: cellProps.value }} target="_blank">

<DarkTooltip title={rowData}>
  <div
    className="StringOverFlow1"
    style={{
      textDecoration: "underline",
      fontSize: "13px",
      color: "blue",
    }}
  >
    {(cellProps.value &&
      cellProps.value
        .replaceAll("https://", "")
        .replaceAll("http://", "")
        .replaceAll("www.", "") ) ||
      ""}
  </div>
</DarkTooltip>
</Link> : DisplayJustifyCenter("-") }
        
          </div>
        )
      },
    },
    {
      Header: "Regulator Short Name",
      accessor: "issuingAuthority",
      filterable: false,
      width: 123,
      disableFilters: true,
      Cell: cellProps => {
        return (<>
        {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
          ? cellProps?.value?.substr(0, 18) + " ..."
          : cellProps.value : DisplayJustifyCenter("-") }
        </>)
        
      },
    },
    {
      Header: "Reg Law Short Name",
      accessor: "regLaw",
      filterable: false,
      width: 118,
      disableFilters: true,
      Cell: cellProps => {
        return (<>
          {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
            ? cellProps?.value?.substr(0, 18) + " ..."
            : cellProps.value : DisplayJustifyCenter("-") }
          </>)
      },
    },
    {
      Header: "Citation",
      accessor: "citation",
      width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<>
          {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
            ? cellProps?.value?.substr(0, 18) + " ..."
            : cellProps.value : DisplayJustifyCenter("-") }
          </>)
      },
    },
    {
      Header: "Obligation Notes",
      accessor: "obligationNote",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<>
          {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
            ? cellProps?.value?.substr(0, 15) + " ..."
            : cellProps.value : DisplayJustifyCenter("-") }
          </>)
      },
    },
    {
      Header: "Status",
      accessor: "status",
      // width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <Badge
            className={
              cellProps.value === 2
                ? "font-size-11 badge-soft-success"
                : cellProps.value == 3
                ? "font-size-11 badge-soft-danger"
                : "font-size-11 badge-soft-warning"
            }
          >
            {cellProps.value == 1
              ? "Pending"
              : cellProps.value == 2
              ? "Approved"
              : "Removed"}
          </Badge>
        )
      },
    },
    {
      Header: "Last Update Date",
      accessor: "updatedDate",
      width : 80,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const date1 =FormatedDate( cellProps.value)   
        if (cellProps.value) {
          return date1
        } else {
          return "-"
        }
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      disableFilters: true,
      sticky: "right",
      filterable: false,
      width: 72,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <ul
            className="list-unstyled hstack gap-2"
            style={{ marginBottom: "1.2rem" }}
          >
            {/* <LightTooltip  */}
            {/* {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge className="font-size-15 badge-soft-success" pill>
                <DarkTooltip title="Edit">
                  <Link
                    to={{
                      pathname: "/test-obligations/obligation_update",
                      state: { rowData },
                    }}
                  >
                    <i */}
                 {/* //   onClick={() => handleView(value)}
                      style={{ color: "#34C38F", cursor: "pointer" }}
                      className="bx bx-edit-alt  font-size-18"
                      // id="edittooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )} */}
            {/* {obligationAccess && obligationAccess.readAccess === true ? (
              <Badge className="font-size-15 badge-soft-primary" pill>
                <DarkTooltip title="View">
                  <Link
                    to={{
                      pathname: "/test-obligations/obligation_view",
                      state: { rowData },
                    }}
                    onClick={() => {
                      const orderData = cellProps.row.original */}
            {/* // console.log(orderData, 'data') // handleOrderClick(orderData); */}
            {/* }}
                  >
                    <i
                      //   onClick={() => handleView(value)}
                      style={{ color: "blue", cursor: "pointer" }}
                      className="mdi mdi-eye-outline  font-size-18"
                      id="customerViewtooltip"
                    ></i>
                  </Link>
                </DarkTooltip>
              </Badge>
            ) : (
              ""
            )} */}
            {/* {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              <Badge
                color="danger"
                className="font-size-15 badge-soft-danger"
                pill
              > */}
            {/* <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                > */}
            {/* <DarkTooltip title="Remove">
                  <i
                    // onClick={() => toggleViewModal(value)}
                    onClick={() => {
                      onClickDelete(rowData)
                    }}
                    className="bx bx-trash font-size-18"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    id="removetooltip"
                  />
                </DarkTooltip> */}
            {/* </li> */}
            {/* </Badge>
            ) : (
              ""
            )} */}
            {modalTest == false && citationContentId == rowData.id ? (
              <div className="CommonApiLoader"></div>
            ) : (
              <>
                {obligationAccess &&
                obligationAccess.writeAccess === true &&
                checkOrg === 3 ? (
                  <Badge
                    color="info"
                    style={{
                      backgroundColor: "#17a2b8 !important",
                    }}
                    className="font-size-15 badge-soft-info"
                    pill
                    onClick={() => {
                      TestSetData(rowData.testCitationContent, rowData.id)
                    }}
                  >
                    {/* <li
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                > */}
                    <DarkTooltip title="Change Prior Citation Content">
                      <i
                        className="bx bx-edit-alt  font-size-18"
                        style={{
                          color: "#17a2b8",
                          cursor: "pointer",
                        }}
                        id="removetooltip"
                      />
                    </DarkTooltip>
                    {/* </li> */}
                  </Badge>
                ) : (
                  ""
                )}
              </>
            )}
          </ul>
        )
      },
    },
  ]

  const reports = [
    {
      title: "Total Obligations",
      iconClass: "bx-copy-alt",
      description: totalItems && totalItems,
    },
    { title: "New Obligations", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Obligations Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Obligations Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  // console.log(fakeData, "fakeData")
  document.title = "Test Obligations"
  const handleRender = () => {
    setDelete(!is_delete)
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }

  const handleObsChange = option => {
    console.log(option.value, "opionss")
    validation.setFieldValue("obIds", option ? option.map(x => x.value) : [])
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (checkOrg !== undefined && addModal) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/get-all-regulations`,
          {
            customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
          },
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            console.log(response, "Regulations response")
            const optionData = response.data.data.map(option => ({
              value: option.id,
              label: option.regshortname,
            }))

            const modifiedOptions = [
              { label: "Select Reg Law Short Name", value: "" }, // 0 option
              ...optionData, // API options
            ]

            setSelectRegLaw(optionData)
          }
        })
        .catch(err => { 
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          // throw message;
          RedirectOnUnAuthentication(err,navigateTo,message)
        })
    }
  }, [checkOrg, addModal])

  const fetchObligations = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-all-obligations`,
        {
          regulationId: regrId,
          customerorgId: checkOrg && parseInt(checkOrg),
        },
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        console.log("res", response.data)
        if (response.status >= 200 || response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: `${option.obId} (${
              option.obligationName && option.obligationName.length > 8
                ? option.obligationName.substr(0, 45) + " ..."
                : option.obligationName || "-"
            })`,
          }))

          const modifiedOptions = [
            { label: "Select Obligations", value: "" }, // 0 option
            ...optionData, // API options
          ]

          setSelectDisclosure(optionData)
          // return response.data;
          // setSelectObligation(response.data.data.map(option => ({
          //   value: option.id,
          //   label: `${option.obId} (${option.obligationName && option.obligationName.length > 8
          //     ? option.obligationName.substr(0, 45) + " ..."
          //     : option.obligationName ||'-'
          //   })`
          // }
          // )))
          // setTotalItems(response.data.totalRecords)
          // setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    if (regrId) {
      fetchObligations()
    }
  }, [regrId])

  const handleRegrID = id => {
    if (id === 0 || id === null) {
      setRegrID(null)
      // setSelectDisclosure([{ label: 'Select Regulations', value: 0 }]);
    } else {
      console.log("reg id", id)
      setRegrID(id)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reg_id: "",
      obIds: [],
    },
    validationSchema: Yup.object({
      obIds: Yup.array()
        .of(Yup.string().required("Please Select Obligation(s)"))
        .min(1, "Please Select at least one Obligation"),
    }),
    onSubmit: values => {
      console.log(values, "values")

      const addData = {
        ids: values.obIds.toString(),
        customerorgId: checkOrg,
      }
      console.log("addData", addData)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/add-obligation-organization`,
          addData,
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // setSuccess(true)
            toastr.success("Obligations Successfully Added.")
            setDelete(!is_delete)
            setAddModal(false)
            validation.resetForm()
            return response.data
          }
        })
        .catch(err => { 
          let message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = "Bad request! Already Linked"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          // return message
          // throw message;
          RedirectOnUnAuthentication(err,navigateTo,message)
        })
    },
  })
  useEffect(() => {
    if (modalTest) {
      // Simulate fetching or processing modal content

      // function diffHtml() {
      //   // console.log(firstHtml(), secondHtml())
      // return {
      //   __html: HtmlDiff.execute(firstHtml().__html, secondHtml().__html),
      // }
      // }

      setTimeout(() => {
        
        setShow(true)
      }, 2000)
    }
  }, [modalTest])




  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>Obligation Description</ModalHeader>
            <ModalBody className="text-left">
              <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div>
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={modalTest}
            toggle={toggleCitationModael}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggleCitationModael }  >
              Change Prior Citation Content
            </ModalHeader>
            <ModalBody className="text-left">
              {/* <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}

              {show?
              <>
              {/* <CKEditor
                editor={ClassicEditor}
                data={citationContent} 
                // data={citationContent2} 
                config={{
                  removePlugins: [
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                    "EasyImage",
                    "UploadImage",
                  ],
                }}
                onReady={editor => {
                  editor.editing.view.change(writer => {
                    writer.setStyle(
                      "max-height",
                      "300px",
                      editor.editing.view.document.getRoot()
                    )
                  })
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  // validation.setFieldValue('obligationDesc', data);
                  console.log(data, "data")
                  // Slice the string to remove the first <p> tag
                  const modifyData = data //  data.slice( data.indexOf(">") + 1,  data.lastIndexOf("<") )
                  setcitationContent(modifyData)
                }}
              /> */}
              {/* contentData = {`citationContent ${ShortString}`} */} 
              <EditorWithPagination buttonLoader={citationContentLoader} contentData = {citationContent?citationContent:""} onSave={(editedContent)=>!citationContentLoader? onClickCitationSave(editedContent,true):()=>{}}/>
              </>
              : <div className="ml-5 d-flex justify-content-center">
              <Spinner className="ms-2 " color="primary" />
            </div>} 
             
            </ModalBody>
            {/* <ModalFooter className="mx-auto">
              {citationContentLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <Button
                  className="px-5"
                  color="primary"
                  onClick={()=>!citationContentLoader? onClickCitationSave():()=>{}}
                >
                  Save
                </Button>
              )}{" "}
              
            </ModalFooter> */}
          </Modal>

          <Modal
            isOpen={addModal}
            // toggle={toggle}
            toggle={addToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={closeAddModal}>
              Add Record from Admin DB
            </ModalHeader>
            <ModalBody className="text-left">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Select Reg Law Short Name
                        </Label>
                        <Select
                          options={selectRegLaw}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="reg_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={selectRegLaw.find(
                            option => option.value === validation.values.reg_id
                          )}
                          onChange={option => {
                            validation.setFieldValue("reg_id", option.value)
                            handleRegrID(option.value)
                            validation.setFieldValue("ob_id", "") // Reset regulationId to 0 when regulatorId changes
                          }}
                        />
                        {validation.touched.reg_id &&
                        validation.errors.reg_id ? (
                          <div className="error">
                            {validation.errors.reg_id}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Obligations</Label>
                        <Select
                          options={selectDisclosure}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="obIds"
                          isMulti
                          value={selectDisclosure.filter(option =>
                            validation.values.obIds.includes(option.value)
                          )}
                          // value={selectDisclosure.find(
                          //   option => option.value === validation.values.obIds
                          // )}
                          onChange={handleObsChange}
                          onBlur={validation.handleBlur}
                          isDisabled={!validation.values.reg_id}
                        />
                        {validation.touched.obIds && validation.errors.obIds ? (
                          <div className="error">{validation.errors.obIds}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button type="submit" className="me-2" color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={closeAddModal}
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form>
            </ModalBody>
          </Modal>
          {/* <Row>
            {reports.map((report, key) => (
              <Col className="col-xl-3 col-md-6" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody
                    className="custome-card"
                    style={{ padding: "10px 11px !important" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-muted">{report.title}</h6>
                      </div>
                      <div
                        className="avatar-sm bg-primary align-self-center mini-stat-icon"
                        style={{ borderRadius: "100%" }}
                      >
                        <span
                          className="avatar-title bg-primary"
                          style={{ borderRadius: "100%" }}
                        >
                          <p className="mb-0 font-size-18">
                            {report.description}
                          </p>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  {/* <div className="row">
                    <div className="col-md-6">
                      <h5 className="mb-sm-0 font-size-18">
                        List of Obligations
                      </h5>
                    </div>
                  </div> */}
                  <TableContainer
                    setLoader={setLoader}
                    columns={columns}
                    data={reqresData}
                    searchObject={searchObject}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    checkAdmin={checkAdmin}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    setAddModal={setAddModal}
                    addModal={addModal}
                    filterArray={filterArray}
                    obligationAccess={obligationAccess}
                    downloadAccess={downloadAccess}
                    setFilterArray={setFilterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    props={props}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    setSearchObject={setSearchObject}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    setMultyLoader={setMultyLoader}
                    multyLoader={multyLoader}
                    ChangeLoader={ChangeLoader}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Obligations)


const ShortString = `Pakistan Election Result 2024 Live Updates: Following a national election on Thursday, Pakistan experienced notable delays in the vote count, prompting the country's election commission to issue a late-night warning to polling officers, urging them to promptly release results, even ten hours after polls had closed. Pakistan's political landscape is predominantly shaped by three major parties: Pakistan Muslim League-Nawaz (PML-N), Pakistan Tehreek-e-Insaf (PTI), and Pakistan People's Party (PPP).

Pakistan Election Result 2024 Live: PTI-backed independent candidates ‘win’ 47 seats, Geo News reported.
Pakistan Election Result 2024 Live: PTI-backed independent candidates ‘win’ 47 seats, Geo News reported.(REUTERS)
The Election Commission of Pakistan began announcing results more than nine hours after voting came to an end.

The National Assembly, comprised of 336 seats, sees 266 candidates elected through direct voting, while the remaining 70 seats are reserved. Among these reserved seats, 60 are designated for women and 10 for non-Muslims, allocated based on each party's representation in the Assembly. Securing a simple majority requires a minimum of 133 seats, but it's speculated by many analysts that the election outcome might not yield a decisive winner.

Key highlights so far:

Initially, former Pakistan Prime Minister Shehbaz Sharif, representing the PML-N party led by his brother Nawaz Sharif, secured victory in the NA-123 seat from Lahore, contributing to the party's total of 18 seats.
However, despite PML-N's performance, independent candidates supported by the jailed Imran Khan-led PTI party emerged victorious in 27 parliamentary seats.
The PPP, led by Bilawal Bhutto-Zardari, managed to win 20 seats as of 3 pm and others won five seats.
Zafar Iqbal, the special secretary at the Election Commission of Pakistan (ECP), attributed the delay in announcing results to an "internet issue," which caused the first official results for a constituency to be announced more than ten hours after polling closed.
Imran Khan's Pakistan Tehreek-e-Insaf (PTI) party encouraged individuals to remove passwords from their personal WiFi networks via a social media post, aiming to facilitate internet access for anyone nearby.
With the counting process continuing throughout the night, a clearer electoral landscape is expected to emerge by early Friday.`