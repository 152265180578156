import {
  GET_OBLIGATIONS_SUCCESS,
  GET_OBLIGATIONS_FAIL,
  ADD_OBLIGATION_SUCCESS,
  ADD_OBLIGATION_FAIL,
  UPDATE_OBLIGATION_SUCCESS,
  UPDATE_OBLIGATION_FAIL,
  DELETE_OBLIGATION_SUCCESS,
  DELETE_OBLIGATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  obligationsData: [],
  loading: true,
  error: false,
  errorValue: "",
  success: "",
}

const Obligations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OBLIGATIONS_SUCCESS:
      return {
        ...state,
        obligationsData: action.payload,
        loading: false,
      }

    case GET_OBLIGATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorValue: action.payload,
      }

    case ADD_OBLIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case ADD_OBLIGATION_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case UPDATE_OBLIGATION_SUCCESS:
      // console.log(action.payload.message, 'see')
      return {
        ...state,
        loading: false,
      }
    case UPDATE_OBLIGATION_FAIL:
      // console.log(action, 'error')
      return {
        // state ={
        ...state,
        loading: false,
        error: true,
        // }
      }
    case DELETE_OBLIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DELETE_OBLIGATION_FAIL:
      return {
        ...state,
        error: true,
        errorValue: action.payload,
      }
    // break
    default:
      return state
  }
}

export default Obligations
