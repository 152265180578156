import React from "react"
import { Redirect } from "react-router-dom"

// Regulations
import AllRegulations from "../pages/Regulations/All Regulations/index"
import RegulationView from "../pages/Regulations/All Regulations/View"
import RegulationUpdate from "../pages/Regulations/All Regulations/Update"

// Regulators
import Regulators from "../pages/Regulators"
import RegulatorsUpdate from "../pages/Regulators/Update"
import RegulatorView from "../pages/Regulators/View"

// Obligations
import Obligations from "../pages/Obligations"
import ObligationUpdate from "../pages/Obligations/update"
import ObligationView from "../pages/Obligations/view"
import MutiUpdated from "../pages/Obligations/MutliUpdate"

// Obligations
import DisclosureChangeRequest from "../pages/DisclosureChangeRequest"
import ObligationsTest from "../pages/ObligationsTest"
import ObligationUpdateTest from "../pages/ObligationsTest/update"
import ObligationViewTest from "../pages/ObligationsTest/view"
import MutiUpdatedTest from "../pages/ObligationsTest/MutliUpdate"

// Disclosures
import Disclosures from "../pages/Disclosures"
import DisclosureUpdate from "pages/Disclosures/update"
import DisclosureView from "../pages/Disclosures/view"

// Disclosure text
import DisclosuresText from "../pages/DisclosuresText"
import DisclosuresTextUpdate from "../pages/DisclosuresText/update"
import DisclosureTextView from "../pages/DisclosuresText/view"

// Link Table

import LinkTable from "../pages/LinkTable/Index"

// Customer Organization Link Table

import CustLinkTable from "../pages/CustLinkTable"

import LinkedDisclosureReport from "../pages/Reports/LinkedDisclosureReport/index"
import UnlinkedDisclosureReport from "../pages/Reports/UnLinkedDisclosureReport/index"
import NewObliReport from "../pages/Reports/UpdateObliReport/index"
import ScannerReport from "../pages/Reports/ScannerReport"
import UpdatedReportView from "../pages/Reports/UpdateObliReport/view"
import LinkScannerReport from "../pages/Reports/linkScannerReport"
import LinkUpdatedReportView from "../pages/Reports/linkObliReport/view"
import LinkDiscroUpdatedReportView from "../pages/Reports/linkObliReport/disclosureview"
import LinkNewObliReport from "../pages/Reports/linkObliReport/index"

import TestNewObliReport from "../pages/Reports/TestUpdateObliReport/index"
import TestScannerReport from "../pages/Reports/TestScannerReport"
import TestUpdatedReportView from "../pages/Reports/TestUpdateObliReport/view"
import TI_ScannerReport from "../pages/TestInterface/linkScannerReport/index"
import TI_NewObliReport from "../pages/TestInterface/linkObliReport/index"

import RERUpdatedReportView from "../pages/Reports/ErrorScannerReport/UpdateObliReport/view"
import RERNewObliReport from "../pages/Reports/ErrorScannerReport/UpdateObliReport/index"
import RERScannerReport from "../pages/Reports/ErrorScannerReport/index"

import TIERUpdatedReportView from "../pages/TestInterface/TestErrorScannerReport/UpdateObliReport/view"
import TIERNewObliReport from "../pages/TestInterface/TestErrorScannerReport/UpdateObliReport/index"
import TIERScannerReport from "../pages/TestInterface/TestErrorScannerReport/index"
// Customer Organization Linked Report

import CustLinkedReport from "../pages/CustLinkedReport"

// Settings
import Settings from "../pages/Settings"

// File Manager
import FileManager from "../pages/FileManager/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ChangePassword from "../pages/Authentication/ChangePassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index"
import DashboardCrypto from "../pages/Dashboard-crypto/index"
import DashboardJob from "../pages/DashboardJob/index"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import TI_UpdatedReportView from "pages/TestInterface/linkObliReport/view"
import TI_DisclouserView from "pages/TestInterface/linkObliReport/disclosureview"
import CustlinkObliReport from "pages/Reports/CustlinkObliReport"
import CustLinkScannerReport from "pages/Reports/CustLinkScannerReport"
import CustUpdatedReportView from "pages/Reports/CustlinkObliReport/view"
import CustDisclosureview from "pages/Reports/CustlinkObliReport/CustDisclosureview" 
import UpdateObliReportWithUrl from "pages/Reports/UpdateObliReportWithUrl"
import ScannerReportWithUrl from "pages/Reports/ScannerReportWithUrl"
import ScannerReportWithUrlList from "pages/Reports/ScannerReportWithUrl/urlListing"
import UpdatedReportViewWithUrl from "pages/Reports/UpdateObliReportWithUrl/view" 
import RegReviewList from "pages/Reports/RegReview"
import ReviewDetailList from "pages/Reports/ReviewDetail"
import WorkFlowList from "pages/Reports/WorkFlowReport"
import UpdateWorkFlowList from "pages/Reports/UpdateWorkFlowReport"
import UpdateWorkFlowViewList from "pages/Reports/UpdateWorkFlowView"
import AuthorReviewList from "pages/Reports/AuthorReview"
import AuthorReviewDetailList from "pages/Reports/AuthorReviewDetail"
// import AuthorReviewList from "pages/Reports/AuthorReview"

const authProtectedRoutes = [
  { path: "/home", component: Dashboard },
  { path: "/dashboard-saas", component: DashboardSaas },
  { path: "/dashboard-crypto", component: DashboardCrypto },
  { path: "/dashboard-job", component: DashboardJob },

  { path: "/disclosure-change-request", component: DisclosureChangeRequest },
  { path: "/client-disclosure-change-request", component: DisclosureChangeRequest },
  // Regulations
  {
    path: "/regulations/all-regulations",
    component: props => <AllRegulations />,
  },
  { path: "/regulations/regulation_view", component: RegulationView },
  { path: "/regulations/regulation_update", component: RegulationUpdate },

  // Regulators

  { path: "/regulators/all-regulators", component: Regulators },
  { path: "/regulators/regulator_update", component: RegulatorsUpdate },
  { path: "/regulators/regulator_view", component: RegulatorView },

  // Obligations

  { path: "/obligations/all-obligations", component: Obligations },
  { path: "/obligations/obligation_update", component: ObligationUpdate },
  { path: "/obligations/obligation_view", component: ObligationView },
  { path: "/obligations/obligation_muti_edit", component: MutiUpdated },

  // Obligations
  { path: "/test-obligations/all-obligations", component: ObligationsTest },
  {
    path: "/test-obligations/obligation_update",
    component: ObligationUpdateTest,
  },
  { path: "/test-obligations/obligation_view", component: ObligationViewTest },
  {
    path: "/test-obligations/obligation_muti_edit",
    component: MutiUpdatedTest,
  },

  // Disclosure text
  { path: "/disclosure-text/all-disclosure-text", component: DisclosuresText },
  {
    path: "/disclosure-text/disclosure-text_update",
    component: DisclosuresTextUpdate,
  },
  {
    path: "/disclosure-text/disclosure_text_view",
    component: DisclosureTextView,
  },

  // Disclosure
  { path: "/disclosures/all-disclosures", component: Disclosures },
  { path: "/disclosures/disclosure_update", component: DisclosureUpdate },
  { path: "/disclosure-text/disclosure_view", component: DisclosureView },

  // Link Table
  { path: "/link-table", component: LinkTable },

  // Customer Link Table
  { path: "/cust-link-table", component: CustLinkTable },

    // Regulatoion Scan 
    // { path: "/regulators/regulator_view", component: RegulatorView },
// Error reports error module
{ path: "/rer-updated-obli-report-view", component: RERUpdatedReportView },
{ path: "/rer-new-obli-report", component: RERScannerReport },
{ path: "/rer-updated-obli-report", component:RERNewObliReport  },
// Error test_interface error module
{ path: "/tier-updated-obli-report-view", component: TIERUpdatedReportView },
{ path: "/tier-new-obli-report", component: TIERScannerReport },
{ path: "/tier-updated-obli-report", component: TIERNewObliReport },
  { path: "/url-new-obli-report", component: ScannerReportWithUrl },
  { path: "/url-new-obli-report-link", component: ScannerReportWithUrlList },
  { path: "/url-updated-obli-report", component: UpdateObliReportWithUrl },
  { path: "/url-updated-obli-report-view", component: UpdatedReportViewWithUrl },
  { path: "/reports", component: LinkedDisclosureReport },
  { path: "/unlinked-report", component: UnlinkedDisclosureReport },
  { path: "/new-obli-report", component: ScannerReport },
  { path: "/work-flow-report", component: WorkFlowList },
  { path: "/updated-obli-report", component: NewObliReport },
  { path: "/updated-workflow-report", component: UpdateWorkFlowList },
  { path: "/updated-workflow-report-view", component: UpdateWorkFlowViewList}, 
  { path: "/reg-review-report", component: RegReviewList },
  { path: "/author-review-report", component: AuthorReviewList },
  { path: "/author-review-detail", component: AuthorReviewDetailList },
  { path: "/review-Details", component: ReviewDetailList},
  { path: "/updated-obli-report-view", component: UpdatedReportView },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/ti-new-obli-link", component: TI_ScannerReport },
  { path: "/ti-updated-obli-report-link", component: TI_NewObliReport },
  { path: "/test-updated-obli-report-view-link", component: TestUpdatedReportView },
  { path: "/new-obli-link", component: LinkScannerReport },
  { path: "/updated-obli-report-link", component: LinkNewObliReport },
  { path: "/updated-obli-report-view-link", component: LinkUpdatedReportView },
  {
    path: "/updated-obli-report-view-discro",
    component: LinkDiscroUpdatedReportView,
  },
  { path: "/cust-new-obli-link", component: CustLinkScannerReport },
  { path: "/cust-updated-obli-report-link", component: CustlinkObliReport },
  { path: "/cust-updated-obli-report-view-link", component: CustUpdatedReportView },
  {
    path: "/cust-updated-obli-report-view-discro",
    component: CustDisclosureview,
  },
  {
    path: "/ti-updated-obli-report-view-discro",
    component: TI_DisclouserView,
  },
  { path: "/test-new-obli-report", component: TestScannerReport },
  { path: "/test-updated-obli-report", component: TestNewObliReport },
  { path: "/ti-updated-obli-report-view", component: TI_UpdatedReportView },

  // Customer Linked Report
  { path: "/cust-reports", component: CustLinkedReport },

  // Settings
  { path: "/settings", component: Settings },

  //File Manager
  { path: "/apps-filemanager", component: FileManager },

  // //profile
  { path: "/profile", component: UserProfile },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },  
  { path: "/re-charts", component: ReCharts },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:email", component: ChangePassword },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
