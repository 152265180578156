import { takeEvery, put, call, all, fork } from "redux-saga/effects" 
import {
  GET_REGULATORS,
  ADD_NEW_REGULATOR,
  UPDATE_REGULATOR,
  DELETE_REGULATOR,
} from "./actionTypes"

import {
  getRegulatorsSuccess,
  getRegulatorsFail,
  addRegulatorSuccess,
  addRegulatorFail,
  updateRegulatorSuccess,
  updateRegulatorFail,
  deleteRegulatorSuccess,
  deleteRegulatorFail,
} from "./action"

import {
  getRegulators,
  addNewRegulator,
  updateRegulator,
  deleteRegulator,
} from "../../helpers/fakebackend_helper"
import { ToastSuccess } from "helpers/helper_function"

function* fetchRegulators({ urlData }) {
  // if(urlData){
  const limit = urlData.limit
  const page = urlData.currentPage
  // console.log(limit, page,"page in saga")
  // }
  try {
    const response = yield call(getRegulators, limit, page)
    yield put(getRegulatorsSuccess(response))
  } catch (error) {
    console.log(error, "error")
    yield put(getRegulatorsFail(error))
  }
}

function* onAddNewRegulator({ payload: { regulator, history } }) {
  try {
    const response = yield call(addNewRegulator, regulator)
    yield put(addRegulatorSuccess(response)) 
    ToastSuccess({message:"Regulator Successfully Added."}) 
    history.push("/regulators/all-regulators")
  } catch (error) {
    console.log(error, "error") 
    ToastError(error)
    yield put(addRegulatorFail(error))
  }
}

function* onUpdateRegulator({ payload: { regulator, history } }) {
  try {
    const response = yield call(updateRegulator, regulator)
    yield put(updateRegulatorSuccess(response)) 
    ToastSuccess({message:"Regulator Successfully Updated."}) 
    history.push("/regulators/all-regulators")
  } catch (error) {
    console.log(error, "error") 
    ToastError(error)
    yield put(updateRegulatorFail(error))
  }
}

function* onDeleteRegulator({ payload: { regulator, history } }) {
  try {
    const response = yield call(deleteRegulator, regulator)
    console.log("response", response)
    yield put(deleteRegulatorSuccess(response)) 
    ToastSuccess({message:"Regulator Successfully Deleted."}) 
    history.push("/regulators/all-regulators")
  } catch (error) {
    console.log("error", error) 
    ToastError(error)
    yield put(deleteRegulatorFail(error))
  }
}

export function* watchFetchRegulators() {
  yield takeEvery(GET_REGULATORS, fetchRegulators)
}

function* regulatorsSaga() {
  yield all([fork(watchFetchRegulators)])
  yield takeEvery(ADD_NEW_REGULATOR, onAddNewRegulator)
  yield takeEvery(UPDATE_REGULATOR, onUpdateRegulator)
  yield takeEvery(DELETE_REGULATOR, onDeleteRegulator)
}

export default regulatorsSaga
