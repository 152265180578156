import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import './Authentication.css'

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import CarouselPage from "./CarouselPage"
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import lightlogo from "../../assets/images/logo-light.svg"
import loginLogo from "../../assets/images/loginPageLogo.png"
import smallLogo from "../../assets/images/small-logo.png"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  //meta title
  document.title = "Login | Apogee -  Admin & Dashboard"
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please Enter Valid Email").min(10,"Email must be at least 10 characters").max(50,"Email must be at most 50 characters").required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.history))
    },
  })

  const { loading, error } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
  }))

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(socialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  return (
    <React.Fragment>
     
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <div className='mask d-flex justify-content-center align-items-center '  style={{ height: '100vh' }}>
            <div id='authenticationMainBox' >
              <div className='m-5' id='authentication-Under-MainBox' >
                <Col>
                    <div className="justify-content-center">
                      {/* <div className="justify-content-center"> */}
                      
                      <Card className="overflow-hidden mx-4 mb-5 bg-transparent">
                      <div className=" py-2 ms-4 my-2">
                        {/* <Link to="/dashboard" className="d-block auth-logo"> */}
                        <img
                          src={loginLogo}
                          alt=""
                          height=""
                          width="110"
                          className="auth-logo-light"
                        />
                         <div className="text-white my-3">
                           <h5 className="text-white">Welcome Back To Apogee</h5>
                           <span className="fw-0">We are happy to see you again,</span>
                          </div>
                        {/* </Link> */}
                      </div>
                        
                        <CardBody className="pt-0">
                          
                          <div className="p-2">
                            <Form
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                              }}
                            >
                              {loading ? (
                                <div
                                  className="container-fluid "
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Spinner className="ms-2" color="primary" />
                                </div>
                              ) : error ? (
                                <Alert color="danger">{error && error}</Alert>
                              ) : null}
                              <div className="mb-3">
                                <Label className="form-label text-light">Email Address</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter Email Address"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback
                                    type="invalid"
                                    style={{ fontSize: "12px" }}
                                    
                                  >
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label text-light">Password</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={show ? "text" : "password"}
                                    placeholder="Enter Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                      validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  <button
                                    onClick={() => setShow(!show)}
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                  >
                                    {show ? (
                                      <i className="mdi mdi-eye-outline"></i>
                                    ) : (
                                      <i className="mdi mdi-eye-off-outline"></i>
                                    )}
                                  </button>
                                </div>
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <div className="error" style={{ fontSize: "12px" }}>
                                    {validation.errors.password}
                                  </div>
                                ) : null}
                              </div>

                              <Link to="/forgot-password" className="text-decoration-none text-light">
                                  <i className="mdi mdi-lock me-1" /> Forgot your
                                  password?
                              </Link>

                              {/* <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customControlInline"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customControlInline"
                                  >
                                    Remember me
                                  </label>
                                </div>             */}

                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block "
                                  type="submit"
                                >
                                  Log In
                                </button>
                              </div>

                            
                                
                              
                            </Form>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="mt-1 text-center"></div>
                      {/* </div> */}
                    </div>
                </Col>
              </div>
            </div>
          </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}







const logindata=()=> {
  return (
    <Col xl={8}>
<div className="justify-content-center">
  {/* <div className="justify-content-center"> */}
  {/* <div className="mb-5"> */}
  <div className="mb-5 mb-md-5 ms-4 mt-3">
    {/* <Link to="/dashboard" className="d-block auth-logo"> */}
    <img
      src={loginLogo}
      alt=""
      height="50"
      width="100"
      className="auth-logo-light"
    />
    {/* </Link> */}
  </div>
  {/* </div> */}
  <Card className="overflow-hidden mx-4 mt-5">
    <div
      className="text-white"
      style={{ backgroundColor: "rgba(82, 113, 167, 0.5)" }}
    >
      <Row>
        <Col className="col-7">
          <div className="text-white p-4">
            <h5 className="text-white">Login</h5>
            <p>Welcome to Apogee.</p>
          </div>
        </Col>
        <Col className="col-5 align-self-end">
          <img src={profile} alt="" className="img-fluid" />
        </Col>
      </Row>
    </div>
    <CardBody className="pt-0">
      <div className="auth-logo d-flex justify-content-center">
        <Link to="/" className="auth-logo-light">
          <div className="avatar-md profile-user-wid mb-4">
            <span className="avatar-title rounded-circle bg-light">
              <img
                src={lightlogo}
                alt=""
                className="rounded-circle"
                height="34"
              />
            </span>
          </div>
        </Link>
        <Link to="/" className="auth-logo-dark">
          <div className="avatar-md profile-user-wid mb-4">
            <span className="avatar-title rounded-circle bg-light">
              <img
                src={smallLogo}
                alt=""
                className="rounded-circle"
                height="80"
              />
            </span>
          </div>
        </Link>
      </div>
      <div className="p-2">
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          {loading ? (
            <div
              className="container-fluid "
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : error ? (
            <Alert color="danger">{error && error}</Alert>
          ) : null}
          <div className="mb-3">
            <Label className="form-label">Email Address</Label>
            <Input
              name="email"
              className="form-control"
              placeholder="Enter Email Address"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email &&
                validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email &&
            validation.errors.email ? (
              <FormFeedback
                type="invalid"
                style={{ fontSize: "12px" }}
              >
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Password</Label>
            <div className="input-group auth-pass-inputgroup">
              <Input
                name="password"
                value={validation.values.password || ""}
                type={show ? "text" : "password"}
                placeholder="Enter Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.password &&
                  validation.errors.password
                    ? true
                    : false
                }
              />
              <button
                onClick={() => setShow(!show)}
                className="btn btn-light "
                type="button"
                id="password-addon"
              >
                {show ? (
                  <i className="mdi mdi-eye-outline"></i>
                ) : (
                  <i className="mdi mdi-eye-off-outline"></i>
                )}
              </button>
            </div>
            {validation.touched.password &&
            validation.errors.password ? (
              <div className="error" style={{ fontSize: "12px" }}>
                {validation.errors.password}
              </div>
            ) : null}
          </div>

          {/* <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="customControlInline"
              />
              <label
                className="form-check-label"
                htmlFor="customControlInline"
              >
                Remember me
              </label>
            </div>             */}

          <div className="mt-3 d-grid">
            <button
              className="btn btn-primary btn-block "
              type="submit"
            >
              Log In
            </button>
          </div>

          <div className="mt-4 text-center">
            <Link to="/forgot-password" className="text-muted">
              <i className="mdi mdi-lock me-1" /> Forgot your
              password?
            </Link>
          </div>
        </Form>
      </div>
    </CardBody>
  </Card>
  <div className="mt-1 text-center"></div>
  {/* </div> */}
</div>
</Col>
  )
}
