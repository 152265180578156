import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
  useRef,
} from "react"
import { map } from "lodash"
import { useDispatch } from "react-redux"
import toastr from "toastr"
import debounce from "lodash/debounce"
import axios from "axios"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Link, useLocation } from "react-router-dom"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"  
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import { useSticky } from "react-table-sticky"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Form,
} from "reactstrap"
import Paper from "@material-ui/core/Paper"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import styled from "styled-components"
import { Popper } from "@material-ui/core"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Pagination from "../../components/Common/Pagination"
import DeleteModal from "../../components/Common/DeleteModal"
import Select from "react-select"
import { format } from "date-fns"
import { MenuItem, FormControl } from "@material-ui/core"
import { Select as MaterialUISelect } from "@material-ui/core"

// import TableContainer from "../../../components/Common/TableContainer"
import TableHeader from "components/Common/TableHeader"
import FilterModal from "components/FilterModal/FilterModal"
import { init } from "i18next"
import { LOCAL_API_END_POINT, cellValueLimit } from "helpers/general_constant"
import {
  CheckValidValue,
  DisplayJustifyCenter,
  FormatedDate,
  InnerText,
  POST_API_HANDLER,
  RedirectOnUnAuthentication,
  RemoveFalseElementsFromArray,
  RemovePtag,
  RemoveSpaces,
  RenameKeysOfObject,
  SaperateObjectBasedOnValueLength,
  ScrollDown,
  ToastError,
  ToastSuccess,
  ValidCellValue,
  compareStrings,
  convertObjectValuesToArray,
  encryptData,
  handleNullAndUndefinedOfArray,
  hexHtmlToString,
  removeKeysFromObject,
  removeNonBreakingSpaces,
  trimLeft,
  updateOldText,
} from "helpers/helper_function"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorWithPagination from "components/Common/EditorWithPagination"
import ReduceSpan from "./reduceSpan"
import HtmlDiff from "htmldiff-js"
import { HEADER } from "helpers/url_helper"

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const ColumnHideCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <input type="checkbox" ref={resolvedRef} {...rest} />
  }
)

ColumnHideCheckbox.displayName = "ColumnHideCheckbox"

 

const exportToExcel = rowData => {
  // const data = rowData.map(row => row.original)
  // const header = Object.keys(data[0])
  console.log(rowData, "row2584")
  const ArrayWithSaperateObjects = rowData // SaperateObjectBasedOnValueLength(  rowData,  32000  )

  const customizedData = ArrayWithSaperateObjects.map(row => {
    // const updatedDate = FormatedDate(row.updatedDate, true)
    // const createdDate = FormatedDate(row.createdDate, true)

    // const EffectiveDate = FormatedDate(row.regEffectiveDate, true)
    const newRow = removeKeysFromObject(row, [ 
      "custDisclosures_id",
      "custDisclosures_status",
      "custDisclosures_disId",
      "disclosure_id",
      "disclosure_oldText",
      "disclosure_text",
      "customerOrganizationId", 
      "disclosureChangeRequest_createdDate", 
      "disclosureChangeRequest_updatedDate", 
      "disclosureChangeRequest_status", 
    ])
    return {
      ...newRow,
      // disclosureChangeRequest_id: ValidCellValue({ value: row.disclosureChangeRequest_id }),  
      // disclosureChangeRequest_obId: ValidCellValue({ value: row.disclosureChangeRequest_obId }), //row.obligationDesc  ? row.obligationNote.replace(/<[^>]+>/g, "").slice(0,cellValueLimit) : "-",
      // disclosureChangeRequest_disId: ValidCellValue({ value: row.disclosureChangeRequest_disId }),
      // customerOrganization_companyName: ValidCellValue({ value: row.customerOrganization_companyName }),
      // custDisclosures_oldText: ValidCellValue({ value: row.custDisclosures_oldText }),
      // custDisclosures_text: ValidCellValue({ value: row.custDisclosures_text }),
      "Request ID":   row.customerOrganization_id  , 
      "Edit by client":
        row.disclosureChangeRequest_status != ""
          ? row.disclosureChangeRequest_status == 1
            ? "Pending"
            : row.disclosureChangeRequest_status == 2
            ? "Approved"
            : row.disclosureChangeRequest_status == 2
            ? "Declined"
            : "Removed"
          : "All",
      }
    // console.log(row.regulatorDesc, 'regDescription')
  })

  console.log(customizedData, 'filteredData')
  const filteredData = customizedData.map(
    ({
      
      ...rest
    }) => rest
  )
  const arrangedColumns = [
    "Request ID",
    "disclosureChangeRequest_obId",
    "disclosureChangeRequest_disId",
    "customerOrganization_companyName",
    "custDisclosures_oldText",
    "custDisclosures_text", 
    "Edit by client",
    
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
     "Request ID",
    "Obligation ID",
    "Disclosure ID",
    "Client Name",
    "Old Text",
    "New Text",
    "Edit by client", 
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  console.log("filteredData", filteredData)
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "disclosureChangeRequestReport.xlsx")
}

const handleFileUpload = e => {
  const file = e.target.files[0]
  const reader = new FileReader()
  reader.onload = evt => {
    const bstr = evt.target.result
    const wb = XLSX.read(bstr, { type: "binary" })
    const wsname = wb.SheetNames[0]
    const ws = wb.Sheets[wsname]
    const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
    setData(excelData)
  }
  reader.readAsBinaryString(file)
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 80px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}
const TableContainer = ({
  loader,
  pageCount: customePageCount,
  filterArray,
  checkOrg,
  setCheckOrg,
  checkAdmin,
  setAddModal,
  addModal,
  setFilterArray,
  handleSort,
  setSortBy,
  obligationAccess,
  downloadAccess,
  columns,
  customePageSize,
  setCustomePageSize,
  data,
  fetchData,
  searchObject,
  props,
  handleRender,
  setSearchObject,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  globalFilter,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  setLoader,
  setMultyLoader,
  multyLoader,
  ChangeLoader,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      pageCount: customePageCount,
      manualSortBy: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    // useResizeColumns,
    // useFlexLayout,
    useSticky,
    useRowSelect,
    hooks => {
      let num =  0
      data.forEach((i)=> i["disclosureChangeRequest_status"] == 0?num++:"")
      hooks.visibleColumns.push(columns => 
        
        [
        // Let's make a column for selection
        {
          id: "selection",
          sticky: "left",
          width: 37,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className={num == 0 && checkOrg !== 3?"d-none":""}>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div onClick={()=>console.log("columns",row)} className={row.original.disclosureChangeRequest_status != 0 && checkOrg !== 3?"d-none":""}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false)
  const [searchQuery, setSearchQuery] = useState({})
  const [autoFill, setAutoFill] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [selectedDate, setSelectedDate] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const [importModal, setImportModal] = useState(false) 
  const toggleImport = () => {
    setImportModal(!importModal)
    setSelectedFile("")
    setErrors({})
  }
  const callUseNavigate = useHistory()
  const navigateTo = url => {
    callUseNavigate.push(url)
  }
  const generateSortingIndicator = column => {
    return column.id != "selection" && column.Header != "Action" ? " 🔽 🔼" : ""
  }

  // const generateSortingIndicator = column => {
  //   return ( " 🔽" : " 🔼")
  // };

  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  const fieldChangeParams = {
    "disclosureChangeRequest_obId": "disclosureChangeRequest.obId", 
    "disclosureChangeRequest_disId": "disclosureChangeRequest.disId", 
    "customerOrganization_companyName": "customerOrganization.companyName",  
    // Add more mappings as needed
  };
  const handleExportExcel = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)

    const filteredData = selectedFlatRows.filter((row) => row.original.disclosureChangeRequest_status ===0)
    const id_arr = map( checkOrg == 3?selectedFlatRows:filteredData, "original.disclosureChangeRequest_id")
    console.log("selectedFlatRows",selectedFlatRows);
    const ids = id_arr //.toString()
    const finalFilter =   {
      ...searchObject,
      ...filterArray, 
    }
      const finalData = RenameKeysOfObject({ object:finalFilter, params:fieldChangeParams })
    const data = {
      ...finalData,
      "disclosureChangeRequest.id": ids,
      customerorgId:
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }

    let apiUrl  = `${process.env.REACT_APP_BASE_URL}/export-excel-recommend-change-disclosure-request-report`

    axios
      .post(apiUrl, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
          ...HEADER
        },
        responseType: 'blob'
      })
      .then(response => { 
        console.log("response.data",response.data)

        try {
          // const response = await axios.get('backend_endpoint_to_download_xls_file', {
          //   responseType: 'blob' // Set responseType to 'blob' to handle binary data
          // });
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'disclosure_text.xlsx'); // Set filename for downloaded file
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error('Error downloading Excel file:', error);
        }
        setBtnLoader(false)
      })
      .catch(error => {
        RedirectOnUnAuthentication(error, navigateTo, "Error Exporting Data")
        setBtnLoader(false)
      })
    // console.log(selectedFlatRows, 'row')
    // exportToExcel(selectedFlatRows)
  }
  const handleExport = () => {
    console.log("export function",selectedFlatRows)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)

    const id_arr = map(selectedFlatRows, "original.disclosureChangeRequest_id")
    const ids = id_arr.toString()
    console.log(ids, "selectedFlatRows")

   
  const finalFilter =   {
    ...searchObject,
    ...filterArray, 
  }
    const finalData = RenameKeysOfObject({ object:finalFilter, params:fieldChangeParams })
    const data = {
      ...finalData,
      // reportId: queryId,
      ids: ids,
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}recommend-change-disclosure-request-report-pdf`, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
          ...HEADER
        },
        responseType: "blob",
      })
      // .then(response => {
      //   // exportToExcel(response.data.data)
      //   setBtnLoader(false)
      //   response.json()
      // })
      .then(response => {
        setBtnLoader(false)
        toastr.success("Report Downloaded Successfully.")
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "Reg Content Change Report.pdf") //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        setBtnLoader(false)
      })
  }

  const handleSearchQueryChange = (fldName, value) => {
    console.log(fldName, "value" ,value, "handleSearchQueryChange1 ")

    const authToken = JSON.parse(localStorage.getItem("authUser"))

    let columnName, tableName
      // relationFeild = ""
    if (fldName === "disclosureChangeRequest_obId") {
      columnName = "obId"
      tableName = "disclosureChangeRequest"
      // relationFeild = "obligation"
    } else if (fldName === "disclosureChangeRequest_disId") {
      columnName = "disId"
      tableName = "disclosureChangeRequest"

      // relationFeild = "obligation"
    } else if (fldName === "customerOrganization_companyName") {
      columnName = "companyName"
      tableName = "customerOrganization"

      // relationFeild = "obligation"
    } else if (fldName === "disclosure_oldText") {
      columnName = "oldText"
      tableName = "disclosure"
      // relationFeild = "obligation"
    } else if (fldName === "custDisclosures_text") {
      columnName = "text"
      tableName = "custDisclosures"

      // relationFeild = "obligation"
    } 
    else if (fldName === "custDisclosures_oldText") {
      columnName = "oldText"
      tableName = "custDisclosures"

      // relationFeild = "obligation"
    } 
    // else if (fldName === "newObligationReport_prevUpdatedDate") {
    //   const newDate = new Date(value)
    //   const formattedDate = format(newDate, "yyyy-MM-dd")
    //   console.log(formattedDate, "formattedDate")
    //   setSelectedDate(formattedDate)

    //   setSearchQuery(prev => ({ ...prev, ["prevUpdatedDate"]: formattedDate }))
    // } else if (fldName === "obligation_obId") {
    //   console.log(fldName,"value", value,value=="" ,"newObli 123 setSearchQuery")
    //   columnName = "obId"
    //   relationFeild = "obligation"
    //   const updatedSearchQuery = {
    //     ...searchQuery,
    //     // ["newObligationReport.obId"]: value,
    //   }
    //   setSearchQuery(updatedSearchQuery)
    // } else if (fldName === "disclosure_obligations_obligation_contentId") {
    //   columnName = "contentId"
    //   relationFeild = "disclosure_obligations_obligation"
    // } else if (fldName === "obligation_issuingAuthority") {
    //   columnName = "issuingAuthority"
    //   relationFeild = "obligation"
    // } else if (fldName === "disclosure_disId") {
    //   columnName = "disId"
    //   relationFeild = "disclosure"
    // } else if (fldName === "disclosure_disclosureTitle") {
    //   columnName = "disclosureTitle"
    //   relationFeild = "disclosure"
    // } else if (fldName === "disclosure_disclosureDesc") {
    //   columnName = "disclosureDesc"
    //   relationFeild = "disclosure"
    // }
     else if (fldName === "disclosure_text") {
      columnName = "text"
      tableName = "disclosure"

    } 
    // else if (fldName === "disclosure_disclosureSpecificCitation") {
    //   columnName = "disclosureSpecificCitation"
    //   relationFeild = "disclosure"
    // } 
    else {
      columnName = fldName
      tableName = "disclosureChangeRequest"
    }

    const idsArray = localStorage.getItem("idsArray")
    ? JSON.parse(localStorage.getItem("idsArray"))
    : []

    const data ={
      // tableName: "Obligation",
      tableName: tableName,
      feildName: columnName,
      ids: idsArray,
      searchValue: value,
    }

    axios
    .post(
      `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
      {data:encryptData(data)},
      {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
          ...HEADER
        },
      }
    )
    .then(response => {
      // if (columnKey != "updatedDate") {
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [fldName]: response.data.data.map(item => item[columnName]),
        }))
      // }
      setSearchQuery(prev => ({ ...prev, [fldName]: value }))
    })
    .catch(error => {
      RedirectOnUnAuthentication(error, navigateTo)
      setAutoFill(prevAutoFill => ({
        ...prevAutoFill,
        [fldName]: [],
      }))
    })
  }

  const handleAutocompleteChange = (columnId, value) => {
    console.log(columnId, value, "check handleAutocompleteChange and value")

    const optionExists =
      autoFill[columnId] && autoFill[columnId].includes(value)
    if (optionExists || value === "") {
      console.log("selected from suggestion box")

      let updatedValue
      let columnName
      // console.log(columnId, typeof(columnId));
      // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
      if (value == "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
        // console.log("**************");
      } else {
        updatedValue = value
      }

      if (columnId == "obligation_obId") {
        console.log(columnId == "obId",updatedValue=="", "check", "newObligationReport.obId")
        columnName = "obligation.obId"
        setAutoEmpty(prev => ({ ...prev, ["obligation_obId"]: updatedValue, ["newObligationReport.obId"]: updatedValue }))
        //  updatedValue = value
      } else if (columnId == "obligation_obligationName") {
        columnName = "obligation.obligationName"
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_obligationName"]: updatedValue,
        }))
      } else if (columnId == "obligation_obligationDesc") {
        columnName = "obligation.obligationDesc"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_obligationDesc"]: updatedValue,
        }))
      } else if (columnId == "obligation_citationUrl") {
        columnName = "obligation.citationUrl"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_citationUrl"]: updatedValue,
        }))
      } else if (columnId == "obligation_citation") {
        columnName = "obligation.citation"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_citation"]: updatedValue,
        }))
      } else if (columnId == "obligation_regLaw") {
        columnName = "obligation.regLaw"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["obligation_regLaw"]: updatedValue }))
      } else if (columnId == "oldobligationDesc") {
        columnName = "newObligationReport.oldobligationDesc"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue }))
      } else if (columnId == "newObligationReport_prevUpdatedDate") {
        // Set the value to false when selecting "No"
        // console.log(value, 'value')

        const newDate = new Date(value)
        console.log(newDate, "newDate")
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        updatedValue = formattedDate
        columnName = "newObligationReport.prevUpdatedDate"
      } else if (columnId == "disclosure_obligations_obligation_contentId") {
        columnName = "disclosure_obligations_obligation.contentId"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_obligations_obligation_contentId"]: updatedValue,
        }))
      } else if (columnId == "obligation_issuingAuthority") {
        columnName = "obligation.issuingAuthority"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_issuingAuthority"]: updatedValue,
        }))
      } else if (columnId == "disclosure_disId") {
        columnName = "disclosure.disId"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["disclosure_disId"]: updatedValue }))
      } else if (columnId == "disclosure_disclosureTitle") {
        columnName = "disclosure.disclosureTitle"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_disclosureTitle"]: updatedValue,
        }))
      } else if (columnId == "disclosure_disclosureDesc") {
        columnName = "disclosure.disclosureDesc"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_disclosureDesc"]: updatedValue,
        }))
      } else if (columnId == "disclosure_text") {
        columnName = "disclosure.text"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["disclosure_text"]: updatedValue }))
      } else if (columnId == "disclosure_disclosureSpecificCitation") {
        columnName = "disclosure.disclosureSpecificCitation"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_disclosureSpecificCitation"]: updatedValue,
        }))
      } else {
        updatedValue = value // Set the value to true when selecting "Yes"
        columnName = columnId
      }

      // console.log(columnName,updatedValue, "updated alue")
      const updatedSearchQuery = {
        ...searchQuery,
        [columnName]: [updatedValue],
      }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else {
      let updatedValue
      let columnName
      // console.log(columnId, typeof(columnId));
      // console.log(columnId == "obId", 'check', 'newObligationReport.obId');
      if (value == "" || value == null) {
        updatedValue = "" // Set the value to blank when selecting "All"
        // console.log("**************");
      } else {
        updatedValue = value
      }

      console.log(columnId   , "columnId", "newObli 123")
      if (columnId == "obligation_obId") {
        console.log(columnId == "obligation_obId",updatedValue=="", updatedValue,"updatedValue", "newObli 123")
        columnName = "obligation.obId"
        setAutoEmpty(prev => ({ ...prev, ["obligation_obId"]: updatedValue,["newObligationReport.obId"]: updatedValue }))
        //  updatedValue = value
      } else if (columnId == "obligation_obligationName") {
        columnName = "obligation.obligationName"
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_obligationName"]: updatedValue,
        }))
      } else if (columnId == "obligation_obligationDesc") {
        columnName = "obligation.obligationDesc"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_obligationDesc"]: updatedValue,
        }))
      } else if (columnId == "obligation_citationUrl") {
        columnName = "obligation.citationUrl"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_citationUrl"]: updatedValue,
        }))
      } else if (columnId == "obligation_citation") {
        columnName = "obligation.citation"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_citation"]: updatedValue,
        }))
      } else if (columnId == "obligation_regLaw") {
        columnName = "obligation.regLaw"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["obligation_regLaw"]: updatedValue }))
      } else if (columnId == "oldobligationDesc") {
        columnName = "newObligationReport.oldobligationDesc"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue }))
      } else if (columnId == "newObligationReport_prevUpdatedDate") {
        // Set the value to false when selecting "No"
        // console.log(value, 'value')

        const newDate = new Date(value)
        console.log(newDate, "newDate")
        const formattedDate = format(newDate, "yyyy-MM-dd")
        console.log(formattedDate, "formattedDate")
        updatedValue = formattedDate
        columnName = "newObligationReport.prevUpdatedDate"
      } else if (columnId == "disclosure_obligations_obligation_contentId") {
        columnName = "disclosure_obligations_obligation.contentId"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_obligations_obligation_contentId"]: updatedValue,
        }))
      } else if (columnId == "obligation_issuingAuthority") {
        columnName = "obligation.issuingAuthority"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["obligation_issuingAuthority"]: updatedValue,
        }))
      } else if (columnId == "disclosure_disId") {
        columnName = "disclosure.disId"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["disclosure_disId"]: updatedValue }))
      } else if (columnId == "disclosure_disclosureTitle") {
        columnName = "disclosure.disclosureTitle"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_disclosureTitle"]: updatedValue,
        }))
      } else if (columnId == "disclosure_disclosureDesc") {
        columnName = "disclosure.disclosureDesc"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_disclosureDesc"]: updatedValue,
        }))
      } else if (columnId == "disclosure_text") {
        columnName = "disclosure.text"
        updatedValue = value
        setAutoEmpty(prev => ({ ...prev, ["disclosure_text"]: updatedValue }))
      } else if (columnId == "disclosure_disclosureSpecificCitation") {
        columnName = "disclosure.disclosureSpecificCitation"
        updatedValue = value
        setAutoEmpty(prev => ({
          ...prev,
          ["disclosure_disclosureSpecificCitation"]: updatedValue,
        }))
      } else {
        updatedValue = value // Set the value to true when selecting "Yes"
        columnName = columnId
      }

      // console.log(columnName,updatedValue, "updated alue")
      const updatedSearchQuery = { ...searchQuery, [columnName]: updatedValue }
      handleSearchQueryChange(columnId, updatedValue)
      setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  // const handleSearchQueryChange = (columnKey, value) => {
  //   const authToken = JSON.parse(localStorage.getItem("authUser"))
  //   let fileName, tableName;
  //   if(columnKey == "disclosureChangeRequest_obId"){
  //     fileName = "obId";
  //     tableName = "disclosureChangeRequest"
  //   }else if (columnKey == "disclosureChangeRequest_disId"){
  //     fileName = "disId";
  //     tableName = "disclosureChangeRequest"
  //   }
  //   else if (columnKey == "customerOrganization_companyName"){
  //     fileName = "companyName";
  //     tableName = "customerOrganization"
  //   }
  //   else if (columnKey == "disclosure_oldText"){
  //     fileName = "oldText";
  //     tableName = "disclosure"
  //   }
  //   else if (columnKey == "custDisclosures_text"){
  //     fileName = "text";
  //     tableName = "custDisclosures"
  //   }
  //   else if (columnKey == "disclosure_text"){
  //     fileName = "text";
  //     tableName = "disclosure"
  //   }else{
  //     fileName = columnKey;
  //     tableName = "disclosureChangeRequest"
  //   }
    
  //   setSearchQuery(prev => ({ ...prev, [fileName]: value }))

  //   const idsArray = localStorage.getItem("idsArray")
  //     ? JSON.parse(localStorage.getItem("idsArray"))
  //     : []

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
  //       {
  //         // tableName: "Obligation",
  //         tableName: tableName,
  //         feildName: fileName,
  //         ids: idsArray,
  //         searchValue: value,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken.data.authToken}`,
  //         },
  //       }
  //     )
  //     .then(response => {
  //       // if (columnKey != "updatedDate") {
  //         setAutoFill(prevAutoFill => ({
  //           ...prevAutoFill,
  //           [columnKey]: response.data.data.map(item => item[fileName]),
  //         }))
  //       // }
  //       setSearchQuery(prev => ({ ...prev, [columnKey]: value }))
  //     })
  //     .catch(error => {
  //       RedirectOnUnAuthentication(error, navigateTo)
  //       setAutoFill(prevAutoFill => ({
  //         ...prevAutoFill,
  //         [columnKey]: [],
  //       }))
  //     })
  // }

  // const handleAutocompleteChange = (columnId, value) => {
  //   const optionExists =
  //     autoFill[columnId] && autoFill[columnId].includes(value)
  //   if (optionExists || value === "") {
  //     console.log("selected from suggestion box")
  //     let updatedValue
  //     let fileName, tableName;
  //     if (value === "" || value == null) {
  //       updatedValue = "" // Set the value to blank when selecting "All"
  //     } 
  //     else if(columnId == "disclosureChangeRequest_obId"){
  //       fileName = "disclosureChangeRequest.obId";
  //       tableName = "disclosureChangeRequest"
  //     }else if (columnId == "disclosureChangeRequest_disId"){
  //       fileName = "disclosureChangeRequest.disId";
  //       tableName = "disclosureChangeRequest"
  //     }
  //     else if (columnId == "customerOrganization_companyName"){
  //       fileName = "customerOrganization.companyName";
  //       tableName = "customerOrganization"
  //     }
  //     else if (columnId == "disclosure_oldText"){
  //       fileName = "disclosure.oldText";
  //       tableName = "disclosure"
  //     }
  //     else if (columnId == "custDisclosures_text"){
  //       fileName = "custDisclosures.text";
  //       tableName = "custDisclosures"
  //     }
  //     else if (columnId == "disclosure_text"){
  //       fileName = "disclosure.text";
  //       tableName = "disclosure"
  //     }
  //     else if (columnId == "updatedDate") {
  //       // Set the value to false when selecting "No"
  //       const newDate = new Date(value)
  //       const formattedDate = format(newDate, "yyyy-MM-dd")
  //       console.log(formattedDate, "formattedDate")
  //       setSelectedDate(formattedDate)
  //       updatedValue = formattedDate
  //     } else {
  //       updatedValue = value // Set the value to true when selecting "Yes"
  //       fileName = columnId;
  //       tableName = "disclosureChangeRequest"
      
  //     }

  //     const updatedSearchQuery = { ...searchQuery, [fileName]: [updatedValue] }
  //     handleSearchQueryChange(columnId, updatedValue)
  //     setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
  //     setSearchQuery(updatedSearchQuery)
  //     setSearchObject(updatedSearchQuery)
  //   } else {
  //     let updatedValue
  //     let fileName, tableName;
  //     console.log("DDDDDD1123132")
  //     if (value === "" || value == null) {
  //       updatedValue = "" // Set the value to blank when selecting "All"
  //     } 
  //     else if(columnId == "disclosureChangeRequest_obId"){
  //       fileName = "disclosureChangeRequest.obId";
  //     }else if (columnId == "disclosureChangeRequest_disId"){
  //       fileName = "disclosureChangeRequest.disId";
  //     }
  //     else if (columnId == "customerOrganization_companyName"){
  //       fileName = "customerOrganization.companyName";
  //     }
  //     else if (columnId == "disclosure_oldText"){
  //       fileName = "disclosure.oldText";
  //     }
  //     else if (columnId == "custDisclosures_text"){
  //       fileName = "custDisclosures.text";
  //     }
  //     else if (columnId == "disclosure_text"){
  //       fileName = "disclosure.text";
  //     }
  //     else if (columnId == "updatedDate") {
  //       // Set the value to false when selecting "No"
  //       const newDate = new Date(value)
  //       const formattedDate = format(newDate, "yyyy-MM-dd")
  //       console.log(formattedDate, "formattedDate")
  //       setSelectedDate(formattedDate)
  //       updatedValue = formattedDate
  //     } else {
  //       updatedValue = value // Set the value to true when selecting "Yes"
  //       fileName = columnId;
  //       tableName = "disclosureChangeRequest"
  //     }

  //     const updatedSearchQuery = { ...searchQuery, [fileName]: updatedValue }
  //     handleSearchQueryChange(columnId, updatedValue)
  //     setAutoEmpty(prev => ({ ...prev, [columnId]: updatedValue }))
  //     setSearchQuery(updatedSearchQuery)
  //     setSearchObject(updatedSearchQuery)
  //   }

  //   console.log("API call triggered:", value)
  // }

  // const handleKeyDownDate = (columnId, e) => {
  //   if (e.keyCode === 27 || e.keyCode === 8) {
  //     // Clear the selected date on Escape or Backspace key press
  //     // dateQuery(null)
  //     if(columnId == "disclosureChangeRequest_obId"){
  //       fileName = "disclosureChangeRequest.obId";
  //       tableName = "disclosureChangeRequest"
  //     }else if (columnId == "disclosureChangeRequest_disId"){
  //       fileName = "disclosureChangeRequest.disId";
  //       tableName = "disclosureChangeRequest"
  //     }
  //     else if (columnId == "customerOrganization_companyName"){
  //       fileName = "customerOrganization.companyName";
  //       tableName = "customerOrganization"
  //     }
  //     else if (columnId == "disclosure_oldText"){
  //       fileName = "disclosure.oldText";
  //       tableName = "disclosure"
  //     }
  //     else if (columnId == "custDisclosures_text"){
  //       fileName = "custDisclosures.text";
  //       tableName = "custDisclosures"
  //     }
  //     else if (columnId == "disclosure_text"){
  //       fileName = "disclosure.text";
  //       tableName = "disclosure"
  //     }
  //     setSelectedDate(null)
  //     const updatedSearchQuery = { ...searchQuery, [columnId]: "" }
  //     setSearchQuery(updatedSearchQuery)
  //     setSearchObject(updatedSearchQuery)
  //   }
  // }

  const handleValueChange = (columnKey, value) => {
    console.log(value, "value123222")
    if (value === "") {
      const updatedSearchQuery = { ...searchQuery, ["disclosureChangeRequest.status"]: "" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        ["disclosureChangeRequest.status"]: "",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
    else if (value === 0) {
      const updatedSearchQuery = { ...searchQuery, ["disclosureChangeRequest.status"]: "0" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        ["disclosureChangeRequest.status"]: "0",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 1) {
      const updatedSearchQuery = { ...searchQuery, ["disclosureChangeRequest.status"]: "1" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        ["disclosureChangeRequest.status"]: "1",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 2) {
      const updatedSearchQuery = { ...searchQuery, ["disclosureChangeRequest.status"]: "2" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        ["disclosureChangeRequest.status"]: "2",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    } else if (value == 3) {
      const updatedSearchQuery = { ...searchQuery, ["disclosureChangeRequest.status"]: "3" }
      setSearchQuery(prevSearchQuery => ({
        ...prevSearchQuery,
        ["disclosureChangeRequest.status"]: "3",
      }))
      // fetchData(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }

    console.log(searchQuery["disclosureChangeRequest.status"], "column id")
  }
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      fetchData(searchQuery)
    }
  }

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          // height: "35px",
        },
      },
    },
  })

  const clearFilter = () => {
    setLoader(true)
    setSearchQuery({})
    setAutoEmpty({})
    setAutoFill({})
    setSearchObject({})
    setSelectedHeader([])
    setFilterArray({})
    setSortBy({  
      fieldName: "id",
      order: "ASC",
      tableName: "disclosureChangeRequest",
    })
    setSelectedDate(null)
    // fetchData({})
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")

    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  const iconElement = (
    <span className="calendar-icon">
      <i className="bx bx-search-alt search-icon">/</i>
    </span>
  )

  const history = useHistory()
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [modalEdit, setModalEdit] = useState(false)
  const [descEdit, setDescEdit] = useState("")
  const [titleEdit, setTitleEdit] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [updatedData, setUpdatedData] = useState("new")
  const [blankColumn, setBlankColumn] = useState("0")
  const [errors, setErrors] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)

  const [multyResponce, setMultyResponce] = useState({
    REC: {},
    RACD: {},
  })
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const SaveResponce = (keyName, response) => {
    setMultyResponce({ ...multyResponce, [keyName]: response })
  }
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const downloadToggle = () => setDropdownOpen(prevState => !prevState)
  const authToken = JSON.parse(localStorage.getItem("authUser"))
  const toggle = () => {
    setModal(!modal)
    setSelectedFile("")
    setErrors({})
  }
  const toggleEdit = () => {
    setModalEdit(!modalEdit)
    setSelectedFile("")
    setErrors({})
  }
  const onFileChange = ({ target }) => {
    if (!selectedFile) {
      setErrors({})
      const value = target && target.files[0]
      setSelectedFile(value)
    }
  }
  const handleImportData = async e => {
    const id_arr = map(selectedFlatRows, "original.disclosureChangeRequest_id")
    const ids = id_arr.toString()
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("customerorgId",
        checkOrg !== undefined && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "")
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(`${process.env.REACT_APP_BASE_URL}import-excel-recommend-change-disclosure-request-report-organization`, formdata, {
          headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender() 
            setImportModal(false)
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err, navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  const handleImportEditData = async e => {
    e.preventDefault()
    const formdata = new FormData()
    if (!selectedFile) {
      setErrors({ file: "File Required!" })
    } else {
      setErrors({})
      formdata.append("file", selectedFile)
      formdata.append("isBlankUpdatable", blankColumn)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const result = axios
        .post(
          `${process.env.REACT_APP_BASE_URL}import-obligation-update`,
          formdata,
          {
            headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            toastr.success(response.data.message)
            setSelectedFile()
            handleRender()
            toggle()
          }
        })
        .catch(err => {
          RedirectOnUnAuthentication(err, navigateTo)
          toggle()
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 400:
                ToastError(err)
                break
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
  }

  const edit = () => {
    props.history.replace({
      pathname: "/test-obligations/obligation_update",
      state: { rowData: selectedFlatRows && selectedFlatRows[0].original },
    })
  }

  const mutiedit = () => {
    console.log(selectedFlatRows, "selectedFlatRows")
    const data = selectedFlatRows.map(item => item.original)
    history.push("/test-obligations/obligation_muti_edit", { rowData: data })
  }

  const onAllDeleteRecord = e => {
    e.preventDefault()
    setDeleteModal(true)
  }

  const onAllDelete = e => {
    e.preventDefault()
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    const datas = {
      tableName: "Obligation",
      ids: ids,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}delete-data`,
        {data:encryptData(datas)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}`,...HEADER },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          toggleDelete()
          handleRender()
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
              ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
  }

  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }

  const Reload_Empty_Citation = () => {
    POST_API_HANDLER({
      loaderStart: () => ChangeLoader("REC", true),
      loaderClose: () => ChangeLoader("REC", false),
      errorMessage: true,
      ApiEndPoint: `${process.env.REACT_APP_BASE_URL}scan-test-data`,
      setState: response => {
        SaveResponce("REC", response)
      },
      successMessage: true,
      errorMessage: true,
      bodyData: { subType: "empty-citation" },
      navigateTo: navigateTo,
    })
  }

  const Reload_All_Citation_Data = () => {
    POST_API_HANDLER({
      loaderStart: () => ChangeLoader("RACD", true),
      loaderClose: () => ChangeLoader("RACD", false),
      errorMessage: true,
      ApiEndPoint: `${process.env.REACT_APP_BASE_URL}scan-test-data`, //process.env.REACT_APP_BASE_URL
      setState: response => {
        SaveResponce("RACD", response)
      },
      successMessage: true,
      errorMessage: true,
      bodyData: { subType: "all-citation" },
      navigateTo: navigateTo,
    })
  }
  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const obj2 = JSON.parse(localStorage.getItem("orgID"))

      let newCheckOrg

      if (obj2) {
        newCheckOrg = obj2.orgId
      } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
      }

      if (newCheckOrg !== checkOrg) {
        setCheckOrg(newCheckOrg)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
    }
  }, [])
  //   useEffect(()=>{
  //     if (props.location.search.length > 5) {
  //       const Qurry = {}
  //       props.location?.search?.split("?").map((i)=>{Qurry[i?.split("=")[0]]= i?.split("=")[1]})
  //       handleAutocompleteChange("obligations",Qurry.obligations)
  //     }
  // },[])

  const Test = () =>{
    let num =  0
    data.forEach((i)=> i["disclosureChangeRequest_status"] !== 0?num++:"")
    console.log(num);
  }
  return (
    <Fragment>
      <Row className=" d-flex align-items-center" onClick={Test}>
        {/* <Col
          sm={12}
          md={12}
          lg={12}
          xl={4}
          className="py-3 justify-content-end"
        >
          <div className="d-flex flex-wrap align-items-center justify-content-start">
             <select
              className="form-select"
              value={customePageSize}
              onChange={onChangeInSelect}
              style={{ width: "150px", marginLeft: "10px" }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  No of Results {pageSize}
                </option>
              ))}
            </select>
          </div>
         
        </Col> */}


        <Col
          // sm={12}
          // md={12}
          // lg={12}
          // xl={8}
          className="py-3 LDCR_Table_Header"
        >
          <div>
          <TableHeader
            heading={"List of Disclosure Change Request"}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
          </div>
          <div className="d-flex flex-wrap gap-2 justify-content-end">
       
            {obligationAccess &&
            obligationAccess.writeAccess === true &&
            checkOrg === 3 ? (
              selectedFlatRows.length == 1 ? (
                <>
                  <Button
                    onClick={() => edit()}
                    style={{
                      backgroundColor: "#556EE6",
                      // marginLeft: 5,
                      color: "#fff",
                      textTransform: "none",
                      display: "none",
                    }}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                    Edit
                  </Button>
                  {/* <Button
                   onClick={(e) => {
                     onAllDeleteRecord(e)
                   }}
                   style={{
                     backgroundColor: "#556EE6",
                     color: "#fff",
                     textTransform: "none",
                   }}
                 >
                   <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                   Delete
                 </Button> */}
                </>
              ) : (
                // :
                // selectedFlatRows.length >= 1 ? (
                //   <Button
                //     onClick={(e) => {
                //       onAllDeleteRecord(e)
                //     }}
                //     style={{
                //       backgroundColor: "#556EE6",
                //       marginLeft: 5,
                //       marginRight: 5,
                //       color: "#fff",
                //       textTransform: "none",
                //     }}
                //   >
                //     <i className="bx bx-trash font-size-16 align-middle me-1"></i>
                //     Delete
                //   </Button>
                // )
                ""
              )
            ) : (
              ""
            )}
            {selectedFlatRows.length >= 1 && (
              <>
                <Button
                  onClick={() => mutiedit()}
                  style={{
                    backgroundColor: "#556EE6",
                    // marginLeft: 5,
                    color: "#fff",
                    textTransform: "none",
                    display: "none",
                  }}
                >
                  {/* <Link
                          to={{
                            pathname: "/test-obligations/obligation_muti_edit",
                            state: { selectedFlatRows },
                          }}
                          style={{
                            color: "#fff",
                          }}
                        > */}
                  <i className="bx bx-edit-alt font-size-16 align-middle me-1"></i>
                  Multiple Edit
                  {/* </Link> */}
                </Button>
              </>
            )}

            <DeleteModal
              show={deleteModal}
              onDeleteClick={e => onAllDelete(e)}
              onCloseClick={() => setDeleteModal(false)}
            />
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {/* {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg !== 3 ? (
              <Button
                variant="contained" 
                onClick={() => setAddModal(true)}
                style={{
                  backgroundColor: "#556ee6", 
                  color: "#fff",
                  textTransform: "none", 
                }}
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Obligations
              </Button>
            ) : (
              ""
            )} */}

            {/* {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Link
                className="btn btn-primary"
                to="/test-obligations/obligation_update"
              >
                <i className="bx bx-plus font-size-16 align-middle me-1"></i>
                Add Obligation
              </Link>
            ) : (
              "" || ""
            )} */}
            {/* {obligationAccess &&
            obligationAccess.writeAccess == true &&
            checkOrg === 3 ? (
              <Button
                onClick={() => {
                  setModal(!modal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6", 
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )} */}
              {  checkOrg !== 3 ? (
              <Button
                onClick={() => {
                  setImportModal(!importModal)
                  setDesc("Import Data")
                  setTitle("Import Data")
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload
              </Button>
            ) : (
              ""
            )}

           

            <Modal
              isOpen={importModal}
              toggle={toggleImport}
              className="modal-dialog-centered"
              style={{ borderRadius: "50px" }}
            >
              <ModalHeader toggle={toggleImport}>{title}</ModalHeader>
              <ModalBody className="px-4 py-4 text-left">
                <h6>Import Data</h6>
                <div className="form-body mt-3">
                  <input
                    type="file"
                    id="csvFileInput"
                    accept=".xlsx"
                    name="file"
                    onChange={onFileChange}
                    className="form-control mb-2"
                  />
                  <div className="error">{errors.file}</div>
                </div>
                <div className="px-6 mt-3 mb-1 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="button"
                    onClick={e => handleImportData(e)}
                  >
                    Import Data
                  </button>
                </div>
              </ModalBody>
            </Modal>
            {/* {obligationAccess && obligationAccess.writeAccess == true && checkOrg === 3 ?
              <Button
                onClick={() => {
                  setModalEdit(!modalEdit);
                  setDescEdit("Import Data for Update");
                  setTitleEdit("Import Data for Update");
                }}
                style={{
                  backgroundColor: "#556ee6",
                  // marginLeft: 5,
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                <i className="bx bx-upload font-size-16 align-middle me-1"></i>
                Upload for Update
              </Button>
              :
              ""
                } 
              <Modal
                isOpen={modalEdit}
                toggle={toggleEdit}
                className="modal-dialog-centered"
                style={{ borderRadius: "50px" }}
              >
                <ModalHeader toggle={toggleEdit}>{titleEdit}</ModalHeader>
                <ModalBody className="px-4 py-4 text-left">
                  <h6>Import Data for Update</h6>
                  <div className="form-body mt-2">
                    <input
                      type="file"
                      id="csvFileInput"
                      accept=".xlsx"
                      name="file"
                      onChange={onFileChange}
                      className="form-control mb-3"
                    />
                    <div className="error">{errors.file}</div>
                  </div>
                  <div className="px-6 mt-3 mb-1 text-center">
                    <button
                      type="button"
                      className="btn btn-primary"
                      name="button"
                      onClick={(e) => handleImportEditData(e)}
                    >
                      Import Data
                    </button>
                  </div>
                </ModalBody>
              </Modal> */}
            {/* {  
              multyLoader.REC ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={Reload_Empty_Citation}>
                  <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                  Reload Empty Citation
                </button>
              )
           } */}

            {/* {  
              multyLoader.RACD ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <button className="btn btn-primary" onClick={Reload_All_Citation_Data}>
                  <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                  Reload all Citation Data
                </button>
              )
           } */}
{/* selectedFlatRows.length>0 */}
          {/* {checkOrg===3 && downloadAccess && downloadAccess.readAccess === true ?(<>


  {btnLoader  ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <button className="btn btn-primary" onClick={handleExport}>
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Download Pdf
              </button>
            )}
</>) :""} */}



{  downloadAccess && downloadAccess.readAccess === true ?(<>


{btnLoader  ? (
            <div className="ml-5">
              <Spinner className="ms-2 " color="primary" />
            </div>
          ) : (
            <button className="btn btn-primary" onClick={handleExportExcel}>
              <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
              Download Report
            </button>
          )}
</>) :""}
            {/* <button
                type="button"
                className="btn btn-primary "
                // onClick={handleExport}
              >
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Upload
              </button>  */}
            {/* <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="contained-button-file">
                <Buttn
                  variant="contained"
                  style={{
                    backgroundColor: "#556ee6",
                    color: "#fff",
                    textTransform: "none ",
                    fontFamily: "poppins",
                    fontWeight: 400,
                    fontSize: 15
                  }}
                  component="span"
                >
                  <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                  Upload
                </Buttn>
              </label> */}
          </div>
        </Col>
      </Row>
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 600 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span>
                              <br /> */}
                      </div>
                      {/* {console.log(column.id, 'column id')} */}
                      {column.id != "selection" &&
                      column.id != "updatedDate" &&
                      column.id != "disclosureChangeRequest_status" &&
                      column.id != "custDisclosures_text" &&
                      column.id != "disclosure_text" &&
                      column.id != "custDisclosures_oldText" &&
                      column.id != "disclosure_oldText" &&

                      // column.id != "status" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <MuiThemeProvider theme={theme}>
                            <Autocomplete
                              // classes={classes}
                              style={{ width: "70%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              // options={handleNullAndUndefinedOfArray(
                              //   autoFill[column.id] || []
                              // )}
                              options={autoEmpty[column.id] || searchQuery[column.id] ? autoFill[column.id] || [] : []}
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value&&trimLeft(value)) 
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  disabled={loader}
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={handleKeyDown}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          {/* <button
                            onClick={() => {
                              setShowModal(true)
                              setModalData(column)
                            }}
                            // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                            className={
                              selectedHeader.includes(column.Header)
                                ? "filter_button_selected"
                                : "filter_button"
                            }
                          >
                            <i className="bx bx-filter font-size-18"></i>
                          </button> */}
                        </div>
                      ) : column.id == "updatedDate" ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: '2023-05-15',
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                            <button
                                              type="button"
                                              className="btn btn-outline-secondary docs-datepicker-trigger"
                                              // disabled
                                              style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </div> */}
                        </div>
                      ) : column.id == "disclosureChangeRequest_status" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.formControl}
                          >
                            {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
                            <MaterialUISelect
                              className={classes.select}
                              disabled={loader}
                              value={
                                searchQuery["disclosureChangeRequest.status"] != undefined
                                  ? searchQuery["disclosureChangeRequest.status"]
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: false,
                                focused: false,
                              }}
                              displayEmpty
                              onChange={event =>
                                handleValueChange(column.id, event.target.value)
                              }
                            >
                              <MenuItem value="">All</MenuItem>
                              <MenuItem value={0}>
                                {checkOrg != 3
                                  ? "Pending"
                                  : "Awaiting For Client Approval"}
                              </MenuItem>
                              <MenuItem value={1}>
                                {checkOrg != 3 ? "Approved" : "Client Approve"}
                              </MenuItem>
                              <MenuItem value={2}>
                                {checkOrg != 3 ? "Declined" : "Client Decline"}
                              </MenuItem>
                              <MenuItem value={3}>
                                {checkOrg != 3 ? "Edited" : "Edit By Client"}
                              </MenuItem>
                            </MaterialUISelect>
                          </FormControl>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </div> */}
                      {/* </div> */}
                      {/* <Filter column={column} /> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          tableName={
            checkOrg !== "" && checkOrg === 3 ? "Obligation" : "custObligations"
          }
          // tableName={"Obligation"}
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0 ?? false}
        />
      </Styles>
      {/* )} */}
      {/* </div> */}
    </Fragment>
  )
}

const DisclosureChangeRequest = props => {
  //
  const contentRef = useRef(null);

  const [part, setPart] = useState("")
  const [currentPartIndex, setCurrentPartIndex] = useState(0)
  const content = "Your large content string here..."
  const numberOfParts = 5000 // Divide content into 5 parts
  const interval = 5000 // Interval between adding parts (in milliseconds)

  //
  const [fakeData, setFakeData] = useState([])
  const [modal, setModal] = useState(false)
  const [modalTest, setModalTest] = useState(false)
  const [show, setShow] = useState(false)
  const [desc, setDesc] = useState("")
  const [title, setTitle] = useState("")
  const [citationContent, setcitationContent] = useState("")
  const [citationContent2, setcitationContent2] = useState("")
  const [citationContentId, setcitationContentId] = useState("")
  const [citationContentLoader, setCitationContentLoader] = useState(false)
  const [ckEditorErrorShow, setCkEditorErrorShow] = useState(false)
  const toggle = () => setModal(!modal)
  var primaryEditorContent = citationContent2
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [addModal, setAddModal] = useState(false)
  const [reqresData, setReqresData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(false)
  const [delObligation, setDelObligation] = useState(null)
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [filterArray, setFilterArray] = useState({})
  const [selectDisclosure, setSelectDisclosure] = useState([])
  const [customePageSize, setCustomePageSize] = useState(50)
  const [obligationAccess, setObligationAccess] = useState([])
  const [regrId, setRegrID] = useState(null)
  const [selectRegLaw, setSelectRegLaw] = useState([])
  const [organizationID, setOrganizationID] = useState(false)
  const [isCheckOrgSet, setIsCheckOrgSet] = useState(false)
  const [checkOrg, setCheckOrg] = useState()
  const [downloadAccess, setDownloadAccess] = useState([])
  const [checkAdmin, setCheckAdmin] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const callUseNavigate = useHistory()
  const navigateTo = url => {
    callUseNavigate.push(url)
  }
  const [multyLoader, setMultyLoader] = useState({
    REC: false,
    RACD: false,
  })

  const [disclosureChangeModal, setDisclosureChangeModal] = useState(false)
  const [obID, setObId] = useState("")
  const [userListData, setUserListData] = useState({
    title: "",
    discription: "",
    id: "",
  })
  const [LodaerTextUpdated, setLodaerTextUpdated] = useState(false)

  const DisclosureChangeToggle = () => {
    setTextEdit(false)
    setDisclosureChangeModal(!disclosureChangeModal)
  }
  const [textEdit, setTextEdit] = useState(false)

  const location = useLocation()
  const ChangeLoader = (loaderName, value) => {
    const loaderValue = value || false
    setMultyLoader({ ...multyLoader, [loaderName]: loaderValue })
  }
  // console.log(location.state.rowData)

  const CompareModelContent = (oldString,newString) =>{
    function firstHtml() {
      // const oldContent = oldString.replace(/<[^>]*>/g,'')
      console.log("oldData 125456987",oldString);
      return { __html: removeNonBreakingSpaces(oldString) }
    }
  
    function secondHtml() {
      // const newContent = newString.replace(/<[^>]*>/g,'')
      console.log("newData 125456987",newString);
      return { __html: removeNonBreakingSpaces(newString) }
    }
    // console.log("oldString123",firstHtml());
    // console.log("newString123",secondHtml());
    // console.log("HtmlDiffString123",HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)));
    
    console.log("oldString123",oldString);
    console.log("newString123",newString);
    console.log("compareStringsString123",compareStrings(oldString, newString));
    setTimeout(() => {
      setDesc( HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
      // setDesc( HtmlDiff.execute(compareStrings(oldString, newString),newString)),
      // setDesc(compareStrings(oldString, newString) 
      //  ) 
    )
      console.log(HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html) , "Here Is The Html Content"), "+++++++>")
      setTimeout(() => {
        setInnerText(HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)))
      }, 500)
      // setModal(!modal) 
      setShow(false)
    }, 2000)
  }



  useEffect(() => {
    if (location.state && location.state.rowData) {
      setSearchObject({
        ...searchObject,
        ["regLaw"]: location.state.rowData.regShortName,
      })
    }
  }, [location])


  // useEffect(() => {
  //   const org_id = JSON.parse(localStorage.getItem("orgID"));
  //   const authToken = JSON.parse(localStorage.getItem("authUser"));

  //   if(org_id && org_id.orgId === 3){
  //     setCheckAdmin(true)
  //   } else if(authToken && authToken.data.customerorg.id === 3){
  //     setCheckAdmin(true)
  //   }
  // },[])
  function updateStringParts({ originalString, numParts, interval }) {
    let currentIndex = 0
    let parts = []

    // Divide the original string into equal parts
    for (let i = 0; i < numParts; i++) {
      const partLength = Math.ceil(originalString.length / numParts)
      parts.push(originalString.slice(currentIndex, currentIndex + partLength))
      currentIndex += partLength
    }

    // Function to add parts at specified intervals
    const addPartInterval = setInterval(() => {
      // Check if there are remaining parts to add
      if (parts.length > 0) {
        primaryEditorContent += parts.shift() // Add next part to the original string
        setcitationContent2(primaryEditorContent)
        console.log("parts.length", parts.length) // Output updated string
      } else {
        clearInterval(addPartInterval) // Stop interval when all parts added
      }
    }, interval)

    return primaryEditorContent
  }
  const TestSetData = (value, id) => {
    console.log("object open 2546985")
    setcitationContentId(id)
    setcitationContent(value)
    setTimeout(() => {
      setModalTest(true)

      // updateStringParts({ originalString:value, numParts:100, interval:5000})

      ScrollDown()
    }, 2000)
  }
  // const LoadTestSetData = (value, id) => {
  //   setcitationContentId(id)
  //   TestSetData(value, id)
  //   setTimeout(() => {
  //     setModalTest(true)
  //   }, 1000)
  // }

  const toggleCitationModael = () => {
    primaryEditorContent = ""
    setModalTest(!modalTest)
    setShow(!show)
    setcitationContentId("")
    setcitationContent("")
    setCkEditorErrorShow(false)
  }

  const onDisclosureTextUpdated = async () => {
    setLodaerTextUpdated(true)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const data = {
      disId: userListData.title,
      text: userListData.discription,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}change-disclosure-text-organization`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          console.log(response.data.data)
          // setUserListData({
          //   title: disId,
          //   discription: disclosureDesc,
          //   userList: response.data.data,
          // })
          ToastSuccess(response)
          DisclosureChangeToggle()
          fetchData()
          // setButtonRecom(false)
          setLodaerTextUpdated(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        setLodaerTextUpdated(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
    // setLodaerTextUpdated(false)
  }

  const onCLickApprovedDenail = async status => {
    setCitationContentLoader(true)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const requestData = {
      id: userListData.id,
      status: status,
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}update-status-recommend-change-disclosure`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        console.log(response, "obs issue response")
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          toastr.success("successfully status updated")
          setCitationContentLoader(false)
          fetchData()
          DisclosureChangeToggle()
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        setCitationContentLoader(false)
        DisclosureChangeToggle()
        throw message
      })
  }

  const onClickCitationSave = (editedContent, newFunction) => {
    const submitableValue = newFunction ? editedContent : citationContent
    // const submitableValue =   citationContent
    console.log("testCitationContent", RemovePtag(submitableValue))
    if (CheckValidValue({ value: RemovePtag(submitableValue) })) {
      setCitationContentLoader(true)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      const requestData = {
        obligation_id: citationContentId,
        testCitationContent: RemovePtag(submitableValue),
      }

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/update-test-obligation`,
          {data:encryptData(requestData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
              ...HEADER
            },
          }
        )
        .then(response => {
          console.log(response, "obs issue response")
          if (response.status >= 200 || response.status <= 299) {
            // return response.data;
            toastr.success("Citation Content Updated")
            toggleCitationModael()

            setCitationContentLoader(false)
            fetchData()
          }
          // throw response.data;
        })
        .catch(err => {
          RedirectOnUnAuthentication(err, navigateTo)
          var message
          setLoader(false)
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          setCitationContentLoader(false)
          toggleCitationModael()
          throw message
        })
    } else {
      ToastError({ message: "Content Value is require; can`t save empty" })
      setCkEditorErrorShow(true)
      toggleCitationModael()
    }
  }

  console.log(checkOrg, "checkOrg")

  const [sortBy, setSortBy] = useState({
    fieldName: "id",
    order: "ASC",
    tableName: "disclosureChangeRequest",
  })

  useEffect(() => {
    // setSortBy(prevSortBy => ({
    //   ...prevSortBy,
    //   tableName: checkOrg !== undefined  && checkOrg === 3 ? "obligation" : 'custObligations',
    // }));
    if (checkOrg !== undefined && checkOrg === 3) {
      setSortBy({
        tableName: "disclosureChangeRequest",
        fieldName: "id",
        order: "ASC",
      })
    } else if (checkOrg !== undefined && checkOrg !== 3) {
      setSortBy({
        tableName: "disclosureChangeRequest",
        fieldName: "id",
        order: "ASC",
      })
    }
  }, [checkOrg])

  const limit = 20

  const handleSort = column => {
    console.log("sortBy123",sortBy,column.id)
    const isAsc =   sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (column.id !== "selection" && column.id !== "Action") {
      let fileName;
      let tableName;
      if(column.id == "disclosureChangeRequest_obId"){
        fileName = "obId";
        tableName = "disclosureChangeRequest"
      }else if (column.id == "disclosureChangeRequest_disId"){
        fileName = "disId";
        tableName = "disclosureChangeRequest"
      }
      else if (column.id == "customerOrganization_companyName"){
        fileName = "companyName";
        tableName = "customerOrganization"
      }
      else if (column.id == "disclosure_oldText"){
        fileName = "oldText";
        tableName = "disclosure"
      }
      else if (column.id == "custDisclosures_text"){
        fileName = "text";
        tableName = "custDisclosures"
      }
      else if (column.id == "custDisclosures_oldText"){
        fileName = "oldText";
        tableName = "custDisclosures"
      }
      else if (column.id == "disclosure_text"){
        fileName = "text";
        tableName = "disclosure"
      }else{
        fileName = column.id;
        tableName = "disclosureChangeRequest"
      }
      setSortBy({
        tableName: tableName,
        fieldName: fileName,
        order: sortOrder,
      })
    }
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setObligationAccess(authToken && authToken.data.role.rolesPermission[2])
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const onClickDelete = reg => {
    // setOrder(order);
    setDelObligation(reg)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    // console.log(delRegulator, 'reg for delete')
    // setDelete(!is_delete)
    if (delObligation.id) {
      // dispatch(onDeleteObligation(delObligation.id, props.history))
      deleteObligation(delObligation.id)
      setDeleteModal(false)
    }
  }

  const addToggle = () => setAddModal(!addModal)

  const closeAddModal = () => {
    setAddModal(false)
    validation.resetForm()
  }

  function changeKeys(obj) {
    const newObj = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        let newKey
        if(key == "disclosureChangeRequest_obId" || key == "obId"){
          newKey = "disclosureChangeRequest.obId";
        }else if (key == "disclosureChangeRequest_disId" || key == "disId"){
          newKey = "disclosureChangeRequest.disId";
        }
        else if (key == "customerOrganization_companyName" || key == "companyName"){
          newKey = "customerOrganization.companyName";
        }
        else if (key == "disclosure_oldText"){
          newKey = "disclosure.oldText";
        }
        else if (key == "custDisclosures_text"){
          newKey = "custDisclosures.text";
        }
        else if (key == "custDisclosures_oldText"){
          newKey = "custDisclosures.oldText";
        }
        else if (key == "disclosure_text"){
          newKey = "disclosure.text";
        }else{
          newKey = key;
        }
        newObj[newKey] = obj[key];
        // const newKey = key.replace(/[A-Z]/g, '_$&').toLowerCase(); // Convert camelCase to snake_case
        // newObj[newKey] = obj[key];
      }
    }
    return newObj;
  }

  const fetchData = searchQuery => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    console.log('fetchData123123')
    // console.log({searchQuery});
    // let finalData= Object.keys(filterArray).length
    //   ? filterArray
    //   : searchObject

    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData = { ...searchObject, ...filterArray }
    // const data = JSON.stringify({searchQuery})
    // console.log(data,"re-renderd")
    let apiUrl
    if (checkOrg !== "" && checkOrg === 3) {
      apiUrl = `${process.env.REACT_APP_BASE_URL}recommend-change-disclosure-request-report/?limit=${customePageSize}&pageNo=${currentPage}`
    } else {
      apiUrl = `${process.env.REACT_APP_BASE_URL}recommend-change-disclosure-request-report/?limit=${customePageSize}&pageNo=${currentPage}`
    }

    setLoader(true)
    if (Object.keys(filterArray).length > 0) {
      console.log(finalData, "finalData")
      localStorage.setItem("selectedFilterArray", JSON.stringify(finalData))
    } else {
      localStorage.removeItem("selectedFilterArray")
    }
    const params = {
      "disclosureChangeRequest_obId": "disclosureChangeRequest.obId", 

    };
    console.log(finalData,'finalData123')
    const newfinalData = changeKeys(finalData);
     console.log(newfinalData,'newfinalData123')
    const requestData = {
      ...newfinalData,
      customerorgId:
        checkOrg !== "" && checkOrg !== 3
          ? checkOrg !== "" && parseInt(checkOrg)
          : "",
      orderBy: {
        ...sortBy,
      },
    }

    axios
      .post(
        //   checkOrg !== ""  && checkOrg === 3?
        //    `${process.env.REACT_APP_BASE_URL}/get-obligations/?limit=${customePageSize}&pageNo=${currentPage}`
        // : `${process.env.REACT_APP_BASE_URL}/get-obligation-organization/?limit=${customePageSize}&pageNo=${currentPage}`
        apiUrl,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        console.log(response, "obs issue response")
        if (response.status >= 200 || response.status <= 299) {
          // return response.data;
          console.log("response.data.data",response.data.data);
          setReqresData(updateOldText(response?.data?.data??[],checkOrg===3 ))
          // setReqresData([updateOldText( [])])
          var ids = []
          if (response.data.ids !== undefined && response.data.ids !== "") {
            ids = response.data.ids.split(",")
          }
          localStorage.setItem("idsArray", JSON.stringify(ids))
          setTotalItems(response.data.totalRecords)
          if (response.data.isProcessing) {
            setTimeout(() => {
              setMultyLoader("RACD", true)
              setMultyLoader("REC", true)
            }, 7000)
          } else {
            setTimeout(() => {
              setMultyLoader("RACD", false)
              setMultyLoader("REC", false)
            }, 7000)
          }

          setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        setLoader(false)
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  const deleteObligation = obligation => {
    // console.log(regulation, 'for delete')
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}delete-obligation?id=${obligation}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success("Obligation Successfully Deleted.")
          setDelete(!is_delete)
        }
      })
      .catch(err => {
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 400:
              message = err.response.data.message
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        RedirectOnUnAuthentication(err, navigateTo, message)
      })
  }

  useEffect(() => {
    // console.log('page is loading')
    if (checkOrg !== undefined) {
      fetchData(searchObject)
      localStorage.removeItem("formattedData")
    }
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
    checkOrg,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const columns = RemoveFalseElementsFromArray([
    {
      Header: "Obligation ID",
      width: 130,
      accessor: "disclosureChangeRequest_obId",
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          // <Link
          //   to={{
          //     pathname: "/test-obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <span style={{ color: "#000" }}>
            {" "}
            {CheckValidValue({ value: cellProps?.value })
              ? cellProps?.value
              : DisplayJustifyCenter("-")}{" "}
          </span>
          // </Link>
        )
      },
      // width: 150
      // Cell: cellProps => {
      //   return (
      //    cellProps?.value?.length > 15 ?  cellProps?.value?.substr(0, 18) + " ..." : cellProps.value
      //   )
      // }
    },
    {
      Header: "Disclosure ID",
      accessor: "disclosureChangeRequest_disId",
      filterable: false,
      disableFilters: true,
      // width: 200,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          // <Link
          //   to={{
          //     pathname: "/test-obligations/obligation_view",
          //     state: { rowData },
          //   }}
          // >
          <LightTooltip title={cellProps?.value}>
            <span style={{ color: "#000" }} className="StringOverFlow1">
              {CheckValidValue({ value: cellProps?.value })
                ? cellProps.value && cellProps?.value?.length > 15
                  ? cellProps?.value
                  : cellProps.value || ""
                : DisplayJustifyCenter("-")}
            </span>
          </LightTooltip>
          // </Link>
        )
      },
    },
    {
      Header: "Client Name",
      width: 175,
      filterable: false,
      disableFilters: true,
      accessor: "customerOrganization_companyName",
      Cell: cellProps => {
        return (
          <>
            <span style={{ color: "#000" }}>
              {" "}
              {CheckValidValue({ value: cellProps?.value })
                ? cellProps?.value
                : DisplayJustifyCenter("-")}{" "}
            </span>
          </>
        )
      },
    },
    {
      Header: "Old Text",
      width: 125,
      filterable: false,
      disableFilters: true,
      accessor: checkOrg === 3 ? "disclosure_oldText" : "custDisclosures_oldText",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return cellProps.value ? (
          <div>
            <DarkTooltip title="View Details" style={{ width: "15px" }}>
              <div>
                <i
                  onClick={() => {
                    setModal(!modal)
                    console.log("testtext Old text",cellProps.value);
                    // setDesc(cellProps.value)
                    setTimeout(() => {
                      setInnerText(cellProps.value)
                    },500)
                    setTitle("Old Text")
                  }}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "0 5px",
                    borderRadius: "20%",
                    color: "#556ee6",
                  }}
                  className="bx bxs-info-circle  font-size-24"
                  id="descToolTip"
                ></i>
                <div
                  onClick={() => {
                    setModal(!modal)
                    setDesc(cellProps.value)
                  }}
                ></div>
              </div>
            </DarkTooltip>
          </div>
        ): (
          DisplayJustifyCenter("-")  
       )
      },
    },
    ...[
      checkOrg != 3 && {
      Header: "Recommended Text",
      width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "disclosure_text",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        const oldData =  cellProps.row.original[checkOrg === 3 ? "disclosure_oldText" : "custDisclosures_oldText"]
        return cellProps.value ?  (
          <div>
            <DarkTooltip title="View Details" style={{ width: "15px" }}>
              <div>
                <i
                  onClick={() => {
                    setModal(!modal) 
                    setTitle("Recommended Text") 
                    console.log(cellProps.value, "Here Is The New Value,")
                    CompareModelContent(oldData,cellProps.value) 
                    setShow(true)
                  }}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "0 5px",
                    borderRadius: "20%",
                    color: "#556ee6",
                  }}
                  className="bx bxs-info-circle  font-size-24"
                  id="descToolTip"
                ></i>
                <div
                  onClick={() => {
                    setModal(!modal)
                    setDesc(cellProps.value)
                  }}
                ></div>
              </div>
            </DarkTooltip>
          </div>
        ): (
          DisplayJustifyCenter("-")  
       )
      },
    }],
    {
      Header: "New Text",
      accessor: checkOrg === 3 ? "disclosure_text" : "custDisclosures_text",
      filterable: false,
      width: 125,
      disableFilters: true,
      Cell: cellProps => {
        const oldData =  cellProps.row.original[checkOrg === 3 ? "disclosure_oldText" : "disclosure_text"]
        return (cellProps.value && (checkOrg === 3 || cellProps.row.original.disclosureChangeRequest_status != 0)) ? (
          <>
            <DarkTooltip title="View Details" style={{ width: "15px" }}>
              <div>
                <i

                // onClick={() => {
                //   setModal(!modal)
                //   console.log("testtext Old text",cellProps.value);
                //   // setDesc(cellProps.value)
                //   setTimeout(() => {
                //     setInnerText(cellProps.value)
                //   },500)
                //   setTitle("Old Text")
                // }}

                  onClick={() => {
                    setShow(true)
                    CompareModelContent(oldData,cellProps.value)
                    setModal(true)
                    console.log("testtext oldData", oldData);
                    setTitle("New Text")
                  }}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "0 5px",
                    borderRadius: "20%",
                    color: "#556ee6",
                  }}
                  className="bx bxs-info-circle  font-size-24"
                  id="descToolTip"
                ></i>
                <div
                  onClick={() => {
                    setModal(!modal)
                    setDesc(cellProps.value)
                  }}
                ></div>
              </div>
            </DarkTooltip>
          </>
        ): (
          DisplayJustifyCenter("-")  
       )
      },
    },
    ...[
      checkOrg === 3 && {
      Header: "Edit by client",
      width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "custDisclosures_text",
      Cell: cellProps => {
        const rowData = cellProps.value
        const oldData =  cellProps.row.original[checkOrg === 3 ? "disclosure_text" : "custDisclosures_oldText"]
        // console.log(rowData, 'celpops')
        return cellProps.value ?  (
          <div>
            <DarkTooltip title="View Details" style={{ width: "15px" }}>
              <div>
                <i
                  onClick={() => {
                    setModal(!modal)
                    CompareModelContent(oldData,cellProps.value)
                    // setDesc(cellProps.value)
                    setTitle("Edit By Client")
                    setShow(true)
                  }}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    padding: "0 5px",
                    borderRadius: "20%",
                    color: "#556ee6",
                  }}
                  className="bx bxs-info-circle  font-size-24"
                  id="descToolTip"
                ></i>
                <div
                  onClick={() => {
                    setModal(!modal)
                    setDesc(cellProps.value)
                  }}
                ></div>
              </div>
            </DarkTooltip>
          </div>
        ): (
          DisplayJustifyCenter("-")  
       )
      },
    }],
    // {
    //   Header: "Edit by client",
    //   accessor: "custDisclosures_text",
    //   filterable: false,
    //   width: 1,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (cellProps.value && (checkOrg === 3 || cellProps.row.original.disclosureChangeRequest_status != 0)) ? (
    //       <>
    //         <DarkTooltip title="View Details" style={{ width: "15px" }}>
    //           <div>
    //             <i
    //               onClick={() => {
    //                 setModal(!modal)
    //                 setDesc(cellProps.value)
    //                 setTitle("Edit by client")
    //               }}
    //               style={{
    //                 cursor: "pointer",
    //                 textAlign: "center",
    //                 padding: "0 5px",
    //                 borderRadius: "20%",
    //                 color: "#556ee6",
    //               }}
    //               className="bx bxs-info-circle  font-size-24"
    //               id="descToolTip"
    //             ></i>
    //             <div
    //               onClick={() => {
    //                 setModal(!modal)
    //                 setDesc(cellProps.value)
    //               }}
    //             ></div>
    //           </div>
    //         </DarkTooltip>
    //       </>
    //     ): (
    //       DisplayJustifyCenter("-")  
    //    )
    //   },
    // },
    // {
    //   Header: "Reg Law Short Name",
    //   accessor: "regLaw",
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (<>
    //       {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
    //         ? cellProps?.value?.substr(0, 18) + " ..."
    //         : cellProps.value : DisplayJustifyCenter("-") }
    //       </>)
    //   },
    // },
    // {
    //   Header: "Citation",
    //   accessor: "citation",
    //   width: 115,
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (<>
    //       {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
    //         ? cellProps?.value?.substr(0, 18) + " ..."
    //         : cellProps.value : DisplayJustifyCenter("-") }
    //       </>)
    //   },
    // },
    // {
    //   Header: "Obligation Notes",
    //   accessor: "obligationNote",
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (<>
    //       {CheckValidValue({value:cellProps?.value })? cellProps.value && cellProps?.value?.length > 15
    //         ? cellProps?.value?.substr(0, 15) + " ..."
    //         : cellProps.value : DisplayJustifyCenter("-") }
    //       </>)
    //   },
    // },
    {
      Header: "Status",
      accessor: "disclosureChangeRequest_status",
      // width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (
          <Badge
            className={
              cellProps.value === 1
                ? "font-size-11 badge-soft-success"
                : cellProps.value == 2
                ? "font-size-11 badge-soft-danger"
                : "font-size-11 badge-soft-warning"
            }
          >
            {cellProps.value == 0
              ? checkOrg != 3
                ? "Pending"
                : "Awaiting For Client Approval"
              : cellProps.value == 1
              ? checkOrg != 3
                ? "Approved"
                : "Client Approve"
              : cellProps.value == 2
              ? checkOrg != 3
                ? "Declined"
                : "Client Decline"
              : checkOrg != 3
              ? "Edited"
              : "Edit By Client"}
          </Badge>
        )
      },
    },
    // {
    //   Header: "Last Update Date",
    //   accessor: "updatedDate",
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     const date1 =FormatedDate( cellProps.value)
    //     if (cellProps.value) {
    //       return date1
    //     } else {
    //       return "-"
    //     }
    //   },
    // },
    ...[
      checkOrg != 3 && {
        Header: "Action",
        textAlign: "top",
        disableFilters: true,
        sticky: "right",
        filterable: false,
        width: 72,
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <button
              className="btn btn-primary btn-sm"
              style={{ padding: "5px 15px" }}
              disabled={
                cellProps.row.original.disclosureChangeRequest_status != 0
              }
              onClick={() => {
                setUserListData({
                  title: rowData.disclosureChangeRequest_disId,
                  discription: rowData.disclosure_text,
                  id: rowData.disclosureChangeRequest_id,
                })
                DisclosureChangeToggle()
              }}
            >
              {/* <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "} */}
              View
            </button>
          )
        },
      },
    ],
  ])

  const reports = [
    {
      title: "Total Obligations",
      iconClass: "bx-copy-alt",
      description: totalItems && totalItems,
    },
    { title: "New Obligations", iconClass: "bx-archive-in", description: "0" },
    {
      title: "Recent Obligations Changed",
      iconClass: "bx-archive-in",
      description: "0",
    },
    {
      title: "Obligations Effecting Customers",
      iconClass: "bx-purchase-tag-alt",
      description: "0",
    },
  ]

  // console.log(fakeData, "fakeData")
  document.title = "Disclosure Change Request"
  const handleRender = () => {
    setDelete(!is_delete)
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#556ee6" : "white",
        color: isFocused ? "#fff" : "$000",
      }
    },
  }

  const handleObsChange = option => {
    console.log(option.value, "opionss")
    validation.setFieldValue("obIds", option ? option.map(x => x.value) : [])
  }

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (checkOrg !== undefined && addModal) {
      const requestData = {
        customerorgId: checkOrg !== 3 ? checkOrg && parseInt(checkOrg) : "",
      }
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/get-all-regulations`,
          {data:encryptData(requestData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
              ...HEADER
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            console.log(response, "Regulations response")
            const optionData = response.data.data.map(option => ({
              value: option.id,
              label: option.regshortname,
            }))

            const modifiedOptions = [
              { label: "Select Reg Law Short Name", value: "" }, // 0 option
              ...optionData, // API options
            ]

            setSelectRegLaw(optionData)
          }
        })
        .catch(err => {
          var message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          // throw message;
          RedirectOnUnAuthentication(err, navigateTo, message)
        })
    }
  }, [checkOrg, addModal])

  const fetchObligations = () => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const requestData ={
      regulationId: regrId,
      customerorgId: checkOrg && parseInt(checkOrg),
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/get-all-obligations`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
            ...HEADER
          },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          const optionData = response.data.data.map(option => ({
            value: option.id,
            label: `${option.obId} (${
              option.obligationName && option.obligationName.length > 8
                ? option.obligationName.substr(0, 45) + " ..."
                : option.obligationName || "-"
            })`,
          }))

          const modifiedOptions = [
            { label: "Select Obligations", value: "" }, // 0 option
            ...optionData, // API options
          ]

          setSelectDisclosure(optionData)
          // return response.data;
          // setSelectObligation(response.data.data.map(option => ({
          //   value: option.id,
          //   label: `${option.obId} (${option.obligationName && option.obligationName.length > 8
          //     ? option.obligationName.substr(0, 45) + " ..."
          //     : option.obligationName ||'-'
          //   })`
          // }
          // )))
          // setTotalItems(response.data.totalRecords)
          // setLoader(false)
        }
        // throw response.data;
      })
      .catch(err => {
        RedirectOnUnAuthentication(err, navigateTo)
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
  }

  useEffect(() => {
    if (regrId) {
      fetchObligations()
    }
  }, [regrId])

  const handleRegrID = id => {
    if (id === 0 || id === null) {
      setRegrID(null)
      // setSelectDisclosure([{ label: 'Select Regulations', value: 0 }]);
    } else {
      console.log("reg id", id)
      setRegrID(id)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reg_id: "",
      obIds: [],
    },
    validationSchema: Yup.object({
      obIds: Yup.array()
        .of(Yup.string().required("Please Select Obligation(s)"))
        .min(1, "Please Select at least one Obligation"),
    }),
    onSubmit: values => {
      console.log(values, "values")

      const addData = {
        ids: values.obIds.toString(),
        customerorgId: checkOrg,
      }
      console.log("addData", addData)
      const authToken = JSON.parse(localStorage.getItem("authUser"))
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/add-obligation-organization`,
          {data:encryptData(addData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
              ...HEADER
            },
          }
        )
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // setSuccess(true)
            toastr.success("Obligations Successfully Added.")
            setDelete(!is_delete)
            setAddModal(false)
            validation.resetForm()
            return response.data
          }
        })
        .catch(err => {
          let message
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = "Bad request! Already Linked"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          // return message
          // throw message;
          RedirectOnUnAuthentication(err, navigateTo, message)
        })
    },
  })
  useEffect(() => {
    if (modalTest) {
      // Simulate fetching or processing modal content

      // function diffHtml() {
      //   // console.log(firstHtml(), secondHtml())
      // return {
      //   __html: HtmlDiff.execute(firstHtml().__html, secondHtml().__html),
      // }
      // }

      setTimeout(() => {
        setShow(true)
      }, 2000)
    }
  }, [modalTest])

  const setInnerText = (content) => {
    console.log(content, 'this is the content With Spaces.... Verify Here...')
    if (contentRef.current) {
      const encodedContent = content.replace(/<<(.*?)>>/g, "&lt;&lt;$1&gt;&gt;");
      contentRef.current.innerHTML = encodedContent;
    } else {
      console.error("contentRef is not defined or not pointing to a valid DOM element.");
    }
  }

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Obligations" breadcrumbItem="Obligations" /> */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />

          <Modal
            isOpen={modal}
            toggle={toggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody className="text-left">
            {show ? (
          <div className="ml-5 d-flex justify-content-center">
            <Spinner className="ms-2 " color="primary" />
          </div>
        ) : (
               // <div style={{ whiteSpace: "break-spaces" }}  dangerouslySetInnerHTML={{ __html: desc && desc }} onClick={()=>console.log(desc)}></div>
              <div style={{ whiteSpace: "break-spaces" }} ref={contentRef}></div>
             )}    
            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={toggle}>
                Ok
              </Button>{" "}
              {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
            </ModalFooter>
          </Modal>


          <Modal
            isOpen={modalTest}
            toggle={toggleCitationModael}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={toggleCitationModael}>
              Change Prior Citation Content
            </ModalHeader>
            <ModalBody className="text-left">
              {/* <div dangerouslySetInnerHTML={{ __html: desc && desc }}></div> */}

              {show ? (
                <>
                  {/* <CKEditor
                editor={ClassicEditor}
                data={citationContent} 
                // data={citationContent2} 
                config={{
                  removePlugins: [
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                    "EasyImage",
                    "UploadImage",
                  ],
                }}
                onReady={editor => {
                  editor.editing.view.change(writer => {
                    writer.setStyle(
                      "max-height",
                      "300px",
                      editor.editing.view.document.getRoot()
                    )
                  })
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  // validation.setFieldValue('obligationDesc', data);
                  console.log(data, "data")
                  // Slice the string to remove the first <p> tag
                  const modifyData = data //  data.slice( data.indexOf(">") + 1,  data.lastIndexOf("<") )
                  setcitationContent(modifyData)
                }}
              /> */}
                  {/* contentData = {`citationContent ${ShortString}`} */}
                  <EditorWithPagination
                    buttonLoader={citationContentLoader}
                    contentData={citationContent ? citationContent : ""}
                    onSave={editedContent =>
                      !citationContentLoader
                        ? onClickCitationSave(editedContent, true)
                        : () => {}
                    }
                  />
                </>
              ) : (
                <div className="ml-5 d-flex justify-content-center">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              )}
            </ModalBody>
            {/* <ModalFooter className="mx-auto">
              {citationContentLoader ? (
                <div className="ml-5">
                  <Spinner className="ms-2 " color="primary" />
                </div>
              ) : (
                <Button
                  className="px-5"
                  color="primary"
                  onClick={()=>!citationContentLoader? onClickCitationSave():()=>{}}
                >
                  Save
                </Button>
              )}{" "}
              
            </ModalFooter> */}
          </Modal>

          <Modal
            isOpen={addModal}
            // toggle={toggle}
            toggle={addToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={closeAddModal}>
              Add Record from Admin DB
            </ModalHeader>
            <ModalBody className="text-left">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="form-group">
                  <Row>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">
                          Select Reg Law Short Name
                        </Label>
                        <Select
                          options={selectRegLaw}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="reg_id"
                          // value={selecData.find(option => option.label  == validation.values.ob_id ) }
                          value={selectRegLaw.find(
                            option => option.value === validation.values.reg_id
                          )}
                          onChange={option => {
                            validation.setFieldValue("reg_id", option.value)
                            handleRegrID(option.value)
                            validation.setFieldValue("ob_id", "") // Reset regulationId to 0 when regulatorId changes
                          }}
                        />
                        {validation.touched.reg_id &&
                        validation.errors.reg_id ? (
                          <div className="error">
                            {validation.errors.reg_id}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <div className="mb-3">
                        <Label className="form-label">Select Obligations</Label>
                        <Select
                          options={selectDisclosure}
                          styles={customStyles}
                          // defaultValue={selectedJuris && selectedJuris}
                          name="obIds"
                          isMulti
                          value={selectDisclosure.filter(option =>
                            validation.values.obIds.includes(option.value)
                          )}
                          // value={selectDisclosure.find(
                          //   option => option.value === validation.values.obIds
                          // )}
                          onChange={handleObsChange}
                          onBlur={validation.handleBlur}
                          isDisabled={!validation.values.reg_id}
                        />
                        {validation.touched.obIds && validation.errors.obIds ? (
                          <div className="error">{validation.errors.obIds}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs="12" md="12">
                  <div className="text-end">
                    <Button type="submit" className="me-2" color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={closeAddModal}
                      className="btn btn-secondary waves-effect waves-light "
                      color="secondnary"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Form>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={disclosureChangeModal}
            toggle={DisclosureChangeToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={DisclosureChangeToggle}>
              <div style={{ display: "flex", gap: "2px" }}>
                Disclosure Change Request -{" "}
                <div style={{ fontWeight: "bold" }}>{userListData.title}</div>
              </div>
            </ModalHeader>
            <ModalBody className="text-left">
              {loader || citationContentLoader ? (
                <div
                  className="container-fluid mt-5 mb-5"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner className="ms-2" color="primary" />
                </div>
              ) : (
                <div>
                  {!textEdit && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: InnerText(userListData.discription),
                      }}
                    ></div>
                  )}
                  {textEdit ? (
                    <>
                      <CKEditor
                        editor={ClassicEditor}
                        // data={validation.values.text || ''}
                        data={
                          // (userListData.discription &&
                          //   userListData.discription) ||
                          // ""
                          InnerText(userListData.discription)
                        }
                        config={{
                          removePlugins: [
                            "ImageCaption",
                            "ImageStyle",
                            "ImageToolbar",
                            "ImageUpload",
                            "MediaEmbed",
                            "EasyImage",
                            "UploadImage",
                          ],
                        }}
                        onReady={editor => {
                          editor.editing.view.change(writer => {
                            writer.setStyle(
                              "min-height",
                              "200px",
                              editor.editing.view.document.getRoot()
                            )
                          })
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          setUserListData({
                            discription: data,
                            title: userListData.title,
                            id: userListData.id,
                          })
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "10px",
                        }}
                      >
                        {LodaerTextUpdated ? (
                          <div className="ml-5">
                            <Spinner className="ms-2 " color="primary" />
                          </div>
                        ) : (
                          <Button
                            className="p-2"
                            color="primary"
                            onClick={onDisclosureTextUpdated}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </>
                  ) : null}
                  {!textEdit && (
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => setTextEdit(true)}
                      >
                        Edit
                      </Button>
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => onCLickApprovedDenail(1)}
                      >
                        Approve
                      </Button>
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => onCLickApprovedDenail(2)}
                      >
                        Decline
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </ModalBody>
            {/* <ModalFooter className="mx-auto">
              <Button
                className="px-5"
                color="primary"
                onClick={DisclosureChangeToggle}
              >
                Ok
              </Button>{" "}
            </ModalFooter> */}
          </Modal>

     
          {/* <Row>
            {reports.map((report, key) => (
              <Col className="col-xl-3 col-md-6" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody
                    className="custome-card"
                    style={{ padding: "10px 11px !important" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <h6 className="text-muted">{report.title}</h6>
                      </div>
                      <div
                        className="avatar-sm bg-primary align-self-center mini-stat-icon"
                        style={{ borderRadius: "100%" }}
                      >
                        <span
                          className="avatar-title bg-primary"
                          style={{ borderRadius: "100%" }}
                        >
                          <p className="mb-0 font-size-18">
                            {report.description}
                          </p>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  {/* <div className="row">
                    <div className="col-md-6">
                    <ReduceSpan/>
                    </div>
                  </div> */}
                  <TableContainer
                    setLoader={setLoader}
                    columns={columns}
                    data={reqresData}
                    searchObject={searchObject}
                    checkOrg={checkOrg}
                    setCheckOrg={setCheckOrg}
                    checkAdmin={checkAdmin}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    setAddModal={setAddModal}
                    addModal={addModal}
                    filterArray={filterArray}
                    obligationAccess={obligationAccess}
                    downloadAccess={downloadAccess}
                    setFilterArray={setFilterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    props={props}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    setSearchObject={setSearchObject}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    setMultyLoader={setMultyLoader}
                    multyLoader={multyLoader}
                    ChangeLoader={ChangeLoader}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalItems}
                        pageSize={customePageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DisclosureChangeRequest)

const ShortString = `Pakistan Election Result 2024 Live Updates: Following a national election on Thursday, Pakistan experienced notable delays in the vote count, prompting the country's election commission to issue a late-night warning to polling officers, urging them to promptly release results, even ten hours after polls had closed. Pakistan's political landscape is predominantly shaped by three major parties: Pakistan Muslim League-Nawaz (PML-N), Pakistan Tehreek-e-Insaf (PTI), and Pakistan People's Party (PPP).

Pakistan Election Result 2024 Live: PTI-backed independent candidates ‘win’ 47 seats, Geo News reported.
Pakistan Election Result 2024 Live: PTI-backed independent candidates ‘win’ 47 seats, Geo News reported.(REUTERS)
The Election Commission of Pakistan began announcing results more than nine hours after voting came to an end.

The National Assembly, comprised of 336 seats, sees 266 candidates elected through direct voting, while the remaining 70 seats are reserved. Among these reserved seats, 60 are designated for women and 10 for non-Muslims, allocated based on each party's representation in the Assembly. Securing a simple majority requires a minimum of 133 seats, but it's speculated by many analysts that the election outcome might not yield a decisive winner.

Key highlights so far:

Initially, former Pakistan Prime Minister Shehbaz Sharif, representing the PML-N party led by his brother Nawaz Sharif, secured victory in the NA-123 seat from Lahore, contributing to the party's total of 18 seats.
However, despite PML-N's performance, independent candidates supported by the jailed Imran Khan-led PTI party emerged victorious in 27 parliamentary seats.
The PPP, led by Bilawal Bhutto-Zardari, managed to win 20 seats as of 3 pm and others won five seats.
Zafar Iqbal, the special secretary at the Election Commission of Pakistan (ECP), attributed the delay in announcing results to an "internet issue," which caused the first official results for a constituency to be announced more than ten hours after polling closed.
Imran Khan's Pakistan Tehreek-e-Insaf (PTI) party encouraged individuals to remove passwords from their personal WiFi networks via a social media post, aiming to facilitate internet access for anyone nearby.
With the counting process continuing throughout the night, a clearer electoral landscape is expected to emerge by early Friday.`
