import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import toastr from "toastr"
import Flatpickr from "react-flatpickr"
import { Select, MenuItem, FormControl } from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import $ from "jquery"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout,
  useRowSelect,
} from "react-table"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { useSticky } from "react-table-sticky"
import Tooltip from "@material-ui/core/Tooltip"
import {
  Table,
  Row,
  Col,
  Input,
  CardBody,
  Button,
  UncontrolledTooltip,
  Badge,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Filter, DefaultColumnFilter } from "../../../../components/Common/filters"
import Pagination from "../../../../components/Common/Pagination"
import styled from "styled-components"
import { createMuiTheme } from "@material-ui/core/styles"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { format } from "date-fns"
import { Popper } from "@material-ui/core"
import FilterModal from "../../../../components/FilterModal/FilterModal"
import axios from "axios"
import TableHeader from "components/Common/TableHeader"
import ReactDiffViewer from "react-diff-viewer"
import Prism from "prismjs"
import XLSX from "xlsx"
import FileSaver from "file-saver"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { map } from "lodash" 
import HtmlDiff from "htmldiff-js"
import PDFGenerator from "./PdfComponent"
import "./diff.css"
import { CheckValidValue, DisplayJustifyCenter, DownloadExelFrom, FormatedDate, ModifyObjectForAutoEmpty, RedirectOnUnAuthentication, RemoveSpaces, RenameKeysOfObject, SeparateObjectByValueType, ValidObject, convertObjectValuesToArray, encryptData, handleNullAndUndefinedOfArray, hexHtmlToString, removeKeysFromObject, trimLeft } from "helpers/helper_function"
import { LOCAL_API_END_POINT } from "helpers/general_constant"
import { error } from "jquery"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
// import $ from "jquery";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    const [done, setDone] = React.useState(true)
    return (
      <>
        <input
          type="checkbox"
          value={done}
          onChange={e => setDone(e.target.value)}
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

IndeterminateCheckbox.displayName = "IndeterminateCheckbox"

const exportToExcel = rowData => {
  const customizedData = rowData.map(row => { 
    console.log(row, "row")
    const newRow = removeKeysFromObject(row,["newReport" ])
    return {
      ...newRow,
      obId: CheckValidValue({value:row.obId})? row.obId:"-",
      citationUrl:  CheckValidValue({value:row.citationUrl})? row.citationUrl:"-",
      error: CheckValidValue({value:row.error})? row.error:"-",
       
    }
  })
  const filteredData = customizedData.map(
    ({ id, obligation, citationCount, ...rest }) => rest
  )
  const arrangedColumns = [
    "obId", 
    "citationUrl",
    "error", 
  ]
  const worksheet = XLSX.utils.json_to_sheet(filteredData, {
    header: arrangedColumns,
  })
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [
        "Obligation ID", 
        "Citation URL",
        "Error", 
      ],
    ],
    { origin: "A1" }
  )
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  })
  FileSaver.saveAs(blob, "Test Error Reg Content Change Report.xlsx")
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :first-child {
        .td {
          padding-top: 10px;
        }
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      padding-left: 10px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 100px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`
const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 110,
  },

  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
})

const PopperMy = function (props) {
  // console.log(props, 'props')
  return (
    <Popper
      {...props}
      style={{ width: 250, color: "red" }}
      placement="bottom-start"
    />
  )
}

// const highlightSyntax = str => (
//   <pre
//     style={{ display: 'inline' }}
//     dangerouslySetInnerHTML={{
//       __html: Prism.highlight(str, Prism.languages.javascript),
//     }}
//   />
// );

const TableContainer = ({
  loader,
  columns,
  reqresData,
  filterArray,
  setSortBy,
  sortBy,
  handleSort,
  queryId,
  searchObject,
  downloadAccess,
  handleRender,
  props,
  customePageSize,
  setCustomePageSize,
  fetchData,
  dateQuery,
  setSearchObject,
  setCurrentPage,
  setFilterArray,
  data,
  pageCount: customePageCount,
  customPageSize,
  searchQuery, setSearchQuery,
  autoEmpty,setAutoEmpty
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      // disableRowSelect: true,
      manualPagination: true,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: false,
        //   },
        // ],
      },
      pageCount: customePageCount,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    // useFlexLayout,
    useBlockLayout,
    useSticky,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        // {
        //   id: "linktoDisc",
        //   sticky: "left",
        //   width: 80,

        //   // The header can use the table's getToggleAllRowsSelectedProps method
        //   // to render a checkbox
        //   Header: () => <div>Linked to Disc.</div>,
        //   // The cell can use the individual row's getToggleRowSelectedProps method
        //   // to the render a checkbox
        //   Cell: ({ row }) => (
        //     <div style={{ backgroundColor: "#fff" }}>
        //       {row.original.obligation.disclosure_obligations_obligation
        //         .length > 0
        //         ? "Y"
        //         : "N"}
        //     </div>
        //   ),
        // },
        {
          id: "selection",
          sticky: "left",
          width: 37,

          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div style={{ backgroundColor: "#fff" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const [btnLoader, setBtnLoader] = useState(false) 

  const [autoFill, setAutoFill] = useState({})
  const [selectedDate, setSelectedDate] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [selectedHeader, setSelectedHeader] = useState([])
  const [localStorageFilter, setLocalStorageFilter] = useState({})
  const [btnLoaderExcel, setBtnLoaderExcel] = useState(false)
  const callUseNavigate = useHistory()
const navigateTo = (url) =>{
  callUseNavigate.push(url)
}
  const handleExportExcel = () => {

    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoaderExcel(true)
  
    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()  
    const params = { 
      
        "obligation.citationUrl":"newErrorTestObligationReport.citationUrl",
        "error":"newErrorTestObligationReport.error",
        "obligation.obId":"newErrorTestObligationReport.obId",

        // Add more mappings as needed
    };
      const rawObject =    { 
        ...searchObject,
        ...filterArray,
        reportId: queryId,
        ids: ids,
        orderBy: {
          ...sortBy,
        },}
  
        
      const data = RenameKeysOfObject({object:rawObject, params:params}) 
      // obligationIds,
      // disclosureIds,
 
    // setBtnLoaderExcel(false)
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/export-excel-new-error-test-obligation-report`,
        {data:encryptData(data)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
          responseType:'blob'
        }
      )
      .then(response => {
        // console.log("data14568",response.data.data);
        // exportToExcel(response.data.data) 
        DownloadExelFrom({responseData:response.data,fileTitle: "Test Error Reg Content Change Report"})
        setBtnLoaderExcel(false)
      })
      .catch(error => {
        // toastr.error(error, "Error Exporting Data")
        RedirectOnUnAuthentication(error,navigateTo,"Error Exporting Data")
        console.log(error);
        setBtnLoaderExcel(false)
      })
  }

  const handleExport = () => {
    console.log("export function")
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setBtnLoader(true)

    const id_arr = map(selectedFlatRows, "original.id")
    const ids = id_arr.toString()
    console.log(ids, "selectedFlatRows")

    const data = {
      ...searchObject,
      ...filterArray,
      reportId: queryId,
      ids: ids,
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}get-download-report`, {data:encryptData(data)}, {
        headers: {
          Authorization: `Bearer ${authToken.data.authToken}`,
        },
        responseType: "blob",
      })
      // .then(response => {
      //   // exportToExcel(response.data.data)
      //   setBtnLoader(false)
      //   response.json()
      // })
      .then(response => {
        setBtnLoader(false)
        toastr.success("Report Downloaded Successfully.")
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "Test Error Reg Content Change Report.pdf") //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        // toastr.error(error, "Error Exporting Data")
        setBtnLoader(false)
      })
  }

  useEffect(() => {
    const data = localStorage.getItem("tier-updated-obli-report")
    if (CheckValidValue({value:data})) {
       setLocalStorageFilter(CheckValidValue({value:data}))
    } 
   
    let finalData
    console.log(
      props?.location?.state?.rowData?.clear,
      "props?.location?.state?.rowData?.clear"
    )
    if (data != null && !props?.location?.state?.rowData?.clear) {
      finalData = JSON.parse(data)
      let modifiedObject = {}
      console.log(finalData,'finalData')
      for (let key in finalData) {
        if (finalData.hasOwnProperty(key)) {
          let newKey;
          let selectedkey;
      
          if (key === "newErrorTestObligationReport.obId") {
            newKey = "newErrorTestObligationReport.obId";
            selectedkey = "obId"
          } 
          else if (key === "newErrorTestObligationReport.regulationSite" || key === "regulationSite"){
            newKey = "regulationSite";
            selectedkey = "newErrorTestObligationReport.regulationSite"
          }
          else if (key === "newErrorTestObligationReport.citationUrl" || key === "citationUrl"){
            newKey = "citationUrl";
            selectedkey = "newErrorTestObligationReport.citationUrl"
          }
          else if (key === "newErrorTestObligationReport.error" || key === "error"){
            newKey = "error";
            selectedkey = "newErrorTestObligationReport.error"
          }
          else {
            newKey = key;
            selectedkey = key;
          }
           // Check if the value is an array
           if (Array.isArray(finalData[key])) {
            // If it's an array, take the first element
            modifiedObject[newKey] = finalData[key][0];
          } else if (typeof finalData[key] === "string") {
            // If it's already a string, keep it as it is
            modifiedObject[newKey] = finalData[key];
          } else {
            console.log(`Value for key ${key} is not a valid string.`);
          }

          const selectedFilterArray = JSON.parse(localStorage.getItem("selectedFilterArray"));
          if (selectedFilterArray && selectedFilterArray.hasOwnProperty(selectedkey)) {
              delete modifiedObject[newKey];
          }
        }
      }
      console.log(modifiedObject,'modifiedObject')
      setAutoEmpty(modifiedObject)
    }
  }, [localStorage.getItem("tier-updated-obli-report")])

  const handleSearchQueryChange = (fldName, value) => {
    // console.log(fldName, value, "check handleSearchQueryChange and value")
    console.log("handleSearchQueryChange open case",fldName, value)
    const authToken = JSON.parse(localStorage.getItem("authUser"))

    let columnName
    if (fldName === "obligation.obligationDesc") {
      columnName = "obligationDesc"
    } else if (fldName === "obligation.obligationName") {
      columnName = "obligationName"
    } else if (fldName === "obligation.citationUrl") {
      columnName = "citationUrl"
    } else if (fldName === "obligation.citation") {
      columnName = "citation"
    } else if (fldName === "obligation.regLaw") {
      columnName = "regLaw"
    } else if (fldName === "prevUpdatedDate") {
      const newDate = new Date(value)
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      setSelectedDate(formattedDate)

      setSearchQuery(prev => ({ ...prev, [fldName]: formattedDate }))
    } else if (fldName === "obId") {
      columnName = "obId"
      const updatedSearchQuery = {
        ...searchQuery,
        ["newErrorTestObligationReport.obId"]: value,
      }
      setSearchQuery(updatedSearchQuery)
    }
    else {
      columnName = fldName
    }

    console.log(columnName, value, "check")
    const ID_Array = CheckValidValue({value: localStorage.getItem("idsArray")})?JSON.parse(localStorage.getItem("idsArray")):[]
    const requestData = {
      tableName: "newErrorTestObligationReport",
      reportId: queryId,
      ids:ID_Array,
      feildName: columnName ==="obligation.obId"?"obId":columnName,
      searchValue: value ? value : "",
      relationFeild:
        columnName === "obligationName" ||
        columnName === "citationUrl" ||
        columnName === "obligation.obId" ||
        fldName === "obligation.obligationDesc" ||
        fldName === "obligation.regLaw" ||
        fldName === "obligation.citation" ||
        fldName === "oldobligationDesc"
          ? "obligation"
          : "",
      isUnlink: false,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}autosearch?limit=1000000&pageNo=1`,
        {data:encryptData(requestData)},
        {
          headers: {
            Authorization: `Bearer ${authToken.data.authToken}`,
          },
        }
      )
      .then(response => {
        console.log(response,  "12548965")
        if (fldName != "prevUpdatedDate") {
          setAutoFill(prevAutoFill => ({
            ...prevAutoFill,
            [fldName]: response.data.data.map(item => item[columnName ==="obligation.obId"?"obId":columnName]),
          }))
        }
      })
      .catch(error => {
        RedirectOnUnAuthentication(error,navigateTo)
        setAutoFill(prevAutoFill => ({
          ...prevAutoFill,
          [columnName ==="obligation.obId"?"obId":columnName]: [],
        }))
      })
  }

  const handleAutocompleteChange = (columnId, value) => { 
    console.log("handleAutocompleteChange outside case",columnId, value)
    const optionExists = autoFill[columnId] && autoFill[columnId].includes(value);
    if (optionExists || value === "") { 

      let updatedValue
    let columnName
    // console.log(columnId, typeof(columnId));
    // console.log(columnId == "obId", 'check', 'newErrorTestObligationReport.obId');
    if (value == "" || value == null) {
      console.log("handleAutocompleteChange null case",columnId, value)
      updatedValue = "" // Set the value to blank when selecting "All"
      // console.log("**************");
    } else {
      console.log("handleAutocompleteChange normal case",columnId, value)
      updatedValue = value
    }

    if (columnId == "obligation.obId") {
      console.log(columnId == "obId", "check", "newErrorTestObligationReport.obId")
      columnName = "newErrorTestObligationReport.obId"
      setAutoEmpty(prev => ({ ...prev, ["newErrorTestObligationReport.obId"]: updatedValue }))
      //  updatedValue = value
    } 
    else if (columnId == "regulationSite") {
      columnName = "newErrorTestObligationReport.regulationSite"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["regulationSite"]: updatedValue }))
    } 
    else if (columnId == "obligationDesc") {
      columnName = "newErrorTestObligationReport.obligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["obligationDesc"]: updatedValue }))
    } 
    else if (columnId == "error") {
      columnName = "newErrorTestObligationReport.error"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["error"]: updatedValue }))
    } 
    else if (columnId == "oldobligationDesc") {
      columnName = "newErrorTestObligationReport.oldobligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue }))
    } else if (columnId == "citationUrl") {
      columnName = "newErrorTestObligationReport.citationUrl"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["citationUrl"]: updatedValue }))
    } else if (columnId == "prevUpdatedDate") {
      // Set the value to false when selecting "No"
      // console.log(value, 'value')

      const newDate = new Date(value)
      console.log(newDate, "newDate")
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      updatedValue = formattedDate
      columnName = "newErrorTestObligationReport.prevUpdatedDate"
    } else {
      updatedValue = value // Set the value to true when selecting "Yes"
      columnName = columnId
    }

    // console.log(columnName,updatedValue, "updated alue 11")
    const updatedSearchQuery = { ...searchQuery, [columnName]: [updatedValue] }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    } else {

      let updatedValue
    let columnName
    // console.log(columnId, typeof(columnId));
    // console.log(columnId == "obId", 'check', 'newErrorTestObligationReport.obId');
    if (value == "" || value == null) {
      updatedValue = "" // Set the value to blank when selecting "All"
      // console.log("**************");
    } else {
      updatedValue = value
    }

    if (columnId == "obligation.obId") {
      console.log(columnId == "obId", "check", "newErrorTestObligationReport.obId")
      columnName = "newErrorTestObligationReport.obId"
      setAutoEmpty(prev => ({ ...prev, ["obId"]: updatedValue }))
      //  updatedValue = value
    } else if (columnId == "obligationDesc") {
      columnName = "newErrorTestObligationReport.obligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["obligationDesc"]: updatedValue }))
    } else if (columnId == "regulationSite") {
      columnName = "newErrorTestObligationReport.regulationSite"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["regulationSite"]: updatedValue }))
    } else if (columnId == "error") {
      columnName = "newErrorTestObligationReport.error"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["error"]: updatedValue }))
    } else if (columnId == "oldobligationDesc") {
      columnName = "newErrorTestObligationReport.oldobligationDesc"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["oldobligationDesc"]: updatedValue }))
    } else if (columnId == "citationUrl") {
      columnName = "newErrorTestObligationReport.citationUrl"
      updatedValue = value
      setAutoEmpty(prev => ({ ...prev, ["citationUrl"]: updatedValue }))
    } else if (columnId == "prevUpdatedDate") {
      // Set the value to false when selecting "No"
      // console.log(value, 'value')

      const newDate = new Date(value)
      console.log(newDate, "newDate")
      const formattedDate = format(newDate, "yyyy-MM-dd")
      console.log(formattedDate, "formattedDate")
      updatedValue = formattedDate
      columnName = "newErrorTestObligationReport.prevUpdatedDate"
    } else {
      updatedValue = value ?? "" // Set the value to true when selecting "Yes"
      columnName = columnId
    }

    console.log(columnName,updatedValue, "updated alue")
    const updatedSearchQuery = { ...searchQuery, [columnName]: updatedValue }
    handleSearchQueryChange(columnId, updatedValue)
    setAutoEmpty(prev => ({ ...prev, [columnName]: updatedValue }))
    setSearchQuery(updatedSearchQuery)
    setSearchObject(updatedSearchQuery)
    }
    
  }

  const handleKeyDownDate = (columnId, e) => {
    if (e.keyCode === 27 || e.keyCode === 8) {
      // Clear the selected date on Escape or Backspace key press
      // dateQuery(null)
      setSelectedDate(null)
      const updatedSearchQuery = {
        ...searchQuery,
        ["newErrorTestObligationReport.prevUpdatedDate"]: "",
      }
      setSearchQuery(updatedSearchQuery)
      setSearchObject(updatedSearchQuery)
    }
  }

  const clearFilter = () => {
    setSearchQuery({})
    setSelectedHeader([])
    setAutoEmpty({})
    setAutoFill({})
    setFilterArray({})
    setSearchObject({})
    setSelectedDate(null)
    setCurrentPage(1)
    setSortBy({
      tableName: "newErrorTestObligationReport",
      fieldName: "regulationSite",
      order: "ASC",
    })
    // fetchData({})
    localStorage.removeItem("tier-updated-obli-report")
    localStorage.removeItem("selectedFilterArray")
    localStorage.removeItem("idsArray")
    localStorage.removeItem("tier-updated-obli-report-filter-array")
    localStorage.removeItem("tier-updated-obli-report-search-object")
    // dateQuery({})
    const textFields = document.querySelectorAll('input[type="text"]')
    textFields.forEach(textField => {
      textField.value = ""
    })
  }
  // console.log('autoEmpty', autoEmpty);
  // console.log('searchQuery', searchQuery);
  const onChangeInSelect = event => {
    // setPageSize(Number(event.target.value))
    setCustomePageSize(Number(event.target.value))
  }

  const theme = createTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: "#fff", // Set your desired background color here
          border: "1px solid #ced4da",
          borderBottom: 0,
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          // '&&[class*="MuiOutlinedInput-root"] $input': {
          //   padding: "1px"
          // },
          "& .MuiAutocomplete-inputRoot": {
            color: "purple",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type':
              {
                // Default left padding is 6px
                padding: 26,
              },
          },
        },
      },
    },
  })

  const classes = useStyles()

  const [modal, setModal] = useState(false) 

  const toggle = () => {
    setModal(!modal)
  }

  // const classes = useStyles();

  const SelectedFilterIcon = (filterArray,column_id)=>{
    const columnId = `${column_id}`.split(".").pop()

          try {
              

              const IsColumnIdSelected = JSON.parse(localStorage.getItem("selectedFilterArray") || "[]")?.[columnId] || []
              const IsColumnIdSelectedObj = JSON.parse(localStorage.getItem("selectedFilterArray") ) || {}
              const IsColumnIdExistInFilterArray = Object.keys(IsColumnIdSelectedObj||{}).some((i)=>i.includes(`${column_id}`.split(".").pop(columnId)))  
             console.log("ooooooooooooooooooooo",  IsColumnIdSelectedObj)
              if (IsColumnIdExistInFilterArray && IsColumnIdSelected.length !=0) {
                return true
              } else {
                return false
              } 
          } catch (error) {
            console.log("ooooooooooooooooooooo", "error",error)
            return false
    }
    

  }

  // useEffect(()=>{
  //   console.log("test_12345 searchQuery",searchQuery);
  //  const storageSearchData =  localStorage.getItem("tier-updated-obli-report-search-object")
  //  if (storageSearchData) {
  //   const  localStorageData = JSON.parse(storageSearchData)
  //   console.log("test_12345",SeparateObjectByValueType(localStorageData));
  //   const {singleRecordArrayObjectConvertedWithStingValue,stringValuesObject,stringValuesObjectConvertedWithArrayValue,forAutoEmpty} = SeparateObjectByValueType(localStorageData)
  //   setAutoEmpty(forAutoEmpty)
  //   setSearchQuery(stringValuesObjectConvertedWithArrayValue)
  //  }  
  // },[])

  const test2 = () =>{
    console.log("autoEmpty test_1234========>",autoEmpty, )
    console.log("searchObject test_123========>",searchObject )
    console.log("filterArray test_123========>",filterArray )
    console.log("searchQuery test_123========>",searchQuery )
    console.log("autoFill test_123========>",autoFill )
     const storageSearchData =  localStorage.getItem("tier-updated-obli-report-search-object")
   if (storageSearchData) {
    const  localStorageData = JSON.parse(storageSearchData)
    console.log("test_123",SeparateObjectByValueType(localStorageData));
    const {singleRecordArrayObjectConvertedWithStingValue,stringValuesObject,stringValuesObjectConvertedWithArrayValue} = SeparateObjectByValueType(localStorageData)
    // setAutoEmpty(stringValuesObject)
    // setSearchQuery(stringValuesObjectConvertedWithArrayValue)
    console.log("test_123 stringValuesObject", stringValuesObject); 
    console.log("test_123 singleRecordArrayObjectConvertedWithStingValue",singleRecordArrayObjectConvertedWithStingValue, ); 
  } 


     const elements = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
     for (let i = 0; i < elements.length; i++) {
      // Attach an onClick event handler to each element
      elements[i].addEventListener("click", function() {
        // Your onClick logic here
        console.log("test_1234Element clicked:", elements[i].textContent);
      });
    }


    $(".MuiAutocomplete-clearIndicator").click(function() {
      // Your onClick logic here
      console.log("test_1234Element clicked:", $(this).text());
    });
    ;
  }
  return (
    <Fragment>
      <Row className="pt-3" > 
        <Col
          xs={12}
          md={12}
          lg={12}
          xl={6}
          className="d-flex flex-column flex-lg-row gap-3 align-items-center"
        >
          {/* <div className="d-flex  align-items-center ">
              <h5 className="font-size-18 mb-0">Updated Obligation Report</h5>
              <select
                className="form-select"
                value={customePageSize}
                onChange={onChangeInSelect}
                style={{width : "150px", marginLeft: "10px"}}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    No of Results {pageSize}
                  </option>
                ))}
              </select>
            </div> */}

          {/* <div className="d-flex justify-content-start ">
           
            </div> */}
          <TableHeader
            heading={""}
            onChangeInSelect={onChangeInSelect}
            customePageSize={customePageSize}
          />
        </Col>
        {/* {console.log(headerGroups && headerGroups[0].headers[2], "headerGroups")} */}
        <Col
          sm={12}
          md={12}
          lg={12}
          xl={6}
          className="justify-content-end py-2"
        >
          <div className="d-flex flex-wrap gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-primary "
              onClick={clearFilter}
            >
              <i className="mdi mdi-filter-remove-outline font-size-16 align-middle me-1"></i>{" "}
              Clear Filter
            </button>
            {/* {downloadAccess && downloadAccess.readAccess === true ? (
                 btnLoader ? (
                    <div className="ml-5">
                      <Spinner className="ms-2 " color="primary" />
                    </div>
                  ) : (
                      <button 
                      className="btn btn-primary"
                      onClick={handleScan}
                      >
                         <i className="mdi mdi-radar font-size-16 align-middle me-1"></i>{" "}
                           Scan Now
                      </button>
                  )
                  )
                  :
                  ("")
                  } */}

            {btnLoaderExcel ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <button className="btn btn-primary" onClick={handleExportExcel}>
                <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                Download Report
              </button>
            )}
            {/* {btnLoader ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <PDFGenerator
              data={`<div >
                <div>
                  <div class="row"> 
                      <div class="col-12 col">
                          <div>
                              <h4 >Updated Obligation Detail</h4>
                              
                          </div>
                      </div>
                  </div>
                  `}
            />
            )} */}
          </div>
        </Col>
      </Row>

      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <div
            className="sticky table"
            {...getTableProps()}
            style={{ height: 500 }}
          >
            <div className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map(headerGroup => (
                <div
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map(column => (
                    // <th className="table-light" key={column.id} {...column.getHeaderProps()}>
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th"
                      // onClick={()=>console.log("test_123 column.id",column.id,searchQuery,autoEmpty)}
                    >
                      {/* <div> */}
                      <div
                        className="mb-2 mt-0"
                        {...column.getSortByToggleProps()}
                        onClick={() => handleSort(column)}
                      >
                        {column.render("Header")}
                        {/* <span style={{ color: "#556ee6" }}>
                                {" "}
                                {generateSortingIndicator(column)}
                              </span>
                              <br /> */}
                      </div>
                      {/* {console.log(column.id, 'colims')} */}
                      {column.id != "selection" &&
                      column.id !== "linktoDisc" &&
                      column.id !== "obligationDesc" &&
                      column.id !== "oldobligationDesc" &&
                      column.id !== "prevUpdatedDate" &&
                      column.Header !== "Action" ? (
                        <div
                          style={{
                            width: "100%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                          onClick={()=>console.log("autoEmpty",autoEmpty,[column.id])}
                        > 
                          <MuiThemeProvider theme={theme} >
                            <Autocomplete
                              // classes={classes}
                              style={{ width: "75%" }}
                              PopperComponent={PopperMy}
                              disabled={loader}
                              // disableClearable={true}
                              // options={autoFill[column.id] || []}
                              options={handleNullAndUndefinedOfArray(autoFill[column.id ]||[])}
                              
                              // getOptionLabel={(option) => option || ""}
                              value={autoEmpty[column.id=="obligation.obId"?"newErrorTestObligationReport.obId":column.id] || ""}
                              classes={{
                                option: classes.option,
                              }}
                              freeSolo
                              onChange={(event, value) =>
                                handleAutocompleteChange(column.id, value&&trimLeft(value))
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Search..."
                                  disabled={loader}
                                  variant="filled"
                                  size="small"
                                  // className="form-control"
                                  className={classes.root}
                                  value={searchQuery[column.id] || ""}
                                  onChange={e =>
                                    handleSearchQueryChange(
                                      column.id,
                                      e.target.value
                                    )
                                  }
                                  // onKeyDown={handleKeyDown}
                                  // disableClearable={true}
                                  InputLabelProps={{
                                    shrink: false,
                                    focused: false,
                                  }}
                                />
                              )}
                            />
                          </MuiThemeProvider>
                          {
                                column.id !== "obligationDesc" 
                                && column.id !== "obligation.obligationDesc" ?
                                (
                              <button
                                onClick={() => {
                                  setShowModal(true)
                                  setModalData(column)
                                }}
                                disabled={loader}
                                className={
                                  // selectedHeader.includes(column.Header)
                                  SelectedFilterIcon(filterArray,column.id) 
                                    ? "filter_button_selected"
                                    : "filter_button"
                                }
                                // style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px"}}
                              >
                                <i className="bx bx-filter font-size-18"></i>
                              </button>

                                )
                              :
                              ("")
                              }
                        </div>
                      ) : column.id == "prevUpdatedDate" ? (
                        <div
                          style={{
                            width: "80%",
                            position: "absolute",
                            bottom: "10px",
                            display: "flex",
                          }}
                        >
                          <Flatpickr
                            className="form-control  d-block flatpickr-input"
                            placeholder="Select Date..."
                            disabled={loader}
                            style={{
                              fontSize: "12px",
                              paddingLeft: 4,
                              paddingBottom: 4,
                              paddingTop: 8,
                              borderBottom: "1px solid #000",
                            }}
                            onChange={e =>
                              handleAutocompleteChange(column.id, e)
                            }
                            value={selectedDate}
                            options={{
                              dateFormat: "Y-m-d",
                              // defaultDate: "2023-07-19",
                            }}
                            onKeyDown={e => handleKeyDownDate(column.id, e)}
                          />

                          <div
                            className="icon-container"
                            style={{ position: "absolute", right: 6, top: 8 }}
                          >
                            {/* Replace with Boxicons or Font Awesome icon component */}
                            <i
                              className="fa fa-calendar"
                              // aria-hidden="true"
                            />
                          </div>
                          {/* <div className="input-group-append">
                                              <button
                                                type="button"
                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                // disabled
                                                style={{backgroundColor: "#fff", border: "1px solid #ADB5BD", borderRadius: "0px 4px 4px 0px", padding:5}}
                                              >
                                                <i
                                                  className="fa fa-calendar"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                            </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {loader ? (
              <div
                className="container-fluid mt-5 mb-5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : rows.length > 0 ? (
              <div {...getTableBodyProps()} className="body">
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <div {...row.getRowProps()} className="tr">
                        {row.cells.map(cell => {
                          return (
                            <div
                              key={cell.id}
                              {...cell.getCellProps({
                                style: {
                                  backgroundColor: "#fff",
                                },
                              })}
                              className="td"
                            >
                              {cell.render("Cell")}
                            </div>
                          )
                        })}
                      </div>
                    </Fragment>
                  )
                })}
              </div>
            ) : (
              <Row className="mt-5">
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "150px 0",
                    fontSize: "25px",
                  }}
                >
                  No Records Found
                </Col>
              </Row>
            )}
          </div>
        </div>
        <FilterModal
          setSelectedHeader={setSelectedHeader}
          filterArray={filterArray}
          setFilterArray={setFilterArray}
          isShowModal={showModal}
          filterData={modalData}
          setShowModal={setShowModal}
          tableName="newErrorTestObligationReport"
          relationFeild={`${
            modalData.id === "obligation.obligationName" ||
            modalData.id === "obligation.obligationDesc" ||
            modalData.id === "obligation.citationUrl"
              ? "obligation"
              : ""
          }`}
          isUnlink={false}
          isObliReport={true}
          isRemoveDuplicates={true}
          emptyTable={data?.length == 0??false}

        />
      </Styles>
      {/* )} */}
    </Fragment>
  )
}

const LazyLoadedModal = ({ isOpen, toggle, title, desc }) => {
  const [modalContent, setModalContent] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      // Simulate fetching or processing modal content
      // You can replace this with your actual data loading logic
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        setModalContent(desc)
      }, 500) // Adjust the delay as needed
    }
  }, [isOpen, desc])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      style={{ borderRadius: "50px" }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="text-left">
        {loading ? (
          <div className="ml-5 d-flex justify-content-center">
            <Spinner className="ms-2 " color="primary" />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        )}
      </ModalBody>
      <ModalFooter className="mx-auto">
        <Button className="px-5" color="primary" onClick={toggle}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const LazyLoadedModalNew = ({
  isOpen,
  toggle,
  title,
  desc,
  oldString,
  newString,
}) => {
  const [modalContent, setModalContent] = useState({})
  const [show, setShow] = useState(false)

  function firstHtml() {
    // const oldContent = oldString.replace(/<[^>]*>/g,'')
    return { __html: oldString }
  }

  function secondHtml() {
    // const newContent = newString.replace(/<[^>]*>/g,'')
    return { __html: newString }
  }

  useEffect(() => {
    if (isOpen) {
      // Simulate fetching or processing modal content

      // function diffHtml() {
      //   // console.log(firstHtml(), secondHtml())
      // return {
      //   __html: HtmlDiff.execute(firstHtml().__html, secondHtml().__html),
      // }
      // }

      setTimeout(() => {
        setModalContent({
          __html: HtmlDiff.execute(hexHtmlToString(firstHtml().__html), hexHtmlToString(secondHtml().__html)),
        })
        setShow(true)
      }, 2000)
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      style={{ borderRadius: "50px" }}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="text-left" style={{ overflowX: "auto" }}>
        <div className="inner">
          {show ? (
            <div dangerouslySetInnerHTML={modalContent} />
          ) : (
            <div className="ml-5 d-flex justify-content-center">
              <Spinner className="ms-2 " color="primary" />
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="mx-auto">
        <Button className="px-5" color="primary" onClick={toggle}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const NewObliReport = props => {
  const [modal, setModal] = useState(false)
  const [newModal, setNewModal] = useState(false)
  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [loader, setLoader] = useState(true)
  const [disclosureDetail, setDisclosureDetail] = useState([])
  const [is_delete, setDelete] = useState(false)
  const [searchObject, setSearchObject] = useState({})
  const [queryId, setQueryId] = useState()
  const [filterArray, setFilterArray] = useState({})
  const [searchQuery, setSearchQuery] = useState({})
  const [autoEmpty, setAutoEmpty] = useState({})
  const [customePageSize, setCustomePageSize] = useState(50)
  const [downloadAccess, setDownloadAccess] = useState([])
  const [oldString, setOldString] = useState("")
  const [newString, setNewString] = useState("")
  const [sortBy, setSortBy] = useState({})
  const [modalContent, setModalContent] = useState("")
  const [newModalContent, setNewModalContent] = useState("")
  const [newModalTitle, setNewModalTitle] = useState("")
  const [dateCreate, setDateCreate] = useState("")
  const dispatch = useDispatch()
  const location = useLocation()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  // $(function () {
  //   $('#tab-links a').click(function () {

  //     // Check for active
  //     $('.nav-link').removeClass('active');
  //     $(this).addClass('active');

  //     // Display active tab
  //     let currentTab = $(this).attr('href');
  //     $('.tab-pane').hide();
  //     $(currentTab).show();

  //     return false;
  //   });
  // });

  const limit = 20

  console.log(props, "props")

  useEffect(() => {
    setDateCreate(props?.location?.state?.rowData?.createdDate)
  }, [])

  useEffect(() => {
    console.log(
      props?.location?.state?.rowData?.clear,
      "props?.location?.state?.rowData?.clear"
    )
    if (props?.location?.state?.rowData?.clear) {
      localStorage.removeItem("tier-updated-obli-report")
      localStorage.removeItem("selectedFilterArray")
    }
  }, [props?.location?.state?.rowData?.clear])

  useEffect(() => {
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setDownloadAccess(authToken && authToken.data.role.rolesPermission[5])
  }, [])

  useEffect(() => {
    setSortBy({
      tableName: "newErrorTestObligationReport",
      fieldName: "regulationSite",
      order: "ASC",
    })
  }, [])

  const handleSort = column => {
    console.log(column, "handleSort")
    let columnName
    if (column.id === "obligation.obligationDesc") {
      columnName = "obligationDesc"
    } else if (column.id === "obligation.obligationName") {
      columnName = "obligationName"
    } else if (column.id === "obligation.citationUrl") {
      columnName = "citationUrl"
    } else if (column.id === "obligation.citation") {
      columnName = "citation"
    } else if (column.id === "obligation.regLaw") {
      columnName = "regLaw"
    } else if (column.id === "obligation.obId") {
      columnName = "obId"
    } else {
      columnName = column.id
    }
    const isAsc = sortBy.fieldName === columnName && sortBy.order === "ASC"
    const sortOrder = isAsc ? "DESC" : "ASC"
    if (
      column.id !== "selection" &&
      column.id !== "Action" &&
      column.id !== "linktoDisc"
    ) {
      setSortBy({
        tableName:
          columnName === "obligationName" ||
          columnName === "obligationDesc" ||
          columnName === "citationUrl" ||
          columnName === "citation" ||
          columnName === "regLaw"
            ? "obligation"
            : "newErrorTestObligationReport",
        fieldName: columnName,
        order: sortOrder,
      })
    }
  }

  const customePageCount = Math.ceil(totalItems / limit)

  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)
  const DarkTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(255, 255, 255, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  const toggle = data => {
    setModal(!modal)
  }

  const newToggle = () => {
    setNewModal(!newModal)
  }

  const openModal = useCallback(content => {
    setModal(true)
    setModalContent(content)
    setTitle("Prior Citation Content")
  }, [])

  const openNewModal = useCallback((oldData, content) => {
    setNewModal(true)
    setOldString(oldData)
    setNewString(content)
    // setNewModalContent(diffHtml()); // You may need to re-generate the diff content here
    setNewModalTitle("New Citation Content")
  }, [])

  const columns = [
    {
      Header: "Obligation ID",
      accessor: "obligation.obId",
      width: 200,
      filterable: false,
      disableFilters: true,
      sticky: "left",
      Cell: cellProps => {
        return (<>   { CheckValidValue({value:cellProps?.value})?cellProps?.value:DisplayJustifyCenter("-")  }</>)
      },
    },
    // {
    //   Header: "Obligation Name",
    //   accessor: "obligation.obligationName",
    //   // width: 100,
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (
    //       <LightTooltip title={cellProps.value}>
    //         <span style={{ color: "#000" }}>
    //           {cellProps?.value?.substr(0, 30) + " ..."}
    //         </span>
    //       </LightTooltip>
    //     )
    //   },
    // },
    // {
    //   Header: "Reg Law Short Name",
    //   accessor: "obligation.regLaw",
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return cellProps.value && cellProps?.value?.length > 15
    //       ? cellProps?.value?.substr(0, 18) + " ..."
    //       : cellProps.value || "-"
    //   },
    // },
    {
      Header: "Regulation Site",
      accessor: "regulationSite",
      // width: 115,
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return (<>   { CheckValidValue({value:cellProps?.value})?cellProps?.value:DisplayJustifyCenter("-")  }</>)
      },
    },
    {
      Header: "Citation URL",
      // width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "citationUrl",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div>
          {  CheckValidValue({value:cellProps?.value})
          ?  <Link to={{ pathname: cellProps.value }} target="_blank">
              <LightTooltip title={rowData}>
                <div
                className="StringOverFlow1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "13px",
                    color: "blue",
                  }}
                >
                  {(cellProps.value &&
                    cellProps.value
                      .replaceAll("https://", "")
                      .replaceAll("http://", "")
                      .replaceAll("www.", "")   ) ||
                    ""}
                </div>
              </LightTooltip>
            </Link>
            :DisplayJustifyCenter("-")}  
           
          </div>
        )
      },
    },
    // {
    //   Header: "Prior Updated Date",
    //   accessor: "prevUpdatedDate",
    //   // width: 400,
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => { 
    //     if (cellProps.value) {
    //       return <div className="text-align-center">{date1}</div>
    //     } else {
    //       return <div style={{ paddingLeft: "100px" }}>-</div>
    //     }
    //   },
    // },
    // {
    //   Header: "Prior Citation Content",
    //   accessor: "oldobligationDesc",
    //   // width: 100,
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     // setOldString(cellProps.value)
    //     // console.log(cellProps, "cellProps")
    //     const date = cellProps.row.original.prevUpdatedDate 
    //     // firstHtml(cellProps.value)
    //     // handleCitation(cellProps.value);
    //     return cellProps.value ? (
    //       <DarkTooltip
    //         title="View Details"
    //         style={{ width: "64%", textAlign: "center" }}
    //       >
    //         <div style={{ textAlign: "center" }}>
    //           {/* <span>{date1}</span> */}
    //           <i
    //             onClick={() => {
    //               // setModal(!modal)
    //               // setOldString(cellProps.value)
    //               // setDesc(cellProps.value)
    //               openModal(cellProps.value)
    //             }}
    //             style={{
    //               cursor: "pointer",

    //               padding: "0 5px",
    //               borderRadius: "20%",
    //               color: "#556ee6",
    //             }}
    //             className="bx bxs-info-circle  font-size-24"
    //             id="descToolTip"
    //           ></i>
    //         </div>
    //       </DarkTooltip>
    //     ) : (
    //       "-"
    //     )
    //   },
    // },
    // {
    //   Header: "New Citation Content",
    //   accessor: "obligationDesc",
    //   // width: 100,
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     // setNewString(cellProps.value)
    //     // console.log(cellProps, "new props")
    //     const oldData = cellProps.row.original.oldobligationDesc
    //     return cellProps.value ? (
    //       <DarkTooltip
    //         title="View Details"
    //         style={{ width: "64%", textAlign: "center" }}
    //       >
    //         <div>
    //           <i
    //             // onClick={() => {
    //             //   setNewModal(!newModal)
    //             //   setOldString(oldData)
    //             //   setNewString(cellProps.value)
    //             //   setTitle("New Citation Content")
    //             //   openNewModal(oldData,cellProps.value)
    //             // }}
    //             onClick={() => openNewModal(oldData, cellProps.value)}
    //             style={{
    //               cursor: "pointer",
    //               textAlign: "center",
    //               padding: "0 5px",
    //               borderRadius: "20%",
    //               color: "#556ee6",
    //             }}
    //             className="bx bxs-info-circle  font-size-24"
    //             id="descToolTip"
    //           ></i>
    //         </div>
    //       </DarkTooltip>
    //     ) : (
    //       "-"
    //     )
    //   },
    // },
    {
      Header: "Error",
      // width: 125,
      filterable: false,
      disableFilters: true,
      accessor: "error",
      Cell: cellProps => {
        const rowData = cellProps.value
        // console.log(rowData, 'celpops')
        return (
          <div> 
              <LightTooltip title={rowData}>
                <div
                  
                >
                 { CheckValidValue({value:cellProps?.value})?cellProps?.value:DisplayJustifyCenter("-")  }
                  
                </div>
              </LightTooltip> 
          </div>
        )
      },
    },
    {
      Header: "Action",
      textAlign: "top",
      sticky: "right",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const rowData = cellProps.row.original
        return (
          <Badge className="font-size-15  badge-soft-primary" pill>
            <DarkTooltip title="View">
              <Link
                to={{
                  pathname: "/tier-updated-obli-report-view",
                  state: { rowData: { ...rowData, queryId,createdDate:props?.location?.state?.rowData?.createdDate } },
                }}
                onClick={() => {
                  const orderData = cellProps.row.original
                  // console.log(orderData, 'data')
                  // handleOrderClick(orderData);
                }}
              >
                <i
                  //   onClick={() => handleView(value)}
                  style={{ color: "blue", cursor: "pointer" }}
                  className="mdi mdi-eye-outline  font-size-18"
                  id="customerViewtooltip"
                ></i>
              </Link>
            </DarkTooltip>
          </Badge>
        )
      },
    },
  ]

  useEffect(() => {
    console.log(location.state, "locationData")
    if (location.state && location.state.rowData) {
      console.log(location.state.rowData, "locationData")
      // setSearchObject({...searchObject, reportId : locationData.id})
      setQueryId(location.state.rowData.id)
    }
  }, [])

  const fetchData = searchObject => {
    console.log("object1 2558");
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    setLoader(true)
    // let finalData = Object.keys(filterArray).length ? filterArray : searchObject
    // const searchDataObject = convertObjectValuesToArray(searchObject)||{}
    let finalData =   {...searchObject,...filterArray}
    let data = localStorage.getItem("tier-updated-obli-report")
    if (data != null) {
      console.log("data0 2558");
      let parsedData = JSON.parse(data)
      finalData = {  ...parsedData, ...finalData }
      // if (Object.keys(finalData).length > 0) {
      //   if (
      //     !(Object.keys(finalData).length < Object.keys(data).length) ||
      //     !(Object.keys(finalData).length > Object.keys(data).length)
      //   ) {
      //     data = finalData
      //     console.log(data, "data1")
      //   } else {
      //     finalData = data
      //     console.log(data, "data2")
      //   }
      // } else {
      //   // finalData = data
      //   console.log(data, "data32222222222211111111")
      //   console.log( SeparateObjectByValueType(data), "data32222222222211111111111") 
      //   const { singleRecordArrayObject, multipleRecordArrayObject, stringValuesObject } =  SeparateObjectByValueType(data);

      //   setSearchObject({...stringValuesObject,...singleRecordArrayObject})
      //   setSearchQuery({...stringValuesObject,...singleRecordArrayObject})
      //   setFilterArray({...multipleRecordArrayObject})
      
      //   const ObjectForSearchQuery = ModifyObjectForAutoEmpty({...stringValuesObject,...singleRecordArrayObject})
      //   setAutoEmpty(ObjectForSearchQuery)
      // }
    }
  
    // if (Object.keys(searchObject).length > 0) {
     
    //   const {singleRecordArrayObjectConvertedWithStingValue,stringValuesObject} = SeparateObjectByValueType(searchObject)
    //     localStorage.setItem("tier-updated-obli-report-search-object", JSON.stringify({...stringValuesObject,...singleRecordArrayObjectConvertedWithStingValue}))
    // }
    // if (Object.keys(filterArray).length > 0) { 
    //    localStorage.setItem("tier-updated-obli-report-filter-array", JSON.stringify({...filterArray}))
    // }

    if (Object.keys(finalData).length > 0) {
      console.log("object3 2558");
      // console.log(finalData, "finalData")
      
      localStorage.setItem("tier-updated-obli-report", JSON.stringify(finalData))
    }
    //  else {
    //   localStorage.removeItem("tier-updated-obli-report")
    // }
    console.log("object4 2558");
    const params = { 
      
      "obligation.citationUrl":"newErrorTestObligationReport.citationUrl",
      "error":"newErrorTestObligationReport.error",
      "regulationSite":"newErrorTestObligationReport.regulationSite"
      // Add more mappings as needed
  };
    const rawObject =   {
      ...finalData,
      reportId: queryId,
      orderBy: {
        ...sortBy,
      },
    }

    const requestData = RenameKeysOfObject({object:rawObject, params:params}) 

    if (queryId) {
      console.log("object5 2558");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}new-error-test-obligation-report/?limit=${customePageSize}&pageNo=${currentPage}`,
          {data:encryptData(requestData)},
          {
            headers: {
              Authorization: `Bearer ${authToken.data.authToken}`,
            },
          }
        )
        .then(response => {
          console.log("object response41 2558");
          if (response.status >= 200 || response.status <= 299) {
            // return response.data;
            console.log("object response42 2558"); 
            setDisclosureDetail(ValidObject({object:response?.data?.data}))
            // setOldString(response.data.data[0].oldobligationDesc)
            // setNewString(response.data.data[0].obligationDesc)
            console.log(response.data.data)
            var ids = []
            if (response.data.ids !== undefined && response.data.ids !== "") {
              ids = response.data.ids.split(",")
              console.log("object response43 2558");
            }
            console.log("object totalRecords 25589",response.data);
            localStorage.setItem("idsArray", JSON.stringify(ids))
             
            setTotalItems(response?.data?.totalRecords ) 
          }
          setLoader(false)
          
          // throw response.data;
        })
        .catch(err => {
          console.log("object response51 2558");
          var message
          setLoader(false)
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message = "Sorry! Network Error(Status:404)"
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team, Status-500   "
                break
              case 401:
                message = "You are not authorized to view this data."
                break
              default:
                message = err[1]
                break
            }
          }
          throw message
        })
    }
    console.log("object6 2558");
  }

  const test = () =>{
    console.log(filterArray, "filterArray test data3.1")
    console.log(searchObject, "searchObject test data3.1")
  }
  useEffect(() => {
    fetchData(searchObject)
  }, [
    currentPage,
    is_delete,
    searchObject,
    filterArray,
    customePageSize,
    sortBy,
  ])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchObject, filterArray, customePageSize])

  const handleRender = () => {
    setDelete(!is_delete)
  }

  document.title = "Test Error Reg Content Change Report"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Scanned Report"
            // breadcrumbItem={updateTitle}
            path="tier-new-obli-report"
            breadcrumbItem={`Test Error Reg Content Change Report - ${FormatedDate(dateCreate)}`}
          />
          {modal && (
            <LazyLoadedModal
              isOpen={modal}
              toggle={() => setModal(false)}
              title={title}
              desc={modalContent}
            />
          )}
          {newModal && (
            <LazyLoadedModalNew
              isOpen={newModal}
              toggle={() => setNewModal(false)}
              oldString={oldString}
              newString={newString}
              title={newModalTitle}
              desc={newModalContent}
            />
          )}
          {/* <Modal
            isOpen={newModal}
            toggle={newToggle}
            className="modal-dialog-centered"
            style={{ borderRadius: "50px" }}
          >
            <ModalHeader toggle={newToggle}>{title}</ModalHeader>
            <ModalBody className="text-left">
              <div className="inner">

                <div dangerouslySetInnerHTML={diffHtml()} />
              </div>

            </ModalBody>
            <ModalFooter className="mx-auto">
              <Button className="px-5" color="primary" onClick={newToggle}>
                Ok
              </Button>{" "}
            </ModalFooter>
          </Modal> */}
          <Row onClick={test}>
            <Col xs="12">
              <Card>
                <CardBody className="table-class">
                  <TableContainer
                  searchQuery={searchQuery}
                   setSearchQuery={setSearchQuery}
                   autoEmpty={autoEmpty}
                   setAutoEmpty={setAutoEmpty}
                    toggle={toggle}
                    queryId={queryId}
                    setModal={setModal}
                    fetchData={fetchData}
                    handleRender={handleRender}
                    handleSort={handleSort}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    downloadAccess={downloadAccess}
                    searchObject={searchObject}
                    setSearchObject={setSearchObject}
                    filterArray={filterArray}
                    customePageSize={customePageSize}
                    setCustomePageSize={setCustomePageSize}
                    setFilterArray={setFilterArray}
                    setCurrentPage={setCurrentPage}
                    columns={columns}
                    data={disclosureDetail}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    loader={loader}
                    props={props}
                  />
                  <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                      <Pagination
                        className="pagination-bar"
                        // name={console.log({ totalItems })}
                        currentPage={currentPage||1}
                        totalCount={totalItems||1}
                        pageSize={customePageSize||1}
                        onPageChange={page => setCurrentPage(page)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(NewObliReport)
