import toast from "toastr"
import React from "react"
import { Spinner } from "reactstrap"
import * as moment from "moment-timezone"
// import * as moment from "moment"
import XLSX from 'xlsx';
import axios from "axios"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"

import CryptoJS from "crypto-js"

const JSONTokenData = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser"))
  : { data: { authToken: "" } }
const authToken = `Bearer ${JSONTokenData.data.authToken}`

export const CheckValidValue = ({
  value,
  defaultValue,
  notCheck,
  isReplace,
  replceFormula,
}) => {
  if (["null", null, undefined, "undefined", ""].includes(value)) {
    return defaultValue ? defaultValue : false
  } else {
    return notCheck ? value : true
  }
}

export const NotNull = (value, defaultValue) => {
  if (["null", null, undefined, "undefined", ""].includes(value)) {
    return defaultValue ? defaultValue : ""
  } else {
    return value
  }
}

export function RemoveFalseElementsFromArray(arrayData) {
  console.log("RemoveFalseElementsFromArray", arrayData)
  const array = [...arrayData]
  return array.filter(
    element => element !== false && CheckValidValue({ value: element })
  )
}
export function trimLeft(str) {
  // Match one or more whitespace characters at the beginning of the string
  // Replace them with an empty string
  return str.trim();
}
export function handleNullAndUndefinedOfArray(arr) {
  // console.log(arr, "Here We Are Getting Arr ++++++>")
  const handledArray = arr.map(value => {
    if (value === null || value === undefined) {
      return [];
    } else {
      return value;
    }
  });
  return handledArray;
}

export const ValidCellValue = ({ value, defaultValue, other }) => {
  const cellValueLimit = 32000
  const cellValue = value
    ? value.replace(/<[^>]+>/g, "").slice(0, cellValueLimit)
    : "-"

  if (
    [
      "null",
      null,
      undefined,
      "undefined",
      "",
      "<html><body>null</body></html>",
    ].includes(cellValue)
  ) {
    return "-"
  } else {
    return cellValue
  }
}

export const ToastError = error => {
  console.log("ToastError", error)

  if (error?.response?.status === 401) {
    console.log("RedirectOnUnAuthentication........error.........", error)
    window.location.href = "/login"
  } else {
    error?.response?.data?.message.query ||
    error?.response?.data?.message?.level
      ? toast.error(error?.message)
      : error?.response?.data?.message
      ? toast.error(error?.response?.data?.message)
      : error?.message
      ? toast.error(error?.message)
      : error
      ? toast.error(error)
      : toast.error("Something Going Wrong")
  }

  return null
}

export const ToastSuccess = response => {
  response?.data?.data?.message
    ? toast.success(response?.data?.message)
    : response?.data?.message
    ? toast.success(response?.data?.message)
    : response?.message
    ? toast.success(response?.message)
    : response?.message
    ? toast.success(response?.message)
    : toast.success("Api call successfuly")

  return null
}

export const ToastWarning = warning => {
  warning?.response?.data?.message.query ||
  warning?.response?.data?.message?.level
    ? toast.warning(warning?.message)
    : warning?.response?.data?.message
    ? toast.warning(warning?.response?.data?.message)
    : warning?.message
    ? toast.warning(warning?.message)
    : warning
    ? toast.warning(warning)
    : toast.warning("Something going wrong")
  return null
}

export function RemoveTestContentFromArray(array) {
  return array.map(obj => {
    // Extract the keys excluding "testCitationCount" and "testCitationContent"
    const filteredKeys = Object.keys(obj).filter(
      key => key !== "testCitationCount" && key !== "testCitationContent"
    )

    // Create a new object with only the desired keys
    const newObj = Object.fromEntries(filteredKeys.map(key => [key, obj[key]]))

    return newObj
  })
}
export const FormatedDate = (dateString, withTime) => {
  // const properDate = moment.utc(new Date(dateString)).subtract(5, 'hours').format("MMM DD Y hh:mm a")
  //  moment.tz("EST").utc(new Date(cellProps.value)).format(
  //   "MMM DD Y hh:mm a"
  // )

  if (withTime) {
    const properDate = moment.utc(new Date(dateString)).tz('EST').format("L h:mm:ss a")
    // const properDate = moment.utc(new Date(dateString)).format("L h:mm:ss a")
    return properDate
  } else {
    const properDate = moment.utc(new Date(dateString)).tz('EST').format("L")
    // const properDate = moment.utc(new Date(dateString)).format("L")

    //moment(new Date(dateString)).format("L")
    return properDate
  }
}
export const ScrollDown = class_name => {
  // setChatMessage("");
  setTimeout(() => {
    scrollingDiv.scrollTop = scrollingDiv.scrollHeight
  }, 200)
}

export function SaperateObjectBasedOnValueLength(array, givenParticularLength) {
  function splitString(str, length) {
    return typeof str === "string"
      ? str.match(new RegExp(".{1," + length + "}", "g")) || []
      : [str]
  }

  for (let i = 0; i < array.length; i++) {
    const obj = array[i]

    let longestKey = ""
    let longestValueLength = 0

    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        typeof obj[key] === "string" &&
        obj[key].length > longestValueLength
      ) {
        longestKey = key
        longestValueLength = obj[key].length
      }
    }

    const addableObjects =
      Math.ceil(longestValueLength / givenParticularLength) - 1

    if (addableObjects > 0) {
      for (let j = 0; j < addableObjects; j++) {
        const newObj = {}

        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            newObj[key] =
              splitString(obj[key], givenParticularLength)[j + 1] || ""
          }
        }

        array.splice(i + j + 1, 0, newObj)
      }
    }

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = splitString(obj[key], givenParticularLength)[0] || ""
      }
    }
  }

  return array
}

// Example usage
// const givenParticularLength = 30
// const modifiedArray = modifyObjects(yourArray, givenParticularLength)
// console.log(modifiedArray)
function getLastWord(input) {
  // If the input string contains ".", split it
  if (input.includes(".")) {
    // Split the string by "."
    const parts = input.split(".")
    // Return the last element of the array
    return parts[parts.length - 1]
  } else {
    // If there is no ".", return the input string as it is
    return input
  }
}

export function returnValidObject(obj) {
  if (typeof obj === "object" && obj !== null && !Array.isArray(obj)) {
    return obj
  } else {
    return {}
  }
}

function removeObjectWithMatchingValue(obj, stringToRemove) {
  const newObj = returnValidObject({ ...obj })
  for (let key in newObj) {
    if (newObj[key] === stringToRemove) {
      delete newObj[key]
    }
  }
  return newObj
}
export function removeDuplicatesRecords(
  array,
  isRemoveDuplicates,
  lableName,
  firstTimeLoading
) {
  let LABLE_NAME = getLastWord(lableName)
  console.log("isRemoveDuplicates", array, lableName, LABLE_NAME)
  // switch (lableName) {
  //   case "obligation.regLaw":
  //     LABLE_NAME = "regLaw"
  //     break;
  //   case "disclosure.disclosureTitle":
  //     LABLE_NAME = "disclosureTitle"
  //     break;
  //   case "disclosure.disclosureDesc":
  //     LABLE_NAME = "disclosureDesc"
  //     break;
  //   default:
  //     break;
  // }

  const removeDublicateRecorOf = [
    "contentId",
    "obId",
    "obligationName",
    "obligationDesc",
    "citationUrl",
    "regLaw",
    "issuingAuthority",
    "citation",
    "disId",
    "disclosureTitle",
    "disclosureDesc",
    "text",
    "disclosureSpecificCitation",
    "obligationNote",
    "regulatorShortName",
    "regulatorLongName",
    "regulatorDesc",
    "regulatorMainUrl",
    "regulatorUpdateSource",
    "regulatorUpdateContact",
    "country",
    "state",
    "county",
    "regId",
    "regShortName",
    "cfrRef",
    "regLongName",
    "regDescription",
    "regUrl",
    "regComplianceGuideUrl",
    "regRelatedLaw",
    "regAuthoringRegulator",
    "regPrimarySupervisor",
    "sample",
    "sampleUrl1",
    "sampleUrl2",
    "sampleUrl3",
    "disclosureNote",
    "disclosureSpecificUrl",
    "communicationTypes",
    "error",
    "regulationSite",
  ]

  if (isRemoveDuplicates && removeDublicateRecorOf.includes(LABLE_NAME)) {
    const uniqueObIds = new Set()
    return array.filter(item => {
      if (!uniqueObIds.has(item[LABLE_NAME])) {
        uniqueObIds.add(item[LABLE_NAME])
        return true
      }
      return false
    })
  } else {
    return array
  }
}

export const RemovePtag = Paragraph => {
  const modifiedParagraph = Paragraph
    ? Paragraph?.slice(Paragraph?.indexOf(">") + 1, Paragraph?.lastIndexOf("<"))
    : Paragraph
  return modifiedParagraph
}

export function RemoveSpaces(inputString) {
  // Use a regular expression to replace all spaces with an empty string
  return inputString.replace(/\s/g, "")
}

export const SmoothSearchLoader = () => {
  return (
    <>
      <div className="filterSearchLoader" id="filterSearchLoader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  )
}

export const CirculareLoader = () => {
  return (
    <>
      <div className="filterSearchLoader">
        <Spinner className={"ms-2"} color="primary" />
      </div>
    </>
  )
}

export const ButtonLoader = () => {
  return (
    <>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </>
  )
}

export const DisplayJustifyCenter = content => {
  return (
    <>
      <div className="display-justify-center">{content}</div>
    </>
  )
}
export function GetValidArray(input) {
  if (Array.isArray(input)) {
    return input
  } else {
    return []
  }
}
export const ValidObject = ({ object }) => {
  if (object.severity == "ERROR") {
    return []
  } else {
    const newObj = GetValidArray(object)
    return newObj
  }
}

export function hexHtmlToString(str) {
  var REG_HEX = /&#x([a-fA-F0-9]+);/g
  return str.replace(REG_HEX, function (match, grp) {
    var num = parseInt(grp, 16)
    return String.fromCharCode(num)
  })
}

export function copyObjectExceptKeys(obj, keysToExclude) {
  // Create a shallow copy of the object
  const copiedObject = { ...obj }

  // Remove keys to exclude from the copied object
  keysToExclude.forEach(key => delete copiedObject[key])

  return copiedObject
}

// Example usage:
// const originalObject = { a: 1, b: 2, c: 3, d: 4 };
// const keysToExclude = ['b', 'd'];
// const copiedObject = copyObjectExceptKeys(originalObject, keysToExclude);

export const GET_API = endPoint => {
  const HEADERS = {
    headers: {
      // "Content-Type": "application/json",
      // "Accept": "application/json",
      Authorization: authToken,
      // Include any other required headers
    },
  }
  return new Promise((Response, Reject) => {
    axios
      .get(endPoint, HEADERS)
      .then(res => {
        Response(res)
      })
      .catch(error => {
        Reject(error)
      })
  })
}

export const POST_API = ({ endPoint, body, providedAuthToken }) => {
  const HEADERS = {
    headers: {
      // "Content-Type": "application/json",
      // "Accept": "application/json",
      Authorization: providedAuthToken ? providedAuthToken : authToken,
      // Include any other required headers
    },
  }
  return new Promise((Response, Reject) => {
    axios
      .post(endPoint, body, HEADERS)
      .then(res => {
        Response(res)
      })
      .catch(error => {
        Reject(error)
      })
  })
}

export const POST_FORMDATA_API = ({ endPoint, body }) => {
  const bodyFormData = new FormData()
  Object.keys(body).map(i => {
    bodyFormData.append(i, body[i])
  })
  const HEADERS = {
    headers: {
      // "Content-Type": "application/json",
      // "Accept": "application/json",
      Authorization: authToken,
      // Include any other required headers
    },
  }
  return new Promise((Response, Reject) => {
    axios
      .post(endPoint, bodyFormData, HEADERS)
      .then(res => {
        Response(res)
      })
      .catch(error => {
        Reject(error)
      })
  })
}

export const POST_API_HANDLER = ({
  loaderStart,
  loaderClose,
  successMessage,
  errorMessage,
  ApiEndPoint,
  setState,
  bodyData,
  navigateTo,
}) => {
  loaderStart ? loaderStart() : () => {}

  POST_API({
    endPoint: ApiEndPoint,
    body: bodyData,
  })
    .then(response => {
      setState(response)
      successMessage ? ToastSuccess(response) : () => {}
      loaderClose ? loaderClose() : () => {}
    })
    .catch(error => {
      if (navigateTo) {
        RedirectOnUnAuthentication(error, navigateTo)
      }
      errorMessage ? ToastError(error) : () => {}
      loaderClose ? loaderClose() : () => {}
    })
}

export function convertObjectValuesToArray(obj) {
  const ModifiedObj = removeObjectWithMatchingValue(obj, "")
  if (Object.values(ModifiedObj).includes("")) {
    console.log("enterKey2 convertObjectValuesToArray", ModifiedObj)
    return ModifiedObj
  } else {
    console.log("enterKey3 convertObjectValuesToArray", ModifiedObj)
    const convertedObject = {}

    // Iterate over the entries of the original object
    for (const [key, value] of Object.entries(ModifiedObj)) {
      // Convert the value to an array containing the string value
      convertedObject[key] = [value]
    }
    // return convertedObject;
    return obj
  }
}

export function getObjectWithSpecificKeys(obj, keysArray) {
  const newObj = {}
  keysArray.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}

export function UpdateObjectByKeyMatches(object, key, matchesAndNewValues) {
  const updatedObject = { ...object }

  matchesAndNewValues.forEach(([match, newValue]) => {
    if (object[key] === match) {
      updatedObject[key] = newValue
    }
  })

  return updatedObject
}

export function RenameKeysOfObject({ object, params }) {
  // example of params
  // const params = {
  //   "contentId": "newContentId",
  //   "obId": "newObId",
  //   "obligationName": "newObligationName",
  //   // Add more mappings as needed
  // };
  const data = { ...object }
  const renamedData = {}
  for (const key in data) {
    if (key in params) {
      renamedData[params[key]] = data[key]
    } else {
      renamedData[key] = data[key]
    }
  }
  return renamedData
}

export function UpdateColumnNameRelationFeild({
  array,
  fldName,
  relationField,
  columnName,
}) {
  // Find the subarray matching the provided fldName
  const subArray = array.find(item => item[0] === fldName)
  if (subArray) {
    const functionalityObject = subArray[3]
    if (functionalityObject && functionalityObject.funcPerform === true) {
      functionalityObject.Fuctionality()
    }
    // Update columnName and relationFeild based on the found subarray
    columnName = subArray[1]
    relationField = subArray[2]
  } else {
    columnName = columnName
    relationField = relationField
  }
}

// Example usage:
// const fields = [
//     ["fldName1", "columnName1", "relationFeild1"],
//     ["fldName2", "columnName2", "relationFeild2"],
//     ["fldName3", "columnName3", "relationFeild3"]
// ];

// updateFields(fields, "fldName3");

export function RemoveMatchingKeysByValues({ object, valuesToRemove }) {
  const obj = { ...object }
  // Iterate through each key-value pair in the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the value of the current key matches any value in the array
      if (valuesToRemove.includes(obj[key])) {
        // If it matches, remove the key from the object
        delete obj[key]
      }
    }
  }
  // Return the modified object
  return obj
}

// Example usage:
// let myObject = {  name: null,  age: 30,  city: 'New York',  country: 'USA'  };

// let valuesToRemove = ['John', null];

// let result = removeMatchingKeys(myObject, valuesToRemove);
export function updateOldText(data,isApogee) {
  const newData =  data?.map(obj => ({
    ...obj,
    custDisclosures_oldText: obj?.disclosureChangeRequest_status === 0 ? obj?.custDisclosures_text : obj?.custDisclosures_oldText,
    custDisclosures_text: obj?.disclosureChangeRequest_status === 3   ? obj?.custDisclosures_text : isApogee?"": obj?.custDisclosures_text
  }));

  console.log("newData",newData)
  return newData

}
export function UpdateVariable({ originalVariableName, UpdateValuePairs }) {
  // Check if the original variable exists in the global scope
  console.log("test25456", originalVariableName, UpdateValuePairs)
  let originalValue = originalVariableName

  // Find the pair that matches the original value
  let pair = UpdateValuePairs.find(pair => pair[0] === originalValue)

  // If a matching pair is found, update the variable
  if (pair) {
    let updatedValue = pair[1]
    return updatedValue
  } else {
    return originalVariableName
  }
}

// let myVariable = 8;
// let UpdateValuePairs = [[5, 10], [8, 20]]; // If original value is 5, update to 10. If original value is 8, update to 20.
// let result = updateVariable(myVariable, UpdateValuePairs);

export const ColumnDataWithTooltip = ({ content, link, attr, onlyMessage }) => {
  const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

  return (
    <>
      {CheckValidValue({ value: content }) ? (
        <LightTooltip title={onlyMessage ? onlyMessage : content}>
          {link ? (
            <Link to={{ ...link }} target="_blank">
              <span className="StringOverFlow1" {...attr}>
                {content}
              </span>
            </Link>
          ) : (
            <span className="StringOverFlow1" {...attr}>
              {content}
            </span>
          )}
        </LightTooltip>
      ) : (
        DisplayJustifyCenter("-")
      )}
    </>
  )
}

export function removeKeysFromObject(obj, keysToRemove) {
  const newobj = { ...obj }
  keysToRemove.forEach(key => {
    delete newobj[key]
  })
  return newobj
}
export function SeparateObjectByValueType(object) {
  const obj = { ...object }
  let singleRecordArrayObject = {}
  let multipleRecordArrayObject = {}
  let stringValuesObject = {}
  let otherValuesObject = {}
  let singleRecordArrayObjectConvertedWithStingValue = {}
  let stringValuesObjectConvertedWithArrayValue = {}
  let stringValuesObjectConvertedWithDotFormateToDashFormate = {}
  let forAutoEmpty = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]

      if (Array.isArray(value) && value.length === 1) {
        singleRecordArrayObject[key] = value
        singleRecordArrayObjectConvertedWithStingValue[key] = value[0]
        // multipleRecordArrayObject[key] = value;
      } else if (Array.isArray(value) && value.length > 1) {
        multipleRecordArrayObject[key] = value
      } else if (typeof value === "string") {
        stringValuesObject[key] = value
        stringValuesObjectConvertedWithArrayValue[key] = [value] 
        forAutoEmpty[key] = value
        forAutoEmpty[`${key}`.split(".")[`${key}`.split(".").length-1]] = value
        stringValuesObjectConvertedWithDotFormateToDashFormate[key] = value
        stringValuesObjectConvertedWithDotFormateToDashFormate[`${key}`.split(".").join("_")] = value
      }else{
        otherValuesObject[key] = value
      }
    }
  }

  return {
    singleRecordArrayObject,
    multipleRecordArrayObject,
    stringValuesObject,
    otherValuesObject,
    singleRecordArrayObjectConvertedWithStingValue,
    stringValuesObjectConvertedWithArrayValue,
    forAutoEmpty,
    stringValuesObjectConvertedWithDotFormateToDashFormate
  }
  // return { multipleRecordArrayObject, stringValuesObject };
}

export const TrimmedValue = value => {
  if (CheckValidValue({ value: value })) {
    return `${value}`.trim()
  } else {
    return ""
  }
}

// Function to assign new value to each key if the value is an array
function processArrayValues(object) {
  const obj = { ...object }
  const processedObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        return [key, value[0]]
      }
      return [key, value]
    })
  )

  return processedObj
}

export function ModifyObjectForAutoEmpty(object) {
  // Process 1: Assign new value to each key if the value is an array

  const renamingArray = {
    "newErrorTestObligationReport.obId": "obligation.obId",
    "newErrorTestObligationReport.regulationSite": "regulationSite",
    "newErrorTestObligationReport.error": "error",
    "newErrorTestObligationReport.citationUrl": "citationUrl",
  }

  // const params = {
  //   "contentId": "newContentId",
  //   "obId": "newObId",
  //   "obligationName": "newObligationName",
  //   // Add more mappings as needed
  // };
  try {
    const obj = { ...object }
    const resultWithRenameKey = RenameKeysOfObject({
      object: obj,
      params: renamingArray,
    })
    const FinalResulte = processArrayValues(resultWithRenameKey)
    return FinalResulte
  } catch (error) {
    console.log("Error in ModifyObjectForSearchQuery error", error)
    console.log("Error in ModifyObjectForSearchQuery object", object)
    console.log(
      "Error in ModifyObjectForSearchQuery renamingArray",
      renamingArray
    )
    return {}
  }
}

// export function ModifyObjectForAutoEmpty(object) {
//   const renamingArray = [
//     ["newErrorTestObligationReport.obId", "obligation.obId"],
//     ["newErrorTestObligationReport.regulationSite", "regulationSite"]
//     ["newErrorTestObligationReport.error", "error"],
//     ["newErrorTestObligationReport.citationUrl", "citationUrl"],
// ];
//   // Process 1: Assign new value to each key if the value is an array
//   const obj = {...object}
//   const processedObj = Object.fromEntries(
//       Object.entries(obj).map(([key, value]) => {
//           if (Array.isArray(value) && value.length > 0) {
//               return [key, value[0]];
//           }
//           return [key, value];
//       })
//   );

//   // Process 2: Rename keys if specified in the renaming array
//   renamingArray.forEach(pair => {
//       const oldKey = pair[0];
//       const newKey = pair[1];
//       if (processedObj.hasOwnProperty(oldKey)) {
//           processedObj[newKey] = processedObj[oldKey];
//           delete processedObj[oldKey];
//       }
//   });

//   return processedObj;
// }

export const RedirectOnUnAuthentication = (
  error,
  navigateTo,
  modiFiedError
) => {
  try {
    if (error.response.status === 401) {
      console.log("Error", error.response)
      // ToastError(error)
      navigateTo("/login")
      console.log("RedirectOnUnAuthentication........Login.........")
    } else {
      console.log("Error", error.response)
      modiFiedError ? ToastError(modiFiedError) : console.log("Error", error)
    }
  } catch (error) {
    console.log("Error in RedirectOnUnAuthentication", error)
  }
}

export function filterFieldsFromArray(arrayObj, fields) {
  const array = [...arrayObj]
  return array.map(obj => {
    const newObj = {}
    fields.forEach(field => {
      if (obj.hasOwnProperty(field)) {
        newObj[field] = obj[field] || ""
      }
    })
    return newObj
  })
}

export function UpdateNestedObject(object, updateValues) {
  try {
    // Iterate over each key-value pair in the updateValues object
    let obj = { ...object }
    Object.entries(updateValues).forEach(([key, value]) => {
      // Check if the key exists in the current object
      if (obj.hasOwnProperty(key)) {
        // Update the value if the key exists
        obj[key] = value
      }
    })

    // Recursively update values in nested objects
    Object.values(obj).forEach(value => {
      if (typeof value === "object" && value !== null) {
        UpdateNestedObject(value, updateValues)
      }
    })

    return obj
  } catch (error) {
    console.log("Error in UpdateNestedObject", error)
    return {}
  }
}

export function ReplaceMultipleSpacesWithNonBreakingSpace(htmlString) {
  return htmlString.replace(/ +/g, function (match) {
    return "&nbsp;".repeat(match.length)
  })
}
export function removeNonBreakingSpaces(content) {
  return content.replace(/&nbsp;/g, '');
}
export function compareStringsIns(oldString, newString) {
  let result = '';
  let newIndex = 0;

  for (let i = 0; i < oldString.length; i++) {
      if (newString[newIndex] !== oldString[i]) {
          // Content is added in newString, wrap it
          result += `<span class="diffins">${newString[newIndex]}</span>`;
          newIndex++;
          i--; // Revisit the current character in oldString
      } else {
          result += oldString[i];
          newIndex++;
      }

      if (newIndex >= newString.length) break;
  }

  // If there are remaining characters in newString, add them
  if (newIndex < newString.length) {
      result += `<span class="diffins">${newString.substring(newIndex)}</span>`;
  }

  return `<span class="compareContent">${result}</span>`;
}
export function compareStringsDel(oldString, newString) {
  let result = '';
  let oldIndex = 0;
  
  for (let i = 0; i < newString.length; i++) {
      if (oldString[oldIndex] !== newString[i]) {
          // Content is missing in newString, highlight it
          result += `<span class="diffdel">${oldString[oldIndex]}</span>`;
          oldIndex++;
          i--; // Revisit the current character in newString
      } else {
          result += newString[i];
          oldIndex++;
      }

      if (oldIndex >= oldString.length) break;
  }

  // If there are remaining characters in oldString, add them
  if (oldIndex < oldString.length) {
      result += `<span class="diffdel">${oldString.substring(oldIndex)}</span>`;
  }

  const NewStr = compareStrings(result,newString);
  return `<span class="compareContent">${NewStr}</span>`;
}

export function compareStrings( oldHtml,newHtml) {
  const diff = [];

  // Split the HTML strings into arrays of words
  const newWords = newHtml.replace(/<[^>]*>/g, ' ').split(/\s+/);
  const oldWords = oldHtml.replace(/<[^>]*>/g, ' ').split(/\s+/);

  // Iterate over the words in the new HTML
  let i = 0;
  while (i < newWords.length) {
      if (newWords[i] !== oldWords[i]) {
          // If words don't match, find the end of the difference
          let j = i + 1;
          while (j < newWords.length && newWords[j] !== oldWords[j]) {
              j++;
          }

          // Add the difference to the diff array
          diff.push({
              type: 'changed',
              newText: newWords.slice(i, j).join(' '),
              oldText: oldWords.slice(i, j).join(' ')
          });
          i = j;
      } else {
          // If words match, move to the next word
          i++;
      }
  }

  // Create a new HTML string to show the differences
  let resultHtml = '';
  diff.forEach(part => {
      if (part.type === 'changed') {
          resultHtml += `<span class="diffdel">${part.oldText}</span><span class="diffins">${part.newText}</span>`;
      }
  });

  return `<span class="compareContent">${resultHtml}</span>` 
}

export const DownloadFormat = ({data,filename}) => {
  // const csvData = "Disclosure Id,Disclosure Text"
  // const filename = "Disclosure_Text_format.csv"
  // const csvBlob = new Blob([csvData], { type: "text/csv" })
  // const csvURL = window.URL.createObjectURL(csvBlob)

  // const tempLink = document.createElement("a")
  // tempLink.href = csvURL
  // tempLink.setAttribute("download", filename)
  // tempLink.click()

  // // Cleanup
  // window.URL.revokeObjectURL(csvURL)
  // tempLink.remove()

//   const data = [
//     ["Disclosure Id", "Disclosure Text"],
//     // Add your data rows here
// ];
 
const ws = XLSX.utils.aoa_to_sheet(data);
const wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
// const filename = "Disclosure_Text_format.xlsx";
XLSX.writeFile(wb, filename);
}


export const DownloadExelFrom = ({responseData,fileTitle}) =>{
  try {
    // const response = await axios.get('backend_endpoint_to_download_xls_file', {
    //   responseType: 'blob' // Set responseType to 'blob' to handle binary data
    // });
    const blob = new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileTitle}.xlsx`); // Set filename for downloaded file
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error downloading Excel file:', error);
  }
}

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRIPTION_KEY; // Replace with your secret key

// Encrypts data using AES encryption
export function encryptData(data) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
  // console.log(encryptedData);
  return encryptedData;
}


export const InnerText = (content) => {
  const encodedContent = content.replace(/<<([^>]+)>>/g, "&lt;&lt;$1&gt;&gt;");
  return encodedContent;
}

// For Getting Token
export function getUserDetails() {
  const userDetailsString = localStorage?.getItem('authUser');
  const userDetails = userDetailsString ? JSON?.parse(userDetailsString) : null;
  return userDetails?.data?.authToken || null;
}

export const dummyData = [
  {
    obligationName: "Dummy Obligation 1",
    oldobligationDesc: "Previous content for Dummy Obligation 1",
    obligationDesc: "Current content for Dummy Obligation 1",
    status: "Pending",
  },
  {
   
    obligationName: "Dummy Obligation 2",
    oldobligationDesc: "Previous content for Dummy Obligation 2",
    obligationDesc: "Current content for Dummy Obligation 2",
    status: "Completed",
  },
  {
    obligationName: "Dummy Obligation 3",
    oldobligationDesc: "Previous content for Dummy Obligation 3",
    obligationDesc: "Current content for Dummy Obligation 3",
    status: "Pending",
  },
  {
    obligationName: "Dummy Obligation 4",
    oldobligationDesc: "Previous content for Dummy Obligation 4",
    obligationDesc: "Current content for Dummy Obligation 4",
    status: "Completed",
  },
  {
    obligationName: "Dummy Obligation 5",
    oldobligationDesc: "Previous content for Dummy Obligation 5",
    obligationDesc: "Current content for Dummy Obligation 5",
    status: "Pending",
  },
  {
    obligationName: "Dummy Obligation 6",
    oldobligationDesc: "Previous content for Dummy Obligation 6",
    obligationDesc: "Current content for Dummy Obligation 6",
    status: "Completed",
  },
  {
    obligationName: "Dummy Obligation 7",
    oldobligationDesc: "Previous content for Dummy Obligation 7",
    obligationDesc: "Current content for Dummy Obligation 7",
    status: "Pending",
  },
  {
    obligationName: "fummy Obligation 8",
    oldobligationDesc: "Previous content for Dummy Obligation 8",
    obligationDesc: "Current content for Dummy Obligation 8",
    status: "Completed",
  },
  {
    obligationName: "Dummy Obligation 9",
    oldobligationDesc: "Previous content for Dummy Obligation 9",
    obligationDesc: "Current content for Dummy Obligation 9",
    status: "Pending",
  },
  {
    obligationName: "Dummy Obligation 10",
    oldobligationDesc: "Previous content for Dummy Obligation 10",
    obligationDesc: "Current content for Dummy Obligation 10",
    status: "Completed",
  },
];


export const cardTitles = [
  'Prior Citation Content',
  'Disclosure Text Content',
  'New Citation Content',
  'Summary',
  'Impact'
];


export const ReportId = () => {
      return localStorage.getItem('rowDataId')
}


export const RadioOptions = [
  { label: 'AI Changes Recommended', value: 'AI Changes Recommended' },
  { label: 'Pending Author Review', value: 'Pending Author Review' }
];

export const unlikeViewOptions = [
  { label: 'AI Changes Recommended', value: 'AI Changes Recommended' },
];

export const classifications = [
  { label: 'No likelihood of change', value: 0, per : '(less than 20%)' },
  { label: 'Minor likelihood of change', value: 1, per: '(20-40%)' },
  { label: 'Moderate likelihood of change', value: 2, per : '(40-60%)' },
  { label: 'Significant likelihood of change', value: 3, per : '(60+%)' },
];

export const likelihoodMessages = [
  'No likelihood of change (less than 20%)',
  'Minor likelihood of change (20-40%)',
  'Moderate likelihood of change (40-60%)',
  'Significant likelihood of change (60+%)'
];