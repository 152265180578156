import {
  GET_OBLIGATIONS,
  GET_OBLIGATIONS_SUCCESS,
  GET_OBLIGATIONS_FAIL,
  ADD_NEW_OBLIGATION,
  ADD_OBLIGATION_SUCCESS,
  ADD_OBLIGATION_FAIL,
  UPDATE_OBLIGATION,
  UPDATE_OBLIGATION_SUCCESS,
  UPDATE_OBLIGATION_FAIL,
  DELETE_OBLIGATION,
  DELETE_OBLIGATION_SUCCESS,
  DELETE_OBLIGATION_FAIL,
} from "./actionTypes"

export const getObligations = urlData => ({
  type: GET_OBLIGATIONS,
  urlData,
})

export const getObligationsSuccess = data => ({
  type: GET_OBLIGATIONS_SUCCESS,
  payload: data,
})

export const getObligationsFail = error => ({
  type: GET_OBLIGATIONS_FAIL,
  payload: error,
})

export const addNewObligation = (obligation, history,path) => ({
  type: ADD_NEW_OBLIGATION,
  payload: { obligation, history,path },
})

export const addObligationSuccess = obligation => ({
  type: ADD_OBLIGATION_SUCCESS,
  payload: obligation,
})

export const addObligationFail = error => ({
  type: ADD_OBLIGATION_FAIL,
  payload: error,
})

export const updateObligation = (obligation, history,path) => ({
  type: UPDATE_OBLIGATION,
  payload: { obligation, history,path },
})

export const updateObligationSuccess = obligation => ({
  type: UPDATE_OBLIGATION_SUCCESS,
  payload: obligation,
})

export const updateObligationFail = error => ({
  type: UPDATE_OBLIGATION_FAIL,
  payload: error,
})

export const deleteObligation = (obligation, history,path) => ({
  type: DELETE_OBLIGATION,
  payload: { obligation, history,path },
})

export const deleteObligationSuccess = obligation => ({
  type: DELETE_OBLIGATION_SUCCESS,
  payload: obligation,
})

export const deleteObligationFail = error => ({
  type: DELETE_OBLIGATION_FAIL,
  payload: error,
})
