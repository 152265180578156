import {
  GET_REGULATORS,
  GET_REGULATORS_SUCCESS,
  GET_REGULATORS_FAIL,
  ADD_NEW_REGULATOR,
  ADD_REGULATOR_SUCCESS,
  ADD_REGULATOR_FAIL,
  UPDATE_REGULATOR,
  UPDATE_REGULATOR_SUCCESS,
  UPDATE_REGULATOR_FAIL,
  DELETE_REGULATOR,
  DELETE_REGULATOR_SUCCESS,
  DELETE_REGULATOR_FAIL,
} from "./actionTypes"

export const getRegulators = urlData => ({
  type: GET_REGULATORS,
  urlData,
})

export const getRegulatorsSuccess = data => ({
  type: GET_REGULATORS_SUCCESS,
  payload: data,
})

export const getRegulatorsFail = error => ({
  type: GET_REGULATORS_FAIL,
  payload: error,
})

export const addNewRegulator = (regulator, history) => ({
  type: ADD_NEW_REGULATOR,
  payload: { regulator, history },
})

export const addRegulatorSuccess = regulator => ({
  type: ADD_REGULATOR_SUCCESS,
  payload: regulator,
})

export const addRegulatorFail = error => ({
  type: ADD_REGULATOR_FAIL,
  payload: error,
})

export const updateRegulator = (regulator, history) => ({
  type: UPDATE_REGULATOR,
  payload: { regulator, history },
})

export const updateRegulatorSuccess = regulator => ({
  type: UPDATE_REGULATOR_SUCCESS,
  payload: regulator,
})

export const updateRegulatorFail = error => ({
  type: UPDATE_REGULATOR_FAIL,
  payload: error,
})

export const deleteRegulator = (regulator, history) => ({
  type: DELETE_REGULATOR,
  payload: { regulator, history },
})

export const deleteRegulatorSuccess = regulator => ({
  type: DELETE_REGULATOR_SUCCESS,
  payload: regulator,
})

export const deleteRegulatorFail = error => ({
  type: DELETE_REGULATOR_FAIL,
  payload: error,
})
