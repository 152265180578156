// get DISCLOSURES
export const GET_DISCLOSURES = "GET_DISCLOSURES"
export const GET_DISCLOSURES_SUCCESS = "GET_DISCLOSURES_SUCCESS"
export const GET_DISCLOSURES_FAIL = "GET_DISCLOSURES_FAIL"

// add DISCLOSURE

export const ADD_NEW_DISCLOSURE = "ADD_NEW_DISCLOSURE"
export const ADD_DISCLOSURE_SUCCESS = "ADD_DISCLOSURE_SUCCESS"
export const ADD_DISCLOSURE_FAIL = "ADD_DISCLOSURE_FAIL"

// edit DISCLOSURE
export const UPDATE_DISCLOSURE = "UPDATE_DISCLOSURE"
export const UPDATE_DISCLOSURE_SUCCESS = "UPDATE_DISCLOSURE_SUCCESS"
export const UPDATE_DISCLOSURE_FAIL = "UPDATE_DISCLOSURE_FAIL"

/**
 * Delete DISCLOSURE
 */
export const DELETE_DISCLOSURE = "DELETE_DISCLOSURE"
export const DELETE_DISCLOSURE_SUCCESS = "DELETE_DISCLOSURE_SUCCESS"
export const DELETE_DISCLOSURE_FAIL = "DELETE_DISCLOSURE_FAIL"


// Loading State 
export const SET_LOADING = "SET_LOADING";
