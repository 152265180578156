import React from 'react'
import WorkFlowTable from './components/WorkFlowTable'

const WorkFlowList = () => {
  return (
    <div>
        <WorkFlowTable/>
    </div>
  )
}

export default WorkFlowList