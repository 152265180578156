import React, { useEffect, useMemo, useState } from "react"
import { usePagination, useTable } from "react-table"
// import { withRouter } from "react-router-dom"
import {
  BreadcrumbItem,
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Spinner,
} from "reactstrap"
import TableHeader from "components/Common/TableHeader"
import styled from "styled-components"
import { Breadcrumbs, makeStyles } from "@material-ui/core"
import axios from "axios"
import toastr from "toastr"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { RedirectOnUnAuthentication, ToastError, encryptData } from "helpers/helper_function"

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  // padding: 1rem;

  .table {
    border: 1px solid #ddd;
    border-radius: 5px;
    .tr {
      min-width: 100%;
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #eff2f7;
      // border-bottom: 1px solid #ddd;
      // border-right: 1px solid #ddd;
      // background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      :not([data-sticky-td]) {
        flex-grow: 1;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      position: relative;
      height: 80px;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
        min-width: 100%;
      }

      .header {
        top: 0;
        // box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 0px 2px #ccc;
      }

      [data-sticky-first-right-td] {
        // box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: "2px", // Adjust the padding value as per your requirement
    },
    "& input::placeholder": {
      fontSize: "12px",
      fontFamily: "poppins",
    },
  },
  option: {
    fontFamily: "poppins",
    fontSize: "13px",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    backgroundColor: "#fff", // Set your desired background color her
    paddingLeft: 5,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "poppins",
  },
  datePickerInput: {
    backgroundColor: "#fff", // Set your desired background color here
    // borderRadius: theme.shape.borderRadius,
    // padding: theme.spacing(1),
  },
})

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (id === "citationUrl") {
    return (
      <input
        className="form-control"
        type="url"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    )
  }

  return (
    <input
      className="form-control"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}

function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  currentPage,
  totalItems,
  customePageSize,
  onPageChange,
  onClickSave,
  onCancelSave,
  resetData,
  loader,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    usePagination
  )

  // Render the UI for your table
  return (
    <>
      <Row className=" d-flex align-items-center">
        {/* <Col sm={12} md={12} lg={12} xl={4} className="py-3 justify-content-end"> */}
        {/* <TableHeader heading={"List of Obligations"} onChangeInSelect={onChangeInSelect} customePageSize={customePageSize} /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2rem",
            marginBottom: "1rem",
          }}
        >
          <div className="d-flex flex-wrap align-items-center   justify-content-start">
            {/* <h5 className="font-size-18 mb-0">Update Multiple Obligations</h5> */}
            {/* <select
                className="form-select"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                style={{width : "150px", marginLeft: "10px"}}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    No of Results {pageSize}
                  </option>
                ))}
              </select> */}
          </div>
          <div style={{ display: "flex", gap: "4px" }}>
            <div>
              <Button
                style={{ backgroundColor: "transparent", color: "black" }}
                onClick={onCancelSave}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button style={{ backgroundColor: "grey" }} onClick={resetData}>
                Reset Data
              </Button>
            </div>
            {loader ? (
              <div className="ml-5">
                <Spinner className="ms-2 " color="primary" />
              </div>
            ) : (
              <div>
                <Button style={{ backgroundColor: "green" }} type="submit">
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
        {/* </Col> */}
      </Row>
      <Styles>
        <div style={{ minHeight: "25rem" }}>
          <table className="sticky table" {...getTableProps()}>
            <thead className="table-light header" style={{ fontWeight: 600 }}>
              {headerGroups.map((headerGroup, index) => (
                <tr
                  className="tr"
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, i) => (
                    <th
                      style={{ height: "36px" }}
                      className="th"
                      key={i}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="body" {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr className="tr" key={i} {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td className="td" key={i} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Row className="justify-content-center mt-3">
            <Col className="col-auto">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalItems}
                pageSize={customePageSize}
                onPageChange={page => onPageChange(page)}
              />
            </Col>
          </Row>
          {/* <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>        
      </div> */}
        </div>
      </Styles>
    </>
  )
}

const MutiUpdated = props => {
  const [data, setData] = useState([])
  const [editData, setEditData] = useState([])
  const [skipPageReset, setSkipPageReset] = useState(false)
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [customePageSize, setCustomePageSize] = useState(50)
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  useEffect(() => {
    setData(props?.location?.state?.rowData || [])
    setEditData(props?.location?.state?.rowData || [])
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Obligation Name",
        accessor: "obligationName",
      },
      {
        Header: "Obligation Description",
        accessor: "obligationDesc",
      },
      {
        Header: "Citation URL",
        accessor: "citationUrl",
      },
      {
        Header: "Citation",
        accessor: "citation",
      },
    ],
    []
  )

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setEditData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  useEffect(() => {
    setSkipPageReset(false)
  }, [data])

  const resetData = () => setEditData(data)

  const onClickSave = e => {
    e.preventDefault()
    setLoader(true)
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const newArray = editData.map(obj => {
      return {
        id: obj.id,
        obligationName: obj.obligationName,
        obligationDesc: obj.obligationDesc,
        citationUrl: obj.citationUrl,
        citation: obj.citation,
      }
    })

    const data = {
      obligations: newArray,
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/bulk-update-obligation`,
        {data:encryptData(data)},
        {
          headers: { Authorization: `Bearer ${authToken.data.authToken}` },
        }
      )
      .then(response => {
        if (response.status >= 200 || response.status <= 299) {
          toastr.success(response.data.message)
          props.history.replace({
            pathname: "/obligations/all-obligations",
          })
        }
      })
      .catch(err => {
        RedirectOnUnAuthentication(err,navigateTo)
        console.log(err.response.data.message, "mesg")
        var message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 400:
               ToastError(err)
              toggleDelete()
              break
            case 404:
              message = "Sorry! Network Error(Status:404)"
              break
            case 500:
              message =
                "Sorry! something went wrong, please contact our support team, Status-500   "
              break
            case 401:
              message = "You are not authorized to view this data."
              break
            default:
              message = err[1]
              break
          }
          handleRender()
        }
        throw message
      })
    setLoader(true)
  }

  const onCancelSave = () => {
    props.history.replace({
      pathname: "/obligations/all-obligations",
    })
  }

  document.title = "Update Multiple Obligations"
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">
              {"Update Multiple Obligations"}
            </h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link
                    to={{
                      pathname: "/obligations/all-obligations",
                      // state: { rowData: {id: data.queryId} },
                    }}
                  >
                    {"List of Obligations"}
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  {"Update Multiple Obligations"}
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
          <Row style={{ backgroundColor: "white" }}>
            <Container fluid={true}>
              <form onSubmit={onClickSave}>
                <Table
                  columns={columns}
                  data={editData}
                  updateMyData={updateMyData}
                  skipPageReset={skipPageReset}
                  currentPage={currentPage}
                  totalCount={totalItems}
                  pageSize={customePageSize}
                  onPageChange={page => onPageChange(page)}
                  onClickSave={onClickSave}
                  onCancelSave={onCancelSave}
                  resetData={resetData}
                  loader={loader}
                />
              </form>
            </Container>
          </Row>
        </div>
      </div>
    </>
  )
}

export default MutiUpdated
 
