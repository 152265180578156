import PropTypes from "prop-types"
import React, { useState } from "react"
import toastr from "toastr"
import axios from "axios"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useParams, useHistory } from "react-router-dom"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import CarouselPage from "./CarouselPage"
import lightlogo from "../../assets/images/logo-light.svg"
import loginLogo from "../../assets/images/login-logo.png"
import smallLogo from "../../assets/images/small-logo.png"
import {RedirectOnUnAuthentication} from "../../helpers/helper_function"
const ChangePassword = props => {
  //meta title
  const { email } = useParams()
  const history = useHistory()
  const callUseNavigate = useHistory()
  const navigateTo = (url) =>{
    callUseNavigate.push(url)
  }
  console.log(email, "email")
  document.title = "Change Password | Apogee -  Admin & Dashboard"
  const [show, setShow] = useState(false)
  const [confirmShow, setConfirmShow] = useState(false)
  const [apiLoader, setApiLoader] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // prevPswd: "" || "",
      newPassword: "" || "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      // prevPswd: Yup.string().required("Please Enter Old Password"),
      newPassword: Yup.string()
        .required("Please Enter New Password")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ),
      confirmPassword: Yup.string()
        .required("Please Enter New Password Again")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        )
        .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
    }),
    onSubmit: values => {
      console.log(values, "values")
      const addData = {
        email: email,
        password: values.confirmPassword,
      }
      setApiLoader(true)
      axios
        .post(`${process.env.REACT_APP_BASE_URL}change-password`, addData)
        .then(response => {
          if (response.status >= 200 || response.status <= 299) {
            // setSuccess(true)

            setApiLoader(false)
            toastr.success("Password Successfully Changed.")
            history.push("/login")
            return response.data
          }
        })
        .catch(err => {
          let message
          RedirectOnUnAuthentication(err,navigateTo)
          console.log(err, "error")
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 404:
                message =
                  "Sorry! the page you are looking for could not be found"
                break
              case 400:
                message = err.response.data.message
                break
              case 500:
                message =
                  "Sorry! something went wrong, please contact our support team"
                break
              case 401:
                message = "Invalid credentials"
                break
              default:
                message = err[1]
                break
            }
          }
          setApiLoader(false)
          // return message
          // throw message;
          // toastr.error(message)
        })
      // dispatch(loginUser(values, props.history))
    },
  })

  const { loading, error } = useSelector(state => ({
    loading: state.Login.loading,
    error: state.Login.error,
  }))

  console.log(error, "error login")
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={4}>
              <div className="justify-content-center">
                {/* <div className="justify-content-center"> */}
                {/* <div className="mb-5"> */}
                <div className="mb-5 mb-md-5 ms-4 mt-3">
                  {/* <Link to="/dashboard" className="d-block auth-logo"> */}
                  <img
                    src={loginLogo}
                    alt=""
                    height="50"
                    width="100"
                    className="auth-logo-light"
                  />
                  {/* </Link> */}
                </div>
                {/* </div> */}
                <Card className="overflow-hidden mx-4 mt-5">
                  <div
                    className="text-white"
                    style={{ backgroundColor: "rgba(82, 113, 167, 0.5)" }}
                  >
                    <Row>
                      <Col className="col-7">
                        <div className="text-white p-4">
                          <h5 className="text-white">Change Password</h5>
                          <p>Welcome to Apogee.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo d-flex justify-content-center">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={smallLogo}
                              alt=""
                              className="rounded-circle"
                              height="80"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {apiLoader ? (
                          <div
                            className="container-fluid "
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Spinner className="ms-2" color="primary" />
                          </div>
                        ) : error ? (
                          <Alert color="danger">{errMsg && errMsg}</Alert>
                        ) : null}
                        {/* <div className="mb-3">
                          <Label className="form-label">Old Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              value={validation.values.prevPswd || ""}
                              type={show ? "text" : "password"}
                              placeholder="Enter Old Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.prevPswd &&
                                validation.errors.prevPswd
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() => setShow(!show)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          {validation.touched.prevPswd &&
                          validation.errors.prevPswd ? (
                            <FormFeedback type="invalid">
                              {validation.errors.prevPswd}
                            </FormFeedback>
                          ) : null}
                        </div> */}

                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="newPassword"
                              value={validation.values.newPassword || ""}
                              type={show ? "text" : "password"}
                              placeholder="Enter New Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.newPassword &&
                                validation.errors.newPassword
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() => setShow(!show)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              {show ? (
                                <i className="mdi mdi-eye-outline"></i>
                              ) : (
                                <i className="mdi mdi-eye-off-outline"></i>
                              )}
                            </button>
                          </div>
                          {validation.touched.newPassword &&
                          validation.errors.newPassword ? (
                            <div className="error" style={{ fontSize: "12px" }}>
                              {validation.errors.newPassword}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="confirmPassword"
                              value={validation.values.confirmPassword || ""}
                              type={confirmShow ? "text" : "password"}
                              placeholder="Confirm Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.confirmPassword &&
                                validation.errors.confirmPassword
                                  ? true
                                  : false
                              }
                            />
                            <button
                              onClick={() => setConfirmShow(!confirmShow)}
                              className="btn btn-light "
                              type="button"
                              id="password-addon"
                            >
                              {show ? (
                                <i className="mdi mdi-eye-outline"></i>
                              ) : (
                                <i className="mdi mdi-eye-off-outline"></i>
                              )}
                            </button>
                          </div>
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <div className="error" style={{ fontSize: "12px" }}>
                              {validation.errors.confirmPassword}
                            </div>
                          ) : null}
                        </div>

                        {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>             */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block "
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" /> Forgot your
                            password?
                          </Link>
                        </div> */}
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-1 text-center"></div>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ChangePassword)
