import {
  GET_DISCLOSURES_SUCCESS,
  GET_DISCLOSURES_FAIL,
  ADD_DISCLOSURE_SUCCESS,
  ADD_DISCLOSURE_FAIL,
  UPDATE_DISCLOSURE_SUCCESS,
  UPDATE_DISCLOSURE_FAIL,
  DELETE_DISCLOSURE_SUCCESS,
  DELETE_DISCLOSURE_FAIL,
  SET_LOADING, // Import the new action type
} from "./actionTypes";

const INIT_STATE = {
  disclosuresData: [],
  loading: false,
  error: false,
  errorValue: "",
  success: "",
};

const Disclosures = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISCLOSURES_SUCCESS:
      return {
        ...state,
        disclosuresData: action.payload,
        loading: false,
      };

    case GET_DISCLOSURES_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorValue: action.payload,
      };

    case ADD_DISCLOSURE_SUCCESS:
    case ADD_DISCLOSURE_FAIL:
    case UPDATE_DISCLOSURE_SUCCESS:
    case UPDATE_DISCLOSURE_FAIL:
    case DELETE_DISCLOSURE_SUCCESS:
    case DELETE_DISCLOSURE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_LOADING: 
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default Disclosures;
