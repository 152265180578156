// get OBLIGATIONS
export const GET_OBLIGATIONS = "GET_OBLIGATIONS"
export const GET_OBLIGATIONS_SUCCESS = "GET_OBLIGATIONS_SUCCESS"
export const GET_OBLIGATIONS_FAIL = "GET_OBLIGATIONS_FAIL"

// add OBLIGATION

export const ADD_NEW_OBLIGATION = "ADD_NEW_OBLIGATION"
export const ADD_OBLIGATION_SUCCESS = "ADD_OBLIGATION_SUCCESS"
export const ADD_OBLIGATION_FAIL = "ADD_OBLIGATION_FAIL"

// edit OBLIGATION
export const UPDATE_OBLIGATION = "UPDATE_OBLIGATION"
export const UPDATE_OBLIGATION_SUCCESS = "UPDATE_OBLIGATION_SUCCESS"
export const UPDATE_OBLIGATION_FAIL = "UPDATE_OBLIGATION_FAIL"

/**
 * Delete OBLIGATION
 */
export const DELETE_OBLIGATION = "DELETE_OBLIGATION"
export const DELETE_OBLIGATION_SUCCESS = "DELETE_OBLIGATION_SUCCESS"
export const DELETE_OBLIGATION_FAIL = "DELETE_OBLIGATION_FAIL"
