//import images
import airbnb from "../../assets/images/companies/airbnb.svg"
import mailchimp from "../../assets/images/companies/mailchimp.svg"
import reddit from "../../assets/images/companies/reddit.svg"
import amzon from "../../assets/images/companies/amazon.svg"
import adobPhotoshop from "../../assets/images/companies/adobe-photoshop.svg"
import line from "../../assets/images/companies/line.svg"

const jobVacancy = [
  {
    id: 1,
    img: airbnb,
    title: "Project Manager",
    country: " California",
    vacancy: 8,
  },
  {
    id: 2,
    img: mailchimp,
    title: "Marketing Director",
    country: "Danemark",
    vacancy: 12,
  },
  {
    id: 3,
    img: reddit,
    title: "Product Designer",
    country: "France",
    vacancy: 25,
  },
  {
    id: 4,
    img: amzon,
    title: "Magento Developer",
    country: "Hong-Kong",
    vacancy: 8,
  },
  {
    id: 5,
    img: adobPhotoshop,
    title: "Product Sales Specialist",
    country: " Louisiana",
    vacancy: 1,
  },
  {
    id: 6,
    img: line,
    title: "Business Associate",
    country: " Phoenix",
    vacancy: 15,
  },
]

export default jobVacancy
