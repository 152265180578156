import React, { useEffect, useRef, useState } from 'react'
import "./GlobalSearch.css"
import axios from 'axios';
import { HEADER } from 'helpers/url_helper';
import { RedirectOnUnAuthentication, encryptData } from 'helpers/helper_function';
import { Link } from "react-router-dom"
import { Spinner } from 'reactstrap';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"


function HighlightedText({ text, searchTerm }) {
    // Split the text into parts based on the search term
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    
    return (
      <p className='global-serach-data-regulation'>
        {parts.map((part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} style={{ color: 'blue' }}>{part}</span>
          ) : (
            part
          )
        )}
      </p>
    );
  }
  


const GlobalSearch = () => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [data, setData] = useState({
        regulations: [],
        obligations: [],
        disclosures: [],
        regulators: []
    });
    const [loder, serLoder] = useState(false);
    const globalSearchDataRef = useRef(null);
    const callUseNavigate = useHistory()
    const navigateTo = (url) => {
        callUseNavigate.push(url)
    }
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    const obj2 = JSON.parse(localStorage.getItem("orgID"))
    let newCheckOrg
    if (obj2) {
        newCheckOrg = obj2.orgId
    } else if (!obj2 && authToken) {
        newCheckOrg = authToken.data.customerorg.id
    }
    const [checkOrg, setCheckOrg] = useState(obj2?.orgId || authToken?.data?.customerorg?.id)


    const [classData, setClassData] = useState('global-serach-data-none')

    const fetchData = async (inputValue, controller) => {
        serLoder(true)
        try {
            const data = {
                searchValue: inputValue, customerOrgId:
                    checkOrg !== "" && checkOrg !== 3
                        ? checkOrg !== "" && parseInt(checkOrg)
                        : "",
            };

            const response = await axios
                .post(`${process.env.REACT_APP_BASE_URL}/globalSearch`, { data: encryptData(data) }, {
                    headers: {
                        Authorization: `Bearer ${authToken.data.authToken}`,
                        ...HEADER
                    },
                    signal: controller.signal
                })
            if (response.status >= 200 || response.status <= 299) {

                if (response.data.data.disclosures) {
                    console.log(response.data.data, 'response.data')
                    setData(response.data.data);

                    setClassData('global-serach-data')
                } else {
                    setData({
                        regulations: [],
                        obligations: [],
                        disclosures: [],
                        regulators: []
                    })
                }
                //   setLoader(false)
            }
            // throw response.data;
            serLoder(false)
        } catch (err) {
            RedirectOnUnAuthentication(err, navigateTo)
            var message
            // setLoader(false)
            if (err.response && err.response.status) {
                switch (err.response.status) {
                    case 404:
                        message = "Sorry! Network Error(Status:404)"
                        break
                    case 500:
                        message =
                            "Sorry! something went wrong, please contact our support team, Status-500   "
                        break
                    case 401:
                        message = "You are not authorized to view this data."
                        break
                    default:
                        message = err[1]
                        break
                }
            }
            serLoder(false)
            throw message

        }
    }

    const handleInputChange = async (event) => {
        const inputValue = event.target.value.replace(/^\s+/, '');
        setInputValue(inputValue);
    };
    useEffect(() => {
        const controller = new AbortController();

        const timeData = setTimeout(async () => {
            await fetchData(inputValue, controller);
        }, 500);

        return () => { clearTimeout(timeData); controller.abort() };
    }, [inputValue]);

    const handleSelectSuggestion = (suggestion) => {
        setInputValue(suggestion);
        setSuggestions([]);
    };

    const handleClickOutside = (event) => {
        if (globalSearchDataRef.current && !globalSearchDataRef.current.contains(event.target)) {
            // Clicked outside of global search data, disable it here
            setClassData('global-serach-data-none')
            // Add your logic to disable the global search data here
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // console.log(data,'data')

    return (
        <div className="autocomplete" ref={globalSearchDataRef}>
            <div className='global-serach-input-div'>
                <input
                    className='global-serach-data-input'
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search here anything"
                    onFocus={() => { setClassData(data.regulations.length > 0 || data.regulators.length > 0 || data.obligations.length > 0 || data.disclosures.length > 0 ? 'global-serach-data' : 'global-serach-data-none') }}
                />
                {
                    !loder ?
                        <div className='global-serach-data-icon'>
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        :
                        <div className="ml-4" style={{ marginTop: '2px', marginRight: "2px" }}>
                            <Spinner className="ms-2" style={{ width: 25, height: 25 }} color="primary" />
                        </div>
                }
            </div>
            {(data.regulations.length > 0 || data.regulators.length > 0 || data.obligations.length > 0 || data.disclosures.length > 0) && <div className={classData}>
                {data.obligations.length > 0 && (
                    <div className="obligations">
                        <p className='global-serach-data-title'>Obligation</p>
                        {data.obligations.map((obligation) => (
                            <Link
                                to={{
                                    pathname: "/obligations/obligation_view",
                                    state: { rowData: obligation },
                                }}
                                key={obligation.obId} className="regulators"
                            >
                                <p style={{ marginBottom: '0px' }}>{HighlightedText({text:`${obligation.obId} - ${obligation.obligationName}`, searchTerm: inputValue})}</p>
                            </Link>
                        ))}
                    </div>
                )}
                {data.regulators.length > 0 && (
                    <div className="regulators">
                        <p className='global-serach-data-title'>Regulators</p>
                        {data.regulators.map((regulator) => (
                            <Link
                                to={{
                                    pathname: "/regulators/regulator_view",
                                    state: { rowData: regulator },
                                }}
                                key={regulator.id} className="regulators"
                            >
                                <p style={{ marginBottom: '0px' }}>{HighlightedText({text:`${regulator.regulatorShortName} - ${regulator.regulatorLongName}`, searchTerm: inputValue})}</p>
                            </Link>
                        ))}
                    </div>
                )}
                {data.regulations.length > 0 && (
                    <div className="regulations">
                        <p className='global-serach-data-title'>Regulations</p>
                        {data.regulations.map((regulation) => (
                            <Link
                                to={{
                                    pathname: "/regulations/regulation_view",
                                    state: { rowData: regulation },
                                }}
                                // to={"#"}
                                onClick={() => { console.log(regulation, 'regulationData') }}
                                key={regulation.regId} className="regulation"
                            >
                                <p style={{ marginBottom: '0px' }}>{HighlightedText({text:`${regulation.regId} - ${regulation.regShortName}`, searchTerm: inputValue})}</p>
                            </Link>
                        ))}
                    </div>
                )}
                {data.disclosures.length > 0 && (
                    <div className="disclosures">
                        <p className='global-serach-data-title'>Disclosures</p>
                        {data.disclosures.map((disclosure) => (
                            <Link
                                to={{
                                    pathname: "/disclosure-text/disclosure_view",
                                    state: { rowData: disclosure },
                                }}
                                key={disclosure.disId} className="regulators"
                            >
                                <p style={{ marginBottom: '0px' }}>{HighlightedText({text:`${disclosure.disId} - ${disclosure.disclosureTitle}`, searchTerm: inputValue})}</p>
                            </Link>
                        ))}
                    </div>
                )}
                {/* Include similar conditional rendering for obligations, disclosures, and regulators */}
            </div>}
        </div>
    );
}

export default GlobalSearch