import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Table,
  Row,
  TabContent,
  TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  Form,
  FormGroup,
  CardBody,
  CardTitle,
  NavLink,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

import { map } from "lodash"
import XLSX from 'xlsx';
// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import {
  getInboxMails as onGetInboxMails,
  getStarredMails as onGetStarredMails,
  getImportantMails as onGetImportantMails,
  getDraftMails as onGetDraftMails,
  getSentMails as onGetSentMails,
  getTrashMails as onGetTrashMails,
} from "store/mails/actions"

//Import Email Topbar
// import EmailToolbar from "./email-toolbar";
import RolesUpdate from "./Roles/update"
import RoleTable from "./Roles/index"

import Language from "./Language"

//redux
import { useSelector, useDispatch } from "react-redux"
import UsersTable from "./UsersTable"
import Update from "./Update"
import Add from "./Add"
import OrgaUpdate from "./Organization/update"
import UserView from "./UserView"
import OrganizationTable from "./Organization/index"
import RegulationScan from "pages/RegulationScan"
import { DownloadFormat } from "helpers/helper_function"

const Settings = props => {
  //meta title
  document.title = "Settings"

  const dispatch = useDispatch()

  //   const [activeTab, setactiveTab] = useState("1")
  const [selectedGroup, setselectedGroup] = useState(null)
  const [modal, setmodal] = useState(false)
  const [showTable, setShowTable] = useState("table")
  const [userViewData, setUsersViewData] = useState({})
  const [orgaViewData, setOrgaViewData] = useState({})
  const [showRolesTable, setShowRolesTable] = useState("table")
  const [showOrgaTable, setShowOrgaTable] = useState("table")
  const [editData, setEditData] = useState({})
  const [editRoleData, setEditRoleData] = useState({})
  const [editOrgaData, setEditOrgaData] = useState({})
  const [checkOrg, setCheckOrg] = useState()
  const [checkAdmin, setCheckAdmin] = useState()
  const [user, setUser] = useState()
  // const [activeTab, setactiveTab] = useState( checkOrg === 3 && checkAdmin === 1  ? "2" : "3");
  const [activeTab, setactiveTab] = useState("1")
  // console.log(showRolesTable, "RolesTable")

  // useEffect(() => {
  //   setShowRolesTable("table")
  // },[])

    const DownloadFormat2 = ({data,filename}) => {
    // const csvData = "Disclosure Id,Disclosure Text"
    // const filename = "Disclosure_Text_format.csv"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)
  
    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()
  
    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
  
  //   const data = [
  //     ["Disclosure Id", "Disclosure Text"],
  //     // Add your data rows here
  // ];
  
  // const ws = XLSX.utils.aoa_to_sheet(data);
  // const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // // const filename = "Disclosure_Text_format.xlsx";
  // XLSX.writeFile(wb, filename);




  //     const data = [
  //     ["Disclosure Id", "Disclosure Text"],
  //     // Add your data rows here
  // ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // const filename = "Disclosure_Text_format.xlsx";
  XLSX.writeFile(wb, filename);
  }
  const handleRegulatorDownloadFormat = () => { 
    const csvData =
      "Regulator Short Name,Regulator Long Name,Regulator Description,Regulator Main URL,Regulator Country,Regulator State,Regulator County"
    const filename = "Regulators_format.xlsx"
 
    DownloadFormat({data:[csvData.split(",")],filename:filename})





  //   const data = [
  //     ["Disclosure Id", "Disclosure Text"],
  //     // Add your data rows here
  // ];

  // const ws = XLSX.utils.aoa_to_sheet(data);
  // const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // const filename = "Disclosure_Text_format.xlsx";
  // XLSX.writeFile(wb, filename);
  }

  const handleRegulatorEditDownloadFormat = () => {
    //,Regulator Update Source,Regulator Update Contact
    const csvData =
      "Regulator ID, Regulator Short Name,Regulator Long Name,Regulator Description,Regulator Main URL,Regulator Country,Regulator State,Regulator County"
    const filename = "Regulators_edit_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()


    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }

  const handleRegulationDownloadFormat = () => {
    const csvData =
      "Reg Law Short Name,CFR Ref,Regulation Long Name,Regulation Description,Regulation URL,Regulation Related Law,Regulator Short Name,Regulation  Primary Supervisor,Regulation Effective Date (MM/DD/YYYY),Regulation Compliance Guide URL,Reg Last Update Date (MM/DD/YYYY)"
    const filename = "Regulation_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()

    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }
  
  const handleRegulationEditDownloadFormat = () => {
    const csvData =
      "Reg ID,Reg Law Short Name,CFR Ref,Regulation Long Name,Regulation Description,Regulation URL,Regulation Compliance Guide URL,Regulation Related Law,Regulator Short Name,Reguation  Primary Supervisor,Regulation Effective Date (MM/DD/YYYY),Reg Last Update Date (MM/DD/YYYY)"
    const filename = "Regulation_edit_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }

  const handleObligationDownloadFormat = () => {
    const csvData =
      "Obligation Name,Obligation Description,Citation URL,Regulator Short Name,Reg Law Short Name,Citation,Obligation Notes,Status"
    const filename = "Obligations_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }

  const handleUpdateObligationDownloadFormat = () => {
    const csvData =
      "Obligation ID,Obligation Name,Obligation Description,Citation URL,Regulator Short Name,Reg Law Short Name,Citation,Obligation Notes"
    const filename = "Obligations_edit_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }

  const handleDisclosureDownloadFormat = () => {
    const csvData =
      'Disclosure Title,Disclosure Description,Disclosure Type,Text,Sample,Sample URL1,Sample URL2,Sample URL3,"Safe Harbor (All;Part;None)",Disclosure Notes,Disclaimer,Disclosure specific URL,Disclosure specific Citation,Prod Group Inv,Prod Group Dep,Prod Group Auto,Pro Group Mort,Pro Group Card,Prod Group Other,Life - Marketing,Life - Open Onboard,Life - Servicing,Life - Close-out End,Communication Types,Status,Effective Until Date'
    const filename = "Disclosures_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }

  const handleDisclosureEditDownloadFormat = () => {
    const csvData =
      'Disclosure ID, Disclosure Title,Disclosure Description,Disclosure Type,Text,Sample,Sample URL1,Sample URL2,Sample URL3,"Safe Harbor (All; Part; None)",Disclosure Notes,Disclaimer,Disclosure specific URL,Disclosure specific Citation,Prod Group Inv,Prod Group Dep,Prod Group Auto,Pro Group Mort,Pro Group Card,Prod Group Other,Life - Marketing,Life - Open Onboard,Life - Servicing,Life - Close-out End,Communication Types,Status, Effective Until Date'
    const filename = "Disclosures_edit_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }

  const handleLinksDownloadFormat = () => {
    const csvData = "Disclosure ID,Obligation ID"
    const filename = "Links_format.xlsx"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()

    DownloadFormat({data:[csvData.split(",")],filename:filename})
  }
  const handleDisTextUploadFormat = () => {
    // const csvData = "Disclosure Id,Disclosure Text"
    // const filename = "Disclosure_Text_format.csv"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()
    const data = [
      ["Disclosure Id", "Disclosure Text"],
      // Add your data rows here
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const filename = "Disclosure_Text_format.xlsx";
  XLSX.writeFile(wb, filename);
  }
  const handleDisLangDownloadFormat = () => {
    // const csvData = "Disclosure Id,Disclosure Text"
    // const filename = "Disclosure_Text_format.csv"
    // const csvBlob = new Blob([csvData], { type: "text/csv" })
    // const csvURL = window.URL.createObjectURL(csvBlob)

    // const tempLink = document.createElement("a")
    // tempLink.href = csvURL
    // tempLink.setAttribute("download", filename)
    // tempLink.click()

    // // Cleanup
    // window.URL.revokeObjectURL(csvURL)
    // tempLink.remove()

    const data = [
      ["Disclosure Id", "Disclosure Text"],
      // Add your data rows here
  ];

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const filename = "Disclosure_Text_format.xlsx";
  XLSX.writeFile(wb, filename);
  }

  useEffect(() => {
    const handleStorageChange = () => {
      const authToken = JSON.parse(localStorage.getItem("authUser"))

      const obj2 = JSON.parse(localStorage.getItem("orgID"))
      if (obj2) {
        setCheckOrg(obj2.orgId)
        setCheckAdmin(authToken.data.role.id)
        // setCheckAdmin(authToken.data.role.id)
      } else if (!obj2 && authToken) {
        setCheckOrg(authToken.data.customerorg.id)
        setCheckAdmin(authToken.data.role.id)
        setUser(authToken.data.role.id)
      }
    }

    // Add event listener to listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange)
    }
  }, [])

  useEffect(() => {
    // const initialOrgID = localStorage.getItem('orgID');
    // setCheckOrg(initialOrgID);
    const initialOrgID = JSON.parse(localStorage.getItem("orgID"))
    const authToken = JSON.parse(localStorage.getItem("authUser"))
    if (initialOrgID) {
      setCheckOrg(initialOrgID && initialOrgID.orgId)
      setCheckAdmin(authToken.data.role.id)
    } else if (authToken) {
      setCheckOrg(authToken.data.customerorg.id)
      setCheckAdmin(authToken.data.role.id)
    }
    if (authToken && authToken.data.role.id === 17) {
      setactiveTab("3")
    }
  }, [])

  // console.log(checkOrg, checkAdmin, "checkOrg,checkAdmin")
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="3" sm="3">
              <Card className="email-leftbar">
                <div className="mail-list">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    {checkOrg === 3 && checkAdmin === 1 ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            setactiveTab("2")
                            setShowRolesTable("table")
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="bx bx-user-check font-size-14 me-2"></i>
                          Role Management
                        </NavLink>
                      </NavItem>
                    ) : (
                      ""
                    )}
                    {checkOrg === 3 && checkAdmin === 1 ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                            setactiveTab("4")
                            setShowOrgaTable("table")
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="mdi mdi-account-group-outline me-2"></i>{" "}
                          Organization{" "}
                          {/* <span className="ml-1 float-end">(18s)</span> */}
                        </NavLink>
                      </NavItem>
                    ) : (
                      ""
                    )}
                    {checkAdmin !== 17 && (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            setactiveTab("1")
                            setShowTable("table")
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="bx bx-user-circle me-2"></i> Users{" "}
                          {/* <span className="ml-1 float-end">(18s)</span> */}
                        </NavLink>
                      </NavItem>
                    )}

                    {checkOrg === 3 || checkAdmin === 17 ? (
                      // checkAdmin === 1 &&
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            setactiveTab("3")
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="bx bxs-download me-2"></i>Download
                          Upload Format
                        </NavLink>
                      </NavItem>
                    ) : (
                      ""
                    )}
                    {checkOrg !== 3 ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "6" ,
                          })}
                          onClick={() => {
                            setactiveTab("6") 
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="bx bx-upload font-size-16 align-middle me-1"></i>{" "}
                          Download Dislosure Text format{" "} 
                        </NavLink>
                      </NavItem>
                    ) : (
                      ""
                    )}
                    {/* {checkOrg === 3 && checkAdmin === 1 ? ( 
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "5",
                          })}
                          onClick={() => {
                            setactiveTab("5")
                            setShowOrgaTable("table")
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="bx bx-upload font-size-16 align-middle me-1"></i>{" "}
                          Upload Dislosure Text File{" "} 
                        </NavLink>
                      </NavItem>
                    ) : (
                      ""
                    )} */}
                       {checkOrg === 3 || checkAdmin === 17 ? (
                      // checkAdmin === 1 &&
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "7",
                          })}
                          onClick={() => {
                            setactiveTab("7")
                          }}
                          style={{ fontSize: "14px" }}
                        >
                          <i className="mdi mdi-radar me-2"></i>Regulation Scan
                        </NavLink>
                      </NavItem>
                    ) : (
                      ""
                    )}
                  </Nav>
                </div>
              </Card>
            </Col>
            <Col lg="9" sm="9">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {showTable == "table" ? (
                        <UsersTable
                          checkAdmin={checkAdmin}
                          checkOrg={checkOrg}
                          setEditData={setEditData}
                          setShowTable={setShowTable}
                          setUsersViewData={setUsersViewData}
                        />
                      ) : showTable == "addTable" ? (
                        <Add setShowTable={setShowTable} />
                      ) : showTable == "editTable" ? (
                        <Update
                          setShowTable={setShowTable}
                          editData={editData}
                        />
                      ) : showTable == "viewTable" ? (
                        <UserView
                          setShowTable={setShowTable}
                          userViewData={userViewData}
                        />
                      ) : (
                        ""
                      )}
                    </TabPane>
                    <TabPane
                      tabId="2"
                      id="v-pills-payment"
                      role="tabpanel"
                      aria-labelledby="v-pills-payment-tab"
                    >
                      {showRolesTable == "table" ? (
                        <RoleTable
                          setEditRoleData={setEditRoleData}
                          setShowRolesTable={setShowRolesTable}
                        />
                      ) : showRolesTable == "addTable" ? (
                        <RolesUpdate setShowRolesTable={setShowRolesTable} />
                      ) : showRolesTable == "editTable" ? (
                        <RolesUpdate
                          setShowRolesTable={setShowRolesTable}
                          editRoleData={editRoleData}
                        />
                      ) : (
                        ""
                      )}
                    </TabPane>
                    <TabPane tabId="3" id="v-pills-confir" role="tabpanel">
                      <Card className="shadow-none border mb-0">
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table align-middle mb-0 table-nowrap">
                              <thead className="table-light">
                                <tr>
                                  <th scope="col">Download Upload Format</th>
                                  {/* <th scope="col">Product Desc</th> */}
                                  <th scope="col" colSpan="2">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">Regulators</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleRegulatorDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Regulators Edit</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleRegulatorEditDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Regulations</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleRegulationDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Regulations Edit</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleRegulationEditDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Obligations</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleObligationDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Obligations Edit</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={
                                        handleUpdateObligationDownloadFormat
                                      }
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Disclosures</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleDisclosureDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Disclosures Edit</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleDisclosureEditDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Link Table</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleLinksDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    Disclosure Text
                                  </th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleDisLangDownloadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId="4">
                      {showOrgaTable == "table" ? (
                        <OrganizationTable
                          setEditOrgaData={setEditOrgaData}
                          setShowOrgaTable={setShowOrgaTable}
                          setOrgaViewData={setOrgaViewData}
                        />
                      ) : showOrgaTable == "addTable" ? (
                        <OrgaUpdate setShowOrgaTable={setShowOrgaTable} />
                      ) : showOrgaTable == "editTable" ? (
                        <OrgaUpdate
                          setShowOrgaTable={setShowOrgaTable}
                          editOrgaData={editOrgaData}
                        />
                      ) : (
                        ""
                      )}
                    </TabPane>
                    <TabPane tabId="5">
                      <Language />
                    </TabPane>
                    <TabPane tabId="6" id="v-pills-confir" role="tabpanel">
                      <Card className="shadow-none border mb-0">
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table align-middle mb-0 table-nowrap">
                              <thead className="table-light">
                                <tr>
                                  <th scope="col">Download Upload Format</th>
                                  {/* <th scope="col">Product Desc</th> */}
                                  <th scope="col" colSpan="2">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row">Disclosure Text</th>
                                  <td>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleDisTextUploadFormat}
                                    >
                                      <i className="bx bx-download font-size-16 align-middle me-1"></i>{" "}
                                      Download
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId="7" id="v-pills-confir" role="tabpanel">
                    
                            <RegulationScan activeTab={activeTab}/> 
                        
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

            <Modal
              isOpen={modal}
              autoFocus={true}
              centered={true}
              toggle={() => {
                setmodal(!modal)
              }}
            >
              <div className="modal-content">
                <ModalHeader
                  toggle={() => {
                    setmodal(!modal)
                  }}
                >
                  New Message
                </ModalHeader>
                <ModalBody>
                  <form>
                    <div className="mb-3">
                      <Input
                        type="email"
                        className="form-control"
                        placeholder="To"
                      />
                    </div>

                    <div className="mb-3">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </form>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      setmodal(!modal)
                    }}
                  >
                    Close
                  </Button>
                  <Button type="button" color="primary">
                    Send <i className="fab fa-telegram-plane ms-1"></i>
                  </Button>
                </ModalFooter>
              </div>
            </Modal>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Settings)
